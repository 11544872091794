// import React from "react";
// import { Link } from "react-router-dom";
// import Main from "../../main";
// import PageTitle from "../../includes/page_title.js";
// import SlideToTop from "../../includes/slide_to_top.js";
// import { connect } from "react-redux"
// import PropTypes from "prop-types"
// import { urlEndPoints } from "./../../../httpAPI/apiConfig"
// import _ from "lodash"
// import { LoadingAction } from "./../../../actions"
// import topLineImage from "../../../assets/images/top-line.png"
// import blankImage from "../../../assets/images/blank.png"
// import activityImage from "../../../assets/images/ico_activities.png"
// import connectionImage from "../../../assets/images/ico_connections.png"
// import settingsImage from "../../../assets/images/ico_settings.png"
// import checkList from "../../../assets/images/checkList.png"
// import transcript from "../../../assets/images/transcript.png"
// import reward from "../../../assets/images/reward.png"

// class ViewProfile extends Main {
//     constructor(props) {
//         super(props);
//         this.state = {
//             config: this.config(),
//             profileData: {
//                 picture: "",
//                 name: ""
//             },
//             invalidAccess: false
//         }
//         this.props.showLoader()
//     }
//     componentDidMount() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_profile")} | ${this.context.t("_student")}`
//         this.getProfileData()
//     }
//     componentDidUpdate() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_profile")} | ${this.context.t("_student")}`
//     }
//     getProfileData = async () => {
//         // const authResponse = await this.auth();
//         // const { match: { params: { id } } } = this.props;
//         const id="4155"

//         // if (authResponse) {
//         const { config } = this.state
//         const url = `${config.api_url}${urlEndPoints.getProfile(id)}`
//         // const headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         try {
//             const data = await this.networkRequest(url)
//             this.props.hideLoader()
//             if (_.has(data, "data.error.qid")) {
//                 this.setState({ invalidAccess: true })
//             } else {
//                 this.setState({ profileData: data.data })
//             }
//             setTimeout(() => {
//                 document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
//             }, 500);
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//         // }
//     }
//     // handleGoBack = () => {
//     //     this.props.history.goBack();
//     // }
//     render() {
//         const { profileData, config, invalidAccess } = this.state;
// const id="4155"
//         // const { match: { params: { id } } } = this.props;
//         return (

//             <div id="main-container">
//                 <SlideToTop />
//                 <PageTitle title={this.context.t("_profile")} />
//                 <div id="skipContent" className="container">
//                     <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
//                         <ul>
//                             <li className="breadcrumb-item">
//                                 <Link to="/parent-profile">{this.context.t("_profile")}</Link> {" "}

//                             </li>
//                             <li className="breadcrumb-item">
//                                 <Link tabIndex="0" to aria-current={this.context.t("_page")}>
//                                     <span>{this.context.t("_participants")}</span>
//                                 </Link>
//                             </li>
//                         </ul>
//                     </nav>
//                 </div>
//                 <div>
//                     <div className="container profile-container">
//                         {
//                             invalidAccess ?
//                                 <div className="page-not-found center">
//                                     <h4>{this.context.t("_unauthorized")}</h4>
//                                     <button onClick={() => this.handleGoBack()} className="btn profileBtn">{this.context.t("_go_back")}</button>
//                                 </div>

//                                 :
//                                 <div className="row">
//                                     <div className="col s12 text-center">
//                                         <figure className="profilePic profileNew">
//                                             {
//                                                 _.isEmpty(profileData.picture) ?
//                                                     <img src={blankImage} alt="" />
//                                                     :
//                                                     <>
//                                                         <img src={blankImage} alt="" />
//                                                         <span>
//                                                             <img src={`${config.origin}${profileData.picture}`} alt="" />
//                                                         </span>
//                                                     </>
//                                             }


//                                         </figure>
//                                         <h4 className="profileheading profileNew">{profileData.name}</h4>
//                                         {/* <div className="starHolder">
//                                             <span className="fa fa-star checked"></span>
//                                             <span className="fa fa-star checked"></span>
//                                             <span className="fa fa-star checked"></span>
//                                             <span className="fa fa-star"></span>
//                                             <span className="fa fa-star"></span>
//                                         </div> */}
//                                     </div>
//                                     <div className="col s12">
//                                         <div className="profileStatus profileNew">
//                                             <ul>
//                                                 <li tabIndex="0" aria-label={`${this.context.t("_challenge_count")} ${_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}`}>
//                                                     <strong>
//                                                         {_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}
//                                                     </strong>
//                                                     <span>{this.context.t("_challenges")}</span></li>
//                                                 <li tabIndex="0" aria-label={`${this.context.t("_competition_count")} ${_.has(profileData, "enrollment_count.competition.count") ? profileData.enrollment_count.competition.count : 0}`}>
//                                                     <strong>
//                                                         {_.has(profileData, "enrollment_count.competition.count") ? profileData.enrollment_count.competition.count : 0}
//                                                     </strong>
//                                                     <span>{this.context.t("_competitions")}</span></li>
//                                                 <li tabIndex="0" aria-label={`${this.context.t("_session_count")} ${_.has(profileData, "enrollment_count.session.count") ? profileData.enrollment_count.session.count : 0}`}>
//                                                     <strong>
//                                                         {_.has(profileData, "enrollment_count.session.count") ? profileData.enrollment_count.session.count : 0}
//                                                     </strong>
//                                                     <span>{this.context.t("_sessions")}</span></li>
//                                                 <li tabIndex="0" aria-label={`${this.context.t("_event_count")} ${_.has(profileData, "enrollment_count.event.count") ? profileData.enrollment_count.event.count : 0}`}>
//                                                     <strong>
//                                                         {_.has(profileData, "enrollment_count.event.count") ? profileData.enrollment_count.event.count : 0}
//                                                     </strong>
//                                                     <span>{this.context.t("_events")}</span></li>
//                                                 <li tabIndex="0" aria-label={`${this.context.t("_project_count")} ${_.has(profileData, "enrollment_count.project.count") ? profileData.enrollment_count.project.count : 0}`}>
//                                                     <strong>
//                                                         {_.has(profileData, "enrollment_count.project.count") ? profileData.enrollment_count.project.count : 0}
//                                                     </strong>
//                                                     <span>{this.context.t("_projects")}</span></li>
//                                             </ul>
//                                         </div>

//                                         <div className="profileTabHolder profileNew">
//                                             <div className="cell">
//                                                 <Link to={`/student-activity/${id}`}>
//                                                     <div className="profileTab item4">
//                                                         <img src={activityImage} alt="" />
//                                                         <p>{this.context.t("_activities")}</p>
//                                                     </div>
//                                                 </Link>
//                                             </div>
//                                             <div className="cell">
//                                                 {
//                                                     localStorage.getItem("role") != "volunteer" && <div className="cell">
//                                                         <Link to={`/student-connection/${id}`}>
//                                                             <div className="profileTab item4">
//                                                                 <img src={connectionImage} alt="" />
//                                                                 <p>{this.context.t("_relatives")}</p>
//                                                             </div>
//                                                         </Link>
//                                                     </div>
//                                                 }
//                                             </div>
//                                             <div className="cell">
//                                                 <Link to={`/student-settings/${id}`}>
//                                                     <div className="profileTab item4">
//                                                         <img src={settingsImage} alt="" />
//                                                         <p>{this.context.t("_profile_settings")}</p>
//                                                     </div>
//                                                 </Link>
//                                             </div>
//                                             <div className="cell">
//                                                 <Link to={`/student-attendance/${id}`}>
//                                                     <div className="profileTab item4">
//                                                         <img src={checkList} alt="" />
//                                                         <p>{this.context.t("_attendance")}</p>
//                                                     </div>
//                                                 </Link>
//                                             </div>
//                                             <div className="cell">
//                                                 <Link to={`/student-transcript/${id}`}>
//                                                     <div className="profileTab item4">
//                                                         <img src={transcript} alt="" />
//                                                         <p>{this.context.t("_transcript")}</p>
//                                                     </div>
//                                                 </Link>
//                                             </div>
//                                             <div className="cell">
//                                                 <Link to={`/reward/${id}`}>
//                                                     <div className="profileTab item4">
//                                                         <img src={reward} alt="" />
//                                                         <p>{this.context.t("_reward_system")}</p>
//                                                     </div>
//                                                 </Link>
//                                             </div>
//                                         </div>
//                                     </div>

//                                 </div>

//                         }
//                     </div>
//                 </div>
//                 <div className="top-line ng-scope">
//                     <img src={topLineImage} alt="" />
//                 </div>
//             </div>
//         )
//     }

// }

// const mapStateToProps = ({ i18nState }) => {
//     return {
//         lang: i18nState.lang
//     }
// }
// const mapDispatchToProps = {
//     showLoader: LoadingAction.showLoader,
//     hideLoader: LoadingAction.hideLoader,
// }
// ViewProfile.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(ViewProfile);


import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Main from "../../../constants/utilities.js";
import PageTitle from "../../includes/page_title.js";
import SlideToTop from "../../includes/slide_to_top.js";
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import _ from "lodash"
import { LoadingAction } from "./../../../actions"
import topLineImage from "../../../assets/images/top-line.png"
import blankImage from "../../../assets/images/blank.png"
import activityImage from "../../../assets/images/ico_activities.png"
import connectionImage from "../../../assets/images/ico_connections.png"
import settingsImage from "../../../assets/images/ico_settings.png"
import checkList from "../../../assets/images/checkList.png"
import transcript from "../../../assets/images/transcript.png"
import reward from "../../../assets/images/reward.png"
import { useState } from "react";
import { useEffect } from "react";

const ViewProfile = (props,context) => {
 
    const { config, networkRequest } = Main();

    const [profileData, setProfileData] = useState({ picture: "", name: "" });
    const [invalidAccess, setInvalidAccess] = useState(false);
    const configuration=config()


    const { lang } = useSelector((state) => state.i18nState)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { id } = useParams()
    

    useEffect(() => {
        dispatch(LoadingAction.showLoader())
        document.title = `${context.t("_studio56")} | ${context.t("_profile")} | ${context.t("_student")}`
        getProfileData()
    }, []);

    // componentDidUpdate() {
    //     document.title = `${context.t("_studio56")} | ${context.t("_profile")} | ${context.t("_student")}`
    // }

    const getProfileData = async () => {
        // const authResponse = await this.auth();
        // if (authResponse) {
        const url = `${configuration.api_url}${urlEndPoints.getProfile(id)}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await networkRequest(url)
            dispatch(LoadingAction.hideLoader())

            if (_.has(data, "data.error.qid")) {
                setInvalidAccess(true)
                // this.setState({ invalidAccess: true })
            } else {
                setProfileData(data.data)
                // this.setState({ profileData: data.data })
            }
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // }
    }
    const handleGoBack = () => {
        navigate(-1);
    }

    return (

        <div id="main-container">
            <SlideToTop />
            <PageTitle title={context.t("_profile")} />
            <div id="skipContent" className="container">
                <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                    <ul>
                        <li className="breadcrumb-item">
                            <Link to="/parent-profile">{context.t("_profile")}</Link> {" "}

                        </li>
                        <li className="breadcrumb-item">
                            <Link tabIndex="0" to aria-current={context.t("_page")}>
                                <span>{context.t("_participants")}</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div>
                <div className="container profile-container">
                    {
                        invalidAccess ?
                            <div className="page-not-found center">
                                <h4>{context.t("_unauthorized")}</h4>
                                <button onClick={() => handleGoBack()} className="btn profileBtn">{context.t("_go_back")}</button>
                            </div>

                            :
                            <div className="row">
                                <div className="col s12 text-center">
                                    <figure className="profilePic profileNew">
                                        {
                                            _.isEmpty(profileData.picture) ?
                                                <img src={blankImage} alt="" />
                                                :
                                                <>
                                                    <img src={blankImage} alt="" />
                                                    <span>
                                                        <img src={`${configuration.origin}${profileData.picture}`} alt="" />
                                                    </span>
                                                </>
                                        }


                                    </figure>
                                    <h4 className="profileheading profileNew">{profileData.name}</h4>
                                    {/* <div className="starHolder">
                                            <span className="fa fa-star checked"></span>
                                            <span className="fa fa-star checked"></span>
                                            <span className="fa fa-star checked"></span>
                                            <span className="fa fa-star"></span>
                                            <span className="fa fa-star"></span>
                                        </div> */}
                                </div>
                                <div className="col s12">
                                    <div className="profileStatus profileNew">
                                        <ul>
                                            <li tabIndex="0" aria-label={`${context.t("_challenge_count")} ${_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}`}>
                                                <strong>
                                                    {_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}
                                                </strong>
                                                <span>{context.t("_challenges")}</span></li>
                                            <li tabIndex="0" aria-label={`${context.t("_competition_count")} ${_.has(profileData, "enrollment_count.competition.count") ? profileData.enrollment_count.competition.count : 0}`}>
                                                <strong>
                                                    {_.has(profileData, "enrollment_count.competition.count") ? profileData.enrollment_count.competition.count : 0}
                                                </strong>
                                                <span>{context.t("_competitions")}</span></li>
                                            <li tabIndex="0" aria-label={`${context.t("_session_count")} ${_.has(profileData, "enrollment_count.session.count") ? profileData.enrollment_count.session.count : 0}`}>
                                                <strong>
                                                    {_.has(profileData, "enrollment_count.session.count") ? profileData.enrollment_count.session.count : 0}
                                                </strong>
                                                <span>{context.t("_sessions")}</span></li>
                                            <li tabIndex="0" aria-label={`${context.t("_event_count")} ${_.has(profileData, "enrollment_count.event.count") ? profileData.enrollment_count.event.count : 0}`}>
                                                <strong>
                                                    {_.has(profileData, "enrollment_count.event.count") ? profileData.enrollment_count.event.count : 0}
                                                </strong>
                                                <span>{context.t("_events")}</span></li>
                                            <li tabIndex="0" aria-label={`${context.t("_project_count")} ${_.has(profileData, "enrollment_count.project.count") ? profileData.enrollment_count.project.count : 0}`}>
                                                <strong>
                                                    {_.has(profileData, "enrollment_count.project.count") ? profileData.enrollment_count.project.count : 0}
                                                </strong>
                                                <span>{context.t("_projects")}</span></li>
                                        </ul>
                                    </div>

                                    <div className="profileTabHolder profileNew">
                                        <div className="cell">
                                            <Link to={`/student-activity/${id}`}>
                                                <div className="profileTab item4">
                                                    <img src={activityImage} alt="" />
                                                    <p>{context.t("_activities")}</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="cell">
                                            {
                                                localStorage.getItem("role") != "volunteer" && <div className="cell">
                                                    <Link to={`/student-connection/${id}`}>
                                                        <div className="profileTab item4">
                                                            <img src={connectionImage} alt="" />
                                                            <p>{context.t("_relatives")}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            }
                                        </div>
                                        <div className="cell">
                                            <Link to={`/student-settings/${id}`}>
                                                <div className="profileTab item4">
                                                    <img src={settingsImage} alt="" />
                                                    <p>{context.t("_profile_settings")}</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="cell">
                                            <Link to={`/student-attendance/${id}`}>
                                                <div className="profileTab item4">
                                                    <img src={checkList} alt="" />
                                                    <p>{context.t("_attendance")}</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="cell">
                                            <Link to={`/student-transcript/${id}`}>
                                                <div className="profileTab item4">
                                                    <img src={transcript} alt="" />
                                                    <p>{context.t("_transcript")}</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="cell">
                                            <Link to={`/reward/${id}`}>
                                                <div className="profileTab item4">
                                                    <img src={reward} alt="" />
                                                    <p>{context.t("_reward_system")}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>

                    }
                </div>
            </div>
            <div className="top-line ng-scope">
                <img src={topLineImage} alt="" />
            </div>
        </div>
    )


}


ViewProfile.contextTypes = {
    t: PropTypes.func
}
export default ViewProfile
