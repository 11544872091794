// import React from "react";
// import { Link } from "react-router-dom";
// import _, { forEach } from "lodash"
// import Main from "../../main";
// import PageTitle from "../../includes/page_title.js";
// import SlideToTop from "../../includes/slide_to_top.js";
// import { connect } from "react-redux"
// import PropTypes from "prop-types"
// import DatePicker from "react-datepicker"
// import "react-datepicker/dist/react-datepicker.css";
// import { dynamicStyles, customModalStyles } from "./../../../constants/dynamicStyles"
// import { urlEndPoints } from "./../../../httpAPI/apiConfig"
// import { qidValidate, nameValidate, nameWordLengthCheck, emailValidate, phoneValidate, calenderI18n, gradeListNew, CustomDateInput, calenderLocal } from "./../../../constants/common"
// import M from "materialize-css";
// import { PopupAction, LoadingAction } from "./../../../actions"
// import dateformat from "dateformat"
// import * as XLSX from "xlsx";

// import Modal from "react-modal";
// import topLineImage from "../../../assets/images/top-line.png"
// import { handleGradeName, getGender } from "./../../dashboard/pages/utilFunctions"

// class SchoolConnections extends Main {
//     constructor(props) {
//         super(props);
//         this.state = {
//             formData: {},
//             studentFormData: {},
//             parentFormData: {},
//             studentList: [],
//             config: this.config(),
//             intervalId: 0,
//             scrollStepInPx: 50,
//             delayInMs: 16.66,
//             isParentExist: true,
//             selectedStudent: "",
//             removeStudentConfirm: false,
//             removeStudentId: null,
//             schoolList: [],
//             checkedList: {},
//             checkAll: false,
//             mySchool: null
//         };
//         this.props.showLoader();
//     }
//     async componentDidMount() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_manage_connections")}`
//         await this.initState();
//         this.getSchoolList();
//         await this.getChildren();
//         this.getMySchool();
//         M.AutoInit();
//         var options = {
//             autoClose: true,
//             dismissible: true
//         };
//         const dobOptions = {
//             autoClose: true,
//             onSelect: ((date) => {
//                 this.handleChangeAddForm("dob", dateformat(date, "dd/mm/yyyy"))
//             }),
//             defaultDate: new Date("01/01/2001"),
//             setDefaultDate: new Date("01/01/2001"),
//             format: "dd/mm/yyyy",
//             container: null,
//             maxDate: new Date("12/31/2014"),
//             minDate: new Date("01/01/2001"),
//             yearRange: 20,
//             minYear: 2001,
//             maxYear: 2014
//         };
//         setTimeout(() => {
//             var elems = document.querySelectorAll("#dob");
//             dobOptions.i18n = calenderI18n(this.context);
//             // M.Datepicker.init(elems, dobOptions);
//             var elems = document.querySelectorAll(".modal");
//             M.Modal.init(elems, options);
//             const selectelem = document.querySelectorAll("select");
//             M.FormSelect.init(selectelem, options);

//         }, 200);
//         window.addEventListener('load', this.handleTabIndex());
//         setTimeout(() => {
//             document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
//         }, 500);
//     }
//     componentDidUpdate() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_manage_connections")}`
//     }
//     handleTabIndex = () => {
//         setTimeout(() => {
//             let elem = document.querySelectorAll('select');
//             elem.forEach(element => {
//                 element.setAttribute("tabindex", "0");
//             });
//         }, 1000);
//     }
//     initState = () => {
//         this.setState({
//             formData: {
//                 name: { value: "", error: "", class: "" },
//                 grade: { value: "", error: "", class: "" },
//                 gender: { value: "", error: "", class: "" },
//                 age: { value: "", error: "", class: "" }
//             },
//             studentFormData: {
//                 name: { value: "", error: "", class: "" },
//                 qid: { value: "", error: "", class: "" },
//                 email: { value: "", error: "", class: "" },
//                 gender: { value: "Male", error: "", class: "" },
//                 mobile: { value: "", error: "", class: "" },
//                 dob: { value: "", error: "", class: "" },
//                 schoolName: { value: "", error: "", class: "" },
//                 schoolNewName: { value: "", error: "", class: "" },
//                 parentName: { value: "", error: "", class: "" },
//                 parentNameArabic: { value: "", error: "", class: "" },
//                 parentMobile: { value: "", error: "", class: "" },
//                 parentMobile2: { value: "", error: "", class: "" },
//                 parentEmail: { value: "", error: "", class: "" },
//                 parentQid: { value: "", error: "", class: "" },
//                 relation: { value: "", error: "", class: "" },
//             },
//             parentFormData: {
//                 parentName: { value: "", error: "", class: "" },
//                 qid: { value: "", error: "", class: "" },
//                 dob: { value: "", error: "", class: "" },
//                 email: { value: "", error: "", class: "" },
//                 mobileNumber: { value: "", error: "", class: "" },
//             }
//         })
//     }
//     handleChange = (field, value) => {
//         const { formData } = this.state;
//         formData[field]["class"] = value ? "" : "invalid";
//         formData[field]["value"] = value;
//         this.setState({ formData });
//     };
//     handleChangeAddForm = (field, value) => {
//         if (field == "qid")
//             if (isNaN(value))
//                 return false
//         const { studentFormData } = this.state;
//         studentFormData[field]["class"] = value ? "" : "invalid";
//         studentFormData[field]["value"] = value;
//         this.setState({ studentFormData });
//     };
//     handleChangeParentForm = (field, value) => {
//         if (field == "qid")
//             if (isNaN(value))
//                 return false
//         const { parentFormData } = this.state;
//         parentFormData[field]["class"] = value ? "" : "invalid";
//         parentFormData[field]["value"] = value;
//         this.setState({ parentFormData });
//     };

//     getSchoolList = async () => {
//         const { config } = this.state
//         const { lang } = this.props;
//         const url = `${config.api_url}${urlEndPoints.schoolList(lang)}`
//         try {
//             const data = await this.networkRequest(url)
//             this.setState({ schoolList: data.data })
//             // this.schoolName.focus()
//             // this.initSelect()
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//     }
//     getMySchool = async () => {
//         const { config, studentFormData } = this.state
//         const { lang } = this.props;
//         const url = `${config.api_url}${urlEndPoints.getMySchool}`
//         try {
//             const data = await this.networkRequest(url)
//             if (data.data && data.data.length > 0) {
//                 studentFormData.schoolName.value = data.data[0].id
//                 this.setState({ studentFormData })
//             }
//             // this.setState({ schoolList: data.data })
//             // this.schoolName.focus()
//             // this.initSelect()
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//     }
//     getChildren = async () => {
//         // const authResponse = await this.auth();
//         const { formData } = this.state;
//         // if (authResponse) {
//         const { config } = this.state
//         let url = `${config.api_url}${urlEndPoints.myChildren}`

//         if (!_.isEmpty(formData.name.value))
//             url = `${url}&combine=${formData.name.value}`

//         if (!_.isEmpty(formData.gender.value))
//             url = `${url}&field_gender_value=${formData.gender.value}`

//         if (!_.isEmpty(formData.grade.value))
//             url = `${url}&field_grade_value=${formData.grade.value}`

//         if (!_.isEmpty(formData.age.value)) {
//             const ageGroup = this.calculateAgeGroup(formData.age.value)
//             url = `${url}&field_student_dob_value=${ageGroup.minDate}&field_student_dob_value_1=${ageGroup.maxDate}`
//         }


//         // const headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         try {
//             const data = await this.networkRequest(url)
//             this.props.hideLoader();
//             if (!data.data.message) {
//                 await this.setState({ studentList: data.data.results, checkedList: {}, checkAll: false })
//                 await this.handleGetParent();
//             }
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//         // }
//     }
//     calculateAgeGroup = (ageGroup) => {
//         switch (ageGroup) {
//             case "one_to_ten": {
//                 const dob = new Date();
//                 dob.setFullYear(dob.getFullYear() - Number(1));
//                 const minDate = `${dateformat(dob, "yyyy-mm-dd")}`
//                 dob.setFullYear(dob.getFullYear() - Number(10));
//                 const maxDate = `${dateformat(dob, "yyyy-mm-dd")}`

//                 return { minDate, maxDate }
//             }
//             case "eleven_to_twenty": {
//                 const dob = new Date();
//                 dob.setFullYear(dob.getFullYear() - Number(11));
//                 const minDate = `${dateformat(dob, "yyyy-mm-dd")}`
//                 dob.setFullYear(dob.getFullYear() - Number(20));
//                 const maxDate = `${dateformat(dob, "yyyy-mm-dd")}`

//                 return { minDate, maxDate }
//             }
//             case "twentyone_to_thirty": {
//                 const dob = new Date();
//                 dob.setFullYear(dob.getFullYear() - Number(21));
//                 const minDate = `${dateformat(dob, "yyyy-mm-dd")}`
//                 dob.setFullYear(dob.getFullYear() - Number(30));
//                 const maxDate = `${dateformat(dob, "yyyy-mm-dd")}`

//                 return { minDate, maxDate }
//             }

//             default:
//                 break;
//         }
//     }
//     calculateAge = (dateString) => {
//         var today = new Date();
//         var birthDate = new Date(dateString);
//         var age = today.getFullYear() - birthDate.getFullYear();
//         var m = today.getMonth() - birthDate.getMonth();
//         if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
//             age--;
//         }
//         return age;
//     }
//     handleCloseModal = () => {
//         this.initState();
//         let elems = document.querySelector("#checkQid");
//         elems.removeAttribute("aria-hidden")
//         elems.removeAttribute("tabindex")
//         let instance = M.Modal.getInstance(elems);
//         instance.close();
//         elems = document.querySelector("#checkQidForParent");
//         elems.removeAttribute("aria-hidden")
//         elems.removeAttribute("tabindex")
//         instance = M.Modal.getInstance(elems);
//         instance.close();
//         elems = document.querySelector("#addParent");
//         elems.removeAttribute("aria-hidden")
//         elems.removeAttribute("tabindex")
//         instance = M.Modal.getInstance(elems);
//         instance.close();
//         elems = document.querySelector("#addStudent");
//         elems.removeAttribute("aria-hidden")
//         elems.removeAttribute("tabindex")
//         instance = M.Modal.getInstance(elems);
//         instance.close();
//     }
//     handleAddParent = () => {
//         this.setState({ isParentExist: false })
//     }
//     onSubmit = (event) => {
//         event.preventDefault();
//         this.props.showLoader()
//         this.getChildren()
//     }
//     handleViewProfile = id => {
//         this.props.history.push(`/student-profile/${id}`);
//     }

//     renderSelect = ({ name, label, placeholder, isMandatory = false, data = [], type = "select", disabled = false }) => {
//         const { studentFormData } = this.state;
//         const { lang } = this.props;
//         return (
//             <div className={`input-field mobileSelect item ${lang}`} >
//                 <label htmlFor={name} style={dynamicStyles.position(lang)}>
//                     {`${label} `}
//                     {isMandatory ? <span className="asterisk">*</span> : null}
//                 </label>
//                 <select
//                     name={name}
//                     disabled={disabled}
//                     id={name}
//                     className="browser-default"
//                     style={dynamicStyles.textAlign(lang)}
//                     ref={(input) => { this[name] = input; }}
//                     onChange={evt =>
//                         this.handleChangeAddForm(name, evt.target.value)
//                     }

//                     onFocus={evt =>
//                         this.handleChangeAddForm(name, evt.target.value)
//                     }
//                     value={studentFormData[name].value}
//                 // className={"validate" + formData[name].class}
//                 >
//                     <option value="" disabled> {placeholder} </option>
//                     {
//                         data.map((item, index) => {
//                             return type == "country" ?
//                                 <option key={index} value={item.country_id}>{item.country[lang]}</option>
//                                 :
//                                 type == "school" ? <option key={index} value={item.id}>{item.sname}</option> :
//                                     <option key={index} value={item.key}>{item.label}</option>
//                         })
//                     }
//                     {
//                         type == "school" && <option value="0">{this.context.t("_not_in_list")}</option>
//                     }
//                 </select>
//             </div>
//         )
//     }
//     renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max, onchange }) => {
//         const { studentFormData } = this.state;
//         const { lang } = this.props;
//         return (type == "phone_number" ?
//             <div className="row mobile">
//                 <div className="col s4">
//                     <div className="input-field item">
//                         <input name={`${name}_country_code`}
//                             placeholder="Country Code"
//                             value={this.context.t("_ar_country_code")}
//                             style={dynamicStyles.textAlign(lang)}
//                             id={`${name}_country_code`}
//                             type="text"
//                             minLength="3"
//                             maxLength="3"
//                             disabled
//                         // className={"validate " + registration_form.mobile.class}
//                         // required
//                         />
//                         <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{this.context.t("_code")}</label>
//                         <span className="helper-text" data-error="Enter a valid code."></span>
//                     </div>
//                 </div>
//                 <div className="col s8">
//                     <div className="input-field item">
//                         <input
//                             name={name}
//                             placeholder={placeholder}
//                             ref={(input) => { this[name] = input; }}
//                             aria-label={this.context.t("_phone_aria_label")}
//                             id={name}
//                             maxLength={max}
//                             type="text"
//                             style={dynamicStyles.textAlign(lang)}
//                             // readOnly={type === "date" ? "readonly" : false}
//                             autoComplete="no"
//                             onChange={evt =>
//                                 this.handleChangeAddForm(name, evt.target.value)
//                             }
//                             onFocus={evt =>
//                                 this.handleChangeAddForm(name, evt.target.value)
//                             }
//                             value={studentFormData[name].value}
//                         // className={"validate " + formData[name].class}
//                         // required={isMandatory}
//                         />
//                         <label htmlFor={name} style={dynamicStyles.position(lang)}>
//                             {`${label} `}
//                             {isMandatory ? <span className="asterisk">*</span> : null}
//                         </label>
//                         <span
//                             style={dynamicStyles.textAlign(lang)}
//                             className="helper-text"
//                             data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
//                         ></span>
//                     </div>
//                 </div>
//             </div>
//             : type == "date" ? <div className="input-field item">
//                 <DatePicker
//                     name={name}
//                     placeholderText={placeholder}
//                     ref={(input) => { this[name] = input; }}
//                     id={name}
//                     autoComplete="no"
//                     // className={styleClass}
//                     maxDate={new Date("12/31/2014")}
//                     minDate={new Date("01/01/2001")}
//                     dateFormat="yyyy-MM-dd"
//                     onChange={date =>
//                         this.handleChangeAddForm(name, date)
//                     }
//                     style={dynamicStyles.textAlign(lang)}
//                     value={studentFormData[name].value}
//                     selected={studentFormData[name].value}
//                     locale={calenderLocal(this.context)}
//                     customInput={<CustomDateInput ariaLabel={this.context.t("_select_dob_button")} onFieldChange={date => this.handleChangeAddForm(name, new Date(date))} />}
//                     onCalendarOpen={e => {
//                         let elems = document.querySelector(".react-datepicker");
//                         elems.setAttribute("tabindex", "0")
//                         elems.focus()
//                         elems = document.querySelector(".react-datepicker__month-select");
//                         elems.setAttribute("aria-label", this.context.t("_select_month"))
//                         elems = document.querySelector(".react-datepicker__year-select");
//                         elems.setAttribute("aria-label", this.context.t("_select_year"))

//                     }}
//                     onCalendarClose={e => {
//                         var elems = document.querySelector(".hiddenDob");
//                         elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(studentFormData[name].value, "dd-mm-yyyy")}`)
//                         elems.setAttribute("value", dateformat(studentFormData[name].value, "yyyy-mm-dd"))
//                         setTimeout(() => {
//                             elems.focus()
//                         }, 200);
//                     }}
//                     peekNextMonth
//                     showMonthDropdown
//                     showYearDropdown
//                     dropdownMode="select"
//                 />
//                 <label htmlFor={name} style={dynamicStyles.position(lang)}>
//                     {`${label} `}
//                     {isMandatory ? <span className="asterisk">*</span> : null}
//                 </label>
//                 <span
//                     style={dynamicStyles.textAlign(lang)}
//                     className="helper-text"
//                     data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
//                 ></span>
//             </div> :
//                 <div className="input-field item">
//                     <input
//                         name={name}
//                         placeholder={placeholder}
//                         ref={(input) => { this[name] = input; }}
//                         id={name}
//                         maxLength={max}
//                         type={type === "date" ? "text" : type}
//                         style={dynamicStyles.textAlign(lang)}
//                         // readOnly={type === "date" ? "readonly" : false}
//                         autoComplete="no"
//                         onChange={evt =>
//                             this.handleChangeAddForm(name, evt.target.value)
//                         }
//                         onFocus={evt =>
//                             this.handleChangeAddForm(name, evt.target.value)
//                         }
//                         value={studentFormData[name].value}
//                     // className={"validate " + formData[name].class}
//                     // required={isMandatory}
//                     />
//                     <label htmlFor={name} style={dynamicStyles.position(lang)}>
//                         {`${label} `}
//                         {isMandatory ? <span className="asterisk">*</span> : null}
//                     </label>
//                     <span
//                         style={dynamicStyles.textAlign(lang)}
//                         className="helper-text"
//                         data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
//                     ></span>
//                 </div>)
//     }

//     renderRadioButton = ({ data = [], name, label, defaultChecked, isMandatory = false }) => {
//         const { studentFormData } = this.state;
//         const { lang } = this.props;
//         return (
//             <div className="input-field item">
//                 <div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={name}>
//                     {
//                         data.map((item, index) => {
//                             return (
//                                 <div key={index} className={item.value}>
//                                     <input
//                                         aria-checked={studentFormData[name].value === item.value}
//                                         name={name}
//                                         id={item.value}
//                                         aria-labelledby={`${name} ${item.label}`}
//                                         type="radio"
//                                         readOnly
//                                         className="radio-btn"
//                                         onClick={evt =>
//                                             this.handleChangeAddForm(name, item.value)
//                                         }
//                                         checked={studentFormData[name].value === item.value}
//                                     />
//                                     <label id={`_${item.label}`} htmlFor={item.value} className="custom-radio-style">
//                                         <span className="custom-radio-style">{item.label}</span>
//                                     </label>
//                                 </div>
//                             )
//                         })
//                     }

//                 </div>
//                 <label htmlFor={name} style={dynamicStyles.position(lang)}>
//                     {`${label} `}
//                     {isMandatory ? <span className="asterisk">*</span> : null}
//                 </label>
//                 <span
//                     className="helper-text"
//                     data-error="Required field."
//                 ></span>
//             </div>
//         )
//     }
//     validate = (type) => {
//         const { studentFormData } = this.state;
//         for (var key in studentFormData) {
//             studentFormData[key].value = key == "dob" ? studentFormData[key].value : studentFormData[key].value.trim();
//             studentFormData[key].error = "";
//         }
//         if (type == "qid") {
//             if (studentFormData.qid.value == "")
//                 studentFormData.qid.error = this.context.t("_qid_required");

//             if (studentFormData.qid.value != "" && !qidValidate.test(studentFormData.qid.value))
//                 studentFormData.qid.error = this.context.t("_invalid_qid");
//         } else {
//             if (studentFormData.name.value == "")
//                 studentFormData.name.error = this.context.t("_student_name_required");

//             if (studentFormData.name.value != "" && !nameValidate.test(studentFormData.name.value))
//                 studentFormData.name.error = this.context.t("_invalid_student_name");

//             if (studentFormData.name.value != "" && nameValidate.test(studentFormData.name.value) && !nameWordLengthCheck(studentFormData.name.value))
//                 studentFormData.name.error = this.context.t("_student_name_contains_atleast_two_word");

//             if (studentFormData.dob.value == "")
//                 studentFormData.dob.error = this.context.t("_date_of_birth_required");

//             if (studentFormData.email.value != "" && !emailValidate.test(studentFormData.email.value))
//                 studentFormData.email.error = this.context.t("_invalid_email");

//             if (studentFormData.mobile.value == "")
//                 studentFormData.mobile.error = this.context.t("_mobile_number_required");

//             if (studentFormData.mobile.value != "" && !phoneValidate.test(studentFormData.mobile.value.replace(/ /g, "")))
//                 studentFormData.mobile.error = this.context.t("_invalid_mobile_number");

//             if (studentFormData.mobile.value != "")
//                 if (studentFormData.mobile.value.toString().startsWith("3") || studentFormData.mobile.value.toString().startsWith("5") || studentFormData.mobile.value.toString().startsWith("6") || studentFormData.mobile.value.toString().startsWith("7")) {

//                 } else {
//                     studentFormData.mobile.error = this.context.t("_invalid_mobile_number");
//                 }


//             if (studentFormData.schoolName.value == "")
//                 studentFormData.schoolName.error = this.context.t("_school_name_required");

//             if (studentFormData.schoolName.value == "0" && studentFormData.schoolNewName.value == "")
//                 studentFormData.schoolNewName.error = this.context.t("_school_name_required");

//             if (studentFormData.parentQid.value == "")
//                 studentFormData.parentQid.error = this.context.t("_parent_qid_required");

//             if (studentFormData.parentQid.value != "" && !qidValidate.test(studentFormData.parentQid.value))
//                 studentFormData.parentQid.error = this.context.t("_invalid_parent_qid");

//             if (studentFormData.parentName.value == "")
//                 studentFormData.parentName.error = this.context.t("_parent_name_required");

//             if (studentFormData.parentName.value != "" && !nameValidate.test(studentFormData.parentName.value))
//                 studentFormData.parentName.error = this.context.t("_invalid_parent_name");

//             if (studentFormData.parentName.value != "" && nameValidate.test(studentFormData.parentName.value) && !nameWordLengthCheck(studentFormData.parentName.value))
//                 studentFormData.parentName.error = this.context.t("_parent_name_contains_atleast_two_word");


//             if (studentFormData.relation.value == "")
//                 studentFormData.relation.error = this.context.t("_relationship_required");

//             if (studentFormData.parentMobile.value == "")
//                 studentFormData.parentMobile.error = this.context.t("_parent_mobile_number_1_required");

//             if (studentFormData.parentMobile.value != "" && !phoneValidate.test(studentFormData.parentMobile.value.replace(/ /g, "")))
//                 studentFormData.parentMobile.error = this.context.t("_invalid_parent_mobile");

//             if (studentFormData.parentMobile.value != "")
//                 if (studentFormData.parentMobile.value.toString().startsWith("3") || studentFormData.parentMobile.value.toString().startsWith("5") || studentFormData.parentMobile.value.toString().startsWith("6") || studentFormData.parentMobile.value.toString().startsWith("7")) {

//                 } else {
//                     studentFormData.parentMobile.error = this.context.t("_invalid_parent_mobile");
//                 }

//             if (studentFormData.parentMobile2.value == "")
//                 studentFormData.parentMobile2.error = this.context.t("_parent_mobile_number_2_required");

//             if (studentFormData.parentMobile2.value != "" && !phoneValidate.test(studentFormData.parentMobile2.value.replace(/ /g, "")))
//                 studentFormData.parentMobile2.error = this.context.t("_invalid_parent_mobile_2");

//             if (studentFormData.parentMobile2.value != "")
//                 if (studentFormData.parentMobile2.value.toString().startsWith("3") || studentFormData.parentMobile2.value.toString().startsWith("5") || studentFormData.parentMobile2.value.toString().startsWith("6") || studentFormData.parentMobile2.value.toString().startsWith("7")) {

//                 } else {
//                     studentFormData.parentMobile2.error = this.context.t("_invalid_parent_mobile_2");
//                 }

//             if (studentFormData.parentEmail.value == "")
//                 studentFormData.parentEmail.error = this.context.t("_parent_email_required");

//             if (studentFormData.parentEmail.value != "" && !emailValidate.test(studentFormData.parentEmail.value))
//                 studentFormData.parentEmail.error = this.context.t("_invalid_parent_email");

//             if ((studentFormData.parentEmail.value != "" && studentFormData.email.value != "") && studentFormData.parentEmail.value == studentFormData.email.value)
//                 studentFormData.parentEmail.error = this.context.t("_parent_student_email_cannot_be_same");

//             if ((studentFormData.parentQid.value != "" && studentFormData.parentQid.value != "") && studentFormData.parentQid.value == studentFormData.qid.value)
//                 studentFormData.parentQid.error = this.context.t("_parent_student_qid_cannot_be_same");
//         }

//         for (var key in studentFormData) {
//             if (studentFormData[key].error != "") {
//                 this.setState({ studentFormData, submissionStatus: false })
//                 // this.scrollToTop();
//                 setTimeout(() => {
//                     type == "qid" ? this.refs[key].focus() : this[key + "error"].focus()
//                 }, 1000);
//                 return false;

//             }
//         }
//         return true;
//     }
//     validateParent = (type) => {
//         const { parentFormData } = this.state;
//         for (var key in parentFormData) {
//             parentFormData[key].value = parentFormData[key].value.trim();
//             parentFormData[key].error = "";
//         }
//         if (type == "qid") {
//             if (parentFormData.qid.value == "")
//                 parentFormData.qid.error = this.context.t("_qid_required");

//             if (parentFormData.qid.value != "" && !qidValidate.test(parentFormData.qid.value))
//                 parentFormData.qid.error = this.context.t("_invalid_qid");
//         } else {
//             // if (formData.parentName.value == "")
//             //     formData.parentName.error = this.context.t("_parent_name_required");

//             // if (formData.parentName.value != "" && !nameValidate.test(formData.parentName.value))
//             //     formData.parentName.error = this.context.t("_invalid_parent_name");

//             // if (formData.parentName.value != "" && nameValidate.test(formData.parentName.value) && !nameWordLengthCheck(formData.parentName.value))
//             //     formData.parentName.error = this.context.t("_parent_name_contains_atleast_two_word");

//             // if (formData.dob.value == "")
//             //     formData.dob.error = this.context.t("_date_of_birth_required");
//             if (parentFormData.email.value == "")
//                 parentFormData.email.error = this.context.t("_email_required");

//             if (parentFormData.email.value != "" && !emailValidate.test(parentFormData.email.value))
//                 parentFormData.email.error = this.context.t("_invalid_email");

//             // if (formData.mobileNumber.value == "")
//             //     formData.mobileNumber.error = this.context.t("_mobile_number_required");

//             // if (formData.mobileNumber.value != "" && !phoneValidate.test(formData.mobileNumber.value))
//             //     formData.mobileNumber.error = this.context.t("_invalid_mobile_number");
//         }
//         for (var key in parentFormData) {
//             if (parentFormData[key].error != "") {
//                 this.setState({ parentFormData, submissionStatus: false })
//                 // this.scrollToTop();
//                 setTimeout(() => {
//                     this.refs[key].focus()
//                 }, 1000);
//                 return false;

//             }
//         }
//         return true;
//     }
//     displayError = error => {
//         const { studentFormData } = this.state;
//         const { lang } = this.props;
//         if (error.qid)
//             studentFormData.qid.error = error.qid[lang]

//         if (error.name)
//             studentFormData.name.error = error.name[lang]

//         if (error.mail)
//             studentFormData.email.error = error.mail[lang]

//         if (error.DOB)
//             studentFormData.dob.error = error.DOB[lang]


//         for (var key in studentFormData) {
//             if (studentFormData[key].error != "") {
//                 this.setState({ studentFormData })
//                 // this.scrollToTop();
//                 setTimeout(() => {
//                     // this.refs.errorListRef.focus()
//                     this.refs[key].focus()
//                 }, 1000);
//                 return false;

//             }
//         }

//         this.setState({ studentFormData })
//         // this.scrollToTop();

//     }
//     displayParentError = error => {
//         const { parentFormData } = this.state;
//         const { lang } = this.props;
//         if (error.qid)
//             parentFormData.qid.error = error.qid[lang]

//         if (error.name)
//             parentFormData.name.error = error.name[lang]

//         if (error.mail)
//             parentFormData.email.error = error.mail[lang]

//         if (error.DOB)
//             parentFormData.dob.error = error.DOB[lang]

//         for (var key in parentFormData) {
//             if (parentFormData[key].error != "") {
//                 this.setState({ parentFormData })
//                 // this.scrollToTop();
//                 setTimeout(() => {
//                     // this.refs.errorListRef.focus()
//                     this.refs[key].focus()
//                 }, 1000);
//                 return false;

//             }
//         }
//         this.setState({ parentFormData })

//     }
//     handleCheckQid = async (event) => {
//         event.preventDefault();
//         const { studentFormData, config } = this.state;

//         if (this.validate("qid")) {
//             this.props.showLoader()
//             // const authResponse = await this.auth();
//             // if (authResponse) {
//             const obj = {
//                 field_qid: studentFormData.qid.value
//             }
//             const url = `${config.api_url}${urlEndPoints.checkQid}`
//             // const headers = {
//             //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//             // }
//             try {
//                 const data = await this.networkRequest(url, "POST", obj)
//                 if (data.status == 200) {
//                     if (data.data.error) {
//                         this.props.hideLoader()
//                         this.displayError(data.data.error)
//                     } else {
//                         if (!data.data.user) {
//                             this.props.hideLoader()
//                             this.handleOpenAddStudentModal();
//                         }
//                         else {
//                             this.handleCloseModal()
//                             this.getChildren();
//                         }
//                     }
//                 }
//             } catch (error) {
//                 this.props.hideLoader()
//                 console.warn("error: ", error)
//             }
//             // } else {
//             //     this.props.hideLoader()
//             // }
//         }
//     }
//     handleOpenAddStudentModal = () => {
//         const { studentFormData } = this.state;
//         for (var key in studentFormData) {
//             studentFormData[key].error = "";
//         }
//         this.setState({ studentFormData })
//         let elems = document.querySelector("#checkQid");
//         elems.removeAttribute("aria-hidden")
//         elems.removeAttribute("tabindex")
//         let instance = M.Modal.getInstance(elems);
//         instance.close();
//         elems = document.querySelector("#addStudent");
//         elems.setAttribute("aria-hidden", "false")
//         elems.setAttribute("tabindex", "-1")
//         instance = M.Modal.getInstance(elems);
//         instance.open();
//     }
//     handleOpenAddParentModal = () => {
//         const { parentFormData } = this.state;
//         for (var key in parentFormData) {
//             parentFormData[key].error = "";
//         }
//         this.setState({ parentFormData })
//         let elems = document.querySelector("#checkQidForParent");
//         elems.removeAttribute("aria-hidden")
//         elems.removeAttribute("tabindex")
//         let instance = M.Modal.getInstance(elems);
//         instance.close();
//         elems = document.querySelector("#addParent");
//         elems.setAttribute("aria-hidden", "false")
//         elems.setAttribute("tabindex", "-1")
//         instance = M.Modal.getInstance(elems);
//         instance.open();
//     }
//     handleAddNewStudent = async (event) => {
//         event.preventDefault();
//         const { studentFormData, config, schoolList } = this.state;
//         if (this.validate()) {
//             this.props.showLoader()
//             // const authResponse = await this.auth();
//             // if (authResponse) {
//             let obj = {
//                 "name": studentFormData.email.value != "" ? studentFormData.email.value : studentFormData.name.value,
//                 "field_qid": studentFormData.qid.value,
//                 "field_student_mobile": `+974${studentFormData.mobile.value.replace(/ /g, "")}`,
//                 "roles": {
//                     "target_id": "student"
//                 },
//                 "field_gender": studentFormData.gender.value,
//                 "field_student_name": studentFormData.name.value,
//                 "field_student_dob": dateformat(studentFormData.dob.value, "dd/mm/yyyy"),
//                 "field_reference": {
//                     "target_id": ""
//                 },
//                 "field_parent_qid": studentFormData.parentQid.value,
//                 "field_parent_name": studentFormData.parentName.value,
//                 "field_parent_email": studentFormData.parentEmail.value,
//                 "field_parent_mobile_number": `+974${studentFormData.parentMobile.value.replace(/ /g, "")}`,
//                 "field_parent_mobile_number_2": `+974${studentFormData.parentMobile2.value.replace(/ /g, "")}`,
//                 "field_relation_to_the_student": studentFormData.relation.value,
//             }
//             if (studentFormData.email.value != "") {
//                 obj.mail = studentFormData.email.value
//                 obj.field_student_email = studentFormData.email.value
//             }
//             if (!_.isEmpty(studentFormData.schoolName.value)) {
//                 let schoolName = schoolList.filter(item => item.id == studentFormData.schoolName.value)
//                 if (studentFormData.schoolName.value == "0")
//                     schoolName = studentFormData.schoolNewName.value
//                 else
//                     schoolName = _.isEmpty(schoolName) ? null : schoolName[0].sname


//                 obj.school_id = {
//                     "target_id": studentFormData.schoolName.value,
//                     "target_type": "taxonomy_term"
//                 };
//                 obj.school_name = schoolName
//             }
//             const url = `${config.api_url}${urlEndPoints.schoolAddStudent}`
//             // const headers = {
//             //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//             // }
//             try {
//                 const data = await this.networkRequest(url, "POST", obj)
//                 if (data.data.error) {
//                     this.displayError(data.data.error)
//                     this.props.hideLoader()
//                 }
//                 else {
//                     this.setState({ submissionStatus: true })
//                     this.handleCloseModal()
//                     this.getChildren();
//                 }
//             } catch (error) {
//                 this.props.hideLoader()
//                 console.warn("error: ", error)
//             }
//             // } else {
//             //     this.props.hideLoader()
//             // }
//         }

//     }

//     handleRemove = async id => {
//         this.props.showLoader()
//         this.handleCloseConfirmModal("removeStudentConfirm")
//         const { config, checkedList } = this.state;
//         // const authResponse = await this.auth();
//         // if (authResponse) {
//         const user_ids = Object.keys(checkedList).map((key) => key)
//         let obj = {
//             user_ids
//         }
//         const url = `${config.api_url}${urlEndPoints.removeLinking}`
//         // const headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         try {
//             const data = await this.networkRequest(url, "POST", obj)
//             if (data.data.error) {
//                 this.props.hideLoader()
//                 this.props.showPopup({
//                     type: "error",
//                     title: this.context.t("_failed"),
//                     message: this.context.t("_student_unlink_failed"),
//                     isOpen: true,
//                     buttonText: this.context.t("_ok")
//                 })
//             }
//             else {
//                 this.getChildren()
//             }
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//         // } else {
//         //     this.props.hideLoader()
//         // }
//     }

//     handleOpenQidCheck = () => {
//         const { studentFormData } = this.state;
//         for (var key in studentFormData) {
//             studentFormData[key].error = "";
//         }
//         this.setState({ studentFormData })
//         let options = {
//             inDuration: 1000,
//             opacity: 1
//         }
//         let elems = document.querySelector("#checkQid");
//         elems.setAttribute("aria-hidden", "false")
//         elems.setAttribute("tabindex", "-1")
//         let instance = M.Modal.getInstance(elems, options);
//         instance.open();
//     }
//     handleOpenQidCheckParent = (id) => {

//         const { parentFormData } = this.state;
//         for (var key in parentFormData) {
//             parentFormData[key].error = "";
//         }
//         this.setState({ parentFormData, selectedStudent: id })
//         let options = {
//             inDuration: 1000,
//             opacity: 1
//         }
//         let elems = document.querySelector("#checkQidForParent");
//         elems.setAttribute("aria-hidden", "false")
//         elems.setAttribute("tabindex", "-1")
//         let instance = M.Modal.getInstance(elems, options);
//         instance.open();
//     }
//     clearSearch = async () => {
//         this.props.showLoader()
//         const { formData } = this.state;
//         formData.name.value = ""
//         formData.grade.value = ""
//         formData.gender.value = ""
//         formData.age.value = ""

//         await this.setState({ formData })
//         this.getChildren()
//     }
//     handleGetParent = async id => {
//         const { config, studentList } = this.state
//         studentList.map(async (item, index) => {
//             let url = `${config.api_url}${urlEndPoints.getParentName(item.uid)}`
//             try {
//                 const data = await this.networkRequest(url)
//                 if (_.has(data, "data.results") && data.data.results.length > 0)
//                     studentList[index].parents = data.data.results
//                 else
//                     studentList[index].parents = []

//                 this.setState({ studentList })
//             } catch (error) {
//                 this.props.hideLoader()
//                 console.warn("error: ", error)
//             }
//         });
//     }
//     handleCheckParentQid = async (event) => {
//         event.preventDefault();
//         const { parentFormData, config, selectedStudent } = this.state;

//         if (this.validateParent("qid")) {
//             this.props.showLoader()
//             // const authResponse = await this.auth();
//             // if (authResponse) {
//             const obj = {
//                 field_qid: parentFormData.qid.value,
//                 id: selectedStudent
//             }
//             const url = `${config.api_url}${urlEndPoints.checkQidAfterProfileView}`
//             // const headers = {
//             //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//             // }
//             try {
//                 const data = await this.networkRequest(url, "POST", obj)
//                 if (data.status == 200) {
//                     if (data.data.error) {
//                         this.props.hideLoader()
//                         this.displayParentError(data.data.error)
//                     } else {
//                         if (!data.data.user) {
//                             this.props.hideLoader()
//                             this.handleOpenAddParentModal();
//                         }
//                         else {
//                             this.handleCloseModal()
//                             this.getChildren();
//                         }
//                     }
//                 }
//             } catch (error) {
//                 this.props.hideLoader()
//                 console.warn("error: ", error)
//             }
//             // } else {
//             //     this.props.hideLoader()
//             // }
//         }
//     }
//     handleAddNewParent = async (event) => {
//         event.preventDefault();
//         const { parentFormData, config } = this.state;
//         const { lang } = this.props
//         if (this.validateParent()) {
//             this.props.showLoader()
//             // const authResponse = await this.auth();
//             // if (authResponse) {
//             let obj = {
//                 email: parentFormData.email.value
//             }
//             const url = `${config.api_url}${urlEndPoints.sendNotification}`
//             // const headers = {
//             //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//             // }
//             try {
//                 const data = await this.networkRequest(url, "POST", obj)
//                 if (data.data.error) {
//                     this.displayError(data.data.error)
//                     this.props.hideLoader()
//                 }
//                 else {
//                     this.props.showPopup({
//                         type: "success",
//                         title: this.context.t("_success"),
//                         message: data.data.message[lang],
//                         isOpen: true,
//                         buttonText: this.context.t("_ok")
//                     })
//                     this.handleCloseModal();
//                     this.getChildren();
//                     // this.setState({ submissionStatus: true, parentList });
//                     // this.props.hideLoader();
//                 }
//             } catch (error) {
//                 this.props.hideLoader()
//                 console.warn("error: ", error)
//             }
//             // } else {
//             //     this.props.hideLoader()
//             // }
//         }
//     }
//     handleCloseConfirmModal = (state) => {
//         this.setState({ [state]: false, removeStudentId: null })
//     }
//     handleConfirmRemoveStudent = () => {
//         const { removeStudentId } = this.state;
//         if (!_.isEmpty(removeStudentId))
//             this.handleRemove(removeStudentId);
//         this.handleCloseConfirmModal("removeStudentConfirm")
//     }
//     handleOpenRemoveSchoolConfirm = () => {
//         this.setState({ removeStudentConfirm: true, })
//     }
//     handleUploadExcel = async (event) => {
//         event.preventDefault();
//     }
//     onChangeExcel(event) {
//         event.stopPropagation();
//         event.preventDefault();
//         var file = event.target.files[0];
//         var name = file.name;
//         const reader = new FileReader();
//         reader.onload = (evt) => {
//             const bstr = evt.target.result;
//             const wb = XLSX.read(bstr, { type: "binary" });
//             const wsname = wb.SheetNames[0];
//             const ws = wb.Sheets[wsname];
//             const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
//             console.log(this.convertToJson(data));
//         };
//         reader.readAsBinaryString(file);
//     }
//     convertToJson(csv) {
//         var lines = csv.split("\n");

//         var result = [];

//         var headers = lines[0].split(",");

//         for (var i = 1; i < lines.length; i++) {
//             var obj = {};
//             var currentline = lines[i].split(",");

//             for (var j = 0; j < headers.length; j++) {
//                 obj[headers[j]] = currentline[j];
//             }

//             result.push(obj);
//         }
//         return JSON.stringify(result); //JSON
//     }
//     handleCheck = (event, id) => {
//         let { checkedList, checkAll } = this.state
//         if (event.target.checked)
//             checkedList[id] = true
//         else {
//             delete checkedList[id];
//             checkAll = false;
//         }
//         this.setState({ checkedList, checkAll })

//     }
//     handleCheckAll = (event) => {
//         const checkedList = {}
//         let checkAll = false;
//         const { studentList } = this.state;
//         if (event.target.checked) {
//             studentList.forEach(data => {
//                 checkedList[data.uid] = true
//             });
//             checkAll = true
//         }
//         this.setState({ checkedList, checkAll })

//     }
//     render() {
//         const { formData, studentList, studentFormData, parentFormData, removeStudentConfirm, schoolList, checkedList, checkAll, mySchool } = this.state;
//         const { lang } = this.props;
//         let hasError = false;
//         Object.keys(studentFormData).map((item, index) => {
//             if (studentFormData[item].error != "")
//                 hasError = true
//         })
//         Object.keys(parentFormData).map((item, index) => {
//             if (parentFormData[item].error != "")
//                 hasError = true
//         })
//         return (
//             <>
//                 <div id="main-container">
//                     <SlideToTop />
//                     <PageTitle title={this.context.t("_students")} />
//                     <div id="skipContent" className="container">
//                         <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
//                             <ul>
//                                 <li className="breadcrumb-item">
//                                     <Link to="/profile">{this.context.t("_profile")}</Link> {" "}

//                                 </li>
//                                 <li className="breadcrumb-item">
//                                     <Link tabIndex="0" to aria-current={this.context.t("_page")}>
//                                         <span>
//                                             {this.context.t("_students")}
//                                         </span>
//                                     </Link>
//                                 </li>
//                             </ul>
//                         </nav>
//                     </div>
//                     <div>
//                         <div className="container">
//                             <h2>{this.context.t("_students")}</h2>
//                             {
//                                 _.isEmpty(formData) ?
//                                     <div /> :
//                                     <div className="row topFormHolder">
//                                         <form onSubmit={this.onSubmit} className="row full-width">
//                                             <div className="col s12 m3 l3">
//                                                 <div className="input-field item">
//                                                     <label htmlFor="activityName" className="active" style={dynamicStyles.position(lang)}>{this.context.t("_search")} <span className="asterisk">*</span></label>
//                                                     <input
//                                                         onChange={evt =>
//                                                             this.handleChange("name", evt.target.value)
//                                                         }
//                                                         onFocus={evt =>
//                                                             this.handleChange("name", evt.target.value)
//                                                         }
//                                                         id="activityName"
//                                                         name="activityName"
//                                                         ref={(input) => { this.parentName = input; }}
//                                                         value={formData.name.value}
//                                                         placeholder={this.context.t("_search")}
//                                                         type="text"
//                                                     />
//                                                     <span className="helper-text"></span>
//                                                 </div>
//                                             </div>
//                                             <div className="col s12 m2 l">
//                                                 <div className="input-field item">
//                                                     <label style={dynamicStyles.position(lang)}>{this.context.t("_gender")}</label>
//                                                     <select
//                                                         className="browser-default"
//                                                         id="gender"
//                                                         name="gender"
//                                                         value={formData.gender.value}
//                                                         onChange={evt => this.handleChange("gender", evt.target.value)}
//                                                         onFocus={evt => this.handleChange("gender", evt.target.value)}
//                                                     >
//                                                         <option value="" disabled>{this.context.t("_select_gender")}</option>
//                                                         <option value="Male">{this.context.t("_male")}</option>
//                                                         <option value="Female">{this.context.t("_female")}</option>
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                             <div className="col s12 m2 l2">
//                                                 <div className="input-field item">
//                                                     <label style={dynamicStyles.position(lang)}>{this.context.t("_age_group")}</label>
//                                                     <select
//                                                         className="browser-default"
//                                                         id="age"
//                                                         name="age"
//                                                         value={formData.age.value}
//                                                         onChange={evt => this.handleChange("age", evt.target.value)}
//                                                         onFocus={evt => this.handleChange("age", evt.target.value)}
//                                                     >
//                                                         <option value="" disabled>{this.context.t("_select_age_group")}</option>
//                                                         <option value="one_to_ten">{`1 ${this.context.t("_to")} 10`}</option>
//                                                         <option value="eleven_to_twenty">{`11 ${this.context.t("_to")} 20`}</option>
//                                                         <option value="twentyone_to_thirty">{`21 ${this.context.t("_to")} 30`}</option>
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                             <div className="col s12 m2 l2">
//                                                 <div className="input-field item">
//                                                     <label style={dynamicStyles.position(lang)}>{this.context.t("_grade")}</label>

//                                                     <select
//                                                         className="browser-default"
//                                                         onChange={evt =>
//                                                             this.handleChange("grade", evt.target.value)
//                                                         }
//                                                         onFocus={evt =>
//                                                             this.handleChange("grade", evt.target.value)
//                                                         }
//                                                         ref={(input) => { this.grade = input; }}
//                                                         value={formData.grade.value}
//                                                     >
//                                                         <option value="" disabled >{this.context.t("_select_grade")}</option>
//                                                         {
//                                                             gradeListNew[lang].map((item, index) => {
//                                                                 return <option key={index} value={item.value}>{item.label}</option>
//                                                             })
//                                                         }
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                             <div className="col s12 m3 l3 valign-wrapper center-align search-btn-holder">
//                                                 <button className="btn profileBtn yellow waves-effect waves-light">
//                                                     {this.context.t("_search")} <i className={`tiny material-icons ${lang}`} >{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
//                                                 </button>
//                                                 <button type="button" onClick={() => this.clearSearch()} className="btn noWidth  blue-btn waves-effect waves-light">
//                                                     {this.context.t("_clear")}
//                                                 </button>
//                                                 <br /><br />
//                                             </div>
//                                         </form>
//                                     </div>
//                             }

//                             <div className="tableHolder">
//                                 {
//                                     _.isEmpty(studentList) ?
//                                         <div className="row"><p aria-label={this.context.t("_no_result_found")} className="col s12 no-result">{this.context.t("_no_result_found")}</p></div>
//                                         :
//                                         <table className="responsive-table highlight">
//                                             <thead className="blue lighten-5">
//                                                 <tr>
//                                                     <th><strong>{this.context.t("_name")}</strong></th>
//                                                     <th><strong>{this.context.t("_qid")}</strong></th>
//                                                     <th><strong>{this.context.t("_phone_number")}</strong></th>
//                                                     <th><strong>{this.context.t("_age")}</strong></th>
//                                                     <th><strong>{this.context.t("_grade")}</strong></th>
//                                                     <th><strong>{this.context.t("_gender")}</strong></th>
//                                                     {/* <th><strong>{this.context.t("_status")}</strong></th> */}
//                                                     <th><strong>{this.context.t("_parents")}</strong></th>
//                                                     <th width="140"><p className="checkboxHolder checkboxNew"><input id="selectAll" onChange={(e) => this.handleCheckAll(e)} checked={checkAll} type="checkbox" aria-label={this.context.t("_select_all_for_remove_connection")} /><label htmlFor="selectAll"><span style={{ fontSize: 16, margin: "0 7px", textTransform: "capitalize" }}>{this.context.t("_select_all")}</span></label></p></th>
//                                                 </tr>
//                                             </thead>

//                                             <tbody>

//                                                 {
//                                                     studentList.map((item, index) => {
//                                                         return (
//                                                             <tr key={item.uid}>
//                                                                 <td>{item.field_student_name}</td>
//                                                                 <td>{item.field_qid}</td>
//                                                                 <td>{item.field_student_mobile}</td>
//                                                                 <td>{this.calculateAge(item.field_student_dob)}</td>
//                                                                 <td>{handleGradeName(item.field_grade, this.props.lang)}</td>
//                                                                 <td>{getGender(item.field_gender, this.context)}</td>
//                                                                 {/* <td>{item.status}</td> */}
//                                                                 <td>
//                                                                     {item.parents ?
//                                                                         item.parents.length > 1 ? item.parents.map(itemParent => {
//                                                                             return <span key={itemParent.uid}>{itemParent.field_parent_name}<br /></span>
//                                                                         }) :
//                                                                             <button aria-label={`${this.context.t("_add_guardian_for")} ${item.field_student_name}`} onClick={() => this.handleOpenQidCheckParent(item.uid)} className="btn-flat blue-text"><strong>{this.context.t("_add")}</strong></button>
//                                                                         : null
//                                                                     }
//                                                                 </td>
//                                                                 {/* <td><button onClick={() => { this.handleViewProfile(item.uid) }} className="btn profileBtn">{this.context.t("_view_profile")}</button></td> */}
//                                                                 {/* <td><button onClick={() => { this.handleOpenRemoveSchoolConfirm(item.uid) }} className="btn profileBtn">{this.context.t("_remove")}</button></td> */}
//                                                                 <td><p className="checkboxHolder checkboxNew"><input id={`enroll${index}`} aria-label={`${item.field_student_name} ${this.context.t("_selected_for_remove_connection")}`}
//                                                                     checked={checkedList[item.uid] ? true : false}
//                                                                     onChange={(e) => this.handleCheck(e, item.uid)} type="checkbox" /><label htmlFor={`enroll${index}`}><span style={{ display: "none" }}>{this.context.t("_select")}</span></label></p></td>
//                                                             </tr>
//                                                         )
//                                                     })
//                                                 }
//                                             </tbody>
//                                         </table>
//                                 }
//                             </div>
//                             <button className={`waves-effect waves-light btn profileBtn`}
//                                 onClick={() => {
//                                     this.handleOpenQidCheck();
//                                     this.getMySchool();
//                                 }}>{this.context.t("_add_student")}</button>
//                             <button className={`waves-effect waves-light btn profileBtn pull-${lang == "ar" ? "left" : "right"}`} onClick={() => { this.handleOpenRemoveSchoolConfirm() }}>{this.context.t("_remove")}</button>
//                             {/* <div className="file-upload-container">
//                             <h4>{this.context.t("_import_students")}</h4>
//                             <form onSubmit={this.handleUploadExcel} className="row full-width">
//                                 <div className="col s6">
//                                     <div className="input-field item">
//                                         <input
//                                             type="file"
//                                             onChange={this.onChangeExcel.bind(this)}
//                                         />
//                                         <label style={dynamicStyles.position(lang)}>{this.context.t("_choose_file")} <span className="asterisk">*</span></label>
//                                     </div>
//                                 </div>

//                                 <div className="col s2">
//                                     <button type="submit" className="btn noWidth  blue-btn waves-effect waves-light" style={{ marginTop: 26 }}>{this.context.t("_upload")}</button>
//                                 </div>
//                                 <div className="col s4" />
//                             </form>
//                         </div> */}


//                             <div id="checkQid" className="modal sm" role="dialog">
//                                 <div className="modal-content modal-no-close">
//                                     {
//                                         _.isEmpty(studentFormData) ?
//                                             <div /> :
//                                             <div>
//                                                 <span className="badge modalClose"><button className="close" aria-label="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
//                                                 <h2 className="line">{this.context.t("_add_student")}</h2>
//                                                 {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit.</p> */}
//                                                 {hasError ? <div className="error-container fail">
//                                                     <h5>{this.context.t("_error_title")}</h5>
//                                                     <ul>
//                                                         {studentFormData.qid.error != "" ? <li ><a ref="qid" tabIndex="0" href="#qid" className="error" htmlFor="qid">{studentFormData.qid.error}</a></li> : null}
//                                                     </ul>
//                                                 </div> : null
//                                                 }
//                                                 <form onSubmit={this.handleCheckQid} className="row full-width">
//                                                     <div className="col s12">
//                                                         <div className="input-field item">
//                                                             <input
//                                                                 placeholder={this.context.t("_type_child_qid")}
//                                                                 type="text"
//                                                                 name="qid"
//                                                                 id="qid"
//                                                                 onChange={(evt) => this.handleChangeAddForm("qid", evt.target.value)}
//                                                                 onFocus={(evt) => this.handleChangeAddForm("qid", evt.target.value)}
//                                                                 value={studentFormData.qid.value}
//                                                                 maxLength={11}
//                                                             />
//                                                             <label htmlFor="qid" style={dynamicStyles.position(lang)}>{this.context.t("_student_qid")} <span className="asterisk">*</span></label>
//                                                         </div>
//                                                     </div>

//                                                     <div className="col s12">
//                                                         <button type="button" onClick={this.handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{this.context.t("_cancel")}</button>
//                                                         <button className="btn profileBtn blue noWidth waves-effect waves-light">{this.context.t("_add")}</button>
//                                                     </div>
//                                                 </form>
//                                             </div>
//                                     }
//                                 </div>
//                             </div>
//                             <div id="addStudent" className="modal modal-full-height" role="dialog">
//                                 <div className="modal-content modal-no-close">
//                                     {
//                                         _.isEmpty(studentFormData) ?
//                                             <div /> :
//                                             <form onSubmit={this.handleAddNewStudent} className="col full-width">
//                                                 <span className="badge modalClose"><a onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></a></span>
//                                                 <h2 className="line">{this.context.t("_add_student")}</h2>

//                                                 {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p> */}
//                                                 {hasError ? <div className="error-container fail">
//                                                     <h5>{this.context.t("_error_title")}</h5>
//                                                     <ul>
//                                                         {
//                                                             Object.keys(studentFormData).map((item, index) => {
//                                                                 return studentFormData[item].error != "" ? <li key={index} ><a ref={(refItem) => { this[item + "error"] = refItem; }} tabIndex="0" href={`#${item}`} className="error" htmlFor={item}>{studentFormData[item].error}</a></li>
//                                                                     :
//                                                                     null
//                                                             })}
//                                                     </ul>
//                                                 </div> : null
//                                                 }
//                                                 <p>{this.context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

//                                                 <div className="row">
//                                                     <div className="col s12 m6">
//                                                         {this.renderInput({ name: "name", label: this.context.t("_student_name"), placeholder: this.context.t("_student_name_placeholder"), isMandatory: true })}
//                                                         {this.renderInput({ name: "email", label: this.context.t("_email"), placeholder: this.context.t("_email_placeholder"), type: "email", isMandatory: true })}

//                                                         {
//                                                             this.renderRadioButton({ label: this.context.t("_gender"), isMandatory: true, name: "gender", defaultChecked: "male", data: [{ label: this.context.t("_male"), value: "Male" }, { label: this.context.t("_female"), value: "Female" }] })
//                                                         }

//                                                     </div>
//                                                     <div className="col s12 m6">
//                                                         <div className="date-popup">
//                                                             {this.renderInput({ name: "dob", label: this.context.t("_dob"), placeholder: this.context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
//                                                         </div>

//                                                         {this.renderInput({ name: "mobile", label: this.context.t("_mobile_number"), placeholder: this.context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max: 8 })}

//                                                         <div className="result-type">
//                                                             {
//                                                                 this.renderSelect({
//                                                                     name: "schoolName", label: this.context.t("_school_name"), placeholder: this.context.t("_select_school_name"), data: schoolList, type: "school", isMandatory: true, disabled: true
//                                                                 })
//                                                             }
//                                                         </div>
//                                                         {
//                                                             studentFormData.schoolName.value == "0" && this.renderInput({ name: "schoolNewName", label: this.context.t("_school_name"), placeholder: this.context.t("_select_school_name"), isMandatory: true })
//                                                         }
//                                                     </div>
//                                                     <div className="col s12">
//                                                         <p><br />{this.context.t("_guardian_information")} </p>
//                                                     </div>

//                                                     <div className="col s12 m6">
//                                                         {this.renderInput({ name: "parentQid", label: this.context.t("_patent_qid"), placeholder: this.context.t("_patent_qid_placeholder"), isMandatory: true, max: 11 })}
//                                                         {this.renderInput({ name: "parentName", label: this.context.t("_parent_name"), placeholder: this.context.t("_parent_name_placeholder"), isMandatory: true })}
//                                                         {this.renderInput({ name: "parentEmail", label: this.context.t("_parent_email"), placeholder: this.context.t("_parent_email_placeholder"), isMandatory: true, type: "email", styleClass: "fontEnglish" })}
//                                                     </div>
//                                                     <div className="col s12 m6">

//                                                         {this.renderInput({ name: "parentMobile", label: this.context.t("_parent_mobile"), placeholder: this.context.t("_parent_mobile_placeholder"), type: "phone_number", isMandatory: true, max: 8 })}
//                                                         {this.renderInput({ name: "parentMobile2", label: this.context.t("_parent_mobile_2"), placeholder: this.context.t("_parent_mobile_placeholder_2"), type: "phone_number", isMandatory: true, max: 8 })}

//                                                         <div className="result-type1">
//                                                             {
//                                                                 this.renderSelect({
//                                                                     name: "relation", label: this.context.t("_relation"), placeholder: this.context.t("_select_relation"), isMandatory: true, data: [
//                                                                         { label: this.context.t("_father"), value: "Father" },
//                                                                         { label: this.context.t("_mother"), value: "Mother" },
//                                                                         { label: this.context.t("_sister"), value: "Sister" },
//                                                                         { label: this.context.t("_brother"), value: "Brother" },
//                                                                         { label: this.context.t("_grand_mother"), value: "Grandmother" },
//                                                                         { label: this.context.t("_grand_father"), value: "Grandfather" },
//                                                                         { label: this.context.t("_parent"), value: "Guardian" }
//                                                                     ]
//                                                                 })
//                                                             }
//                                                         </div>

//                                                     </div>
//                                                     <div className="col s12">
//                                                         <button className="btn profileBtn blue waves-effect waves-light">{this.context.t("_save_changes")}</button>
//                                                         <button type="button" onClick={() => this.handleCloseModal()} className="waves-effect waves-light btn profileBtn">{this.context.t("_close")}</button>
//                                                     </div>
//                                                     <div className="clearfix" />
//                                                 </div>
//                                             </form>
//                                     }
//                                 </div>
//                             </div>

//                             <div id="checkQidForParent" className="modal sm" role="dialog">
//                                 <div className="modal-content modal-no-close">
//                                     {
//                                         _.isEmpty(parentFormData) ?
//                                             <div /> :
//                                             <div>
//                                                 <span className="badge modalClose"><a onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></a></span>
//                                                 <h2 className="line">{this.context.t("_add_guardian")}</h2>
//                                                 {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit.</p> */}
//                                                 {hasError ? <div className="error-container fail">
//                                                     <h5>{this.context.t("_error_title")}</h5>
//                                                     <ul>
//                                                         {parentFormData.qid.error != "" ? <li ><a ref="qid" tabIndex="0" href="#parentId" className="error" htmlFor="parentId">{parentFormData.qid.error}</a></li> : null}
//                                                     </ul>
//                                                 </div> : null
//                                                 }
//                                                 <form onSubmit={this.handleCheckParentQid} className="row full-width">
//                                                     <div className="col s12">
//                                                         <div className="input-field item">
//                                                             <input
//                                                                 placeholder={this.context.t("_parent_id_placeholder")}
//                                                                 type="text"
//                                                                 name="parentId"
//                                                                 id="parentId"
//                                                                 maxLength={11}
//                                                                 onChange={(evt) => this.handleChangeParentForm("qid", evt.target.value, "parentFormData")}
//                                                                 onFocus={(evt) => this.handleChangeParentForm("qid", evt.target.value, "parentFormData")}
//                                                                 value={parentFormData.qid.value}
//                                                             />
//                                                             <label htmlFor="parentId" style={dynamicStyles.position(lang)}>{this.context.t("_parent_id")} <span className="asterisk">*</span></label>
//                                                         </div>
//                                                     </div>

//                                                     <div className="col s12">
//                                                         <button type="button" onClick={this.handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{this.context.t("_cancel")}</button>
//                                                         <button className="btn profileBtn blue noWidth waves-effect waves-light">{this.context.t("_add")}</button>
//                                                     </div>
//                                                 </form>
//                                             </div>
//                                     }
//                                 </div>
//                             </div>
//                             <div id="addParent" className="modal sm user-add-modal" role="dialog">
//                                 <div className="modal-content modal-no-close">
//                                     {
//                                         _.isEmpty(parentFormData) ?
//                                             <div /> :
//                                             <form onSubmit={this.handleAddNewParent} className="col full-width">
//                                                 <span className="badge modalClose"><a onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></a></span>
//                                                 <h2 className="line">{this.context.t("_add_guardian")}</h2>

//                                                 {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p> */}
//                                                 {hasError ? <div className="error-container fail">
//                                                     <h5>{this.context.t("_error_title")}</h5>
//                                                     <ul>
//                                                         {parentFormData.email.error != "" ? <li ><a ref="email" tabIndex="0" href="#email_address" className="error" htmlFor="email_address">{parentFormData.email.error}</a></li> : null}

//                                                     </ul>
//                                                 </div> : null
//                                                 }
//                                                 <p>{this.context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

//                                                 <div className="row">
//                                                     <div className="col s12">
//                                                         {/* {this.renderInput({ name: "parentName", label: this.context.t("_parent_name"), placeholder: this.context.t("_name_placeholder"), isMandatory: true })} */}
//                                                         <div className="input-field item">
//                                                             <input
//                                                                 placeholder={this.context.t("_parent_email_address_placeholder")}
//                                                                 type="text"
//                                                                 name="email_address"
//                                                                 id="email_address"
//                                                                 onChange={(evt) => this.handleChangeParentForm("email", evt.target.value, "parentFormData")}
//                                                                 onFocus={(evt) => this.handleChangeParentForm("email", evt.target.value, "parentFormData")}
//                                                                 value={parentFormData.email.value}
//                                                             />
//                                                             <label htmlFor="email_address" style={dynamicStyles.position(lang)}>{this.context.t("_parent_email_address")} <span className="asterisk">*</span></label>
//                                                         </div>
//                                                         {/* {this.renderInput({ name: "email", label: this.context.t("_parent_email_address"), placeholder: this.context.t("_parent_email_address_placeholder"), type: "email", onchange = this.handleChangeParentForm("email", evt.target.value, "parentFormData") })} */}

//                                                     </div>
//                                                     {/* <div className="col s12 m6">
//                                                     <div className="date-popup">
//                                                         {this.renderInput({ name: "dob", label: this.context.t("_dob"), placeholder: this.context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
//                                                     </div>

//                                                     {this.renderInput({ name: "mobileNumber", label: this.context.t("_parent_mobile_number"), placeholder: this.context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max:8 })}
//                                                 </div> */}
//                                                     <div className="col s12">
//                                                         <button className="btn profileBtn blue waves-effect waves-light">{this.context.t("_add")}</button>
//                                                         <button type="button" onClick={this.handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{this.context.t("_cancel")}</button>
//                                                     </div>
//                                                     <div className="clearfix" />
//                                                 </div>
//                                             </form>
//                                     }
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <Modal
//                         isOpen={removeStudentConfirm}
//                         style={customModalStyles}
//                         contentLabel="unlink parent confirm modal"
//                         ariaHideApp={false}
//                     >
//                         <div className="popupAlert warning">
//                             <br />
//                             <h3>{this.context.t("_confirm")}</h3>
//                             <p>{this.context.t("_remove_student_confirm")}</p>
//                             <div className="row">
//                                 <button className="col btn blue" onClick={() => this.handleCloseConfirmModal("removeStudentConfirm")}>{this.context.t("_cancel")}</button>
//                                 <button className="col btn profileBtn yellow" onClick={() => this.handleRemove()}>{this.context.t("_continue")}</button>
//                             </div>
//                         </div>
//                     </Modal>

//                 </div>
//                 <div className="top-line ng-scope">
//                     <img src={topLineImage} alt="" />
//                 </div>
//             </>
//         )
//     }

// }

// const mapStateToProps = ({ i18nState }) => {
//     return {
//         lang: i18nState.lang
//     }
// }
// const mapDispatchToProps = {
//     showPopup: (data) => PopupAction.showPopup(data),
//     hidePopup: (data) => PopupAction.hidePopup(data),
//     showLoader: LoadingAction.showLoader,
//     hideLoader: LoadingAction.hideLoader,
// }
// SchoolConnections.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(SchoolConnections);





import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import _, { forEach } from "lodash"
import Main from "../../../constants/utilities.js";
import PageTitle from "../../includes/page_title.js";
import SlideToTop from "../../includes/slide_to_top.js";
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { dynamicStyles, customModalStyles } from "./../../../constants/dynamicStyles"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import { qidValidate, nameValidate, nameWordLengthCheck, emailValidate, phoneValidate, calenderI18n, gradeListNew, CustomDateInput, calenderLocal } from "./../../../constants/common"
import M from "materialize-css";
import { PopupAction, LoadingAction } from "./../../../actions"
import dateformat from "dateformat"
import * as XLSX from "xlsx";

import Modal from "react-modal";
import topLineImage from "../../../assets/images/top-line.png"
import { handleGradeName, getGender } from "./../../dashboard/pages/utilFunctions"
import { useEffect } from "react";

const initialFormData = {
    name: { value: "", error: "", class: "" },
    grade: { value: "", error: "", class: "" },
    gender: { value: "", error: "", class: "" },
    age: { value: "", error: "", class: "" }
}

const initialStudentFormData = {
    name: { value: "", error: "", class: "" },
    qid: { value: "", error: "", class: "" },
    email: { value: "", error: "", class: "" },
    gender: { value: "Male", error: "", class: "" },
    mobile: { value: "", error: "", class: "" },
    dob: { value: "", error: "", class: "" },
    schoolName: { value: "", error: "", class: "" },
    schoolNewName: { value: "", error: "", class: "" },
    parentName: { value: "", error: "", class: "" },
    parentNameArabic: { value: "", error: "", class: "" },
    parentMobile: { value: "", error: "", class: "" },
    parentMobile2: { value: "", error: "", class: "" },
    parentEmail: { value: "", error: "", class: "" },
    parentQid: { value: "", error: "", class: "" },
    relation: { value: "", error: "", class: "" },
}

const initialParentFormData = {
    parentName: { value: "", error: "", class: "" },
    qid: { value: "", error: "", class: "" },
    dob: { value: "", error: "", class: "" },
    email: { value: "", error: "", class: "" },
    mobileNumber: { value: "", error: "", class: "" },
}

const SchoolConnections = (props,context) => {
    const { config, networkRequest } = Main();
    const [formData, setFormData] = useState(initialFormData);
    const [studentFormData, setStudentFormData] = useState(initialStudentFormData);
    const [parentFormData, setParentFormData] = useState(initialParentFormData);
    const [studentList, setStudentList] = useState([]);
    const [intervalId, setIntervalId] = useState(0);
    const [scrollStepInPx, setScrollStepInPx] = useState(50);
    const [delayInMs, setDelayInMs] = useState(16.66);
    const [isParentExist, setIsParentExist] = useState(true);
    const [selectedStudent, setSelectedStudent] = useState("");
    const [removeStudentConfirm, setRemoveStudentConfirm] = useState(false);
    const [removeStudentId, setRemoveStudentId] = useState(null);
    const [schoolList, setSchoolList] = useState([]);
    const [checkedList, setCheckedList] = useState({});
    const [checkAll, setCheckAll] = useState(false);
    const [mySchool, setMySchool] = useState(null);
    const [submissionStatus, setSubmissionStatus] = useState(false);
    const configuration = config()
    const { lang } = useSelector((state) => state.i18nState)
    const dispatch = useDispatch()
    const navigate = useNavigate()
 
    useEffect(() => {
        // dispatch(LoadingAction.showLoader())
        document.title = `${context.t("_studio56")} | ${context.t("_settings")}`;

        const fetchData = async () => {
            await initState();
            getSchoolList();
            await getChildren();
            getMySchool();
            M.AutoInit();
            var options = {
                autoClose: true,
                dismissible: true
            };
            const dobOptions = {
                autoClose: true,
                onSelect: ((date) => {
                    handleChangeAddForm("dob", dateformat(date, "dd/mm/yyyy"))
                }),
                defaultDate: new Date("01/01/2001"),
                setDefaultDate: new Date("01/01/2001"),
                format: "dd/mm/yyyy",
                container: null,
                maxDate: new Date("12/31/2014"),
                minDate: new Date("01/01/2001"),
                yearRange: 20,
                minYear: 2001,
                maxYear: 2014
            };
            setTimeout(() => {
                var elems = document.querySelectorAll("#dob");
                dobOptions.i18n = calenderI18n(context);
                // M.Datepicker.init(elems, dobOptions);
                var elems = document.querySelectorAll(".modal");
                M.Modal.init(elems, options);
                const selectelem = document.querySelectorAll("select");
                M.FormSelect.init(selectelem, options);

            }, 200);
            window.addEventListener('load', handleTabIndex());
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        };
        fetchData();
    }, []);



    const handleTabIndex = () => {
        setTimeout(() => {
            let elem = document.querySelectorAll('select');
            elem.forEach(element => {
                element.setAttribute("tabindex", "0");
            });
        }, 1000);
    }
    const initState = () => {
        setFormData({
            name: { value: "", error: "", class: "" },
            grade: { value: "", error: "", class: "" },
            gender: { value: "", error: "", class: "" },
            age: { value: "", error: "", class: "" }
        })
        setStudentFormData({
            name: { value: "", error: "", class: "" },
            qid: { value: "", error: "", class: "" },
            email: { value: "", error: "", class: "" },
            gender: { value: "Male", error: "", class: "" },
            mobile: { value: "", error: "", class: "" },
            dob: { value: "", error: "", class: "" },
            schoolName: { value: "", error: "", class: "" },
            schoolNewName: { value: "", error: "", class: "" },
            parentName: { value: "", error: "", class: "" },
            parentNameArabic: { value: "", error: "", class: "" },
            parentMobile: { value: "", error: "", class: "" },
            parentMobile2: { value: "", error: "", class: "" },
            parentEmail: { value: "", error: "", class: "" },
            parentQid: { value: "", error: "", class: "" },
            relation: { value: "", error: "", class: "" },
        })
        setParentFormData({
            parentName: { value: "", error: "", class: "" },
            qid: { value: "", error: "", class: "" },
            dob: { value: "", error: "", class: "" },
            email: { value: "", error: "", class: "" },
            mobileNumber: { value: "", error: "", class: "" },
        })
    }
    const handleChange = (field, value) => {
        formData[field]["class"] = value ? "" : "invalid";
        formData[field]["value"] = value;
        setFormData({ ...formData })
    };
    const handleChangeAddForm = (field, value) => {
        if (field == "qid")
            if (isNaN(value))
                return false
        studentFormData[field]["class"] = value ? "" : "invalid";
        studentFormData[field]["value"] = value;
        setStudentFormData({ ...studentFormData })
    };
    const handleChangeParentForm = (field, value) => {
        if (field == "qid")
            if (isNaN(value))
                return false
        parentFormData[field]["class"] = value ? "" : "invalid";
        parentFormData[field]["value"] = value;
        setParentFormData({ ...parentFormData })
    };

    const getSchoolList = async () => {
        const url = `${configuration.api_url}${urlEndPoints.schoolList(lang)}`
        try {
            const data = await networkRequest(url)
            setSchoolList(data.data)
            // schoolName.focus()
            // initSelect()
        } catch (error) {
            dispatch()
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
    const getMySchool = async () => {
        const url = `${configuration.api_url}${urlEndPoints.getMySchool}`
        try {
            const data = await networkRequest(url)
            if (data.data && data.data.length > 0) {
                studentFormData.schoolName.value = data.data[0].id
                setStudentFormData({...studentFormData})
                // setState({ studentFormData })
            }
            // setState({ schoolList: data.data })
            // schoolName.focus()
            // initSelect()
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
    const getChildren = async () => {
        dispatch(LoadingAction.showLoader())
        // const authResponse = await this.auth();
        // if (authResponse) {
        let url = `${configuration.api_url}${urlEndPoints.myChildren}`

        if (!_.isEmpty(formData.name.value))
            url = `${url}&combine=${formData.name.value}`

        if (!_.isEmpty(formData.gender.value))
            url = `${url}&field_gender_value=${formData.gender.value}`

        if (!_.isEmpty(formData.grade.value))
            url = `${url}&field_grade_value=${formData.grade.value}`

        if (!_.isEmpty(formData.age.value)) {
            const ageGroup = calculateAgeGroup(formData.age.value)
            url = `${url}&field_student_dob_value=${ageGroup.minDate}&field_student_dob_value_1=${ageGroup.maxDate}`
        }


        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await networkRequest(url)
            if (!data.data.message) {
                dispatch(LoadingAction.hideLoader())
                setStudentList(data.data.results);
                setCheckedList({});
                setCheckAll(false);
                handleGetParent(data.data.results);
            }
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // }
    }
    const calculateAgeGroup = (ageGroup) => {
        switch (ageGroup) {
            case "one_to_ten": {
                const dob = new Date();
                dob.setFullYear(dob.getFullYear() - Number(1));
                const minDate = `${dateformat(dob, "yyyy-mm-dd")}`
                dob.setFullYear(dob.getFullYear() - Number(10));
                const maxDate = `${dateformat(dob, "yyyy-mm-dd")}`

                return { minDate, maxDate }
            }
            case "eleven_to_twenty": {
                const dob = new Date();
                dob.setFullYear(dob.getFullYear() - Number(11));
                const minDate = `${dateformat(dob, "yyyy-mm-dd")}`
                dob.setFullYear(dob.getFullYear() - Number(20));
                const maxDate = `${dateformat(dob, "yyyy-mm-dd")}`

                return { minDate, maxDate }
            }
            case "twentyone_to_thirty": {
                const dob = new Date();
                dob.setFullYear(dob.getFullYear() - Number(21));
                const minDate = `${dateformat(dob, "yyyy-mm-dd")}`
                dob.setFullYear(dob.getFullYear() - Number(30));
                const maxDate = `${dateformat(dob, "yyyy-mm-dd")}`

                return { minDate, maxDate }
            }

            default:
                break;
        }
    }
    const calculateAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    const handleCloseModal = () => {
        initState();
        let elems = document.querySelector("#checkQid");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#checkQidForParent");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addParent");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addStudent");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        instance = M.Modal.getInstance(elems);
        instance.close();
    }
    const handleAddParent = () => {
        setIsParentExist(false)
        // setState({ isParentExist: false })
    }
    const onSubmit = (event) => {
        event.preventDefault();
        dispatch(LoadingAction.showLoader())
        getChildren()
    }
    const handleViewProfile = id => {
        navigate(`/student-profile/${id}`);
    }

    const renderSelect = ({ name, label, placeholder, isMandatory = false, data = [], type = "select", disabled = false }) => {

        return (
            <div className={`input-field mobileSelect item ${lang}`} >
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <select
                    name={name}
                    disabled={disabled}
                    id={name}
                    className="browser-default"
                    style={dynamicStyles.textAlign(lang)}
                    // ref={(input) => { this[name] = input; }}
                    onChange={evt =>
                        handleChangeAddForm(name, evt.target.value)
                    }

                    onFocus={evt =>
                        handleChangeAddForm(name, evt.target.value)
                    }
                    value={studentFormData[name].value}
                // className={"validate" + formData[name].class}
                >
                    <option value="" disabled> {placeholder} </option>
                    {
                        data.map((item, index) => {
                            return type == "country" ?
                                <option key={index} value={item.country_id}>{item.country[lang]}</option>
                                :
                                type == "school" ? <option key={index} value={item.id}>{item.sname}</option> :
                                    <option key={index} value={item.key}>{item.label}</option>
                        })
                    }
                    {
                        type == "school" && <option value="0">{context.t("_not_in_list")}</option>
                    }
                </select>
            </div>
        )
    }
    const renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max, onchange }) => {

        return (type == "phone_number" ?
            <div className="row mobile">
                <div className="col s4">
                    <div className="input-field item">
                        <input name={`${name}_country_code`}
                            placeholder="Country Code"
                            value={context.t("_ar_country_code")}
                            style={dynamicStyles.textAlign(lang)}
                            id={`${name}_country_code`}
                            type="text"
                            minLength="3"
                            maxLength="3"
                            disabled
                        // className={"validate " + registration_form.mobile.class}
                        // required
                        />
                        <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{context.t("_code")}</label>
                        <span className="helper-text" data-error="Enter a valid code."></span>
                    </div>
                </div>
                <div className="col s8">
                    <div className="input-field item">
                        <input
                            name={name}
                            placeholder={placeholder}
                            // ref={(input) => { this[name] = input; }}
                            aria-label={context.t("_phone_aria_label")}
                            id={name}
                            maxLength={max}
                            type="text"
                            style={dynamicStyles.textAlign(lang)}
                            // readOnly={type === "date" ? "readonly" : false}
                            autoComplete="no"
                            onChange={evt =>
                                handleChangeAddForm(name, evt.target.value)
                            }
                            onFocus={evt =>
                                handleChangeAddForm(name, evt.target.value)
                            }
                            value={studentFormData[name].value}
                        // className={"validate " + formData[name].class}
                        // required={isMandatory}
                        />
                        <label htmlFor={name} style={dynamicStyles.position(lang)}>
                            {`${label} `}
                            {isMandatory ? <span className="asterisk">*</span> : null}
                        </label>
                        <span
                            style={dynamicStyles.textAlign(lang)}
                            className="helper-text"
                            data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
                        ></span>
                    </div>
                </div>
            </div>
            : type == "date" ? <div className="input-field item">
                <DatePicker
                    name={name}
                    placeholderText={placeholder}
                    // ref={(input) => { this[name] = input; }}
                    id={name}
                    autoComplete="no"
                    // className={styleClass}
                    maxDate={new Date("12/31/2014")}
                    minDate={new Date("01/01/2001")}
                    dateFormat="yyyy-MM-dd"
                    onChange={date =>
                        handleChangeAddForm(name, date)
                    }
                    style={dynamicStyles.textAlign(lang)}
                    value={studentFormData[name].value}
                    selected={studentFormData[name].value}
                    locale={calenderLocal(context)}
                    customInput={<CustomDateInput ariaLabel={context.t("_select_dob_button")} onFieldChange={date => handleChangeAddForm(name, new Date(date))} />}
                    onCalendarOpen={e => {
                        let elems = document.querySelector(".react-datepicker");
                        elems.setAttribute("tabindex", "0")
                        elems.focus()
                        elems = document.querySelector(".react-datepicker__month-select");
                        elems.setAttribute("aria-label", context.t("_select_month"))
                        elems = document.querySelector(".react-datepicker__year-select");
                        elems.setAttribute("aria-label", context.t("_select_year"))

                    }}
                    onCalendarClose={e => {
                        var elems = document.querySelector(".hiddenDob");
                        elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(studentFormData[name].value, "dd-mm-yyyy")}`)
                        elems.setAttribute("value", dateformat(studentFormData[name].value, "yyyy-mm-dd"))
                        setTimeout(() => {
                            elems.focus()
                        }, 200);
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <span
                    style={dynamicStyles.textAlign(lang)}
                    className="helper-text"
                    data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
                ></span>
            </div> :
                <div className="input-field item">
                    <input
                        name={name}
                        placeholder={placeholder}
                        // ref={(input) => { this[name] = input; }}
                        id={name}
                        maxLength={max}
                        type={type === "date" ? "text" : type}
                        style={dynamicStyles.textAlign(lang)}
                        // readOnly={type === "date" ? "readonly" : false}
                        autoComplete="no"
                        onChange={evt =>
                            handleChangeAddForm(name, evt.target.value)
                        }
                        onFocus={evt =>
                            handleChangeAddForm(name, evt.target.value)
                        }
                        value={studentFormData[name].value}
                    // className={"validate " + formData[name].class}
                    // required={isMandatory}
                    />
                    <label htmlFor={name} style={dynamicStyles.position(lang)}>
                        {`${label} `}
                        {isMandatory ? <span className="asterisk">*</span> : null}
                    </label>
                    <span
                        style={dynamicStyles.textAlign(lang)}
                        className="helper-text"
                        data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
                    ></span>
                </div>)
    }

    const renderRadioButton = ({ data = [], name, label, defaultChecked, isMandatory = false }) => {

        return (
            <div className="input-field item">
                <div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={name}>
                    {
                        data.map((item, index) => {
                            return (
                                <div key={index} className={item.value}>
                                    <input
                                        aria-checked={studentFormData[name].value === item.value}
                                        name={name}
                                        id={item.value}
                                        aria-labelledby={`${name} ${item.label}`}
                                        type="radio"
                                        readOnly
                                        className="radio-btn"
                                        onClick={evt =>
                                            handleChangeAddForm(name, item.value)
                                        }
                                        checked={studentFormData[name].value === item.value}
                                    />
                                    <label id={`_${item.label}`} htmlFor={item.value} className="custom-radio-style">
                                        <span className="custom-radio-style">{item.label}</span>
                                    </label>
                                </div>
                            )
                        })
                    }

                </div>
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <span
                    className="helper-text"
                    data-error="Required field."
                ></span>
            </div>
        )
    }
    const validate = (type) => {
        for (var key in studentFormData) {
            studentFormData[key].value = key == "dob" ? studentFormData[key].value : studentFormData[key].value.trim();
            studentFormData[key].error = "";
        }
        if (type == "qid") {
            if (studentFormData.qid.value == "")
                studentFormData.qid.error = context.t("_qid_required");

            if (studentFormData.qid.value != "" && !qidValidate.test(studentFormData.qid.value))
                studentFormData.qid.error = context.t("_invalid_qid");
        } else {
            if (studentFormData.name.value == "")
                studentFormData.name.error = context.t("_student_name_required");

            if (studentFormData.name.value != "" && !nameValidate.test(studentFormData.name.value))
                studentFormData.name.error = context.t("_invalid_student_name");

            if (studentFormData.name.value != "" && nameValidate.test(studentFormData.name.value) && !nameWordLengthCheck(studentFormData.name.value))
                studentFormData.name.error = context.t("_student_name_contains_atleast_two_word");

            if (studentFormData.dob.value == "")
                studentFormData.dob.error = context.t("_date_of_birth_required");

            if (studentFormData.email.value != "" && !emailValidate.test(studentFormData.email.value))
                studentFormData.email.error = context.t("_invalid_email");

            if (studentFormData.mobile.value == "")
                studentFormData.mobile.error = context.t("_mobile_number_required");

            if (studentFormData.mobile.value != "" && !phoneValidate.test(studentFormData.mobile.value.replace(/ /g, "")))
                studentFormData.mobile.error = context.t("_invalid_mobile_number");

            if (studentFormData.mobile.value != "")
                if (studentFormData.mobile.value.toString().startsWith("3") || studentFormData.mobile.value.toString().startsWith("5") || studentFormData.mobile.value.toString().startsWith("6") || studentFormData.mobile.value.toString().startsWith("7")) {

                } else {
                    studentFormData.mobile.error = context.t("_invalid_mobile_number");
                }


            if (studentFormData.schoolName.value == "")
                studentFormData.schoolName.error = context.t("_school_name_required");

            if (studentFormData.schoolName.value == "0" && studentFormData.schoolNewName.value == "")
                studentFormData.schoolNewName.error = context.t("_school_name_required");

            if (studentFormData.parentQid.value == "")
                studentFormData.parentQid.error = context.t("_parent_qid_required");

            if (studentFormData.parentQid.value != "" && !qidValidate.test(studentFormData.parentQid.value))
                studentFormData.parentQid.error = context.t("_invalid_parent_qid");

            if (studentFormData.parentName.value == "")
                studentFormData.parentName.error = context.t("_parent_name_required");

            if (studentFormData.parentName.value != "" && !nameValidate.test(studentFormData.parentName.value))
                studentFormData.parentName.error = context.t("_invalid_parent_name");

            if (studentFormData.parentName.value != "" && nameValidate.test(studentFormData.parentName.value) && !nameWordLengthCheck(studentFormData.parentName.value))
                studentFormData.parentName.error = context.t("_parent_name_contains_atleast_two_word");


            if (studentFormData.relation.value == "")
                studentFormData.relation.error = context.t("_relationship_required");

            if (studentFormData.parentMobile.value == "")
                studentFormData.parentMobile.error = context.t("_parent_mobile_number_1_required");

            if (studentFormData.parentMobile.value != "" && !phoneValidate.test(studentFormData.parentMobile.value.replace(/ /g, "")))
                studentFormData.parentMobile.error = context.t("_invalid_parent_mobile");

            if (studentFormData.parentMobile.value != "")
                if (studentFormData.parentMobile.value.toString().startsWith("3") || studentFormData.parentMobile.value.toString().startsWith("5") || studentFormData.parentMobile.value.toString().startsWith("6") || studentFormData.parentMobile.value.toString().startsWith("7")) {

                } else {
                    studentFormData.parentMobile.error = context.t("_invalid_parent_mobile");
                }

            if (studentFormData.parentMobile2.value == "")
                studentFormData.parentMobile2.error = context.t("_parent_mobile_number_2_required");

            if (studentFormData.parentMobile2.value != "" && !phoneValidate.test(studentFormData.parentMobile2.value.replace(/ /g, "")))
                studentFormData.parentMobile2.error = context.t("_invalid_parent_mobile_2");

            if (studentFormData.parentMobile2.value != "")
                if (studentFormData.parentMobile2.value.toString().startsWith("3") || studentFormData.parentMobile2.value.toString().startsWith("5") || studentFormData.parentMobile2.value.toString().startsWith("6") || studentFormData.parentMobile2.value.toString().startsWith("7")) {

                } else {
                    studentFormData.parentMobile2.error = context.t("_invalid_parent_mobile_2");
                }

            if (studentFormData.parentEmail.value == "")
                studentFormData.parentEmail.error = context.t("_parent_email_required");

            if (studentFormData.parentEmail.value != "" && !emailValidate.test(studentFormData.parentEmail.value))
                studentFormData.parentEmail.error = context.t("_invalid_parent_email");

            if ((studentFormData.parentEmail.value != "" && studentFormData.email.value != "") && studentFormData.parentEmail.value == studentFormData.email.value)
                studentFormData.parentEmail.error = context.t("_parent_student_email_cannot_be_same");

            if ((studentFormData.parentQid.value != "" && studentFormData.parentQid.value != "") && studentFormData.parentQid.value == studentFormData.qid.value)
                studentFormData.parentQid.error = context.t("_parent_student_qid_cannot_be_same");
        }

        for (var key in studentFormData) {
            if (studentFormData[key].error != "") {
                setStudentFormData({ ...studentFormData })
                setSubmissionStatus(false)
                // this.setState({ studentFormData, submissionStatus: false })
                // this.scrollToTop();
                setTimeout(() => {
                    // type == "qid" ? refs[key].focus() : this[key + "error"].focus()
                    document.querySelector(`a[href="#${key}"]`).focus()

                }, 1000);
                return false;

            }
        }
        return true;
    }
    const validateParent = (type) => {
        for (var key in parentFormData) {
            parentFormData[key].value = parentFormData[key].value.trim();
            parentFormData[key].error = "";
        }
        if (type == "qid") {
            if (parentFormData.qid.value == "")
                parentFormData.qid.error = context.t("_qid_required");

            if (parentFormData.qid.value != "" && !qidValidate.test(parentFormData.qid.value))
                parentFormData.qid.error = context.t("_invalid_qid");
        } else {
            // if (formData.parentName.value == "")
            //     formData.parentName.error = context.t("_parent_name_required");

            // if (formData.parentName.value != "" && !nameValidate.test(formData.parentName.value))
            //     formData.parentName.error = context.t("_invalid_parent_name");

            // if (formData.parentName.value != "" && nameValidate.test(formData.parentName.value) && !nameWordLengthCheck(formData.parentName.value))
            //     formData.parentName.error = context.t("_parent_name_contains_atleast_two_word");

            // if (formData.dob.value == "")
            //     formData.dob.error = context.t("_date_of_birth_required");
            if (parentFormData.email.value == "")
                parentFormData.email.error = context.t("_email_required");

            if (parentFormData.email.value != "" && !emailValidate.test(parentFormData.email.value))
                parentFormData.email.error = context.t("_invalid_email");

            // if (formData.mobileNumber.value == "")
            //     formData.mobileNumber.error = context.t("_mobile_number_required");

            // if (formData.mobileNumber.value != "" && !phoneValidate.test(formData.mobileNumber.value))
            //     formData.mobileNumber.error = context.t("_invalid_mobile_number");
        }
        for (var key in parentFormData) {
            if (parentFormData[key].error != "") {
                setParentFormData({ ...parentFormData })
                setSubmissionStatus(false)
                // this.setState({ parentFormData, submissionStatus: false })
                // this.scrollToTop();
                setTimeout(() => {
                    document.querySelector(`a[href="#${key}"]`).focus()

                }, 1000);
                return false;

            }
        }
        return true;
    }
    const displayError = error => {

        if (error.qid)
            studentFormData.qid.error = error.qid[lang]

        if (error.name)
            studentFormData.name.error = error.name[lang]

        if (error.mail)
            studentFormData.email.error = error.mail[lang]

        if (error.DOB)
            studentFormData.dob.error = error.DOB[lang]


        for (var key in studentFormData) {
            if (studentFormData[key].error != "") {
                setStudentFormData({ ...studentFormData })
                // this.setState({ studentFormData })
                // this.scrollToTop();
                setTimeout(() => {
                    // this.refs.errorListRef.focus()
                    // this.refs[key].focus()
                    document.querySelector(`a[href="#${key}"]`).focus()

                }, 1000);
                return false;

            }
        }
        setStudentFormData({ ...studentFormData })

        // this.setState({ studentFormData })
        // this.scrollToTop();

    }
    const displayParentError = error => {

        if (error.qid)
            parentFormData.qid.error = error.qid[lang]

        if (error.name)
            parentFormData.name.error = error.name[lang]

        if (error.mail)
            parentFormData.email.error = error.mail[lang]

        if (error.DOB)
            parentFormData.dob.error = error.DOB[lang]

        for (var key in parentFormData) {
            if (parentFormData[key].error != "") {
                setParentFormData({ ...parentFormData })
                // this.setState({ parentFormData })
                // this.scrollToTop();
                setTimeout(() => {
                    // this.refs.errorListRef.focus()
                    // this.refs[key].focus()
                    document.querySelector(`a[href="#${key}"]`).focus()

                }, 1000);
                return false;

            }
        }
        setParentFormData({ ...parentFormData })

    }
    const handleCheckQid = async (event) => {
        event.preventDefault();

        if (validate("qid")) {
            dispatch(LoadingAction.showLoader())
            // const authResponse = await this.auth();
            // if (authResponse) {
            const obj = {
                field_qid: studentFormData.qid.value
            }
            const url = `${configuration.api_url}${urlEndPoints.checkQid}`
            // const headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            try {
                const data = await networkRequest(url, "POST", obj)
                if (data.status == 200) {
                    if (data.data.error) {
                        dispatch(LoadingAction.hideLoader())
                        displayError(data.data.error)
                    } else {
                        if (!data.data.user) {
                            dispatch(LoadingAction.hideLoader())
                            handleOpenAddStudentModal();
                        }
                        else {
                            handleCloseModal()
                            getChildren();
                        }
                    }
                }
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
            // } else {
            //     dispatch(LoadingAction.hideLoader())
            // }
        }
    }
    const handleOpenAddStudentModal = () => {
        for (var key in studentFormData) {
            studentFormData[key].error = "";
        }
        setStudentFormData({ ...studentFormData })
        let elems = document.querySelector("#checkQid");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addStudent");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        instance = M.Modal.getInstance(elems);
        instance.open();
    }
    const handleOpenAddParentModal = () => {
        for (var key in parentFormData) {
            parentFormData[key].error = "";
        }
        setParentFormData({ ...parentFormData })
        let elems = document.querySelector("#checkQidForParent");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addParent");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        instance = M.Modal.getInstance(elems);
        instance.open();
    }
    const handleAddNewStudent = async (event) => {
        event.preventDefault();
        if (validate()) {
            dispatch(LoadingAction.showLoader())
            // const authResponse = await this.auth();
            // if (authResponse) {
            let obj = {
                "name": studentFormData.email.value != "" ? studentFormData.email.value : studentFormData.name.value,
                "field_qid": studentFormData.qid.value,
                "field_student_mobile": `+974${studentFormData.mobile.value.replace(/ /g, "")}`,
                "roles": {
                    "target_id": "student"
                },
                "field_gender": studentFormData.gender.value,
                "field_student_name": studentFormData.name.value,
                "field_student_dob": dateformat(studentFormData.dob.value, "dd/mm/yyyy"),
                "field_reference": {
                    "target_id": ""
                },
                "field_parent_qid": studentFormData.parentQid.value,
                "field_parent_name": studentFormData.parentName.value,
                "field_parent_email": studentFormData.parentEmail.value,
                "field_parent_mobile_number": `+974${studentFormData.parentMobile.value.replace(/ /g, "")}`,
                "field_parent_mobile_number_2": `+974${studentFormData.parentMobile2.value.replace(/ /g, "")}`,
                "field_relation_to_the_student": studentFormData.relation.value,
            }
            if (studentFormData.email.value != "") {
                obj.mail = studentFormData.email.value
                obj.field_student_email = studentFormData.email.value
            }
            if (!_.isEmpty(studentFormData.schoolName.value)) {
                let schoolName = schoolList.filter(item => item.id == studentFormData.schoolName.value)
                if (studentFormData.schoolName.value == "0")
                    schoolName = studentFormData.schoolNewName.value
                else
                    schoolName = _.isEmpty(schoolName) ? null : schoolName[0].sname


                obj.school_id = {
                    "target_id": studentFormData.schoolName.value,
                    "target_type": "taxonomy_term"
                };
                obj.school_name = schoolName
            }
            const url = `${configuration.api_url}${urlEndPoints.schoolAddStudent}`
            // const headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            try {
                const data = await networkRequest(url, "POST", obj)
                if (data.data.error) {
                    displayError(data.data.error)
                    dispatch(LoadingAction.hideLoader())
                }
                else {
                    setSubmissionStatus(false)
                    // this.setState({ submissionStatus: true })
                    handleCloseModal()
                    getChildren();
                }
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
            // } else {
            //     dispatch(LoadingAction.hideLoader())
            // }
        }

    }

    const handleRemove = async id => {
        dispatch(LoadingAction.showLoader())
        handleCloseConfirmModal("removeStudentConfirm")
        // const authResponse = await this.auth();
        // if (authResponse) {
        const user_ids = Object.keys(checkedList).map((key) => key)
        let obj = {
            user_ids
        }
        const url = `${configuration.api_url}${urlEndPoints.removeLinking}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await networkRequest(url, "POST", obj)
            if (data.data.error) {
                dispatch(LoadingAction.hideLoader())
                dispatch(PopupAction.showPopup({
                    type: "error",
                    title: context.t("_failed"),
                    message: context.t("_student_unlink_failed"),
                    isOpen: true,
                    buttonText: context.t("_ok")
                }))

            }
            else {
                getChildren()
            }
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // } else {
        //     dispatch(LoadingAction.hideLoader())
        // }
    }

    const handleOpenQidCheck = () => {
        for (var key in studentFormData) {
            studentFormData[key].error = "";
        }
        setStudentFormData({ ...studentFormData })
        // this.setState({ studentFormData })
        let options = {
            inDuration: 1000,
            opacity: 1
        }
        let elems = document.querySelector("#checkQid");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        let instance = M.Modal.getInstance(elems, options);
        instance.open();
    }
    const handleOpenQidCheckParent = (id) => {

        for (var key in parentFormData) {
            parentFormData[key].error = "";
        }
        setParentFormData({ ...parentFormData })
        setSelectedStudent(id)
        // this.setState({ parentFormData, selectedStudent: id })
        let options = {
            inDuration: 1000,
            opacity: 1
        }
        let elems = document.querySelector("#checkQidForParent");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        let instance = M.Modal.getInstance(elems, options);
        instance.open();
    }
    const clearSearch = async () => {
        dispatch(LoadingAction.showLoader())
        formData.name.value = ""
        formData.grade.value = ""
        formData.gender.value = ""
        formData.age.value = ""

        setFormData({ ...formData })
        // await this.setState({ formData })
        getChildren()
    }
    const handleGetParent = async studentData => {
        let studentListdata =  studentData ? studentData : studentList
        studentListdata.map(async (item, index) => {
            let url = `${configuration.api_url}${urlEndPoints.getParentName(item.uid)}`
            try {
                const data = await networkRequest(url)
                if (_.has(data, "data.results") && data.data.results.length > 0)
                studentListdata[index].parents = data.data.results
                else
                studentListdata[index].parents = []
                setStudentList([ ...studentListdata ])
                dispatch(LoadingAction.hideLoader())
                // this.setState({ studentList })
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
        });
    }
    const handleCheckParentQid = async (event) => {
        event.preventDefault();

        if (validateParent("qid")) {
            dispatch(LoadingAction.showLoader())
            // const authResponse = await this.auth();
            // if (authResponse) {
            const obj = {
                field_qid: parentFormData.qid.value,
                id: selectedStudent
            }
            const url = `${configuration.api_url}${urlEndPoints.checkQidAfterProfileView}`
            // const headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            try {
                const data = await networkRequest(url, "POST", obj)
                if (data.status == 200) {
                    if (data.data.error) {
                        dispatch(LoadingAction.hideLoader())
                        displayParentError(data.data.error)
                    } else {
                        if (!data.data.user) {
                            dispatch(LoadingAction.hideLoader())
                            handleOpenAddParentModal();
                        }
                        else {
                            handleCloseModal()
                            getChildren();
                        }
                    }
                }
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
            // } else {
            //     dispatch(LoadingAction.hideLoader())
            // }
        }
    }
    const handleAddNewParent = async (event) => {
        event.preventDefault();
        if (validateParent()) {
            dispatch(LoadingAction.showLoader())
            // const authResponse = await this.auth();
            // if (authResponse) {
            let obj = {
                email: parentFormData.email.value
            }
            const url = `${configuration.api_url}${urlEndPoints.sendNotification}`
            // const headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            try {
                const data = await networkRequest(url, "POST", obj)
                if (data.data.error) {
                    displayError(data.data.error)
                    dispatch(LoadingAction.hideLoader())
                }
                else {
                    dispatch(PopupAction.showPopup({
                        type: "success",
                        title: context.t("_success"),
                        message: data.data.message[lang],
                        isOpen: true,
                        buttonText: context.t("_ok")
                    }))
                 
                    handleCloseModal();
                    getChildren();
                    
                    // this.setState({ submissionStatus: true, parentList });
                    // dispatch(LoadingAction.hideLoader());
                }
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
            // } else {
            //     dispatch(LoadingAction.hideLoader())
            // }
        }
    }
    const handleCloseConfirmModal = (state) => {
        setRemoveStudentConfirm(false)
        setRemoveStudentId(null)
        // this.setState({ [state]: false, removeStudentId: null })
    }
    const handleConfirmRemoveStudent = () => {
        if (!_.isEmpty(removeStudentId))
            handleRemove(removeStudentId);
        handleCloseConfirmModal("removeStudentConfirm")
    }
    const handleOpenRemoveSchoolConfirm = () => {
        setRemoveStudentConfirm(true)
    }
    const handleUploadExcel = async (event) => {
        event.preventDefault();
    }
    const onChangeExcel = (event) => {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        var name = file.name;
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        };
        reader.readAsBinaryString(file);
    }
    const convertToJson = (csv) => {
        var lines = csv.split("\n");

        var result = [];

        var headers = lines[0].split(",");

        for (var i = 1; i < lines.length; i++) {
            var obj = {};
            var currentline = lines[i].split(",");

            for (var j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }

            result.push(obj);
        }
        return JSON.stringify(result); //JSON
    }
    const handleCheck = (event, id) => {
        let updatedCheckedList = { ...checkedList };
        let updatedCheckAll = checkAll;
        if (event.target.checked) {
          updatedCheckedList[id] = true;
        } else {
          delete updatedCheckedList[id];
          updatedCheckAll = false;
        }
        setCheckedList(updatedCheckedList);
        setCheckAll(updatedCheckAll);
      };

    const handleCheckAll = (event) => {
        const checkedList = {}
        let checkAll = false;
        if (event.target.checked) {
            studentList.forEach(data => {
                checkedList[data.uid] = true
            });
            checkAll = true
        }
        setCheckedList(checkedList)
        setCheckAll(checkAll)
        // this.setState({ checkedList, checkAll })

    }
    let hasError = false;
    Object.keys(studentFormData).map((item, index) => {
        if (studentFormData[item].error != "")
            hasError = true
    })
    Object.keys(parentFormData).map((item, index) => {
        if (parentFormData[item].error != "")
            hasError = true
    })
    return (
        <>
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={context.t("_students")} />
                <div id="skipContent" className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/profile">{context.t("_profile")}</Link> {" "}

                            </li>
                            <li className="breadcrumb-item">
                                <Link tabIndex="0" to aria-current={context.t("_page")}>
                                    <span>
                                        {context.t("_students")}
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <div className="container">
                        <h2>{context.t("_students")}</h2>
                        {
                            _.isEmpty(formData) ?
                                <div /> :
                                <div className="row topFormHolder">
                                    <form onSubmit={onSubmit} className="row full-width">
                                        <div className="col s12 m3 l3">
                                            <div className="input-field item">
                                                <label htmlFor="activityName" className="active" style={dynamicStyles.position(lang)}>{context.t("_search")} <span className="asterisk">*</span></label>
                                                <input
                                                    onChange={evt =>
                                                        handleChange("name", evt.target.value)
                                                    }
                                                    onFocus={evt =>
                                                        handleChange("name", evt.target.value)
                                                    }
                                                    id="activityName"
                                                    name="activityName"
                                                    // ref={(input) => { parentName = input; }}
                                                    value={formData.name.value}
                                                    placeholder={context.t("_search")}
                                                    type="text"
                                                />
                                                <span className="helper-text"></span>
                                            </div>
                                        </div>
                                        <div className="col s12 m2 l">
                                            <div className="input-field item">
                                                <label style={dynamicStyles.position(lang)}>{context.t("_gender")}</label>
                                                <select
                                                    className="browser-default"
                                                    id="gender"
                                                    name="gender"
                                                    value={formData.gender.value}
                                                    onChange={evt => handleChange("gender", evt.target.value)}
                                                    onFocus={evt => handleChange("gender", evt.target.value)}
                                                >
                                                    <option value="" disabled>{context.t("_select_gender")}</option>
                                                    <option value="Male">{context.t("_male")}</option>
                                                    <option value="Female">{context.t("_female")}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col s12 m2 l2">
                                            <div className="input-field item">
                                                <label style={dynamicStyles.position(lang)}>{context.t("_age_group")}</label>
                                                <select
                                                    className="browser-default"
                                                    id="age"
                                                    name="age"
                                                    value={formData.age.value}
                                                    onChange={evt => handleChange("age", evt.target.value)}
                                                    onFocus={evt => handleChange("age", evt.target.value)}
                                                >
                                                    <option value="" disabled>{context.t("_select_age_group")}</option>
                                                    <option value="one_to_ten">{`1 ${context.t("_to")} 10`}</option>
                                                    <option value="eleven_to_twenty">{`11 ${context.t("_to")} 20`}</option>
                                                    <option value="twentyone_to_thirty">{`21 ${context.t("_to")} 30`}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col s12 m2 l2">
                                            <div className="input-field item">
                                                <label style={dynamicStyles.position(lang)}>{context.t("_grade")}</label>

                                                <select
                                                    className="browser-default"
                                                    onChange={evt =>
                                                        handleChange("grade", evt.target.value)
                                                    }
                                                    onFocus={evt =>
                                                        handleChange("grade", evt.target.value)
                                                    }
                                                    // ref={(input) => { grade = input; }}
                                                    value={formData.grade.value}
                                                >
                                                    <option value="" disabled >{context.t("_select_grade")}</option>
                                                    {
                                                        gradeListNew[lang].map((item, index) => {
                                                            return <option key={index} value={item.value}>{item.label}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col s12 m3 l3 valign-wrapper center-align search-btn-holder">
                                            <button className="btn profileBtn yellow waves-effect waves-light">
                                                {context.t("_search")} <i className={`tiny material-icons ${lang}`} >{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
                                            </button>
                                            <button type="button" onClick={() => clearSearch()} className="btn noWidth  blue-btn waves-effect waves-light">
                                                {context.t("_clear")}
                                            </button>
                                            <br /><br />
                                        </div>
                                    </form>
                                </div>
                        }

                        <div className="tableHolder">
                            {
                                _.isEmpty(studentList) ?
                                    <div className="row"><p aria-label={context.t("_no_result_found")} className="col s12 no-result">{context.t("_no_result_found")}</p></div>
                                    :
                                    <table className="responsive-table highlight">
                                        <thead className="blue lighten-5">
                                            <tr>
                                                <th><strong>{context.t("_name")}</strong></th>
                                                <th><strong>{context.t("_qid")}</strong></th>
                                                <th><strong>{context.t("_phone_number")}</strong></th>
                                                <th><strong>{context.t("_age")}</strong></th>
                                                <th><strong>{context.t("_grade")}</strong></th>
                                                <th><strong>{context.t("_gender")}</strong></th>
                                                {/* <th><strong>{context.t("_status")}</strong></th> */}
                                                <th><strong>{context.t("_parents")}</strong></th>
                                                <th width="140"><p className="checkboxHolder checkboxNew"><input id="selectAll" onChange={(e) => handleCheckAll(e)} checked={checkAll} type="checkbox" aria-label={context.t("_select_all_for_remove_connection")} /><label htmlFor="selectAll"><span style={{ fontSize: 16, margin: "0 7px", textTransform: "capitalize" }}>{context.t("_select_all")}</span></label></p></th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {
                                                studentList.map((item, index) => {
                                                    return (
                                                        <tr key={item.uid}>
                                                            <td>{item.field_student_name}</td>
                                                            <td>{item.field_qid}</td>
                                                            <td>{item.field_student_mobile}</td>
                                                            <td>{calculateAge(item.field_student_dob)}</td>
                                                            <td>{handleGradeName(item.field_grade, lang)}</td>
                                                            <td>{getGender(item.field_gender, context)}</td>
                                                            {/* <td>{item.status}</td> */}
                                                            <td>
                                                                    {item.parents &&
                                                                        item.parents.length > 1 ? item.parents.map(itemParent => {
                                                                            return <span key={itemParent.uid}>{itemParent.field_parent_name}<br /></span>
                                                                        }) :
                                                                            <button aria-label={`${context.t("_add_guardian_for")} ${item.field_student_name}`} onClick={() => handleOpenQidCheckParent(item.uid)} className="btn-flat blue-text"><strong>{context.t("_add")}</strong></button>
                                                                        
                                                                    }
                                                                </td>
                                                            {/* <td><button onClick={() => { handleViewProfile(item.uid) }} className="btn profileBtn">{context.t("_view_profile")}</button></td> */}
                                                            {/* <td><button onClick={() => { handleOpenRemoveSchoolConfirm(item.uid) }} className="btn profileBtn">{context.t("_remove")}</button></td> */}
                                                            <td><p className="checkboxHolder checkboxNew"><input id={`enroll${index}`} aria-label={`${item.field_student_name} ${context.t("_selected_for_remove_connection")}`}
                                                                checked={checkedList[item.uid] ? true : false}
                                                                onChange={(e) => handleCheck(e, item.uid)} type="checkbox" /><label htmlFor={`enroll${index}`}><span style={{ display: "none" }}>{context.t("_select")}</span></label></p></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                            }
                        </div>
                        <button className={`waves-effect waves-light btn profileBtn`}
                            onClick={() => {
                                handleOpenQidCheck();
                                getMySchool();
                            }}>{context.t("_add_student")}</button>
                        <button className={`waves-effect waves-light btn profileBtn pull-${lang == "ar" ? "left" : "right"}`} onClick={() => { handleOpenRemoveSchoolConfirm() }}>{context.t("_remove")}</button>
                        {/* <div className="file-upload-container">
                            <h4>{context.t("_import_students")}</h4>
                            <form onSubmit={handleUploadExcel} className="row full-width">
                                <div className="col s6">
                                    <div className="input-field item">
                                        <input
                                            type="file"
                                            onChange={onChangeExcel.bind(this)}
                                        />
                                        <label style={dynamicStyles.position(lang)}>{context.t("_choose_file")} <span className="asterisk">*</span></label>
                                    </div>
                                </div>

                                <div className="col s2">
                                    <button type="submit" className="btn noWidth  blue-btn waves-effect waves-light" style={{ marginTop: 26 }}>{context.t("_upload")}</button>
                                </div>
                                <div className="col s4" />
                            </form>
                        </div> */}


                        <div id="checkQid" className="modal sm" role="dialog">
                            <div className="modal-content modal-no-close">
                                {
                                    _.isEmpty(studentFormData) ?
                                        <div /> :
                                        <div>
                                            <span className="badge modalClose"><button className="close" aria-label="close" onClick={() => handleCloseModal()}><i className="material-icons">close</i></button></span>
                                            <h2 className="line">{context.t("_add_student")}</h2>
                                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit.</p> */}
                                            {hasError ? <div className="error-container fail">
                                                <h5>{context.t("_error_title")}</h5>
                                                <ul>
                                                    {studentFormData.qid.error != "" ? <li ><a ref="qid" tabIndex="0" href="#qid" className="error" htmlFor="qid">{studentFormData.qid.error}</a></li> : null}
                                                </ul>
                                            </div> : null
                                            }
                                            <form onSubmit={handleCheckQid} className="row full-width">
                                                <div className="col s12">
                                                    <div className="input-field item">
                                                        <input
                                                            placeholder={context.t("_type_child_qid")}
                                                            type="text"
                                                            name="qid"
                                                            id="qid"
                                                            onChange={(evt) => handleChangeAddForm("qid", evt.target.value)}
                                                            onFocus={(evt) => handleChangeAddForm("qid", evt.target.value)}
                                                            value={studentFormData.qid.value}
                                                            maxLength={11}
                                                        />
                                                        <label htmlFor="qid" style={dynamicStyles.position(lang)}>{context.t("_student_qid")} <span className="asterisk">*</span></label>
                                                    </div>
                                                </div>

                                                <div className="col s12">
                                                    <button type="button" onClick={handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{context.t("_cancel")}</button>
                                                    <button className="btn profileBtn blue noWidth waves-effect waves-light">{context.t("_add")}</button>
                                                </div>
                                            </form>
                                        </div>
                                }
                            </div>
                        </div>
                        <div id="addStudent" className="modal modal-full-height" role="dialog">
                            <div className="modal-content modal-no-close">
                                {
                                    _.isEmpty(studentFormData) ?
                                        <div /> :
                                        <form onSubmit={handleAddNewStudent} className="col full-width">
                                            <span className="badge modalClose"><a onClick={() => handleCloseModal()}><i className="material-icons">close</i></a></span>
                                            <h2 className="line">{context.t("_add_student")}</h2>

                                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p> */}
                                            {hasError ? <div className="error-container fail">
                                                <h5>{context.t("_error_title")}</h5>
                                                <ul>
                                                    {
                                                        Object.keys(studentFormData).map((item, index) => {
                                                            return studentFormData[item].error != "" ? <li key={index} ><a  tabIndex="0" href={`#${item}`} className="error" htmlFor={item}>{studentFormData[item].error}</a></li>
                                                                :
                                                                null
                                                        })}
                                                </ul>
                                            </div> : null
                                            }
                                            <p>{context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

                                            <div className="row">
                                                <div className="col s12 m6">
                                                    {renderInput({ name: "name", label: context.t("_student_name"), placeholder: context.t("_student_name_placeholder"), isMandatory: true })}
                                                    {renderInput({ name: "email", label: context.t("_email"), placeholder: context.t("_email_placeholder"), type: "email", isMandatory: true })}

                                                    {
                                                        renderRadioButton({ label: context.t("_gender"), isMandatory: true, name: "gender", defaultChecked: "male", data: [{ label: context.t("_male"), value: "Male" }, { label: context.t("_female"), value: "Female" }] })
                                                    }

                                                </div>
                                                <div className="col s12 m6">
                                                    <div className="date-popup">
                                                        {renderInput({ name: "dob", label: context.t("_dob"), placeholder: context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
                                                    </div>

                                                    {renderInput({ name: "mobile", label: context.t("_mobile_number"), placeholder: context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max: 8 })}

                                                    <div className="result-type">
                                                        {
                                                            renderSelect({
                                                                name: "schoolName", label: context.t("_school_name"), placeholder: context.t("_select_school_name"), data: schoolList, type: "school", isMandatory: true, disabled: true
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        studentFormData.schoolName.value == "0" && renderInput({ name: "schoolNewName", label: context.t("_school_name"), placeholder: context.t("_select_school_name"), isMandatory: true })
                                                    }
                                                </div>
                                                <div className="col s12">
                                                    <p><br />{context.t("_guardian_information")} </p>
                                                </div>

                                                <div className="col s12 m6">
                                                    {renderInput({ name: "parentQid", label: context.t("_patent_qid"), placeholder: context.t("_patent_qid_placeholder"), isMandatory: true, max: 11 })}
                                                    {renderInput({ name: "parentName", label: context.t("_parent_name"), placeholder: context.t("_parent_name_placeholder"), isMandatory: true })}
                                                    {renderInput({ name: "parentEmail", label: context.t("_parent_email"), placeholder: context.t("_parent_email_placeholder"), isMandatory: true, type: "email", styleClass: "fontEnglish" })}
                                                </div>
                                                <div className="col s12 m6">

                                                    {renderInput({ name: "parentMobile", label: context.t("_parent_mobile"), placeholder: context.t("_parent_mobile_placeholder"), type: "phone_number", isMandatory: true, max: 8 })}
                                                    {renderInput({ name: "parentMobile2", label: context.t("_parent_mobile_2"), placeholder: context.t("_parent_mobile_placeholder_2"), type: "phone_number", isMandatory: true, max: 8 })}

                                                    <div className="result-type1">
                                                        {
                                                            renderSelect({
                                                                name: "relation", label: context.t("_relation"), placeholder: context.t("_select_relation"), isMandatory: true, data: [
                                                                    { label: context.t("_father"), value: "Father" },
                                                                    { label: context.t("_mother"), value: "Mother" },
                                                                    { label: context.t("_sister"), value: "Sister" },
                                                                    { label: context.t("_brother"), value: "Brother" },
                                                                    { label: context.t("_grand_mother"), value: "Grandmother" },
                                                                    { label: context.t("_grand_father"), value: "Grandfather" },
                                                                    { label: context.t("_parent"), value: "Guardian" }
                                                                ]
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                                <div className="col s12">
                                                    <button className="btn profileBtn blue waves-effect waves-light">{context.t("_save_changes")}</button>
                                                    <button type="button" onClick={() => handleCloseModal()} className="waves-effect waves-light btn profileBtn">{context.t("_close")}</button>
                                                </div>
                                                <div className="clearfix" />
                                            </div>
                                        </form>
                                }
                            </div>
                        </div>

                        <div id="checkQidForParent" className="modal sm" role="dialog">
                            <div className="modal-content modal-no-close">
                                {
                                    _.isEmpty(parentFormData) ?
                                        <div /> :
                                        <div>
                                            <span className="badge modalClose"><a onClick={() => handleCloseModal()}><i className="material-icons">close</i></a></span>
                                            <h2 className="line">{context.t("_add_guardian")}</h2>
                                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit.</p> */}
                                            {hasError ? <div className="error-container fail">
                                                <h5>{context.t("_error_title")}</h5>
                                                <ul>
                                                    {parentFormData.qid.error != "" ? <li ><a ref="qid" tabIndex="0" href="#parentId" className="error" htmlFor="parentId">{parentFormData.qid.error}</a></li> : null}
                                                </ul>
                                            </div> : null
                                            }
                                            <form onSubmit={handleCheckParentQid} className="row full-width">
                                                <div className="col s12">
                                                    <div className="input-field item">
                                                        <input
                                                            placeholder={context.t("_parent_id_placeholder")}
                                                            type="text"
                                                            name="parentId"
                                                            id="parentId"
                                                            maxLength={11}
                                                            onChange={(evt) => handleChangeParentForm("qid", evt.target.value, "parentFormData")}
                                                            onFocus={(evt) => handleChangeParentForm("qid", evt.target.value, "parentFormData")}
                                                            value={parentFormData.qid.value}
                                                        />
                                                        <label htmlFor="parentId" style={dynamicStyles.position(lang)}>{context.t("_parent_id")} <span className="asterisk">*</span></label>
                                                    </div>
                                                </div>

                                                <div className="col s12">
                                                    <button type="button" onClick={handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{context.t("_cancel")}</button>
                                                    <button className="btn profileBtn blue noWidth waves-effect waves-light">{context.t("_add")}</button>
                                                </div>
                                            </form>
                                        </div>
                                }
                            </div>
                        </div>
                        <div id="addParent" className="modal sm user-add-modal" role="dialog">
                            <div className="modal-content modal-no-close">
                                {
                                    _.isEmpty(parentFormData) ?
                                        <div /> :
                                        <form onSubmit={handleAddNewParent} className="col full-width">
                                            <span className="badge modalClose"><a onClick={() => handleCloseModal()}><i className="material-icons">close</i></a></span>
                                            <h2 className="line">{context.t("_add_guardian")}</h2>

                                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p> */}
                                            {hasError ? <div className="error-container fail">
                                                <h5>{context.t("_error_title")}</h5>
                                                <ul>
                                                    {parentFormData.email.error != "" ? <li ><a ref="email" tabIndex="0" href="#email_address" className="error" htmlFor="email_address">{parentFormData.email.error}</a></li> : null}

                                                </ul>
                                            </div> : null
                                            }
                                            <p>{context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

                                            <div className="row">
                                                <div className="col s12">
                                                    {/* {renderInput({ name: "parentName", label: context.t("_parent_name"), placeholder: context.t("_name_placeholder"), isMandatory: true })} */}
                                                    <div className="input-field item">
                                                        <input
                                                            placeholder={context.t("_parent_email_address_placeholder")}
                                                            type="text"
                                                            name="email_address"
                                                            id="email_address"
                                                            onChange={(evt) => handleChangeParentForm("email", evt.target.value, "parentFormData")}
                                                            onFocus={(evt) => handleChangeParentForm("email", evt.target.value, "parentFormData")}
                                                            value={parentFormData.email.value}
                                                        />
                                                        <label htmlFor="email_address" style={dynamicStyles.position(lang)}>{context.t("_parent_email_address")} <span className="asterisk">*</span></label>
                                                    </div>
                                                    {/* {renderInput({ name: "email", label: context.t("_parent_email_address"), placeholder: context.t("_parent_email_address_placeholder"), type: "email", onchange = handleChangeParentForm("email", evt.target.value, "parentFormData") })} */}

                                                </div>
                                                {/* <div className="col s12 m6">
                                                    <div className="date-popup">
                                                        {renderInput({ name: "dob", label: context.t("_dob"), placeholder: context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
                                                    </div>

                                                    {renderInput({ name: "mobileNumber", label: context.t("_parent_mobile_number"), placeholder: context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max:8 })}
                                                </div> */}
                                                <div className="col s12">
                                                    <button className="btn profileBtn blue waves-effect waves-light">{context.t("_add")}</button>
                                                    <button type="button" onClick={handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{context.t("_cancel")}</button>
                                                </div>
                                                <div className="clearfix" />
                                            </div>
                                        </form>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={removeStudentConfirm}
                    style={customModalStyles}
                    contentLabel="unlink parent confirm modal"
                    ariaHideApp={false}
                >
                    <div className="popupAlert warning">
                        <br />
                        <h3>{context.t("_confirm")}</h3>
                        <p>{context.t("_remove_student_confirm")}</p>
                        <div className="row">
                            <button className="col btn blue" onClick={() => handleCloseConfirmModal("removeStudentConfirm")}>{context.t("_cancel")}</button>
                            <button className="col btn profileBtn yellow" onClick={() => handleRemove()}>{context.t("_continue")}</button>
                        </div>
                    </div>
                </Modal>

            </div>
            <div className="top-line ng-scope">
                <img src={topLineImage} alt="" />
            </div>
        </>
    )
}

SchoolConnections.contextTypes = {
    t: PropTypes.func
}
export default SchoolConnections

