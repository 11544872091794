import React, { useEffect } from "react";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import loadingImage from "../../assets/images/logo5.svg";

// class Loader extends Component {
const Loader = ({ loading, lang }, context) => {
    useEffect(() => {
        if (loading) {
            document.getElementById("loaderContainer") && document.getElementById("loaderContainer").focus()
        }
    }, [loading])
    // render() {
    //     const { loading, lang } = this.props

    return (
        <>
            {
                loading ? <div className="loader-container" style={lang === "en" ? {} : { direction: "ltr" }}>
                    <div className="loader-inner" id="loaderContainer" tabIndex={loading ? 0 : -1}><img aria-label={context.t("_loading")} className="loaderImage" height="150px" src={loadingImage} alt="" />
                        {/* <div className="preloader-wrapper big active"> */}
                        {/* <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div><div className="gap-patch">
                                    <div className="circle"></div>
                                </div><div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div> */}
                        {/* </div> */}
                    </div>
                </div>
                    :
                    <div />
            }


        </>

    )
    // }
}
Loader.defaultProps = {
    loading: false
}

const mapStateToProps = ({ i18nState, loaderReducer }) => {
    return {
        lang: i18nState.lang,
        loading: loaderReducer.loading
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
Loader.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Loader);
