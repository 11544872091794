// import React from "react";
// import { Link } from "react-router-dom";
// import _ from "lodash"
// import Main from "../../main";
// import PageTitle from "../../includes/page_title.js";
// import SlideToTop from "../../includes/slide_to_top.js";
// import { connect } from "react-redux"
// import PropTypes from "prop-types"
// import { dynamicStyles, customModalStyles } from "./../../../constants/dynamicStyles"
// import { urlEndPoints } from "./../../../httpAPI/apiConfig"
// import { nameWordLengthCheck, qidValidate, nameValidate, emailValidate, phoneValidate, calenderI18n } from "./../../../constants/common"
// import M from "materialize-css";
// import { LoadingAction, PopupAction } from "./../../../actions"
// import Modal from "react-modal";
// import dateformat from "dateformat";
// import topLineImage from "../../../assets/images/top-line.png"

// class ViewStudentConnections extends Main {
//     constructor(props) {
//         super(props);
//         this.state = {
//             schoolFormData: {},
//             parentFormData: {},
//             addSchoolFormData: {},
//             formData: {},
//             config: this.config(),
//             schoolList: [],
//             parentList: [],
//             mySchoolList: [],
//             isParentExist: true,
//             intervalId: 0,
//             scrollStepInPx: 50,
//             delayInMs: 16.66,
//             removeSchoolId: null,
//             showAddSchool: false
//         };
//         this.props.showLoader()
//     }
//     async componentDidMount() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_profile")} | ${this.context.t("_manage_connections")}`
//         await this.initState();
//         const schoolList = await this.getSchoolList();
//         const parentList = await this.getMyParents();
//         const mySchoolList = await this.getMySchools();
//         this.setState({ schoolList, mySchoolList, parentList })
//         M.AutoInit();
//         this.props.hideLoader()
//         const options = {
//             autoClose: true,
//             dismissible: true
//         };
//         const dobOptions = {
//             autoClose: true,
//             onSelect: ((date) => {
//                 this.handleChange("dob", dateformat(date, "dd/mm/yyyy"), "formData")
//             }),
//             format: "dd/mm/yyyy",
//             container: null,
//             minDate: new Date("01/01/1950"),
//         };
//         setTimeout(() => {
//             let elems = document.querySelectorAll("#dob");
//             dobOptions.i18n = calenderI18n(this.context);
//             M.Datepicker.init(elems, dobOptions);
//             elems = document.querySelectorAll(".datepicker");
//             M.Datepicker.init(elems, dobOptions);
//             elems = document.querySelectorAll(".modal");
//             M.Modal.init(elems, options);
//             elems = document.querySelector("select");
//             M.FormSelect.init(elems)
//         }, 200);
//         window.addEventListener('load', this.handleTabIndex());
//         setTimeout(() => {
//             document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
//         }, 500);
//     }
//     componentDidUpdate() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_profile")} | ${this.context.t("_manage_connections")}`
//     }
//     handleTabIndex = () => {
//         setTimeout(() => {
//             let elem = document.querySelectorAll('select');
//             elem.forEach(element => {
//                 element.setAttribute("tabindex", "0");
//             });
//         }, 1000);
//     }
//     initState = () => {
//         this.setState({
//             schoolFormData: {
//                 name: { value: "", error: "", class: "" },
//                 grade: { value: "", error: "", class: "" },
//                 gender: { value: "", error: "", class: "" }
//             },
//             parentFormData: {
//                 name: { value: "", error: "", class: "" },
//                 grade: { value: "", error: "", class: "" },
//                 gender: { value: "", error: "", class: "" }
//             },
//             addSchoolFormData: {
//                 schoolName: { value: "", error: "", class: "" },
//                 schoolNewName: { value: "", error: "", class: "" },
//             },
//             formData: {
//                 parentName: { value: "", error: "", class: "" },
//                 parentId: { value: "", error: "", class: "" },
//                 relation: { value: "", error: "", class: "" },
//                 dob: { value: "", error: "", class: "" },
//                 email: { value: "", error: "", class: "" },
//                 mobileNumber: { value: "", error: "", class: "" },
//             }
//         })
//     }
//     getSchoolList = async () => {
//         const { config } = this.state
//         const url = `${config.api_url}${urlEndPoints.schoolListWithRep}`
//         try {
//             const data = await this.networkRequest(url)
//             if (data.status == 200)
//                 return data.data;
//             return []
//             // this.setState({ schoolList: data.data })
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//             return [];
//         }
//     }
//     getMyParents = async () => {
//         // const authResponse = await this.auth();
//         // if (authResponse) {
//         const { config } = this.state
//         const { match: { params: { id } } } = this.props;
//         const url = `${config.api_url}${urlEndPoints.viewStudentParent(id)}`
//         // const headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         try {
//             const data = await this.networkRequest(url)
//             if (data.status == 200)
//                 return data.data.results
//             return []
//             // this.setState({ parentList: data.data })
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//             return []
//         }
//         // }
//     }
//     getMySchools = async () => {
//         // const authResponse = await this.auth();
//         // if (authResponse) {
//         const { config } = this.state
//         const { match: { params: { id } } } = this.props;
//         const url = `${config.api_url}${urlEndPoints.viewMySchoolRep(id)}`
//         // const headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         try {
//             const data = await this.networkRequest(url)
//             if (data.status == 200)
//                 return data.data.results
//             return []
//             // this.setState({ mySchoolList: data.data })
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//             return []
//         }
//         // }
//     }
//     handleChange = (field, value, state) => {
//         if (field == "parentQid" || field == "parentId")
//             if (isNaN(value))
//                 return false
//         const tempState = this.state[state];
//         tempState[field]["class"] = value ? "" : "invalid";
//         tempState[field]["value"] = value;
//         this.setState({ [state]: tempState });
//     };
//     renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max, reference }) => {
//         const { formData } = this.state;
//         const { lang } = this.props;
//         return (type == "phone_number" ?
//             <div className="row mobile">
//                 <div className="col s4">
//                     <div className="input-field item">
//                         <input name={`${name}_country_code`}
//                             placeholder="Country Code"
//                             value={this.context.t("_ar_country_code")}
//                             style={dynamicStyles.textAlign(lang)}
//                             id={`${name}_country_code`}
//                             type="text"
//                             minLength="3"
//                             maxLength="3"
//                             disabled
//                             ref={reference}
//                         // className={"validate " + registration_form.mobile.class}
//                         // required
//                         />
//                         <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{this.context.t("_country_code")}</label>
//                         <span className="helper-text" data-error="Enter a valid code."></span>
//                     </div>
//                 </div>
//                 <div className="col s8">
//                     <div className="input-field item">
//                         <input
//                             name={name}
//                             placeholder={placeholder}
//                             ref={(input) => { this[name] = input; }}
//                             aria-label={this.context.t("_phone_aria_label")}
//                             id={name}
//                             maxLength={max}
//                             type="text"
//                             style={dynamicStyles.textAlign(lang)}
//                             // readOnly={type === "date" ? "readonly" : false}
//                             autoComplete="no"
//                             onChange={evt =>
//                                 this.handleChange(name, evt.target.value, "formData")
//                             }
//                             onFocus={evt =>
//                                 this.handleChange(name, evt.target.value, "formData")
//                             }
//                             value={formData[name].value}
//                         // className={"validate " + formData[name].class}
//                         // required={isMandatory}
//                         />
//                         <label htmlFor={name} style={dynamicStyles.position(lang)}>
//                             {`${label} `}
//                             {isMandatory ? <span className="asterisk">*</span> : null}
//                         </label>
//                         <span
//                             style={dynamicStyles.textAlign(lang)}
//                             className="helper-text"
//                             data-error={formData[name].error ? formData[name].error : "Required field."}
//                         ></span>
//                     </div>
//                 </div>
//             </div>
//             :
//             <div className="input-field item">
//                 <input
//                     name={name}
//                     placeholder={placeholder}
//                     ref={(input) => { this[name] = input; }}
//                     id={name}
//                     maxLength={max}
//                     type={type === "date" ? "text" : type}
//                     style={dynamicStyles.textAlign(lang)}
//                     // readOnly={type === "date" ? "readonly" : false}
//                     autoComplete="no"
//                     onChange={evt =>
//                         this.handleChange(name, evt.target.value, "formData")
//                     }
//                     onFocus={evt =>
//                         this.handleChange(name, evt.target.value, "formData")
//                     }
//                     value={formData[name].value}
//                 // className={"validate " + formData[name].class}
//                 // required={isMandatory}
//                 />
//                 <label htmlFor={name} style={dynamicStyles.position(lang)}>
//                     {`${label} `}
//                     {isMandatory ? <span className="asterisk">*</span> : null}
//                 </label>
//                 <span
//                     style={dynamicStyles.textAlign(lang)}
//                     className="helper-text"
//                     data-error={formData[name].error ? formData[name].error : "Required field."}
//                 ></span>
//             </div>)
//     }
//     handleCloseModal = (modalFocus) => {
//         this.initState();
//         let elems = document.querySelector("#checkQid");
//         elems.removeAttribute("aria-hidden")
//         elems.removeAttribute("tabindex")
//         let instance = M.Modal.getInstance(elems);
//         instance.close();
//         elems = document.querySelector("#addParent");
//         elems.removeAttribute("aria-hidden")
//         elems.removeAttribute("tabindex")
//         instance = M.Modal.getInstance(elems);
//         instance.close();
//         setTimeout(() => {
//             elems = document.querySelector("select");
//             M.FormSelect.init(elems)
//         }, 1000);
//         if (modalFocus) {
//             let elems = document.querySelector("#popupmodal");
//             elems.setAttribute("tabindex", "0")
//             document.getElementById("popupmodal") && document.getElementById("popupmodal").focus()
//         } else {
//             document.getElementById("addGuardianBtn") && document.getElementById("addGuardianBtn").focus()
//         }
//     }
//     handleAddParent = () => {
//         this.setState({ isParentExist: false })
//     }
//     validateSchool = (type) => {
//         const { addSchoolFormData } = this.state;
//         for (var key in addSchoolFormData) {
//             addSchoolFormData[key].value = addSchoolFormData[key].value.trim();
//             addSchoolFormData[key].error = "";
//         }
//         if (addSchoolFormData.schoolName.value == "")
//             addSchoolFormData.schoolName.error = this.context.t("_school_name_required");

//         if (addSchoolFormData.schoolName.value == "0" && addSchoolFormData.schoolNewName.value == "")
//             addSchoolFormData.schoolNewName.error = this.context.t("_school_name_required");

//         for (var key in addSchoolFormData) {
//             if (addSchoolFormData[key].error != "") {
//                 this.setState({ addSchoolFormData, submissionStatus: false })
//                 // this.scrollToTop();
//                 // setTimeout(() => {
//                 //     this[key + "error"].focus()
//                 // }, 1000);
//                 return false;

//             }
//         }
//         return true;
//     }
//     validate = (type) => {
//         const { formData } = this.state;
//         for (var key in formData) {
//             formData[key].value = formData[key].value.trim();
//             formData[key].error = "";
//         }
//         if (type == "qid") {
//             if (formData.parentId.value == "")
//                 formData.parentId.error = this.context.t("_qid_required");

//             if (formData.parentId.value != "" && !qidValidate.test(formData.parentId.value))
//                 formData.parentId.error = this.context.t("_invalid_qid");

//             if (formData.relation.value == "")
//                 formData.relation.error = this.context.t("_relationship_required");
//         } else {
//             // if (formData.parentName.value == "")
//             //     formData.parentName.error = this.context.t("_parent_name_required");

//             // if (formData.parentName.value != "" && !nameValidate.test(formData.parentName.value))
//             //     formData.parentName.error = this.context.t("_invalid_parent_name");

//             // if (formData.parentName.value != "" && nameValidate.test(formData.parentName.value) && !nameWordLengthCheck(formData.parentName.value))
//             //     formData.parentName.error = this.context.t("_parent_name_contains_atleast_two_word");

//             // if (formData.dob.value == "")
//             //     formData.dob.error = this.context.t("_date_of_birth_required");

//             if (formData.email.value == "")
//                 formData.email.error = this.context.t("_email_required");

//             if (formData.email.value != "" && !emailValidate.test(formData.email.value))
//                 formData.email.error = this.context.t("_invalid_email");

//             // if (formData.mobileNumber.value == "")
//             //     formData.mobileNumber.error = this.context.t("_mobile_number_required");

//             // if (formData.mobileNumber.value != "" && !phoneValidate.test(formData.mobileNumber.value))
//             //     formData.mobileNumber.error = this.context.t("_invalid_mobile_number");
//         }
//         for (var key in formData) {
//             if (formData[key].error != "") {
//                 this.setState({ formData, submissionStatus: false })
//                 setTimeout(() => {
//                     this.refs[key].focus()
//                 }, 1000);
//                 return false;

//             }
//         }
//         return true;
//     }
//     displayError = error => {
//         const { formData } = this.state;
//         const { lang } = this.props;
//         if (error.qid)
//             formData.parentId.error = error.qid[lang]

//         if (error.name)
//             formData.name.error = error.name[lang]

//         if (error.mail)
//             formData.email.error = error.mail[lang]

//         if (error.DOB)
//             formData.dob.error = error.DOB[lang]

//         for (var key in formData) {
//             if (formData[key].error != "") {
//                 this.setState({ formData, submissionStatus: false })
//                 setTimeout(() => {
//                     this.refs[key].focus()
//                 }, 1000);
//                 return false;

//             }
//         }

//     }
//     displayAddSchoolError = error => {
//         const { addSchoolFormData } = this.state;
//         const { lang } = this.props;
//         if (error.name)
//             addSchoolFormData.schoolName.error = error.name[lang]

//         this.setState({ addSchoolFormData })
//     }
//     handleAddSchool = async event => {
//         event.preventDefault();
//         const { addSchoolFormData, schoolList, config, showAddSchool } = this.state;
//         this.props.showLoader()
//         if (this.validateSchool()) {
//             // const authResponse = await this.auth();
//             // if (authResponse) {
//             const { match: { params: { id } } } = this.props;
//             let schoolName = schoolList.filter(item => item.tid == addSchoolFormData.schoolName.value)
//             if (addSchoolFormData.schoolName.value == "0")
//                 schoolName = addSchoolFormData.schoolNewName.value
//             else
//                 schoolName = schoolName[0].name
//             const obj = {
//                 "school_id": {
//                     "target_id": addSchoolFormData.schoolName.value,
//                     "target_type": "taxonomy_term",
//                 },
//                 "school_name": schoolName,
//                 "school_update": showAddSchool,
//                 id
//             }
//             const url = `${config.api_url}${urlEndPoints.viewAddSchool}`
//             // const headers = {
//             //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//             // }
//             try {
//                 const data = await this.networkRequest(url, "POST", obj)
//                 if (data.status == 200) {
//                     if (data.data.error) {
//                         this.displayAddSchoolError(data.data.error)
//                     } else {
//                         const mySchoolList = await this.getMySchools();
//                         if (addSchoolFormData.schoolName.value == "0" || showAddSchool) {
//                             this.props.showPopup({
//                                 type: "success",
//                                 title: this.context.t("_success"),
//                                 message: this.context.t("_notified_to_admin"),
//                                 isOpen: true,
//                                 buttonText: this.context.t("_ok")
//                             })
//                         }
//                         this.setState({
//                             mySchoolList, addSchoolFormData: {
//                                 schoolName: { value: "", error: "", class: "" },
//                                 schoolNewName: { value: "", error: "", class: "" },
//                             },
//                             showAddSchool: false
//                         })
//                     }

//                     this.props.hideLoader()
//                 }
//                 else {
//                     this.props.hideLoader()
//                 }
//             } catch (error) {
//                 this.props.hideLoader()
//                 console.warn("error: ", error)
//             }
//             // } else {
//             //     this.props.hideLoader()
//             // }
//         } else {
//             this.props.hideLoader()
//         }

//     }
//     handleCheckQid = async (event) => {
//         event.preventDefault();
//         const { formData, config } = this.state;

//         if (this.validate("qid")) {
//             this.props.showLoader()
//             // const authResponse = await this.auth();
//             // if (authResponse) {
//             const { match: { params: { id } } } = this.props;
//             const obj = {
//                 field_qid: formData.parentId.value,
//                 field_relation_to_the_student: formData.relation.value,
//                 id
//             }
//             const url = `${config.api_url}${urlEndPoints.viewCheckQid}`
//             // const headers = {
//             //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//             // }
//             try {
//                 const data = await this.networkRequest(url, "POST", obj)
//                 if (data.status == 200) {
//                     if (data.data.error) {
//                         this.props.hideLoader();
//                         this.displayError(data.data.error);
//                     } else {
//                         if (!data.data.user) {
//                             this.props.hideLoader();
//                             this.handleOpenAddStudentModal();
//                         } else {
//                             const parentList = await this.getMyParents();
//                             this.setState({ parentList });
//                             this.props.hideLoader();
//                             this.handleCloseModal();
//                         }
//                     }
//                 }
//             } catch (error) {
//                 this.props.hideLoader()
//                 console.warn("error: ", error)
//             }
//             // } else {
//             //     this.props.hideLoader()
//             // }
//         }
//     }
//     handleOpenAddStudentModal = () => {
//         const { formData } = this.state;
//         for (var key in formData) {
//             formData[key].error = "";
//         }
//         this.setState({ formData })
//         let elems = document.querySelector("#checkQid");
//         elems.removeAttribute("aria-hidden")
//         elems.removeAttribute("tabindex")
//         let instance = M.Modal.getInstance(elems);
//         instance.close();
//         elems = document.querySelector("#addParent");
//         elems.setAttribute("aria-hidden", "false")
//         elems.setAttribute("tabindex", "-1")
//         instance = M.Modal.getInstance(elems);
//         instance.open();
//         document.getElementById("email") && document.getElementById("email").focus();
//     }
//     handleOpenQidCheck = () => {
//         const { formData } = this.state;
//         for (var key in formData) {
//             formData[key].error = "";
//         }
//         this.setState({ formData })
//         let elems = document.querySelector("#checkQid");
//         elems.setAttribute("aria-hidden", "false")
//         elems.setAttribute("tabindex", "-1")
//         let instance = M.Modal.getInstance(elems);
//         instance.open();
//         document.getElementById("parentId") && document.getElementById("parentId").focus();
//     }
//     handleAddNewParent = async (event) => {
//         event.preventDefault();
//         const { formData, config } = this.state;
//         const { lang } = this.props
//         if (this.validate()) {
//             this.props.showLoader()
//             // const authResponse = await this.auth();
//             // if (authResponse) {
//             let obj = {
//                 email: formData.email.value
//             }
//             const url = `${config.api_url}${urlEndPoints.sendNotification}`
//             // const headers = {
//             //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//             // }
//             try {
//                 const data = await this.networkRequest(url, "POST", obj)
//                 if (data.data.error) {
//                     this.displayError(data.data.error)
//                     this.props.hideLoader()
//                 }
//                 else {
//                     this.props.showPopup({
//                         type: "success",
//                         title: this.context.t("_success"),
//                         message: data.data.message[lang],
//                         isOpen: true,
//                         buttonText: this.context.t("_ok")
//                     })
//                     this.handleCloseModal(true);
//                     const parentList = await this.getMyParents();
//                     this.setState({ submissionStatus: true, parentList });
//                     this.props.hideLoader();
//                 }
//             } catch (error) {
//                 this.props.hideLoader()
//                 console.warn("error: ", error)
//             }
//             // } else {
//             //     this.props.hideLoader()
//             // }
//         }
//     }
//     handleRemove = async userId => {
//         this.props.showLoader()
//         const { config } = this.state;
//         // const authResponse = await this.auth();
//         const { match: { params: { id } } } = this.props;
//         // if (authResponse) {
//         let obj = {
//             "linked_user": userId,
//             id
//         }
//         const url = `${config.api_url}${urlEndPoints.viewProfileLinking}`
//         // const headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         try {
//             const data = await this.networkRequest(url, "POST", obj)
//             if (data.data.error) {
//                 this.props.hideLoader()
//                 this.props.showPopup({
//                     type: "error",
//                     title: this.context.t("_failed"),
//                     message: this.context.t("_student_unlink_failed"),
//                     isOpen: true,
//                     buttonText: this.context.t("_ok")
//                 })
//             }
//             else {
//                 const parentList = await this.getMyParents();
//                 this.setState({ parentList })
//                 this.props.hideLoader();
//             }
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//         // } else {
//         //     this.props.hideLoader()
//         // }
//     }

//     handleRemoveSchool = async sId => {
//         this.props.showLoader()
//         const { config } = this.state;
//         // const authResponse = await this.auth();
//         // if (authResponse) {
//         const { match: { params: { id } } } = this.props;
//         const obj = {
//             "school_id": sId,
//             id
//         }
//         const url = `${config.api_url}${urlEndPoints.viewRemoveSchool}`
//         // const headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         try {
//             const data = await this.networkRequest(url, "POST", obj)
//             if (data.data.error) {
//                 this.props.hideLoader()
//                 this.props.showPopup({
//                     type: "error",
//                     title: this.context.t("_failed"),
//                     message: this.context.t("_school_unlinking_failed"),
//                     isOpen: true,
//                     buttonText: this.context.t("_ok")
//                 })
//             }
//             else {
//                 this.props.showPopup({
//                     type: "success",
//                     title: this.context.t("_success"),
//                     message: this.context.t("_school_unlinking_success"),
//                     isOpen: true,
//                     buttonText: this.context.t("_ok")
//                 })
//                 const mySchoolList = await this.getMySchools();
//                 this.setState({ mySchoolList })
//                 this.props.hideLoader()
//                 M.AutoInit()
//             }
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//         // } else {
//         //     this.props.hideLoader()
//         // }
//     }
//     handleCloseConfirmModal = (state) => {
//         this.setState({ [state]: false, removeSchoolId: null })
//     }
//     handleConfirmRemoveSchool = () => {
//         this.setState({ showAddSchool: true })
//         // const { removeSchoolId } = this.state;
//         // if (!_.isEmpty(removeSchoolId))
//         //     this.handleRemoveSchool(removeSchoolId);
//         setTimeout(() => {
//             var elems = document.querySelectorAll("select");
//             M.FormSelect.init(elems);
//         }, 100);
//         this.handleCloseConfirmModal("removeSchoolConfirm")
//     }
//     handleOpenRemoveSchoolConfirm = id => {
//         this.setState({ removeSchoolConfirm: true, removeSchoolId: id })
//     }
//     render() {
//         const { schoolList, removeSchoolConfirm, showAddSchool, parentList, mySchoolList, addSchoolFormData, formData } = this.state;
//         const { lang } = this.props;
//         const { match: { params: { id } } } = this.props;
//         let hasError = false;
//         Object.keys(formData).map((item, index) => {
//             if (formData[item].error != "")
//                 hasError = true
//         })
//         return (

//             <div id="main-container">
//                 <SlideToTop />
//                 <PageTitle title={this.context.t("_manage_connections")} />
//                 <div id="skipContent" className="container">
//                     <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
//                         <ul>
//                             <li className="breadcrumb-item">
//                                 <Link to="/profile">{this.context.t("_profile")}</Link> {" "}

//                             </li>
//                             <li className="breadcrumb-item">
//                                 <Link to={`/student-profile/${id}`}>{this.context.t("_participants")}</Link> {" "}

//                             </li>

//                             <li className="breadcrumb-item">
//                                 <Link tabIndex="0" to aria-current={this.context.t("_page")}>
//                                     <span>
//                                         {this.context.t("_manage_connections")}
//                                     </span>
//                                 </Link>
//                             </li>
//                         </ul>
//                     </nav>
//                 </div>
//                 <div>
//                     <div className="container">

//                         <h2>{this.context.t("_parents")}</h2>
//                         {
//                             !_.isEmpty(parentList) &&
//                             <div className="tableHolder">
//                                 <table className="responsive-table highlight">
//                                     <thead className="blue lighten-5">
//                                         <tr>
//                                             <th><strong>{this.context.t("_name")}</strong></th>
//                                             <th><strong>{this.context.t("_qid")}</strong></th>
//                                             <th><strong>{this.context.t("_phone_number")}</strong></th>
//                                             <th><strong>{this.context.t("_relation_to_student")}</strong></th>
//                                             {/* <th><strong>{this.context.t("_status")}</strong></th> */}
//                                             {/* <th width="148"><strong></strong></th> */}
//                                             {/* <th width="150"><strong></strong></th> */}
//                                         </tr>
//                                     </thead>

//                                     <tbody>
//                                         {
//                                             parentList.map(item => {
//                                                 return (
//                                                     <tr key={item.uid}>
//                                                         <td>{item.field_parent_name}</td>
//                                                         <td>{item.field_parent_qid}</td>
//                                                         <td>{item.field_parent_mobile_number}</td>
//                                                         <td>{item.field_relation_with_student}</td>
//                                                         {/* <td>{item.status == "On" ? "Approved" : "Blocked"}</td> */}
//                                                         {/* <td><button className="btn profileBtn">{this.context.t("_view_profile")}</button></td> */}
//                                                         {/* <td><button onClick={() => { this.handleRemove(item.uid) }} className="btn profileBtn">{this.context.t("_remove")}</button></td> */}
//                                                     </tr>
//                                                 )
//                                             })
//                                         }
//                                     </tbody>
//                                 </table>
//                             </div>
//                         }
//                         {
//                             parentList.length < 2 && <button id="addGuardianBtn" className="waves-effect waves-light btn profileBtn" onClick={() => { this.handleOpenQidCheck() }}>{this.context.t("_add_guardian")}</button>
//                         }

//                         <div id="checkQid" className="modal sm" role="dialog">
//                             <div className="modal-content modal-no-close">
//                                 {
//                                     _.isEmpty(formData) ?
//                                         <div /> :
//                                         <div>
//                                             <span className="badge modalClose"><a onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></a></span>
//                                             <h2 className="line">{this.context.t("_add_guardian")}</h2>
//                                             {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit.</p> */}
//                                             {hasError ? <div className="error-container fail">
//                                                 <h5>{this.context.t("_error_title")}</h5>
//                                                 <ul>
//                                                     {formData.parentId.error != "" ? <li ><a ref="parentId" tabIndex="0" href="#parentId" className="error" htmlFor="parentId">{formData.parentId.error}</a></li> : null}

//                                                     {formData.relation.error != "" ? <li ><a ref="relation" tabIndex="0" href="#relation" className="error" htmlFor="relation">{formData.relation.error}</a></li> : null}
//                                                 </ul>
//                                             </div> : null
//                                             }
//                                             <form onSubmit={this.handleCheckQid} className="row full-width">
//                                                 <div className="col s12">
//                                                     <div className="input-field item">
//                                                         <input
//                                                             placeholder={this.context.t("_patent_qid_placeholder")}
//                                                             type="text"
//                                                             name="parentId"
//                                                             id="parentId"
//                                                             maxLength={11}
//                                                             onChange={(evt) => this.handleChange("parentId", evt.target.value, "formData")}
//                                                             onFocus={(evt) => this.handleChange("parentId", evt.target.value, "formData")}
//                                                             value={formData.parentId.value}
//                                                         />
//                                                         <label htmlFor="parentId" style={dynamicStyles.position(lang)}>{this.context.t("_patent_qid")} <span className="asterisk">*</span></label>
//                                                     </div>

//                                                     <div className="input-field mobileSelect">
//                                                         <label htmlFor="relation" style={dynamicStyles.position(lang)}>
//                                                             {this.context.t("_select_relation_1")}<span className="asterisk">*</span>
//                                                         </label>
//                                                         <select
//                                                             name="relation"
//                                                             id="relation"
//                                                             className="browser-default"
//                                                             onChange={(evt) => this.handleChange("relation", evt.target.value, "formData")}
//                                                             value={formData.relation.value}
//                                                         >
//                                                             <option value="" disabled> {this.context.t("_select_relation")} </option>
//                                                             <option value="Father">{this.context.t("_father")}</option>
//                                                             <option value="Mother">{this.context.t("_mother")}</option>
//                                                             <option value="Sister">{this.context.t("_sister")}</option>
//                                                             <option value="Brother">{this.context.t("_brother")}</option>
//                                                             <option value="Grandmother">{this.context.t("_grand_mother")}</option>
//                                                             <option value="Grandfather">{this.context.t("_grand_father")}</option>
//                                                             <option value="Guardian">{this.context.t("_parent")}</option>
//                                                         </select>
//                                                         <span
//                                                             style={dynamicStyles.textAlign(lang)}
//                                                             className="helper-text"
//                                                             data-error="Required field."
//                                                         ></span>
//                                                     </div>
//                                                 </div>

//                                                 <div className="col s12">
//                                                     <button type="button" onClick={this.handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{this.context.t("_cancel")}</button>
//                                                     <button className="btn profileBtn blue noWidth waves-effect waves-light">{this.context.t("_add")}</button>
//                                                 </div>
//                                             </form>
//                                         </div>
//                                 }
//                             </div>
//                         </div>
//                         <div id="addParent" className="modal sm user-add-modal" role="dialog">
//                             <div className="modal-content modal-no-close">
//                                 {
//                                     _.isEmpty(formData) ?
//                                         <div /> :
//                                         <form onSubmit={this.handleAddNewParent} className="col full-width">
//                                             <span className="badge modalClose"><a onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></a></span>
//                                             <h2 className="line">{this.context.t("_add_guardian")}</h2>

//                                             {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p> */}
//                                             {hasError ? <div className="error-container fail">
//                                                 <h5>{this.context.t("_error_title")}</h5>
//                                                 <ul>
//                                                     {formData.email.error != "" ? <li ><a ref="email" tabIndex="0" href="#email" className="error" htmlFor="email">{formData.email.error}</a></li> : null}
//                                                 </ul>
//                                             </div> : null
//                                             }
//                                             <p>{this.context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

//                                             <div className="row">
//                                                 <div className="col s12">
//                                                     {/* {this.renderInput({ name: "parentName", label: this.context.t("_parent_name"), placeholder: this.context.t("_name_placeholder"), isMandatory: true })} */}
//                                                     {this.renderInput({ name: "email", label: this.context.t("_parent_email_address"), placeholder: this.context.t("_parent_email_address_placeholder"), type: "email", reference: "email" })}

//                                                 </div>
//                                                 {/* <div className="col s12 m6">
//                                                     <div className="date-popup">
//                                                         {this.renderInput({ name: "dob", label: this.context.t("_dob"), placeholder: this.context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
//                                                     </div>

//                                                     {this.renderInput({ name: "mobileNumber", label: this.context.t("_parent_mobile_number"), placeholder: this.context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number" })}
//                                                 </div> */}
//                                                 <div className="col s12">
//                                                     <button className="btn profileBtn blue waves-effect waves-light">{this.context.t("_save_changes")}</button>
//                                                     <button type="button" onClick={this.handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{this.context.t("_cancel")}</button>
//                                                 </div>
//                                                 <div className="clearfix" />
//                                             </div>
//                                         </form>
//                                 }
//                             </div>
//                         </div>
//                     </div>

//                     <br /><br />
//                     {/* <div className="container">
//                         <h2>{this.context.t("_schools")}</h2>
//                         {
//                             !_.isEmpty(mySchoolList) &&
//                             <div className="tableHolder">
//                                 <table className="responsive-table highlight">
//                                     <thead className="blue lighten-5">
//                                         <tr>
//                                             <th><strong>{this.context.t("_name")}</strong></th>
//                                             <th><strong>{this.context.t("_phone_number")}</strong></th>
//                                             <th><strong>{this.context.t("_status")}</strong></th>
//                                             {/* <th width="148"><strong></strong></th> 
//                                             <th width="140"><strong></strong></th>
//                                         </tr>
//                                     </thead>

//                                     <tbody>
//                                         {
//                                             mySchoolList.map(item => {
//                                                 return (
//                                                     <tr key={item.field_school_1}>
//                                                         <td>{item.field_school}</td>
//                                                         <td>{item.field_rep_mobile_number_}</td>
//                                                         <td>{item.status == "1" ? "Approved" : "Blocked"}</td>
//                                                         {/* <td><button className="btn profileBtn">{this.context.t("_view_profile")}</button></td> 
//                                                         <td><button onClick={() => this.handleOpenRemoveSchoolConfirm(item.field_school_1)} className="btn profileBtn">{this.context.t("_edit")}</button></td>
//                                                     </tr>
//                                                 )
//                                             })
//                                         }
//                                     </tbody>
//                                 </table>
//                             </div>
//                         }
//                         {
//                             _.isEmpty(addSchoolFormData) ?
//                                 <div /> :
//                                 <>
//                                     {
//                                         addSchoolFormData.schoolName.error != "" || addSchoolFormData.schoolNewName.error != "" ? <div className="error-container fail">
//                                             <h5>{this.context.t("_error_title")}</h5>
//                                             <ul>
//                                                 {
//                                                     addSchoolFormData.schoolName.error != "" && <li><label className="error" htmlFor="schoolName">{addSchoolFormData.schoolName.error}</label></li>
//                                                 }
//                                                 {
//                                                     addSchoolFormData.schoolNewName.error != "" && <li><label className="error" htmlFor="schoolNewName">{addSchoolFormData.schoolNewName.error}</label></li>
//                                                 }

//                                             </ul>
//                                         </div> : null
//                                     }
//                                 </>

//                         }
//                         {
//                             (_.isEmpty(mySchoolList) || showAddSchool) && <form onSubmit={this.handleAddSchool}>
//                                 <div className="row topFormHolder noBorder">
//                                     {
//                                         _.isEmpty(addSchoolFormData) ?
//                                             <div /> :
//                                             <div className="col s12 m6 l10">
//                                                 <div className={`input-field mobileSelect item ${lang}`}>
//                                                     <select
//                                                         defaultValue=""
//                                                         id="schoolName"
//                                                         name="schoolName"
//                                                         onChange={evt =>
//                                                             this.handleChange("schoolName", evt.target.value, "addSchoolFormData")
//                                                         }>
//                                                         <option value={addSchoolFormData.schoolName.value} disabled>{this.context.t("_choose_school")}</option>
//                                                         {
//                                                             schoolList.map(item => {
//                                                                 return <option key={item.tid} value={item.tid}>{item.name}</option>
//                                                             })
//                                                         }
//                                                         <option value="0">Other</option>
//                                                     </select>

//                                                     <span className="helper-text"></span>
//                                                 </div>
//                                                 {
//                                                     addSchoolFormData.schoolName.value == "0" ?
//                                                         <div className="input-field item">
//                                                             <label className="active">{this.context.t("_school_name")} <span className="asterisk">*</span></label>
//                                                             <input
//                                                                 id="schoolNewName"
//                                                                 name="schoolNewName"
//                                                                 onChange={evt =>
//                                                                     this.handleChange("schoolNewName", evt.target.value, "addSchoolFormData")
//                                                                 }
//                                                                 onFocus={evt =>
//                                                                     this.handleChange("schoolNewName", evt.target.value, "addSchoolFormData")
//                                                                 }
//                                                                 value={addSchoolFormData.schoolNewName.value}
//                                                                 placeholder={this.context.t("_select_school_name")}
//                                                                 type="text"
//                                                             />
//                                                             <span className="helper-text"></span>
//                                                         </div>
//                                                         :
//                                                         null
//                                                 }

//                                             </div>
//                                     }
//                                     <div className="col s12 m6 l2 valign-wrapper add-school-btn-container center-align justify-center">
//                                         <button className="btn profileBtn">
//                                             {this.context.t("_add_school")}
//                                         </button>
//                                         <br /><br />
//                                     </div>
//                                 </div>
//                             </form>
//                         }

//                     </div> */}

//                 </div>
//                 <div className="top-line ng-scope">
//                     <img src={topLineImage} alt="" />
//                 </div>

//                 <Modal
//                     isOpen={removeSchoolConfirm}
//                     style={customModalStyles}
//                     contentLabel="unlink parent confirm modal"
//                     ariaHideApp={false}
//                 >
//                     <div className="popupAlert warning">
//                         <br />
//                         <h3>{this.context.t("_confirm")}</h3>
//                         <p>{this.context.t("_edit_school_confirmation")}</p>
//                         <div className="row">
//                             <button className="col btn blue" onClick={() => this.handleCloseConfirmModal("removeSchoolConfirm")}>{this.context.t("_no")}</button>
//                             <button className="col btn profileBtn yellow" onClick={() => this.handleConfirmRemoveSchool()}>{this.context.t("_yes")}</button>
//                         </div>
//                     </div>
//                 </Modal>
//             </div>
//         )
//     }

// }

// const mapStateToProps = ({ i18nState }) => {
//     return {
//         lang: i18nState.lang
//     }
// }
// const mapDispatchToProps = {
//     showLoader: LoadingAction.showLoader,
//     hideLoader: LoadingAction.hideLoader,
//     showPopup: (data) => PopupAction.showPopup(data),
//     hidePopup: (data) => PopupAction.hidePopup(data),
// }
// ViewStudentConnections.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(ViewStudentConnections);




import React from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash"
import Main from "../../../constants/utilities.js";
import PageTitle from "../../includes/page_title.js";
import SlideToTop from "../../includes/slide_to_top.js";
import {  useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles, customModalStyles } from "./../../../constants/dynamicStyles"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import { nameWordLengthCheck, qidValidate, nameValidate, emailValidate, phoneValidate, calenderI18n } from "./../../../constants/common"
import M from "materialize-css";
import { LoadingAction, PopupAction } from "./../../../actions"
import Modal from "react-modal";
import dateformat from "dateformat";
import topLineImage from "../../../assets/images/top-line.png"
import { useState } from "react";
import { useEffect } from "react";

const ViewStudentConnections = (props, context) => {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         schoolFormData: {},
    //         parentFormData: {},
    //         addSchoolFormData: {},
    //         formData: {},
    //         config: this.config(),
    //         schoolList: [],
    //         parentList: [],
    //         mySchoolList: [],
    //         isParentExist: true,
    //         intervalId: 0,
    //         scrollStepInPx: 50,
    //         delayInMs: 16.66,
    //         removeSchoolId: null,
    //         showAddSchool: false
    //     };
    //     this.props.showLoader()
    // }
    const { config, networkRequest } = Main();


    const [schoolFormData, setSchoolFormData] = useState({});
    const [parentFormData, setParentFormData] = useState({});
    const [addSchoolFormData, setAddSchoolFormData] = useState({});
    const [formData, setFormData] = useState({});
    const [schoolList, setSchoolList] = useState([]);
    const [parentList, setParentList] = useState([]);
    const [mySchoolList, setMySchoolList] = useState([]);
    const [isParentExist, setIsParentExist] = useState(true);
 
    const [removeSchoolId, setRemoveSchoolId] = useState(null);
    const [showAddSchool, setShowAddSchool] = useState(false);
    const [removeSchoolConfirm, setRemoveSchoolConfirm] = useState(false);
    const [configuration, setConfiguration] = useState(config())
    const [submissionStatus, setSubmissionStatus] = useState(false);

    const intervalId=0
    const scrollStepInPx=50
    const delayInMs=16.66

    const { lang } = useSelector((state) => state.i18nState)
    const dispatch = useDispatch()
    const { id } = useParams()

    useEffect( () => {
        dispatch(LoadingAction.showLoader())

        const fetchData = async () => {
            document.title = `${context.t("_studio56")} | ${context.t("_profile")} | ${context.t("_manage_connections")}`
            await initState();
            const schoolList = await getSchoolList();
            const parentList = await getMyParents();
            const mySchoolList = await getMySchools();
            // this.setState({ schoolList, mySchoolList, parentList })
            setSchoolList(schoolList)
            setMySchoolList(mySchoolList)
            setParentList(parentList)
            M.AutoInit();
            dispatch(LoadingAction.hideLoader())
            const options = {
                autoClose: true,
                dismissible: true
            };
            const dobOptions = {
                autoClose: true,
                onSelect: ((date) => {
                    handleChange("dob", dateformat(date, "dd/mm/yyyy"), "formData")
                }),
                format: "dd/mm/yyyy",
                container: null,
                minDate: new Date("01/01/1950"),
            };
            setTimeout(() => {
                let elems = document.querySelectorAll("#dob");
                dobOptions.i18n = calenderI18n(context);
                M.Datepicker.init(elems, dobOptions);
                elems = document.querySelectorAll(".datepicker");
                M.Datepicker.init(elems, dobOptions);
                elems = document.querySelectorAll(".modal");
                M.Modal.init(elems, options);
                elems = document.querySelector("select");
                M.FormSelect.init(elems)
            }, 200);
            window.addEventListener('load', handleTabIndex());
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        }
        fetchData()
    }, []);

    // componentDidUpdate() {
    //     document.title = `${context.t("_studio56")} | ${context.t("_profile")} | ${context.t("_manage_connections")}`
    // }

    const handleTabIndex = () => {
        setTimeout(() => {
            let elem = document.querySelectorAll('select');
            elem.forEach(element => {
                element.setAttribute("tabindex", "0");
            });
        }, 1000);
    }
    const initState = () => {
        setSchoolFormData({
            name: { value: "", error: "", class: "" },
            grade: { value: "", error: "", class: "" },
            gender: { value: "", error: "", class: "" }
        })
        setParentFormData({
            name: { value: "", error: "", class: "" },
            grade: { value: "", error: "", class: "" },
            gender: { value: "", error: "", class: "" }
        })
        setAddSchoolFormData({
            schoolName: { value: "", error: "", class: "" },
            schoolNewName: { value: "", error: "", class: "" },
        })
        setFormData({
            parentName: { value: "", error: "", class: "" },
            parentId: { value: "", error: "", class: "" },
            relation: { value: "", error: "", class: "" },
            dob: { value: "", error: "", class: "" },
            email: { value: "", error: "", class: "" },
            mobileNumber: { value: "", error: "", class: "" },
        })

    }
    const getSchoolList = async () => {
        const url = `${configuration.api_url}${urlEndPoints.schoolListWithRep}`
        try {
            const data = await networkRequest(url)
            if (data.status == 200)
                return data.data;
            return []
            // this.setState({ schoolList: data.data })
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
            return [];
        }
    }
    const getMyParents = async () => {
        // const authResponse = await this.auth();
        // if (authResponse) {

        const url = `${configuration.api_url}${urlEndPoints.viewStudentParent(id)}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await networkRequest(url)
            if (data.status == 200)
                return data.data.results
            return []
            // this.setState({ parentList: data.data })
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
            return []
        }
        // }
    }
    const getMySchools = async () => {
        // const authResponse = await this.auth();
        // if (authResponse) {

        const url = `${configuration.api_url}${urlEndPoints.viewMySchoolRep(id)}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await networkRequest(url)
            if (data.status == 200)
                return data.data.results
            return []
            // this.setState({ mySchoolList: data.data })
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
            return []
        }
        // }
    }
    const handleChange = (field, value, state) => {
        if (field == "parentQid" || field == "parentId")
            if (isNaN(value))
                return false
        let tempState;

        if (state === "formData") {
            tempState = { ...formData }; // Assuming formData is a state variable
        } else if (state === "addSchoolFormData") {
            tempState = { ...addSchoolFormData }; // Assuming addSchoolFormData is a state variable
        }
        tempState[field]["class"] = value ? "" : "invalid";
        tempState[field]["value"] = value;

        if (state === "formData") {
            setFormData(tempState);
        } else if (state === "addSchoolFormData") {
            setAddSchoolFormData(tempState);
        }
        // this.setState({ [state]: tempState });
    };

    // const handleChange = (field, value, state) => {
    //     if (field === "parentQid" && isNaN(value)) {
    //         return false;
    //     }

    //     let tempState;

    //     if (state === "formData") {
    //         tempState = { ...formData }; // Assuming formData is a state variable
    //       } else if (state === "addSchoolFormData") {
    //         tempState = { ...addSchoolFormData }; // Assuming addSchoolFormData is a state variable
    //       }

    //     tempState[field]["class"] = value ? "" : "invalid";
    //     tempState[field]["value"] = value;
    //     if (state === "formData") {
    //         setFormData({ ...tempState });
    //     } else if (state === "addSchoolFormData") {
    //         setAddSchoolFormData({ ...tempState })
    //     }
    // };
    const renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max, reference }) => {

        return (type == "phone_number" ?
            <div className="row mobile">
                <div className="col s4">
                    <div className="input-field item">
                        <input name={`${name}_country_code`}
                            placeholder="Country Code"
                            value={context.t("_ar_country_code")}
                            style={dynamicStyles.textAlign(lang)}
                            id={`${name}_country_code`}
                            type="text"
                            minLength="3"
                            maxLength="3"
                            disabled
                            ref={reference}
                        // className={"validate " + registration_form.mobile.class}
                        // required
                        />
                        <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{context.t("_country_code")}</label>
                        <span className="helper-text" data-error="Enter a valid code."></span>
                    </div>
                </div>
                <div className="col s8">
                    <div className="input-field item">
                        <input
                            name={name}
                            placeholder={placeholder}
                            ref={(input) => { this[name] = input; }}
                            aria-label={context.t("_phone_aria_label")}
                            id={name}
                            maxLength={max}
                            type="text"
                            style={dynamicStyles.textAlign(lang)}
                            // readOnly={type === "date" ? "readonly" : false}
                            autoComplete="no"
                            onChange={evt =>
                                handleChange(name, evt.target.value, "formData")
                            }
                            onFocus={evt =>
                                handleChange(name, evt.target.value, "formData")
                            }
                            value={formData[name].value}
                        // className={"validate " + formData[name].class}
                        // required={isMandatory}
                        />
                        <label htmlFor={name} style={dynamicStyles.position(lang)}>
                            {`${label} `}
                            {isMandatory ? <span className="asterisk">*</span> : null}
                        </label>
                        <span
                            style={dynamicStyles.textAlign(lang)}
                            className="helper-text"
                            data-error={formData[name].error ? formData[name].error : "Required field."}
                        ></span>
                    </div>
                </div>
            </div>
            :
            <div className="input-field item">
                <input
                    name={name}
                    placeholder={placeholder}
                    // ref={(input) => { this[name] = input; }}
                    id={name}
                    maxLength={max}
                    type={type === "date" ? "text" : type}
                    style={dynamicStyles.textAlign(lang)}
                    // readOnly={type === "date" ? "readonly" : false}
                    autoComplete="no"
                    onChange={evt =>
                        handleChange(name, evt.target.value, "formData")
                    }
                    onFocus={evt =>
                        handleChange(name, evt.target.value, "formData")
                    }
                    value={formData[name].value}
                // className={"validate " + formData[name].class}
                // required={isMandatory}
                />
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <span
                    style={dynamicStyles.textAlign(lang)}
                    className="helper-text"
                    data-error={formData[name].error ? formData[name].error : "Required field."}
                ></span>
            </div>)
    }
    const handleCloseModal = (modalFocus) => {
        initState();
        let elems = document.querySelector("#checkQid");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addParent");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        instance = M.Modal.getInstance(elems);
        instance.close();
        setTimeout(() => {
            elems = document.querySelector("select");
            M.FormSelect.init(elems)
        }, 1000);
        if (modalFocus) {
            let elems = document.querySelector("#popupmodal");
            elems.setAttribute("tabindex", "0")
            document.getElementById("popupmodal") && document.getElementById("popupmodal").focus()
        } else {
            document.getElementById("addGuardianBtn") && document.getElementById("addGuardianBtn").focus()
        }
    }
    const handleAddParent = () => {
        setIsParentExist(false)
        // this.setState({ isParentExist: false })
    }
    const validateSchool = (type) => {
        for (var key in addSchoolFormData) {
            addSchoolFormData[key].value = addSchoolFormData[key].value.trim();
            addSchoolFormData[key].error = "";
        }
        if (addSchoolFormData.schoolName.value == "")
            addSchoolFormData.schoolName.error = context.t("_school_name_required");

        if (addSchoolFormData.schoolName.value == "0" && addSchoolFormData.schoolNewName.value == "")
            addSchoolFormData.schoolNewName.error = context.t("_school_name_required");

        for (var key in addSchoolFormData) {
            if (addSchoolFormData[key].error != "") {
                setAddSchoolFormData({ ...addSchoolFormData })
                setSubmissionStatus(false)
                // this.setState({ addSchoolFormData, submissionStatus: false })
                // this.scrollToTop();
                // setTimeout(() => {
                //     this[key + "error"].focus()
                // }, 1000);
                return false;

            }
        }
        return true;
    }
    const validate = (type) => {
        for (var key in formData) {
            formData[key].value = formData[key].value.trim();
            formData[key].error = "";
        }
        if (type == "qid") {
            if (formData.parentId.value == "")
                formData.parentId.error = context.t("_qid_required");

            if (formData.parentId.value != "" && !qidValidate.test(formData.parentId.value))
                formData.parentId.error = context.t("_invalid_qid");

            if (formData.relation.value == "")
                formData.relation.error = context.t("_relationship_required");
        } else {
            // if (formData.parentName.value == "")
            //     formData.parentName.error = context.t("_parent_name_required");

            // if (formData.parentName.value != "" && !nameValidate.test(formData.parentName.value))
            //     formData.parentName.error = context.t("_invalid_parent_name");

            // if (formData.parentName.value != "" && nameValidate.test(formData.parentName.value) && !nameWordLengthCheck(formData.parentName.value))
            //     formData.parentName.error = context.t("_parent_name_contains_atleast_two_word");

            // if (formData.dob.value == "")
            //     formData.dob.error = context.t("_date_of_birth_required");

            if (formData.email.value == "")
                formData.email.error = context.t("_email_required");

            if (formData.email.value != "" && !emailValidate.test(formData.email.value))
                formData.email.error = context.t("_invalid_email");

            // if (formData.mobileNumber.value == "")
            //     formData.mobileNumber.error = context.t("_mobile_number_required");

            // if (formData.mobileNumber.value != "" && !phoneValidate.test(formData.mobileNumber.value))
            //     formData.mobileNumber.error = context.t("_invalid_mobile_number");
        }
        for (var key in formData) {
            if (formData[key].error != "") {
                setFormData({ ...formData })
                setSubmissionStatus(false)
                // this.setState({ formData, submissionStatus: false })
                setTimeout(() => {
                    document.querySelector(`a[href="#${key}"]`).focus()
                }, 1000);
                return false;

            }
        }
        return true;
    }
    const displayError = error => {

        if (error.qid)
            formData.parentId.error = error.qid[lang]

        if (error.name)
            formData.name.error = error.name[lang]

        if (error.mail)
            formData.email.error = error.mail[lang]

        if (error.DOB)
            formData.dob.error = error.DOB[lang]

        for (var key in formData) {
            if (formData[key].error != "") {
                setFormData({ ...formData })
                setSubmissionStatus(false)
                // this.setState({ formData, submissionStatus: false })
                setTimeout(() => {
                    document.querySelector(`a[href="#${key}"]`).focus()
                }, 1000);
                return false;

            }
        }

    }
    const displayAddSchoolError = error => {
        if (error.name)
            addSchoolFormData.schoolName.error = error.name[lang]

        // this.setState({ addSchoolFormData })
        setAddSchoolFormData({ ...addSchoolFormData })
    }
    const handleAddSchool = async event => {
        event.preventDefault();
        dispatch(LoadingAction.showLoader())
        if (validateSchool()) {
            // const authResponse = await this.auth();
            // if (authResponse) {
            let schoolName = schoolList.filter(item => item.tid == addSchoolFormData.schoolName.value)
            if (addSchoolFormData.schoolName.value == "0")
                schoolName = addSchoolFormData.schoolNewName.value
            else
                schoolName = schoolName[0].name
            const obj = {
                "school_id": {
                    "target_id": addSchoolFormData.schoolName.value,
                    "target_type": "taxonomy_term",
                },
                "school_name": schoolName,
                "school_update": showAddSchool,
                id
            }
            const url = `${configuration.api_url}${urlEndPoints.viewAddSchool}`
            // const headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            try {
                const data = await networkRequest(url, "POST", obj)
                if (data.status == 200) {
                    if (data.data.error) {
                        displayAddSchoolError(data.data.error)
                    } else {
                        const mySchoolList = await getMySchools();
                        if (addSchoolFormData.schoolName.value == "0" || showAddSchool) {
                            dispatch(PopupAction.showPopup({
                                type: "success",
                                title: context.t("_success"),
                                message: context.t("_notified_to_admin"),
                                isOpen: true,
                                buttonText: context.t("_ok")
                            }))

                        }
                        // this.setState({
                        //     mySchoolList, addSchoolFormData: {
                        //         schoolName: { value: "", error: "", class: "" },
                        //         schoolNewName: { value: "", error: "", class: "" },
                        //     },
                        //     showAddSchool: false
                        // })
                        setMySchoolList(mySchoolList)
                        setAddSchoolFormData({
                            schoolName: { value: "", error: "", class: "" },
                            schoolNewName: { value: "", error: "", class: "" },
                        })
                        setShowAddSchool(false)
                    }

                    dispatch(LoadingAction.hideLoader())
                }
                else {
                    dispatch(LoadingAction.hideLoader())
                }
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
            // } else {
            //      dispatch(LoadingAction.hideLoader())
            // }
        } else {
            dispatch(LoadingAction.hideLoader())
        }

    }
    const handleCheckQid = async (event) => {
        event.preventDefault();

        if (validate("qid")) {
            dispatch(LoadingAction.showLoader())
            // const authResponse = await this.auth();
            // if (authResponse) {
            const obj = {
                field_qid: formData.parentId.value,
                field_relation_to_the_student: formData.relation.value,
                id
            }
            const url = `${configuration.api_url}${urlEndPoints.viewCheckQid}`
            // const headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            try {
                const data = await networkRequest(url, "POST", obj)
                if (data.status == 200) {
                    if (data.data.error) {
                        dispatch(LoadingAction.hideLoader());
                        displayError(data.data.error);
                    } else {
                        if (!data.data.user) {
                            dispatch(LoadingAction.hideLoader());
                            handleOpenAddStudentModal();
                        } else {
                            const parentList = await getMyParents();
                            // this.setState({ parentList });
                            setParentList(parentList)
                            dispatch(LoadingAction.hideLoader());
                            handleCloseModal();
                        }
                    }
                }
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
            // } else {
            //      dispatch(LoadingAction.hideLoader())
            // }
        }
    }
    const handleOpenAddStudentModal = () => {
        for (var key in formData) {
            formData[key].error = "";
        }
        // this.setState({ formData })
        setFormData({ ...formData })
        let elems = document.querySelector("#checkQid");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addParent");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        instance = M.Modal.getInstance(elems);
        instance.open();
        document.getElementById("email") && document.getElementById("email").focus();
    }
    const handleOpenQidCheck = () => {
        for (var key in formData) {
            formData[key].error = "";
        }
        // this.setState({ formData })
        setFormData({ ...formData })
        let elems = document.querySelector("#checkQid");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        let instance = M.Modal.getInstance(elems);
        instance.open();
        document.getElementById("parentId") && document.getElementById("parentId").focus();
    }
    const handleAddNewParent = async (event) => {
        event.preventDefault();

        if (validate()) {
            dispatch(LoadingAction.showLoader())
            // const authResponse = await this.auth();
            // if (authResponse) {
            let obj = {
                email: formData.email.value
            }
            const url = `${configuration.api_url}${urlEndPoints.sendNotification}`
            // const headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            try {
                const data = await networkRequest(url, "POST", obj)
                if (data.data.error) {
                    displayError(data.data.error)
                    dispatch(LoadingAction.hideLoader())
                }
                else {
                    dispatch(LoadingAction.showPopup({
                        type: "success",
                        title: context.t("_success"),
                        message: data.data.message[lang],
                        isOpen: true,
                        buttonText: context.t("_ok")
                    }))

                    handleCloseModal(true);
                    const parentList = await getMyParents();
                    setSubmissionStatus(true)
                    setParentList(parentList)
                    // this.setState({ submissionStatus: true, parentList });
                    dispatch(LoadingAction.hideLoader());
                }
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
            // } else {
            //      dispatch(LoadingAction.hideLoader())
            // }
        }
    }
    const handleRemove = async userId => {
        dispatch(LoadingAction.showLoader())
        // const authResponse = await this.auth();
        // if (authResponse) {
        let obj = {
            "linked_user": userId,
            id
        }
        const url = `${configuration.api_url}${urlEndPoints.viewProfileLinking}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await networkRequest(url, "POST", obj)
            if (data.data.error) {
                dispatch(LoadingAction.hideLoader())
                dispatch(PopupAction.showPopup({
                    type: "error",
                    title: context.t("_failed"),
                    message: context.t("_student_unlink_failed"),
                    isOpen: true,
                    buttonText: context.t("_ok")
                }))

            }
            else {
                const parentList = await getMyParents();
                setParentList(parentList)
                // this.setState({ parentList })
                dispatch(LoadingAction.hideLoader());
            }
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // } else {
        //      dispatch(LoadingAction.hideLoader())
        // }
    }

    const handleRemoveSchool = async sId => {
        dispatch(LoadingAction.showLoader())
        // const authResponse = await this.auth();
        // if (authResponse) {
        const obj = {
            "school_id": sId,
            id
        }
        const url = `${configuration.api_url}${urlEndPoints.viewRemoveSchool}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await networkRequest(url, "POST", obj)
            if (data.data.error) {
                dispatch(LoadingAction.hideLoader())
                dispatch(PopupAction.showPopup({
                    type: "error",
                    title: context.t("_failed"),
                    message: context.t("_school_unlinking_failed"),
                    isOpen: true,
                    buttonText: context.t("_ok")
                }))

            }
            else {
                dispatch(PopupAction.showPopup({
                    type: "success",
                    title: context.t("_success"),
                    message: context.t("_school_unlinking_success"),
                    isOpen: true,
                    buttonText: context.t("_ok")
                }))

                const mySchoolList = await getMySchools();
                setMySchoolList(mySchoolList)
                // this.setState({ mySchoolList })
                dispatch(LoadingAction.hideLoader())
                M.AutoInit()
            }
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // } else {
        //      dispatch(LoadingAction.hideLoader())
        // }
    }
    const handleCloseConfirmModal = (state) => {
        setRemoveSchoolConfirm(false)
        setRemoveSchoolId(null)
        // this.setState({ [state]: false, removeSchoolId: null })
    }
    const handleConfirmRemoveSchool = () => {
        setShowAddSchool(true)
        // this.setState({ showAddSchool: true })
        // const { removeSchoolId } = this.state;
        // if (!_.isEmpty(removeSchoolId))
        //     this.handleRemoveSchool(removeSchoolId);
        setTimeout(() => {
            var elems = document.querySelectorAll("select");
            M.FormSelect.init(elems);
        }, 100);
        handleCloseConfirmModal("removeSchoolConfirm")
    }
    const handleOpenRemoveSchoolConfirm = id => {
        setRemoveSchoolId(id)
        setRemoveSchoolConfirm(true)
        // this.setState({ removeSchoolConfirm: true, removeSchoolId: id })
    }
    // const { lang } = this.props;
    // const { match: { params: { id } } } = this.props;
    let hasError = false;
    Object.keys(formData).map((item, index) => {
        if (formData[item].error != "")
            hasError = true
    })


    return (

        <div id="main-container">
            <SlideToTop />
            <PageTitle title={context.t("_manage_connections")} />
            <div id="skipContent" className="container">
                <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                    <ul>
                        <li className="breadcrumb-item">
                            <Link to="/profile">{context.t("_profile")}</Link> {" "}

                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`/student-profile/${id}`}>{context.t("_participants")}</Link> {" "}

                        </li>

                        <li className="breadcrumb-item">
                            <Link tabIndex="0" to aria-current={context.t("_page")}>
                                <span>
                                    {context.t("_manage_connections")}
                                </span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div>
                <div className="container">

                    <h2>{context.t("_parents")}</h2>
                    {
                        !_.isEmpty(parentList) &&
                        <div className="tableHolder">
                            <table className="responsive-table highlight">
                                <thead className="blue lighten-5">
                                    <tr>
                                        <th><strong>{context.t("_name")}</strong></th>
                                        <th><strong>{context.t("_qid")}</strong></th>
                                        <th><strong>{context.t("_phone_number")}</strong></th>
                                        <th><strong>{context.t("_relation_to_student")}</strong></th>
                                        {/* <th><strong>{context.t("_status")}</strong></th> */}
                                        {/* <th width="148"><strong></strong></th> */}
                                        {/* <th width="150"><strong></strong></th> */}
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        parentList.map(item => {
                                            return (
                                                <tr key={item.uid}>
                                                    <td>{item.field_parent_name}</td>
                                                    <td>{item.field_parent_qid}</td>
                                                    <td>{item.field_parent_mobile_number}</td>
                                                    <td>{item.field_relation_with_student}</td>
                                                    {/* <td>{item.status == "On" ? "Approved" : "Blocked"}</td> */}
                                                    {/* <td><button className="btn profileBtn">{context.t("_view_profile")}</button></td> */}
                                                    {/* <td><button onClick={() => { handleRemove(item.uid) }} className="btn profileBtn">{context.t("_remove")}</button></td> */}
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                    {
                        parentList.length < 2 && <button id="addGuardianBtn" className="waves-effect waves-light btn profileBtn" onClick={() => { handleOpenQidCheck() }}>{context.t("_add_guardian")}</button>
                    }

                    <div id="checkQid" className="modal sm" role="dialog">
                        <div className="modal-content modal-no-close">
                            {
                                _.isEmpty(formData) ?
                                    <div /> :
                                    <div>
                                        <span className="badge modalClose"><a onClick={() => handleCloseModal()}><i className="material-icons">close</i></a></span>
                                        <h2 className="line">{context.t("_add_guardian")}</h2>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit.</p> */}
                                        {hasError ? <div className="error-container fail">
                                            <h5>{context.t("_error_title")}</h5>
                                            <ul>
                                                {formData.parentId.error != "" ? <li ><a tabIndex="0" href="#parentId" className="error" htmlFor="parentId">{formData.parentId.error}</a></li> : null}

                                                {formData.relation.error != "" ? <li ><a tabIndex="0" href="#relation" className="error" htmlFor="relation">{formData.relation.error}</a></li> : null}
                                            </ul>
                                        </div> : null
                                        }
                                        <form onSubmit={handleCheckQid} className="row full-width">
                                            <div className="col s12">
                                                <div className="input-field item">
                                                    <input
                                                        placeholder={context.t("_patent_qid_placeholder")}
                                                        type="text"
                                                        name="parentId"
                                                        id="parentId"
                                                        maxLength={11}
                                                        onChange={(evt) => handleChange("parentId", evt.target.value, "formData")}
                                                        onFocus={(evt) => handleChange("parentId", evt.target.value, "formData")}
                                                        value={formData.parentId.value}
                                                    />
                                                    <label htmlFor="parentId" style={dynamicStyles.position(lang)}>{context.t("_patent_qid")} <span className="asterisk">*</span></label>
                                                </div>

                                                <div className="input-field mobileSelect">
                                                    <label htmlFor="relation" style={dynamicStyles.position(lang)}>
                                                        {context.t("_select_relation_1")}<span className="asterisk">*</span>
                                                    </label>
                                                    <select
                                                        name="relation"
                                                        id="relation"
                                                        className="browser-default"
                                                        onChange={(evt) => handleChange("relation", evt.target.value, "formData")}
                                                        value={formData.relation.value}
                                                    >
                                                        <option value="" disabled> {context.t("_select_relation")} </option>
                                                        <option value="Father">{context.t("_father")}</option>
                                                        <option value="Mother">{context.t("_mother")}</option>
                                                        <option value="Sister">{context.t("_sister")}</option>
                                                        <option value="Brother">{context.t("_brother")}</option>
                                                        <option value="Grandmother">{context.t("_grand_mother")}</option>
                                                        <option value="Grandfather">{context.t("_grand_father")}</option>
                                                        <option value="Guardian">{context.t("_parent")}</option>
                                                    </select>
                                                    <span
                                                        style={dynamicStyles.textAlign(lang)}
                                                        className="helper-text"
                                                        data-error="Required field."
                                                    ></span>
                                                </div>
                                            </div>

                                            <div className="col s12">
                                                <button type="button" onClick={handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{context.t("_cancel")}</button>
                                                <button className="btn profileBtn blue noWidth waves-effect waves-light">{context.t("_add")}</button>
                                            </div>
                                        </form>
                                    </div>
                            }
                        </div>
                    </div>
                    <div id="addParent" className="modal sm user-add-modal" role="dialog">
                        <div className="modal-content modal-no-close">
                            {
                                _.isEmpty(formData) ?
                                    <div /> :
                                    <form onSubmit={handleAddNewParent} className="col full-width">
                                        <span className="badge modalClose"><a onClick={() => handleCloseModal()}><i className="material-icons">close</i></a></span>
                                        <h2 className="line">{context.t("_add_guardian")}</h2>

                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p> */}
                                        {hasError ? <div className="error-container fail">
                                            <h5>{context.t("_error_title")}</h5>
                                            <ul>
                                                {formData.email.error != "" ? <li ><a tabIndex="0" href="#email" className="error" htmlFor="email">{formData.email.error}</a></li> : null}
                                            </ul>
                                        </div> : null
                                        }
                                        <p>{context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

                                        <div className="row">
                                            <div className="col s12">
                                                {/* {renderInput({ name: "parentName", label: context.t("_parent_name"), placeholder: context.t("_name_placeholder"), isMandatory: true })} */}
                                                {renderInput({ name: "email", label: context.t("_parent_email_address"), placeholder: context.t("_parent_email_address_placeholder"), type: "email", reference: "email" })}

                                            </div>
                                            {/* <div className="col s12 m6">
                                                    <div className="date-popup">
                                                        {renderInput({ name: "dob", label: context.t("_dob"), placeholder: context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
                                                    </div>

                                                    {renderInput({ name: "mobileNumber", label: context.t("_parent_mobile_number"), placeholder: context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number" })}
                                                </div> */}
                                            <div className="col s12">
                                                <button className="btn profileBtn blue waves-effect waves-light">{context.t("_save_changes")}</button>
                                                <button type="button" onClick={handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{context.t("_cancel")}</button>
                                            </div>
                                            <div className="clearfix" />
                                        </div>
                                    </form>
                            }
                        </div>
                    </div>
                </div>

                <br /><br />
                {/* <div className="container">
                        <h2>{context.t("_schools")}</h2>
                        {
                            !_.isEmpty(mySchoolList) &&
                            <div className="tableHolder">
                                <table className="responsive-table highlight">
                                    <thead className="blue lighten-5">
                                        <tr>
                                            <th><strong>{context.t("_name")}</strong></th>
                                            <th><strong>{context.t("_phone_number")}</strong></th>
                                            <th><strong>{context.t("_status")}</strong></th>
                                            {/* <th width="148"><strong></strong></th> 
                                            <th width="140"><strong></strong></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            mySchoolList.map(item => {
                                                return (
                                                    <tr key={item.field_school_1}>
                                                        <td>{item.field_school}</td>
                                                        <td>{item.field_rep_mobile_number_}</td>
                                                        <td>{item.status == "1" ? "Approved" : "Blocked"}</td>
                                                        {/* <td><button className="btn profileBtn">{context.t("_view_profile")}</button></td> 
                                                        <td><button onClick={() => handleOpenRemoveSchoolConfirm(item.field_school_1)} className="btn profileBtn">{context.t("_edit")}</button></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        {
                            _.isEmpty(addSchoolFormData) ?
                                <div /> :
                                <>
                                    {
                                        addSchoolFormData.schoolName.error != "" || addSchoolFormData.schoolNewName.error != "" ? <div className="error-container fail">
                                            <h5>{context.t("_error_title")}</h5>
                                            <ul>
                                                {
                                                    addSchoolFormData.schoolName.error != "" && <li><label className="error" htmlFor="schoolName">{addSchoolFormData.schoolName.error}</label></li>
                                                }
                                                {
                                                    addSchoolFormData.schoolNewName.error != "" && <li><label className="error" htmlFor="schoolNewName">{addSchoolFormData.schoolNewName.error}</label></li>
                                                }

                                            </ul>
                                        </div> : null
                                    }
                                </>

                        }
                        {
                            (_.isEmpty(mySchoolList) || showAddSchool) && <form onSubmit={handleAddSchool}>
                                <div className="row topFormHolder noBorder">
                                    {
                                        _.isEmpty(addSchoolFormData) ?
                                            <div /> :
                                            <div className="col s12 m6 l10">
                                                <div className={`input-field mobileSelect item ${lang}`}>
                                                    <select
                                                        defaultValue=""
                                                        id="schoolName"
                                                        name="schoolName"
                                                        onChange={evt =>
                                                            handleChange("schoolName", evt.target.value, "addSchoolFormData")
                                                        }>
                                                        <option value={addSchoolFormData.schoolName.value} disabled>{context.t("_choose_school")}</option>
                                                        {
                                                            schoolList.map(item => {
                                                                return <option key={item.tid} value={item.tid}>{item.name}</option>
                                                            })
                                                        }
                                                        <option value="0">Other</option>
                                                    </select>

                                                    <span className="helper-text"></span>
                                                </div>
                                                {
                                                    addSchoolFormData.schoolName.value == "0" ?
                                                        <div className="input-field item">
                                                            <label className="active">{context.t("_school_name")} <span className="asterisk">*</span></label>
                                                            <input
                                                                id="schoolNewName"
                                                                name="schoolNewName"
                                                                onChange={evt =>
                                                                    handleChange("schoolNewName", evt.target.value, "addSchoolFormData")
                                                                }
                                                                onFocus={evt =>
                                                                    handleChange("schoolNewName", evt.target.value, "addSchoolFormData")
                                                                }
                                                                value={addSchoolFormData.schoolNewName.value}
                                                                placeholder={context.t("_select_school_name")}
                                                                type="text"
                                                            />
                                                            <span className="helper-text"></span>
                                                        </div>
                                                        :
                                                        null
                                                }

                                            </div>
                                    }
                                    <div className="col s12 m6 l2 valign-wrapper add-school-btn-container center-align justify-center">
                                        <button className="btn profileBtn">
                                            {context.t("_add_school")}
                                        </button>
                                        <br /><br />
                                    </div>
                                </div>
                            </form>
                        }

                    </div> */}

            </div>
            <div className="top-line ng-scope">
                <img src={topLineImage} alt="" />
            </div>

            <Modal
                isOpen={removeSchoolConfirm}
                style={customModalStyles}
                contentLabel="unlink parent confirm modal"
                ariaHideApp={false}
            >
                <div className="popupAlert warning">
                    <br />
                    <h3>{context.t("_confirm")}</h3>
                    <p>{context.t("_edit_school_confirmation")}</p>
                    <div className="row">
                        <button className="col btn blue" onClick={() => handleCloseConfirmModal("removeSchoolConfirm")}>{context.t("_no")}</button>
                        <button className="col btn profileBtn yellow" onClick={() => handleConfirmRemoveSchool()}>{context.t("_yes")}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}


const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
    showPopup: (data) => PopupAction.showPopup(data),
    hidePopup: (data) => PopupAction.hidePopup(data),
}

ViewStudentConnections.contextTypes = {
    t: PropTypes.func
}
export default ViewStudentConnections

