import React, { useState, useEffect, useRef } from "react"
import Main from "../../constants/utilities"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import PropTypes from "prop-types"
import M from "materialize-css"
import dateformat from "dateformat"
import _ from "lodash"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { PopupAction, LoadingAction, ActivityListAction, StudentListAction, StudentCountAction, ActivityCountAction, TypeCountAction, ZoneCountAction } from "./../../actions"

import StudentItem from "./pages/students"
import ActivityItem from "./pages/activity"
import TypesItem from "./pages/type"
import ZoneItem from "./pages/zone"
import ActivityList from "./pages/activityList"
import StudentList from "./pages/studentList"
import GenderList from "./pages/gender"
import CitizenList from "./pages/citizen"
import NewStudentList from "./pages/newStudentList"
import { getGender, handleGradeName, calculateAge, handleCountryName, getType, getFieldType, getZone } from "./pages/utilFunctions"
import { calenderI18n, CustomDateInput, calenderLocal } from "./../../constants/common"
import { useNavigate } from "react-router"

const Dashboard = (props, context) => {
	const studentReference = useRef()
	const activityRef = useRef()
	const { type } = useParams();
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { config, networkRequest } = Main();
	const configure = config()
	const { lang } = useSelector((state) => state.i18nState);
	const [search, setSearch] = useState({
		startDate: {},
		endDate: {},
		type: "",
		zone: "",
		fieldType: "",
		studentLevel: "",
		studentPoint: "",
	});
	const [dashboardCount, setDashboardCount] = useState({});
	const [studentFullList, setStudentFullList] = useState([]);
	const [activityFullList, setActivityFullList] = useState([]);
	const [studentLevel, setStudentLevel] = useState([]);
	const [studentPoints, setStudentPoints] = useState([]);
	const [resetFlag, setResetFlag] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			document.title = `${context.t("_studio56")} | ${context.t("_dashboard")}`
			if (_.isEmpty(type)) {
				navigate("/dashboard/student")
			}
			dispatch(LoadingAction.showLoader());
			M.AutoInit();
			initDatepicker()
			await getDashboardCount();
			if (_.isEmpty(type) || type == "student") {
				await dispatch(StudentListAction.getStudentsList({}));
				setTimeout(() => {
					dispatch(StudentListAction.getNewStudentsList({}));
					dispatch(ActivityListAction.getActivityList({ lang }));
				}, 1000);
			} else if (type == "activity") {
				await dispatch(ActivityListAction.getActivityList({ lang }));
				setTimeout(() => {
					dispatch(StudentListAction.getNewStudentsList({}));
					dispatch(StudentListAction.getStudentsList({}));
				}, 1000);
			} else {
				await dispatch(StudentListAction.getStudentsList({ lang }));
				setTimeout(() => {
					dispatch(StudentListAction.getNewStudentsList({}));
					dispatch(ActivityListAction.getActivityList({}));
				}, 1000);

			}

		}
		fetchData();

	}, []);

	const initDatepicker = () => {
		if (search.startDate.value) {
			setSearch((prevSearch) => ({
				...prevSearch,
				endDate: {
					...prevSearch.endDate,
					minDate: new Date(prevSearch.startDate.value),
				},
			}));
		}

		if (search.endDate.value) {
			setSearch((prevSearch) => ({
				...prevSearch,
				startDate: {
					...prevSearch.startDate,
					maxDate: new Date(prevSearch.endDate.value),
				},
			}));
		}

	}
	const getActivityFullList = async ({ title = "", type = "", zone = "", fieldType = "", startDate = "", endDate = "" }) => {
		try {
			let url = ""
			if (lang == "ar")
				url = `${configure.origin}${lang}/api/${urlEndPoints.getDashboardActivityFullList}?`
			else
				url = `${configure.origin}api/${urlEndPoints.getDashboardActivityFullList}?`

			const body = {}

			if (!_.isEmpty(title)) {
				body.title = title
			}
			if (!_.isEmpty(type)) {
				body.type_1 = type
			}
			if (!_.isEmpty(zone)) {
				body.field_activity_zone_target_id = zone
			}
			if (!_.isEmpty(fieldType)) {
				body.field_type_of_activity_value = fieldType
			}
			if (!_.isEmpty(startDate)) {
				body.field_start_and_end_time_value = `${dateformat(startDate.value, "yyyy-mm-dd")} 00:00:00`
			}
			if (!_.isEmpty(endDate)) {
				body.field_start_and_end_time_end_value_1 = `${dateformat(endDate.value, "yyyy-mm-dd")} 00:00:00`
			}

			dispatch(LoadingAction.showLoader())
			const data = await networkRequest(url, "POST", body)
			const results = data.data.results;
			results.forEach(item => {
				item.startDate = item.startDate.split("*")
				item.endDate = item.endDate.split("*")
			});
			setActivityFullList(results)
			dispatch(LoadingAction.hideLoader())
			setTimeout(() => {
				return window.print();
			}, 500);
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}

	}
	const getStudentsFullList = async ({ title = "", age = "", nationality = "", gender = "", startDate = "", endDate = "", type = "", zone = "", fieldType = "", }) => {
		try {
			let url = `${configure.api_url}${urlEndPoints.getDashboardStudentFullList}?`

			const body = {}
			if (!_.isEmpty(title)) {
				body.field_student_name_value = title
			}
			if (!_.isEmpty(age)) {
				var dob = new Date();
				dob.setFullYear(dob.getFullYear() - Number(age));
				body.field_student_dob_value = `${dateformat(dob, "yyyy-mm-dd")}`
			}
			if (!_.isEmpty(nationality)) {
				body.field_nationality_value = nationality
			}
			if (!_.isEmpty(gender)) {
				body.field_gender_value = gender
			}
			if (!_.isEmpty(startDate)) {
				body.field_start_and_end_time_value = dateformat(startDate.value, "yyyy-mm-dd")
			}
			if (!_.isEmpty(endDate)) {
				body.field_start_and_end_time_end_value_1 = dateformat(endDate.value, "yyyy-mm-dd")
			}
			if (!_.isEmpty(type)) {
				body.type_1 = type
			}
			if (!_.isEmpty(zone)) {
				if (zone == "1") {
					body.field_activity_zone_target_id = "Creativity Zone"
				}
				if (zone == "3") {
					body.field_activity_zone_target_id = "Technology Zone"
				}
				if (zone == "2") {
					body.field_activity_zone_target_id = "Software Zone"
				}
			}
			if (!_.isEmpty(fieldType)) {
				body.field_type_of_activity_value = fieldType
			}

			dispatch(LoadingAction.showLoader())
			const data = await networkRequest(url, "POST", body)
			setStudentFullList(data.data.results)
			dispatch(LoadingAction.hideLoader())
			setTimeout(() => {
				return window.print();
			}, 500);
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}
	const getNewStudentsFullList = async ({ title = "", age = "", nationality = "", gender = "", startDate = "", endDate = "" }) => {
		try {
			let url = `${configure.api_url}${urlEndPoints.getDashboardNewStudentFullList}?`

			const body = {}
			if (!_.isEmpty(title)) {
				body.field_student_name_value = title
			}
			if (!_.isEmpty(age)) {
				var dob = new Date();
				dob.setFullYear(dob.getFullYear() - Number(age));
				body.field_student_dob_value = `${dateformat(dob, "yyyy-mm-dd")}`
			}
			if (!_.isEmpty(nationality)) {
				body.field_nationality_value = nationality
			}
			if (!_.isEmpty(gender)) {
				body.field_gender_value = gender
			}
			if (!_.isEmpty(startDate)) {
				body.field_start_and_end_time_value = dateformat(startDate.value, "yyyy-mm-dd")
			}
			if (!_.isEmpty(endDate)) {
				body.field_start_and_end_time_end_value_1 = dateformat(endDate.value, "yyyy-mm-dd")
			}

			dispatch(LoadingAction.showLoader())
			const data = await networkRequest(url, "POST", body)
			setStudentFullList(data.data.results)
			dispatch(LoadingAction.hideLoader())
			setTimeout(() => {
				return window.print();
			}, 500);
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}
	const handleChange = (field, value) => {
		if (field == "type" || field == "zone" || field == "fieldType" || field == "studentLevel" || field == "studentPoint") {
			setSearch(prevSearch => ({ ...prevSearch, [field]: value }));
		} else  {
			setSearch(prevSearch => ({
				...prevSearch,
				[field]: {
				  ...prevSearch[field],
				  value: value,
				},
			  }));
			}
		if (type == "all-student") {
			studentReference.current.click()

		}
	}
	const handleApplyDateFilter = () => {
		dispatch(LoadingAction.showLoader());
		getDashboardCount()
		dispatch(ActivityListAction.getActivityList({ lang, ...search }));
		dispatch(StudentListAction.getStudentsList({ ...search }));
		dispatch(StudentListAction.getNewStudentsList({ ...search }));
	}
	const getDashboardCount = async () => {
		let url = `${configure.origin}${urlEndPoints.getDashboardCount}?`
		let obj = {};
		if (!_.isEmpty(search.startDate))
			obj.startDate = `${dateformat(search.startDate.value, "yyyy-mm-dd")} 00:00:00`
		if (!_.isEmpty(search.endDate))
			obj.endDate = `${dateformat(search.endDate.value, "yyyy-mm-dd")} 00:00:00`
		if (!_.isEmpty(search.type))
			obj.type_1 = search.type
		if (!_.isEmpty(search.zone)) {
			if (search.zone == "1") {
				obj.field_activity_zone_target_id = "Creativity Zone"
			}
			if (search.zone == "3") {
				obj.field_activity_zone_target_id = "Technology Zone"
			}
			if (search.zone == "2") {
				obj.field_activity_zone_target_id = "Software Zone"
			}
		}

		if (!_.isEmpty(search.fieldType))
			obj.field_type_of_activity_value = search.fieldType

		try {
			const data = await networkRequest(url, "POST", obj)
			if (data.status == 200) {
				setDashboardCount(data.data.results)
			}
		} catch (error) {
			// dispatch(LoadingAction.hideLoader());
		}

	}
	const handlePrint = async () => {
		if (type == "student") {
			getStudentsFullList({ ...search })

		} else if (type == "activity") {
			getActivityFullList({ ...search })
			// return window.print();
		} else {
			getNewStudentsFullList({ ...search })
		}
	}
	const handleResetFilter = () => {
		setSearch(prevState => ({ ...prevState, startDate: {}, endDate: {}, type: "", zone: "", fieldType: "" }));
		setResetFlag(prevResetFlag => !prevResetFlag);
		dispatch(LoadingAction.showLoader());
		getDashboardCount()
		dispatch(ActivityListAction.getActivityList({ lang }));
	}

	const getZone = zone => {
		zone = zone.toLowerCase()
		switch (zone) {
			case "creativity zone":
				return context.t("_creative_zone")
			case "software zone":
				return context.t("_software_zone")
			case "technology zone":
				return context.t("_technology_zone")

		}
	}
	const getType = type => {
		type = type.toLowerCase()
		switch (type) {
			case "event":
				return context.t("_events")
			case "session":
				return context.t("_sessions")
			case "competition":
				return context.t("_competitions")
			case "challenge":
				return context.t("_challenges")
			case "project":
				return context.t("_projects")

		}
	}
	const getFieldType = field => {
		field = field.toLowerCase()
		switch (field) {
			case "online":
				return context.t("_online")
			case "onsite":
				return context.t("_onsite")

		}
	}

	const calculateDateDiff = (fromDate, toDate) => {
		const date1 = new Date(fromDate);
		const date2 = new Date(toDate);
		const diffTime = Math.abs(date2 - date1);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays;
	}
	const getButtonLabel = (type) => {
		switch (type) {
			case "student":
				return context.t("_export_student_details")
			case "activity":
				return context.t("_export_activity_details")
			case "all-student":
				return context.t("_export_all_student_details")

			default:
				break;
		}
	}
	return (
		<div id="main-content">

			<div className="dashboardTitleBar"></div>
			<div id="skipContent">
				<div id="main-container">
					<div className="container dashboardContainer">

						<div className="dashboardTop">
							<div className="dashboardTopForm">
								<p>{context.t("_select_date_range")}</p>
								<div className="dashboardFormCalendar">

									<div className="date-popup">
										<div className="input-field">
											<label htmlFor="startDate">{context.t("_from")}</label>
											{/* <input
												name="startDate"
												placeholder={context.t("_from")}
												id="startDate"
												type="text"
												className="startDate"
												readOnly="readonly"
												autoComplete="no"
											/> */}
											<DatePicker
												name="startDate"
												placeholderText={context.t("_from")}
												id="startDate"
												className="startDate"
												// readOnly="readonly"
												autoComplete="off"
												dateFormat="yyyy-MM-dd"
												locale={calenderLocal(context)}
												onChange={date => {
													handleChange("startDate", date)

												}}
												// style={dynamicStyles.textAlign(lang)}
												minDate={search.startDate.minDate}
												maxDate={search.startDate.maxDate}
												value={search.startDate.value}
												selected={search.startDate.value}
												customInput={<CustomDateInput ariaLabel={context.t("_select_start_date_button")} onFieldChange={(date) => handleChange("startDate", new Date(date))} />}
												onCalendarOpen={e => {
													let elems = document.querySelector(".react-datepicker");
													elems.setAttribute("tabindex", "0")
													elems && elems.focus()
													elems = document.querySelector(".react-datepicker__month-select");
													elems.setAttribute("aria-label", context.t("_select_month"))
													elems = document.querySelector(".react-datepicker__year-select");
													elems.setAttribute("aria-label", context.t("_select_year"))

												}}
												onCalendarClose={e => {
													var elems = document.querySelector("#startDate");
													elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(search.startDate.value, "dd-mm-yyyy")}`)
													elems.setAttribute("value", dateformat(search.startDate.value, "yyyy-mm-dd"))
													setTimeout(() => {
														elems && elems.focus()
													}, 200);
												}}
												peekNextMonth
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
											/>
										</div>
									</div>
									<span className="inBetween"> . . . </span>
									<div className="date-popup">
										<div className="input-field">
											<label htmlFor="endDate">{context.t("_to")}</label>
											{/* <input
												name="endDate"
												placeholder={context.t("_to")}
												id="endDate"
												type="text"
												className="endDate"
												readOnly="readonly"
												autoComplete="no"
											/> */}
											<DatePicker
												name="endDate"
												placeholderText={context.t("_to")}
												id="endDate"
												className="endDate"
												// readOnly="readonly"
												autoComplete="off"
												locale={calenderLocal(context)}
												dateFormat="yyyy-MM-dd"
												onChange={date => {
													handleChange("endDate", date)
													initDatepicker()
												}}
												// style={dynamicStyles.textAlign(lang)}
												minDate={search.endDate.minDate}
												maxDate={search.endDate.maxDate}
												value={search.endDate.value}
												selected={search.endDate.value}
												customInput={<CustomDateInput ariaLabel={context.t("_select_end_date_button")} onFieldChange={(date) => handleChange("endDate", new Date(date))} />}
												onCalendarOpen={e => {
													let elems = document.querySelector(".react-datepicker");
													elems.setAttribute("tabindex", "0")
													elems && elems.focus()
													elems = document.querySelector(".react-datepicker__month-select");
													elems.setAttribute("aria-label", context.t("_select_month"))
													elems = document.querySelector(".react-datepicker__year-select");
													elems.setAttribute("aria-label", context.t("_select_year"))

												}}
												onCalendarClose={e => {
													var elems = document.querySelector("#endDate");
													elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(search.endDate.value, "dd-mm-yyyy")}`)
													elems.setAttribute("value", dateformat(search.endDate.value, "yyyy-mm-dd"))
													setTimeout(() => {
														elems && elems.focus()
													}, 200);
												}}
												peekNextMonth
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
											/>
										</div>
									</div>

								</div>

								{/* <button onClick={() => handleApplyDateFilter()} className="btn bgYellow waves-effect waves-light">{context.t("_apply")}</button>
									<button onClick={() => handleResetFilter()} type="button" className="btn blueBorder">{context.t("_reset")}</button> */}
							</div>

							<div className="dashboardTopRight">
								<button onClick={() => handlePrint()} className="btn bgYellow waves-effect waves-light">{getButtonLabel(type)}</button>
							</div>
							<div className="" style={{ width: "100%" }}>
								<div className="col s12">
									<div className="dashboard_main_filter">
										<label htmlFor="type">{context.t("_type")}</label>
										<div className="input-field inline search mobileSelect">
											<select
												id="type"
												name="type"
												className="browser-default"
												onChange={evt => {
													handleChange("type", evt.target.value)

												}}
												value={search.type}
											>
												<option value="" disabled>{context.t("_select_type")}</option>
												<option value="event">{context.t("_events")}</option>
												<option value="session">{context.t("_sessions")}</option>
												<option value="competition">{context.t("_competitions")}</option>
												<option value="challenge">{context.t("_challenges")}</option>
												<option value="project">{context.t("_projects")}</option>
											</select>
										</div>
										{/* <label htmlFor="type">{context.t("_type")}</label>
											<div className="input-field inline search mobileSelect">
												<select
													id="type"
													name="type"
													className="browser-default"
													onChange={evt => {
														handleChange("type", evt.target.value)

													}}
													value={search.type}
												>
													<option value="" disabled>{context.t("_select_type")}</option>
													<option value="event">{context.t("_events")}</option>
													<option value="session">{context.t("_sessions")}</option>
													<option value="competition">{context.t("_competitions")}</option>
													<option value="challenge">{context.t("_challenges")}</option>
													<option value="project">{context.t("_projects")}</option>
												</select>
											</div> */}
										<label htmlFor="zone">{context.t("_zone")}</label>
										<div className="input-field inline search mobileSelect">
											<select
												id="zone"
												name="zone"
												className="browser-default"
												onChange={evt => {
													handleChange("zone", evt.target.value)

												}}
												value={search.zone}
											>

												<option value="" disabled>{context.t("_select_zone")}</option>
												<option value="1">{context.t("_creative_zone")}</option>
												<option value="3">{context.t("_technology_zone")}</option>
												<option value="2">{context.t("_software_zone")}</option>

											</select>
										</div>
										<label htmlFor="fieldType">{`${context.t("_online")}/ ${context.t("_onsite")}`}</label>
										<div className="input-field inline mobileSelect">
											<select
												id="fieldType"
												name="fieldType"
												className="browser-default"
												onChange={evt => {
													handleChange("fieldType", evt.target.value)

												}}
												value={search.fieldType}
											>
												<option value="" disabled>{context.t("_select")}</option>
												<option value="online">{context.t("_online")}</option>
												<option value="onsite">{context.t("_onsite")}</option>
											</select>
										</div>

										<button onClick={() => handleApplyDateFilter()} className="btn bgYellow waves-effect waves-light">{context.t("_apply")}</button>
										<button onClick={() => handleResetFilter()} type="button" className="btn blueBorder">{context.t("_reset")}</button>
									</div>

								</div>


							</div>

						</div>

						<div className="dashboardBoxHolder">
							<StudentItem {...search} {...dashboardCount} />
							<GenderList {...search} {...dashboardCount} resetFlag={resetFlag} />
							<CitizenList {...search} {...dashboardCount} resetFlag={resetFlag} />
							<ActivityItem {...search} {...dashboardCount} />
							<TypesItem {...search} {...dashboardCount} />
							<ZoneItem {...search} {...dashboardCount} />
						</div>



						<div className="dashboardWholeWrapper">

							<div className="row">
								<div className="col s12">
									<ul className="tabs">
										<li className="tab"><a ref={studentReference} onClick={() => navigate("/dashboard/student")} className={`waves-effect waves-light ${type == "student" ? "active" : ""}`} href="#student">{context.t("_student_details")}</a></li>
										<li className="tab"><a onClick={() => navigate("/dashboard/activity")} className={`waves-effect waves-light ${type == "activity" ? "active" : ""}`} href="#activity">{context.t("_activity_details")}</a></li>
										{
											_.isEmpty(search.type) && _.isEmpty(search.zone) && _.isEmpty(search.fieldType) && <li className="tab"><a onClick={() => navigate("/dashboard/all-student")} className={`waves-effect waves-light ${type == "all-student" ? "active" : ""}`} href="#all-student">{context.t("_all_students")}</a></li>

										}

									</ul>
								</div>

								<StudentList {...search} />
								<ActivityList {...search} />
								<NewStudentList {...search} />

								{

									type == "student" || type == "all-student" ? <div id="student" className="col s12">
										<div className="tableHolder list-print">
											<form className="fliters">
												{context.t("_applied_filters")}: &nbsp;&nbsp;
												{
													!_.isEmpty(search.title) && <>
														<label>{context.t("_search")}: <span>{search.title}</span></label>
													</>
												}

												{
													!_.isEmpty(search.age) && <>
														<label>{context.t("_age")}: <span>{search.age}</span></label>
													</>
												}
												{
													!_.isEmpty(search.nationality) && <>
														<label>{context.t("_nationality")}: <span>{search.nationality}</span></label>
													</>
												}
												{
													!_.isEmpty(search.gender) && <>
														<label>{context.t("_gender")}: <span>{search.gender}</span></label>
													</>
												}

											</form>

											<table>
												<thead>
													<tr>
														<th><strong>{context.t("_student_name_1")}</strong></th>
														<th><strong>{context.t("_qid")}</strong></th>
														<th><strong>{context.t("_phone_number")}</strong></th>
														<th><strong>{context.t("_gender")}</strong></th>
														<th><strong>{context.t("_grade")}</strong></th>
														<th><strong>{context.t("_age")}</strong></th>
														<th><strong>{context.t("_email")}</strong></th>
														<th><strong>{context.t("_nationality")}</strong></th>
														<th><strong>{context.t("_enrolled")} </strong></th>
														<th><strong>{context.t("_attended")}</strong></th>
														<th><strong>{context.t("_waiting")}</strong></th>
														<th><strong>{context.t("_student_points")}</strong></th>
														<th><strong>{context.t("_student_level")}</strong></th>
													</tr>
												</thead>
												<tbody>
													{
														studentFullList.map(item => {
															return (
																<tr key={item.uid}>
																	<td>
																		<img className="profile-img" src={item.picture} alt="" />
																		<span>{item.name}</span>
																	</td>
																	<td>{item.qid}</td>
																	<td>{item.phone}</td>
																	<td>{getGender(item.gender, context)}</td>
																	<td>{handleGradeName(item.grade, lang)}</td>
																	<td>{calculateAge(item.dob)}</td>
																	<td>{item.email}</td>
																	<td>{handleCountryName(item.nationality, lang)}</td>
																	<td className="text-center">{item.enrollment_count}</td>
																	<td className="text-center">{item.attended_count}</td>
																	<td className="text-center">{item.waitinglist_count}</td>
																	<td className="text-center">{item.field_activity_point}</td>
																	<td>{item.level}</td>
																</tr>
															)
														})
													}
												</tbody>
												<tfoot>
													<tr>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
													</tr>
												</tfoot>
											</table>
										</div>
									</div> :
										<div id="activity" className="col s12">
											<div className="tableHolder list-print">
												<form className="fliters" 
												// onSubmit={onSubmit} 
												autoComplete="no">
													{context.t("_applied_filters")}: &nbsp;&nbsp;
													{
														!_.isEmpty(search.title) && <>
															<label>{context.t("_search")}: {search.title}&nbsp;&nbsp;</label>
														</>
													}

													{
														!_.isEmpty(search.type) && <>
															<label>{context.t("_type")}: {search.type}&nbsp;&nbsp;</label>
														</>
													}

													{
														!_.isEmpty(search.zone) && <>
															<label>{context.t("_zone")}: {search.zone}&nbsp;&nbsp;</label>
														</>
													}

													{
														!_.isEmpty(search.fieldType) && <>
															<label>{`${context.t("_online")}/ ${context.t("_onsite")}`}: {search.fieldType}</label>
														</>
													}

												</form>
												<table>
													<thead>
														<tr>
															<th><strong>{context.t("_activity_name")}</strong></th>
															<th><strong>{context.t("_type")}</strong></th>
															<th><strong>{`${context.t("_online")}/ ${context.t("_onsite")}`}</strong></th>
															<th><strong>{context.t("_zone")}</strong></th>
															<th><strong>{context.t("_session_hours")}</strong></th>
															<th><strong>{context.t("_start_date")}</strong></th>
															<th><strong>{context.t("_end_date")}</strong></th>
															<th><strong>{context.t("_activity_gender")}</strong></th>
															<th><strong>{context.t("_age_group")}</strong></th>
															<th><strong>{context.t("_enrolled")}</strong></th>
															<th><strong>{context.t("_attended")}</strong></th>
															<th><strong>{context.t("_waiting")}</strong></th>
														</tr>
													</thead>
													<tbody>
														{
															activityFullList.map(item => {
																return (
																	<tr key={item.nid}>
																		<td>
																			<img className="profile-img" src={item.image} alt="" />
																			<span>{item.title}</span>
																		</td>
																		<td>{getType(item.event_type, context)}</td>
																		<td>{getFieldType(item.activity_type, context)}</td>
																		<td>{getZone(item.activity_zone, context)}</td>
																		<td className="text-center">{item.session_hours}</td>
																		<td>{item.startDate[0]}</td>
																		<td>{item.endDate[item.endDate.length - 1]}</td>
																		<td>{getGender(item.activity_gender, context)}</td>
																		<td>{item.activity_age_group}</td>
																		<td className="text-center">{item.enrollment_count}</td>
																		<td className="text-center">{item.attended_count}</td>
																		<td className="text-center">{item.waitinglist_count}</td>
																	</tr>
																)
															})
														}
													</tbody>
													<tfoot>
														<tr>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
													</tfoot>
												</table>

											</div>
										</div>
								}

							</div>
						</div>
					</div>
				</div>
			</div>
		</div >
	)
}

Dashboard.contextTypes = {
	t: PropTypes.func
}
export default Dashboard;