import React, { useEffect } from "react"
import PropTypes from "prop-types"

import logo from "./../../assets/images/logo56.png"


const Maintenance = (props, context) => {
    useEffect(() => {
        document.title = `${context.t("_studio56")} | ${context.t("_maintenance")}`
    }, [])
    return (<div id="main-content" >
        <div className="container maintenance">
            <div className="wrap">
                <img src={logo} alt={context.t("studio_logo_alt")} />
                <h1>{context.t("_maintenance_mode")}</h1>
                <h2>
                    <p>
                        {context.t("_maintenance_mode_caption1")}<br />
                        {context.t("_maintenance_mode_caption2")}<br />
                        {context.t("_maintenance_mode_caption3")}
                    </p>
                </h2 >
            </div>
        </div>
    </div >)
}

Maintenance.contextTypes = {
    t: PropTypes.func
}
export default Maintenance