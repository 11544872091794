import { GET_STUDENT_LIST, GET_NEW_STUDENT_LIST, GET_STUDENT_FULL_LIST, GET_STUDENT_LEVEL, GET_STUDENT_POINT_RANGE } from "./../../constants/constants";

const initialState = {
    studentList: {
        results: [],
        pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 }
    },
    studentNewList: {
        results: [],
        pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 }
    },
    studentFullList: [],
    studentPointLevel: [],
    studentPointRange: [],
};

const studentListReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_STUDENT_LIST:
            return {
                ...state,
                studentList: action.payload
            };
        case GET_NEW_STUDENT_LIST:
            return {
                ...state,
                studentNewList: action.payload
            };
        case GET_STUDENT_FULL_LIST:
            return {
                ...state,
                studentFullList: action.payload
            };
        case GET_STUDENT_LEVEL:
            return {
                ...state,
                studentPointLevel: action.payload
            };
        case GET_STUDENT_POINT_RANGE:
            return {
                ...state,
                studentPointRange: action.payload
            };
        default:
            return { ...state };
    }
};

export default studentListReducer;
