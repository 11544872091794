
import React,{ useState,useEffect } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import Main from "../../constants/utilities"
import { LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"

const Makers = (props, contest) => {
    const { config, networkRequest } = Main();
    const [configuration, setConfiguration] = useState(config())

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(LoadingAction.showLoader())
        getRedirectPath()
        // this.props.history.push("/news/3137")
    }, [])

    const getRedirectPath = async () => {
        const url = `${configuration.api_url}${urlEndPoints.makersPath}`
        try {
            const data = await networkRequest(url)
            if (data.data.error) {

            } else if(!isEmpty(data.data[0].field_redirect_node)) {
                navigate(`/news/${data.data[0].field_redirect_node}`)
            }
            dispatch(LoadingAction.hideLoader())
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
    return (
        <div id="main-content" />
    )

}

Makers.contextTypes = {
    t: PropTypes.func
}
export default Makers
