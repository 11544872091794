export const SHOW_POPUP = "SHOW_POPUP";
export const HIDE_POPUP = "HIDE_POPUP";
export const GET_MEDIA_CENTER = "GET_MEDIA_CENTER";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGOUT = "USER_LOGOUT";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";
export const SET_USER_IMAGE = "SET_USER_IMAGE";

//Dashboard

export const GET_STUDENTS_ENROLLED = "GET_STUDENTS_ENROLLED"
export const GET_STUDENTS_WAITING_LIST = "GET_STUDENTS_WAITING_LIST"
export const GET_STUDENTS_ATTENDED = "GET_STUDENTS_ATTENDED"

export const GET_TOTAL_ACTIVITIES = "GET_TOTAL_ACTIVITIES"
export const GET_CURRENT_ACTIVITIES = "GET_CURRENT_ACTIVITIES"
export const GET_COMPLETED_ACTIVITIES = "GET_COMPLETED_ACTIVITIES"
export const GET_UPCOMING_ACTIVITIES = "GET_UPCOMING_ACTIVITIES"

export const GET_EVENT_TYPE = "GET_EVENT_TYPE"
export const GET_SESSION_TYPE = "GET_SESSION_TYPE"
export const GET_COMPETITION_TYPE = "GET_COMPETITION_TYPE"

export const GET_CREATIVE_ZONE = "GET_CREATIVE_ZONE"
export const GET_SOFTWARE_ZONE = "GET_SOFTWARE_ZONE"
export const GET_TECHNOLOGY_ZONE = "GET_TECHNOLOGY_ZONE"

export const GET_STUDENT_LIST = "GET_STUDENT_LIST"
export const GET_NEW_STUDENT_LIST = "GET_NEW_STUDENT_LIST"
export const GET_STUDENT_FULL_LIST = "GET_STUDENT_FULL_LIST"

export const GET_ACTIVITY_LIST = "GET_ACTIVITY_LIST"
export const GET_ACTIVITY_FULL_LIST = "GET_ACTIVITY_FULL_LIST"
export const GET_STUDENT_LEVEL = "GET_STUDENT_LEVEL"
export const GET_STUDENT_POINT_RANGE = "GET_STUDENT_POINT_RANGE"

export const GET_PARENT_PROFILE_DETAILS="GET_PARENT_PROFILE_DETAILS"