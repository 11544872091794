import React, { forwardRef } from "react"
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PropTypes from "prop-types"
import dateformat from "dateformat"

export const emailValidate = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const nameValidate = /^[a-zA-Z \u0600-\u06FF]+$/;
export const nameWordCheck = /(\w.+\s).+/i
export const qidValidate = /^[0-9]{11}$/;
export const phoneValidate = /^[0-9]{8}$/;
// export const phoneValidate = /^[3567][0-9]{7}$/g;
export const numberCheck = /^[0-9]*$/;
export const otpValidate = /^[0-9]{6}$/;
// export const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,10}$/g;
export const passwordValidation = /^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,10}/
const dateValidate = /^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/;
export const nameWordLengthCheck = word => {
    word = word.trim();
    if (word.split(" ").length > 1)
        return true;
    return false;
}
export const gradeList = [
    { id: 1, label: "Grade 1", value: "Grade 1" },
    { id: 2, label: "Grade 2", value: "Grade 2" },
    { id: 3, label: "Grade 3", value: "Grade 3" },
    { id: 4, label: "Grade 4", value: "Grade 4" },
    { id: 5, label: "Grade 5", value: "Grade 5" },
    { id: 6, label: "Grade 6", value: "Grade 6" },
    { id: 7, label: "Grade 7", value: "Grade 7" },
    { id: 8, label: "Grade 8", value: "Grade 8" },
    { id: 9, label: "Grade 9", value: "Grade 9" },
    { id: 10, label: "Grade 10", value: "Grade 10" },
    { id: 11, label: "Grade 11", value: "Grade 11" },
    { id: 12, label: "Grade 12", value: "Grade 12" }
];

export const gradeListNew = {
    en: [
        { id: 1, label: "Grade 1", value: "Grade 1" },
        { id: 2, label: "Grade 2", value: "Grade 2" },
        { id: 3, label: "Grade 3", value: "Grade 3" },
        { id: 4, label: "Grade 4", value: "Grade 4" },
        { id: 5, label: "Grade 5", value: "Grade 5" },
        { id: 6, label: "Grade 6", value: "Grade 6" },
        { id: 7, label: "Grade 7", value: "Grade 7" },
        { id: 8, label: "Grade 8", value: "Grade 8" },
        { id: 9, label: "Grade 9", value: "Grade 9" },
        { id: 10, label: "Grade 10", value: "Grade 10" },
        { id: 11, label: "Grade 11", value: "Grade 11" },
        { id: 12, label: "Grade 12", value: "Grade 12" }
    ],
    ar: [
        { id: 1, label: "الصف الأول", value: "Grade 1" },
        { id: 2, label: "الصف الثاني", value: "Grade 2" },
        { id: 3, label: "الصف الثالث", value: "Grade 3" },
        { id: 4, label: "الصف الرابع", value: "Grade 4" },
        { id: 5, label: "الصف الخامس", value: "Grade 5" },
        { id: 6, label: "الصف السادس", value: "Grade 6" },
        { id: 7, label: "الصف السابع", value: "Grade 7" },
        { id: 8, label: "الصف الثامن", value: "Grade 8" },
        { id: 9, label: "الصف التاسع", value: "Grade 9" },
        { id: 10, label: "الصف العاشر", value: "Grade 10" },
        { id: 11, label: "الصف الحادي عشر", value: "Grade 11" },
        { id: 12, label: "الصف الثاني عشر", value: "Grade 12" }
    ]
};

export const calenderI18n = (context) => {
    return {
        cancel: context.t("_cancel"),
        done: context.t("_ok"),
        months: [context.t("_january"), context.t("_february"), context.t("_march"), context.t("_april"), context.t("_may"), context.t("_june"), context.t("_july"), context.t("_august"), context.t("_september"), context.t("_october"), context.t("_november"), context.t("_december")],
        monthsShort: [context.t("_jan"), context.t("_feb"), context.t("_mar"), context.t("_apr"), context.t("_may"), context.t("_jun"), context.t("_jul"), context.t("_aug"), context.t("_sep"), context.t("_oct"), context.t("_nov"), context.t("_dec")],
        weekdaysAbbrev: [context.t("_sun"), context.t("_mon"), context.t("_tue"), context.t("_wed"), context.t("_thu"), context.t("_fri"), context.t("_sat")]

    }
}
export const calenderLocal = (context) => {
    const months = [context.t("_january"), context.t("_february"), context.t("_march"), context.t("_april"), context.t("_may"), context.t("_june"), context.t("_july"), context.t("_august"), context.t("_september"), context.t("_october"), context.t("_november"), context.t("_december")]
    const days = [context.t("_sun"), context.t("_mon"), context.t("_tue"), context.t("_wed"), context.t("_thu"), context.t("_fri"), context.t("_sat")]

    return {
        localize: {
            month: n => months[n],
            day: n => days[n]
        },
        formatLong: {}
    }
}


export const CustomDateInput = forwardRef(
    ({ value, onClick, name, id, placeholder, ariaLabel, onFieldChange = () => { }, minDate, maxDate }, ref) => (
        <>
            <input
                name={name}
                autoComplete="off"
                onChange={e => {
                    e.target.value && onFieldChange(e.target.value)
                }}
                id={id}
                placeholder={placeholder}
                type="date"
                className="hiddenDob customDateField"
                ref={ref}
                value={value}
            // min={minDate}
            // max={maxDate}
            />
            <button aria-label={ariaLabel} type="button" className="example-custom-input" onClick={onClick}>
                <span aria-hidden="true" className="material-icons">date_range</span>
            </button>
        </>
    ),
);

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();

        return <Component {...props} router={{ location, navigate, params }} />;
    }

    return ComponentWithRouterProp;
}