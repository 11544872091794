import React, { useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const Activity = (props, context) => {
		const { Activity_count, currently_running, completed_activity_count, upcomming_activity_count } = props;
		return (
			<div className="dashboardBox">
				<h2>{context.t("_activity")}</h2>
				<table>
					<tbody>
						<tr>
							<td>{context.t("_total_activities")}</td>
							<td className="numberWidth">{Activity_count}</td>
						</tr>
						<tr>
							<td>{context.t("_current_activities_1")}</td>
							<td>{currently_running}</td>
						</tr>
						<tr>
							<td>{context.t("_completed_activities")}</td>
							<td>{completed_activity_count}</td>
						</tr>
						<tr>
							<td>{context.t("_upcoming_activities")}</td>
							<td>{upcomming_activity_count}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
Activity.defaultProps = {
	Activity_count: 0,
	currently_running: 0,
	completed_activity_count: 0,
	upcomming_activity_count: 0
}

Activity.contextTypes = {
	t: PropTypes.func
}
export default Activity;

// import React from "react"
// import Main from "../../main"
// import { connect } from "react-redux"
// import PropTypes from "prop-types"
// import _ from "lodash"
// // import { PopupAction, LoadingAction, ActivityCountAction } from "./../../../actions"
// // import { urlEndPoints } from "./../../../httpAPI/apiConfig"

// class Activity extends Main {
// 	constructor(props) {
// 		super(props);
// 	}
// 	componentDidMount() {
// 		// this.props.getTotalActivities({})
// 		// this.props.getCurrentActivities({})
// 		// this.props.getCompletedActivities({})
// 		// this.props.getUpcomingActivities({})
// 	}

// 	render() {
// 		const { Activity_count, currently_running, completed_activity_count, upcomming_activity_count } = this.props;
// 		return (
// 			<div className="dashboardBox">
// 				<h2>{this.context.t("_activity")}</h2>
// 				<table>
// 					<tbody>
// 						<tr>
// 							<td>{this.context.t("_total_activities")}</td>
// 							<td className="numberWidth">{Activity_count}</td>
// 						</tr>
// 						<tr>
// 							<td>{this.context.t("_current_activities_1")}</td>
// 							<td>{currently_running}</td>
// 						</tr>
// 						<tr>
// 							<td>{this.context.t("_completed_activities")}</td>
// 							<td>{completed_activity_count}</td>
// 						</tr>
// 						<tr>
// 							<td>{this.context.t("_upcoming_activities")}</td>
// 							<td>{upcomming_activity_count}</td>
// 						</tr>
// 					</tbody>
// 				</table>
// 			</div>
// 		)
// 	}

// }
// Activity.defaultProps = {
// 	Activity_count: 0,
// 	currently_running: 0,
// 	completed_activity_count: 0,
// 	upcomming_activity_count: 0
// }
// const mapStateToProps = ({ i18nState, activityCountReducer }) => {
// 	return {
// 		lang: i18nState.lang,
// 		// totalActivityCount: activityCountReducer.totalActivityCount,
// 		// currentActivityCount: activityCountReducer.currentActivityCount,
// 		// completedActivityCount: activityCountReducer.completedActivityCount,
// 		// upcomingActivityCount: activityCountReducer.upcomingActivityCount,
// 	}
// }
// const mapDispatchToProps = {
// 	// showPopup: (data) => PopupAction.showPopup(data),
// 	// hidePopup: (data) => PopupAction.hidePopup(data),
// 	// getTotalActivities: ActivityCountAction.getTotalActivities,
// 	// getCurrentActivities: ActivityCountAction.getCurrentActivities,
// 	// getCompletedActivities: ActivityCountAction.getCompletedActivities,
// 	// getUpcomingActivities: ActivityCountAction.getUpcomingActivities,
// 	// showLoader: LoadingAction.showLoader,
// 	// hideLoader: LoadingAction.hideLoader,
// }
// Activity.contextTypes = {
// 	t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(Activity);