import React from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Main from "../../constants/utilities"
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";
import M from "materialize-css";
import { connect, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import _ from "lodash"
import { LoadingAction } from "./../../actions"
import { useState } from "react";
import { useEffect } from "react";


const VirtualTour = (props, context) => {
    const { config, networkRequest } = Main()
    const configure = config()
    const [isLoaded, setIsLoaded] = useState(false)
    const [virtualTourList, setVirtualTourList] = useState([])
    const [pager, setPager] = useState({ count: 0, pages: 0, items_per_page: 0, current_page: 1 })
    const dispatch = useDispatch()

    const getVirtualTour = async () => {
        const url = `${configure.api_url}${urlEndPoints.getVirtualTours}`
        try {
            const data = await networkRequest(url)
            if (data.data.error) {

            } else {
                setVirtualTourList(data.data.results)
                setPager(data.data.pager)
                setIsLoaded(true)
            }
            dispatch(LoadingAction.hideLoader())
        } catch (error) {
            setIsLoaded(true)
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }

    useEffect(() => {
        M.AutoInit();
        dispatch(LoadingAction.showLoader())
        getVirtualTour()
    }, [])
    return (
        <div id="main-container">
            <SlideToTop />
            <PageTitle title={"Virtual Tour"} />
            <div className="container">
                <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                    <ul>
                        <li className="breadcrumb-item">
                            <Link to="/">{`Home`}</Link> {" "}

                        </li>
                        <li className="breadcrumb-item">
                            <span>
                                Virtual Tour
                            </span>

                        </li>
                    </ul>
                </nav>
            </div>
            <div className="container">

                <h2>Title</h2>

                <div className="row noFlex">
                    {isLoaded && virtualTourList.length == 0 ? <div className="row"><p aria-label={context.t("_no_result_found")} tabIndex="0" className="col s12 no-result">{context.t("_no_result_found")}</p></div> :
                        virtualTourList.map(item => {
                            return (
                                <div key={item?.nid} className="col s12 m6 l4">
                                    <div className="card virtualBox">
                                        <div className="card-image">
                                            <a className="btn-floating btn-large valign-wrapper iconPlay"><i className="material-icons large">play_arrow</i></a>
                                            <img src={`${configure.origin}${item.field_360_panoramas_photo}`} alt="" />
                                        </div>
                                        <div className="card-content cardText">
                                            <h3 className="card-title activator">{item.title} <i className="material-icons right">more_vert</i></h3>
                                            <div dangerouslySetInnerHTML={{ __html: item.body }}></div>
                                        </div>
                                        <div className="card-reveal">
                                            <h3 className="card-title">{item.title} <i className="material-icons right">close</i></h3>
                                            <div dangerouslySetInnerHTML={{ __html: item.body }}></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <nav className="pagination-wrapper" aria-label="pagination">
                <Pagination

                    hideDisabled={true}
                    activePage={pager.current_page + 1}
                    itemsCountPerPage={pager.items_per_page}
                    totalItemsCount={typeof(pager.count) !== Number ? Number(pager.count) : pager.count}
                    pageRangeDisplayed={5}
                // onChange={handlePageChange}
                />
            </nav>
        </div>
    )
}

VirtualTour.contextTypes = {
    t: PropTypes.func
}

export default VirtualTour
