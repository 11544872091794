import { USER_LOGGED_IN, USER_LOGOUT, SET_USER_IMAGE } from "./../constants/constants"

const userLoggedIn = (image) => {
    return async dispatch => {
        dispatch({
            type: USER_LOGGED_IN,
            payload: true,
            image
        })
    }

}
const userLogout = () => {
    return async dispatch => {
        dispatch({
            type: USER_LOGOUT,
            payload: false
        })
    }
}
const setUserImage = (path) => {
    return async dispatch => {
        dispatch({
            type: SET_USER_IMAGE,
            payload: path
        })
    }
}

export default {
    userLoggedIn,
    userLogout,
    setUserImage
}