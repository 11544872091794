import React, { Fragment, useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import Main from "../../constants/utilities"
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";
import M from "materialize-css"
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import _ from "lodash"

import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { LoadingAction } from "./../../actions"

const Machines = (props, context) => {
    const { config, networkRequest } = Main()
    const [pager, setPager] = useState({ count: 0, pages: 0, items_per_page: 0, current_page: 1 })
    const [isLoaded, setIsLoaded] = useState(false)
    const [configure, setConfigure] = useState(config())
    const [machineList, setMachineList] = useState([])
    const dispatch = useDispatch()
    const { lang } = useSelector((state) => state.i18nState)

    const getMachineList = async () => {
        const url = `${configure.api_url}${urlEndPoints.getMachinesList}?page=${pager.pages}`
        try {
            const data = await networkRequest(url)
            if (data.data.error) {

            } else {
                setMachineList(data.data.results)
                setPager(data.data.pager)
                setIsLoaded(true)
            }
            dispatch(LoadingAction.hideLoader())
        } catch (error) {
            setIsLoaded(true)
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
    const handlePageChange = (pageNumber) => {
        dispatch(LoadingAction.showLoader())
        pager.pages = pageNumber - 1
        setPager(pager)
        getMachineList()
    }
    useEffect(() => {
        dispatch(LoadingAction.showLoader())
        document.title = `${context.t("_studio56")} | ${context.t("_machines")}`
        M.AutoInit();
        (async () => await getMachineList())()
        setTimeout(() => {
            var elems = document.querySelectorAll(".machineModal");
            M.Modal.init(elems)
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        }, 1000);
    }, [])
    return (
        <div id="main-container">
            <SlideToTop />
            <PageTitle title={context.t("_machines")} isDashboardItem={true} />
            <div className="container">
                <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                    <ul>
                        <li className="breadcrumb-item">
                            <Link to="/">{context.t("_home")}</Link> {" "}
                        </li>
                        <li className="breadcrumb-item">
                            <Link tabIndex="0" to aria-current={context.t("_page")}>
                                <span>{context.t("_machines")}</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div id="skipContent" className="container">

                <h2 className="line">{context.t("_machines")}</h2>
                <div className="machine-item-holder">

                    {isLoaded && machineList.length == 0 ? <div className="row"><p aria-label={context.t("_no_result_found")} tabIndex="0" className="col s12 no-result">{context.t("_no_result_found")}</p></div> :
                        machineList.map(item => {
                            return (
                                <div className="machine-item" key={item.nid}>
                                    <figure className="machine-item-figure">
                                        <img src={`${configure.origin}${item.field_machines_image_1}`} alt="" />
                                    </figure>
                                    <aside className="machine-item-content">
                                        <Link to={`/machine-detail/${item.nid}`}>
                                            <h4>{item.title}</h4>
                                        </Link>
                                        {!_.isEmpty(item.field_machine_brand) && <h5>{item.field_machine_brand}</h5>}

                                        <div dangerouslySetInnerHTML={{ __html: item.field_machines_brief }}></div>
                                        <Link aria-label={`view more ${item.title}`} className="machine-btn" to={`/machine-detail/${item.nid}`}>{context.t("_view_more")}</Link>
                                    </aside>
                                </div>
                            )
                        })
                    }
                </div>
                <nav className="pagination-wrapper" aria-label="pagination">
                    <Pagination
                        prevPageText={`< ${context.t("_previous")}`}
                        nextPageText={`${context.t("_next")} >`}
                        hideFirstLastPages={true}
                        hideDisabled={true}
                        linkClassPrev="linkClassPrev"
                        linkClassNext="linkClassNext"
                        activePage={pager.current_page + 1}
                        itemsCountPerPage={pager.items_per_page}
                        totalItemsCount={Number(pager.count)}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </nav>
            </div>
        </div >
    )
}

Machines.contextTypes = {
    t: PropTypes.func
}

export default Machines
