import React, { useEffect, useState } from "react"
import Main from "../../constants/utilities"
import { connect, useSelector } from "react-redux"
import PropTypes from "prop-types"
const axios = require("axios");

const Zone = (_, context) => {
	const { config } = Main()
	const configure = config()
	const [zones, setZones] = useState([])
	const { lang } = useSelector(state => state.i18nState)

	function get_zone() {

		let url = "";
		if (lang == "ar")
			url = `${configure.origin}${lang}/api/zones`
		else
			url = `${configure.origin}api/zones`

		var self = this;
		axios.get(url, {
			params: {
				_format: "json"
			}
		})
			.then(function (response) {
				setZones(response.data)
			})
			.catch(function (error) {
				console.warn(error);
			})
			.then(function () {
				// always executed
			});
	}
	useEffect(() => {
		get_zone()
	}, [])
	return (
		<div className="zone-wrap">
			<div className="container">
				<div className={`zone-text ${lang}`}>
					<h3>{context.t("_zone")}</h3>
					<div>{context.t("_zone_description")}</div>
				</div>
				<div className={`service-zone ${lang}`}>
					<div className="flex-row same-height col-4 ng-scope">
						{zones.map((row, index) => (
							<div className="item" key={index} >
								<div className="icon" style={{ background: `url(${configure.origin + row.zoneimage}) no-repeat` }}></div>
								<h3 dangerouslySetInnerHTML={{ __html: row.zonenames }}></h3>
								<div className="desc" dangerouslySetInnerHTML={{ __html: row.zonesdescription }}></div>
							</div>
						))}
					</div>
					<span className="grey-square"></span>
				</div>
			</div>
		</div>
	)
}
Zone.contextTypes = {
	t: PropTypes.func
}
export default Zone
