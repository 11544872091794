
import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types"
import { useDispatch, useSelector } from 'react-redux'
import Pagination from "react-js-pagination";
import PageTitle from "../includes/page_title.js";
import SlideToTop from "../includes/slide_to_top.js";
import Main from '../../constants/utilities'
import { LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import topLineImage from "../../assets/images/top-line.png"
import { Link } from 'react-router-dom';
import mailIcon from "../../assets/images/mail.svg";
const MediaCenter = (props, context) => {

  const { config, networkRequest } = Main()
  const [filter, setFilter] = useState([])
  const [pager, setPager] = useState({ count: 0, pages: 0, items_per_page: 0, current_page: 1 })
  const [isLoaded, setIsLoaded] = useState(false)
  const [configure, setConfigure] = useState(config())
  const [search, setSearch] = useState({ page: 0 })
  const [error, setError] = useState("")
  const [media, setMedia] = useState([])
  const dispatch = useDispatch()
  const { lang } = useSelector((state) => state.i18nState)


  const getMediaList = async (isPagination) => {
    let url = "";
    if (lang == "ar")
      url = `${configure.origin}${lang}/api/${urlEndPoints.mediaCenter}`
    else
      url = `${configure.origin}api/${urlEndPoints.mediaCenter}`

    if (search.page)
      url = `${url}&page=${search.page}`
    try {
      const data = await networkRequest(url)
      setMedia(data.data.results)
      
      
      setPager(data.data.pager)
      setIsLoaded(true)
      dispatch(LoadingAction.hideLoader())
      if (isPagination)
        this.refs.mediaCenterRef0 && this.refs.mediaCenterRef0.focus()
    } catch (error) {
      setIsLoaded(true)
      dispatch(LoadingAction.hideLoader())
      console.warn("error: ", error)
    }
  }
  const filterMenu = async () => {
    let url = "";
    if (lang == "ar")
      url = `${configure.api_url}${urlEndPoints.filter(lang)}`
    else
      url = `${configure.api_url}${urlEndPoints.filter(lang)}`
    try {
      const data = await networkRequest(url)
      setFilter(data.data)
    } catch (error) {
      dispatch(LoadingAction.hideLoader())
      console.warn("error: ", error)
    }
  }
  function handlePageChange(pageNumber) {
    dispatch(LoadingAction.showLoader())
    search.page = pageNumber - 1
    setSearch({ ...search })
    getMediaList(true);
  }
  useEffect(() => {
    dispatch(LoadingAction.showLoader())
    document.title = `${context.t("_studio56")} | ${context.t("_navbar_media_center")}`
    filterMenu();
    (async () => await getMediaList())()
    setTimeout(() => {
      document.getElementById("pageHeading") && document.getElementById("pageHeading").focus()
    }, 500);
  }, [])
  return (
    <>
      <div id="main-content" className="media-center-page">
        <SlideToTop />
        <PageTitle title={context.t("_navbar_media_center")} isDashboardItem={true} />
        <div className="container">
          <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
            <ul>
              <li className="breadcrumb-item">
                <Link to="/">{context.t("_home")}</Link> {" "}
              </li>
              <li className="breadcrumb-item">
                <Link tabIndex="0" to aria-current={context.t("_page")}>
                  <span>{context.t("_navbar_media_center")}</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div id="skipContent">
          <div className="container">
            <div className="row">
              <div className="col s3 left-side">
                <div className="filter-wrap">
                  <h3 id="filter-title">{context.t("_filter_media")}</h3>
                  <ul aria-label="aside navigation">
                    {filter.map((row, index) => (
                      <li className={`media-item ${lang}`} key={index}>
                        <Link aria-label={row.filter + " " + context.t("_section") + " " + context.t("_section {number}", { number: row.count })} to={"/filter-media/" + row.tid}>
                          {row.filter}
                          <span>{row.count}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <span className="grey-square-rotate"></span>
                  <span className="multi-square">
                    <b>
                      <i></i>
                    </b>
                  </span>
                </div>
              </div>
              <div className="col s9 media-content">
                <span className="orange-circle"></span>
                <div className="flex-row col-3">
                  {isLoaded && media.length == 0 ? <div className="row"><p aria-label={context.t("_no_result_found")} tabIndex="0" className="col s12 no-result">{context.t("_no_result_found")}</p></div> : <ul aria-label="media center">
                    {media.map((row, index) => (
                      <li className="media-item" key={index}>
                        <div
                          dangerouslySetInnerHTML={{ __html: row.category }}
                          className={"category-wrap " + row.field_key.toLowerCase()}
                        ></div>
                        <h3>
                          <Link aria-label={`${row.category} ${row.title} ${row.date}`} to={"/news/" + row.nid}>{row.title}</Link>
                        </h3>
                        <div className="mediaList">
                          <div className="medialistImg">
                            {row.field_key === "Videos" && (
                              <div className="videowrapper">
                                <object data={row.video}>
                                  <param name="movie" value={row.video}></param>
                                  <param
                                    name="allowFullScreen"
                                    mozallowfullscreen="mozallowfullscreen"
                                    msallowfullscreen="msallowfullscreen"
                                    oallowfullscreen="oallowfullscreen"
                                    webkitallowfullscreen="webkitallowfullscreen"
                                    value="true"
                                  ></param>
                                  <span>video</span>
                                </object>
                              </div>
                            )}
                            {
                            ((row.field_key === "Gallery" || row.field_key === "News" || row.field_key === "Articles") && row.field_newsimage) 
                               && (
                                <div
                                  // dangerouslySetInnerHTML={{ __html: row.media }}
                                  className="img-wrap imageFixedSize"
                                >
                                  <img alt="" src={configure.origin + row.field_newsimage} />
                                </div>
                              )}

                          </div>
                          <div className="medialistText">
                            <div
                              className="desc"
                              dangerouslySetInnerHTML={{ __html: row.description }}
                            ></div>
                            <div
                              className="date-wrap"
                              dangerouslySetInnerHTML={{ __html: row.date }}
                            ></div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>}
                </div>
                <nav className="pagination-wrapper" aria-label="pagination">
                  {(media.length) ? (
                    <Pagination
                      prevPageText={`< ${context.t("_previous")}`}
                      nextPageText={`${context.t("_next")} >`}
                      hideFirstLastPages={true}
                      hideDisabled={true}
                      linkClassPrev="linkClassPrev"
                      linkClassNext="linkClassNext"
                      activePage={pager.current_page + 1}
                      itemsCountPerPage={pager.items_per_page}
                      totalItemsCount={pager.count}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                    />) : ""
                  }
                </nav>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="top-line ng-scope">
        <img src={topLineImage} alt="" />
      </div>
      <div id="register-wrap" className="ng-scope">
        <div className="container">
          <div><img src={mailIcon} className="mail-icon" alt="" /></div>
          <p>{context.t("_latest_insight")}</p>
          <p><Link to="/subscribe" className="red-btn btn">{context.t("_subscribe")} <i className={`material-icons ${lang}`}>{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i></Link></p>
        </div>
      </div>
    </>
  )
}
MediaCenter.contextTypes = {
  t: PropTypes.func
}
export default MediaCenter
