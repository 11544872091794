import React from "react"
import { Route, BrowserRouter as Router, Routes as Switch, } from "react-router-dom"
import { setLanguage } from "redux-i18n"
import { connect } from "react-redux"
import _ from "lodash"
import IdleTimer from "react-idle-timer";
import Authorization from "./authorization"
import { Loading, PopupModal } from "./../components/common"

import Navigation from "./../components/includes/navigation"
import Footer from "./../components/includes/footer"
import Home from "./../components/home/home"
import MediaCenter from "./../components/media-center/media_center"
import FilterMedia from "./../components/media-center/filter_media"
import Subscribe from "./../components/subscribe/subscribe"
import ThankYou from "./../components/subscribe/thank_you"
import News from "./../components/news/news"
import Notfound from "./../components/notfound"
import Login from "./../components/auth/login"
import ChangePassword from "./../components/auth/changePassword"
// import Register from "./../components/auth/register"
import ResetPassword from "./../components/auth/resetPassword"
import ForgotPassword from "./../components/auth/forgotPassword"
// import Dashboard from "./../components/dashboard"
import ActivitiesList from "./../components/activities/activities_list"
import ActivityDetails from "./../components/activities/activity_detail"
import Registration from "./../components/registration/registration"
import SchoolRegistration from "./../components/registration/schoolRegistration"
import ParentRegistration from "./../components/registration/parentRegistration"
import VolunteerRegistration from "./../components/registration/volunteerRegistration"
import StudentRegistration from "./../components/registration/studentRegistration"
import UniversityStudentRegistration from "../components/registration/universityStudentRegistration"
import Profile from "./../components/profile/profile"
import StudentSettings from "./../components/profile/student/studentSettings"
import StudentConnections from "./../components/profile/student/studentConnections"
import ViewStudentProfile from "./../components/profile/student/viewProfile"
import ViewStudentSettings from "./../components/profile/student/viewSettings"
import ViewStudentConnection from "./../components/profile/student/viewConnections"
import ViewStudentActivity from "./../components/profile/student/viewActivities"
import ParentSettings from "./../components/profile/parent/parentSettings"
import ParentConnections from "./../components/profile/parent/parentConnections"
import SchoolSettings from "./../components/profile/school/schoolSettings"
import VolunteerSettings from "./../components/profile/volunteer/volunteerSettings"
import ActivityHistory from "./../components/profile/volunteer/activityHistory"
import SchoolConnections from "./../components/profile/school/schoolConnections"
import CurrentActivities from "./../components/profile/currentActivities"
// import Workshop from "./../components/workshop/workshop"
import SubscribeActivities from "./../components/subscribe/subscribeWithActivities"
import Machines from "./../components/machines/machines";
import FabLabs from "./../components/fab-labs/fabLabs";
import Announcements from "./../components/announcements/announcements";
import Faq from "./../components/faq/faq";
import VirtualTour from "./../components/virtual-tour/virtualTour";
// import panorama from "./../components/panorama";
// import panorama1 from "./../components/panorama/index1";
import AboutUs from "./../components/about-us/about_us";
import WaitingList from "./../components/profile/school/waitingList"
import EquipmentUsed from "./../components/equipment-used/equipmentUsed"
import Notification from "./../components/notification/notification"
import AwardCompetition from "./../components/awards-and-competition/awardCompetition"
import ContactUs from "./../components/contact/contact"
import Publications from "./../components/publications/publications"
import Calendar from "./../components/calender/calender"
import PrivacyPolices from "./../components/privacy-polices/privacyPolices"
import Makers from "./../components/makers/makers"
import AdminProfile from "./../components/profile/admin/profile"
import AdminUpcomingActivity from "./../components/profile/admin/upcomingActivity"
import AdminActivityHistory from "./../components/profile/admin/activityHistory"
import AdminCalendar from "./../components/profile/admin/calender"
import MarkAttendance from "./../components/profile/admin/markAttendance"
import EnrolmentListing from "./../components/profile/admin/enrolmentListing"
import EnrolmentStudentListing from "./../components/profile/admin/enrolmentStudentList"
import Summer from "./../components/summer/summer"
import ContactThankYou from "./../components/contact/thank_you"
import Dashboard from "./../components/dashboard/dashboard"
import DashboardActivityDetails from "./../components/dashboard/activity-details"
import DashboardStudentDetails from "./../components/dashboard/student-details"
import UiScreen from "./../components/ui-screens"
import StudentAttendance from "./../components/profile/student/studentAttendance"
import ParentProfile from "./../components/profile/parent/parentProfile"
import StudentTranscript from "./../components/profile/student/studentTranscript"
import MachineDetail from "./../components/machines/machineDetail"
import Maintenance from "./../components/maintenance/maintenance"
import Reward from "../components/profile/student/reward"
import UniversityProfileSettings from "./../components/profile/student/universityStudentSettings"

import PrivateRoute from "./../components/auth/privateRoute";
import { dynamicStyles } from "./../constants/dynamicStyles"

import { urlEndPoints } from "./../httpAPI/apiConfig"
import Main from "./../components/main"


// import ActivityDetails from "./act-details/act-details"

import "../assets/css/materialize.min.css"
import "../assets/css/slick.css"
import "../assets/css/style.css"
import "../assets/css/font-awesome.css"

// const Student = Authorization(["student"]);
// const Parent = Authorization(["parent"]);
// const StudentParent = Authorization(["student", "parent"]);
// const School = Authorization(["school"]);
// const Volunteer = Authorization(["volenteer"]);
// const Admin = Authorization(["trainer", "manager", "asistant", "edu_specialist", "administrator"]);
// const Administrator = Authorization(["manager", "administrator", "edu_specialist"]);
// const Common = Authorization(["student", "parent", "school", "volenteer", "trainer", "manager", "asistant", "edu_specialist", "administrator"]);

const common = ["student", "parent", "school", "volenteer", "trainer", "manager", "asistant", "edu_specialist", "administrator", "university_student"]
const school = ["school"]
const student = ["student", "university_student"]
const parent = ["parent"]
const volunteer = ["volenteer"]
const admin = ["trainer", "manager", "asistant", "edu_specialist", "administrator"]
const administrator = ["manager", "administrator", "edu_specialist"]


class Routes extends Main {
  constructor(props) {
    super(props)
    this.state = {
      timeout: 300000 * 3,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      loading: false,
      isMaintenance: false,
      config: this.config(),

    }

    this.idleTimer = null
    // this.onAction = this._onAction.bind(this)
    // this.onActive = this._onActive.bind(this)
    // this.onIdle = this._onIdle.bind(this)
  }
  async componentDidMount() {

    try {
      const lang = await localStorage.getItem("lang");
      let pathName = window.location.pathname.toString();
      const urlLang = pathName.split("/")
      if (urlLang[1] == "ar" || urlLang[1] == "en") {
        if (urlLang[1] != lang) {
          await localStorage.setItem("lang", lang == "en" ? "ar" : "en");
          window.location.reload();
        }
      }

      if (lang !== null) {
        this.props.dispatch(setLanguage(lang || "en"))
      } else {
        localStorage.setItem("lang", "en");
        this.props.dispatch(setLanguage("en"))
      }
      this.getMaintenance()
    } catch (error) {
      // console.log("ERROR", error);
      // Error retrieving data
    }
  }
  getMaintenance = async () => {
    const { config } = this.state;
    let url = `${config.origin}${urlEndPoints.getMaintananceMode}`

    try {
      const data = await this.networkRequest(url, "POST")
      if (_.has(data, "data.maintanace_mode") && data.data.maintanace_mode == 1) {
        this.setState({
          loading: true,
          isMaintenance: true
        }, () => {
          this.forceUpdate()
          this.props.dispatch({
            type: "HIDE_LOADER", payload: false
          })
        })
      }
    } catch (error) {
      console.warn("error: ", error)
    }
    // setTimeout(() => {
    // 
    // }, 3000);
  }
  componentDidUpdate() {
    let elem = document.querySelectorAll('select');
    elem.forEach(element => {
      element.setAttribute("tabindex", "0");
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    // return !(nextState.isTimedOut == this.state.nextState)
    return false;
  }
  onAction = (e) => {
    this.setState({ isTimedOut: false })
  }

  onActive = (e) => {
    this.setState({ isTimedOut: false })
  }

  onIdle = (e) => {
    const isTimedOut = this.state.isTimedOut
    const { lang } = this.props;
    if (isTimedOut) {
      if (
        !_.isEmpty(localStorage.getItem("accessToken")) ||
        !_.isEmpty(localStorage.getItem("refreshToken")) ||
        !_.isEmpty(localStorage.getItem("tokenType")) ||
        !_.isEmpty(localStorage.getItem("role"))
      ) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("tokenType");
        localStorage.removeItem("role");
        window.location = `/${lang}/login`
      }
    } else {
      this.setState({ showModal: true })
      this.idleTimer.reset();
      this.setState({ isTimedOut: true })
    }

  }

  render() {
    const { lang } = this.props;
    const { isMaintenance, loading } = this.state
    return (
      <>
        {
          loading && isMaintenance ? <div style={Object.assign(dynamicStyles.direction(lang), { overflow: "hidden" })} className={lang == "ar" ? "ab" : ""}>
            <Router basename={`/${lang}/`}>
              <Loading />
              <div className="clearfix" />
              <Route element={<Maintenance />} />
            </Router>
          </div>
            :
            <div style={Object.assign(dynamicStyles.direction(lang), { overflow: "hidden" })} className={lang == "ar" ? "ab" : ""}>
              <a href="#skipContent" className="skipLink">
                {lang == "ar" ? "تخطي إلى المحتوى الرئيسي" : "Skip to main content"}
              </a>
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={(e) => this.onActive(e)}
                onIdle={(e) => this.onIdle(e)}
                onAction={(e) => this.onAction(e)}
                // debounce={50}
                timeout={this.state.timeout}
              />
              <Router basename={`/${lang}/`}>
                <Navigation />
                <Loading />
                <PopupModal />
                <div className="clearfix" />
                <Switch>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/media-center" element={<MediaCenter />} />
                  <Route exact path="/filter-media/:id" element={<FilterMedia />} />
                  <Route exact path="/subscribe" element={<Subscribe />} />
                  <Route exact path="/thankyou" element={<ThankYou />} />
                  <Route exact path="/login" element={<Login />} />
                  <Route exact path="/news/:id" element={<News />} />
                  <Route exact path="/activities" element={<ActivitiesList />} />
                  <Route exact path="/activity/:id" element={<ActivityDetails />} />
                  <Route exact path="/registration" element={<Registration />} />
                  <Route exact path="/registration/school" element={<SchoolRegistration />} />
                  <Route exact path="/registration/parent" element={<ParentRegistration />} />
                  <Route exact path="/registration/volunteer" element={<VolunteerRegistration />} />
                  <Route exact path="/registration/university-student" element={<UniversityStudentRegistration />} />
                  <Route exact path="/registration/student" element={<StudentRegistration />} />
                  <Route extend path="/reset-password/:token" element={<ResetPassword />} />
                  <Route extend path="/forgot-password" element={<ForgotPassword />} />
                  <Route exact path="/machines" element={<Machines />} />
                  <Route exact path="/fab-labs" element={<FabLabs />} />
                  <Route exact path="/announcements" element={<Announcements />} />
                  <Route exact path="/faq" element={<Faq />} />
                  <Route exact path="/virtual-tour" element={<VirtualTour />} />

                  <Route exact path="/about" element={<AboutUs />} />
                  <Route exact path="/equipment-used" element={<EquipmentUsed />} />
                  <Route exact path="/awards-competition" element={<AwardCompetition />} />
                  <Route exact path="/contact" element={<ContactUs />} />
                  <Route exact path="/contact-success" element={<ContactThankYou />} />
                  <Route exact path="/publications" element={<Publications />} />
                  <Route exact path="/privacy-policies" element={<PrivacyPolices />} />
                  <Route exact path="/makers" element={<Makers />} />
                  <Route exact path="/machine-detail/:id" element={<MachineDetail />} />
                  <Route exact path="/summer" element={<Summer />} />
                  <Route exact path="/ui-screen" element={<UiScreen />} />
                  <Route exact path="/profile" element={<PrivateRoute roles={common} ><Profile /></PrivateRoute>} />
                  <Route exact path="/university-student-settings" element={<PrivateRoute roles={student} ><UniversityProfileSettings/></PrivateRoute>} />
                  <Route exact path="/student-settings" element={<PrivateRoute roles={student} ><StudentSettings /></PrivateRoute>} />
                  <Route exact path="/parent-settings" element={<PrivateRoute roles={parent} ><ParentSettings /></PrivateRoute>} />
                  <Route exact path="/school-settings" element={<PrivateRoute roles={school} ><SchoolSettings /></PrivateRoute>} />
                  <Route exact path="/current-activities" element={<PrivateRoute roles={common} ><CurrentActivities /></PrivateRoute>} />
                  <Route exact path="/volenteer-settings" element={<PrivateRoute roles={volunteer}><VolunteerSettings /></PrivateRoute>} />
                  <Route exact path="/volenteer-history" element={<PrivateRoute roles={volunteer}><ActivityHistory /></PrivateRoute>} />
                  <Route exact path="/student-connections" element={<PrivateRoute roles={student} ><StudentConnections /></PrivateRoute>} />
                  <Route exact path="/parent-connections" element={<PrivateRoute roles={parent} ><ParentConnections /></PrivateRoute>} />
                  <Route exact path="/school-connections" element={<PrivateRoute roles={school}><SchoolConnections /></PrivateRoute>} />
                  <Route exact path="/subscribe-activities/:id" element={<PrivateRoute><SubscribeActivities /></PrivateRoute>} />
                  <Route exact path="/student-profile/:id" element={<PrivateRoute roles={common}><ViewStudentProfile /></PrivateRoute>} />
                  <Route exact path="/student-connection/:id" element={<PrivateRoute roles={common}><ViewStudentConnection /></PrivateRoute>} />
                  <Route exact path="/student-activity/:id" element={<PrivateRoute roles={common}><ViewStudentActivity /></PrivateRoute>} />
                  <Route exact path="/change-password" element={<PrivateRoute roles={common}><ChangePassword /></PrivateRoute>} />
                  <Route exact path="/calendar" element={<PrivateRoute roles={common}><Calendar /></PrivateRoute>} />
                  <Route exact path="/waiting-list" element={<PrivateRoute roles={school}><WaitingList /></PrivateRoute>} />
                  <Route exact path="/notification" element={<PrivateRoute roles={common}><Notification /></PrivateRoute>} />
                  <Route exact path="/admin-profile" element={<PrivateRoute roles={admin}><AdminProfile /></PrivateRoute>} />


                  <Route exact path="/upcoming-activity" element={<PrivateRoute roles={admin}><AdminUpcomingActivity /></PrivateRoute>} />
                  <Route exact path="/admin-history" element={<PrivateRoute roles={admin}><AdminActivityHistory /></PrivateRoute>} />
                  <Route exact path="/admin-calendar" element={<PrivateRoute roles={admin}><AdminCalendar /></PrivateRoute>} />
                  <Route exact path="/attendance/:activityId" element={<PrivateRoute roles={admin}><MarkAttendance /></PrivateRoute>} />
                  <Route exact path="/admin-enrolment" element={<PrivateRoute roles={admin}><EnrolmentListing /></PrivateRoute>} />
                  <Route exact path="/admin-enrolment/:activityId" element={<PrivateRoute roles={admin}><EnrolmentStudentListing /></PrivateRoute>} />
                  <Route exact path="/admin-enrolment/:activityId" element={<PrivateRoute roles={admin}><EnrolmentStudentListing /></PrivateRoute>} />

                  <Route exact path="/dashboard" element={<PrivateRoute roles={administrator}><Dashboard /></PrivateRoute>} />
                  <Route exact path="/dashboard/:type" element={<PrivateRoute roles={administrator}><Dashboard /></PrivateRoute>} />
                  <Route exact path="/activity-details/:id" element={<PrivateRoute roles={administrator}><DashboardActivityDetails /></PrivateRoute>} />
                  <Route exact path="/student-details/:id" element={<PrivateRoute roles={administrator}><DashboardStudentDetails /></PrivateRoute>} />

                  <Route exact path="/student-attendance" element={<PrivateRoute roles={student}><StudentAttendance /></PrivateRoute>} />

                  <Route exact path="/student-attendance/:id" element={<PrivateRoute roles={parent}><StudentAttendance /></PrivateRoute>} />
                  <Route exact path="/parent-profile" element={<PrivateRoute roles={parent}><ParentProfile /></PrivateRoute>} />
                  <Route exact path="/parent-profile/:menuType" element={<PrivateRoute roles={parent}><ParentProfile /></PrivateRoute>} />

                  <Route exact path="/student-transcript/:id" element={<PrivateRoute roles={student}><StudentTranscript /></PrivateRoute>} />
                  <Route exact path="/reward/:id" element={<PrivateRoute roles={common}><Reward /></PrivateRoute>} />
                  <Route element={<Notfound />} />
                </Switch>
                <Footer />
              </Router>
            </div>
        }
      </>
    )
  }
}
const mapStateToProps = ({ i18nState }) => {
  return {
    lang: i18nState.lang
  }
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Routes)