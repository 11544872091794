import React, { useState, useEffect } from "react"
import Main from "../../constants/utilities.js"
import SlideToTop from "../includes/slide_to_top.js"
import PageTitle from "../includes/page_title.js"
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker"
import M from "materialize-css"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import { calenderI18n, CustomDateInput, calenderLocal } from "./../../constants/common"
import _ from "lodash"
import { PopupAction, LoadingAction } from "./../../actions"
import topLineImage from "../../assets/images/top-line.png"
import dateformat from "dateformat";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loading } from "./../../components/common"
import { useRef } from "react"


// class ActivitiesList extends Main {
// 	constructor(props) {
// 		super(props);
// 		checkLogin();
// 		state = {
// 			isLoaded: false,
// 			config: config(),
// 			results: [],
// 			userActivityList: [],
// 			studentList: [],
// 			pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
// 			search: { _format: "json", type: "All", title: "", field_start_end_dates_value: "", page: 0 },
// 			modalContent: {
// 				type: "",
// 				title: "",
// 				message: "",
// 				isOpen: false,
// 				buttonText: ""
// 			},
// 			intervalId: 0,
// 			scrollStepInPx: 50,
// 			delayInMs: 16.66,
// 			searchActive: false
// 		}
// 		dispatch(LoadingAction.showLoader())
// 		handleChange = handleChange.bind(this);
// 		onSubmit = onSubmit.bind(this);
// 		handlePageChange = handlePageChange.bind(this);


// 	}
// 	async componentDidMount() {
// 		document.title = `${context.t("_studio56")} | ${context.t("_activities")}`
// 		M.AutoInit();
// 		var options = {
// 			autoClose: true,
// 			format: "d mmmm, yyyy",
// 			onSelect: ((date) => {
// 				handleChange("field_start_end_dates_value", dateformat(date, "d mmmm, yyyy"))
// 				// handleChange("field_start_end_dates_value", dateformat(date, "yyyy-mm-dd"))
// 			}),
// 			showClearBtn: true,
// 			i18n: {
// 				cancel: context.t("_cancel"),
// 				done: context.t("_ok"),
// 				clear: context.t("_clear")
// 			},
// 		}
// 		setTimeout(() => {
// 			var elems = document.querySelectorAll("select");
// 			M.FormSelect.init(elems, options);
// 			var clearBtn = document.querySelectorAll(".datepicker-clear");
// 			clearBtn.forEach(data => {
// 				data.setAttribute("aria-label", context.t("_clear"));
// 			});
// 		}, 1000);
// 		if (localStorage.getItem("role") == "parent")
// 			await getChildren();
// 		if (localStorage.getItem("role") == "student")
// 			await checkStudentEnrolled();
// 		if (localStorage.getItem("role") == "volenteer")
// 			await checkVolunteerEnrolled();
// 		await getActivity();
// 		window.addEventListener('load', handleTabIndex());
// 	}
// 	componentDidUpdate() {
// 		document.title = `${context.t("_studio56")} | ${context.t("_activities")}`
// 	}

// 	render() {
// 		const { results, pager, isLoaded, search, userActivityList, config, searchActive } = state;
// 		const { lang } = props;
// 		const listActivity = search.type == "All" ? results : results.filter(data => data.field_event_type.toLowerCase() == search.type)
// 		return (
// 			<div id="main-content" className="activiti-list">
// 				<SlideToTop />
// 				<PageTitle title={context.t("_activities")} isDashboardItem={true} />
// 				<div className="container">
// 					<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
// 						<ul>
// 							<li className="breadcrumb-item">
// 								<Link to="/">{context.t("_home")}</Link>{" "}
// 							</li>
// 							<li className="breadcrumb-item">
// 								<Link tabIndex="0" to aria-current={context.t("_page")}>
// 									<span>{context.t("_activities")}</span>
// 								</Link>
// 							</li>
// 						</ul>
// 					</nav>
// 				</div>
// 				<span className="multi-square one"><b><i></i></b></span>
// 				<div id="skipContent">
// 					<div id="main-container">
// 						<div className="container">
// 							<form onSubmit={onSubmit} method="post" name="search" className="search">
// 								<div className="row topFormHolder">
// 									<div className="col s12 m6 l3">
// 										<div className="input-field item">
// 											<input name="Title"
// 												placeholder={context.t("_title")}
// 												onChange={evt => handleChange("title", evt.target.value)}
// 												onFocus={evt => handleChange("title", evt.target.value)}
// 												value={search.title}
// 												id="title"
// 												type="text"
// 												aria-label="Activity search title"
// 											/>
// 											<label style={dynamicStyles.position(lang)} htmlFor="title">{context.t("_title")}</label>
// 											<span className="helper-text" ></span>
// 										</div>
// 									</div>
// 									<div className="col s12 m6 l3 date-popup">
// 										<div className={`input-field mobileSelect item ${lang}`}>
// 											<label htmlFor="activityType" className="active" style={dynamicStyles.position(lang)}>{context.t("_activity_type")}</label>
// 											<select
// 												className="browser-default" id="activityType" tabIndex="0" value={search.category} onChange={evt => handleChange("category", evt.target.value)}>
// 												<option value="" disabled >{context.t("_select_activity_type")}</option>
// 												<option value="All" >{context.t("_all")}</option>
// 												<option value="77">{context.t("_public_activity")}</option>
// 												<option value="78">{context.t("_school_activity")}</option>
// 											</select>
// 											<span className="helper-text"></span>
// 										</div>
// 									</div>
// 									<div className="col s12 m6 l3 date-popup">

// 										<div className="input-field item">
// 											<DatePicker
// 												name="date"
// 												placeholderText={context.t("_date")}
// 												id="date"
// 												autoComplete="off"
// 												dateFormat="yyyy-MM-dd"
// 												locale={calenderLocal(context)}
// 												customInput={<CustomDateInput ariaLabel={context.t("_select_date_button")} onFieldChange={date => handleChange("field_start_end_dates_value", new Date(date))} />}
// 												ariaLabelledBy="select date"
// 												onChange={date =>
// 													handleChange("field_start_end_dates_value", date)
// 												}
// 												onCalendarOpen={e => {
// 													let elems = document.querySelector(".react-datepicker");
// 													elems.setAttribute("tabindex", "0")
// 													elems && elems.focus()
// 													elems = document.querySelector(".react-datepicker__month-select");
// 													elems.setAttribute("aria-label", context.t("_select_month"))
// 													elems = document.querySelector(".react-datepicker__year-select");
// 													elems.setAttribute("aria-label", context.t("_select_year"))

// 												}}
// 												onCalendarClose={e => {
// 													var elems = document.querySelector(".hiddenDob");
// 													elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(search.field_start_end_dates_value, "dd-mm-yyyy")}`)
// 													elems.setAttribute("value", dateformat(search.field_start_end_dates_value, "yyyy-mm-dd"))
// 													setTimeout(() => {
// 														elems && elems.focus()
// 													}, 200);
// 												}}
// 												style={dynamicStyles.textAlign(lang)}
// 												value={search.field_start_end_dates_value}
// 												selected={search.field_start_end_dates_value}
// 												peekNextMonth
// 												showMonthDropdown
// 												showYearDropdown
// 												dropdownMode="select"
// 											/>
// 											<label style={dynamicStyles.position(lang)} htmlFor="date">{context.t("_date")}</label>
// 											<span className="helper-text" ></span>
// 										</div>
// 									</div>
// 									<div className="col s12 m6 l3 valign-wrapper center-align search-btn-holder">
// 										<button type="submit" className="btn noWidth profileBtn yellow waves-effect waves-light" aria-label="Activity search">{context.t("_search")} <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i></button>
// 										<button type="button" aria-label="Activity search clear" onClick={() => clearSearch()} className="btn noWidth blue-btn waves-effect waves-light">
// 											{context.t("_clear")}
// 										</button>
// 									</div>
// 								</div>
// 							</form>
// 							<div className="row">
// 								<div className="col s7 result-status">
// 									{isLoaded && searchActive ? <h2 tabIndex="0" ref="searchResult" className="search-result">{context.t("_search_result") + " ( " + (listActivity.length) + ` ${context.t("_of")} ` + pager.count + " )"}</h2> : ""}

// 								</div>
// 								<div className="col s5 result-type">
// 									<div className={`input-field col s12 mobileSelect filter-select ${lang}`}>
// 										<label htmlFor="result-type">{context.t("_select_activity")}</label>
// 										<select id="result-type" className="browser-default" value={search.type} onChange={evt => handleChange("type", evt.target.value)}>
// 											<option value="All" >{context.t("_all")}</option>
// 											<option value="competition">{context.t("_competitions")}</option>
// 											<option value="session">{context.t("_sessions")}</option>
// 											<option value="event">{context.t("_events")}</option>
// 											<option value="challenge">{context.t("_challenges")}</option>
// 											<option value="project">{context.t("_projects")}</option>
// 										</select>
// 									</div>
// 								</div>
// 							</div>
// 							<ul className="activities-list" aria-label="activities">
// 								{/* <InfiniteScroll
// 									dataLength={listActivity.length}
// 									next={handlePageChange}
// 									hasMore={true}
// 									// loader={<Loading />}
// 									style={{ overflow: 'visible', }}
// 								> */}
// 								{
// 									listActivity.map((row, index) => {
// 										return (


// 											<li className="newactivities test3" key={row.nid}>

// 												<div className="figHolderNA"><img src={row.field_activity_thumbnail} alt={`${row.title} image`} alt="" /></div>

// 												<div className="textHolderNA">
// 													<div className={`topTextHolderNA ${row.field_activity_zone}`}>
// 														<Link aria-label={`${row.title} ${context.t("_activity")} ${row.field_gender} ${row.field_age_group} ${context.t("_year_old")} ${context.t("_start_date")} ${getDateInFormat(row.field_start_and_end_time_3[0])} ${getTimeInFormat(row.field_start_and_end_time[0])} ${context.t("_and")} ${context.t("_end_date")} ${getDateInFormat(row.field_start_and_end_time_4[row.field_start_and_end_time_4.length - 1])} ${getTimeInFormat(row.field_start_and_end_time_1[row.field_start_and_end_time_1.length - 1])}`} ref={`activityListItemRef${index}`} to={`/activity/${row.nid}`}>
// 															<h4><i className="topHeadNA"></i>{row.title}</h4>
// 														</Link>
// 														<div className="topTextInsideNA">
// 															<span className="blackText"><i className="topBoyIconNA"></i> {getGender(row.field_gender)}</span>
// 															<span>{`${row.field_age_group} ${context.t("_year_old")}`}</span>
// 															{/* <span className="schoolSessionNA">{`${row.nothing.trim()} - ${row.field_activity_category}`}</span> */}
// 														</div>
// 													</div>

// 													<div className="bottomTextHolderNA">
// 														<div className="bottomTextLeftNA" dangerouslySetInnerHTML={{ __html: row.body }}></div>
// 														<div className="bottomTextRightNA">
// 															<span className="calendarNA">
// 																<i className="calendar-icons"></i> {context.t("_start_date")}  <span className="date">{getDateInFormat(row.field_start_and_end_time_3[0])} <br /><small>{getTimeInFormat(row.field_start_and_end_time[0])}</small></span>
// 															</span>
// 															<span className="calendarNA">
// 																<i className="calendar-icons"></i> {context.t("_end_date")} <span className="date">{getDateInFormat(row.field_start_and_end_time_4[row.field_start_and_end_time_4.length - 1])}<br /><small>{getTimeInFormat(row.field_start_and_end_time_1[row.field_start_and_end_time_1.length - 1])}</small></span>
// 															</span>
// 														</div>
// 													</div>
// 												</div>
// 												<div className="btnHolderNA">
// 													{
// 														(_.isEmpty(localStorage.getItem("role")) && row.field_need_volenteer != "0") ?
// 															<button className="btn" onClick={() => { handleRegisterVolunteerRegister() }}>{context.t("_volunteer_now")}</button> : null
// 													}
// 													{
// 														localStorage.getItem("role") == "volenteer" ?
// 															row.field_need_volenteer == "0" ?
// 																<button className="btn btn-disabled" onClick={() => { handleVolunteeredClick() }} >{context.t("_volunteer_now")}</button>
// 																:
// 																<button className="btn" onClick={() => { handleEnroll(row.nid, row.field_activity_category_1) }} >{context.t("_volunteer_now")}</button>
// 															: null
// 													}
// 													{
// 														(
// 															localStorage.getItem("role") == "trainer" ||
// 															localStorage.getItem("role") == "manager" ||
// 															localStorage.getItem("role") == "asistant" ||
// 															localStorage.getItem("role") == "edu_specialist" ||
// 															localStorage.getItem("role") == "administrator"
// 														) ? null :
// 															(
// 																row.field_need_to_show_enroll_button == "1" &&
// 																!_.isEmpty(row.field_start_and_end_time_3) &&
// 																!_.isEmpty(row.field_start_and_end_time_4)) ?
// 																(new Date(row.field_start_and_end_time_2) >= new Date()) ?
// 																	(localStorage.getItem("role") != "volenteer" ||
// 																		_.isEmpty(localStorage.getItem("role"))) &&
// 																	(!_.isEmpty(_.find(userActivityList, ["field_activity_id", row.nid])) ?
// 																		(_.find(userActivityList, ["field_activity_id", row.nid]).field_published_status == "Unpublished" ||
// 																			_.find(userActivityList, ["field_activity_id", row.nid]).field_published_status == "Published") ?
// 																			<button onClick={() => { handleEnrolledClick() }} className="btn blueColor btn-disabled">
// 																				{_.find(userActivityList, ["field_activity_id", row.nid]).field_published_status == "Published" ? context.t("_enrolled") : context.t("_pending")}
// 																			</button> :
// 																			handleEnrolButton(row)
// 																		:
// 																		handleEnrolButton(row)
// 																	)
// 																	: <button onClick={() => { handleEnrollClosed() }} className="btn blueColor btn-disabled">
// 																		{context.t("_closed")}
// 																	</button>
// 																: null

// 													}
// 												</div>
// 											</li>

// 										)
// 									})
// 								}
// 								{/* </InfiniteScroll> */}

// 							</ul>
// 							{
// 								isLoaded && listActivity.length == 0 && <div className="row"><p id="noResultFoundText" aria-label={context.t("_no_activities_found")} tabIndex="0" className="col s12 no-result">{context.t("_no_activities_found")}</p></div>
// 							}

// 							<nav className="pagination-wrapper" aria-label="pagination">
// 								{(listActivity.length > 0 && isLoaded) ? (
// 									<Pagination
// 										prevPageText={`< ${context.t("_previous")}`}
// 										nextPageText={`${context.t("_next")} >`}
// 										hideFirstLastPages={true}
// 										hideDisabled={true}
// 										linkClassPrev="linkClassPrev"
// 										linkClassNext="linkClassNext"
// 										activePage={pager.current_page + 1}
// 										itemsCountPerPage={pager.items_per_page}
// 										totalItemsCount={pager.count}
// 										pageRangeDisplayed={5}
// 										onChange={handlePageChange}
// 									/>) : ""
// 								}
// 							</nav>

// 						</div>
// 						<div className="sparkles">
// 							<span className="orange-circle"></span>
// 							<span className="multi-square two"><b><i></i></b></span>
// 							<span className="grey-square-rotate red-sq one"></span>
// 							<span className="grey-square-rotate red-sq two"></span>
// 						</div>
// 					</div>
// 					<div className="top-line ng-scope">
// 						<img src={topLineImage} alt="" />
// 					</div>
// 				</div>
// 			</div>
// 		)
// 	}
// }
// const mapStateToProps = ({ i18nState }) => {
// 	return {
// 		lang: i18nState.lang
// 	}
// }
// const mapDispatchToProps = {
// 	showPopup: (data) => PopupAction.showPopup(data),
// 	hidePopup: (data) => PopupAction.hidePopup(data),
// 	showLoader: LoadingAction.showLoader,
// 	hideLoader: LoadingAction.hideLoader,
// }

// export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesList);


const ActivitiesList = (props, context) => {
	const { config, networkRequest } = Main();

	const [isLoaded, setIsLoaded] = useState(false)
	const [configuration, setConfiguration] = useState(config())
	const [results, setResults] = useState([])
	const [userActivityList, setUserActivityList] = useState([])
	const [studentList, setStudentList] = useState([])
	const [pager, setPager] = useState({ count: 0, pages: 0, items_per_page: 0, current_page: 1 })
	const [search, setSearch] = useState({ _format: "json", type: "All", title: "", field_start_end_dates_value: "", page: 0 })
	const [modalContent, setModalContent] = useState({ type: "", title: "", message: "", isOpen: false, buttonText: "" })
	const [intervalId, setIntervalId] = useState(0)
	const [scrollStepInPx, setScrollStepInPx] = useState(50)
	const [delayInMs, setDelayInMs] = useState(16.66)
	const [searchActive, setSearchActive] = useState(false)
	const [searchClear, setSearchClear] = useState(false)
	const searchResult = useRef()
	const navigate = useNavigate()

	const { lang } = useSelector((state) => state.i18nState)

	const dispatch = useDispatch()

	const listActivity = search.type == "All" ? results : results.filter(data => data.field_event_type.toLowerCase() == search.type)

	useEffect(() => {
		(async () => {
			dispatch(LoadingAction.showLoader())
			document.title = `${context.t("_studio56")} | ${context.t("_activities")}`
			M.AutoInit();
			var options = {
				autoClose: true,
				format: "d mmmm, yyyy",
				onSelect: ((date) => {
					handleChange("field_start_end_dates_value", dateformat(date, "d mmmm, yyyy"))
					// handleChange("field_start_end_dates_value", dateformat(date, "yyyy-mm-dd"))
				}),
				showClearBtn: true,
				i18n: {
					cancel: context.t("_cancel"),
					done: context.t("_ok"),
					clear: context.t("_clear")
				},
			}
			setTimeout(() => {
				var elems = document.querySelectorAll("select");
				M.FormSelect.init(elems, options);
				var clearBtn = document.querySelectorAll(".datepicker-clear");
				clearBtn.forEach(data => {
					data.setAttribute("aria-label", context.t("_clear"));
				});
			}, 1000);
			if (localStorage.getItem("role") == "parent")
				await getChildren();
			if (localStorage.getItem("role") == "student")
				await checkStudentEnrolled();
			if (localStorage.getItem("role") == "volenteer")
				await checkVolunteerEnrolled();
			await getActivity();
			window.addEventListener('load', handleTabIndex());
		})();
	}, [])

	useEffect(() => {
		if (searchClear) {
			setSearchClear(false)
			getActivity()
		}
	}, [searchClear])


	const handleTabIndex = () => {
		setTimeout(() => {
			let elem = document.querySelectorAll('select');
			elem.forEach(element => {
				element.setAttribute("tabindex", "0");
			});
		}, 1000);
	}
	const getChildren = async () => {
		let url = `${configuration.api_url}${urlEndPoints.myChildren}`
		try {
			const data = await networkRequest(url)
			if (!data.data.message) {
				setStudentList(data.data.results)
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}
	const getActivity = async (isPagination = false) => {
		let url = "";
		if (lang == "ar")
			url = `${configuration.origin}${lang}/api/${urlEndPoints.getActivitiesList}`
		else
			url = `${configuration.origin}api/${urlEndPoints.getActivitiesList}`
		// title: "", field_start_end_dates_value: "",
		const body = {
			lang
		}
		if (!_.isEmpty(search.type) && search.type != "All")
			body.field_event_type_value = search.type
		if (!_.isEmpty(search.title))
			body.title = search.title
		if (!_.isEmpty(search.category) && search.category != "All")
			body.field_activity_category_target_id = search.category
		if (search.field_start_end_dates_value)
			body.field_start_and_end_time_value = dateformat(search.field_start_end_dates_value, "yyyy-mm-dd")
		if (search.page)
			body.page = search.page

		try {
			const data = await networkRequest(url, "POST", body)
			if (data.status == 200) {
				// scrollToTop()
				// dispatch(LoadingAction.hideLoader())
				const results = data.data.results;
				results.forEach(async item => {
					item.field_start_and_end_time_2 = item.field_start_and_end_time_2.split("*")
					item.field_start_and_end_time_2 = item.field_start_and_end_time_2.map(date => new Date(date))
					item.field_start_and_end_time_2 = new Date(Math.min.apply(null, item.field_start_and_end_time_2));
					item.field_start_and_end_time_3 = item.field_start_and_end_time_3.split("*")
					item.field_start_and_end_time_4 = item.field_start_and_end_time_4.split("*")
					item.field_start_and_end_time = item.field_start_and_end_time.split("*")
					item.field_start_and_end_time_1 = item.field_start_and_end_time_1.split("*")
				});

				setPager(data.data.pager)
				setEnrolCount(results, isPagination)
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}
	const setEnrolCount = async (activities, isPagination) => {
		// for (const activity of activities) {
		// 	let url = `${configuration.api_url}${urlEndPoints.getEnrolmentCount(activity.nid)}`
		// 	try {
		// 		const data = await networkRequest(url)
		// 		activity.enrolmentCount = !_.isEmpty(data.data) ? data.data.length : 0
		// 	} catch (error) {
		// 		dispatch(LoadingAction.hideLoader())
		// 		console.warn("error: ", error)
		// 	}
		// }
		// setState({
		// 	// results: search.page == 0 ? activities : [...results, ...activities],
		// 	results: activities,
		// 	isLoaded: true
		// })
		setResults(activities)
		setIsLoaded(true)
		setTimeout(() => {
			if (searchActive) {
				if (activities.length == 0)
					document.getElementById("noResultFoundText") && document.getElementById("noResultFoundText").focus()
				// else
				// 	searchResult && searchResult.focus()
			} else if (isPagination && activities.length > 0) {
				// refs.activityListItemRef0.focus()
			} else {
				document.getElementById("pageHeading") && document.getElementById("pageHeading").focus()
			}
		}, 500);

		dispatch(LoadingAction.hideLoader())
	}
	const checkStudentEnrolled = async () => {
		let url = `${configuration.api_url}${urlEndPoints.getUserActivityById}`
		try {
			const data = await networkRequest(url)
			setUserActivityList(data.data)
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}
	const checkVolunteerEnrolled = async () => {
		let url = `${configuration.api_url}${urlEndPoints.get_volunteer_activities}`
		try {
			const data = await networkRequest(url)
			setUserActivityList(data.data)
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}
	// handlePageChange() {
	// 	var { search, pager } = state;
	// 	if (pager.current_page + 1 < pager.pages) {
	// 		props.showLoader()
	// 		search.page = search.page + 1;
	// 		setState({ search: search }, () => {
	// 			getActivity(true);
	// 		});
	// 	}
	// }
	function handlePageChange(pageNumber) {
		dispatch(LoadingAction.showLoader())
		search.page = pageNumber - 1;
		setSearch(search)
		getActivity(true);
	}
	const handleChange = (field, value) => {
		search[field] = value;
		setSearch({ ...search })
		// if (field == "type") {
		// 	dispatch(LoadingAction.showLoader())
		// 	getActivity();
		// }
	}
	const onSubmit = async (event) => {
		dispatch(LoadingAction.showLoader())
		event.preventDefault();
		getActivity(true);
		setSearchActive(true)
	}
	const handleEnroll = async (id, category) => {
		const role = await localStorage.getItem("role");
		switch (role) {
			case "student":
				if (category == "77") {
					dispatch(LoadingAction.showLoader())
					handleEnrollStudent(id)
				} else {
					dispatch(PopupAction.showPopup({
						type: "error",
						title: context.t("_error"),
						message: context.t("_school_activity_error"),
						isOpen: true,
						buttonText: context.t("_close")
					}))
				}
				break;
			case "volenteer":
				dispatch(LoadingAction.showLoader())
				handleEnrollVolunteer(id)
				break;
			case "school":
				if (category == "78") {
					dispatch(LoadingAction.showLoader())
					handleEnrollParent(id);
				} else {
					dispatch(PopupAction.showPopup({
						type: "error",
						title: context.t("_error"),
						message: context.t("_public_activity_error"),
						isOpen: true,
						buttonText: context.t("_close")
					}))
				}
				break;
			case "parent":
				if (category == "77") {
					dispatch(LoadingAction.showLoader())
					handleEnrollParent(id)
				} else {
					dispatch(PopupAction.showPopup({
						type: "error",
						title: context.t("_error"),
						message: context.t("_school_activity_error"),
						isOpen: true,
						buttonText: context.t("_close")
					}))
				}
				break;

			default:
				dispatch(PopupAction.showPopup({
					type: "error",
					title: "",
					// message: context.t("_login_required"),
					message: <>
						<Link aria-label={context.t("_login_link")} onClick={() => { dispatch(PopupAction.hidePopup({})) }} to="/login"><b>{context.t("_login_link")}</b></Link> {context.t("_is_required_for_enrolment")}
					</>,
					isOpen: true,
					buttonText: context.t("_ok")
				}))
				break;
		}
	}
	const handleEnrollStudent = async (id) => {
		const url = `${configuration.api_url}${urlEndPoints.activityEnrolment}`
		const obj = {
			"activity_id": id
		}
		try {
			const data = await networkRequest(url, "POST", obj)
			dispatch(LoadingAction.hideLoader())
			if (data.data.error) {
				displayError(data.data.error)
			} else {
				await checkStudentEnrolled();
				getActivity()
				dispatch(PopupAction.showPopup({
					type: "success",
					title: context.t("_success"),
					message: context.t("_enroll_success"),
					isOpen: true,
					buttonText: context.t("_ok")
				}))
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
		// }

	}
	const handleEnrollVolunteer = async (id) => {
		const url = `${configuration.api_url}${urlEndPoints.volunteerEnrolment}`
		const obj = {
			"activity_id": id
		}
		try {
			const data = await networkRequest(url, "POST", obj)
			dispatch(LoadingAction.hideLoader())
			if (data.data.error) {
				displayError(data.data.error)
			} else {
				await checkVolunteerEnrolled();
				getActivity()
				dispatch(PopupAction.showPopup({
					type: "success",
					title: context.t("_success"),
					message: context.t("_request_send"),
					isOpen: true,
					buttonText: context.t("_ok")
				}))
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
		// }

	}
	const handleEnrollParent = (id) => {
		if (localStorage.getItem("role") == "parent" && studentList.length == 1) {
			checkStudentEnrolled1(studentList[0].uid, id);
		} else {
			navigate(`/subscribe-activities/${id}`);
		}

	}
	const checkStudentEnrolled1 = async (userId, activityId) => {
		let url = `${configuration.api_url}${urlEndPoints.getActivityById(userId)}`
		try {
			const data = await networkRequest(url)
			if (_.has(data, "data") && data.data.length > 0) {
				const fieldActivity = _.find(data.data, ["field_activity_id", activityId]);
				if (!_.isEmpty(fieldActivity)) {

					dispatch(LoadingAction.hideLoader())
					return dispatch(PopupAction.showPopup({
						type: fieldActivity.field_published_status == "Waiting List" ? "success" : "error",
						title: context.t(fieldActivity.field_published_status == "Waiting List" ? "_success" : "_error"),
						message: context.t(fieldActivity.field_published_status == "Waiting List" ? "already_waiting_list" : "_already_enrolled"),
						isOpen: true,
						buttonText: context.t("_ok")
					}))
				}
				else
					handleInitialEnroll(activityId);
			} else {
				handleInitialEnroll(activityId);
			}
			dispatch(LoadingAction.hideLoader())
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}
	const handleInitialEnroll = async (id) => {
		if (studentList && studentList[0] && studentList[0].uid) {
			const checkedList = {
				0: studentList[0].uid
			}
			dispatch(LoadingAction.showLoader())
			let url = `${configuration.api_url}${urlEndPoints.activityEnrolment}`
			let obj = {
				"activity_id": id,
				user_ids: checkedList
			}
			try {
				const data = await networkRequest(url, "POST", obj)
				if (data.data.error) {
					dispatch(PopupAction.showPopup({
						type: "error",
						title: context.t("_error"),
						message: `${data.data.error.name[lang]}`,
						isOpen: true,
						buttonText: context.t("_ok")
					}))
				} else {
					let message = context.t("_enroll_success");
					if (localStorage.getItem("role") == "school") {
						message = `${message}. ${context.t("_admin_contact_you")}`
					}
					dispatch(PopupAction.showPopup({
						type: "success",
						title: context.t("_success"),
						message,
						isOpen: true,
						buttonText: context.t("_ok")
					}))
				}
				getActivity();
			} catch (error) {
				dispatch(LoadingAction.hideLoader())
				console.warn("error: ", error)
			}
			// } else {
			//     props.hideLoader();
			// }

		}
	}
	const displayError = error => {
		const { lang } = props;
		if (error.name) {
			dispatch(PopupAction.showPopup({
				type: error.type == "waiting_list" ? "success" : "error",
				title: error.type == "waiting_list" ? context.t("_success") : context.t("_error"),
				message: error.name[lang],
				isOpen: true,
				buttonText: context.t("_ok")
			}))
		}
	}
	const clearSearch = async () => {
		dispatch(LoadingAction.showLoader())
		setTimeout(() => {
			let elems = document.querySelector("select");
			M.FormSelect.init(elems)
		}, 1000);
		setSearch({ _format: "json", type: "All", title: "", category: "All", field_start_end_dates_value: "", page: 0 })
		setSearchActive(false)
		setSearchClear(true)
	}


	const handleDetailRedirect = id => {
		props.history.push(`/activity/${id}`);
	}
	function scrollStep() {
		var scrollStepInPx = props.scrollStepInPx ? props.scrollStepInPx : scrollStepInPx;
		if (window.pageYOffset === 0) {
			clearInterval(intervalId);
		}
		window.scroll(0, window.pageYOffset - scrollStepInPx);
	}
	function scrollToTop() {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
	}
	const handleEnrolledClick = () => {
		dispatch(PopupAction.showPopup({
			type: "error",
			title: context.t("_warning"),
			message: context.t("_already_enrolled"),
			isOpen: true,
			buttonText: context.t("_ok")
		}))
	}
	const handleVolunteeredClick = () => {
		dispatch(PopupAction.showPopup({
			type: "error",
			title: context.t("_warning"),
			message: context.t("_volunteer_not_needed"),
			isOpen: true,
			buttonText: context.t("_ok")
		}))
	}
	const handleRegisterVolunteerRegister = () => {
		dispatch(PopupAction.showPopup({
			type: "error",
			title: "",
			// message: context.t("_login_required"),
			message: <>
				<Link aria-label={context.t("_login_link")} onClick={() => { dispatch(PopupAction.hidePopup({})) }} to="/login"><b>{context.t("_login_link")}</b></Link> {context.t("_is_required_for_volunteer")}
			</>,
			isOpen: true,
			buttonText: context.t("_ok")
		}))
		//dispatch(PopupAction.showPopup({
		// 	type: "error",
		// 	title: context.t("_warning"),
		// 	message: context.t("_volunteer_not_registered"),
		// 	isOpen: true,
		// 	buttonText: context.t("_ok")
		// }))
		// props.history.push("/registration/volunteer")
	}
	const getGender = gender => {
		gender = gender.toLowerCase()
		switch (gender) {
			case "male":
				return context.t("_boys")
			case "female":
				return context.t("_girls")
			case "mixed":
				return context.t("_mixed")

		}
	}
	const getDateInFormat = date => {
		// if (!_.isEmpty(date)) {
		if (date.includes("Jan"))
			date = date.replace("Jan", context.t("_jan"))

		if (date.includes("Feb"))
			date = date.replace("Feb", context.t("_feb"))

		if (date.includes("Mar"))
			date = date.replace("Mar", context.t("_mar"))

		if (date.includes("Apr"))
			date = date.replace("Apr", context.t("_apr"))

		if (date.includes("May"))
			date = date.replace("May", context.t("_may"))

		if (date.includes("Jun"))
			date = date.replace("Jun", context.t("_jun"))

		if (date.includes("Jul"))
			date = date.replace("Jul", context.t("_jul"))

		if (date.includes("Aug"))
			date = date.replace("Aug", context.t("_aug"))

		if (date.includes("Sep"))
			date = date.replace("Sep", context.t("_sep"))

		if (date.includes("Oct"))
			date = date.replace("Oct", context.t("_oct"))

		if (date.includes("Nov"))
			date = date.replace("Nov", context.t("_nov"))

		if (date.includes("Dec"))
			date = date.replace("Dec", context.t("_dec"))
		// }

		date = date.replace(",", context.t("_coma"))
		return date
	}

	const getTimeInFormat = time => {
		// if (!_.isEmpty(time)) {
		if (time.includes("am"))
			time = time.replace("am", context.t("_am"))

		if (time.includes("pm"))
			time = time.replace("pm", context.t("_pm"))
		// }
		return time;
	}
	const handleEnrolButton = item => {
		if (Number(item.field_number_of_attendees) > Number(item.enrollment_count))
			return <button onClick={() => { handleEnroll(item.nid, item.field_activity_category_1) }} className="btn blueColor ">
				{context.t("_enroll")}
			</button>
		else if (Number(item.enrollment_count) < (Number(item.field_number_of_attendees) + Number(item.field_waiting_list_count)))
			return <button onClick={() => { handleEnroll(item.nid, item.field_activity_category_1) }} className="btn blueColor ">
				{context.t("_waiting_list")}
			</button>
		else
			return <button onClick={() => { handleEnrollClosed() }} className="btn blueColor btn-disabled">
				{context.t("_closed")}
			</button>
	}
	const handleEnrollClosed = () => {
		dispatch(PopupAction.showPopup({
			type: "error",
			title: context.t("_warning"),
			message: context.t("_enrolment_closed"),
			isOpen: true,
			buttonText: context.t("_ok")
		}))
	}



	return (
		<div id="main-content" className="activiti-list">
			<SlideToTop />
			<PageTitle title={context.t("_activities")} isDashboardItem={true} />
			<div className="container">
				<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
					<ul>
						<li className="breadcrumb-item">
							<Link to="/">{context.t("_home")}</Link>{" "}
						</li>
						<li className="breadcrumb-item">
							<Link tabIndex="0" to aria-current={context.t("_page")}>
								<span>{context.t("_activities")}</span>
							</Link>
						</li>
					</ul>
				</nav>
			</div>
			<span className="multi-square one"><b><i></i></b></span>
			<div id="skipContent">
				<div id="main-container">
					<div className="container">
						<form onSubmit={onSubmit} method="post" name="search" className="search">
							<div className="row topFormHolder">
								<div className="col s12 m6 l3">
									<div className="input-field item">
										<input name="Title"
											placeholder={context.t("_title")}
											onChange={evt => handleChange("title", evt.target.value)}
											onFocus={evt => handleChange("title", evt.target.value)}
											value={search.title}
											id="title"
											type="text"
											aria-label="Activity search title"
										/>
										<label style={dynamicStyles.position(lang)} htmlFor="title">{context.t("_title")}</label>
										<span className="helper-text" ></span>
									</div>
								</div>
								<div className="col s12 m6 l3 date-popup">
									<div className={`input-field mobileSelect item ${lang}`}>
										<label htmlFor="activityType" className="active" style={dynamicStyles.position(lang)}>{context.t("_activity_type")}</label>
										<select
											className="browser-default" id="activityType" tabIndex="0" value={search.category} onChange={evt => handleChange("category", evt.target.value)}>
											<option value="" disabled >{context.t("_select_activity_type")}</option>
											<option value="All" >{context.t("_all")}</option>
											<option value="77">{context.t("_public_activity")}</option>
											<option value="78">{context.t("_school_activity")}</option>
										</select>
										<span className="helper-text"></span>
									</div>
								</div>
								<div className="col s12 m6 l3 date-popup">

									<div className="input-field item">
										<DatePicker
											name="date"
											placeholderText={context.t("_date")}
											id="date"
											autoComplete="off"
											dateFormat="yyyy-MM-dd"
											locale={calenderLocal(context)}
											customInput={<CustomDateInput ariaLabel={context.t("_select_date_button")} onFieldChange={date => handleChange("field_start_end_dates_value", new Date(date))} />}
											ariaLabelledBy="select date"
											onChange={date =>
												handleChange("field_start_end_dates_value", date)
											}
											onCalendarOpen={e => {
												let elems = document.querySelector(".react-datepicker");
												elems.setAttribute("tabindex", "0")
												elems && elems.focus()
												elems = document.querySelector(".react-datepicker__month-select");
												elems.setAttribute("aria-label", context.t("_select_month"))
												elems = document.querySelector(".react-datepicker__year-select");
												elems.setAttribute("aria-label", context.t("_select_year"))

											}}
											onCalendarClose={e => {
												var elems = document.querySelector(".hiddenDob");
												elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(search.field_start_end_dates_value, "dd-mm-yyyy")}`)
												elems.setAttribute("value", dateformat(search.field_start_end_dates_value, "yyyy-mm-dd"))
												setTimeout(() => {
													elems && elems.focus()
												}, 200);
											}}
											style={dynamicStyles.textAlign(lang)}
											value={search.field_start_end_dates_value}
											selected={search.field_start_end_dates_value}
											peekNextMonth
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
										/>
										<label style={dynamicStyles.position(lang)} htmlFor="date">{context.t("_date")}</label>
										<span className="helper-text" ></span>
									</div>
								</div>
								<div className="col s12 m6 l3 valign-wrapper center-align search-btn-holder">
									<button type="submit" className="btn noWidth profileBtn yellow waves-effect waves-light" aria-label="Activity search">{context.t("_search")} <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i></button>
									<button type="button" aria-label="Activity search clear" onClick={() => clearSearch()} className="btn noWidth blue-btn waves-effect waves-light">
										{context.t("_clear")}
									</button>
								</div>
							</div>
						</form>
						<div className="row">
							<div className="col s7 result-status">
								{isLoaded && searchActive ? <h2 tabIndex="0" ref={searchResult} className="search-result">{context.t("_search_result") + " ( " + (listActivity.length) + ` ${context.t("_of")} ` + pager.count + " )"}</h2> : ""}

							</div>
							<div className="col s5 result-type">
								<div className={`input-field col s12 mobileSelect filter-select ${lang}`}>
									<label htmlFor="result-type">{context.t("_select_activity")}</label>
									<select id="result-type" className="browser-default" value={search.type} onChange={evt => handleChange("type", evt.target.value)}>
										<option value="All" >{context.t("_all")}</option>
										<option value="competition">{context.t("_competitions")}</option>
										<option value="session">{context.t("_sessions")}</option>
										<option value="event">{context.t("_events")}</option>
										<option value="challenge">{context.t("_challenges")}</option>
										<option value="project">{context.t("_projects")}</option>
									</select>
								</div>
							</div>
						</div>
						<ul className="activities-list" aria-label="activities">
							{/* <InfiniteScroll
								dataLength={listActivity.length}
								next={handlePageChange}
								hasMore={true}
								// loader={<Loading />}
								style={{ overflow: 'visible', }}
							> */}
							{
								listActivity.map((row, index) => {
									return (


										<li className="newactivities test3" key={row.nid}>

											<div className="figHolderNA"><img src={row.field_activity_thumbnail} alt={`${row.title} image`} /></div>

											<div className="textHolderNA">
												<div className={`topTextHolderNA ${row.field_activity_zone}`}>
													<Link aria-label={`${row.title} ${context.t("_activity")} ${row.field_gender} ${row.field_age_group} ${context.t("_year_old")} ${context.t("_start_date")} ${getDateInFormat(row.field_start_and_end_time_3[0])} ${getTimeInFormat(row.field_start_and_end_time[0])} ${context.t("_and")} ${context.t("_end_date")} ${getDateInFormat(row.field_start_and_end_time_4[row.field_start_and_end_time_4.length - 1])} ${getTimeInFormat(row.field_start_and_end_time_1[row.field_start_and_end_time_1.length - 1])}`}
														// ref={`activityListItemRef${index}`} 
														to={`/activity/${row.nid}`}>
														<h4><i className="topHeadNA"></i>{row.title}</h4>
													</Link>
													<div className="topTextInsideNA">
														<span className="blackText"><i className="topBoyIconNA"></i> {getGender(row.field_gender)}</span>
														<span>{`${row.field_age_group} ${context.t("_year_old")}`}</span>
														{/* <span className="schoolSessionNA">{`${row.nothing.trim()} - ${row.field_activity_category}`}</span> */}
													</div>
												</div>

												<div className="bottomTextHolderNA">
													<div className="bottomTextLeftNA" dangerouslySetInnerHTML={{ __html: row.body }}></div>
													<div className="bottomTextRightNA">
														<span className="calendarNA">
															<i className="calendar-icons"></i> {context.t("_start_date")}  <span className="date">{getDateInFormat(row.field_start_and_end_time_3[0])} <br /><small>{getTimeInFormat(row.field_start_and_end_time[0])}</small></span>
														</span>
														<span className="calendarNA">
															<i className="calendar-icons"></i> {context.t("_end_date")} <span className="date">{getDateInFormat(row.field_start_and_end_time_4[row.field_start_and_end_time_4.length - 1])}<br /><small>{getTimeInFormat(row.field_start_and_end_time_1[row.field_start_and_end_time_1.length - 1])}</small></span>
														</span>
													</div>
												</div>
											</div>
											<div className="btnHolderNA">
												{
													(_.isEmpty(localStorage.getItem("role")) && row.field_need_volenteer != "0") ?
														<button className="btn" onClick={() => { handleRegisterVolunteerRegister() }}>{context.t("_volunteer_now")}</button> : null
												}
												{
													localStorage.getItem("role") == "volenteer" ?
														row.field_need_volenteer == "0" ?
															<button className="btn btn-disabled" onClick={() => { handleVolunteeredClick() }} >{context.t("_volunteer_now")}</button>
															:
															<button className="btn" onClick={() => { handleEnroll(row.nid, row.field_activity_category_1) }} >{context.t("_volunteer_now")}</button>
														: null
												}
												{
													(
														localStorage.getItem("role") == "trainer" ||
														localStorage.getItem("role") == "manager" ||
														localStorage.getItem("role") == "asistant" ||
														localStorage.getItem("role") == "edu_specialist" ||
														localStorage.getItem("role") == "administrator"
													) ? null :
														(
															row.field_need_to_show_enroll_button == "1" &&
															!_.isEmpty(row.field_start_and_end_time_3) &&
															!_.isEmpty(row.field_start_and_end_time_4)) ?
															(new Date(row.field_start_and_end_time_2) >= new Date()) ?
																(localStorage.getItem("role") != "volenteer" ||
																	_.isEmpty(localStorage.getItem("role"))) &&
																(!_.isEmpty(_.find(userActivityList, ["field_activity_id", row.nid])) ?
																	(_.find(userActivityList, ["field_activity_id", row.nid]).field_published_status == "Unpublished" ||
																		_.find(userActivityList, ["field_activity_id", row.nid]).field_published_status == "Published") ?
																		<button onClick={() => { handleEnrolledClick() }} className="btn blueColor btn-disabled">
																			{_.find(userActivityList, ["field_activity_id", row.nid]).field_published_status == "Published" ? context.t("_enrolled") : context.t("_pending")}
																		</button> :
																		handleEnrolButton(row)
																	:
																	handleEnrolButton(row)
																)
																: <button onClick={() => { handleEnrollClosed() }} className="btn blueColor btn-disabled">
																	{context.t("_closed")}
																</button>
															: null

												}
											</div>
										</li>

									)
								})
							}
							{/* </InfiniteScroll> */}

						</ul>
						{
							isLoaded && listActivity.length == 0 && <div className="row"><p id="noResultFoundText" aria-label={context.t("_no_activities_found")} tabIndex="0" className="col s12 no-result">{context.t("_no_activities_found")}</p></div>
						}

						<nav className="pagination-wrapper" aria-label="pagination">
							{(listActivity.length > 0 && isLoaded) ? (
								<Pagination
									prevPageText={`< ${context.t("_previous")}`}
									nextPageText={`${context.t("_next")} >`}
									hideFirstLastPages={true}
									hideDisabled={true}
									linkClassPrev="linkClassPrev"
									linkClassNext="linkClassNext"
									activePage={pager.current_page + 1}
									itemsCountPerPage={pager.items_per_page}
									totalItemsCount={pager.count}
									pageRangeDisplayed={5}
									onChange={handlePageChange}
								/>) : ""
							}
						</nav>

					</div>
					<div className="sparkles">
						<span className="orange-circle"></span>
						<span className="multi-square two"><b><i></i></b></span>
						<span className="grey-square-rotate red-sq one"></span>
						<span className="grey-square-rotate red-sq two"></span>
					</div>
				</div>
				<div className="top-line ng-scope">
					<img src={topLineImage} alt="" />
				</div>
			</div>
		</div>
	)
}

ActivitiesList.contextTypes = {
	t: PropTypes.func
}

export default ActivitiesList