import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import SlideToTop from "./includes/slide_to_top.js"
class Notfound extends Component {
	componentDidMount() {
		document.title = `${this.context.t("_studio56")} | ${this.context.t("_page_not_found")}`
	}
	componentDidUpdate() {
		document.title = `${this.context.t("_studio56")} | ${this.context.t("_page_not_found")}`
	}
	render() {
		return (<div id="main-content" className="page-not-found center">
			<SlideToTop />
			<h2>{this.context.t("_page_not_found")}</h2>
		</div>)
	}
}
const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
}
Notfound.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Notfound);
