import React, { useState, useEffect } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux"

import Main from "../../constants/utilities"
import PageTitle from "../includes/page_title";
import { LoadingAction } from "./../../actions";
import SlideToTop from "../includes/slide_to_top";
import logo from "./../../assets/images/logo5.png";
import { urlEndPoints } from "./../../httpAPI/apiConfig"

const EquipmentUsed = (props, context) => {
    const { config, networkRequest } = Main();
    const configure = config()
    const [isLoaded, setIsLoaded] = useState(false)
    const [equipmentUsedList, setEquipmentUsedList] = useState([])
    const [pager, setPager] = useState({ count: 0, pages: 0, items_per_page: 0, current_page: 1 })

    const { lang } = useSelector(
        (state) => state.i18nState
    );
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(LoadingAction.showLoader())
        document.title = `${context.t("_studio56")} | ${context.t("_equipment")}`;
        getEquipmentUsed();
    }, []);

    const getEquipmentUsed = async () => {
        const url = `${configure.api_url}${urlEndPoints.getEquipmentUsed}?page=${pager.pages}`
        try {
            const data = await networkRequest(url)
            if (data.data.error) {

            } else {
                setEquipmentUsedList(data.data.results)
                setPager(data.data.pager);
                setIsLoaded(true);
                setTimeout(() => {
                    document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
                }, 500);
            }
            dispatch(LoadingAction.hideLoader())
        } catch (error) {
            setIsLoaded(true);
            console.warn("error: ", error)
            dispatch(LoadingAction.hideLoader())
        }
    }
    const handlePageChange = (pageNumber) => {
        dispatch(LoadingAction.showLoader())
        pager.pages = pageNumber - 1;
        setPager(pager)
        getEquipmentUsed(true);
    }
    return (
        <div id="skipContent">
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={context.t("_equipment")} isDashboardItem={true} />
                <div className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/">{context.t("_home")}</Link> {" "}
                            </li>
                            <li className="breadcrumb-item">
                                <Link tabIndex="0" to aria-current={context.t("_page")}>
                                    <span>{context.t("_equipments_used")}</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div className="container">

                    <h2 class="line">{context.t("_equipment")}</h2>

                    <div className="row machine-item-small-holder">
                        {isLoaded && equipmentUsedList.length == 0 ? <div className="row"><p aria-label={context.t("_no_result_found")} tabIndex="0" className="col s12 no-result">{context.t("_no_result_found")}</p></div> :
                            equipmentUsedList.map(item => {
                                return (<div className="col m12 l6" key={item.nid}>
                                    <div className="machine-item small">
                                        <figure className="machine-item-figure small">
                                            <img src={!_.isEmpty(item.field_equipment_image) ? `${configure.origin}${item.field_equipment_image}` : logo} alt="Coding 101 image" />
                                        </figure>
                                        <aside className="machine-item-content">
                                            <h5>{item.title} {!_.isEmpty(item.field_equipment_type) && <span>{item.field_equipment_type}</span>} </h5>
                                            {!_.isEmpty(item.field_sub_title) && <h4>{item.field_sub_title}</h4>}

                                            {
                                                item.field_is_there_an_update_model_ == 1 && <div className="machine-small-btn">
                                                    <a target="_black" href={item.field_updated_model_website}>
                                                        <span>{context.t("_updated_model")}</span>
                                                        <em>{item.field_updated_model}</em>
                                                    </a>
                                                </div>
                                            }

                                        </aside>
                                    </div>
                                </div>)
                            })
                        }



                    </div>
                    <nav className="pagination-wrapper" aria-label="pagination">
                        <Pagination
                            prevPageText={`< ${context.t("_previous")}`}
                            nextPageText={`${context.t("_next")} >`}
                            hideFirstLastPages={true}
                            hideDisabled={true}
                            linkClassPrev="linkClassPrev"
                            linkClassNext="linkClassNext"
                            activePage={pager.current_page + 1}
                            itemsCountPerPage={pager.items_per_page}
                            totalItemsCount={pager.count}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                    </nav>
                </div>

            </div>
        </div>
    );
}

EquipmentUsed.contextTypes = {
    t: PropTypes.func
}
export default EquipmentUsed;
