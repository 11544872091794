import React, { useState, useEffect, useRef } from "react"
import M from "materialize-css";
import PropTypes from "prop-types"
import { Link } from "react-router-dom";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useDispatch, useSelector } from "react-redux"

import Main from "../../constants/utilities";
import PageTitle from "../includes/page_title";
import { LoadingAction } from "./../../actions";
import SlideToTop from "../includes/slide_to_top";
import { urlEndPoints } from "./../../httpAPI/apiConfig";
import interactionPlugin from "@fullcalendar/interaction";

const Calendar = (props, context) => {
    const { config, networkRequest } = Main();
    const configure = config()
    const dispatch = useDispatch()
    const calendarComponentRef = useRef(null);
    const [faqList, setFaqList] = useState({})
    const [calendarWeekends, setCalendarWeekends] = useState(true)
    const [calendarEvents, setCalendarEvents] = useState([
        // initial event data
        { title: "Event Now", start: new Date() },
        { title: "Two Event Now", start: new Date("2020/03/26"), end: new Date("2020/03/28") }
    ])

    useEffect(() => {
        M.AutoInit();
    }, [])

    const { lang } = useSelector(
        (state) => state.i18nState
    );

    const getFaqList = async () => {
        const url = `${configure.api_url}${urlEndPoints.getFaqs}`
        try {
            const data = await networkRequest(url)
            if (data.data.error) {

            } else {
                setFaqList(data.data.results)
            }
            dispatch(LoadingAction.hideLoader())
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
    const toggleWeekends = () => {
        setCalendarWeekends(!calendarWeekends)
    };
    const gotoPast = () => {
        let calendarApi = calendarComponentRef.current.getApi();
        calendarApi.gotoDate("2000-01-01"); // call a method on the Calendar object
    };
    const handleDateClick = arg => {
        // if (confirm("Would you like to add an event to " + arg.dateStr + " ?")) {
        // this.setState({
        //     // add new event data
        //     calendarEvents: this.state.calendarEvents.concat({
        //         // creates a new array
        //         title: "New Event",
        //         start: arg.date,
        //         allDay: arg.allDay
        //     })
        // });
        // }
    };

    return (
        <div id="main-container">
            <SlideToTop />
            <PageTitle title={"Calendar"} />
            <div id="breadcrumb-wrap">
                <div className="container">
                </div>
            </div>
            <div className="container">
                <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                    <ul>
                        <li className="breadcrumb-item">
                            <Link to="/">{`Home`}</Link> {" "}
                        </li>
                        <li className="breadcrumb-item">
                            <span> Calendar </span>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="container">
                <FullCalendar
                    defaultView="dayGridMonth"
                    header={{
                        left: "prev",
                        center: "title",
                        right: "next"
                    }}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    ref={calendarComponentRef}
                    weekends={calendarWeekends}
                    events={calendarEvents}
                    dateClick={handleDateClick}
                />
            </div>
        </div>
    );
}

Calendar.contextTypes = {
    t: PropTypes.func
}
export default Calendar;
