import Main from "../../components/main"
import _ from "lodash"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { GET_CREATIVE_ZONE, GET_SOFTWARE_ZONE, GET_TECHNOLOGY_ZONE } from "./../../constants/constants"

const MainConst = new Main();
const configConst = MainConst.config();
const getZoneCreative = ({ startDate = "", endDate = "" }) => {
    return async dispatch => {
        let url = `${configConst.origin}${urlEndPoints.getDashboardZoneCreative}?`
        if (!_.isEmpty(startDate))
            url = `${url}&field_start_and_end_time_value=${startDate} 00:00:00`
        if (!_.isEmpty(endDate))
            url = `${url}&field_start_and_end_time_end_value_1=${endDate} 00:00:00`
        MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_CREATIVE_ZONE,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_CREATIVE_ZONE,
                    payload: data.data.length
                })
            }
        })
    }
}
const getZoneSoftware = ({ startDate = "", endDate = "" }) => {
    return async dispatch => {
        let url = `${configConst.origin}${urlEndPoints.getDashboardZoneSoftware}?`
        if (!_.isEmpty(startDate))
            url = `${url}&field_start_and_end_time_value=${startDate} 00:00:00`
        if (!_.isEmpty(endDate))
            url = `${url}&field_start_and_end_time_end_value_1=${endDate} 00:00:00`
        MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_SOFTWARE_ZONE,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_SOFTWARE_ZONE,
                    payload: data.data.length
                })
            }
        })
    }
}
const getZoneTechnology = ({ startDate = "", endDate = "" }) => {
    return async dispatch => {
        let url = `${configConst.origin}${urlEndPoints.getDashboardZoneTechnology}?`
        if (!_.isEmpty(startDate))
            url = `${url}&field_start_and_end_time_value=${startDate} 00:00:00`
        if (!_.isEmpty(endDate))
            url = `${url}&field_start_and_end_time_end_value_1=${endDate} 00:00:00`
        MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_TECHNOLOGY_ZONE,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_TECHNOLOGY_ZONE,
                    payload: data.data.length
                })
            }
        })
    }
}

export default {
    getZoneCreative,
    getZoneSoftware,
    getZoneTechnology
}