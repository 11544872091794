import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux";
import PropTypes from "prop-types"
import Main from "../../constants/utilities";

const axios = require("axios");

const About = (props, context) => {
	const { config } = Main()
	const [configuration, setConfiguration] = useState(config())
	const [about, setAbout] = useState([])

	const { lang } = useSelector((state) => state.i18nState)

	useEffect(() => {
		get_about()
	}, [])

	const get_about = () => {
		var headers = {};
		let url = "";
		if (lang == "ar")
			url = `${configuration.origin}${lang}/api/about`
		else
			url = `${configuration.origin}api/about`
		if (configuration.environment === "production") {
			headers = {
				"Access-Control-Allow-Credentials": true,
				"Access-Control-Allow-Headers": "Content-Type",
				"Access-Control-Allow-Methods": "GET, POST",
				"Access-Control-Allow-Origin": configuration.origin
			};
		}
		axios.get(url, {
			params: {
				_format: "json"
			},
			headers: headers
		})
			.then(function (response) {
				setAbout(response.data)
			})
			.catch(function (error) {
				console.warn(error);
			})
			.then(function () {
				// always executed
			});
	}

	return (
		<div className="about-studio container">
			{about.map((row, index) => (
				<div className="item" key={index} dangerouslySetInnerHTML={{ __html: row.body }}>
				</div>
			))}
			<img alt="" style={{ display: "none" }} src={require("../../assets/images/about-home-1.png")} />
			<span className="orange-circle"></span>
		</div>
	)
}

About.contextTypes = {
	t: PropTypes.func
}

export default About