import React from "react"
import Main from "../main"
import PageTitle from "../includes/page_title.js"
import SlideToTop from "../includes/slide_to_top.js"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { useRef } from "react"
import topLineImage from "../../assets/images/top-line.png";
// const axios = require("axios");

// class ThankYou extends Main {
// 	constructor(props) {
// 		super(props);
// 		this.checkLogin();
// 		this.state = {
// 			isLoaded: false,
// 			config: this.config(),
// 			// error : "",
// 		}
// 	}
// 	componentDidMount() {
// 		// console.log(this.state);
// 		// this.filter_menu();
// 		setTimeout(() => {
// 			this.refs.contactSuccessRef.focus()
// 		}, 1000);
// 	}
// 	/*filter_menu(){
// 		const { config } = this.state;
// 		axios.get(config.origin+"/api/media-detailsgallery/4", {
// 			params: {
// 			  _format: "json"
// 			}
// 			})
// 			.then(function (response) {
// 				console.log(response.data);
		    
// 			})
// 			.catch(function (error) {
// 			console.log(error);
// 			});
// 	}*/
// 	render() {
// 		const topLineImage = require("../../assets/images/top-line.png");
// 		return (
// 			<div id="main-content">
// 				<SlideToTop />
// 				<PageTitle title={this.context.t("_subscribe")} />
// 				<div className="container">
// 					<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
// 						<ul>
// 							<li className="breadcrumb-item">
// 								<Link to="/">{this.context.t("_home")}</Link>  {" "}

// 							</li>
// 							<li className="breadcrumb-item">
// 								<Link tabIndex="0" to aria-current={this.context.t("_page")}>
// 									<span>{this.context.t("_subscribe")}</span>
// 								</Link>
// 							</li>
// 						</ul>
// 					</nav>
// 				</div>
// 				<div id="main-container">
// 					<div id="skipContent" className="container">
// 						<div className="row col-2">
// 							<div className="item subscribe-form">
// 								<h2 style={{ marginBottom: 10 }}>{this.context.t("_subscribe")}</h2>
// 								<p><strong>{this.context.t("_subscribe_heading_1")}</strong><br />{this.context.t("_subscribe_heading_2")}</p>
// 								<div className="thankyou-box" tabIndex="0" ref="contactSuccessRef">
// 									<h2 className="" > {this.context.t("_thank_you")}</h2>
// 									<span className=""> {this.context.t("_we_appreciate")}</span>
// 									<br /><span className=""> {this.context.t("_get_back_soon")} </span>
// 								</div>
// 							</div>
// 							<div className="item">
// 								<img src={require("../../assets/images/subscribe-right.png")} alt="" />
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 				<div className="top-line ng-scope">
// 					<img src={topLineImage} alt="" />
// 				</div>
// 			</div>
// 		)
// 	}
// }

// const mapStateToProps = ({ i18nState }) => {
// 	return {
// 		lang: i18nState.lang
// 	}
// }
// const mapDispatchToProps = {
// 	// showPopup: (data) => PopupAction.showPopup(data),
// 	// hidePopup: (data) => PopupAction.hidePopup(data),
// 	// showLoader: LoadingAction.showLoader,
// 	// hideLoader: LoadingAction.hideLoader,
// }
// ThankYou.contextTypes = {
// 	t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);


const ThankYou = (props, context) => {
	const contactSuccessRef = useRef(null)
	useEffect(() => {
		setTimeout(() => {
			contactSuccessRef.current.focus()
		}, 1000);
	}, [])
	return (
		<div id="main-content">
			<SlideToTop />
			<PageTitle title={context.t("_subscribe")} />
			<div className="container">
				<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
					<ul>
						<li className="breadcrumb-item">
							<Link to="/">{context.t("_home")}</Link>  {" "}

						</li>
						<li className="breadcrumb-item">
							<Link tabIndex="0" to aria-current={context.t("_page")}>
								<span>{context.t("_subscribe")}</span>
							</Link>
						</li>
					</ul>
				</nav>
			</div>
			<div id="main-container">
				<div id="skipContent" className="container">
					<div className="row col-2">
						<div className="item subscribe-form">
							<h2 style={{ marginBottom: 10 }}>{context.t("_subscribe")}</h2>
							<p><strong>{context.t("_subscribe_heading_1")}</strong><br />{context.t("_subscribe_heading_2")}</p>
							<div className="thankyou-box" tabIndex="0" ref={contactSuccessRef}>
								<h2 className="" > {context.t("_thank_you")}</h2>
								<span className=""> {context.t("_we_appreciate")}</span>
								<br /><span className=""> {context.t("_get_back_soon")} </span>
							</div>
						</div>
						<div className="item">
							<img src={require("../../assets/images/subscribe-right.png")} alt="" />
						</div>
					</div>
				</div>
			</div>
			<div className="top-line ng-scope">
				<img src={topLineImage} alt="" />
			</div>
		</div>
	)
}

ThankYou.contextTypes = {
	t: PropTypes.func
}

export default ThankYou
