import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"

import Main from "../../constants/utilities";
import PageTitle from "../includes/page_title";
import { LoadingAction } from "./../../actions";
import SlideToTop from "../includes/slide_to_top";
import { urlEndPoints } from "./../../httpAPI/apiConfig";

const FabLabs = (props, context) => {
    const { config, networkRequest } = Main();
    const configure = config()
    const dispatch = useDispatch()
    const [fabContent, setFabContent] = useState({})

    const { lang } = useSelector(
        (state) => state.i18nState
    );

    useEffect(() => {
        dispatch(LoadingAction.showLoader())
        getFabLabs();
    }, [])

    const getFabLabs = async () => {
        const url = `${configure.api_url}${urlEndPoints.getFabLabs}`
        try {
            const data = await networkRequest(url)
            if (data.data.error) {

            } else {
                setFabContent(data.data.results[0])
            }
            dispatch(LoadingAction.hideLoader())
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
    return (
        <div id="main-container">
            <SlideToTop />
            <PageTitle title={`Fab labs`} />
            <div className="container">
                <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                    <ul>
                        <li className="breadcrumb-item">
                            <Link to="/">{`Home`}</Link> {" "}
                        </li>
                        <li className="breadcrumb-item">
                            <span>
                                What is Fab Lab
                            </span>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="container">

                <h2>{fabContent.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: fabContent.body }}></div>

            </div>
            <br />

        </div>
    );
}

FabLabs.contextTypes = {
    t: PropTypes.func
}
export default FabLabs;
