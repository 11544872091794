import React, { useRef } from "react";
import { NavLink, Link, Redirect, useNavigate } from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import M from "materialize-css"
import Main from "../../constants/utilities";
// import Main from "../main"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import { LoginAction, SwitchLanguageAction } from "./../../actions"
import { withRouter } from "../../constants/common";
import { useState } from "react";
import { useEffect } from "react";
import logo from "./../../assets/images/logoSTUDIO5.svg";
import logoMTC from "./../../assets/images/logoMCIT.png";



const { config, networkRequest } = Main();


const Navigation = (props, context) => {

	const [isLoaded, setIsLoaded] = useState(false)
	const [configuration, setConfiguration] = useState(config())
	const [sideNav, setSideNav] = useState("");
	const [token, setToken] = useState(null);
	const [profileData, setProfileData] = useState({});
	const [isOpen, setIsOpen] = useState(false);

	const { lang } = useSelector((state) => state.i18nState)
	const { isLogin, profileImage } = useSelector((state) => state.loginReducer);

	const navigate = useNavigate();
	const dispatch = useDispatch()
	const nvRef = useRef(null);


	useEffect(() => {
		getProfileData()
		setTimeout(() => {
			M.AutoInit();
			let options = {
				startingTop: "0%"
			}
			let elem = document.querySelectorAll("#main-nav");
			M.Sidenav.init(elem, {});
			// elem = document.querySelector("#menuPopup");
			// 			// let instance = M.Modal.getInstance(elem, options);
			// 			// instance.open();
		}, 500);

		checkToken();
		setTimeout(() => {
			handleProfileListiner()
		}, 1000);
	}, []);

	const checkToken = () => {
		let storedToken = localStorage.getItem("refreshToken");
		setToken(storedToken);
	};


	// 	shouldComponentUpdate(nextProps) {
	// 		if (this.props.isLogin != nextProps.isLogin) {
	// 			setTimeout(() => {
	// 				this.handleProfileListiner()
	// 			}, 1000);
	// 			// return false
	// 		}
	// 		return true
	// 	}
	const handleProfileListiner = () => {
		const bannerButton = document.querySelector(".navigation-profile");
		if (bannerButton && bannerButton.addEventListener) {
			bannerButton.addEventListener('click', () => {
				const bannerElement = document.querySelectorAll(".navigation-profile");
				bannerElement[0].classList.toggle('hover');
			});
			bannerButton.addEventListener('keypress', () => {
				const bannerElement = document.querySelectorAll(".navigation-profile");
				bannerElement[0].classList.toggle('hover');
			});
			bannerButton.addEventListener('focusIn', () => {
				const bannerElement = document.querySelectorAll(".navigation-profile");
				bannerElement[0].classList.add('hover');
			});
			bannerButton.addEventListener('focusOut', () => {
				const bannerElement = document.querySelectorAll(".navigation-profile");
				bannerElement[0].classList.remove('hover');
			});

		}
		const popoverList = document.querySelector(".languageFocus");
		if (popoverList && popoverList.addEventListener) {
			popoverList.addEventListener('focusin', () => {
				const bannerElement = document.querySelectorAll("#profile-nav");
				bannerElement.length > 0 && bannerElement[0].classList.remove('hover');
			});
		}
		const contactPopover = document.querySelector(".contactPopover");
		if (contactPopover && contactPopover.addEventListener) {
			contactPopover.addEventListener('focusin', () => {
				const bannerElement = document.querySelectorAll("#profile-nav");
				bannerElement.length > 0 && bannerElement[0].classList.remove('hover');
			});
		}
		const contactFocus = document.querySelector(".hideMenuPopover");
		if (contactFocus && contactFocus.addEventListener) {
			contactFocus.addEventListener('focusin', () => {
				const bannerElement = document.querySelectorAll("#profile-nav");
				bannerElement.length > 0 && bannerElement[0].classList.remove('hover');
			});
		}
	}



	const getProfileData = async () => {
		// 	const authResponse = await this.auth();
		// if (authResponse) {
		// const url = `${configuration.api_url}${urlEndPoints.userDetails}`
		// const headers = {
		//     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		// try {
		// 	const data = await this.networkRequest(url)
		// setProfileData(data.data)
		// } catch (error) {
		// 	console.warn("error: ", error)
		// }
		// }
	};


	const logOut = () => {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("refreshToken");
		localStorage.removeItem("tokenType");
		localStorage.removeItem("role");
		// const { token, config } = this.state;
		dispatch(LoginAction.userLogout())
		navigate("/login")
		// axios.post(config.origin + "studioservices/user/logout?_format=json", {
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 		"Access-Control-Allow-Origin": "*",
		// 		"Accept": "application/json",
		// 		"Connection": "keep-alive",
		// 		"X-CSRF-Token": token
		// 	}
		// }).then(function (response) {
		// 	// localStorage.removeItem("token");
		// 	this.setState({
		// 		token: ""
		// 	});
		// return <Redirect to="" />
		// 	// this.props.history.push("/");
		// }).catch(function (error) {
		// 	console.log(error);
		// });
	};


	const switchLanguage = async (event) => {
		event.preventDefault();
		let curr_lang = localStorage.getItem("lang") == "en" ? "ar" : "en"
		await localStorage.setItem("lang", curr_lang);
		let pathName = window.location.pathname.toString();
		const urlLang = pathName.split("/")

		if (urlLang[1] != curr_lang) {
			pathName = pathName.replace(urlLang[1], curr_lang)
			window.location = pathName
		}
		// setTimeout(() => {
		// 	// that.props.switchLanguage(lang)
		// }, 2000);
		// window.location.reload();
	};




	const handleCloseMenu = () => {
		const elems = document.querySelector("#menuPopup");
		const instance = M.Modal.getInstance(elems);
		instance.close();
	};

	const handleOpenMenu = () => {
		const elems = document.querySelector("#menuPopup");
		const instance = M.Modal.getInstance(elems);
		instance.open();
	};

	const handleProfile = () => {
		const role = localStorage.getItem("role");
		switch (role) {
			case "trainer":
			case "manager":
			case "assistant":
			case "edu_specialist":
			case "administrator":
				return "/admin-profile";
			case "parent":
				return "/parent-profile";
			default:
				return "/profile";
		}
	};

	return (
		<header id="main-header" className={`navbar ${lang}`} ref={nvRef}>
			<div id="menuPopup" className="modal menuHolder">
				<ColouredItems />
				<div className="modalContentInner">

					<div className="modal-content">
						<span className="badge modalClose"><a onClick={() => handleCloseMenu()}><i className="material-icons">close</i></a></span>
						<h3>{context.t("_main_menu")}</h3>
						<div className="row">
							<div className="col">
								<ul>
									<li>
										<NavLink onClick={() => handleCloseMenu()} exact to="/" >{context.t("_home_page")}</NavLink>
									</li>
									<li>
										<NavLink onClick={() => handleCloseMenu()} exact to="/activities" >{context.t("_activities")}</NavLink>
									</li>
									<li>
										<NavLink onClick={() => handleCloseMenu()} exact to="/media-center" >{context.t("_navbar_media_center")}</NavLink>
									</li>
									<li>
										<NavLink onClick={() => handleCloseMenu()} exact to="/contact" >{context.t("_contact_us")}</NavLink>
									</li>
									{/* <li>
										<NavLink onClick={()=>handleCloseMenu()} exact to="/equipment-used" >Equipment</NavLink>
									</li> */}
									<li>
										<NavLink onClick={() => handleCloseMenu()} exact to="/subscribe" >{context.t("_subscribe")}</NavLink>
									</li>
								</ul>
							</div>

							<div className="col">
								<ul className="menuStyle2">
									<li>
										<NavLink onClick={() => handleCloseMenu()} exact to="/about" >{context.t("_about_us")}</NavLink>
									</li>
									{/* <li>
										<NavLink onClick={()=>handleCloseMenu()} exact to >Publications</NavLink>
									</li> */}
									{/* <li>
										<NavLink onClick={()=>handleCloseMenu()} exact to="/announcements" >Announcement</NavLink>
									</li> */}
									{/* <li>
										<NavLink onClick={()=>handleCloseMenu()} exact to="/fab-labs" >Fab Lab</NavLink>
									</li> */}
									<li>
										<NavLink onClick={() => handleCloseMenu()} exact to="/machines" >{context.t("_machines")}</NavLink>
									</li>
									<li>
										<NavLink onClick={() => handleCloseMenu()} exact to="/registration" >{context.t("_registration_menu")}</NavLink>
									</li>
								</ul>
							</div>
							<div className="col">
								<ul className="menuStyle2">
									{/* <li>
										<NavLink onClick={()=>handleCloseMenu()} exact to>Terms and Conditions</NavLink>
									</li> */}
									<li>
										<NavLink onClick={() => handleCloseMenu()} exact to="/privacy-policies">{context.t("_privacy_policy")}</NavLink>
									</li>
									{/* <li>
										<NavLink onClick={()=>handleCloseMenu()} exact to>Best Practices</NavLink>
									</li> */}
									<li>
										<NavLink onClick={() => handleCloseMenu()} exact to="/faq" >{context.t("_faq")}</NavLink>
									</li>
									{/* <li>
										<NavLink onClick={()=>handleCloseMenu()} exact to="/virtual-tour" >Virtual Tour</NavLink>
									</li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container flex-row" style={dynamicStyles.direction(lang)}>

				<nav className="navbar top-menu">
					<div className="nav-wrapper">

						<span className="brand-logo">
							<a href={`https://www.mcit.gov.qa/${lang}`} target="blank">
								<img src={logoMTC} alt={context.t("ministry_logo_alt")} />
							</a>
							<NavLink to="/" id="headerLogo" className="hideMenuPopover">
								<h1>
									<img src={logo} alt={context.t("studio_logo_alt")} />
								</h1>
							</NavLink>
						</span>
					</div>
				</nav>
				{/* <div className="headerRight">
					<div className="lang"><NavLink to="" onClick={evt => switchLanguage(evt)} activeClassName="active" className="sidenav-close" >{lang == "en" ? `عربى` : "English"}<span></span></NavLink></div>
					<div onClick={() => handelOpenMenu()} className="btnMenu">
						<span>
							Menu
						</span>
					</div>
					{isLogin ?

						<span className="navigation-profile" tabIndex="0" type="button" aria-label="profile navigation">
							<img src={profileImage} alt="" />
							<menu>
								<ul>
									<li>
										<NavLink exact to={(
													localStorage.getItem("role") == "trainer" ||
													localStorage.getItem("role") == "manager" ||
													localStorage.getItem("role") == "asistant"|| 
													localStorage.getItem("role") == "edu_specialist"
												)? "/admin-profile" : "/profile"} ><i className="fa fa-user-circle-o" aria-hidden="true"></i> {context.t("_profile")}</NavLink>
									</li>
									<li>
										<NavLink exact to="/notification" ><i className="fa fa-bell-o" aria-hidden="true"></i> {context.t("_notification")}</NavLink>
									</li>
									<li>
										<a onClick={() => logOut()}><i className="fa fa-sign-out" aria-hidden="true"></i> {context.t("_logout")}</a>
									</li>
								</ul>
							</menu>
						</span>
						:
						<NavLink exact to="/login" activeClassName="active" className="sidenav-close" >{context.t("_login")}</NavLink>
					}
				</div> */}
				<ul id="main-nav" className="sidenav sidenav-fixed align-center" style={dynamicStyles.direction(lang)}>
					{
						(
							localStorage.getItem("role") == "trainer" ||
							localStorage.getItem("role") == "manager" ||
							localStorage.getItem("role") == "asistant" ||
							localStorage.getItem("role") == "edu_specialist" ||
							localStorage.getItem("role") == "administrator"
						)
							?
							null :
							<>
								<li><NavLink aria-label={context.t("_home")} exact to="/" activeClassName="active" className="sidenav-close" >{context.t("_home")}</NavLink></li>
								<li><NavLink aria-label={context.t("_about_us")} exact to="/about" activeClassName="active" className="sidenav-close" >{context.t("_about_us")}</NavLink></li>
								<li><NavLink aria-label={context.t("_activities")} to="/activities" activeClassName="active" className="sidenav-close" >{context.t("_activities")}</NavLink></li>
								<li><NavLink aria-label={context.t("_navbar_media_center")} to="/media-center" activeClassName="active" className="sidenav-close" >{context.t("_navbar_media_center")}</NavLink></li>
								<li><NavLink aria-label={context.t("_machines")} to="/machines" activeClassName="active" className="sidenav-close" >{context.t("_machines")}</NavLink></li>
								<li><NavLink aria-label={context.t("_equipment")} to="/equipment-used" activeClassName="active" className="sidenav-close" >{context.t("_equipment")}</NavLink></li>
								<li className="contactPopover"><NavLink aria-label={context.t("_contact_us")} to="/contact" activeClassName="active" className="sidenav-close" >{context.t("_contact_us")}</NavLink></li>
							</>
					}

					{/* {isLogin ?
						<li><Link to="/" onClick={evt => logOut()} className="sidenav-close">{context.t("_navbar_logout")}</Link></li>
						:
						<li><NavLink exact to="/login" activeClassName="active" className="sidenav-close" >{context.t("_login")}</NavLink></li>

					} */}

					<li className="dropdown">{isLogin ?
						<span tabIndex="0" id="profile-nav" className="navigation-profile" role="button" aria-haspopup="true" aria-expanded="false" type="button" aria-label="profile navigation">
							<img src={profileImage===undefined ? localStorage.getItem("profileImage"):profileImage} alt="profile" />
							<menu className='dropmenu' aria-labelledby="profile-nav" role="menu">
								<ul>
									<li role="menuitem">
										<NavLink tabIndex="0"
											exact
											to={handleProfile()} ><i className="fa fa-user-circle-o" aria-hidden="true"></i> {context.t("_profile")}</NavLink>
									</li>
									{
										(
											localStorage.getItem("role") == "trainer" ||
											localStorage.getItem("role") == "manager" ||
											localStorage.getItem("role") == "asistant" ||
											localStorage.getItem("role") == "edu_specialist" ||
											localStorage.getItem("role") == "administrator"
										)
											?
											null :
											<li>
												<NavLink exact to="/notification" ><i className="fa fa-bell-o" aria-hidden="true"></i> {context.t("_notification")}</NavLink>
											</li>
									}
									<li>
										<button tabIndex="0" onClick={() => logOut()}><i className="fa fa-sign-out" aria-hidden="true"></i> {context.t("_logout")}</button>
									</li>
								</ul>
							</menu>
						</span>
						:
						<NavLink to="/login" aria-label={context.t("sign_up_button_label")} activeClassName="active" className="sidenav-close get-started" style={dynamicStyles.direction(lang)}>
							{context.t("_login_nav")}
							<i className={`material-icons ${lang}`}>{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
						</NavLink>
					}
					</li>
					<li className="lang languageFocus"><NavLink to="" style={{ fontFamily: "politicaextrabold" }} onClick={evt => switchLanguage(evt)} activeClassName="" className="sidenav-close nav-lang" aria-label={lang == "ar" ? "language button to english" : "language button to arabic"} >{lang == "en" ? "ع" : "En"}{/* <span></span> */}</NavLink></li>
					{/* <li><NavLink to="/subscribe" activeClassName="active" className="sidenav-close get-started" style={dynamicStyles.direction(lang)}>
						{context.t("_navbar_get_started")}
						<i className={`material-icons ${lang}`}>{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
					</NavLink></li> */}
					{
						// !isLogin && <li><NavLink to="/registration" aria-label={context.t("sign_up_button_label")} activeClassName="active" className="sidenav-close get-started" style={dynamicStyles.direction(lang)}>
						// 	{context.t("_sign_up")}
						// 	<i className={`material-icons ${lang}`}>{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
						// </NavLink></li>
					}

				</ul>
				<a href="#" data-target="main-nav" className="sidenav-trigger sasi"><i className="material-icons">menu</i></a>

				{/* <ul id="main-nav" className="sidenav sidenav-fixed align-center" style={dynamicStyles.direction(lang)}>
					<li><NavLink exact to="/" activeClassName="active" className="sidenav-close" >{context.t("_home")}</NavLink></li>
					<li><NavLink exact to="/activities" activeClassName="active" className="sidenav-close" >{context.t("_activities")}</NavLink></li>
					<li><NavLink to="/media-center" activeClassName="active" className="sidenav-close" >{context.t("_navbar_media_center")}</NavLink></li>
					{isLogin ?
						<li><Link to="/" onClick={evt => logOut()} className="sidenav-close">{context.t("_navbar_logout")}</Link></li>
						:
						<li><NavLink exact to="/login" activeClassName="active" className="sidenav-close" >{context.t("_login")}</NavLink></li>

					}
					<li className="lang"><NavLink to="" onClick={evt => switchLanguage(evt)} activeClassName="active" className="sidenav-close" >{lang == "en" ? `عربى` : "English"}<span></span></NavLink></li>
					{isLogin ?
						<li>
							<span className="navigation-profile" tabIndex="0" >
								<img src={profileImage} alt="" />
								<menu>
									<ul>
										<li>
											<NavLink exact to="/profile" ><i className="fa fa-user-circle-o" aria-hidden="true"></i> {context.t("_profile")}</NavLink>
										</li>
										<li>
											<NavLink exact to="/notification" ><i className="fa fa-bell-o" aria-hidden="true"></i> {context.t("_notification")}</NavLink>
										</li>
										<li>
											<a onClick={evt => logOut()}><i className="fa fa-sign-out" aria-hidden="true"></i> {context.t("_logout")}</a>
										</li>
									</ul>
								</menu>
							</span>
							<NavLink exact to="/profile" activeClassName="active" className="sidenav-close navigation-profile" >
								<img src={profileImage} alt="" />
							</NavLink>
						</li>
						:
						null}

					<li><NavLink to="/subscribe" activeClassName="active" className="sidenav-close get-started" style={dynamicStyles.direction(lang)}>
						{context.t("_navbar_get_started")}
						<i className={`material-icons ${lang}`}>{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
					</NavLink></li>

				</ul> */}
			</div>
		</header>
	)

}

Navigation.contextTypes = {
	t: PropTypes.func
}
export default Navigation

const ColouredItems = () => {
	return (
		<div className="menu-icon-container">
			<span className="grey-square-rotate red-sq one"></span>
			<span className="grey-square-rotate red-sq two"></span>
			<span className="orange-circle one"></span>
			<span className="orange-circle two"></span>
		</div>
	)
}