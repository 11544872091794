import React, { useState, useEffect, useRef } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import Main from "../../constants/utilities"
import PageTitle from "../includes/page_title.js"
import SlideToTop from "../includes/slide_to_top.js"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { LoadingAction, PopupAction } from "./../../actions"
import { passwordValidation } from "./../../constants/common"
import { dynamicStyles } from "./../../constants/dynamicStyles"

const ChangePassword = (props, context) => {
	const { config, networkRequest } = Main();
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const configure = config()
	const intervalId = 0;
	const delayInMs = 16.66
	const scrollStepInPx = 50;
	const [error, setError] = useState("");
	const [formData, setFormData] = useState({});
	const [isLoaded, setIsLoaded] = useState(false);
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [submissionStatus, setSubmissionStatus] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const { lang } = useSelector(
		state => state.i18nState
	);

	useEffect(() => {
		document.title = `${context.t("_studio56")} | ${context.t("_change_password")}`
		initState()
		setTimeout(() => {
			document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
		}, 500);
	}, []);
	// constructor(props) {
	// 	super(props);
	// 	this.state = {
	// 		isLoaded: false,
	// 		config: this.config(),
	// 		formData: {},
	// 		error: "",
	// 		intervalId: 0,
	// 		scrollStepInPx: 50,
	// 		delayInMs: 16.66,
	// 		submissionStatus: false,
	// 		showOldPassword: false,
	// 		showNewPassword: false,
	// 		showConfirmPassword: false,
	// 	}
	// 	this.handleChange = this.handleChange.bind(this);
	// 	this.onSubmit = this.onSubmit.bind(this);
	// }
	// componentDidMount() {
	// 	document.title = `${this.context.t("_studio56")} | ${this.context.t("_change_password")}`
	// 	this.initState()
	// 	setTimeout(() => {
	// 		document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
	// 	}, 500);
	// }
	// componentDidUpdate() {
	// 	document.title = `${this.context.t("_studio56")} | ${this.context.t("_change_password")}`
	// }
	const initState = () => {
		setFormData({
			oldPassword: { value: "", error: "", class: "" },
			newPassword: { value: "", error: "", class: "" },
			cpassword: { value: "", error: "", class: "" },
		})

	}
	const handleChange = (field, value) => {
		const updatedFormData = { ...formData };
		updatedFormData[field]["class"] = value ? "" : "invalid";
		updatedFormData[field]["value"] = value;
		// contact_form.submit = submit;
		setFormData(updatedFormData)
		setSubmissionStatus(false)
	}
	const scrollStep = () => {
		if (window.pageYOffset <= 400) {
			clearInterval(intervalId);
		}
		window.scroll(0, window.pageYOffset - scrollStepInPx);
	}
	const scrollToTop = () => {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
		// var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
		// let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
		// this.setState({ intervalId: intervalId });
	}
	const validate = () => {
		const updatedFormData = { ...formData };
		for (var key1 in updatedFormData) {
			updatedFormData[key1].value = updatedFormData[key1].value.trim();
			updatedFormData[key1].error = "";
		}

		if (updatedFormData.oldPassword.value === "")
			updatedFormData.oldPassword.error = context.t("_old_password_is_required");

		if (updatedFormData.newPassword.value === "")
			updatedFormData.newPassword.error = context.t("_password_is_required");

		if (updatedFormData.newPassword.value != "" && updatedFormData.newPassword.value.length < 8 && !passwordValidation.test(updatedFormData.newPassword.value))
			updatedFormData.newPassword.error = context.t("_password_condition");

		if (updatedFormData.cpassword.value === "")
			updatedFormData.cpassword.error = context.t("_confirm_password_required");

		if ((updatedFormData.newPassword.value !== "" && updatedFormData.cpassword.value !== "") && (updatedFormData.newPassword.value !== updatedFormData.cpassword.value))
			updatedFormData.newPassword.error = context.t("_password_mismatch");

		for (var key in updatedFormData) {
			if (updatedFormData[key].error !== "") {
				setFormData(updatedFormData)
				setSubmissionStatus(false)
				setTimeout(() => {
					document.querySelector(`a[href="#${key}"]`).focus()
				}, 1000);
				return false;

			}
		}
		return true;
	}
	const displayError = error => {
		const updatedFormData = { ...formData };
		if (error.name)
			updatedFormData.oldPassword.error = error.name[lang]

		for (var key in formData) {
			if (formData[key].error !== "") {
				setFormData(updatedFormData)
				setTimeout(() => {
					document.querySelector(`a[href="#${key}"]`).focus()
				}, 1000);
				return false;

			}
		}
	}
	const onSubmit = async (event) => {
		event.preventDefault();
		if (validate()) {
			dispatch(LoadingAction.showLoader())
			const obj = {
				"pass": formData.newPassword.value,
				"oldpass": formData.oldPassword.value
			}
			const url = `${configure.api_url}${urlEndPoints.updatePassword}`
			try {
				const data = await networkRequest(url, "POST", obj)
				if (data.data.error) {
					dispatch(LoadingAction.hideLoader())
					displayError(data.data.error)
				}
				else {
					// localStorage.removeItem("accessToken");
					// localStorage.removeItem("refreshToken");
					// localStorage.removeItem("tokenType");
					// localStorage.removeItem("role");

					dispatch(PopupAction.showPopup({
						type: "success",
						title: context.t("_success"),
						message: context.t("_password_changed_success"),
						isOpen: true,
						buttonText: context.t("_ok")
					}))

					if (
						localStorage.getItem("role") == "trainer" ||
						localStorage.getItem("role") == "manager" ||
						localStorage.getItem("role") == "asistant" ||
						localStorage.getItem("role") == "edu_specialist" ||
						localStorage.getItem("role") == "administrator"
					)
						navigate("/admin-profile");
					else
						navigate("/profile");
				}
			} catch (error) {
				dispatch(LoadingAction.hideLoader())
				console.warn("error: ", error)
			}
		}
	}
	let hasError = false;
	Object.keys(formData).forEach((item, index) => {
		if (formData[item].error !== "")
			hasError = true
	})
	return (
		<div id="main-content">
			<SlideToTop />
			<PageTitle title={context.t("_change_password")} />
			<div className="container">
				<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
					<ul>
						<li className="breadcrumb-item">
							<Link to="/">{context.t("_home")} </Link> {" "}
						</li>
						<li className="breadcrumb-item">
							<Link tabIndex="0" to aria-current={context.t("_page")}>
								<span>{context.t("_change_password")}</span>
							</Link>
						</li>
					</ul>
				</nav>
			</div>
			<div id="skipContent">
				<div id="main-container">
					<div className="container">
						<div className="row col-8">
							{
								_.isEmpty(formData) ?
									<div /> :
									<form name="login" onSubmit={onSubmit} className="login-form">
										{error &&
											<div className="error-container fail">
												<ul>
													<li><label className="error" >{error}</label></li>
												</ul>
											</div>
										}
										{
											hasError ? <div className="error-container fail">
												<h5>{context.t("_error_title")}</h5>
												<ul>
													{
														Object.keys(formData).map((item, index) => {
															return formData[item].error !== "" ? <li key={index} ><a tabIndex="0" href={`#${item}`} className="error" htmlFor={item}>{formData[item].error}</a></li>
																:
																null
														})}
												</ul>
											</div> : null
										}
										{
											submissionStatus ? <div className="error-container success" tabIndex="0" >
												<h5>{context.t("_reset_password_success")}</h5>
											</div> : null
										}
										<div className="input-field item">
											<input name="oldPassword"
												placeholder={context.t("_old_password")}
												className="fontEnglish"
												onChange={evt => handleChange("oldPassword", evt.target.value)}
												onFocus={evt => handleChange("oldPassword", evt.target.value)}
												value={formData.oldPassword.value}
												id="oldPassword"
												type={showOldPassword ? "text" : "password"}
											/>
											<button aria-label="Show password" onClick={() => { setShowOldPassword(!showOldPassword) }} type="button" className="showEyes">
												<i className={showOldPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
											</button>
											<label htmlFor="oldPassword" style={dynamicStyles.position(lang)}>{context.t("_old_password")}</label>
											<span className="helper-text" data-error="Required field."></span>
										</div>
										<div className="input-field item">
											<input name="newPassword"
												className="fontEnglish"
												placeholder={context.t("_password")}
												onChange={evt => handleChange("newPassword", evt.target.value)}
												onFocus={evt => handleChange("newPassword", evt.target.value)}
												value={formData.newPassword.value}
												id="newPassword"
												type={showNewPassword ? "text" : "password"}
											/>
											<button aria-label="Show password" onClick={() => { setShowNewPassword(!showNewPassword) }} type="button" className="showEyes">
												<i className={showNewPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
											</button>
											<label htmlFor="newPassword" style={dynamicStyles.position(lang)}>{context.t("_password")}</label>
											<small className="info">{context.t("_password_condition")}</small>
											<span className="helper-text" data-error="Required field."></span>
										</div>
										<div className="input-field item">
											<input name="cpassword"
												className="fontEnglish"
												placeholder={context.t("_confirm_password")}
												onChange={evt => handleChange("cpassword", evt.target.value)}
												onFocus={evt => handleChange("cpassword", evt.target.value)}
												value={formData.cpassword.value}
												id="cpassword"
												type={showConfirmPassword ? "text" : "password"}
											/>
											<button aria-label="Show password" onClick={() => { setShowConfirmPassword(!showConfirmPassword) }} type="button" className="showEyes">
												<i className={showConfirmPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
											</button>
											<label htmlFor="cpassword" style={dynamicStyles.position(lang)}>{context.t("_confirm_password")}</label>
											<span className="helper-text" data-error="Required field."></span>
										</div>

										<div className="btn-wrap reset-button-container">
											<button className="btn red login" >{context.t("_change_password")} <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i>
											</button>
										</div>
									</form>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

ChangePassword.contextTypes = {
	t: PropTypes.func
}
export default ChangePassword;
