import React, { useEffect, useState } from "react"
import Main from "../../constants/utilities"
import { Link } from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../constants/dynamicStyles"
const axios = require("axios");

const News = (props, context) => {
	const { config } = Main()
	const configure = config()
	const [news, setNews] = useState([])
	const [newsVideo, setNewsVideo] = useState([])
	const [newsGallery, setNewsGallery] = useState([])
	const { lang } = useSelector(state => state.i18nState)

	function get_news_gallery() {
		let url = "";
		if (lang == "ar")
			url = `${configure.origin}${lang}/api/featured-news-gallery`
		else
			url = `${configure.origin}api/featured-news-gallery`

		axios.get(url, {
			params: {
				_format: "json"
			}
		})
			.then(function (response) {
				setNewsGallery(response.data)
			})
			.catch(function (error) {
				console.warn(error);
			});
	}
	function get_news_video() {
		let url = "";
		if (lang == "ar")
			url = `${configure.origin}${lang}/api/featured-news-video`
		else
			url = `${configure.origin}api/featured-news-video`

		axios.get(url, {
			params: {
				_format: "json"
			}
		})
			.then(function (response) {
				setNewsVideo(response.data)
			})
			.catch(function (error) {
				console.warn(error);
			});
	}
	function get_news() {
		let url = "";
		if (lang == "ar")
			url = `${configure.origin}${lang}/api/featured-news-article`
		else
			url = `${configure.origin}api/featured-news-article`
		axios.get(url, {
			params: {
				_format: "json"
			}
		})
			.then(function (response) {
				setNews(response.data)
			})
			.catch(function (error) {
				console.warn(error);
			})
			.then(function () {
				// always executed
			});
	}

	useEffect(() => {
		get_news()
		get_news_gallery()
		get_news_video()
	}, [])
	return (
		<div className="news-wrap">
			<div className="container">
				<h2 id="news-media" className="line">
					{/* <span className="grey-text">{this.context.t("_updates")}</span> */}
					{context.t("_news")} {context.t("_and_symbol")} {context.t("_media")}
					<span className="grey-square-rotate"></span>
				</h2>
				<div className="flex-row col-2">
					<div className="item news-item">
						<div className="flex-row col-3">
							{news.map((row, index) => (
								<div className="homeNewsParent" key={index}>
									<Link to={"/news/" + row.nid} className="item homeNewsText1" aria-label={`${row.title} ${row.category} ${row.date}`}>
										<h3 dangerouslySetInnerHTML={{ __html: row.title }}></h3>
									</Link>
									<div className="img-wrap"
									// dangerouslySetInnerHTML={{ __html: row.image }}
									>
										<img src={configure.origin + row.field_newsimage} alt="news image" />
									</div>
									<div className="homeNewsText2">
										<div dangerouslySetInnerHTML={{ __html: row.category }} className="category-wrap"></div>
										<div className="desc" dangerouslySetInnerHTML={{ __html: row.description }}></div>
										<p className="date-wrap" aria-label={row.date} dangerouslySetInnerHTML={{ __html: row.date }} ></p>
									</div>

								</div>
							))}
						</div>
					</div>
					<div className={`item gal-item ${lang}`}>
						<div className="flex-row col-2">
							<div className="item">
								{newsVideo.map((row, index) => (
									<div className="" key={index}>
										<div className="videowrapper">
											<object
												data={row.video} tabIndex="-1">
												<param name="movie" value={row.video} />
												<param name="allowFullScreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" value="true"></param>
												<span>Video</span>
											</object>
										</div>
										<div className="category-wrap Video" dangerouslySetInnerHTML={{ __html: row.category }} ></div>
										<Link to={"/news/" + row.nid} aria-label={`${row.title} ${row.category} ${row.date}`}>
											<div className="desc" dangerouslySetInnerHTML={{ __html: row.title }}></div>
										</Link>
										<p className="date-wrap" aria-label={row.date} dangerouslySetInnerHTML={{ __html: row.date }}></p>
									</div>
								))}
							</div>
							<div className="item">
								{newsGallery.map((row, index) => (
									<div className="" key={index}>
										<div className="img-wrap" dangerouslySetInnerHTML={{ __html: row.image }}></div>
										<div className="category-wrap Gallery" dangerouslySetInnerHTML={{ __html: row.category }} ></div>
										<Link to={"/news/" + row.nid} aria-label={`${row.title} ${row.category} ${row.date}`}>
											<div className="desc" dangerouslySetInnerHTML={{ __html: row.title }}></div>
										</Link>
										<p className="date-wrap" aria-label={row.date} dangerouslySetInnerHTML={{ __html: row.date }}></p>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="view-more home">
					<Link to="/media-center" aria-label={context.t("_view_more_news_and_media")}>{context.t("_view_more")}
						<i className={`material-icons ${lang}`}>{`${lang == "en" ? "" : ""}`}</i>
					</Link>
				</div>
				<div className="multi-square"><b><i></i></b></div>
				<div className="multi-circle"><b><i></i></b></div>
			</div>
		</div>
	)
}

News.contextTypes = {
	t: PropTypes.func
}

export default News
