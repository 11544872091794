import { GET_PARENT_PROFILE_DETAILS, HIDE_LOADER } from "../../../constants/constants"
import { LoadingAction, LoginAction } from "../.."
import Main from "../../../constants/utilities"
import { urlEndPoints } from "../../../httpAPI/apiConfig";


const { config, networkRequest } = Main();

export const getProfileData = () => {
    // const authResponse = await this.auth();
    // if (authResponse) {
    const url = `${config().api_url}${urlEndPoints.userDetails}`
    // const headers = {
    //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
    // }

    // }
    return async (dispatch) => {
        try {
            const data = await networkRequest(url)
            localStorage.setItem("profileImage", `${config().origin}${data.data.picture}`)
            dispatch(LoginAction.userLoggedIn(`${config().origin}${data.data.picture}`))
            dispatch(LoadingAction.hideLoader())
            dispatch({ type: GET_PARENT_PROFILE_DETAILS, payload: data.data })
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
}