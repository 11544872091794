import React from "react"
import Main from "../../constants/utilities"
import _ from "lodash"
import Slider from "react-slick"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { PopupAction, LoadingAction } from "./../../actions"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import { withRouter } from "../../constants/common"
import { Map, Marker, GoogleApiWrapper } from "google-maps-react"
import { useEffect } from "react"
import loaderAction from "../../actions/loaderAction"
import { useState } from "react"
import { networkRequest } from "../../httpAPI/api"
const axios = require("axios");

const SessionDetails = (props, context) => {
	// isLoaded: false,
	// 		config: config(),
	// 		gallery: [],
	// 		currentSlide: 0
	const [isLoaded, setIsLoaded] = useState(false)
	const [gallery, setGallery] = useState([])
	const [currentSlide, setcurrentSlide] = useState(0)
	const { config } = Main()
	const configure = config()
	const dispatch = useDispatch()
	const { lang } = useSelector(state => state.i18nState)

	const { result, isEnrolled, id } = props
	const navigate = useNavigate()


	const slick_settings = {
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		afterChange: afterChangeHandler,

	};
	var materials = result.field_materials && result.field_materials.split(",").map(function (material, index) {
		return <div key={index} className="item"><div className="img-wrap"></div><p>{material}</p></div>;
	});

	const handleEnroll = async (id) => {
		// if (_.isEmpty(localStorage.getItem("role"))) {
		// 	return this.props.history.push("/registration")
		// }
		const role = await localStorage.getItem("role");
		switch (role) {
			case "student":
				dispatch(LoadingAction.showLoader());
				handleEnrollStudent(id)
				break;
			case "school":
			case "parent":
				dispatch(LoadingAction.showLoader());
				handleEnrollParent(id)
				break;

			default:
				dispatch(PopupAction.showPopup({
					type: "error",
					title: "",
					// message: context.t("_login_required"),
					message: <>
						<Link onClick={() => { dispatch(PopupAction.hidePopup({})) }} to="/login"><b>{context.t("_login_link")}</b></Link> {context.t("_is_required_for_enrolment")}
					</>,
					isOpen: true,
					buttonText: context.t("_ok")
				}))
				break;
		}
	}
	const displayError = error => {
		const { lang } = props;
		if (error.name) {
			dispatch(PopupAction.showPopup({
				type: "error",
				title: context.t("_failed"),
				message: error.name[lang],
				isOpen: true,
				buttonText: context.t("_ok")
			}))
		}
	}
	const handleEnrollStudent = async (id) => {
		// const authResponse = await auth();
		// if (authResponse) {
		const url = `${configure.api_url}${urlEndPoints.activityEnrolment}`
		// const headers = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		const obj = {
			"activity_id": id
		}
		try {
			const data = await networkRequest(url, "POST", obj)
			dispatch(LoadingAction.hideLoader());
			if (data.data.error) {
				displayError(data.data.error)
			} else {
				dispatch(PopupAction.showPopup({
					type: "success",
					title: context.t("_success"),
					message: context.t("_enroll_success"),
					isOpen: true,
					buttonText: context.t("_ok")
				}))
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
		// }

	}
	const handleEnrollParent = (id) => {
		navigate(`/subscribe-activities/${id}`)
	}
	const handleOpenMap = (mapUrl) => {
		window.open(mapUrl);
	}
	const handleEnrolledClick = () => {
		PopupAction.showPopup({
			type: "error",
			title: context.t("_warning"),
			message: context.t("_already_enrolled"),
			isOpen: true,
			buttonText: context.t("_ok")
		})
	}
	const getMapEmbeddedUrl = url => {
		var coords = /\@([0-9\.\,\-a-zA-Z]*)/.exec(url);
		if (coords != null) {
			var coordsArray = coords[1].split(",");
			return "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d" + coordsArray[1] + "!3d" + coordsArray[0] + "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098";
		}
	}
	const afterChangeHandler = (currentSlide) => {
		setcurrentSlide(currentSlide)
	}
	const getGender = gender => {
		gender = gender.toLowerCase()
		switch (gender) {
			case "male":
				return context.t("_boys")
			case "female":
				return context.t("_girls")
			case "mixed":
				return context.t("_mixed")

		}
	}
	const getDateInFormat = date => {
		if (date.includes("Jan"))
			date = date.replace("Jan", context.t("_jan"))

		if (date.includes("Feb"))
			date = date.replace("Feb", context.t("_feb"))

		if (date.includes("Mar"))
			date = date.replace("Mar", context.t("_mar"))

		if (date.includes("Apr"))
			date = date.replace("Apr", context.t("_apr"))

		if (date.includes("May"))
			date = date.replace("May", context.t("_may"))

		if (date.includes("Jun"))
			date = date.replace("Jun", context.t("_jun"))

		if (date.includes("Jul"))
			date = date.replace("Jul", context.t("_jul"))

		if (date.includes("Aug"))
			date = date.replace("Aug", context.t("_aug"))

		if (date.includes("Sep"))
			date = date.replace("Sep", context.t("_sep"))

		if (date.includes("Oct"))
			date = date.replace("Oct", context.t("_oct"))

		if (date.includes("Nov"))
			date = date.replace("Nov", context.t("_nov"))

		if (date.includes("Dec"))
			date = date.replace("Dec", context.t("_dec"))

		return date

	}
	const handleEnrollClosed = () => {
		dispatch(PopupAction.showPopup({
			type: "error",
			title: context.t("_warning"),
			message: context.t("_enrolment_closed"),
			isOpen: true,
			buttonText: context.t("_ok")
		}))
	}
	const handleEnrolButton = (item, id) => {
		if (Number(item.field_number_of_attendees) > Number(item.enrolmentCount))
			return <button onClick={() => { handleEnroll(id) }} className="btn blue-btn ">{context.t("_enroll")}</button>
		else if (Number(item.enrolmentCount) < (Number(item.field_number_of_attendees) + Number(item.field_waiting_list_count)))
			return <button onClick={() => { handleEnroll(id) }} className="btn blue-btn ">{context.t("_waiting_list")}</button>
		else
			return <button onClick={() => { handleEnrollClosed() }} className="btn blueColor btn-disabled">
				{context.t("_closed")}
			</button>
	}
	const getTimeInFormat = time => {
		if (time.includes("am"))
			time = time.replace(/am/g, context.t("_am"))

		if (time.includes("pm"))
			time = time.replace(/pm/g, context.t("_pm"))

		return time;
	}

	useEffect(() => {
		let url = ""
		if (lang == "ar")
			url = `${configure.origin}${lang}/api/${urlEndPoints.activityDetailsGallery(id)}`
		else
			url = `${configure.origin}api/${urlEndPoints.activityDetailsGallery(id)}`

		axios.get(url, {
			params: {
				_format: "json"
			}
		})
			.then(function (response) {
				dispatch(loaderAction.dispatch(LoadingAction.hideLoader()))
				setGallery(response.data["media-gallery"])
			}).catch(err => {
				dispatch(loaderAction.dispatch(LoadingAction.hideLoader()))
				console.warn("Error", err)
			});
	}, [])
	return (
		<div className="session-details">
			<div className="container">
				<div className="flex-row">
					<h2>{result.eventname}</h2>
					{
						(
							localStorage.getItem("role") == "trainer" ||
							localStorage.getItem("role") == "manager" ||
							localStorage.getItem("role") == "asistant" ||
							localStorage.getItem("role") == "edu_specialist" ||
							localStorage.getItem("role") == "administrator"
						) ? null :
							result.field_need_to_show_enroll_button == "1" && (!_.isEmpty(result.field_start_and_end_time_3) && !_.isEmpty(result.field_start_and_end_time_4) && (new Date(result.field_start_and_end_time_2) >= new Date())) ?
								isEnrolled ?
									<button style={{ width: 90 }} onClick={() => { handleEnrolledClick() }} className="btn blue-btn btn-disabled">{context.t("_enrolled")}</button>
									:
									handleEnrolButton(result, id)
								: null
					}
				</div>
			</div>
			<div className="container">
				<div className="description" dangerouslySetInnerHTML={{ __html: result.details }}></div>
			</div>
			<div className="activity-slider">
				{
					!_.isEmpty(gallery) && <Slider {...slick_settings}>
						{gallery.map((row, index) => (
							<div className="item" key={index}>
								<img src={row} alt="" />
							</div>
						))}
					</Slider>
				}
				{
					!_.isEmpty(gallery) && <div className="topBannerCount"><strong>{`${currentSlide + 1}`}</strong>{` / ${gallery.length}`}</div>
				}

			</div>
			<div className="container">
				<h2>{context.t("_learning_objectives")}</h2>
				<div className="description" dangerouslySetInnerHTML={{ __html: result.field_learning_objectives }}></div>
				<h4>{context.t("_details")}</h4>

				<div className="row details-wrap-new">
					<div className="col s6">
						<table>
							<tbody>

								<tr>
									<th>{context.t("_category")}</th>
									<td>{_.isEmpty(result.field_session_category) ? context.t("_category_unavailable") : result.field_session_category}</td>

								</tr>
								<tr>
									<th>{context.t("_date")}</th>
									<td className="activityDetailDateRow p0">
										<table>
											<tbody>
												<tr>
													<th>{context.t("_start_date")}</th>
													<th>{context.t("_end_date")}</th>
												</tr>
												{
													result.field_start_and_end_time_3.map((item, index) => {
														return (
															<tr>
																<td>{`${getDateInFormat(item)} - ${getTimeInFormat(result.field_start_and_end_time[index])}`}</td>
																<td>{`${getDateInFormat(result.field_start_and_end_time_4[index])} - ${getTimeInFormat(result.field_start_and_end_time_1[index])}`}</td>
															</tr>
														)
													})
												}
											</tbody>
										</table>
									</td>
								</tr>
								{/* <tr>
									<th>{context.t("_start_date")}</th>
									<td>{_.isEmpty(result.field_start_and_end_time_3) ? context.t("_start_date_unavailable") : getDateInFormat(result.field_start_and_end_time_3)}</td>

								</tr>
								<tr>
									<th>{context.t("_end_date")}</th>
									<td>{_.isEmpty(result.field_start_and_end_time_4) ? context.t("_end_date_unavailable") : getDateInFormat(result.field_start_and_end_time_4)}</td>

								</tr> */}
								<tr>
									<th>{context.t("_venue")}</th>
									<td dangerouslySetInnerHTML={{ __html: _.isEmpty(result.field_activity_address) ? context.t("_venue_unavailable") : result.field_activity_address }}></td>

								</tr>
								<tr>
									<th>{context.t("_zone_address")}</th>
									<td dangerouslySetInnerHTML={{ __html: `${_.isEmpty(result.field_activity_address) ? context.t("_address_unavailable") : result.field_activity_address}` }}></td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className="col s6">
						<table>
							<tbody>
								<tr>
									<th>{context.t("_trainer_name")}</th>
									<td>{_.isEmpty(result.field_trainer_name) ? context.t("_trainer_name_unavailable") : result.field_trainer_name}</td>
								</tr>
								<tr>
									<th>{context.t("_age_group")}</th>
									<td>{_.isEmpty(result.age_group) ? context.t("_age_group_unavailable") : result.age_group}</td>
								</tr>
								<tr>
									<th>{context.t("_gender")}</th>
									<td>{_.isEmpty(result.gender) ? context.t("_gender_unavailable") : getGender(result.gender)}</td>
								</tr>
								<tr>
									<th>{context.t("_class_room")}</th>
									<td>{_.isEmpty(result.field_class_room) ? context.t("_class_room_unavailable") : result.field_class_room}</td>
								</tr>
								<tr>
									<th>{context.t("_no_of_attendee")}</th>
									<td>{_.isEmpty(result.attendees) ? context.t("_no_of_attendee_unavailable") : result.attendees}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<br />
				{/* <div className="equipment-use">
					<h4 style={{ position: "relative", marginBottom: -3 }}>Equipment Used</h4>
					<div className="input-field item">
						<input name="Title" placeholder="Equipment Used" type="text" readOnly value="Equipment" />
					</div>
				</div> */}
				{
					!_.isEmpty(result.field_materials) && <div className="materials-wrap">
						<h4>{context.t("_materials")}</h4>
						<div className="flex-row">
							{materials}
						</div>
					</div>
				}


				<div className="row machine-location">
					{/* <div className="col s6">
						<h4>Machines</h4>

					</div> */}

					<div className="col s12">
						{
							!_.isEmpty(result.field_google_map) &&
							<div className="location">
								<h4>{context.t("_location")}</h4>
								<div className="google-map">
									<iframe title="map" className={"map"} src={getMapEmbeddedUrl(result.field_google_map)} frameborder="0" allowfullscreen=""  ></iframe>
									{/* <Map google={google} className={"map"} initialCenter={{ lat: result.latitude, lng: result.longitude }} zoom={14}>
									<Marker
										// title={result.address}
										// name={"SOMA"}
										position={{ lat: result.latitude, lng: result.longitude }} />
								</Map> */}
									<br />
									<button onClick={() => handleOpenMap(result.field_google_map)} className="btn profileBtn blue-border">{context.t("_location_view")}</button>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}
const { config } = Main()
const configure = config()

SessionDetails.contextTypes = {
	t: PropTypes.func
}

export default GoogleApiWrapper({
	apiKey: configure.map_api_key
})(SessionDetails)
