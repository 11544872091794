import React from "react";
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import PageTitle from "../includes/page_title.js";
import SlideToTop from "../includes/slide_to_top.js";
import RegisterTypes from "./registerTypes"
import topLineImage from "../../assets/images/top-line.png"
import { useEffect } from "react";

// class Registration extends Main {
//   constructor(props) {
//     super(props);
//     this.state = {
//       selectedRole: "",
//       isTypeSelection: true,
//       stepIndex: 1
//     };
//   }
//   componentDidMount() {
//     document.title = `${this.context.t("_studio56")} | ${this.context.t("_registration")}`
//     this.isLoggedInUsers();
//   }
//   componentDidUpdate() {
//     document.title = `${this.context.t("_studio56")} | ${this.context.t("_registration")}`
//   }
//   render() {
//     return (
//       <div id="main-content" className="registration-page">
//         <SlideToTop />
//         <PageTitle title={this.context.t("_registration")} />
//         <div className="container">
//           <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
//             <ul>
//               <li className="breadcrumb-item">
//                 <Link tabIndex="0" to aria-current={this.context.t("_page")}>
//                   <span>{this.context.t("_registration")}</span>
//                 </Link>
//               </li>
//             </ul>
//           </nav>
//         </div>
//         <ColouredItems />
//         <div className="container registration-options">
//           <RegisterTypes {...this.props} />
//         </div>
//         <div className="top-line ng-scope">
//           <img src={topLineImage} alt="" />
//         </div>
//       </div>
//     );
//   }
// }
// const mapStateToProps = ({ i18nState }) => {
//   return {
//     lang: i18nState.lang
//   }
// }
// const mapDispatchToProps = dispatch => {
//   return {
//     dispatch
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Registration);

const Registration = (props, context) => {
  useEffect(()=>{
    document.title = `${context.t("_studio56")} | ${context.t("_registration")}`
  },[])
  return (
    <div id="main-content" className="registration-page">
      <SlideToTop />
      <PageTitle title={context.t("_registration")} />
      <div className="container">
        <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
          <ul>
            <li className="breadcrumb-item">
              <Link tabIndex="0" to aria-current={context.t("_page")}>
                <span>{context.t("_registration")}</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <ColouredItems />
      <div className="container registration-options">
        <RegisterTypes {...props} />
      </div>
      <div className="top-line ng-scope">
        <img src={topLineImage} alt="" />
      </div>
    </div>
  )
}

Registration.contextTypes = {
  t: PropTypes.func
}

export default Registration

const ColouredItems = () => {
  return (
    <div className="registration-color-container">
      <span className="grey-square-rotate red-sq one"></span>
      <span className="grey-square-rotate red-sq two"></span>
      <span className="orange-circle one"></span>
      <span className="orange-circle two"></span>
      <span className="multi-square one"><b><i></i></b></span>
      <span className="multi-square two"><b><i></i></b></span>
    </div>
  )
}