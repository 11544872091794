import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "lodash"
import Main from "../../constants/utilities.js";
import PageTitle from "../includes/page_title.js";
import SlideToTop from "../includes/slide_to_top.js";
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import M from "materialize-css";
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { PopupAction, LoadingAction } from "./../../actions"
import dateformat from "dateformat"

import topLineImage from "../../assets/images/top-line.png"
import { getGender, handleGradeName } from "./../dashboard/pages/utilFunctions"
import { useEffect } from "react";
import { useState } from "react";

// class SubscribeActivities extends Main {
//     constructor(props) {
//         super(props);
//         state = {
//             formData: {},
//             config: this.config(),
//             intervalId: 0,
//             scrollStepInPx: 50,
//             delayInMs: 16.66,
//             studentList: [],
//             checkedList: {},
//             checkAll: false
//         };
//         this.props.showLoader();
//     }
//     async componentDidMount() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_children")}`
//         await this.initState();
//         M.AutoInit();
//         var options = {}
//         setTimeout(() => {
//             var selectelem = document.querySelectorAll("select");
//             M.FormSelect.init(selectelem, options);
//         }, 1000);
//         this.getChildren()
//         window.addEventListener('load', this.handleTabIndex());
//     }
//     componentDidUpdate() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_children")}`
//     }
//     handleTabIndex = () => {
//         setTimeout(() => {
//             let elem = document.querySelectorAll('select');
//             elem.forEach(element => {
//                 element.setAttribute("tabindex", "0");
//             });
//         }, 1000);
//     }
//     initState = () => {
//         this.setState({
//             formData: {
//                 name: { value: "", error: "", class: "" },
//                 grade: { value: "", error: "", class: "" },
//                 gender: { value: "", error: "", class: "" },
//                 age: { value: "", error: "", class: "" }
//             }
//         })
//     }
//     calculateAgeGroup = (ageGroup) => {
//         switch (ageGroup) {
//             case "one_to_ten": {
//                 const dob = new Date();
//                 dob.setFullYear(dob.getFullYear() - Number(1));
//                 const minDate = `${dateformat(dob, "yyyy-mm-dd")}`
//                 dob.setFullYear(dob.getFullYear() - Number(10));
//                 const maxDate = `${dateformat(dob, "yyyy-mm-dd")}`

//                 return { minDate, maxDate }
//             }
//             case "eleven_to_twenty": {
//                 const dob = new Date();
//                 dob.setFullYear(dob.getFullYear() - Number(11));
//                 const minDate = `${dateformat(dob, "yyyy-mm-dd")}`
//                 dob.setFullYear(dob.getFullYear() - Number(20));
//                 const maxDate = `${dateformat(dob, "yyyy-mm-dd")}`

//                 return { minDate, maxDate }
//             }
//             case "twentyone_to_thirty": {
//                 const dob = new Date();
//                 dob.setFullYear(dob.getFullYear() - Number(21));
//                 const minDate = `${dateformat(dob, "yyyy-mm-dd")}`
//                 dob.setFullYear(dob.getFullYear() - Number(30));
//                 const maxDate = `${dateformat(dob, "yyyy-mm-dd")}`

//                 return { minDate, maxDate }
//             }

//             default:
//                 break;
//         }
//     }
//     getChildren = async () => {
//         // let authResponse = await this.auth();
//         const { formData } = this.state;
//         // if (authResponse) {
//         const { config } = this.state
//         let url = `${config.api_url}${urlEndPoints.myChildren}`

//         if (!_.isEmpty(formData.name.value))
//             url = `${url}&combine=${formData.name.value}`

//         if (!_.isEmpty(formData.gender.value))
//             url = `${url}&field_gender_value=${formData.gender.value}`

//         if (!_.isEmpty(formData.grade.value))
//             url = `${url}&field_grade_value=${formData.grade.value}`

//         if (!_.isEmpty(formData.age.value)) {
//             const ageGroup = this.calculateAgeGroup(formData.age.value)
//             url = `${url}&field_student_dob_value=${ageGroup.minDate}&field_student_dob_value_1=${ageGroup.maxDate}`
//         }

//         // let headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         try {
//             const data = await this.networkRequest(url)
//             if (!data.data.message) {
//                 this.checkStudentEnrolled(data.data.results)
//                 // this.setState({ studentList: data.data.results, checkedList: {} })
//             }

//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//         // }
//     }
//     checkStudentEnrolled = async (userList) => {
//         const { config } = this.state
//         let studentList = [];
//         const { match: { params: { id } } } = this.props
//         if (userList && userList.length > 0) {
//             userList.map(async (item, index) => {
//                 let url = `${config.api_url}${urlEndPoints.getActivityById(item.uid)}`
//                 try {
//                     const data = await this.networkRequest(url)
//                     if (_.has(data, "data") && data.data.length > 0) {
//                         const fieldActivity = _.find(data.data, ["field_activity_id", id]);
//                         if (!_.isEmpty(fieldActivity))
//                             item.fieldActivity = fieldActivity.field_published_status
//                         studentList.push(item)
//                         // if (_.find(data.data, "field_activity_id", id)) {
//                         //     if(data.data.)
//                         // }
//                         // if (!_.find(data.data, { field_activity_id: id })) {
//                         //     studentList.push(item)
//                         // }
//                     } else {
//                         studentList = userList;
//                     }
//                     studentList = _.orderBy(studentList, ["name"], ["asc"])
//                     await this.setState({ studentList: _.uniqBy(studentList), checkedList: {}, checkAll: false })
//                     this.props.hideLoader();
//                     setTimeout(() => {
//                         document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
//                     }, 500);
//                 } catch (error) {
//                     this.props.hideLoader()
//                     console.warn("error: ", error)
//                 }
//             })
//         } else {
//             await this.setState({ studentList: userList, checkedList: {} })
//             this.props.hideLoader();
//         }
//     }
//     handleChange = (field, value,) => {
//         const { formData } = this.state;
//         formData[field]["class"] = value ? "" : "invalid";
//         formData[field]["value"] = value;
//         this.setState({ formData });
//     };
//     calculateAge = (dateString) => {
//         var today = new Date();
//         var birthDate = new Date(dateString);
//         var age = today.getFullYear() - birthDate.getFullYear();
//         var m = today.getMonth() - birthDate.getMonth();
//         if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
//             age--;
//         }
//         return age;
//     }
//     handleCheck = (event, id, index) => {
//         const { checkedList } = this.state
//         if (event.target.checked)
//             checkedList[id] = id
//         else
//             delete checkedList[id]
//         this.setState({ checkedList })

//     }
//     handleSave = async () => {
//         const { checkedList } = this.state;
//         // return console.log(checkedList);
//         const { match: { params: { id } } } = this.props
//         const { lang } = this.props;
//         if (id) {
//             this.props.showLoader();
//             // let authResponse = await this.auth();
//             // if (authResponse) {
//             const { config } = this.state
//             let url = `${config.api_url}${urlEndPoints.activityEnrolment}`
//             // let headers = {
//             //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//             // }

//             let obj = {
//                 "activity_id": id,
//                 user_ids: checkedList
//             }
//             try {
//                 const data = await this.networkRequest(url, "POST", obj)
//                 // this.props.hideLoader();
//                 if (data.data.error) {
//                     // this.displayError(data.data.error)
//                     this.props.showPopup({
//                         type: data.data.type == "waiting_list" ? "success" : "error",
//                         title: data.data.type == "waiting_list" ? this.context.t("success") : this.context.t("_error"),
//                         message: data.data.type == "waiting_list" ? this.context.t("already_waiting_list") : `${data.data.error.name[lang]}`,
//                         isOpen: true,
//                         buttonText: this.context.t("_ok")
//                     })
//                 } else {
//                     let message = this.context.t("_enroll_success");
//                     if (localStorage.getItem("role") == "school") {
//                         message = `${message}. ${this.context.t("_admin_contact_you")}`
//                     }
//                     this.props.showPopup({
//                         type: "success",
//                         title: this.context.t("_success"),
//                         message,
//                         isOpen: true,
//                         buttonText: this.context.t("_ok")
//                     })
//                 }
//                 this.getChildren();
//                 this.setState({ checkedList: {}, })
//             } catch (error) {
//                 this.props.hideLoader()
//                 console.warn("error: ", error)
//             }
//             // } else {
//             //     this.props.hideLoader();
//             // }

//         }
//     }
//     displayError = error => {
//         const { lang } = this.props;
//         if (error.name) {
//             this.props.showPopup({
//                 type: "error",
//                 title: this.context.t("_failed"),
//                 message: error.name[lang],
//                 isOpen: true,
//                 buttonText: this.context.t("_retry")
//             })
//         }
//     }
//     onSubmit = (event) => {
//         this.props.showLoader()
//         event.preventDefault();
//         this.getChildren()
//     }
//     handleGoBack = () => {
//         this.props.history.goBack();
//     }
//     clearSearch = async () => {
//         this.props.showLoader()
//         const { formData } = this.state;
//         formData.name.value = ""
//         formData.grade.value = ""
//         formData.gender.value = ""
//         formData.age.value = ""

//         await this.setState({ formData })
//         this.getChildren()
//     }
//     handleCheckAll = (event) => {
//         const checkedList = {}
//         let checkAll = false;
//         const { studentList } = this.state;
//         if (event.target.checked) {
//             studentList.forEach(data => {
//                 if (data.fieldActivity == "Waiting List" || !data.fieldActivity)
//                     checkedList[data.uid] = data.uid
//             });
//             checkAll = true
//         }
//         this.setState({ checkedList, checkAll })

//     }
//     handleRedirectConnection = () => {

//         if (localStorage.getItem("role") == "parent") {
//             return "/parent-profile/participants"
//         } else {
//             return `/${localStorage.getItem("role")}-connections`;
//         }

//     }
//     render() {
//         const { studentList, checkedList, checkAll } = this.state;
//         return (
//             <>
//                 <div id="main-container">
//                     <SlideToTop />
//                     <PageTitle title={localStorage.getItem("role") == "school" || localStorage.getItem("role") == "school" ? this.context.t("_subscribe_with_activities_school") : this.context.t("_subscribe_with_activities")} />
//                     <div id="skipContent">
//                         <div className="container">
//                             {
//                                 // _.isEmpty(formData) ?
//                                 //     <div /> :
//                                 //     <div className="row topFormHolder">
//                                 //         <form onSubmit={this.onSubmit} className="row full-width">
//                                 //             <div className="col s12 m3 l3">
//                                 //                 <div className="input-field item">
//                                 //                     <label className="active" style={dynamicStyles.position(lang)}>{this.context.t("_search")} <span className="asterisk">*</span></label>
//                                 //                     <input
//                                 //                         onChange={evt =>
//                                 //                             this.handleChange("name", evt.target.value)
//                                 //                         }
//                                 //                         onFocus={evt =>
//                                 //                             this.handleChange("name", evt.target.value)
//                                 //                         }
//                                 //                         ref={(input) => { this.parentName = input; }}
//                                 //                         value={formData.name.value}
//                                 //                         placeholder={this.context.t("_search")}
//                                 //                         type="text"
//                                 //                     />
//                                 //                     <span className="helper-text"></span>
//                                 //                 </div>
//                                 //             </div>
//                                 //             <div className="col s12 m2 l">
//                                 //                 <div className="input-field item">
//                                 //                     <label style={dynamicStyles.position(lang)}>{this.context.t("_gender")}</label>
//                                 //                     <select
//                                 //                         className="browser-default"
//                                 //                         id="gender"
//                                 //                         name="gender"
//                                 //                         value={formData.gender.value}
//                                 //                         onChange={evt => this.handleChange("gender", evt.target.value)}
//                                 //                         onFocus={evt => this.handleChange("gender", evt.target.value)}
//                                 //                     >
//                                 //                         <option value="" disabled>{this.context.t("_select_gender")}</option>
//                                 //                         <option value="Male">{this.context.t("_male")}</option>
//                                 //                         <option value="Female">{this.context.t("_female")}</option>
//                                 //                     </select>
//                                 //                 </div>
//                                 //             </div>
//                                 //             <div className="col s12 m2 l2">
//                                 //                 <div className="input-field item">
//                                 //                     <label style={dynamicStyles.position(lang)}>{this.context.t("_age_group")}</label>
//                                 //                     <select
//                                 //                         className="browser-default"
//                                 //                         id="gender"
//                                 //                         name="gender"
//                                 //                         value={formData.age.value}
//                                 //                         onChange={evt => this.handleChange("age", evt.target.value)}
//                                 //                         onFocus={evt => this.handleChange("age", evt.target.value)}
//                                 //                     >
//                                 //                         <option value="" disabled>{this.context.t("_select_age_group")}</option>
//                                 //                         <option value="one_to_ten">{`1 ${this.context.t("_to")} 10`}</option>
//                                 //                         <option value="eleven_to_twenty">{`11 ${this.context.t("_to")} 20`}</option>
//                                 //                         <option value="twentyone_to_thirty">{`21 ${this.context.t("_to")} 30`}</option>
//                                 //                     </select>
//                                 //                 </div>
//                                 //             </div>
//                                 //             <div className="col s12 m2 l2">
//                                 //                 <div className="input-field item">
//                                 //                     <label style={dynamicStyles.position(lang)}>{this.context.t("_grade")}</label>

//                                 //                     <select
//                                 //                         className="browser-default"
//                                 //                         onChange={evt =>
//                                 //                             this.handleChange("grade", evt.target.value)
//                                 //                         }
//                                 //                         onFocus={evt =>
//                                 //                             this.handleChange("grade", evt.target.value)
//                                 //                         }
//                                 //                         ref={(input) => { this.grade = input; }}
//                                 //                         value={formData.grade.value}
//                                 //                     >
//                                 //                         <option value="" disabled >{this.context.t("_select_grade")}</option>
//                                 //                         {
//                                 //                             gradeListNew[lang].map((item, index) => {
//                                 //                                 return <option key={index} value={item.value}>{item.label}</option>
//                                 //                             })
//                                 //                         }
//                                 //                     </select>
//                                 //                 </div>
//                                 //             </div>
//                                 //             <div className="col s12 m3 l3 valign-wrapper center-align search-btn-holder">
//                                 //                 <button className="btn profileBtn yellow waves-effect waves-light">
//                                 //                     {this.context.t("_search")} <i className={`tiny material-icons ${lang}`} >{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
//                                 //                 </button>
//                                 //                 <button type="button" onClick={() => this.clearSearch()} className="btn noWidth  blue-btn waves-effect waves-light">
//                                 //                     {this.context.t("_clear")}
//                                 //                 </button>
//                                 //                 <br /><br />
//                                 //             </div>
//                                 //         </form>
//                                 //     </div>
//                             }
//                             <br />
//                             <div className="tableHolder">
//                                 {
//                                     studentList.length > 0 ?
//                                         <>
//                                             <table className="responsive-table highlight">
//                                                 <thead className="blue lighten-5">
//                                                     <tr>
//                                                         <th><strong>{this.context.t("_name")}</strong></th>
//                                                         <th><strong>{this.context.t("_qid")}</strong></th>
//                                                         <th><strong>{this.context.t("_phone_number")}</strong></th>
//                                                         <th><strong>{this.context.t("_grade")}</strong></th>
//                                                         <th><strong>{this.context.t("_gender")}</strong></th>
//                                                         <th><strong>{this.context.t("_age")}</strong></th>
//                                                         <th>
//                                                             {
//                                                                 studentList.filter(item => item.fieldActivity == "Waiting List" || !item.fieldActivity).length > 0 ? <p className="checkboxHolder checkboxNew"><input id="selectAllCheck" aria-label={this.context.t("_select_all_for_enrollment")} onChange={(e) => this.handleCheckAll(e)} checked={checkAll} type="checkbox" /><label for="selectAllCheck"><span style={{ fontSize: 16, margin: "0 7px", textTransform: "capitalize" }}>{this.context.t("_select_all")}</span></label></p> : <span style={{ display: "none" }}>{this.context.t("_select_all")}</span>
//                                                             }
//                                                         </th>
//                                                     </tr>
//                                                 </thead>

//                                                 <tbody>
//                                                     {
//                                                         studentList.map((item, index) => {
//                                                             return (
//                                                                 <tr key={index}>
//                                                                     <td>{item.field_student_name}</td>
//                                                                     <td>{item.field_qid}</td>
//                                                                     <td>{item.field_student_mobile}</td>
//                                                                     <td>{handleGradeName(item.field_grade, this.props.lang)}</td>
//                                                                     <td>{getGender(item.field_gender, this.context)}</td>
//                                                                     <td>{this.calculateAge(item.field_student_dob)}</td>
//                                                                     <td>
//                                                                         {
//                                                                             item.fieldActivity == "Published" ? this.context.t("_enrolled_successfully") : null
//                                                                         }
//                                                                         {
//                                                                             item.fieldActivity == "Unpublished" ? this.context.t("_pending") : null
//                                                                         }
//                                                                         {
//                                                                             (item.fieldActivity == "Waiting List" || !item.fieldActivity) ? <p className="checkboxHolder checkboxNew"><input aria-label={`${item.field_student_name} ${this.context.t("_select_for_enrollment")}`} id={`enroll${index}`} onChange={(e) => this.handleCheck(e, item.uid, index)} type="checkbox" checked={checkedList[item.uid] ? true : false} /><label for={`enroll${index}`}><span style={{ display: "none" }}>select</span></label></p> : null
//                                                                         }


//                                                                     </td>
//                                                                 </tr>
//                                                             )
//                                                         })
//                                                     }
//                                                 </tbody>
//                                             </table>
//                                             <br />
//                                             <div className="col s12">
//                                                 <button onClick={() => { this.handleSave() }} disabled={_.isEmpty(checkedList)} className="btn waves-effect waves-light blue-btn">{this.context.t("_enroll")}</button>
//                                             </div>
//                                         </>
//                                         :
//                                         <div className="page-not-found center">
//                                             <br />
//                                             <h4>{this.context.t("_no_relatives")}</h4>
//                                             {/* <button style={{ margin: "0 15px" }} onClick={() => this.handleGoBack()} className="btn profileBtn">{this.context.t("_go_back")}</button> */}
//                                             <Link to={this.handleRedirectConnection()} className="btn profileBtn waves-effect waves-light">{this.context.t("_add_relatives")}</Link>
//                                         </div>
//                                 }
//                             </div>
//                         </div>
//                     </div>
//                 </div >
//                 <div className="top-line ng-scope">
//                     <img src={topLineImage} alt="" />
//                 </div>
//             </>
//         )
//     }

// }

// const mapStateToProps = ({ i18nState }) => {
//     return {
//         lang: i18nState.lang
//     }
// }
// const mapDispatchToProps = {
//     showPopup: (data) => PopupAction.showPopup(data),
//     hidePopup: (data) => PopupAction.hidePopup(data),
//     showLoader: LoadingAction.showLoader,
//     hideLoader: LoadingAction.hideLoader,
// }
// SubscribeActivities.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(SubscribeActivities);


const SubscribeActivities = (props, context) => {
    const [formData, setFormData] = useState({
        name: { value: "", error: "", class: "" },
        grade: { value: "", error: "", class: "" },
        gender: { value: "", error: "", class: "" },
        age: { value: "", error: "", class: "" }
    });
    const [intervalId, setIntervalId] = useState(0);
    const [scrollStepInPx, setScrollStepInPx] = useState(50);
    const [delayInMs, setDelayInMs] = useState(16.66);
    const [studentList, setStudentList] = useState([]);
    const [checkedList, setCheckedList] = useState({});
    const [checkAll, setCheckAll] = useState(false);
    const { lang } = useSelector(state => state.i18nState)
    const dispatch = useDispatch()
    const { config, networkRequest } = Main()
    const configure = config()
    const { id } = useParams()
    const navigate = useNavigate()

    const handleTabIndex = () => {
        setTimeout(() => {
            let elem = document.querySelectorAll('select');
            elem.forEach(element => {
                element.setAttribute("tabindex", "0");
            });
        }, 1000);
    }
    const initState = () => {
        setFormData({
            name: { value: "", error: "", class: "" },
            grade: { value: "", error: "", class: "" },
            gender: { value: "", error: "", class: "" },
            age: { value: "", error: "", class: "" }
        })
    }
    const calculateAgeGroup = (ageGroup) => {
        switch (ageGroup) {
            case "one_to_ten": {
                const dob = new Date();
                dob.setFullYear(dob.getFullYear() - Number(1));
                const minDate = `${dateformat(dob, "yyyy-mm-dd")}`
                dob.setFullYear(dob.getFullYear() - Number(10));
                const maxDate = `${dateformat(dob, "yyyy-mm-dd")}`

                return { minDate, maxDate }
            }
            case "eleven_to_twenty": {
                const dob = new Date();
                dob.setFullYear(dob.getFullYear() - Number(11));
                const minDate = `${dateformat(dob, "yyyy-mm-dd")}`
                dob.setFullYear(dob.getFullYear() - Number(20));
                const maxDate = `${dateformat(dob, "yyyy-mm-dd")}`

                return { minDate, maxDate }
            }
            case "twentyone_to_thirty": {
                const dob = new Date();
                dob.setFullYear(dob.getFullYear() - Number(21));
                const minDate = `${dateformat(dob, "yyyy-mm-dd")}`
                dob.setFullYear(dob.getFullYear() - Number(30));
                const maxDate = `${dateformat(dob, "yyyy-mm-dd")}`

                return { minDate, maxDate }
            }

            default:
                break;
        }
    }
    const getChildren = async () => {
        // let authResponse = await auth();
        let url = `${configure.api_url}${urlEndPoints.myChildren}`

        if (!_.isEmpty(formData?.name?.value))
            url = `${url}&combine=${formData?.name?.value}`

        if (!_.isEmpty(formData?.gender?.value))
            url = `${url}&field_gender_value=${formData?.gender?.value}`

        if (!_.isEmpty(formData?.grade?.value))
            url = `${url}&field_grade_value=${formData?.grade?.value}`

        if (!_.isEmpty(formData?.age?.value)) {
            const ageGroup = calculateAgeGroup(formData?.age?.value)
            url = `${url}&field_student_dob_value=${ageGroup.minDate}&field_student_dob_value_1=${ageGroup.maxDate}`
        }

        // let headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await networkRequest(url)
            if (!data.data.message) {
                checkStudentEnrolled(data.data.results)
                // setState({ studentList: data.data.results, checkedList: {} })
            }

        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // }
    }
    const checkStudentEnrolled = async (userList) => {
        let studentListArr = [];
        if (userList && userList.length > 0) {
            userList.map(async (item, index) => {
                let url = `${configure.api_url}${urlEndPoints.getActivityById(item.uid)}`
                try {
                    const data = await networkRequest(url)
                    if (_.has(data, "data") && data.data.length > 0) {
                        const fieldActivity = _.find(data.data, ["field_activity_id", id]);
                        if (!_.isEmpty(fieldActivity))
                            item.fieldActivity = fieldActivity.field_published_status
                        studentListArr.push(item)
                        // if (_.find(data.data, "field_activity_id", id)) {
                        //     if(data.data.)
                        // }
                        // if (!_.find(data.data, { field_activity_id: id })) {
                        //     studentList.push(item)
                        // }
                    } else {
                        studentListArr = userList;
                    }
                    studentListArr = _.orderBy(studentListArr, ["name"], ["asc"])
                    setStudentList(_.uniqBy(studentListArr))
                    setCheckedList({})
                    setCheckAll(false)
                    dispatch(LoadingAction.hideLoader());
                    setTimeout(() => {
                        document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
                    }, 500);
                } catch (error) {
                    dispatch(LoadingAction.hideLoader())
                    console.warn("error: ", error)
                }
            })
        } else {
            setStudentList(userList)
            setCheckedList({})
            dispatch(LoadingAction.hideLoader());
        }
    }
    const handleChange = (field, value,) => {
        formData[field]["class"] = value ? "" : "invalid";
        formData[field]["value"] = value;
        setFormData({ ...formData });
    };
    const calculateAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    const handleCheck = (event, id, index) => {
        if (event.target.checked)
            checkedList[id] = id
        else
            delete checkedList[id]
        setCheckedList({ ...checkedList })
    }
    const handleSave = async () => {
        if (id) {
            dispatch(LoadingAction.showLoader());
            // let authResponse = await auth();
            // if (authResponse) {
            let url = `${configure.api_url}${urlEndPoints.activityEnrolment}`
            // let headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }

            let obj = {
                "activity_id": id,
                user_ids: checkedList
            }
            try {
                const data = await networkRequest(url, "POST", obj)
                // props.hideLoader();
                if (data.data.error) {
                    // displayError(data.data.error)
                    dispatch(PopupAction.showPopup({
                        type: data.data.type == "waiting_list" ? "success" : "error",
                        title: data.data.type == "waiting_list" ? context.t("success") : context.t("_error"),
                        message: data.data.type == "waiting_list" ? context.t("already_waiting_list") : `${data.data.error.name[lang]}`,
                        isOpen: true,
                        buttonText: context.t("_ok")
                    }))
                } else {
                    let message = context.t("_enroll_success");
                    if (localStorage.getItem("role") == "school") {
                        message = `${message}. ${context.t("_admin_contact_you")}`
                    }
                    dispatch(PopupAction.showPopup({
                        type: "success",
                        title: context.t("_success"),
                        message,
                        isOpen: true,
                        buttonText: context.t("_ok")
                    }))
                }
                getChildren();
                setCheckedList({})
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
            // } else {
            //     props.hideLoader();
            // }

        }
    }
    const displayError = error => {
        if (error.name) {
            dispatch(props.showPopup({
                type: "error",
                title: context.t("_failed"),
                message: error.name[lang],
                isOpen: true,
                buttonText: context.t("_retry")
            }))
        }
    }
    const onSubmit = (event) => {
        dispatch(LoadingAction.showLoader())
        event.preventDefault();
        getChildren()
    }
    const handleGoBack = () => {
        navigate(-1)
    }
    const clearSearch = async () => {
        dispatch(LoadingAction.showLoader())
        formData.name.value = ""
        formData.grade.value = ""
        formData.gender.value = ""
        formData.age.value = ""
        await setFormData({ ...formData })
        getChildren()
    }
    const handleCheckAll = (event) => {
        const updatedCheckedList = {};
        let updatedCheckAll = false;
        if (event.target.checked) {
            studentList.forEach(data => {
                if (data.fieldActivity == "Waiting List" || !data.fieldActivity)
                    updatedCheckedList[data.uid] = data.uid
            });
            updatedCheckAll = true
        }
        setCheckAll(updatedCheckAll)
        setCheckedList({ ...updatedCheckedList })
    }
    const handleRedirectConnection = () => {

        if (localStorage.getItem("role") == "parent") {
            return "/parent-profile/participants"
        } else {
            return `/${localStorage.getItem("role")}-connections`;
        }

    }

    useEffect(() => {
        dispatch(LoadingAction.showLoader())
        document.title = `${context.t("_studio56")} | ${context.t("_children")}`
        initState();
        M.AutoInit();
        var options = {}
        setTimeout(() => {
            var selectelem = document.querySelectorAll("select");
            M.FormSelect.init(selectelem, options);
        }, 1000);
        getChildren()
        window.addEventListener('load', handleTabIndex());
    }, [])
    return (
        <>
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={localStorage.getItem("role") == "school" || localStorage.getItem("role") == "school" ? context.t("_subscribe_with_activities_school") : context.t("_subscribe_with_activities")} />
                <div id="skipContent">
                    <div className="container">
                        {
                            // _.isEmpty(formData) ?
                            //     <div /> :
                            //     <div className="row topFormHolder">
                            //         <form onSubmit={onSubmit} className="row full-width">
                            //             <div className="col s12 m3 l3">
                            //                 <div className="input-field item">
                            //                     <label className="active" style={dynamicStyles.position(lang)}>{context.t("_search")} <span className="asterisk">*</span></label>
                            //                     <input
                            //                         onChange={evt =>
                            //                             handleChange("name", evt.target.value)
                            //                         }
                            //                         onFocus={evt =>
                            //                             handleChange("name", evt.target.value)
                            //                         }
                            //                         ref={(input) => { parentName = input; }}
                            //                         value={formData.name.value}
                            //                         placeholder={context.t("_search")}
                            //                         type="text"
                            //                     />
                            //                     <span className="helper-text"></span>
                            //                 </div>
                            //             </div>
                            //             <div className="col s12 m2 l">
                            //                 <div className="input-field item">
                            //                     <label style={dynamicStyles.position(lang)}>{context.t("_gender")}</label>
                            //                     <select
                            //                         className="browser-default"
                            //                         id="gender"
                            //                         name="gender"
                            //                         value={formData.gender.value}
                            //                         onChange={evt => handleChange("gender", evt.target.value)}
                            //                         onFocus={evt => handleChange("gender", evt.target.value)}
                            //                     >
                            //                         <option value="" disabled>{context.t("_select_gender")}</option>
                            //                         <option value="Male">{context.t("_male")}</option>
                            //                         <option value="Female">{context.t("_female")}</option>
                            //                     </select>
                            //                 </div>
                            //             </div>
                            //             <div className="col s12 m2 l2">
                            //                 <div className="input-field item">
                            //                     <label style={dynamicStyles.position(lang)}>{context.t("_age_group")}</label>
                            //                     <select
                            //                         className="browser-default"
                            //                         id="gender"
                            //                         name="gender"
                            //                         value={formData.age.value}
                            //                         onChange={evt => handleChange("age", evt.target.value)}
                            //                         onFocus={evt => handleChange("age", evt.target.value)}
                            //                     >
                            //                         <option value="" disabled>{context.t("_select_age_group")}</option>
                            //                         <option value="one_to_ten">{`1 ${context.t("_to")} 10`}</option>
                            //                         <option value="eleven_to_twenty">{`11 ${context.t("_to")} 20`}</option>
                            //                         <option value="twentyone_to_thirty">{`21 ${context.t("_to")} 30`}</option>
                            //                     </select>
                            //                 </div>
                            //             </div>
                            //             <div className="col s12 m2 l2">
                            //                 <div className="input-field item">
                            //                     <label style={dynamicStyles.position(lang)}>{context.t("_grade")}</label>

                            //                     <select
                            //                         className="browser-default"
                            //                         onChange={evt =>
                            //                             handleChange("grade", evt.target.value)
                            //                         }
                            //                         onFocus={evt =>
                            //                             handleChange("grade", evt.target.value)
                            //                         }
                            //                         ref={(input) => { grade = input; }}
                            //                         value={formData.grade.value}
                            //                     >
                            //                         <option value="" disabled >{context.t("_select_grade")}</option>
                            //                         {
                            //                             gradeListNew[lang].map((item, index) => {
                            //                                 return <option key={index} value={item.value}>{item.label}</option>
                            //                             })
                            //                         }
                            //                     </select>
                            //                 </div>
                            //             </div>
                            //             <div className="col s12 m3 l3 valign-wrapper center-align search-btn-holder">
                            //                 <button className="btn profileBtn yellow waves-effect waves-light">
                            //                     {context.t("_search")} <i className={`tiny material-icons ${lang}`} >{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
                            //                 </button>
                            //                 <button type="button" onClick={() => clearSearch()} className="btn noWidth  blue-btn waves-effect waves-light">
                            //                     {context.t("_clear")}
                            //                 </button>
                            //                 <br /><br />
                            //             </div>
                            //         </form>
                            //     </div>
                        }
                        <br />
                        <div className="tableHolder">
                            {
                                studentList.length > 0 ?
                                    <>
                                        <table className="responsive-table highlight">
                                            <thead className="blue lighten-5">
                                                <tr>
                                                    <th><strong>{context.t("_name")}</strong></th>
                                                    <th><strong>{context.t("_qid")}</strong></th>
                                                    <th><strong>{context.t("_phone_number")}</strong></th>
                                                    <th><strong>{context.t("_grade")}</strong></th>
                                                    <th><strong>{context.t("_gender")}</strong></th>
                                                    <th><strong>{context.t("_age")}</strong></th>
                                                    <th>
                                                        {
                                                            studentList.filter(item => item.fieldActivity == "Waiting List" || !item.fieldActivity).length > 0 ? <p className="checkboxHolder checkboxNew"><input id="selectAllCheck" aria-label={context.t("_select_all_for_enrollment")} onChange={(e) => handleCheckAll(e)} checked={checkAll} type="checkbox" /><label for="selectAllCheck"><span style={{ fontSize: 16, margin: "0 7px", textTransform: "capitalize" }}>{context.t("_select_all")}</span></label></p> : <span style={{ display: "none" }}>{context.t("_select_all")}</span>
                                                        }
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    studentList.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.field_student_name}</td>
                                                                <td>{item.field_qid}</td>
                                                                <td>{item.field_student_mobile}</td>
                                                                <td>{handleGradeName(item.field_grade, props.lang)}</td>
                                                                <td>{getGender(item.field_gender, context)}</td>
                                                                <td>{calculateAge(item.field_student_dob)}</td>
                                                                <td>
                                                                    {
                                                                        item.fieldActivity == "Published" ? context.t("_enrolled_successfully") : null
                                                                    }
                                                                    {
                                                                        item.fieldActivity == "Unpublished" ? context.t("_pending") : null
                                                                    }
                                                                    {
                                                                        (item.fieldActivity == "Waiting List" || !item.fieldActivity) ? <p className="checkboxHolder checkboxNew"><input aria-label={`${item.field_student_name} ${context.t("_select_for_enrollment")}`} id={`enroll${index}`} onChange={(e) => handleCheck(e, item.uid, index)} type="checkbox" checked={checkedList[item.uid] ? true : false} /><label for={`enroll${index}`}><span style={{ display: "none" }}>select</span></label></p> : null
                                                                    }


                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <br />
                                        <div className="col s12">
                                            <button onClick={() => { handleSave() }} disabled={_.isEmpty(checkedList)} className="btn waves-effect waves-light blue-btn">{context.t("_enroll")}</button>
                                        </div>
                                    </>
                                    :
                                    <div className="page-not-found center">
                                        <br />
                                        <h4>{context.t("_no_relatives")}</h4>
                                        {/* <button style={{ margin: "0 15px" }} onClick={() => handleGoBack()} className="btn profileBtn">{context.t("_go_back")}</button> */}
                                        <Link to={handleRedirectConnection()} className="btn profileBtn waves-effect waves-light">{context.t("_add_relatives")}</Link>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
            <div className="top-line ng-scope">
                <img src={topLineImage} alt="" />
            </div>
        </>
    )
}
SubscribeActivities.contextTypes = {
    t: PropTypes.func
}

export default SubscribeActivities
