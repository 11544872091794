// import React from "react";
// import { Link } from "react-router-dom";
// import Main from "../../main";
// import PageTitle from "../../includes/page_title.js";
// import SlideToTop from "../../includes/slide_to_top.js";
// import { connect } from "react-redux"
// import PropTypes from "prop-types"
// import { urlEndPoints } from "./../../../httpAPI/apiConfig"
// import _ from "lodash"
// import { LoadingAction, LoginAction } from "./../../../actions"
// import M from "materialize-css";
// import ReactCrop from "react-image-crop";
// import topLineImage from "../../../assets/images/top-line.png"
// import blankImage from "../../../assets/images/blank.png"
// import rewardPoint from "../../../assets/images/reward_point.png"
// import studentLevel from "../../../assets/images/student_level.png"
// import clockimg from "../../../assets/images/clock_bg.png"

// const usedSkills = ["skills", "software", "machines", "equipment"]
// class Reward extends Main {
//     constructor(props) {
//         super(props);
//         this.state = {
//             config: this.config(),
//             profileData: {
//                 picture: "",
//                 name: ""
//             },
//             rewardResponse: {}
//         }
//         this.props.showLoader()
//     }
//     componentDidMount() {
//         document.title = `${this.context.t("_studio56")} |`
//         M.AutoInit();
//         this.getProfileData()
//         this.getStudentReward()
//     }
//     componentDidUpdate() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_profile")}`
//     }
//     getProfileData = async () => {
//         const { match: { params: { id } } } = this.props;
//         // const authResponse = await this.auth();
//         // if (authResponse) {
//         const { config } = this.state
//         const url = `${config.api_url}${urlEndPoints.getDashboardStudentProfile(id)}`
//         // const headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         try {
//             const data = await this.networkRequest(url)
//             this.setState({ profileData: data.data })

//             this.props.hideLoader()
//             setTimeout(() => {
//                 document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
//             }, 500);
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//         // }
//     }
//     getStudentReward = async () => {
//         const { config } = this.state
//         const { match: { params: { id } } } = this.props;
//         const url = `${config.api_url}${urlEndPoints.getStudentReward}`
//         try {
//             const obj = {
//                 user_id: id,
//                 language: this.props.lang
//             }
//             const data = await this.networkRequest(url, "POST", obj)
//             if (data.status == 200) {
//                 this.setState({ rewardResponse: data.data.response })
//             }
//             console.log("data", data);

//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//     }

//     render() {
//         const { profileData, config, profileUpdateMessage, rewardResponse } = this.state;
//         const { match: { params: { id } } } = this.props;

//         return (
//             <>
//                 <div id="main-container">
//                     <SlideToTop />
//                     <PageTitle title={this.context.t("_reward_system")} />
//                     <div className="container">
//                         <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
//                             <ul>
//                                 <li className="breadcrumb-item">
//                                     <Link to={localStorage.getItem("role") == "parent" ? "/parent-profile" : "/profile"}>{this.context.t("_profile")}</Link> {" "}
//                                 </li>
//                                 {
//                                     localStorage.getItem("role") == "parent" && <li className="breadcrumb-item">
//                                         <Link to={`/student-profile/${id}`}>{this.context.t("_participants")}</Link> {" "}

//                                     </li>
//                                 }
//                                 <li className="breadcrumb-item">
//                                     <Link tabIndex="0" to aria-current={this.context.t("_page")}>
//                                         <span>{this.context.t("_reward_system")}</span>
//                                     </Link>
//                                 </li>
//                             </ul>
//                         </nav>
//                     </div>
//                     <div>
//                         <div id="skipContent" className="container profile-container">
//                             <div className="row">
//                                 <div className="col s12 text-center">
//                                     <figure className="profilePic">
//                                         <span>
//                                             <img src={!_.isEmpty(profileData.picture) ? `${config.origin}${profileData.picture}` : "../../assets/images/profile_pic.jpg"} alt={`${profileData.name}`} />
//                                         </span>
//                                     </figure>
//                                     <figure className="profilePic profileNew">
//                                         {
//                                             _.isEmpty(profileData.picture) ?
//                                                 <img src={blankImage} alt="" />
//                                                 :
//                                                 <>
//                                                     <img src={blankImage} alt="" />
//                                                     <span>
//                                                         <img src={`${config.origin}${profileData.picture}`} alt="" />
//                                                     </span>
//                                                 </>
//                                         }
//                                     </figure>
//                                     <h4 className="profileheading profileNew">{profileData.name}</h4>
//                                     {
//                                         !_.isEmpty(profileUpdateMessage) && <p tabIndex="0" ref="profileUpdateMessageRef" style={{ textAlign: "center" }}>{profileUpdateMessage}</p>
//                                     }
//                                 </div>

//                                 {
//                                     !_.isEmpty(rewardResponse) && <div className="badge-wrapper col s12">
//                                         <div className="row justify-content-center">
//                                             <div className="col">
//                                                 <div className="media d-inline-flex align-items-center">
//                                                     <div className="figure figure-points">
//                                                         <img src={rewardPoint} />
//                                                     </div>
//                                                     <div className="content">
//                                                         <p>{this.context.t("_total_points")}</p>
//                                                         <h4>{`${rewardResponse.point || 0} ${this.context.t("_points")}`}</h4>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                             <div className="col">
//                                                 <div className="media d-inline-flex align-items-center">
//                                                     <div className="figure figure-average">
//                                                         <img src={`${config.origin}${rewardResponse.image}`} />
//                                                     </div>
//                                                     <div className="content">
//                                                         <p>{this.context.t("_student_level")}</p>
//                                                         <h4>{rewardResponse.level}</h4>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 }
//                             </div>
//                             <div className="rewardZone">
//                                 <ul>
//                                     {rewardResponse?.zone_hours?.map((item, i) => <li>
//                                         <div class="rewardZone_zone">
//                                             <div class="rewardZone_zone_placeholder">
//                                                 <img src={item.type == "total_hours" ? clockimg : `${config.origin}${item.image}`} />
//                                             </div>
//                                             <div class="rewardZone_zone_content">
//                                                 <span>{item?.zone}</span>
//                                                 <div class="time">
//                                                     <span>{`${(this.props.lang == "en" && item?.hours <= 9) ? "0" : ""}${item?.hours}`}<small>{this.context.t("_hours")}</small></span>
//                                                     <span>{`${(this.props.lang == "en" && item?.minutes <= 9) ? "0" : ""}${item?.minutes}`}<small>{this.context.t("_minutes")}</small></span>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </li>)}
//                                 </ul>
//                             </div>

//                             {
//                                 !_.isEmpty(rewardResponse) && <div className="box-wrapper">
//                                     <div className="col s12 row">
//                                         {
//                                             usedSkills.map(skill => <div className="col s3">
//                                                 <div className="box-inner">
//                                                     <h5>{this.context.t(`_${skill}`)}</h5>
//                                                     {
//                                                         !_.isEmpty(rewardResponse[skill]) ? <ul>
//                                                             {
//                                                                 rewardResponse[skill].map(item => <li>{item}</li>)
//                                                             }
//                                                         </ul> : <p className="reward-not-found">{/* <i class="fa fa-thumbs-o-down"></i> */}
//                                                             {this.context.t(`_no_content_title_${skill}`)} <Link to="/activities">{this.context.t("_activities")}</Link></p>
//                                                     }
//                                                 </div>
//                                             </div>)
//                                         }
//                                     </div>
//                                 </div>
//                             }

//                         </div>
//                     </div>
//                 </div>
//                 <div className="top-line ng-scope">
//                     <img src={topLineImage} alt="" />
//                 </div>
//             </>
//         )
//     }

// }

// const mapStateToProps = ({ i18nState }) => {
//     return {
//         lang: i18nState.lang
//     }
// }
// const mapDispatchToProps = {
//     showLoader: LoadingAction.showLoader,
//     hideLoader: LoadingAction.hideLoader,
//     userLoggedIn: LoginAction.userLoggedIn,
// }
// Reward.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(Reward);


import React from "react";
import { Link, useParams } from "react-router-dom";
import Main from "../../../constants/utilities.js";
import PageTitle from "../../includes/page_title.js";
import SlideToTop from "../../includes/slide_to_top.js";
import {  useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import _ from "lodash"
import { LoadingAction, LoginAction } from "./../../../actions"
import M from "materialize-css";
import ReactCrop from "react-image-crop";
import topLineImage from "../../../assets/images/top-line.png"
import blankImage from "../../../assets/images/blank.png"
import rewardPoint from "../../../assets/images/reward_point.png"
import studentLevel from "../../../assets/images/student_level.png"
import clockimg from "../../../assets/images/clock_bg.png"
import { useState } from "react";
import { useEffect } from "react";

const usedSkills = ["skills", "software", "machines", "equipment"]
const Reward = (props, context) => {

    const { config, networkRequest } = Main();

    const [configuration, setConfiguration] = useState(config())
    const [profileData, setProfileData] = useState({
        picture: "",
        name: ""
    });
    const [rewardResponse, setRewardResponse] = useState({});
    const [profileUpdateMessage,setProfileUpdateMessage]=useState("")
    const { lang } = useSelector((state) => state.i18nState)
    const dispatch = useDispatch()

    const { id } = useParams()


    useEffect(() => {
        dispatch(LoadingAction.showLoader())
        document.title = `${context.t("_studio56")} |`
        M.AutoInit();
        getProfileData()
        getStudentReward()
    }, []);


    // componentDidUpdate() {
    //     document.title = `${context.t("_studio56")} | ${context.t("_profile")}`
    // }


    const getProfileData = async () => {
        // const authResponse = await this.auth();
        // if (authResponse) {
        const url = `${configuration.api_url}${urlEndPoints.getDashboardStudentProfile(id)}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await networkRequest(url)
            setProfileData(data.data)
            // setState({ profileData: data.data })

            dispatch(LoadingAction.hideLoader())
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // }
    }
    const getStudentReward = async () => {
        const url = `${configuration.api_url}${urlEndPoints.getStudentReward}`
        try {
            const obj = {
                user_id: id,
                language: lang
            }
            const data = await networkRequest(url, "POST", obj)
            if (data.status == 200) {
                setRewardResponse(data.data.response)
                // setState({ rewardResponse: data.data.response })
            }

        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }

    return (
        <>
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={context.t("_reward_system")} />
                <div className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to={localStorage.getItem("role") == "parent" ? "/parent-profile" : "/profile"}>{context.t("_profile")}</Link> {" "}
                            </li>
                            {
                                localStorage.getItem("role") == "parent" && <li className="breadcrumb-item">
                                    <Link to={`/student-profile/${id}`}>{context.t("_participants")}</Link> {" "}

                                </li>
                            }
                            <li className="breadcrumb-item">
                                <Link tabIndex="0" to aria-current={context.t("_page")}>
                                    <span>{context.t("_reward_system")}</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <div id="skipContent" className="container profile-container">
                        <div className="row">
                            <div className="col s12 text-center">
                                <figure className="profilePic">
                                    <span>
                                        <img src={!_.isEmpty(profileData.picture) ? `${configuration.origin}${profileData.picture}` : "../../assets/images/profile_pic.jpg"} alt={`${profileData.name}`} />
                                    </span>
                                </figure>
                                <figure className="profilePic profileNew">
                                    {
                                        _.isEmpty(profileData.picture) ?
                                            <img src={blankImage} alt="" />
                                            :
                                            <>
                                                <img src={blankImage} alt="" />
                                                <span>
                                                    <img src={`${configuration.origin}${profileData.picture}`} alt="" />
                                                </span>
                                            </>
                                    }
                                </figure>
                                <h4 className="profileheading profileNew">{profileData.name}</h4>
                                {
                                    !_.isEmpty(profileUpdateMessage) && <p tabIndex="0"  style={{ textAlign: "center" }}>{profileUpdateMessage}</p>
                                }
                            </div>

                            {
                                !_.isEmpty(rewardResponse) && <div className="badge-wrapper col s12">
                                    <div className="row justify-content-center">
                                        <div className="col">
                                            <div className="media d-inline-flex align-items-center">
                                                <div className="figure figure-points">
                                                    <img src={rewardPoint} />
                                                </div>
                                                <div className="content">
                                                    <p>{context.t("_total_points")}</p>
                                                    <h4>{`${rewardResponse.point || 0} ${context.t("_points")}`}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="media d-inline-flex align-items-center">
                                                <div className="figure figure-average">
                                                    <img src={`${configuration.origin}${rewardResponse.image}`} />
                                                </div>
                                                <div className="content">
                                                    <p>{context.t("_student_level")}</p>
                                                    <h4>{rewardResponse.level}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="rewardZone">
                            <ul>
                                {rewardResponse?.zone_hours?.map((item, i) => <li>
                                    <div class="rewardZone_zone">
                                        <div class="rewardZone_zone_placeholder">
                                            <img src={item.type == "total_hours" ? clockimg : `${configuration.origin}${item.image}`} />
                                        </div>
                                        <div class="rewardZone_zone_content">
                                            <span>{item?.zone}</span>
                                            <div class="time">
                                                <span>{`${(lang == "en" && item?.hours <= 9) ? "0" : ""}${item?.hours}`}<small>{context.t("_hours")}</small></span>
                                                <span>{`${(lang == "en" && item?.minutes <= 9) ? "0" : ""}${item?.minutes}`}<small>{context.t("_minutes")}</small></span>
                                            </div>
                                        </div>
                                    </div>
                                </li>)}
                            </ul>
                        </div>

                        {
                            !_.isEmpty(rewardResponse) && <div className="box-wrapper">
                                <div className="col s12 row">
                                    {
                                        usedSkills.map(skill => <div className="col s3">
                                            <div className="box-inner">
                                                <h5>{context.t(`_${skill}`)}</h5>
                                                {
                                                    !_.isEmpty(rewardResponse[skill]) ? <ul>
                                                        {
                                                            rewardResponse[skill].map(item => <li>{item}</li>)
                                                        }
                                                    </ul> : <p className="reward-not-found">{/* <i class="fa fa-thumbs-o-down"></i> */}
                                                        {context.t(`_no_content_title_${skill}`)} <Link to="/activities">{context.t("_activities")}</Link></p>
                                                }
                                            </div>
                                        </div>)
                                    }
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
            <div className="top-line ng-scope">
                <img src={topLineImage} alt="" />
            </div>
        </>
    )

}


const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
    userLoggedIn: LoginAction.userLoggedIn,
}
Reward.contextTypes = {
    t: PropTypes.func
}
export default Reward
