// import React from "react";
// import { Link } from "react-router-dom";
// import _ from "lodash"
// import Main from "../../main";
// import PageTitle from "../../includes/page_title.js";
// import SlideToTop from "../../includes/slide_to_top.js";
// import { connect } from "react-redux"
// import PropTypes from "prop-types"
// import DatePicker from "react-datepicker"
// import { dynamicStyles, customModalStyles } from "./../../../constants/dynamicStyles"
// import { urlEndPoints } from "./../../../httpAPI/apiConfig"
// import { qidValidate, nameValidate, emailValidate, phoneValidate, nameWordLengthCheck, calenderI18n, CustomDateInput, calenderLocal } from "./../../../constants/common"
// import M from "materialize-css";
// import { PopupAction, LoadingAction } from "./../../../actions"
// import Modal from "react-modal";
// import dateformat from "dateformat"
// import topLineImage from "../../../assets/images/top-line.png"

// class ParentConnections extends Main {
//     constructor(props) {
//         super(props);
//         this.state = {
//             formData: {},
//             studentFormData: {},
//             studentList: [],
//             config: this.config(),
//             intervalId: 0,
//             scrollStepInPx: 50,
//             delayInMs: 16.66,
//             removeStudentConfirm: false,
//             schoolList: []
//         };
//         this.props.showLoader();
//     }
//     async componentDidMount() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_manage_connections")}`
//         await this.initState();
//         this.getChildren();
//         this.getSchoolList();
//         M.AutoInit();
//         var options = {
//             autoClose: true,
//             dismissible: true
//         };
//         const dobOptions = {
//             autoClose: true,
//             onSelect: ((date) => {
//                 console.warn(date)
//                 this.handleChangeAddForm("dob", dateformat(date, "dd/mm/yyyy"))
//             }),
//             defaultDate: new Date("01/01/2001"),
//             setDefaultDate: new Date("01/01/2001"),
//             format: "dd/mm/yyyy",
//             container: null,
//             maxDate: new Date("12/31/2014"),
//             minDate: new Date("01/01/2001"),
//             yearRange: 20,
//             minYear: 2001,
//             maxYear: 2014
//         };
//         setTimeout(() => {
//             var elems = document.querySelectorAll("#dob");
//             dobOptions.i18n = calenderI18n(this.context);
//             // M.Datepicker.init(elems, dobOptions);
//             var elems = document.querySelectorAll(".modal");
//             M.Modal.init(elems, options);
//             const selectelem = document.querySelectorAll("select");
//             M.FormSelect.init(selectelem, options);

//         }, 200);
//         window.addEventListener('load', this.handleTabIndex());
//     }
//     componentDidUpdate() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_manage_connections")}`
//     }
//     handleTabIndex = () => {
//         setTimeout(() => {
//             let elem = document.querySelectorAll('select');
//             elem.forEach(element => {
//                 element.setAttribute("tabindex", "0");
//             });
//         }, 1000);
//     }
//     getSchoolList = async () => {
//         const { config } = this.state
//         const { lang } = this.props;
//         const url = `${config.api_url}${urlEndPoints.schoolList(lang)}`
//         try {
//             const data = await this.networkRequest(url)
//             this.setState({ schoolList: data.data })
//             // this.schoolName.focus()
//             this.initSelect()
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//     }
//     initSelect = () => {
//         const { formData } = this.state;
//         if (formData.schoolName) {
//             var options = {
//                 autoClose: true,
//                 classes: this.state.formData.schoolName.class

//             };
//             // var elems = document.querySelectorAll("select");
//             // M.FormSelect.init(elems, options);
//         }

//     }
//     initState = () => {
//         this.setState({
//             formData: {
//                 name: { value: "", error: "", class: "" },
//                 grade: { value: "", error: "", class: "" },
//                 gender: { value: "", error: "", class: "" }
//             },
//             studentFormData: {
//                 qid: { value: "", error: "", class: "" },
//                 schoolName: { value: "", error: "", class: "" },
//                 schoolNewName: { value: "", error: "", class: "" },
//                 name: { value: "", error: "", class: "" },
//                 email: { value: "", error: "", class: "" },
//                 mobile: { value: "", error: "", class: "" },
//                 gender: { value: "Male", error: "", class: "" },
//                 relation: { value: "", error: "", class: "" },
//                 dob: { value: "", error: "", class: "" },
//             }
//         })
//     }
//     handleChange = (field, value) => {
//         const { formData } = this.state;
//         formData[field]["class"] = value ? "" : "invalid";
//         formData[field]["value"] = value;
//         this.setState({ formData });
//     };
//     handleChangeAddForm = (field, value) => {
//         if (field == "qid" || field == "mobile")
//             if (isNaN(value))
//                 return false
//         const { studentFormData } = this.state;
//         studentFormData[field]["class"] = value ? "" : "invalid";
//         studentFormData[field]["value"] = value;
//         this.setState({ studentFormData });
//     };

//     getChildren = async () => {
//         // const authResponse = await this.auth();
//         const { formData } = this.state;
//         // if (authResponse) {
//         const { config } = this.state
//         let url = `${config.api_url}${urlEndPoints.myChildren}`

//         if (!_.isEmpty(formData.name.value))
//             url = `${url}&combine=${formData.name.value}`

//         // const headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         try {
//             const data = await this.networkRequest(url)
//             this.props.hideLoader();
//             if (!data.data.message) {
//                 this.setState({ studentList: data.data.results })
//                 await this.handleGetParent();
//             }
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//         // }
//     }
//     handleGetParent = async id => {
//         const { config, studentList } = this.state
//         studentList.map(async (item, index) => {
//             let url = `${config.api_url}${urlEndPoints.getParentName(item.uid)}`
//             try {
//                 const data = await this.networkRequest(url)
//                 if (_.has(data, "data.results") && data.data.results.length > 0)
//                     studentList[index].parents = data.data.results
//                 else
//                     studentList[index].parents = []

//                 this.setState({ studentList })
//             } catch (error) {
//                 this.props.hideLoader()
//                 console.warn("error: ", error)
//             }
//         });
//     }
//     calculateAge = (dateString) => {
//         var today = new Date();
//         var birthDate = new Date(dateString);
//         var age = today.getFullYear() - birthDate.getFullYear();
//         var m = today.getMonth() - birthDate.getMonth();
//         if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
//             age--;
//         }
//         return age;
//     }
//     handleCloseModal = () => {
//         this.initState();
//         let elems = document.querySelector("#checkQid");
//         elems.removeAttribute("aria-hidden")
//         elems.removeAttribute("tabindex")
//         let instance = M.Modal.getInstance(elems);
//         instance.close();
//         elems = document.querySelector("#addStudent");
//         elems.removeAttribute("aria-hidden")
//         elems.removeAttribute("tabindex")
//         instance = M.Modal.getInstance(elems);
//         instance.close();
//         this.checkQidForm.reset();
//         this.studentAddForm.reset();
//     }
//     validate = (type) => {
//         const { studentFormData } = this.state;
//         for (var key in studentFormData) {
//             studentFormData[key].value = studentFormData[key].value.trim();
//             studentFormData[key].error = "";
//         }
//         if (type == "qid") {
//             if (studentFormData.qid.value == "")
//                 studentFormData.qid.error = this.context.t("_qid_required");

//             if (studentFormData.qid.value != "" && !qidValidate.test(studentFormData.qid.value))
//                 studentFormData.qid.error = this.context.t("_invalid_qid");

//             if (studentFormData.relation.value == "")
//                 studentFormData.relation.error = this.context.t("_relationship_required");
//         } else {
//             if (studentFormData.name.value == "")
//                 studentFormData.name.error = this.context.t("_student_name_required");

//             if (studentFormData.name.value != "" && !nameValidate.test(studentFormData.name.value))
//                 studentFormData.name.error = this.context.t("_invalid_student_name");

//             if (studentFormData.name.value != "" && nameValidate.test(studentFormData.name.value) && !nameWordLengthCheck(studentFormData.name.value))
//                 studentFormData.name.error = this.context.t("_student_name_contains_atleast_two_word");

//             if (studentFormData.dob.value == "")
//                 studentFormData.dob.error = this.context.t("_date_of_birth_required");

//             if (studentFormData.email.value != "" && !emailValidate.test(studentFormData.email.value))
//                 studentFormData.email.error = this.context.t("_invalid_email");

//             if (studentFormData.mobile.value == "")
//                 studentFormData.mobile.error = this.context.t("_mobile_number_required");

//             if (studentFormData.mobile.value != "" && !phoneValidate.test(studentFormData.mobile.value.replace(/ /g, "")))
//                 studentFormData.mobile.error = this.context.t("_invalid_mobile_number");

//             if (studentFormData.mobile.value != "")
//                 if (studentFormData.mobile.value.toString().startsWith("3") || studentFormData.mobile.value.toString().startsWith("5") || studentFormData.mobile.value.toString().startsWith("6") || studentFormData.mobile.value.toString().startsWith("7")) {

//                 } else {
//                     studentFormData.mobile.error = this.context.t("_invalid_mobile_number");
//                 }

//             if (studentFormData.relation.value == "")
//                 studentFormData.relation.error = this.context.t("_relationship_required");
//         }

//         for (var key in studentFormData) {
//             if (studentFormData[key].error != "") {
//                 this.setState({ studentFormData, submissionStatus: false })
//                 // this.scrollToTop();
//                 // setTimeout(() => {
//                 //     this[key + "error"].focus()
//                 // }, 1000);
//                 return false;

//             }
//         }
//         return true;
//     }

//     displayError = error => {
//         const { studentFormData } = this.state;
//         const { lang } = this.props;
//         if (error.qid)
//             studentFormData.qid.error = error.qid[lang]

//         if (error.name)
//             studentFormData.name.error = error.name[lang]

//         if (error.mail)
//             studentFormData.email.error = error.mail[lang]

//         if (error.DOB)
//             studentFormData.dob.error = error.DOB[lang]

//         this.setState({ studentFormData })

//     }
//     handleOpenAddStudentModal = () => {
//         const { studentFormData } = this.state;
//         for (var key in studentFormData) {
//             studentFormData[key].error = "";
//         }
//         this.setState({ studentFormData })
//         let elems = document.querySelector("#checkQid");
//         elems.removeAttribute("aria-hidden")
//         elems.removeAttribute("tabindex")
//         let instance = M.Modal.getInstance(elems);
//         instance.close();
//         elems = document.querySelector("#addStudent");
//         elems.setAttribute("aria-hidden", "false")
//         elems.setAttribute("tabindex", "-1")
//         instance = M.Modal.getInstance(elems);
//         instance.open();
//         elems = document.querySelectorAll("select");
//         M.FormSelect.init(elems)
//     }
//     handleOpenQidCheck = () => {
//         const { studentFormData } = this.state;
//         for (var key in studentFormData) {
//             studentFormData[key].error = "";
//         }
//         this.setState({ studentFormData })
//         let options = {
//             inDuration: 1000,
//             opacity: 1
//         }
//         let elems = document.querySelector("#addStudent");
//         elems.setAttribute("aria-hidden", "false")
//         elems.setAttribute("tabindex", "-1")
//         let instance = M.Modal.getInstance(elems, options);
//         instance.open();
//     }
//     handleCheckQid = async (event) => {
//         // event.preventDefault();
//         const { studentFormData, config } = this.state;

//         if (this.validate("qid")) {
//             this.props.showLoader()
//             // const authResponse = await this.auth();
//             // if (authResponse) {
//             const obj = {
//                 field_qid: studentFormData.qid.value,
//                 field_relation_to_the_student: studentFormData.relation.value,
//             }
//             // const headers = {
//             //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//             // }
//             try {
//                 let url = `${config.api_url}${urlEndPoints.getParentCount(studentFormData.qid.value)}`
//                 let data = await this.networkRequest(url)
//                 if (_.has(data, "data.results") && data.data.results.length < 2) {
//                     url = `${config.api_url}${urlEndPoints.checkQid}`
//                     data = await this.networkRequest(url, "POST", obj)
//                     if (data.status == 200) {
//                         if (data.data.error) {
//                             this.props.hideLoader()
//                             this.displayError(data.data.error)
//                         }
//                         else {
//                             if (!data.data.user) {
//                                 return true;
//                                 // this.props.hideLoader()
//                                 // this.handleOpenAddStudentModal();
//                             }
//                             else {
//                                 this.props.showPopup({
//                                     type: "error",
//                                     // title: this.context.t("_warning"),
//                                     message: this.context.t("_student_already_exist_with_this_qid"),
//                                     isOpen: true,
//                                     buttonText: this.context.t("_ok")
//                                 })
//                                 this.handleCloseModal()
//                                 this.getChildren();
//                                 return false;
//                                 // this.getChildren();
//                                 // this.handleCloseModal()
//                             }
//                         }
//                     }
//                 } else {
//                     this.props.hideLoader()
//                     this.handleCloseModal()
//                     this.props.showPopup({
//                         type: "error",
//                         title: this.context.t("_failed"),
//                         message: this.context.t("_parent_limit_exceeds"),
//                         isOpen: true,
//                         buttonText: this.context.t("_ok")
//                     })
//                 }
//             } catch (error) {
//                 this.props.hideLoader()
//                 console.warn("error: ", error)
//             }
//             // }
//         }
//     }
//     onSubmit = (event) => {
//         event.preventDefault();
//         this.props.showLoader()
//         this.getChildren()
//     }
//     handleAddNewStudent = async (event) => {
//         event.preventDefault();
//         const { studentFormData, config, schoolList } = this.state;
//         if (this.validate()) {
//             this.props.showLoader()
//             const qidStatus = await this.handleCheckQid();
//             if (qidStatus) {


//                 // let schoolName = schoolList.filter(item => item.id == studentFormData.schoolName.value)
//                 // if (studentFormData.schoolName.value == "0")
//                 //     schoolName = studentFormData.schoolNewName.value
//                 // else
//                 //     schoolName = _.isEmpty(schoolName) ? null : schoolName[0].sname
//                 // const authResponse = await this.auth();
//                 // if (authResponse) {
//                 let obj = {
//                     // "mail": studentFormData.email.value || null,
//                     "name": studentFormData.email.value != "" ? studentFormData.email.value : studentFormData.name.value,
//                     // "school_id": {
//                     //     "target_id": studentFormData.schoolName.value,
//                     //     "target_type": "taxonomy_term"
//                     // },
//                     // "school_name": schoolName,
//                     "field_qid": studentFormData.qid.value,
//                     "field_student_mobile": `+974${studentFormData.mobile.value.replace(/ /g, "")}`,
//                     "roles": {
//                         "target_id": "student"
//                     },
//                     "field_relation_to_the_student": studentFormData.relation.value,
//                     "field_gender": studentFormData.gender.value,
//                     "field_student_name": studentFormData.name.value,
//                     "field_student_dob": studentFormData.dob.value,
//                     "field_reference": {
//                         "target_id": ""
//                     }
//                 }
//                 if (studentFormData.email.value != "") {
//                     obj.mail = studentFormData.email.value
//                     obj.field_student_email = studentFormData.email.value
//                 }
//                 const url = `${config.api_url}${urlEndPoints.parentAddStudent}`
//                 // const headers = {
//                 //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//                 // }
//                 try {
//                     const data = await this.networkRequest(url, "POST", obj)
//                     if (data.data.error) {
//                         this.displayError(data.data.error)
//                         this.props.hideLoader()
//                     }
//                     else {
//                         this.setState({ submissionStatus: true })
//                         this.handleCloseModal()
//                         this.getChildren();
//                     }
//                 } catch (error) {
//                     this.props.hideLoader()
//                     console.warn("error: ", error)
//                 }
//             }
//             // }
//         }

//     }
//     renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max }) => {
//         const { studentFormData } = this.state;
//         const { lang } = this.props;
//         return (type == "phone_number" ?
//             <div className="row mobile">
//                 <div className="col s4">
//                     <div className="input-field item">
//                         <input name={`${name}_country_code`}
//                             placeholder="Country Code"
//                             value={this.context.t("_ar_country_code")}
//                             style={dynamicStyles.textAlign(lang)}
//                             id={`${name}_country_code`}
//                             type="text"
//                             minLength="3"
//                             maxLength="3"
//                             disabled
//                         // className={"validate " + registration_form.mobile.class}
//                         // required
//                         />
//                         <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{this.context.t("_code")}</label>
//                         <span className="helper-text" data-error=""></span>
//                     </div>
//                 </div>
//                 <div className="col s8">
//                     <div className="input-field item">
//                         <input
//                             name={name}
//                             placeholder={placeholder}
//                             ref={(input) => { this[name] = input; }}
//                             aria-label={this.context.t("_phone_aria_label")}
//                             id={name}
//                             maxLength={max}
//                             type="text"
//                             style={dynamicStyles.textAlign(lang)}
//                             // readOnly={type === "date" ? "readonly" : false}
//                             autoComplete="no"
//                             onChange={evt =>
//                                 this.handleChangeAddForm(name, evt.target.value)
//                             }
//                             onFocus={evt =>
//                                 this.handleChangeAddForm(name, evt.target.value)
//                             }
//                             value={studentFormData[name].value}
//                         // className={"validate " + formData[name].class}
//                         // required={isMandatory}
//                         />
//                         <label htmlFor={name} style={dynamicStyles.position(lang)}>
//                             {`${label} `}
//                             {isMandatory ? <span className="asterisk">*</span> : null}
//                         </label>
//                         <span
//                             style={dynamicStyles.textAlign(lang)}
//                             className="helper-text"
//                             data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
//                         ></span>
//                     </div>
//                 </div>
//             </div>
//             : type == "date" ? <div className="input-field item">
//                 <DatePicker
//                     name={name}
//                     placeholderText={placeholder}
//                     ref={(input) => { this[name] = input; }}
//                     id={name}
//                     autoComplete="no"
//                     // className={styleClass}
//                     maxDate={new Date("12/31/2014")}
//                     minDate={new Date("01/01/2001")}
//                     dateFormat="yyyy-MM-dd"
//                     onChange={date =>
//                         this.handleChangeAddForm(name, date)
//                     }
//                     style={dynamicStyles.textAlign(lang)}
//                     value={studentFormData[name].value}
//                     selected={studentFormData[name].value}
//                     locale={calenderLocal(this.context)}
//                     customInput={<CustomDateInput ariaLabel={this.context.t("_select_dob_button")} onFieldChange={date => this.handleChangeAddForm(name, new Date(date))} />}
//                     onCalendarOpen={e => {
//                         let elems = document.querySelector(".react-datepicker");
//                         elems.setAttribute("tabindex", "0")
//                         elems.focus()
//                         elems = document.querySelector(".react-datepicker__month-select");
//                         elems.setAttribute("aria-label", this.context.t("_select_month"))
//                         elems = document.querySelector(".react-datepicker__year-select");
//                         elems.setAttribute("aria-label", this.context.t("_select_year"))

//                     }}
//                     onCalendarClose={e => {
//                         var elems = document.querySelector(".hiddenDob");
//                         elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(studentFormData[name].value, "dd-mm-yyyy")}`)
//                         elems.setAttribute("value", dateformat(studentFormData[name].value, "yyyy-mm-dd"))
//                         setTimeout(() => {
//                             elems.focus()
//                         }, 200);
//                     }}
//                     peekNextMonth
//                     showMonthDropdown
//                     showYearDropdown
//                     dropdownMode="select"
//                 />
//                 <label htmlFor={name} style={dynamicStyles.position(lang)}>
//                     {`${label} `}
//                     {isMandatory ? <span className="asterisk">*</span> : null}
//                 </label>
//                 <span
//                     style={dynamicStyles.textAlign(lang)}
//                     className="helper-text"
//                     data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
//                 ></span>
//             </div> :
//                 <div className="input-field item">
//                     <input
//                         name={name}
//                         placeholder={placeholder}
//                         ref={(input) => { this[name] = input; }}
//                         id={name}
//                         maxLength={max}
//                         type={type === "date" ? "text" : type}
//                         style={dynamicStyles.textAlign(lang)}
//                         // readOnly={type === "date" ? "readonly" : false}
//                         autoComplete="no"
//                         onChange={evt =>
//                             this.handleChangeAddForm(name, evt.target.value)
//                         }
//                         onFocus={evt =>
//                             this.handleChangeAddForm(name, evt.target.value)
//                         }
//                         value={studentFormData[name].value}
//                     // className={"validate " + formData[name].class}
//                     // required={isMandatory}
//                     />
//                     <label htmlFor={name} style={dynamicStyles.position(lang)}>
//                         {`${label} `}
//                         {isMandatory ? <span className="asterisk">*</span> : null}
//                     </label>
//                     <span
//                         style={dynamicStyles.textAlign(lang)}
//                         className="helper-text"
//                         data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
//                     ></span>
//                 </div>)
//     }

//     renderSelect = ({ name, label, placeholder, isMandatory = false, data = [], type = "select", disabled = false }) => {
//         const { studentFormData } = this.state;
//         const { lang } = this.props;
//         return (
//             <div className="input-field mobileSelect">
//                 <label htmlFor={name} style={dynamicStyles.position(lang)}>
//                     {`${label} `}
//                     {isMandatory ? <span className="asterisk">*</span> : null}
//                 </label>
//                 <select
//                     disabled={disabled}
//                     name={name}
//                     id={name}
//                     style={dynamicStyles.textAlign(lang)}
//                     ref={(input) => { this[name] = input; }}
//                     onChange={evt =>
//                         this.handleChangeAddForm(name, evt.target.value)
//                     }

//                     onFocus={evt =>
//                         this.handleChangeAddForm(name, evt.target.value)
//                     }
//                     value={studentFormData[name].value}
//                 // className={"validate" + studentFormData[name].class}
//                 >
//                     <option value="" disabled> {placeholder} </option>
//                     {
//                         data.map((item, index) => {
//                             return type == "country" ?
//                                 <option key={index} value={item.country_id}>{item.country[lang]}</option>
//                                 :
//                                 type == "school" ? <option key={index} value={item.id}>{item.sname}</option> :
//                                     <option key={index} value={item.key}>{item.label}</option>
//                         })
//                     }
//                     {
//                         type == "school" && <option value="0">{this.context.t("_not_in_list")}</option>
//                     }
//                 </select>
//                 <span
//                     style={dynamicStyles.textAlign(lang)}
//                     className="helper-text"
//                     data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
//                 ></span>
//             </div>
//         )
//     }
//     renderRadioButton = ({ data = [], name, label, defaultChecked, isMandatory = false }) => {
//         const { studentFormData } = this.state;
//         const { lang } = this.props;
//         return (
//             <div className="input-field item" style={{ width: 220 }}>
//                 <div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={name}>
//                     {
//                         data.map((item, index) => {
//                             return (
//                                 <div key={index} className={item.value}>
//                                     <input
//                                         aria-checked={studentFormData[name].value === item.value}
//                                         name={name}
//                                         id={item.value}
//                                         aria-labelledby={`${name} ${item.label}`}
//                                         type="radio"
//                                         readOnly
//                                         className="radio-btn"
//                                         onClick={evt =>
//                                             this.handleChangeAddForm(name, item.value)
//                                         }
//                                         checked={studentFormData[name].value === item.value}
//                                     />
//                                     <label id={`_${item.label}`} htmlFor={item.value} className="custom-radio-style">
//                                         <span className="custom-radio-style">{item.label}</span>
//                                     </label>
//                                 </div>
//                             )
//                         })
//                     }

//                 </div>
//                 <label htmlFor={name} style={dynamicStyles.position(lang)}>
//                     {`${label} `}
//                     {isMandatory ? <span className="asterisk">*</span> : null}
//                 </label>
//                 <span
//                     className="helper-text"
//                     data-error="Required field."
//                 ></span>
//             </div>
//         )
//     }
//     handleRemove = async id => {
//         this.props.showLoader()
//         const { config } = this.state;
//         // const authResponse = await this.auth();
//         // if (authResponse) {
//         let obj = {
//             "linked_user": id
//         }
//         const url = `${config.api_url}${urlEndPoints.removeLinking}`
//         // const headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         try {
//             const data = await this.networkRequest(url, "POST", obj)
//             if (data.data.error) {
//                 this.props.hideLoader()
//                 this.props.showPopup({
//                     type: "error",
//                     title: this.context.t("_failed"),
//                     message: this.context.t("_student_unlink_failed"),
//                     isOpen: true,
//                     buttonText: this.context.t("_ok")
//                 })
//             }
//             else {
//                 this.getChildren()
//             }
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//         // }
//     }
//     clearSearch = async () => {
//         this.props.showLoader()
//         const { formData } = this.state;
//         formData.name.value = ""
//         await this.setState({ formData })
//         this.getChildren()
//     }
//     handleViewProfile = id => {
//         this.props.history.push(`/student-profile/${id}`);
//     }
//     handleCloseConfirmModal = (state) => {
//         this.setState({ [state]: false, removeStudentId: null })
//     }
//     handleOpenRemoveStudentConfirm = id => {
//         this.setState({ removeStudentConfirm: true, removeStudentId: id })
//     }
//     handleConfirmRemoveSchool = () => {
//         const { removeStudentId } = this.state;
//         if (!_.isEmpty(removeStudentId))
//             this.handleRemove(removeStudentId);
//         this.handleCloseConfirmModal("removeStudentConfirm")
//     }
//     render() {
//         const { formData, studentList, removeStudentConfirm, studentFormData, schoolList } = this.state;
//         const { lang } = this.props;
//         let hasError = false;
//         Object.keys(studentFormData).map((item, index) => {
//             if (studentFormData[item].error != "")
//                 hasError = true
//         })
//         return (

//             <div id="main-container">
//                 <SlideToTop />
//                 <PageTitle title={this.context.t("_manage_connections")} />
//                 <div id="skipContent" className="container">
//                     <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
//                         <ul>
//                             <li className="breadcrumb-item">
//                                 <Link to="/profile">{this.context.t("_profile")}</Link> {" "}

//                             </li>
//                             <li className="breadcrumb-item">
//                                 <Link tabIndex="0" to aria-current={this.context.t("_page")}>
//                                     <span>
//                                         {this.context.t("_manage_connections")}
//                                     </span>
//                                 </Link>
//                             </li>
//                         </ul>
//                     </nav>
//                 </div>
//                 <div>
//                     <div className="container">
//                         <h2>{this.context.t("_children")}</h2>
//                         {/* {
//                             _.isEmpty(formData) ?
//                                 <div /> :
//                                 <div className="row topFormHolder">
//                                     <form onSubmit={this.onSubmit} className="row full-width">
//                                         <div className="col s12 m8 l9">
//                                             <div className="input-field item">
//                                                 <label className="active" style={dynamicStyles.position(lang)}>{this.context.t("_search")} <span className="asterisk">*</span></label>
//                                                 <input
//                                                     onChange={evt =>
//                                                         this.handleChange("name", evt.target.value)
//                                                     }
//                                                     onFocus={evt =>
//                                                         this.handleChange("name", evt.target.value)
//                                                     }
//                                                     ref={(input) => { this.parentName = input; }}
//                                                     value={formData.name.value}
//                                                     placeholder={this.context.t("_search")}
//                                                     type="text"
//                                                 />
//                                                 <span className="helper-text"></span>
//                                             </div>
//                                         </div>

//                                         <div className="col s12 m4 l3 valign-wrapper center-align search-btn-holder">
//                                             <button className="btn profileBtn yellow waves-effect waves-light">
//                                                 {this.context.t("_search")} <i className={`tiny material-icons ${lang}`} >{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
//                                             </button>
//                                             <button type="button" onClick={() => this.clearSearch()} className="btn noWidth  blue-btn waves-effect waves-light">
//                                                 {this.context.t("_clear")}
//                                             </button>
//                                             <br /><br />
//                                         </div>
//                                     </form>
//                                 </div>
//                         } */}

//                         <div className="tableHolder">
//                             {
//                                 _.isEmpty(studentList) ?
//                                     <div className="row">
//                                         <div className="col s12 no-result" style={{ textAlign: "center" }}>
//                                             <p>{this.context.t("_no_relative_yet")}</p>
//                                             <p>{this.context.t("_click_add_children")}</p>
//                                         </div>
//                                     </div>
//                                     :
//                                     <table className="responsive-table highlight">
//                                         <thead className="blue lighten-5">
//                                             <tr>
//                                                 <th><strong>{this.context.t("_name")}</strong></th>
//                                                 <th><strong>{this.context.t("_qid")}</strong></th>
//                                                 <th><strong>{this.context.t("_phone_number")}</strong></th>
//                                                 <th><strong>{this.context.t("_age")}</strong></th>
//                                                 {/* <th><strong>{this.context.t("_status")}</strong></th> */}
//                                                 <th><strong>{this.context.t("_parents")}</strong></th>
//                                                 <th width="80"><strong>{this.context.t("_profile")}</strong></th>
//                                                 <th width="80"><strong>{this.context.t("_delete")}</strong></th>
//                                             </tr>
//                                         </thead>

//                                         <tbody>

//                                             {
//                                                 studentList.map(item => {
//                                                     return (
//                                                         <tr key={item.uid}>
//                                                             <td>{item.field_student_name}</td>
//                                                             <td>{item.field_qid}</td>
//                                                             <td>{item.field_student_mobile}</td>
//                                                             <td>{this.calculateAge(item.field_student_dob)}</td>
//                                                             {/* <td>{item.status == "On" ? "Approved" : "Blocked"}</td> */}
//                                                             <td className="gaurdianText">
//                                                                 {item.parents ?
//                                                                     item.parents.length > 0 ? item.parents.map(itemParent => {
//                                                                         return <span key={itemParent.uid}>{itemParent.field_parent_name}<br /></span>
//                                                                     }) :
//                                                                         null
//                                                                     : null
//                                                                 }
//                                                             </td>
//                                                             <td>
//                                                                 <i onClick={() => this.handleViewProfile(item.uid)} className="material-icons attendanceClose tooltipped" data-tooltip={this.context.t("_view_profile")}>remove_red_eye</i>
//                                                             </td>
//                                                             <td>
//                                                                 <i onClick={() => this.handleOpenRemoveStudentConfirm(item.uid)} className="material-icons attendanceClose tooltipped" data-tooltip={this.context.t("_remove")}>delete_forever</i>
//                                                             </td>
//                                                         </tr>
//                                                     )
//                                                 })
//                                             }
//                                         </tbody>
//                                     </table>
//                             }

//                         </div>
//                         <button className="waves-effect waves-light btn profileBtn" onClick={() => { this.handleOpenQidCheck() }}>{this.context.t("_add_children")}</button>

//                         <div id="checkQid" className="modal sm" role="dialog">
//                             <div className="modal-content modal-no-close">
//                                 {
//                                     _.isEmpty(studentFormData) ?
//                                         <div /> :
//                                         <div>
//                                             <span className="badge modalClose"><a onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></a></span>
//                                             <h2 className="line">{this.context.t("_add_children")}</h2>
//                                             {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit.</p> */}
//                                             {hasError ? <div className="error-container fail">
//                                                 <h5>{this.context.t("_error_title")}</h5>
//                                                 <ul>
//                                                     {studentFormData.qid.error != "" ? <li ><label className="error" htmlFor="qid">{studentFormData.qid.error}</label></li> : null}
//                                                     {studentFormData.relation.error != "" ? <li ><label className="error" htmlFor="relation">{studentFormData.relation.error}</label></li> : null}
//                                                 </ul>
//                                             </div> : null
//                                             }
//                                             <form onSubmit={this.handleCheckQid} ref={(el) => this.checkQidForm = el} className="row full-width">
//                                                 <div className="col s12">
//                                                     <div className="input-field item">
//                                                         <input
//                                                             placeholder={this.context.t("_type_child_qid")}
//                                                             type="text"
//                                                             name="qid"
//                                                             id="qid"
//                                                             onChange={(evt) => this.handleChangeAddForm("qid", evt.target.value)}
//                                                             onFocus={(evt) => this.handleChangeAddForm("qid", evt.target.value)}
//                                                             value={studentFormData.qid.value}
//                                                             maxLength={11}
//                                                         />
//                                                         <label style={dynamicStyles.position(lang)}>{this.context.t("_student_qid")} <span className="asterisk">*</span></label>
//                                                     </div>

//                                                     <div className="input-field mobileSelect">
//                                                         <label style={dynamicStyles.position(lang)}>{this.context.t("_select_relation_1")}<span className="asterisk">*</span>
//                                                         </label>
//                                                         <select
//                                                             name="relation"
//                                                             onChange={(evt) => this.handleChangeAddForm("relation", evt.target.value)}
//                                                             value={studentFormData.relation.value}
//                                                         >
//                                                             <option value="" disabled> {this.context.t("_select_relation")} </option>
//                                                             <option value="Father">{this.context.t("_father")}</option>
//                                                             <option value="Mother">{this.context.t("_mother")}</option>
//                                                             <option value="Sister">{this.context.t("_sister")}</option>
//                                                             <option value="Brother">{this.context.t("_brother")}</option>
//                                                             <option value="Grandmother">{this.context.t("_grand_mother")}</option>
//                                                             <option value="Grandfather">{this.context.t("_grand_father")}</option>
//                                                             <option value="Guardian">{this.context.t("_parent")}</option>
//                                                         </select>
//                                                         <span
//                                                             style={dynamicStyles.textAlign(lang)}
//                                                             className="helper-text"
//                                                             data-error="Required field."
//                                                         ></span>
//                                                     </div>
//                                                 </div>

//                                                 <div className="col s12">
//                                                     <button type="button" onClick={this.handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{this.context.t("_cancel")}</button>
//                                                     <button className="btn profileBtn blue noWidth waves-effect waves-light">{this.context.t("_add")}</button>
//                                                 </div>
//                                             </form>
//                                         </div>
//                                 }
//                             </div>
//                         </div>
//                         <div id="addStudent" className="modal user-add-modal overflow-scroll" role="dialog">
//                             <div className="modal-content modal-no-close">
//                                 {
//                                     _.isEmpty(studentFormData) ?
//                                         <div /> :
//                                         <form onSubmit={this.handleAddNewStudent} ref={(el) => this.studentAddForm = el} className="col full-width">
//                                             <span className="badge modalClose"><a onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></a></span>
//                                             <h2 className="line">{this.context.t("_add_children")}</h2>

//                                             {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p> */}
//                                             {hasError ? <div className="error-container fail">
//                                                 <h5>{this.context.t("_error_title")}</h5>
//                                                 <ul>
//                                                     {
//                                                         Object.keys(studentFormData).map((item, index) => {
//                                                             return studentFormData[item].error != "" ? <li key={index} ><a ref={(refItem) => { this[item + "error"] = refItem; }} tabIndex="0" href={`#${item}`} className="error" htmlFor={item}>{formData[item].error}</a></li>
//                                                                 :
//                                                                 null
//                                                         })}
//                                                 </ul>
//                                             </div> : null
//                                             }
//                                             <p>{this.context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

//                                             <div className="row">
//                                                 <div className="col s12 m6">
//                                                     {this.renderInput({ name: "name", label: this.context.t("_student_name"), placeholder: this.context.t("_student_name_placeholder"), isMandatory: true })}
//                                                     {this.renderInput({ name: "qid", label: this.context.t("_student_qid"), placeholder: this.context.t("_type_child_qid"), isMandatory: true, max: 11 })}
//                                                     {this.renderInput({ name: "email", label: this.context.t("_email"), placeholder: this.context.t("_email_placeholder"), type: "email" })}
//                                                     {/* <div className="result-type" style={{ paddingTop: 28 }}>
//                                                         {
//                                                             this.renderSelect({
//                                                                 name: "schoolName", label: this.context.t("_school_name"), placeholder: this.context.t("_select_school_name"), data: schoolList, type: "school"
//                                                             })
//                                                         }
//                                                     </div> */}
//                                                     <div className="result-type">
//                                                         {
//                                                             this.renderSelect({
//                                                                 name: "relation", label: this.context.t("_relation"), placeholder: this.context.t("_select_relation"), isMandatory: true, data: [
//                                                                     { label: this.context.t("_father"), value: "Father" },
//                                                                     { label: this.context.t("_mother"), value: "Mother" },
//                                                                     { label: this.context.t("_sister"), value: "Sister" },
//                                                                     { label: this.context.t("_brother"), value: "Brother" },
//                                                                     { label: this.context.t("_grand_mother"), value: "Grandmother" },
//                                                                     { label: this.context.t("_grand_father"), value: "Grandfather" },
//                                                                     { label: this.context.t("_parent"), value: "Guardian" }
//                                                                 ]
//                                                             })
//                                                         }
//                                                     </div>
//                                                 </div>
//                                                 <div className="col s12 m6">
//                                                     <div className="date-popup">
//                                                         {this.renderInput({ name: "dob", label: this.context.t("_dob"), placeholder: this.context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
//                                                     </div>

//                                                     {this.renderInput({ name: "mobile", label: this.context.t("_mobile_number"), placeholder: this.context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max: 8 })}

//                                                     {/* {
//                                                         studentFormData.schoolName.value == "0" && this.renderInput({ name: "schoolNewName", label: this.context.t("_school_name"), placeholder: this.context.t("_select_school_name") })
//                                                     } */}
//                                                     {
//                                                         this.renderRadioButton({ label: this.context.t("_gender"), isMandatory: true, name: "gender", defaultChecked: "male", data: [{ label: this.context.t("_male"), value: "Male" }, { label: this.context.t("_female"), value: "Female" }] })
//                                                     }
//                                                 </div>
//                                                 <div className="col s12">
//                                                     <button className="btn profileBtn blue waves-effect waves-light">{this.context.t("_save_changes")}</button>
//                                                     <button type="button" onClick={() => this.handleCloseModal()} className="waves-effect waves-light btn profileBtn">{this.context.t("_close")}</button>
//                                                 </div>
//                                                 <div className="clearfix" />
//                                             </div>
//                                         </form>
//                                 }
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="top-line ng-scope">
//                     <img src={topLineImage} alt="" />
//                 </div>

//                 <Modal
//                     isOpen={removeStudentConfirm}
//                     style={customModalStyles}
//                     contentLabel="unlink parent confirm modal"
//                     ariaHideApp={false}
//                 >
//                     <div className="popupAlert warning">
//                         <br />
//                         <h3>{this.context.t("_confirm")}</h3>
//                         <p>{this.context.t("_remove_student_confirm")}</p>
//                         <div className="row">
//                             <button className="col btn blue" onClick={() => this.handleCloseConfirmModal("removeStudentConfirm")}>{this.context.t("_no")}</button>
//                             <button className="col btn profileBtn yellow" onClick={() => this.handleConfirmRemoveSchool()}>{this.context.t("_yes")}</button>
//                         </div>
//                     </div>
//                 </Modal>
//             </div>
//         )
//     }

// }

// const mapStateToProps = ({ i18nState }) => {
//     return {
//         lang: i18nState.lang
//     }
// }
// const mapDispatchToProps = {
//     showPopup: (data) => PopupAction.showPopup(data),
//     hidePopup: (data) => PopupAction.hidePopup(data),
//     showLoader: LoadingAction.showLoader,
//     hideLoader: LoadingAction.hideLoader,
// }
// ParentConnections.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(ParentConnections);


import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash"
import Main from "../../../constants/utilities.js";
import PageTitle from "../../includes/page_title.js";
import SlideToTop from "../../includes/slide_to_top.js";
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import { dynamicStyles, customModalStyles } from "./../../../constants/dynamicStyles"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import { qidValidate, nameValidate, emailValidate, phoneValidate, nameWordLengthCheck, calenderI18n, CustomDateInput, calenderLocal } from "./../../../constants/common"
import M from "materialize-css";
import { PopupAction, LoadingAction } from "./../../../actions"
import Modal from "react-modal";
import dateformat from "dateformat"
import topLineImage from "../../../assets/images/top-line.png"
import { useState } from "react";
import { useRef } from "react";

const ParentConnections = (props, context) => {

    const { config, networkRequest } = Main();

    const [formData, setFormData] = useState({});
    const [studentFormData, setStudentFormData] = useState({});
    const [studentList, setStudentList] = useState([]);
    const [intervalId, setIntervalId] = useState(0);
    const [scrollStepInPx] = useState(50);
    const [delayInMs] = useState(16.66);
    const [removeStudentConfirm, setRemoveStudentConfirm] = useState(false);
    const [schoolList, setSchoolList] = useState([]);
    const [submissionStatus, setSubmissionStatus] = useState(false);
    const [removeStudentId, setRemoveStudentId] = useState(null);


    const configuration=config()

    const { lang } = useSelector((state) => state.i18nState)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const checkQidFormRef = useRef(null);
    const studentAddFormRef = useRef(null);

    useEffect(() => {
        dispatch(LoadingAction.showLoader())


        const fetchData = async () => {
            document.title = `${context.t("_studio56")} | ${context.t("_manage_connections")}`
            await initState();
            getChildren();
            getSchoolList();
            M.AutoInit();
            var options = {
                autoClose: true,
                dismissible: true
            };
            const dobOptions = {
                autoClose: true,
                onSelect: ((date) => {
                    console.warn(date)
                    handleChangeAddForm("dob", dateformat(date, "dd/mm/yyyy"))
                }),
                defaultDate: new Date("01/01/2001"),
                setDefaultDate: new Date("01/01/2001"),
                format: "dd/mm/yyyy",
                container: null,
                maxDate: new Date("12/31/2014"),
                minDate: new Date("01/01/2001"),
                yearRange: 20,
                minYear: 2001,
                maxYear: 2014
            };
            setTimeout(() => {
                var elems = document.querySelectorAll("#dob");
                dobOptions.i18n = calenderI18n(context);
                // M.Datepicker.init(elems, dobOptions);
                var elems = document.querySelectorAll(".modal");
                M.Modal.init(elems, options);
                const selectelem = document.querySelectorAll("select");
                M.FormSelect.init(selectelem, options);

            }, 200);
            window.addEventListener('load', handleTabIndex());
        }
        fetchData()
    }, []);




    // componentDidUpdate() {
    //     document.title = `${this.context.t("_studio56")} | ${this.context.t("_manage_connections")}`
    // }
    const handleTabIndex = () => {
        setTimeout(() => {
            let elem = document.querySelectorAll('select');
            elem.forEach(element => {
                element.setAttribute("tabindex", "0");
            });
        }, 1000);
    }
    const getSchoolList = async () => {

        const url = `${configuration.api_url}${urlEndPoints.schoolList(lang)}`
        try {
            const data = await networkRequest(url)
            setSchoolList(data.data)
            // this.schoolName.focus()
            initSelect()
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
    const initSelect = () => {
        if (formData.schoolName) {
            var options = {
                autoClose: true,
                classes: formData.schoolName.class

            };
            // var elems = document.querySelectorAll("select");
            // M.FormSelect.init(elems, options);
        }

    }
    const initState = () => {
        setFormData({
            name: { value: "", error: "", class: "" },
            grade: { value: "", error: "", class: "" },
            gender: { value: "", error: "", class: "" }
        })
        setStudentFormData({
            qid: { value: "", error: "", class: "" },
            schoolName: { value: "", error: "", class: "" },
            schoolNewName: { value: "", error: "", class: "" },
            name: { value: "", error: "", class: "" },
            email: { value: "", error: "", class: "" },
            mobile: { value: "", error: "", class: "" },
            gender: { value: "Male", error: "", class: "" },
            relation: { value: "", error: "", class: "" },
            dob: { value: "", error: "", class: "" },
        })

    }
    const handleChange = (field, value) => {
        formData[field]["class"] = value ? "" : "invalid";
        formData[field]["value"] = value;
        setFormData({ ...formData })
        // this.setState({ formData });
    };
    const handleChangeAddForm = (field, value) => {
        if (field == "qid" || field == "mobile")
            if (isNaN(value))
                return false
        // const { studentFormData } = this.state;
        studentFormData[field]["class"] = value ? "" : "invalid";
        studentFormData[field]["value"] = value;
        setStudentFormData({ ...studentFormData })
        // this.setState({ studentFormData });
    };

    const getChildren = async () => {
        // const authResponse = await this.auth();
        // if (authResponse) {
        // dispatch(LoadingAction.hideLoader())

        let url = `${configuration.api_url}${urlEndPoints.myChildren}`

        if (!_.isEmpty(formData?.name?.value))
            url = `${url}&combine=${formData.name.value}`

        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await networkRequest(url)
            dispatch(LoadingAction.hideLoader())
            if (!data.data.message) {
                setStudentList(data.data.results)
                // this.setState({ studentList: data.data.results })
                await handleGetParent();
            }
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // }
    }



    const handleGetParent = async id => {
        studentList.map(async (item, index) => {
            let url = `${configuration.api_url}${urlEndPoints.getParentName(item.uid)}`
            try {
                const data = await networkRequest(url)
                if (_.has(data, "data.results") && data.data.results.length > 0)
                    studentList[index].parents = data.data.results
                else
                    studentList[index].parents = []
                setStudentList({ ...studentList })
                // this.setState({ studentList })
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
        });
    }
    const calculateAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    const handleCloseModal = () => {
        initState();
        let elems = document.querySelector("#checkQid");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addStudent");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        instance = M.Modal.getInstance(elems);
        instance.close();
        checkQidFormRef.current.reset();
        studentAddFormRef.current.reset();
    }
    const validate = (type) => {
        for (var key in studentFormData) {
            studentFormData[key].value = typeof (studentFormData[key].value) == "boolean" || key == "dob" ? studentFormData[key].value : studentFormData[key].value.trim();
            // studentFormData[key].value = studentFormData?.[key]?.value?.trim();
            studentFormData[key].error = "";
        }
        if (type == "qid") {
            if (studentFormData.qid.value == "")
                studentFormData.qid.error = context.t("_qid_required");

            if (studentFormData.qid.value != "" && !qidValidate.test(studentFormData.qid.value))
                studentFormData.qid.error = context.t("_invalid_qid");

            if (studentFormData.relation.value == "")
                studentFormData.relation.error = context.t("_relationship_required");
        } else {

            if (studentFormData.qid.value == "")
                studentFormData.qid.error = context.t("_qid_required");

            if (studentFormData.qid.value != "" && !qidValidate.test(studentFormData.qid.value))
                studentFormData.qid.error = context.t("_invalid_qid");

            if (studentFormData.name.value == "")
                studentFormData.name.error = context.t("_student_name_required");

            if (studentFormData.name.value != "" && !nameValidate.test(studentFormData.name.value))
                studentFormData.name.error = context.t("_invalid_student_name");

            if (studentFormData.name.value != "" && nameValidate.test(studentFormData.name.value) && !nameWordLengthCheck(studentFormData.name.value))
                studentFormData.name.error = context.t("_student_name_contains_atleast_two_word");

            if (studentFormData.dob.value == "")
                studentFormData.dob.error = context.t("_date_of_birth_required");

            if (studentFormData.email.value != "" && !emailValidate.test(studentFormData.email.value))
                studentFormData.email.error = context.t("_invalid_email");

            if (studentFormData.mobile.value == "")
                studentFormData.mobile.error = context.t("_mobile_number_required");

            if (studentFormData.mobile.value != "" && !phoneValidate.test(studentFormData.mobile.value.replace(/ /g, "")))
                studentFormData.mobile.error = context.t("_invalid_mobile_number");

            if (studentFormData.mobile.value != "")
                if (studentFormData.mobile.value.toString().startsWith("3") || studentFormData.mobile.value.toString().startsWith("5") || studentFormData.mobile.value.toString().startsWith("6") || studentFormData.mobile.value.toString().startsWith("7")) {

                } else {
                    studentFormData.mobile.error = context.t("_invalid_mobile_number");
                }

            if (studentFormData.relation.value == "")
                studentFormData.relation.error = context.t("_relationship_required");
        }

        for (var key in studentFormData) {
            if (studentFormData[key].error != "") {
                setStudentFormData({ ...studentFormData })
                setSubmissionStatus(false)
                // this.setState({ studentFormData, submissionStatus: false })
                // this.scrollToTop();
                // setTimeout(() => {
                //     this[key + "error"].focus()
                // }, 1000);
                return false;

            }
        }
        return true;
    }

    const displayError = error => {

        if (error.qid)
            studentFormData.qid.error = error.qid[lang]

        if (error.name)
            studentFormData.name.error = error.name[lang]

        if (error.mail)
            studentFormData.email.error = error.mail[lang]

        if (error.DOB)
            studentFormData.dob.error = error.DOB[lang]

        setStudentFormData({ ...studentFormData })

        // this.setState({ studentFormData })

    }
    const handleOpenAddStudentModal = () => {
        for (var key in studentFormData) {
            studentFormData[key].error = "";
        }
        // this.setState({ studentFormData })
        setStudentFormData({ ...studentFormData })

        let elems = document.querySelector("#checkQid");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addStudent");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        instance = M.Modal.getInstance(elems);
        instance.open();
        elems = document.querySelectorAll("select");
        M.FormSelect.init(elems)
    }
    const handleOpenQidCheck = () => {
        for (var key in studentFormData) {
            studentFormData[key].error = "";
        }
        // this.setState({ studentFormData })
        setStudentFormData({ ...studentFormData })

        let options = {
            inDuration: 1000,
            opacity: 1
        }
        let elems = document.querySelector("#addStudent");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        let instance = M.Modal.getInstance(elems, options);
        instance.open();
    }
    const handleCheckQid = async (event) => {
        // event.preventDefault();

        if (validate("qid")) {
            dispatch(LoadingAction.showLoader())
            // const authResponse = await this.auth();
            // if (authResponse) {
            const obj = {
                field_qid: studentFormData.qid.value,
                field_relation_to_the_student: studentFormData.relation.value,
            }
            // const headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            try {
                let url = `${configuration.api_url}${urlEndPoints.getParentCount(studentFormData.qid.value)}`
                let data = await networkRequest(url)
                if (_.has(data, "data.results") && data.data.results.length < 2) {
                    url = `${configuration.api_url}${urlEndPoints.checkQid}`
                    data = await networkRequest(url, "POST", obj)
                    if (data.status == 200) {
                        if (data.data.error) {
                            dispatch(LoadingAction.hideLoader())
                            displayError(data.data.error)
                        }
                        else {
                            if (!data.data.user) {
                                return true;
                                // dispatch(LoadingAction.hideLoader())
                                // handleOpenAddStudentModal();
                            }
                            else {
                                dispatch(PopupAction.showPopup({
                                    type: "error",
                                    // title: context.t("_warning"),
                                    message: context.t("_student_already_exist_with_this_qid"),
                                    isOpen: true,
                                    buttonText: context.t("_ok")
                                }))

                                handleCloseModal()
                                getChildren();
                                return false;
                                // getChildren();
                                // handleCloseModal()
                            }
                        }
                    }
                } else {
                    dispatch(LoadingAction.hideLoader())
                    handleCloseModal()
                    dispatch(PopupAction.showPopup({
                        type: "error",
                        title: context.t("_failed"),
                        message: context.t("_parent_limit_exceeds"),
                        isOpen: true,
                        buttonText: context.t("_ok")
                    }))

                }
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
            // }
        }
    }
    const onSubmit = (event) => {
        event.preventDefault();
        dispatch(LoadingAction.showLoader())
        getChildren()
    }
    const handleAddNewStudent = async (event) => {
        event.preventDefault();
        if (validate()) {
            dispatch(LoadingAction.showLoader())
            const qidStatus = await handleCheckQid();
            if (qidStatus) {


                // let schoolName = schoolList.filter(item => item.id == studentFormData.schoolName.value)
                // if (studentFormData.schoolName.value == "0")
                //     schoolName = studentFormData.schoolNewName.value
                // else
                //     schoolName = _.isEmpty(schoolName) ? null : schoolName[0].sname
                // const authResponse = await auth();
                // if (authResponse) {
                let obj = {
                    // "mail": studentFormData.email.value || null,
                    "name": studentFormData.email.value != "" ? studentFormData.email.value : studentFormData.name.value,
                    // "school_id": {
                    //     "target_id": studentFormData.schoolName.value,
                    //     "target_type": "taxonomy_term"
                    // },
                    // "school_name": schoolName,
                    "field_qid": studentFormData.qid.value,
                    "field_student_mobile": `+974${studentFormData.mobile.value.replace(/ /g, "")}`,
                    "roles": {
                        "target_id": "student"
                    },
                    "field_relation_to_the_student": studentFormData.relation.value,
                    "field_gender": studentFormData.gender.value,
                    "field_student_name": studentFormData.name.value,
                    "field_student_dob": studentFormData.dob.value,
                    "field_reference": {
                        "target_id": ""
                    }
                }
                if (studentFormData.email.value != "") {
                    obj.mail = studentFormData.email.value
                    obj.field_student_email = studentFormData.email.value
                }
                const url = `${configuration.api_url}${urlEndPoints.parentAddStudent}`
                // const headers = {
                //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
                // }
                try {
                    const data = await networkRequest(url, "POST", obj)
                    if (data.data.error) {
                        displayError(data.data.error)
                        dispatch(LoadingAction.hideLoader())
                    }
                    else {
                        setSubmissionStatus(true)
                        // setState({ submissionStatus: true })
                        handleCloseModal()
                        getChildren();
                    }
                } catch (error) {
                    dispatch(LoadingAction.hideLoader())
                    console.warn("error: ", error)
                }
            }
            // }
        }

    }
    const renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max }) => {

        return (type == "phone_number" ?
            <div className="row mobile">
                <div className="col s4">
                    <div className="input-field item">
                        <input name={`${name}_country_code`}
                            placeholder="Country Code"
                            value={context.t("_ar_country_code")}
                            style={dynamicStyles.textAlign(lang)}
                            id={`${name}_country_code`}
                            type="text"
                            minLength="3"
                            maxLength="3"
                            disabled
                        // className={"validate " + registration_form.mobile.class}
                        // required
                        />
                        <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{context.t("_code")}</label>
                        <span className="helper-text" data-error=""></span>
                    </div>
                </div>
                <div className="col s8">
                    <div className="input-field item">
                        <input
                            name={name}
                            placeholder={placeholder}
                            // ref={(input) => { this[name] = input; }}
                            aria-label={context.t("_phone_aria_label")}
                            id={name}
                            maxLength={max}
                            type="text"
                            style={dynamicStyles.textAlign(lang)}
                            // readOnly={type === "date" ? "readonly" : false}
                            autoComplete="no"
                            onChange={evt =>
                                handleChangeAddForm(name, evt.target.value)
                            }
                            onFocus={evt =>
                                handleChangeAddForm(name, evt.target.value)
                            }
                            value={studentFormData[name].value}
                        // className={"validate " + formData[name].class}
                        // required={isMandatory}
                        />
                        <label htmlFor={name} style={dynamicStyles.position(lang)}>
                            {`${label} `}
                            {isMandatory ? <span className="asterisk">*</span> : null}
                        </label>
                        <span
                            style={dynamicStyles.textAlign(lang)}
                            className="helper-text"
                            data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
                        ></span>
                    </div>
                </div>
            </div>
            : type == "date" ? <div className="input-field item">
                <DatePicker
                    name={name}
                    placeholderText={placeholder}
                    // ref={(input) => { this[name] = input; }}
                    id={name}
                    autoComplete="no"
                    // className={styleClass}
                    maxDate={new Date("12/31/2014")}
                    minDate={new Date("01/01/2001")}
                    dateFormat="yyyy-MM-dd"
                    onChange={date =>
                        handleChangeAddForm(name, date)
                    }
                    style={dynamicStyles.textAlign(lang)}
                    value={studentFormData[name].value}
                    selected={studentFormData[name].value}
                    locale={calenderLocal(context)}
                    customInput={<CustomDateInput ariaLabel={context.t("_select_dob_button")} onFieldChange={date => handleChangeAddForm(name, new Date(date))} />}
                    onCalendarOpen={e => {
                        let elems = document.querySelector(".react-datepicker");
                        elems.setAttribute("tabindex", "0")
                        elems.focus()
                        elems = document.querySelector(".react-datepicker__month-select");
                        elems.setAttribute("aria-label", context.t("_select_month"))
                        elems = document.querySelector(".react-datepicker__year-select");
                        elems.setAttribute("aria-label", context.t("_select_year"))

                    }}
                    onCalendarClose={e => {
                        var elems = document.querySelector(".hiddenDob");
                        elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(studentFormData[name].value, "dd-mm-yyyy")}`)
                        elems.setAttribute("value", dateformat(studentFormData[name].value, "yyyy-mm-dd"))
                        setTimeout(() => {
                            elems.focus()
                        }, 200);
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <span
                    style={dynamicStyles.textAlign(lang)}
                    className="helper-text"
                    data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
                ></span>
            </div> :
                <div className="input-field item">
                    <input
                        name={name}
                        placeholder={placeholder}
                        // ref={(input) => { this[name] = input; }}
                        id={name}
                        maxLength={max}
                        type={type === "date" ? "text" : type}
                        style={dynamicStyles.textAlign(lang)}
                        // readOnly={type === "date" ? "readonly" : false}
                        autoComplete="no"
                        onChange={evt =>
                            handleChangeAddForm(name, evt.target.value)
                        }
                        onFocus={evt =>
                            handleChangeAddForm(name, evt.target.value)
                        }
                        value={studentFormData[name].value}
                    // className={"validate " + formData[name].class}
                    // required={isMandatory}
                    />
                    <label htmlFor={name} style={dynamicStyles.position(lang)}>
                        {`${label} `}
                        {isMandatory ? <span className="asterisk">*</span> : null}
                    </label>
                    <span
                        style={dynamicStyles.textAlign(lang)}
                        className="helper-text"
                        data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
                    ></span>
                </div>)
    }

    const renderSelect = ({ name, label, placeholder, isMandatory = false, data = [], type = "select", disabled = false }) => {
        return (
            <div className="input-field mobileSelect">
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <select
                    disabled={disabled}
                    name={name}
                    id={name}
                    style={dynamicStyles.textAlign(lang)}
                    // ref={(input) => { this[name] = input; }}
                    onChange={evt =>
                        handleChangeAddForm(name, evt.target.value)
                    }

                    onFocus={evt =>
                        handleChangeAddForm(name, evt.target.value)
                    }
                    value={studentFormData[name].value}
                // className={"validate" + studentFormData[name].class}
                >
                    <option value="" disabled> {placeholder} </option>
                    {
                        data.map((item, index) => {
                            return type == "country" ?
                                <option key={index} value={item.country_id}>{item.country[lang]}</option>
                                :
                                type == "school" ? <option key={index} value={item.id}>{item.sname}</option> :
                                    <option key={index} value={item.key}>{item.label}</option>
                        })
                    }
                    {
                        type == "school" && <option value="0">{context.t("_not_in_list")}</option>
                    }
                </select>
                <span
                    style={dynamicStyles.textAlign(lang)}
                    className="helper-text"
                    data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
                ></span>
            </div>
        )
    }
    const renderRadioButton = ({ data = [], name, label, defaultChecked, isMandatory = false }) => {

        return (
            <div className="input-field item" style={{ width: 220 }}>
                <div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={name}>
                    {
                        data.map((item, index) => {
                            return (
                                <div key={index} className={item.value}>
                                    <input
                                        aria-checked={studentFormData[name].value === item.value}
                                        name={name}
                                        id={item.value}
                                        aria-labelledby={`${name} ${item.label}`}
                                        type="radio"
                                        readOnly
                                        className="radio-btn"
                                        onClick={evt =>
                                            handleChangeAddForm(name, item.value)
                                        }
                                        checked={studentFormData[name].value === item.value}
                                    />
                                    <label id={`_${item.label}`} htmlFor={item.value} className="custom-radio-style">
                                        <span className="custom-radio-style">{item.label}</span>
                                    </label>
                                </div>
                            )
                        })
                    }

                </div>
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <span
                    className="helper-text"
                    data-error="Required field."
                ></span>
            </div>
        )
    }
    const handleRemove = async id => {
        dispatch(LoadingAction.showLoader())
        // const authResponse = await auth();
        // if (authResponse) {
        let obj = {
            "linked_user": id
        }
        const url = `${configuration.api_url}${urlEndPoints.removeLinking}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await networkRequest(url, "POST", obj)
            if (data.data.error) {
                dispatch(LoadingAction.hideLoader())
                dispatch(PopupAction.showPopup({
                    type: "error",
                    title: context.t("_failed"),
                    message: context.t("_student_unlink_failed"),
                    isOpen: true,
                    buttonText: context.t("_ok")
                }))

            }
            else {
                getChildren()
            }
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // }
    }
    const clearSearch = async () => {
        dispatch(LoadingAction.showLoader())
        formData.name.value = ""
        setFormData({ ...formData })
        // await setState({ formData })
        getChildren()
    }
    const handleViewProfile = id => {
        navigate(`/student-profile/${id}`)
    }
    const handleCloseConfirmModal = (state) => {
        setRemoveStudentConfirm(false)
        setRemoveStudentId(null)
        // setState({ [state]: false, removeStudentId: null })
    }
    const handleOpenRemoveStudentConfirm = id => {
        // setState({ removeStudentConfirm: true, removeStudentId: id })
        setRemoveStudentConfirm(true)
        setRemoveStudentId(id)
    }
    const handleConfirmRemoveSchool = () => {
        // const { removeStudentId } = state;
        if (!_.isEmpty(removeStudentId))
            handleRemove(removeStudentId);
        handleCloseConfirmModal("removeStudentConfirm")
    }

    let hasError = false;
    Object.keys(studentFormData).map((item, index) => {
        if (studentFormData[item].error != "")
            hasError = true
    })
    return (

        <div id="main-container">
            <SlideToTop />
            <PageTitle title={context.t("_manage_connections")} />
            <div id="skipContent" className="container">
                <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                    <ul>
                        <li className="breadcrumb-item">
                            <Link to="/profile">{context.t("_profile")}</Link> {" "}

                        </li>
                        <li className="breadcrumb-item">
                            <Link tabIndex="0" to aria-current={context.t("_page")}>
                                <span>
                                    {context.t("_manage_connections")}
                                </span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div>
                <div className="container">
                    <h2>{context.t("_children")}</h2>
                    {/* {
                            _.isEmpty(formData) ?
                                <div /> :
                                <div className="row topFormHolder">
                                    <form onSubmit={onSubmit} className="row full-width">
                                        <div className="col s12 m8 l9">
                                            <div className="input-field item">
                                                <label className="active" style={dynamicStyles.position(lang)}>{context.t("_search")} <span className="asterisk">*</span></label>
                                                <input
                                                    onChange={evt =>
                                                        handleChange("name", evt.target.value)
                                                    }
                                                    onFocus={evt =>
                                                        handleChange("name", evt.target.value)
                                                    }
                                                    ref={(input) => { parentName = input; }}
                                                    value={formData.name.value}
                                                    placeholder={context.t("_search")}
                                                    type="text"
                                                />
                                                <span className="helper-text"></span>
                                            </div>
                                        </div>

                                        <div className="col s12 m4 l3 valign-wrapper center-align search-btn-holder">
                                            <button className="btn profileBtn yellow waves-effect waves-light">
                                                {context.t("_search")} <i className={`tiny material-icons ${lang}`} >{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
                                            </button>
                                            <button type="button" onClick={() => clearSearch()} className="btn noWidth  blue-btn waves-effect waves-light">
                                                {context.t("_clear")}
                                            </button>
                                            <br /><br />
                                        </div>
                                    </form>
                                </div>
                        } */}

                    <div className="tableHolder">
                        {
                            _.isEmpty(studentList) ?
                                <div className="row">
                                    <div className="col s12 no-result" style={{ textAlign: "center" }}>
                                        <p>{context.t("_no_relative_yet")}</p>
                                        <p>{context.t("_click_add_children")}</p>
                                    </div>
                                </div>
                                :
                                <table className="responsive-table highlight">
                                    <thead className="blue lighten-5">
                                        <tr>
                                            <th><strong>{context.t("_name")}</strong></th>
                                            <th><strong>{context.t("_qid")}</strong></th>
                                            <th><strong>{context.t("_phone_number")}</strong></th>
                                            <th><strong>{context.t("_age")}</strong></th>
                                            {/* <th><strong>{context.t("_status")}</strong></th> */}
                                            <th><strong>{context.t("_parents")}</strong></th>
                                            <th width="80"><strong>{context.t("_profile")}</strong></th>
                                            <th width="80"><strong>{context.t("_delete")}</strong></th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {
                                            studentList.map(item => {
                                                return (
                                                    <tr key={item.uid}>
                                                        <td>{item.field_student_name}</td>
                                                        <td>{item.field_qid}</td>
                                                        <td>{item.field_student_mobile}</td>
                                                        <td>{calculateAge(item.field_student_dob)}</td>
                                                        {/* <td>{item.status == "On" ? "Approved" : "Blocked"}</td> */}
                                                        <td className="gaurdianText">
                                                            {item.parents ?
                                                                item.parents.length > 0 ? item.parents.map(itemParent => {
                                                                    return <span key={itemParent.uid}>{itemParent.field_parent_name}<br /></span>
                                                                }) :
                                                                    null
                                                                : null
                                                            }
                                                        </td>
                                                        <td>
                                                            <i onClick={() => handleViewProfile(item.uid)} className="material-icons attendanceClose tooltipped" data-tooltip={context.t("_view_profile")}>remove_red_eye</i>
                                                        </td>
                                                        <td>
                                                            <i onClick={() => handleOpenRemoveStudentConfirm(item.uid)} className="material-icons attendanceClose tooltipped" data-tooltip={context.t("_remove")}>delete_forever</i>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                        }

                    </div>
                    <button className="waves-effect waves-light btn profileBtn" onClick={() => { handleOpenQidCheck() }}>{context.t("_add_children")}</button>

                    <div id="checkQid" className="modal sm" role="dialog">
                        <div className="modal-content modal-no-close">
                            {
                                _.isEmpty(studentFormData) ?
                                    <div /> :
                                    <div>
                                        <span className="badge modalClose"><a onClick={() => handleCloseModal()}><i className="material-icons">close</i></a></span>
                                        <h2 className="line">{context.t("_add_children")}</h2>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit.</p> */}
                                        {hasError ? <div className="error-container fail">
                                            <h5>{context.t("_error_title")}</h5>
                                            <ul>
                                                {studentFormData.qid.error != "" ? <li ><label className="error" htmlFor="qid">{studentFormData.qid.error}</label></li> : null}
                                                {studentFormData.relation.error != "" ? <li ><label className="error" htmlFor="relation">{studentFormData.relation.error}</label></li> : null}
                                            </ul>
                                        </div> : null
                                        }
                                        <form onSubmit={handleCheckQid} ref={checkQidFormRef} className="row full-width">
                                            <div className="col s12">
                                                <div className="input-field item">
                                                    <input
                                                        placeholder={context.t("_type_child_qid")}
                                                        type="text"
                                                        name="qid"
                                                        id="qid"
                                                        onChange={(evt) => handleChangeAddForm("qid", evt.target.value)}
                                                        onFocus={(evt) => handleChangeAddForm("qid", evt.target.value)}
                                                        value={studentFormData.qid.value}
                                                        maxLength={11}
                                                    />
                                                    <label style={dynamicStyles.position(lang)}>{context.t("_student_qid")} <span className="asterisk">*</span></label>
                                                </div>

                                                <div className="input-field mobileSelect">
                                                    <label style={dynamicStyles.position(lang)}>{context.t("_select_relation_1")}<span className="asterisk">*</span>
                                                    </label>
                                                    <select
                                                        name="relation"
                                                        onChange={(evt) => handleChangeAddForm("relation", evt.target.value)}
                                                        value={studentFormData.relation.value}
                                                    >
                                                        <option value="" disabled> {context.t("_select_relation")} </option>
                                                        <option value="Father">{context.t("_father")}</option>
                                                        <option value="Mother">{context.t("_mother")}</option>
                                                        <option value="Sister">{context.t("_sister")}</option>
                                                        <option value="Brother">{context.t("_brother")}</option>
                                                        <option value="Grandmother">{context.t("_grand_mother")}</option>
                                                        <option value="Grandfather">{context.t("_grand_father")}</option>
                                                        <option value="Guardian">{context.t("_parent")}</option>
                                                    </select>
                                                    <span
                                                        style={dynamicStyles.textAlign(lang)}
                                                        className="helper-text"
                                                        data-error="Required field."
                                                    ></span>
                                                </div>
                                            </div>

                                            <div className="col s12">
                                                <button type="button" onClick={handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{context.t("_cancel")}</button>
                                                <button className="btn profileBtn blue noWidth waves-effect waves-light">{context.t("_add")}</button>
                                            </div>
                                        </form>
                                    </div>
                            }
                        </div>
                    </div>
                    <div id="addStudent" className="modal user-add-modal overflow-scroll" role="dialog">
                        <div className="modal-content modal-no-close">
                            {
                                _.isEmpty(studentFormData) ?
                                    <div /> :
                                    <form onSubmit={handleAddNewStudent} ref={studentAddFormRef} className="col full-width">
                                        <span className="badge modalClose"><a onClick={() => handleCloseModal()}><i className="material-icons">close</i></a></span>
                                        <h2 className="line">{context.t("_add_children")}</h2>

                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p> */}
                                        {hasError ? <div className="error-container fail">
                                            <h5>{context.t("_error_title")}</h5>
                                            <ul>
                                                {
                                                    Object.keys(studentFormData).map((item, index) => {
                                                        return studentFormData[item].error != "" ? <li key={index} ><a tabIndex="0" href={`#${item}`} className="error" htmlFor={item}>{studentFormData?.[item]?.error}</a></li>
                                                            :
                                                            null
                                                    })}
                                            </ul>
                                        </div> : null
                                        }
                                        <p>{context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

                                        <div className="row">
                                            <div className="col s12 m6">
                                                {renderInput({ name: "name", label: context.t("_student_name"), placeholder: context.t("_student_name_placeholder"), isMandatory: true })}
                                                {renderInput({ name: "qid", label: context.t("_student_qid"), placeholder: context.t("_type_child_qid"), isMandatory: true, max: 11 })}
                                                {renderInput({ name: "email", label: context.t("_email"), placeholder: context.t("_email_placeholder"), type: "email" })}
                                                {/* <div className="result-type" style={{ paddingTop: 28 }}>
                                                        {
                                                            renderSelect({
                                                                name: "schoolName", label: context.t("_school_name"), placeholder: context.t("_select_school_name"), data: schoolList, type: "school"
                                                            })
                                                        }
                                                    </div> */}
                                                <div className="result-type">
                                                    {
                                                        renderSelect({
                                                            name: "relation", label: context.t("_relation"), placeholder: context.t("_select_relation"), isMandatory: true, data: [
                                                                { label: context.t("_father"), value: "Father" },
                                                                { label: context.t("_mother"), value: "Mother" },
                                                                { label: context.t("_sister"), value: "Sister" },
                                                                { label: context.t("_brother"), value: "Brother" },
                                                                { label: context.t("_grand_mother"), value: "Grandmother" },
                                                                { label: context.t("_grand_father"), value: "Grandfather" },
                                                                { label: context.t("_parent"), value: "Guardian" }
                                                            ]
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="col s12 m6">
                                                <div className="date-popup">
                                                    {renderInput({ name: "dob", label: context.t("_dob"), placeholder: context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
                                                </div>

                                                {renderInput({ name: "mobile", label: context.t("_mobile_number"), placeholder: context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max: 8 })}

                                                {/* {
                                                        studentFormData.schoolName.value == "0" && renderInput({ name: "schoolNewName", label: context.t("_school_name"), placeholder: context.t("_select_school_name") })
                                                    } */}
                                                {
                                                    renderRadioButton({ label: context.t("_gender"), isMandatory: true, name: "gender", defaultChecked: "male", data: [{ label: context.t("_male"), value: "Male" }, { label: context.t("_female"), value: "Female" }] })
                                                }
                                            </div>
                                            <div className="col s12">
                                                <button className="btn profileBtn blue waves-effect waves-light">{context.t("_save_changes")}</button>
                                                <button type="button" onClick={() => handleCloseModal()} className="waves-effect waves-light btn profileBtn">{context.t("_close")}</button>
                                            </div>
                                            <div className="clearfix" />
                                        </div>
                                    </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="top-line ng-scope">
                <img src={topLineImage} alt="" />
            </div>

            <Modal
                isOpen={removeStudentConfirm}
                style={customModalStyles}
                contentLabel="unlink parent confirm modal"
                ariaHideApp={false}
            >
                <div className="popupAlert warning">
                    <br />
                    <h3>{context.t("_confirm")}</h3>
                    <p>{context.t("_remove_student_confirm")}</p>
                    <div className="row">
                        <button className="col btn blue" onClick={() => handleCloseConfirmModal("removeStudentConfirm")}>{context.t("_no")}</button>
                        <button className="col btn profileBtn yellow" onClick={() => handleConfirmRemoveSchool()}>{context.t("_yes")}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )


}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showPopup: (data) => PopupAction.showPopup(data),
    hidePopup: (data) => PopupAction.hidePopup(data),
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
ParentConnections.contextTypes = {
    t: PropTypes.func
}
export default ParentConnections
