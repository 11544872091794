import React from "react"
import SlideToTop from "./slide_to_top.js"
import {  useSelector } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import footer_logo from "../../assets/images/footer-logo.png";

const Footer=(props,context)=>{
  const { lang } = useSelector((state) => state.i18nState)

  const year = new Date().getFullYear();
  return (
    <div id="footer" className="ng-scope">
      <div className="container">
        {/* <img src={footer_logo} alt={this.context.t("footer_logo_alt")} className="footer-logo" style={dynamicStyles.position(lang)}/> */}
        <div className="clearfix" />
        <div className="copy-text">{`${context.t("_footer_all_rights")}${year}`}</div>
        {
          lang == "ar" ? <a href="https://mada.org.qa/?lang=ar" target="_blank"><img src="https://monitor.mada.org.qa/acc/ar/N1EuEczqIXmhWhrWDdYIXALNseSGpmAc5vGQejSV4WhaNJ9gOpNDTU0hqGbI" alt="اعتماد المواقع الالكترونية الوطنية - مدى, اعتماد النفاذ الرقمي" /></a> : <a href="https://mada.org.qa/" target="_blank">
            <img src="https://monitor.mada.org.qa/acc/en/N1EuEczqIXmhWhrWDdYIXALNseSGpmAc5vGQejSV4WhaNJ9gOpNDTU0hqGbI" alt="Mada National Web Accreditation, Access Certified" />
          </a>
        }

      </div>
      <SlideToTop button="true" />
    </div>
  )
}

Footer.contextTypes = {
  t: PropTypes.func
}
export default Footer