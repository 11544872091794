import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import SlideToTop from "./includes/slide_to_top.js"
class Unauthorized extends Component {
	componentDidMount() {
		document.title = `${this.context.t("_studio56")} | ${this.context.t("_unauthorized_1")}`
	}
	componentDidUpdate() {
		document.title = `${this.context.t("_studio56")} | ${this.context.t("_unauthorized_1")}`
	}
	render() {
		return (<div id="main-content" className="page-not-found center unauthorized"><h4>{this.context.t("_unauthorized")}</h4></div>)
	}
}
const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
}
Unauthorized.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Unauthorized);
