import Main from "../../components/main"
import _ from "lodash"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { GET_TOTAL_ACTIVITIES, GET_CURRENT_ACTIVITIES, GET_COMPLETED_ACTIVITIES, GET_UPCOMING_ACTIVITIES } from "./../../constants/constants"

const MainConst = new Main();
const configConst = MainConst.config();
const getTotalActivities = ({ startDate = "", endDate = "" }) => {
    return async dispatch => {
        let url = `${configConst.origin}${urlEndPoints.getDashboardTotalActivities}?`
        if (!_.isEmpty(startDate))
            url = `${url}&field_start_and_end_time_value=${startDate} 00:00:00`
        if (!_.isEmpty(endDate))
            url = `${url}&field_start_and_end_time_end_value_1=${endDate} 00:00:00`
        MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_TOTAL_ACTIVITIES,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_TOTAL_ACTIVITIES,
                    payload: data.data.length
                })
            }
        })
    }
}
const getCurrentActivities = ({ startDate = "", endDate = "" }) => {
    return async dispatch => {
        let url = `${configConst.origin}${urlEndPoints.getDashboardCurrentActivities}?`
        if (!_.isEmpty(startDate))
            url = `${url}&field_start_and_end_time_value=${startDate} 00:00:00`
        if (!_.isEmpty(endDate))
            url = `${url}&field_start_and_end_time_end_value_1=${endDate} 00:00:00`
        MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_CURRENT_ACTIVITIES,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_CURRENT_ACTIVITIES,
                    payload: data.data.length
                })
            }
        })
    }
}
const getCompletedActivities = ({ startDate = "", endDate = "" }) => {
    return async dispatch => {
        let url = `${configConst.origin}${urlEndPoints.getDashboardCompletedActivities}?`
        if (!_.isEmpty(startDate))
            url = `${url}&field_start_and_end_time_value=${startDate} 00:00:00`
        if (!_.isEmpty(endDate))
            url = `${url}&field_start_and_end_time_end_value_1=${endDate} 00:00:00`
        MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_COMPLETED_ACTIVITIES,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_COMPLETED_ACTIVITIES,
                    payload: data.data.length
                })
            }
        })
    }
}
const getUpcomingActivities = ({ startDate = "", endDate = "" }) => {
    return async dispatch => {
        let url = `${configConst.origin}${urlEndPoints.getDashboardUpcomingActivities}?`
        if (!_.isEmpty(startDate))
            url = `${url}&field_start_and_end_time_value=${startDate} 00:00:00`
        if (!_.isEmpty(endDate))
            url = `${url}&field_start_and_end_time_end_value_1=${endDate} 00:00:00`
        MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_UPCOMING_ACTIVITIES,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_UPCOMING_ACTIVITIES,
                    payload: data.data.length
                })
            }
        })
    }
}

export default {
    getTotalActivities,
    getCurrentActivities,
    getCompletedActivities,
    getUpcomingActivities
}