import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Main from "../../constants/utilities"
import PageTitle from "../includes/page_title.js"
import SlideToTop from "../includes/slide_to_top.js"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import PropTypes from "prop-types"
import { LoadingAction } from "./../../actions"
import topLineImage from "../../assets/images/top-line.png";
const AboutUs = (props, context) => {
	const dispatch = useDispatch()
	const { config, networkRequest } = Main();
	const [id, setId] = useState("");
	const configure = config()
	const [media, setMedia] = useState([]);
	const [slider, setSlider] = useState([]);
	const [page_key, setPageKey] = useState("");
	const [aboutData, setAboutData] = useState({});
	const [isLoaded, setIsLoaded] = useState(false);
	const [visionData, setVisionData] = useState({});
	const [aboutUsData, setAboutUsData] = useState([]);
	const [missionData, setMissionData] = useState({});
	const [currentSlide, setCurrentSlide] = useState(0);
	const { lang } = useSelector(
		(state) => state.i18nState
	);
	useEffect(() => {
		document.title = `${context.t("_studio56")} | ${context.t("_about_us")}`
		async function fetchData() {
			await getAboutUS();
		}
		fetchData();
	}, []);


	const getAboutUS = async () => {
		;
		let url = ""
		if (lang == "ar")
			url = `${configure.origin}${lang}/api/${urlEndPoints.aboutPage}`
		else
			url = `${configure.origin}api/${urlEndPoints.aboutPage}`
		try {
			const data = await networkRequest(url)
			if (!data.data.error)
				setAboutData(data.data[0])
				dispatch(LoadingAction.hideLoader())
			setTimeout(() => {
				document.getElementById("pageHeading") && document.getElementById("pageHeading").focus()
			}, 1000);


			// else
			// 	return []
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}
	const getMission = async () => {
		let url = ""
		if (lang == "ar")
			url = `${configure.origin}${lang}/api/${urlEndPoints.ourMission}`
		else
			url = `${configure.origin}api/${urlEndPoints.ourMission}`

		try {
			const data = await networkRequest(url)
			if (!data.data.error)
				setMissionData(data.data[0])
			// else
			// 	return []
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}
	const getVision = async () => {
		let url = ""
		if (lang == "ar")
			url = `${configure.origin}${lang}/api/${urlEndPoints.ourVision}`
		else
			url = `${configure.origin}api/${urlEndPoints.ourVision}`

		try {
			const data = await networkRequest(url)
			if (!data.data.error)
				setVisionData(data.data[0])
			// else
			// 	return []
			dispatch(LoadingAction.hideLoader())
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}
	return (
		<>
			<div id="main-container">
				<SlideToTop />
				<PageTitle title={context.t("_about_us")} isDashboardItem={true} />
				<div id="skipContent" dangerouslySetInnerHTML={{ __html: aboutData.body }} />
			</div>

			<div className="top-line ng-scope"><img src={topLineImage} alt="" /></div>
		</>
	)

}

AboutUs.contextTypes = {
	t: PropTypes.func
}
export default AboutUs;
