import React, { useState, useEffect, useRef } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import dateformat from "dateformat";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"

import M from "materialize-css";
import DatePicker from "react-datepicker"
import Main from "../../constants/utilities"
import RegisterTypes from "./registerTypes"
import { LoadingAction } from "./../../actions"
import PageTitle from "../includes/page_title.js";
import "react-datepicker/dist/react-datepicker.css";
import SlideToTop from "../includes/slide_to_top.js";
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import topLineImage from "../../assets/images/top-line.png"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import { emailValidate, nameValidate, qidValidate, phoneValidate, nameWordLengthCheck, calenderI18n, CustomDateInput, calenderLocal } from "./../../constants/common"

const VolunteerRegistration = (props, context) => {
  const { config, networkRequest } = Main();
  const dispatch = useDispatch()
  const configure = config()
  const intervalId = 0;
  const delayInMs = 16.66
  const scrollStepInPx = 50;
  const [formData, setFormData] = useState({});
  const [termsAndCondition, setTermsAndCondition] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState(false);

  const { lang } = useSelector(
    state => state.i18nState
  );
  const successMessageRef = useRef(null);

  useEffect(() => {
    document.title = `${context.t("_studio56")} | ${context.t("_registration")} | ${context.t("_volunteer")}`
    initState();
    getTermsAndCondition();
    M.AutoInit();
    let today = new Date()
    today.setFullYear(today.getFullYear() - 18);
    var options = {
      autoClose: true,
      onSelect: ((date) => {
        handleChange("dob", dateformat(date, "dd/mm/yyyy"))
      }),
      defaultDate: new Date("01/01/2001"),
      format: "dd-mm-yyyy",
      container: null,
      minDate: new Date("01/01/1950"),
      yearRange: 20,
      maxDate: today,
      maxYear: 2002
    };
    setTimeout(() => {
      options.i18n = calenderI18n(context);
      var elems = document.querySelectorAll("#dob");
      options.i18n = calenderI18n(context);
      // M.Datepicker.init(elems, options);
    }, 1000);
  }, []);

  const getTermsAndCondition = async () => {
    let url = "";
    if (lang == "ar")
      url = `${configure.origin}${lang}/${urlEndPoints.termsAndCondition}`
    else
      url = `${configure.origin}${urlEndPoints.termsAndCondition}`
    try {
      const data = await networkRequest(url)
      if (data.status == 200 && data.data && data.data.length > 0) {
        setTermsAndCondition(data.data[0])
      }
    } catch (error) {
      dispatch(LoadingAction.hideLoader())
      console.warn("error: ", error)
    }
  }
  const initState = () => {
    setFormData({
      name: { value: "", error: "", class: "" },
      nameArabic: { value: "", error: "", class: "" },
      qid: { value: "", error: "", class: "" },
      gender: { value: "", error: "", class: "" },
      dob: { value: "", error: "", class: "" },
      email: { value: "", error: "", class: "" },
      mobile: { value: "", error: "", class: "" },
      channel: { value: "", error: "", class: "" },
      agree: { value: false, error: "", class: "" },
    })
  }
  const handleChange = (field, value) => {
    if (field == "qid")
      if (isNaN(value))
        return false
    const updatedFormData = { ...formData };
    updatedFormData[field]["class"] = value ? "" : "invalid";
    updatedFormData[field]["value"] = value;
    setFormData(updatedFormData)
    setSubmissionStatus(false)
  };
  const renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max, styleClass = "" }) => {
    return (type == "phone_number" ?
      <div className="row mobile">
        <div className="col s4">
          <div className="input-field item">
            <input name={`${name}_country_code`}
              placeholder="Country Code"
              value={context.t("_ar_country_code")}
              id={`${name}_country_code`}
              type="text"
              className={styleClass}
              minLength="3"
              maxLength="3"
              disabled
            // className={"validate " + registration_form.mobile.class}
            // required
            />
            <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{context.t("_country_code")}</label>
            <span className="helper-text" data-error="Enter a valid code."></span>
          </div>
        </div>
        <div className="col s8">
          <div className="input-field item">
            <input
              name={name}
              placeholder={placeholder}
              id={name}
              className={styleClass}
              maxLength={max}
              type="text"
              aria-label={context.t("_phone_aria_label")}
              // readOnly={type === "date" ? "readonly" : false}
              autoComplete="no"
              onChange={evt =>
                handleChange(name, evt.target.value)
              }
              onFocus={evt =>
                handleChange(name, evt.target.value)
              }
              value={formData[name].value}
            // className={"validate " + formData[name].class}
            // required={isMandatory}
            />
            <label htmlFor={name} style={dynamicStyles.position(lang)}>
              {`${label} `}
              {isMandatory ? <span className="asterisk">*</span> : null}
            </label>
            <span
              className="helper-text"
              data-error={formData[name].error ? formData[name].error : "Required field."}
            ></span>
          </div>
        </div>
      </div>
      : type == "date" ? <div className="input-field item">
        <DatePicker
          name={name}
          placeholderText={placeholder}
          id={name}
          autoComplete="off"
          className={styleClass}
          // maxDate={new Date("12/31/2014")}
          minDate={new Date("01/01/1950")}
          dateFormat="yyyy-MM-dd"
          onChange={date =>
            handleChange(name, date)
          }
          style={dynamicStyles.textAlign(lang)}
          value={formData[name].value}
          selected={formData[name].value}
          locale={calenderLocal(context)}
          customInput={<CustomDateInput ariaLabel={context.t("_select_dob_button")} onFieldChange={date => handleChange(name, new Date(date))} />}
          onCalendarOpen={e => {
            let elems = document.querySelector(".react-datepicker");
            elems.setAttribute("tabindex", "0")
            elems.focus()
            elems = document.querySelector(".react-datepicker__month-select");
            elems.setAttribute("aria-label", context.t("_select_month"))
            elems = document.querySelector(".react-datepicker__year-select");
            elems.setAttribute("aria-label", context.t("_select_year"))

          }}
          onCalendarClose={e => {
            var elems = document.querySelector(".hiddenDob");
            elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(formData[name].value, "dd-mm-yyyy")}`)
            elems.setAttribute("value", dateformat(formData[name].value, "yyyy-mm-dd"))
            setTimeout(() => {
              elems.focus()
            }, 200);
          }}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
        <label htmlFor={name} style={dynamicStyles.position(lang)}>
          {`${label} `}
          {isMandatory ? <span className="asterisk">*</span> : null}
        </label>
        <span
          style={dynamicStyles.textAlign(lang)}
          className="helper-text"
          data-error={formData[name].error ? formData[name].error : "Required field."}
        ></span>
      </div> :
        <div className="input-field item">
          <input
            name={name}
            placeholder={placeholder}
            id={name}
            className={styleClass}
            maxLength={max}
            type={type === "date" ? "text" : type}
            // readOnly={type === "date" ? "readonly" : false}
            autoComplete="no"
            onChange={evt =>
              handleChange(name, evt.target.value)
            }
            onFocus={evt =>
              handleChange(name, evt.target.value)
            }
            value={formData[name].value}
          // className={"validate " + formData[name].class}
          // required={isMandatory}
          />
          <label htmlFor={name} style={dynamicStyles.position(lang)}>
            {`${label} `}
            {isMandatory ? <span className="asterisk">*</span> : null}
          </label>
          <span
            className="helper-text"
            data-error={formData[name].error ? formData[name].error : "Required field."}
          ></span>
        </div>)
  }
  const renderRadioButton = ({ data = [], name, label, defaultChecked, isMandatory = false }) => {
    return (
      <div className="input-field item">
        <div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={name}>
          {
            data.map((item, index) => {
              return (
                <div key={index} className={item.value}>
                  <input
                    aria-checked={formData[name].value === item.value}
                    name={name}
                    id={item.value}
                    aria-labelledby={`${name} ${item.label}`}
                    type="radio"
                    readOnly
                    className="radio-btn"
                    onClick={evt =>
                      handleChange(name, item.value)
                    }
                    checked={formData[name].value === item.value}
                  />
                  <label id={`_${item.label}`} htmlFor={item.value} className="custom-radio-style">
                    <span className="custom-radio-style">{item.label}</span>
                  </label>
                </div>
              )
            })
          }

        </div>
        <label htmlFor={name} id={name} style={dynamicStyles.position(lang)}>
          {`${label} `}
          {isMandatory ? <span className="asterisk">*</span> : null}
        </label>
        <span
          className="helper-text"
          data-error="Required field."
        ></span>
      </div>
    )
  }
  const renderCheckbox = ({ label, name }) => {
    return (
      <div className="item subscribe-items">
        <p>
          <input type="checkbox"
            name={name}
            id={name}
            style={dynamicStyles.textAlign(lang)}
            onChange={(event) => handleChange(name, event.target.checked)}
            checked={formData[name].value}
          />
          <label for={name}>
            <span className={lang}>{context.t("_i_agree")}
              <button type="button" style={{ border: 0, background: "none" }} aria-label={context.t("_read_terms_condition")} tabindex="0" className="modal-trigger underline-text" onClick={() => handleOpenModal()}>{label}</button>
            </span>
          </label>
        </p>
      </div>

    )
  }
  const scrollStep = () => {
    var scrollStepInPx = scrollStepInPx;
    if (window.pageYOffset <= 400) {
      clearInterval(intervalId);
    }
    window.scroll(0, window.pageYOffset - scrollStepInPx);
  }
  const scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" })
    // var delayInMs = props.delayInMs ? props.delayInMs : state.delayInMs;
    // let intervalId = setInterval(scrollStep.bind(this), delayInMs);
    // setState({ intervalId: intervalId });
  }
  const validate = () => {
    const updatedFormData = { ...formData };
    for (var key in updatedFormData) {
      updatedFormData[key].value = typeof (updatedFormData[key].value) == "boolean" || key == "dob" ? updatedFormData[key].value : updatedFormData[key].value.trim();
      updatedFormData[key].error = "";
    }
    if (updatedFormData.name.value == "")
      updatedFormData.name.error = context.t("_volunteer_name_required");

    if (updatedFormData.name.value != "" && !nameValidate.test(updatedFormData.name.value))
      updatedFormData.name.error = context.t("_invalid_volunteer_name");

    if (updatedFormData.name.value != "" && nameValidate.test(updatedFormData.name.value) && !nameWordLengthCheck(updatedFormData.name.value))
      updatedFormData.name.error = context.t("_volunteer_name_contains_atleast_two_word");

    if (updatedFormData.nameArabic.value != "" && nameValidate.test(updatedFormData.nameArabic.value) && !nameWordLengthCheck(updatedFormData.nameArabic.value))
      updatedFormData.nameArabic.error = context.t("_volunteer_name_contains_atleast_two_word");

    if (updatedFormData.qid.value == "")
      updatedFormData.qid.error = context.t("_qid_required");

    if (updatedFormData.qid.value != "" && !qidValidate.test(updatedFormData.qid.value))
      updatedFormData.qid.error = context.t("_invalid_qid");

    if (updatedFormData.gender.value == "")
      updatedFormData.gender.error = context.t("_gender_required");

    if (updatedFormData.dob.value == "")
      updatedFormData.dob.error = context.t("_date_of_birth_required");

    if (updatedFormData.email.value == "")
      updatedFormData.email.error = context.t("_email_required");

    if (updatedFormData.email.value != "" && !emailValidate.test(updatedFormData.email.value))
      updatedFormData.email.error = context.t("_invalid_email");

    if (updatedFormData.mobile.value == "")
      updatedFormData.mobile.error = context.t("_mobile_number_required");

    if (updatedFormData.mobile.value != "" && !phoneValidate.test(updatedFormData.mobile.value.replace(/ /g, "")))
      updatedFormData.mobile.error = context.t("_invalid_mobile_number");

    // if (updatedFormData.channel.value == "")
    //   updatedFormData.channel.error = context.t("_channel_of_communication_required");

    if (!(updatedFormData.agree.value))
      updatedFormData.agree.error = context.t("_terms_and_condition_agree");

    for (var key in updatedFormData) {
      if (updatedFormData[key].error != "") {

        setFormData(updatedFormData)
        setSubmissionStatus(false)
        scrollToTop();
        setTimeout(() => {
          document.querySelector(`a[href="#${key}"]`).focus()
        }, 1000);
        return false;

      }
    }
    setFormData(updatedFormData)
    return true;
  }
  const displayError = error => {
    const updatedFormData = { ...formData };

    if (error.mail)
      updatedFormData.email.error = error.mail[lang]

    if (error.field_volenteer_mobile)
      updatedFormData.mobile.error = error.field_volenteer_mobile[lang]

    if (error.qid)
      updatedFormData.qid.error = error.qid[lang]

    if (error.dob)
      updatedFormData.dob.error = error.dob[lang]

    for (var key in updatedFormData) {
      if (updatedFormData[key].error != "") {
        setFormData(updatedFormData)
        scrollToTop();
        setTimeout(() => {
          document.querySelector(`a[href="#${key}"]`).focus()
        }, 1000);
        return false;

      }
    }
    setFormData(updatedFormData)
    scrollToTop();
  }
  const onSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      dispatch(LoadingAction.showLoader())
      let obj = {
        "name": formData.email.value,
        "mail": formData.email.value,
        "field_volenteer_name": formData.name.value,
        "field_volenteer_name_arabic": formData.nameArabic.value,
        "field_volenteer_email": formData.email.value,
        "field_gender": formData.gender.value,
        "field_qid": formData.qid.value,
        "field_student_dob": dateformat(formData.dob.value, "dd/mm/yyyy"),
        "roles": {
          "target_id": "volenteer"
        },
        "field_volenteer_mobile": `+974${formData.mobile.value.replace(/ /g, "")}`,
        // "field_channels_of_communication": formData.channel.value
      }
      let url = `${configure.api_url}${urlEndPoints.registerVolunteer}`
      try {
        const data = await networkRequest(url, "POST", obj, null, true)
        if (data.data.error) {
          displayError(data.data.error)
        } else {
          setSubmissionStatus(true)
          initState();
          M.AutoInit();
          // scrollToTop();
          successMessageRef.current.focus();
        }
        dispatch(LoadingAction.hideLoader())
      } catch (error) {
        dispatch(LoadingAction.hideLoader())
        console.warn("error: ", error)
      }
    }
  }
  const handleCloseModal = () => {
    let elems = document.querySelector("#termsAndConditionModal");
    elems.removeAttribute("aria-hidden")
    elems.removeAttribute("tabindex")
    let instance = M.Modal.getInstance(elems);
    instance.close();
  }
  const handleOpenModal = () => {
    let elems = document.querySelector("#termsAndConditionModal");
    elems.setAttribute("aria-hidden", "false")
    elems.setAttribute("tabindex", "-1")
    let instance = M.Modal.getInstance(elems);
    instance.open();
  }
  let hasError = false;
  Object.keys(formData).map((item, index) => {
    if (formData[item].error != "")
      hasError = true
  })
  return (
    <div id="main-container">
      <SlideToTop />
      <PageTitle title={context.t("_registration")} />
      <div className="container">
        <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
          <ul>
            <li className="breadcrumb-item">
              <Link to="/registration">{context.t("_registration")}</Link> {" "}

            </li>
            <li className="breadcrumb-item">
              <Link tabIndex="0" to aria-current={context.t("_page")}>
                <span>
                  {context.t("_volunteer")}
                </span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <ColouredItems />
      <div id="skipContent" className="container registration-form">
        <RegisterTypes {...props} />
        <h2 tabIndex="0" aria-label={context.t("_for_volunteer_form")} className="primary-heading">{context.t("_for_volunteer")}</h2>
        <div className="row">
          <div className="col s6 form-container">
            <p>
              {context.t("_required_field")}{" "}
              <span className="asterisk">*</span>
            </p>
            {
              (hasError && !submissionStatus) ? <div className="error-container fail">
                <h5>{context.t("_error_title")}</h5>
                <ul aria-label="error" >
                  {
                    Object.keys(formData).map((item, index) => {
                      return formData[item].error != "" ? <li key={index} ><a href={`#${item}`} tabIndex="0" className="error" htmlFor={item}>{formData[item].error}</a></li>
                        :
                        null
                    })}
                </ul>
              </div> : null
            }
            {
              submissionStatus ? <div className="error-container success" tabIndex="0" ref={successMessageRef}>
                <h5>{context.t("_success_title_student_1")}</h5>
                <p>{context.t("_success_title_student_2")}</p>
                <p>{context.t("_success_title_student_3")}</p>
              </div> : null
            }
            {
              _.isEmpty(formData) ?
                <div /> :
                <form onSubmit={onSubmit} autoComplete="off">
                  {renderInput({ name: "name", label: context.t("_name_english"), placeholder: context.t("_name_english_placeholder"), isMandatory: true })}
                  {renderInput({ name: "nameArabic", label: context.t("_name_arabic"), placeholder: context.t("_name_arabic_placeholder"), isMandatory: false })}
                  {renderInput({ name: "qid", label: context.t("_qid"), placeholder: context.t("_qid_placeholder"), isMandatory: true, max: 11 })}
                  {
                    renderRadioButton({ label: context.t("_gender"), isMandatory: true, name: "gender", defaultChecked: "male", data: [{ label: context.t("_male"), value: "Male" }, { label: context.t("_female"), value: "Female" }] })
                  }
                  <div className=" date-popup">
                    {renderInput({ name: "dob", label: context.t("_dob"), placeholder: context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
                  </div>
                  {renderInput({ name: "email", label: context.t("_email"), placeholder: context.t("_email_placeholder"), isMandatory: true, type: "email", styleClass: "fontEnglish" })}
                  {renderInput({ name: "mobile", label: context.t("_mobile_number"), placeholder: context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max: 8 })}
                  {/* {
                      renderRadioButton({ label: context.t("_channel_of_communication"), name: "channel", defaultChecked: "sms", data: [{ label: context.t("_email"), value: "Email Address" }, { label: context.t("_sms"), value: "Mobile Number" }, { label: context.t("_phone_call"), value: "Phone Call" }] })
                    } */}
                  {renderCheckbox({ label: context.t("_terms_and_condition"), name: "agree" })}
                  <div className="btn-wrap">
                    <button className="btn blue">{context.t("_registration")}</button>
                  </div>
                </form>
            }

          </div>
          <div className="col s6" />
        </div>
      </div>
      <div id="termsAndConditionModal" className="modal" role="dialog">
        <div className="modal-content">
          <span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => handleCloseModal()}><i className="material-icons">close</i></button></span>
          <h4>{termsAndCondition.title}</h4>
          <div className="description" dangerouslySetInnerHTML={{ __html: termsAndCondition.body }}></div>

          <button className={`modal-close waves-effect waves-green btn blue ${lang == "ar" ? "pull-left" : "pull-right"}`}>{context.t("_agree")}</button>
        </div>
      </div>
      <div className="top-line ng-scope">
        <img src={topLineImage} alt="" />
      </div>
    </div>
  );
}
VolunteerRegistration.contextTypes = {
  t: PropTypes.func
}
export default VolunteerRegistration;

const ColouredItems = () => {
  return (
    <div className="volunteer-color-container">
      <span className="grey-square-rotate red-sq one"></span>
      <span className="grey-square-rotate red-sq two"></span>
      <span className="grey-square-rotate red-sq three"></span>
      <span className="orange-circle one"></span>
      <span className="orange-circle two"></span>
      <span className="orange-circle three"></span>
      <span className="orange-circle four"></span>
      <span className="multi-square one"><b><i></i></b></span>
      <span className="multi-square two"><b><i></i></b></span>
      <span className="multi-square three"><b><i></i></b></span>
    </div>
  )
}