import React, { useState } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import Main from "../../constants/utilities"

const PageTitle = (props, context) => {
  const { config } = Main();
  const configure = config()

  const handleTwitterRedirect = (e) => {
    e.preventDefault();
    window.open(configure.social_media_links.twitter, '_blank').focus()
  }

  const { lang } = useSelector(
    (state) => state.i18nState
  );
  return (
    <div className={`page-title ${lang}`}>
      <div className="container">
        <div className="head-title">
          <div>
            {
              props.isDashboardItem ?
                <div className={`share-page ${lang}`}>
                  <h2>{context.t("_title_share_page")}</h2>
                  {configure.social_media_links.facebook &&
                    <a target="_blank" rel="noopener noreferrer" href={configure.social_media_links.facebook} className="fb-xfbml-parse-ignore">
                      <span>acebook</span>
                      <i aria-label="Facebook" className="fa fa-facebook">&nbsp;</i>
                    </a>
                  }
                  {configure.social_media_links.twitter &&
                    <a target="_blank" rel="noopener noreferrer" onClick={handleTwitterRedirect} href={configure.social_media_links.twitter} >
                      <span>acebook</span>
                      <i aria-label="Twitter" className="fa-brands fa-x-twitter">&nbsp;</i>
                    </a>
                  }
                  {/* <span className="yellow-box"></span> */}
                </div>
                :
                null
            }
          </div>
          <h2 id="pageHeading" role="heading" tabindex="0" aria-label={`page heading ${props.title}`}>{props.title}</h2>
        </div>
      </div>
    </div>
  )
}

PageTitle.contextTypes = {
  t: PropTypes.func
}
export default PageTitle