import React from "react"
import _ from "lodash"
import Slider from "react-slick"
import PropTypes from "prop-types"
import Main from "../../main"
import creative from "../../../assets/images/creative.svg"
import software from "../../../assets/images/zone-icon.svg"
import technology from "../../../assets/images/technology-icon.svg"
import { PopupAction, LoadingAction, ZoneCountAction } from "./../../../actions"

const Zones = (props, context) => {
        const { creativity_zone_activity_count, software_zone_activity_count, technology_zone_activity_count } = props;
        const slick_settings = {
            className: "slider news-slider",
            speed: 1000,
            lazyLoad: true,
            slidesToShow: 1,
            infinite: true,
            focusOnSelect: true,
            swipeToSlide: true,
            swipe: true,
            slidesToScroll: 1,
            autoplay: true
        };
        return (
            <div className="dashboardBox">
                <h2>{context.t("_zone")}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>{context.t("_creative_zone")}</td>
                            <td className="numberWidth">{creativity_zone_activity_count}</td>
                        </tr>
                        <tr>
                            <td>{context.t("_technology_zone")}</td>
                            <td>{technology_zone_activity_count}</td>
                        </tr>
                        <tr>
                            <td>{context.t("_software_zone")}</td>
                            <td>{software_zone_activity_count}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            // <>
            //     <div className="dashboardBox for-screen">
            //         <h3>{context.t("_zone")}</h3>
            //         <div className="dashboardBoxSlider">
            //             <Slider {...slick_settings}>
            //                 <div className="slider-item">
            //                     <div className="d-flex">
            //                         <img className="responsive-img" src={creative} alt="type image"></img>
            //                         <div className="slider-item__description">
            //                             <h4>{context.t("_creative_zone")}</h4>
            //                             <p>{`${creativity_zone_activity_count} ${context.t("_current_activities_1")}`}</p>
            //                         </div>
            //                     </div>
            //                 </div>
            //                 <div className="slider-item">
            //                     <div className="d-flex">
            //                         <img className="responsive-img" src={technology} alt="type image"></img>
            //                         <div className="slider-item__description">
            //                             <h4>{context.t("_technology_zone")}</h4>
            //                             <p>{`${technology_zone_activity_count} ${context.t("_current_activities_1")}`}</p>
            //                         </div>
            //                     </div>
            //                 </div>
            //                 <div className="slider-item">
            //                     <div className="d-flex">
            //                         <img className="responsive-img" src={software} alt="type image"></img>
            //                         <div className="slider-item__description">
            //                             <h4>{context.t("_software_zone")}</h4>
            //                             <p>{`${software_zone_activity_count} ${context.t("_current_activities_1")}`}</p>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </Slider>
            //         </div>
            //     </div>
            //     <div className="dashboardBox for-print">
            //         <h3>{context.t("_zone")}</h3>
            //         <table>
            //             <tbody>
            //                 <tr>
            //                     <td>{context.t("_creative_zone")}</td>
            //                     <td>{`${creativity_zone_activity_count} ${context.t("_current_activities_1")}`}</td>
            //                 </tr>
            //                 <tr>
            //                     <td>{context.t("_technology_zone")}</td>
            //                     <td>{`${technology_zone_activity_count} ${context.t("_current_activities_1")}`}</td>
            //                 </tr>
            //                 <tr>
            //                     <td>{context.t("_software_zone")}</td>
            //                     <td>{`${software_zone_activity_count} ${context.t("_current_activities_1")}`}</td>
            //                 </tr>
            //             </tbody>
            //         </table>
            //     </div>
            // </>
        )
    }

Zones.defaultProps = {
    creativity_zone_activity_count: 0,
    software_zone_activity_count: 0,
    technology_zone_activity_count: 0
}

Zones.contextTypes = {
    t: PropTypes.func
}
export default Zones;

// import React from "react"
// import Main from "../../main"
// import { connect } from "react-redux"
// import PropTypes from "prop-types"
// import _ from "lodash"
// import Slider from "react-slick"
// import { PopupAction, LoadingAction, ZoneCountAction } from "./../../../actions"
// import creative from "../../../assets/images/creative.svg"
// import software from "../../../assets/images/zone-icon.svg"
// import technology from "../../../assets/images/technology-icon.svg"

// class Zones extends Main {
//     constructor(props) {
//         super(props);
//         this.state = {
//             config: this.config(),
//             zone: {}
//         }
//     }
//     componentDidMount() {
//         // this.props.getZoneCreative({})
//         // this.props.getZoneSoftware({})
//         // this.props.getZoneTechnology({})
//     }
//     render() {
//         const { creativity_zone_activity_count, software_zone_activity_count, technology_zone_activity_count } = this.props;
//         const slick_settings = {
//             className: "slider news-slider",
//             speed: 1000,
//             lazyLoad: true,
//             slidesToShow: 1,
//             infinite: true,
//             focusOnSelect: true,
//             swipeToSlide: true,
//             swipe: true,
//             slidesToScroll: 1,
//             autoplay: true
//         };
//         return (
//             <div className="dashboardBox">
//                 <h2>{this.context.t("_zone")}</h2>
//                 <table>
//                     <tbody>
//                         <tr>
//                             <td>{this.context.t("_creative_zone")}</td>
//                             <td className="numberWidth">{creativity_zone_activity_count}</td>
//                         </tr>
//                         <tr>
//                             <td>{this.context.t("_technology_zone")}</td>
//                             <td>{technology_zone_activity_count}</td>
//                         </tr>
//                         <tr>
//                             <td>{this.context.t("_software_zone")}</td>
//                             <td>{software_zone_activity_count}</td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>
//             // <>
//             //     <div className="dashboardBox for-screen">
//             //         <h3>{this.context.t("_zone")}</h3>
//             //         <div className="dashboardBoxSlider">
//             //             <Slider {...slick_settings}>
//             //                 <div className="slider-item">
//             //                     <div className="d-flex">
//             //                         <img className="responsive-img" src={creative} alt="type image"></img>
//             //                         <div className="slider-item__description">
//             //                             <h4>{this.context.t("_creative_zone")}</h4>
//             //                             <p>{`${creativity_zone_activity_count} ${this.context.t("_current_activities_1")}`}</p>
//             //                         </div>
//             //                     </div>
//             //                 </div>
//             //                 <div className="slider-item">
//             //                     <div className="d-flex">
//             //                         <img className="responsive-img" src={technology} alt="type image"></img>
//             //                         <div className="slider-item__description">
//             //                             <h4>{this.context.t("_technology_zone")}</h4>
//             //                             <p>{`${technology_zone_activity_count} ${this.context.t("_current_activities_1")}`}</p>
//             //                         </div>
//             //                     </div>
//             //                 </div>
//             //                 <div className="slider-item">
//             //                     <div className="d-flex">
//             //                         <img className="responsive-img" src={software} alt="type image"></img>
//             //                         <div className="slider-item__description">
//             //                             <h4>{this.context.t("_software_zone")}</h4>
//             //                             <p>{`${software_zone_activity_count} ${this.context.t("_current_activities_1")}`}</p>
//             //                         </div>
//             //                     </div>
//             //                 </div>
//             //             </Slider>
//             //         </div>
//             //     </div>
//             //     <div className="dashboardBox for-print">
//             //         <h3>{this.context.t("_zone")}</h3>
//             //         <table>
//             //             <tbody>
//             //                 <tr>
//             //                     <td>{this.context.t("_creative_zone")}</td>
//             //                     <td>{`${creativity_zone_activity_count} ${this.context.t("_current_activities_1")}`}</td>
//             //                 </tr>
//             //                 <tr>
//             //                     <td>{this.context.t("_technology_zone")}</td>
//             //                     <td>{`${technology_zone_activity_count} ${this.context.t("_current_activities_1")}`}</td>
//             //                 </tr>
//             //                 <tr>
//             //                     <td>{this.context.t("_software_zone")}</td>
//             //                     <td>{`${software_zone_activity_count} ${this.context.t("_current_activities_1")}`}</td>
//             //                 </tr>
//             //             </tbody>
//             //         </table>
//             //     </div>
//             // </>
//         )
//     }

// }
// Zones.defaultProps = {
//     creativity_zone_activity_count: 0,
//     software_zone_activity_count: 0,
//     technology_zone_activity_count: 0
// }
// const mapStateToProps = ({ i18nState, zoneCountReducer }) => {
//     return {
//         lang: i18nState.lang,
//         // creativeZoneCount: zoneCountReducer.creativeZoneCount,
//         // softwareZoneCount: zoneCountReducer.softwareZoneCount,
//         // technologyZoneCount: zoneCountReducer.technologyZoneCount,
//     }
// }
// const mapDispatchToProps = {
//     // showPopup: (data) => PopupAction.showPopup(data),
//     // hidePopup: (data) => PopupAction.hidePopup(data),
//     // getZoneCreative: ZoneCountAction.getZoneCreative,
//     // getZoneSoftware: ZoneCountAction.getZoneSoftware,
//     // getZoneTechnology: ZoneCountAction.getZoneTechnology,
//     // showLoader: LoadingAction.showLoader,
//     // hideLoader: LoadingAction.hideLoader,
// }
// Zones.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(Zones);