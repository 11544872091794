import React, { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types"
import Main from "../../constants/utilities.js"
import { Link, Route, NavLink, useParams, Routes } from "react-router-dom"
import PageTitle from "../includes/page_title.js"
import { LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import _ from "lodash";
import Filter from "./filter"
import { networkRequest } from "../../httpAPI/api.js";
// class FilterMedia extends Main {
// 	constructor(props) {
// 		super(props);
// 		state = {
// 			isLoaded: false,
// 			config: config(),
// 			media: [],
// 			filter: [],
// 			id: ""
// 		}
// 		props.showLoader()
// 	}
// 	/*componentWillMount() {
// 	  unlisten = props.history.listen((location, action) => {
// 		getMedia();	    
// 	  });
// 	}
// 	componentWillUnmount() {
// 		unlisten();

// 	}*/
// 	componentDidMount() {
// 		document.title = `${context.t("_studio56")} |`
// 		filterMenu();
// 	}
// 	filterMenu = async () => {
// 		const { config } = state
// 		const { lang } = props;
// 		let url = "";
// 		if (lang == "ar")
// 			url = `${config.api_url}${urlEndPoints.filter(lang)}`
// 		else
// 			url = `${config.api_url}${urlEndPoints.filter(lang)}`
// 		try {
// 			const data = await networkRequest(url)
// 			props.hideLoader();
// 			setState({ filter: data.data })
// 		} catch (error) {
// 			props.hideLoader()
// 			console.warn("error: ", error)
// 		}
// 	}

// 	render() {
// 		const { filter } = state;
// 		const { lang } = props;
// 		const { match: { params } } = props;
// 		let breadcrumbTitle = {}
// 		breadcrumbTitle = _.find(filter, ["tid", params.id])
// 		document.title = `${context.t("_studio56")} | ${breadcrumbTitle && breadcrumbTitle.filter}`
// return (
// <div id="main-content" className="media-center-page">
// 	<PageTitle title={context.t("_navbar_media_center")} />
// 	<div className="container">
// 		<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
// 			<ul>
// 				<li className="breadcrumb-item">
// 					<Link to="/">{context.t("_home")}</Link> {" "}
// 				</li>
// 				<li className="breadcrumb-item">
// 					<Link to="/media-center">{context.t("_navbar_media_center")}</Link>{" "}
// 				</li>
// 				<li className="breadcrumb-item">
// 					<Link tabIndex="0" to aria-current={context.t("_page")}>
// 						<span>{breadcrumbTitle && breadcrumbTitle.filter}</span>
// 					</Link>
// 				</li>
// 			</ul>
// 		</nav>
// 	</div>
// 	<div id="skipContent">
// 		<div className="container">
// 			<div className="row">
// 				<div className="col s3 left-side">
// 					<div className="filter-wrap">
// 						<h2>{context.t("_filter_media")}</h2>
// 						<ul aria-label="aside navigation">
// 							{filter.map((row, index) => (
// 								<li className={`media-item ${lang}`} key={index}>
// 									<NavLink to={"/filter-media/" + row.tid}>{row.filter}<span>{row.count}</span></NavLink>
// 								</li>
// 							))}
// 						</ul>
// 						<span className="grey-square-rotate"></span>
// 						<span className="multi-square"><b><i></i></b></span>
// 					</div>
// 				</div>
// 				<div className="col s9 media-content">
// 					<Route path="/filter-media/:id" component={Filter} />
// 					{/* <div className="flex-row col-3">
// 					<Route path="/filter-media/:id" component={Filter} />
// 				</div> */}
// 				</div>
// 			</div>
// 		</div>
// 	</div>
// </div>
// )
// 	}
// }

// const mapStateToProps = ({ i18nState }) => {
// 	return {
// 		lang: i18nState.lang
// 	}
// }
// const mapDispatchToProps = {
// 	showLoader: LoadingAction.showLoader,
// 	hideLoader: LoadingAction.hideLoader,
// }
// FilterMedia.contextTypes = {
// 	t: PropTypes.func
// }

// export default connect(mapStateToProps, mapDispatchToProps)(FilterMedia);


const FilterMedia = (props, context) => {
	const [isLoaded, setIsLoaded] = useState(false)
	const [media, setMedia] = useState([])
	const [filter, setFilter] = useState([])
	const [id, setId] = useState("")
	const [breadcrumbTitle, setBreadcrumbTitle] = useState("")
	const { config } = Main()
	const configure = config()
	const { lang } = useSelector(state => state.i18nState)
	const dispatch = useDispatch()
	const params = useParams()
	const filterMenu = async () => {
		let url = "";
		if (lang == "ar")
			url = `${configure.api_url}${urlEndPoints.filter(lang)}`
		else
			url = `${configure.api_url}${urlEndPoints.filter(lang)}`
		try {
			const data = await networkRequest(url)
			dispatch(LoadingAction.hideLoader());
			setFilter(data.data)
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}

	useEffect(() => {
		filterMenu()
		const title = _.find(filter, ["tid", params.id])
		setBreadcrumbTitle(title)
		document.title = `${context.t("_studio56")} | ${breadcrumbTitle && breadcrumbTitle.filter}`
	}, [])
	return (
		<div id="main-content" className="media-center-page">
			<PageTitle title={context.t("_navbar_media_center")} />
			<div className="container">
				<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
					<ul>
						<li className="breadcrumb-item">
							<Link to="/">{context.t("_home")}</Link> {" "}
						</li>
						<li className="breadcrumb-item">
							<Link to="/media-center">{context.t("_navbar_media_center")}</Link>{" "}
						</li>
						<li className="breadcrumb-item">
							<Link tabIndex="0" to aria-current={context.t("_page")}>
								<span>{breadcrumbTitle && breadcrumbTitle.filter}</span>
							</Link>
						</li>
					</ul>
				</nav>
			</div>
			<div id="skipContent">
				<div className="container">
					<div className="row">
						<div className="col s3 left-side">
							<div className="filter-wrap">
								<h2>{context.t("_filter_media")}</h2>
								<ul aria-label="aside navigation">
									{filter?.map((row, index) => (
										<li className={`media-item ${lang}`} key={index}>
											<NavLink to={"/filter-media/" + row.tid}>{row.filter}<span>{row.count}</span></NavLink>
										</li>
									))}
								</ul>
								<span className="grey-square-rotate"></span>
								<span className="multi-square"><b><i></i></b></span>
							</div>
						</div>
						<div className="col s9 media-content">
							<Filter />
							{/* <div className="flex-row col-3">
								<Route path="/filter-media/:id" component={Filter} />
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
FilterMedia.contextTypes = {
	t: PropTypes.func
}
export default FilterMedia
