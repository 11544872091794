import React from "react"
import Slider from "react-slick"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
// import { PopupAction, LoadingAction, TypeCountAction } from "./../../../actions"
import type from "../../../assets/images/event-type-icon.svg"

const Types = (props, context) => {
    const { lang } = useSelector(state => state.i18nState);
    const { event_activity_count, session_activity_count, competition_activity_count, challenge_activity_count, project_activity_count } = props;
    const slick_settings = {
        className: "slider news-slider",
        speed: 1000,
        lazyLoad: true,
        slidesToShow: 1,
        infinite: true,
        focusOnSelect: true,
        swipeToSlide: true,
        swipe: true,
        slidesToScroll: 1,
        autoplay: true
    };
    return (
        <div className="dashboardBox">
            <h2>{context.t("_type")}</h2>
            <table>
                <tbody>
                    <tr>
                        <td>{context.t("_events")}</td>
                        <td className="numberWidth">{event_activity_count}</td>
                    </tr>
                    <tr>
                        <td>{context.t("_sessions")}</td>
                        <td>{session_activity_count}</td>
                    </tr>
                    <tr>
                        <td>{context.t("_competitions")}</td>
                        <td>{competition_activity_count}</td>
                    </tr>
                    <tr>
                        <td>{context.t("_challenges")}</td>
                        <td>{challenge_activity_count}</td>
                    </tr>
                    <tr>
                        <td>{context.t("_projects")}</td>
                        <td>{project_activity_count}</td>
                    </tr>
                </tbody>
            </table>

        </div>
        // <>
        //     <div className="dashboardBox for-screen">
        //         <h3>{context.t("_type")}</h3>
        //         <div className="dashboardBoxSlider">
        //             <Slider {...slick_settings}>
        //                 <div className="slider-item">
        //                     <div className="d-flex">
        //                         <img className="responsive-img" src={type} alt="type image"></img>
        //                         <div className="slider-item__description">
        //                             <h4>{context.t("_events")}</h4>
        //                             <p>{`${event_activity_count} ${context.t("_current_activities_1")}`}</p>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div className="slider-item">
        //                     <div className="d-flex">
        //                         <img className="responsive-img" src={type} alt="type image"></img>
        //                         <div className="slider-item__description">
        //                             <h4>{context.t("_sessions")}</h4>
        //                             <p>{`${session_activity_count} ${context.t("_current_activities_1")}`}</p>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div className="slider-item">
        //                     <div className="d-flex">
        //                         <img className="responsive-img" src={type} alt="type image"></img>
        //                         <div className="slider-item__description">
        //                             <h4>{context.t("_competitions")}</h4>
        //                             <p>{`${competition_activity_count} ${context.t("_current_activities_1")}`}</p>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </Slider>
        //         </div>
        //     </div>
        //     <div className="dashboardBox for-print" >
        //         <h3>{context.t("_type")}</h3>
        //         <table>
        //             <tbody>
        //                 <tr>
        //                     <td>{context.t("_events")}</td>
        //                     <td>{`${event_activity_count} ${context.t("_current_activities_1")}`}</td>
        //                 </tr>
        //                 <tr>
        //                     <td>{context.t("_sessions")}</td>
        //                     <td>{`${session_activity_count} ${context.t("_current_activities_1")}`}</td>
        //                 </tr>
        //                 <tr>
        //                     <td>{context.t("_competitions")}</td>
        //                     <td>{`${competition_activity_count} ${context.t("_current_activities_1")}`}</td>
        //                 </tr>
        //             </tbody>
        //         </table>
        //     </div>
        // </>
    )
}
Types.defaultProps = {
    event_activity_count: 0,
    session_activity_count: 0,
    competition_activity_count: 0,
    challenge_activity_count: 0,
    project_activity_count: 0
}

Types.contextTypes = {
    t: PropTypes.func
}
export default Types;


// import React from "react"
// import Main from "../../main"
// import { connect } from "react-redux"
// import PropTypes from "prop-types"
// import Slider from "react-slick"
// // import { PopupAction, LoadingAction, TypeCountAction } from "./../../../actions"
// import type from "../../../assets/images/event-type-icon.svg"

// class Types extends Main {
//     constructor(props) {
//         super(props);
//     }
//     componentDidMount() {
//         // this.props.getEventActivity({});
//         // this.props.getSessionActivity({});
//         // this.props.getCompetitionActivity({});
//     }
//     render() {
//         const { event_activity_count, session_activity_count, competition_activity_count, challenge_activity_count, project_activity_count } = this.props;
//         const slick_settings = {
//             className: "slider news-slider",
//             speed: 1000,
//             lazyLoad: true,
//             slidesToShow: 1,
//             infinite: true,
//             focusOnSelect: true,
//             swipeToSlide: true,
//             swipe: true,
//             slidesToScroll: 1,
//             autoplay: true
//         };
//         return (
//             <div className="dashboardBox">
//                 <h2>{this.context.t("_type")}</h2>
//                 <table>
//                     <tbody>
//                         <tr>
//                             <td>{this.context.t("_events")}</td>
//                             <td className="numberWidth">{event_activity_count}</td>
//                         </tr>
//                         <tr>
//                             <td>{this.context.t("_sessions")}</td>
//                             <td>{session_activity_count}</td>
//                         </tr>
//                         <tr>
//                             <td>{this.context.t("_competitions")}</td>
//                             <td>{competition_activity_count}</td>
//                         </tr>
//                         <tr>
//                             <td>{this.context.t("_challenges")}</td>
//                             <td>{challenge_activity_count}</td>
//                         </tr>
//                         <tr>
//                             <td>{this.context.t("_projects")}</td>
//                             <td>{project_activity_count}</td>
//                         </tr>
//                     </tbody>
//                 </table>

//             </div>
//             // <>
//             //     <div className="dashboardBox for-screen">
//             //         <h3>{this.context.t("_type")}</h3>
//             //         <div className="dashboardBoxSlider">
//             //             <Slider {...slick_settings}>
//             //                 <div className="slider-item">
//             //                     <div className="d-flex">
//             //                         <img className="responsive-img" src={type} alt="type image"></img>
//             //                         <div className="slider-item__description">
//             //                             <h4>{this.context.t("_events")}</h4>
//             //                             <p>{`${event_activity_count} ${this.context.t("_current_activities_1")}`}</p>
//             //                         </div>
//             //                     </div>
//             //                 </div>
//             //                 <div className="slider-item">
//             //                     <div className="d-flex">
//             //                         <img className="responsive-img" src={type} alt="type image"></img>
//             //                         <div className="slider-item__description">
//             //                             <h4>{this.context.t("_sessions")}</h4>
//             //                             <p>{`${session_activity_count} ${this.context.t("_current_activities_1")}`}</p>
//             //                         </div>
//             //                     </div>
//             //                 </div>
//             //                 <div className="slider-item">
//             //                     <div className="d-flex">
//             //                         <img className="responsive-img" src={type} alt="type image"></img>
//             //                         <div className="slider-item__description">
//             //                             <h4>{this.context.t("_competitions")}</h4>
//             //                             <p>{`${competition_activity_count} ${this.context.t("_current_activities_1")}`}</p>
//             //                         </div>
//             //                     </div>
//             //                 </div>
//             //             </Slider>
//             //         </div>
//             //     </div>
//             //     <div className="dashboardBox for-print" >
//             //         <h3>{this.context.t("_type")}</h3>
//             //         <table>
//             //             <tbody>
//             //                 <tr>
//             //                     <td>{this.context.t("_events")}</td>
//             //                     <td>{`${event_activity_count} ${this.context.t("_current_activities_1")}`}</td>
//             //                 </tr>
//             //                 <tr>
//             //                     <td>{this.context.t("_sessions")}</td>
//             //                     <td>{`${session_activity_count} ${this.context.t("_current_activities_1")}`}</td>
//             //                 </tr>
//             //                 <tr>
//             //                     <td>{this.context.t("_competitions")}</td>
//             //                     <td>{`${competition_activity_count} ${this.context.t("_current_activities_1")}`}</td>
//             //                 </tr>
//             //             </tbody>
//             //         </table>
//             //     </div>
//             // </>
//         )
//     }

// }
// Types.defaultProps = {
//     event_activity_count: 0,
//     session_activity_count: 0,
//     competition_activity_count: 0,
//     challenge_activity_count: 0,
//     project_activity_count: 0
// }
// const mapStateToProps = ({ i18nState, typeCountReducer }) => {
//     return {
//         lang: i18nState.lang,
//         // eventActivityCount: typeCountReducer.eventActivityCount,
//         // sessionActivityCount: typeCountReducer.sessionActivityCount,
//         // competitionActivityCount: typeCountReducer.competitionActivityCount,
//     }
// }
// const mapDispatchToProps = {
//     // showPopup: (data) => PopupAction.showPopup(data),
//     // hidePopup: (data) => PopupAction.hidePopup(data),
//     // getEventActivity: TypeCountAction.getEventActivity,
//     // getSessionActivity: TypeCountAction.getSessionActivity,
//     // getCompetitionActivity: TypeCountAction.getCompetitionActivity,
//     // showLoader: LoadingAction.showLoader,
//     // hideLoader: LoadingAction.hideLoader,
// }
// Types.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(Types);