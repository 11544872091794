import { GET_MEDIA_CENTER } from "./../constants/constants";

const initialState = {
  mediaList: []
};

const mediaActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEDIA_CENTER:
      return {
        mediaList: action.data
      };

    default:
      return { ...state };
  }
};

export default mediaActionReducer;
