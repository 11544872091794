import React, { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types"
import Main from "../../constants/utilities.js"
import { Link, Route, NavLink, useParams } from "react-router-dom"
import PageTitle from "../includes/page_title.js"
import Pagination from "react-js-pagination";
import { LoadingAction } from "../../actions"
import { urlEndPoints } from "../../httpAPI/apiConfig"
import { networkRequest } from "../../httpAPI/api.js";


const Filter = (props, context) => {
	const [media, setMedia] = useState([])
	const [isLoaded, setIsLoaded] = useState(false)
	const [pager, setPager] = useState({ count: 0, pages: 0, items_per_page: 0, current_page: 1 })
	const [search, setSearch] = useState({ page: 0 })
	const [id, setId] = useState("")
	const { config } = Main()
	const configure = config()
	const { lang } = useSelector(state => state.i18nState)
	const dispatch = useDispatch()
	const params = useParams()

	const getMedia = async () => {
		dispatch(LoadingAction.showLoader())
		let url = "";
		if (lang == "ar")
			url = `${configure.origin}${lang}/api/${urlEndPoints.filterMedia(params.id)}`
		else
			url = `${configure.origin}api/${urlEndPoints.filterMedia(params.id)}`

		if (search.page)
			url = `${url}&page=${search.page}`
		try {
			const data = await networkRequest(url)
			setMedia(data.data.results)
			setPager(data.data.pager)
			setId(params.id)
			setIsLoaded(true)
			dispatch(LoadingAction.hideLoader());
			// this.refs.mediaCenterRef0 && this.refs.mediaCenterRef0.focus()
		} catch (error) {
			setIsLoaded(true)
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}

	function handlePageChange(pageNumber) {
		dispatch(LoadingAction.showLoader())
		search["page"] = pageNumber - 1;
		setSearch(search)
		getMedia();
	}
	useEffect(() => {
		if (id !== params.id) {
			getMedia();
			window.scroll(0, 0);
		}
	}, [params.id])
	return (
		<>
			{isLoaded && media.length == 0 ? <div className="row"><p aria-label={context.t("_no_result_found")} tabIndex="0" className="col s12 no-result">{context.t("_no_result_found")}</p></div> : <ul aria-label={`${context.t("_navbar_media_center")} ${media.length > 0 ? media[0].category : ""} ${context.t("_with")} ${media.length} ${context.t("_items")}`}>
				{media.map((row, index) => (

					<li className="media-item" key={index}>
						<div dangerouslySetInnerHTML={{ __html: row.category }} className={"category-wrap " + row.field_key.toLowerCase()}></div>
						<h3><Link tabIndex="0" aria-label={`${row.title} ${row.date}`} to={"/news/" + row.nid} >{row.title}</Link></h3>
						<div className="mediaList">
							<div className="medialistImg" >
								{row.field_key === "Videos" &&
									<div className="videowrapper">
										<object data={row.video}>
											<param name="movie" value={row.video}></param>
											<param name="allowFullScreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" value="true"></param>
											<span>video</span>
										</object>
									</div>
								}

								{
									((row.field_key === "Gallery" || row.field_key === "News" || row.field_key === "Articles") && row.field_newsimage) &&
									<div
										// dangerouslySetInnerHTML={{ __html: row.media }}
										className="img-wrap imageFixedSize"
									>
										<img alt="" src={configure.origin + row.field_newsimage} />
									</div>
								}
							</div>
							<div className="medialistText">
								<div
									className="desc"
									dangerouslySetInnerHTML={{ __html: row.description }}
								></div>
								<div
									className="date-wrap"
									dangerouslySetInnerHTML={{ __html: row.date }}
								></div>
							</div>

						</div>
					</li>
				))}
			</ul>
			}
			<nav className="pagination-wrapper" aria-label="pagination">
				{(media.length) ? (
					<Pagination
						prevPageText={`< ${context.t("_previous")}`}
						nextPageText={`${context.t("_next")} >`}
						hideFirstLastPages={true}
						hideDisabled={true}
						linkClassPrev="linkClassPrev"
						linkClassNext="linkClassNext"
						activePage={pager.current_page + 1}
						itemsCountPerPage={pager.items_per_page}
						totalItemsCount={pager.count}
						pageRangeDisplayed={5}
						onChange={handlePageChange}
					/>) : ""
				}
			</nav>
		</>
	)
}
Filter.contextTypes = {
	t: PropTypes.func
}
export default Filter
