
import React, {  useRef, useState } from "react";
import { Link } from "react-router-dom";
import Main from "../../constants/utilities.js";
import PageTitle from "../includes/page_title.js";
import SlideToTop from "../includes/slide_to_top.js";
import { dynamicStyles } from "./../../constants/dynamicStyles"
import M from "materialize-css";
import RegisterTypes from "./registerTypes"
import _ from "lodash"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import dateformat from "dateformat"
import {
  emailValidate, nameValidate, phoneValidate, nameWordLengthCheck, qidValidate, CustomDateInput, calenderLocal
} from "./../../constants/common"
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import topLineImage from "../../assets/images/top-line.png"
import { useEffect } from "react";
const SchoolRepresentativeRegistration =(props,context)=> {
  const { config, networkRequest } = Main();

  const [formData, setFormData] = useState({});
  const [schoolList, setSchoolList] = useState([]);
  const [intervalId, setIntervalId] = useState(0);
  const [scrollStepInPx, setScrollStepInPx] = useState(50);
  const [delayInMs, setDelayInMs] = useState(16.66);
  const [submissionStatus, setSubmissionStatus] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState({});
  const [configuration, setConfiguration] = useState(config())

  const { lang } = useSelector((state) => state.i18nState)
  const dispatch = useDispatch()

  const successMessageRef=useRef(null)

    useEffect(() => {

    document.title = `${context.t("_studio56")} | ${context.t("_registration")} | ${context.t("_school")}`;
    // isLoggedInUsers();
    initState();
    initSelect();
    getSchoolList();
    getTermsAndCondition();
    setTimeout(() => {
      const elems = document.getElementById("schoolName");
      if (elems) elems.tabIndex = 0;
    }, 1000);
    M.AutoInit(); 
  }, []);

  useEffect(() => {
    document.title = `${context.t("_studio56")} | ${context.t("_registration")} | ${context.t("_school")}`;
  }, []);



  const  initSelect = () => {
    if (formData.schoolName) {
      var options = {
        autoClose: true,
        classes:formData.schoolName.class

      };
      var elems = document.querySelectorAll("select");
      M.FormSelect.init(elems, options);
    }

  }
  const initState = () => {
    setFormData({
      schoolName: { value: "", error: "", class: "" },
      schoolNewName: { value: "", error: "", class: "" },
      schoolPhone: { value: "", error: "", class: "" },
      schoolEmail: { value: "", error: "", class: "" },
      repName: { value: "", error: "", class: "" },
      repNameArabic: { value: "", error: "", class: "" },
      qid: { value: "", error: "", class: "" },
      repPosition: { value: "", error: "", class: "" },
      repMobile: { value: "", error: "", class: "" },
      repEmail: { value: "", error: "", class: "" },
      channel: { value: "", error: "", class: "" },
      agree: { value: false, error: "", class: "" },
    })

    initSelect()
  }
  const getSchoolList = async () => {

    const url = `${configuration.api_url}${urlEndPoints.schoolList(lang)}`
    try {
      const data = await networkRequest(url)
      setSchoolList(data.data)
      // this.schoolName.focus()
      initSelect()
    } catch (error) {
      dispatch(LoadingAction.hideLoader())
      console.warn("error: ", error)
    }
  }
  const  getTermsAndCondition = async () => {

    let url = "";
    if (lang == "ar")
      url = `${configuration.origin}${lang}/${urlEndPoints.termsAndCondition}`
    else
      url = `${configuration.origin}${urlEndPoints.termsAndCondition}`
    try {
      const data = await networkRequest(url)
      if (data.status == 200 && data.data && data.data.length > 0) {
        setTermsAndCondition(data.data[0])
        // this.setState({ termsAndCondition: data.data[0] })
        M.AutoInit()
      }
    } catch (error) {
      dispatch(LoadingAction.hideLoader())
      console.warn("error: ", error)
    }
  }
  const handleChange = (field, value) => {
    if (field == "qid")
      if (isNaN(value))
        return false
    if (field == "schoolName") {
      const school = schoolList.filter(item => item.id == value)
      formData[field]["class"] = value ? "" : "invalid";
      formData[field]["value"] = value;
      if (school && school[0]) {
        formData.schoolPhone.value = school[0].field_s.replace("+974", "")
        formData.schoolEmail.value = school[0].field_school_email
      } else {
        formData.schoolPhone.value = ""
        formData.schoolEmail.value = ""
      }
      setFormData({...formData})
      setSubmissionStatus(false)
      initSelect()
      // this.setState({ formData, submissionStatus: false }, () => { this.initSelect() });
    } else {
      formData[field]["class"] = value ? "" : "invalid";
      formData[field]["value"] = value;
      setFormData({...formData})
      setSubmissionStatus(false)
      initSelect()
      // this.setState({ formData, submissionStatus: false }, () => { this.initSelect() });
    }

  };
  const  renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max, disabled, styleClass = "" }) => {

    return (type == "phone_number" ?
      <div className="row mobile">
        <div className="col s4">
          <div className="input-field item">
            <input name={`${name}_country_code`}
              placeholder="Country Code"
              className={styleClass}
              value={context.t("_ar_country_code")}
              style={dynamicStyles.textAlign(lang)}
              id={`${name}_country_code`}
              type="text"
              minLength="3"
              maxLength="3"
              disabled
            // className={"validate " + registration_form.mobile.class}
            // required
            />
            <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{context.t("_country_code")}</label>
            <span className="helper-text" data-error="Enter a valid code."></span>
          </div>
        </div>
        <div className="col s8">
          <div className="input-field item">
            <input
              name={name}
              placeholder={placeholder}
              // ref={(input) => { this[name] = input; }}
              className={styleClass}
              id={name}
              maxLength={max}
              aria-label={context.t("_phone_aria_label")}
              type="text"
              style={dynamicStyles.textAlign(lang)}
              // readOnly={type === "date" ? "readonly" : false}
              autoComplete="no"
              onChange={evt =>
                handleChange(name, evt.target.value)
              }
              onFocus={evt =>
                handleChange(name, evt.target.value)
              }
              disabled={disabled}
              value={formData[name].value}
            // className={"validate " + formData[name].class}
            // required={isMandatory}
            />
            <label htmlFor={name} style={dynamicStyles.position(lang)}>
              {`${label} `}
              {isMandatory ? <span className="asterisk">*</span> : null}
            </label>
            <span
              className="helper-text"
              data-error={formData[name].error ? formData[name].error : "Required field."}
            ></span>
          </div>
        </div>
      </div>
      : type == "date" ? <div className="input-field item">
        <DatePicker
          name={name}
          placeholderText={placeholder}
          // ref={(input) => { this[name] = input; }}
          id={name}
          autoComplete="no"
          className={styleClass}
          maxDate={new Date("12/31/2014")}
          minDate={new Date("01/01/2001")}
          dateFormat="dd-MM-yyyy"
          onChange={date =>
            handleChange(name, date)
          }
          style={dynamicStyles.textAlign(lang)}
          value={formData[name].value}
          locale={calenderLocal(context)}
          selected={formData[name].value} customInput={<CustomDateInput ariaLabel={context.t("_select_dob_button")} />}
          onCalendarOpen={e => {
            let elems = document.querySelector(".react-datepicker");
            elems.setAttribute("tabindex", "0")
            elems.focus()
            elems = document.querySelector(".react-datepicker__month-select");
            elems.setAttribute("aria-label", context.t("_select_month"))
            elems = document.querySelector(".react-datepicker__year-select");
            elems.setAttribute("aria-label", context.t("_select_year"))

          }}
          onCalendarClose={e => {
            var elems = document.querySelector(".hiddenDob");
            elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(formData[name].value, "dd-mm-yyyy")}`)
            setTimeout(() => {
              elems.focus()
            }, 200);
          }}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
        <label htmlFor={name} style={dynamicStyles.position(lang)}>
          {`${label} `}
          {isMandatory ? <span className="asterisk">*</span> : null}
        </label>
        <span
          style={dynamicStyles.textAlign(lang)}
          className="helper-text"
          data-error={formData[name].error ? formData[name].error : "Required field."}
        ></span>
      </div> : <div className="input-field item">
        <input
          name={name}
          placeholder={placeholder}
          id={name}
          className={styleClass}
          // ref={(input) => { this[name] = input; }}
          type={type}
          maxLength={max}
          style={dynamicStyles.textAlign(lang)}
          // readOnly={type === "date" ? "readonly" : false}
          autoComplete="no"
          onChange={evt =>
            handleChange(name, evt.target.value)
          }
          onFocus={evt =>
            handleChange(name, evt.target.value)
          }
          disabled={disabled}
          value={formData[name].value}
        // className={`validate ${formData[name].class} ${type === "date" ? " datepicker" : ""}`}
        // required={isMandatory}
        />
        <label htmlFor={name} style={dynamicStyles.position(lang)}>
          {`${label} `}
          {isMandatory ? <span className="asterisk">*</span> : null}
        </label>
        <span
          className="helper-text"
          data-error={formData[name].error ? formData[name].error : "Required field."}
        ></span>
      </div>)
  }
  const renderRadioButton = ({ data = [], name, label, defaultChecked, isMandatory = false }) => {

    return (
      <div className="input-field item">
        <div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={name}>
          {
            data.map((item, index) => {
              return (
                <div key={index} className={item.value}>
                  <input
                    aria-checked={formData[name].value === item.value}
                    name={name}
                    id={item.value}
                    aria-labelledby={`${name} ${item.label}`}
                    type="radio"
                    readOnly
                    className="radio-btn"
                    onClick={evt =>
                      handleChange(name, item.value)
                    }
                    checked={formData[name].value === item.value}
                  />
                  <label id={`_${item.label}`} htmlFor={item.value} className="custom-radio-style">
                    <span className="custom-radio-style">{item.label}</span>
                  </label>
                </div>
              )
            })
          }

        </div>
        <label htmlFor={name} style={dynamicStyles.position(lang)}>
          {`${label} `}
          {isMandatory ? <span className="asterisk">*</span> : null}
        </label>
        <span
          className="helper-text"
          data-error="Required field."
        ></span>
      </div>
    )
  }
  const  renderSelect = ({ name, label, placeholder, isMandatory = false, data = [] }) => {

    return (
      <div className="input-field mobileSelect">
        <label htmlFor={name} style={dynamicStyles.position(lang)}>
          {`${label} `}
          {isMandatory ? <span className="asterisk">*</span> : null}
        </label>
        <select
          className="browser-default"
          name={name}
          id={name}
          input={<input  />}
          style={dynamicStyles.textAlign(lang)}
          // ref="selectRef"
          onChange={evt =>
            handleChange(name, evt.target.value)
          }

          // onFocus={evt =>
          //   handleChange(name, evt.target.value)
          // }
          value={formData[name].value}
        // className={"validate" + formData[name].class}
        >
          <option value="" disabled> {placeholder} </option>
          {
            data.map((item, index) => {
              return <option key={index} value={item.id}>{item.sname}</option>
            })
          }

          <option value="0">{context.t("_not_in_list")}</option>
        </select>

        <span
          style={dynamicStyles.textAlign(lang)}
          className="helper-text"
          data-error={formData[name].error ? formData[name].error : "Required field."}
        ></span>
      </div>
    )
  }
  const  renderCheckbox = ({ label, name }) => {

    return (
      <div className="item subscribe-items">
        <p>
          <input type="checkbox"
            name={name}
            id={name}
            tabIndex="0"
            style={dynamicStyles.textAlign(lang)}
            // ref={(input) => { this[name] = input; }}
            onChange={(event) => handleChange(name, event.target.checked)}
            checked={formData[name].value}
          />
          <label for={name}>
            <span className={lang}>{context.t("_i_agree")}
              <button type="button" style={{ border: 0, background: "none" }} aria-label={context.t("_read_terms_condition")} tabindex="0" className="modal-trigger underline-text" onClick={() => handleOpenModal()}>{label}</button>
            </span>
          </label>
        </p>
      </div>

    )
  }
  const scrollStep=()=> {
    var scrollStepInPx = scrollStepInPx;
    if (window.pageYOffset <= 400) {
      clearInterval(intervalId);
    }
    window.scroll(0, window.pageYOffset - scrollStepInPx);
  }
  const  scrollToTop=()=> {
    window.scroll({ top: 0, left: 0, behavior: "smooth" })
    // var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
    // let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
    // this.setState({ intervalId: intervalId });
  }
  const  validate = () => {
    for (var key in formData) {
      formData[key].value = typeof (formData[key].value) == "boolean" ? formData[key].value : formData[key].value.trim();
      formData[key].error = "";
    }
    if (formData.schoolName.value == "")
      formData.schoolName.error = context.t("_school_name_required");

    if (formData.schoolName.value == "0" && formData.schoolNewName.value == "")
      formData.schoolNewName.error = context.t("_school_name_required");

    if (formData.schoolPhone.value == "")
      formData.schoolPhone.error = context.t("_school_phone_required");

    if (formData.schoolPhone.value != "" && !phoneValidate.test(formData.schoolPhone.value.replace(/ /g, "")))
      formData.schoolPhone.error = context.t("_invalid_school_phone");

    if (formData.schoolPhone.value != "")
      if (formData.schoolPhone.value.toString().startsWith("3") || formData.schoolPhone.value.toString().startsWith("4") || formData.schoolPhone.value.toString().startsWith("5") || formData.schoolPhone.value.toString().startsWith("6") || formData.schoolPhone.value.toString().startsWith("7")) {

      } else {
        formData.schoolPhone.error = context.t("_invalid_school_phone");
      }

    if (formData.schoolEmail.value == "")
      formData.schoolEmail.error = context.t("_school_email_required");

    if (formData.schoolEmail.value != "" && !emailValidate.test(formData.schoolEmail.value))
      formData.schoolEmail.error = context.t("_invalid_school_email");

    if (formData.repName.value == "")
      formData.repName.error = context.t("_rep_name_required");

    if (formData.repName.value != "" && !nameValidate.test(formData.repName.value))
      formData.repName.error = context.t("_invalid_rep_name");

    if (formData.repName.value != "" && nameValidate.test(formData.repName.value) && !nameWordLengthCheck(formData.repName.value))
      formData.repName.error = context.t("_rep_name_contains_atleast_two_word");

    if (formData.repNameArabic.value != "" && nameValidate.test(formData.repNameArabic.value) && !nameWordLengthCheck(formData.repNameArabic.value))
      formData.repNameArabic.error = context.t("_rep_name_contains_atleast_two_word");

    if (formData.qid.value == "")
      formData.qid.error = context.t("_qid_required");

    if (formData.qid.value != "" && !qidValidate.test(formData.qid.value))
      formData.qid.error = context.t("_invalid_qid");

    if (formData.repPosition.value == "")
      formData.repPosition.error = context.t("_rep_position_required");

    if (formData.repMobile.value == "")
      formData.repMobile.error = context.t("_rep_mobile_required");

    if (formData.repMobile.value != "" && !phoneValidate.test(formData.repMobile.value.replace(/ /g, "")))
      formData.repMobile.error = context.t("_invalid_rep_mobile");

    if (formData.repMobile.value != "")
      if (formData.repMobile.value.toString().startsWith("3") || formData.repMobile.value.toString().startsWith("5") || formData.repMobile.value.toString().startsWith("6") || formData.repMobile.value.toString().startsWith("7")) {

      } else {
        formData.repMobile.error = context.t("_invalid_rep_mobile");
      }

    if (formData.repEmail.value == "")
      formData.repEmail.error = context.t("_rep_email_required");

    if (formData.repEmail.value != "" && !emailValidate.test(formData.repEmail.value))
      formData.repEmail.error = context.t("_invalid_rep_email");

    // if (formData.channel.value == "")
    //   formData.channel.error = context.t("_channel_of_communication_required");

    if (!(formData.agree.value))
      formData.agree.error = context.t("_terms_and_condition_agree");

    for (var key in formData) {
      if (formData[key].error != "") {
        setFormData({...formData})
        setSubmissionStatus(false)
        scrollToTop();
        setTimeout(() => {
          // this.refs.errorListRef.focus()
          // this[key + "error"].focus()
                    document.querySelector(`a[href="#${key}"]`).focus()

        }, 1000);
        return false;

      }
    }
    setFormData({...formData})
    return true;
  }
  const  displayError = error => {

    if (error.mail)
      formData.repEmail.error = error.mail[lang]

    if (error.name)
      formData.schoolName.error = error.name[lang]

    if (error.school_email)
      formData.schoolEmail.error = error.school_email[lang]

    if (error.School)
      formData.schoolName.error = error.School[lang]

    if (error.school_mob)
      formData.schoolPhone.error = error.school_mob[lang]

    if (error.repname)
      formData.repName.error = error.repname[lang]

    if (error.qid)
      formData.qid.error = error.qid[lang]

    if (error.repmail)
      formData.repEmail.error = error.repmail[lang]

    if (error.rep_mob)
      formData.repMobile.error = error.rep_mob[lang]

    if (error.position)
      formData.repPosition.error = error.position[lang]

    for (var key in formData) {
      if (formData[key].error != "") {
        setFormData({...formData})
        scrollToTop();
        setTimeout(() => {
          // this.refs.errorListRef.focus()
          // this[key + "error"].focus()
          document.querySelector(`a[href="#${key}"]`).focus()

        }, 1000);
        return false;

      }
    }
    setFormData({...formData})
    // this.setState({ formData })
    scrollToTop();

  }
  const onSubmit = async (event) => {
    event.preventDefault();
    // schoolName.focus();
    if (validate()) {
      dispatch(LoadingAction.showLoader())
      let schoolName = schoolList.filter(item => item.id == formData.schoolName.value)
      if (formData.schoolName.value == "0")
        schoolName = formData.schoolNewName.value
      else
        schoolName = schoolName[0].sname
      const obj = {
        "name": formData.repEmail.value,
        "mail": formData.repEmail.value,
        "school_id": {
          "target_id": formData.schoolName.value,
          "target_type": "taxonomy_term"
        },
        "school_name": schoolName,
        "school_mob": formData.schoolPhone.value.replace(/ /g, ""),
        "school_email": formData.schoolEmail.value,
        "repname": formData.repName.value,
        "field_representative_name_arabic": formData.repNameArabic.value,
        "field_school_reps_qid": formData.qid.value,
        "position": formData.repPosition.value,
        "rep_mob": `+974${formData.repMobile.value.replace(/ /g, "")}`,
        "repmail": formData.repEmail.value,
        // "communication_value": formData.channel.value,
        "field_reference": {
          "target_id": ""
        },
        "roles": {
          "target_id": "school"
        }

      }
      const url = `${configuration.api_url}${urlEndPoints.registerSchoolRep}`
      try {
        const data = await networkRequest(url, "POST", obj, null, true)
        if (data.data.error) {

          displayError(data.data.error)

        } else {
          setSubmissionStatus(true)

          initState();
          M.AutoInit();
          successMessageRef.current.focus()
          // this.scrollToTop();
        }
        dispatch(LoadingAction.hideLoader());
      } catch (error) {
        dispatch(LoadingAction.hideLoader())
        console.warn("error: ", error)
      }
    }

  }
  const handleCloseModal = () => {
    let elems = document.querySelector("#termsAndConditionModal");
    elems.removeAttribute("aria-hidden")
    elems.removeAttribute("tabindex")
    let instance = M.Modal.getInstance(elems);
    instance.close();
  }
 const handleOpenModal = () => {
    let elems = document.querySelector("#termsAndConditionModal");
    elems.setAttribute("aria-hidden", "false")
    elems.setAttribute("tabindex", "-1")
    let instance = M.Modal.getInstance(elems);
    instance.open();
  }
    // const { formData, schoolList, submissionStatus, termsAndCondition } = this.state;
    // const { lang } = this.props;
    let hasError = false;
    Object.keys(formData).map((item, index) => {
      if (formData[item].error != "")
        hasError = true
    })
    return (
      <div id="main-container">
        <SlideToTop />
        <PageTitle title={context.t("_registration")} />
        <div className="container">
          <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
            <ul>
              <li className="breadcrumb-item">
                <Link to="/registration">{context.t("_registration")}</Link> {" "}

              </li>
              <li className="breadcrumb-item">
                <Link tabIndex="0" to aria-current={context.t("_page")}>
                  <span>
                    {context.t("_school")}
                  </span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="school-registration-container">
          <span className="grey-square-rotate red-sq one"></span>
          <span className="grey-square-rotate red-sq two"></span>
          <span className="grey-square-rotate red-sq three"></span>
          <span className="orange-circle one"></span>
          <span className="orange-circle two"></span>
          <span className="multi-square one">
            <b>
              <i></i>
            </b>
          </span>
          <span className="multi-square two">
            <b>
              <i></i>
            </b>
          </span>
          <span className="multi-square three">
            <b>
              <i></i>
            </b>
          </span>
          <span className="multi-square four">
            <b>
              <i></i>
            </b>
          </span>
          <div id="skipContent" className="container registration-form">
            <RegisterTypes {...props} />
            <h2 tabIndex="0"  aria-label={context.t("_for_school_representative_form")} className="primary-heading" style={dynamicStyles.textAlign(lang)}>{context.t("_for_school_representative")}</h2>
            <div className="row">
              <div className="col s6 form-container">
                <p style={dynamicStyles.textAlign(lang)}>
                  {context.t("_required_field")}{" "}
                  <span className="asterisk">*</span>
                </p>

                {
                  (hasError && !submissionStatus) ? <div className="error-container fail">
                    <h5>{context.t("_error_title")}</h5>
                    <ul aria-label="error" >
                      {
                        Object.keys(formData).map((item, index) => {
                          return formData[item].error != "" ? <li key={index} ><a href={`#${item}`}  tabIndex="0" className="error" htmlFor={item}>{formData[item].error}</a></li>
                            :
                            null
                        })}
                    </ul>
                  </div> : null

                }
                {
                  submissionStatus ? <div className="error-container success" tabIndex="0" ref={successMessageRef}>
                    <h5>{context.t("_success_title_student_1")}</h5>
                    <p>{context.t("_success_title_student_2")}</p>
                    <p  >{context.t("_success_title_student_3")}</p>
                  </div> : null
                }
                {
                  _.isEmpty(formData) ?
                    <div /> :
                    <form onSubmit={onSubmit} autoComplete="no">
                      <div className="result-type">
                        {
                          renderSelect({
                            name: "schoolName", label: context.t("_school_name"), placeholder: context.t("_select_school_name"), isMandatory: true, data: schoolList
                          })
                        }
                      </div>
                      {
                        formData.schoolName.value == "0" && renderInput({ name: "schoolNewName", label: context.t("_school_name"), placeholder: context.t("_select_school_name"), isMandatory: true })
                      }
                      {
                        formData.schoolName.value == "0" && renderInput({ name: "schoolPhone", label: context.t("_school_phone_number"), placeholder: context.t("_school_phone_number_placeholder"), isMandatory: true, disabled: formData.schoolName.value != "0", type: "phone_number", max: 8 })
                      }
                      {
                        formData.schoolName.value == "0" && renderInput({ name: "schoolEmail", label: context.t("_school_email_address"), placeholder: context.t("_school_email_address_placeholder"), isMandatory: true, type: "email", disabled: formData.schoolName.value != "0", styleClass: "fontEnglish" })
                      }
                      <p><br />{context.t("_school_representative")} </p>

                      {renderInput({ name: "repName", label: context.t("_name_english"), placeholder: context.t("_name_english_placeholder"), isMandatory: true })}
                      {renderInput({ name: "repNameArabic", label: context.t("_name_arabic"), placeholder: context.t("_name_arabic_placeholder"), isMandatory: false })}
                      {renderInput({ name: "qid", label: context.t("_qid"), placeholder: context.t("_qid_placeholder"), isMandatory: true, max: 11 })}
                      {renderInput({ name: "repPosition", label: context.t("_position"), placeholder: context.t("_position_placeholder"), isMandatory: true })}
                      {renderInput({ name: "repMobile", label: context.t("_mobile_number"), placeholder: context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max: 8 })}
                      {renderInput({ name: "repEmail", label: context.t("_email"), placeholder: context.t("_email_placeholder"), isMandatory: true, type: "email", styleClass: "fontEnglish" })}
                      {/* {
                        renderRadioButton({ label: context.t("_channel_of_communication"), name: "channel", defaultChecked: "sms", isMandatory: true, data: [{ label: context.t("_email"), value: "Email Address" }, { label: context.t("_sms"), value: "Mobile Number" }, { label: context.t("_phone_call"), value: "Phone Call" }] })
                      } */}
                      {renderCheckbox({ label: context.t("_terms_and_condition"), name: "agree" })}
                      <div className="btn-wrap">
                        <button className="btn blue">{context.t("_registration")}</button>
                      </div>
                    </form>
                }

              </div>
              <div className="col s6" />
            </div>
            <div id="termsAndConditionModal" className="modal" role="dialog">
              <div className="modal-content">
                <span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => handleCloseModal()}><i className="material-icons">close</i></button></span>
                <h4>{termsAndCondition.title}</h4>
                <div className="description" dangerouslySetInnerHTML={{ __html: termsAndCondition.body }}></div>

                <button className={`modal-close waves-effect waves-green btn blue ${lang == "ar" ? "pull-left" : "pull-right"}`}>{context.t("_agree")}</button>
              </div>
            </div>
          </div>
        </div>

        <div className="top-line ng-scope">
          <img src={topLineImage} alt="" />
        </div>
      </div>
    );
  
}

SchoolRepresentativeRegistration.contextTypes = {
  t: PropTypes.func
}
export default SchoolRepresentativeRegistration