import { GET_TOTAL_ACTIVITIES, GET_CURRENT_ACTIVITIES, GET_COMPLETED_ACTIVITIES, GET_UPCOMING_ACTIVITIES } from "./../../constants/constants";

const initialState = {
    totalActivityCount: 0,
    currentActivityCount: 0,
    completedActivityCount: 0,
    completedActivityCount: 0,
    upcomingActivityCount: 0,
};

const activityCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TOTAL_ACTIVITIES:
            return {
                ...state,
                totalActivityCount: action.payload
            };
        case GET_CURRENT_ACTIVITIES:
            return {
                ...state,
                currentActivityCount: action.payload
            };
        case GET_COMPLETED_ACTIVITIES:
            return {
                ...state,
                completedActivityCount: action.payload
            };
        case GET_UPCOMING_ACTIVITIES:
            return {
                ...state,
                upcomingActivityCount: action.payload
            };
        default:
            return { ...state };
    }
};

export default activityCountReducer;
