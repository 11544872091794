import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import I18n from "redux-i18n"
import Routes from "./route/routes";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/store";
import { translations } from "./constants/translations"

const rootElement = createRoot(document.getElementById('root'));
rootElement.render(
  <Provider store={configureStore()}>
    <I18n translations={translations} initialLang={localStorage.getItem("lang") || "en"}>
      <Routes />
    </I18n>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
