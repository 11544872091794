import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Main from "../../constants/utilities"
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";
import M from "materialize-css"
import PropTypes from "prop-types"
import { useNavigate } from 'react-router-dom';
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { LoadingAction, PopupAction } from "./../../actions"
import { emailValidate, nameValidate, phoneValidate, nameWordLengthCheck } from "./../../constants/common"
import _ from "lodash"
import topLineImage from "../../assets/images/top-line.png"
import contactImage from "../../assets/images/contact-right.png"

const ContactUs = (props, context) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { config, networkRequest } = Main();
    const configure = config();
    const [formData, setFormData] = useState({});
    const [contactDetails, setContactDetails] = useState({});
    const [privacyPolicyBody, setPrivacyPolicyBody] = useState("");
    const [privacyPolicyTitle, setPrivacyPolicyTitle] = useState("");

    const { lang } = useSelector(
        state => state.i18nState
    );

    useEffect(() => {
        M.AutoInit();
        document.title = `${context.t("_studio56")} | ${context.t("_contact_us")}`;
        dispatch(LoadingAction.showLoader())
        initState();
        getContactDetails();
        getPrivacyPolicy();
        setTimeout(() => {
            const elems = document.querySelectorAll(".modal");
            M.Modal.init(elems, {
                autoClose: true,
                dismissible: true,
            });
        }, 200);
    }, []);

    const initState = () => {
        setFormData({
            name: { value: "", error: "", class: "" },
            email: { value: "", error: "", class: "" },
            mobile: { value: "", error: "", class: "" },
            iagree: { value: false, error: "", class: "" },
            message: { value: "", error: "", class: "" }
        })
    }

    const getContactDetails = async () => {
        let url = "";
        if (lang === "ar") {
            url = `${configure.origin}${lang}/api/${urlEndPoints.getContactUs}`
        }
        else {
            url = `${configure.origin}api/${urlEndPoints.getContactUs}`
        }
        try {
            const data = await networkRequest(url)
            if (data.data.error) {
                console.warn("error: ", data.data.error)
            } else {
                setContactDetails(data.data[0])
            }
            dispatch(LoadingAction.hideLoader())
            document.getElementById("pageHeading") && document.getElementById("pageHeading").focus()
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }

    const getPrivacyPolicy = async () => {
        let url = ""
        if (lang === "ar") {
            url = `${configure.origin}${lang}/${urlEndPoints.getPrivacyPolicy}`
        }
        else {
            url = `${configure.origin}${urlEndPoints.getPrivacyPolicy}`
        }
        try {
            const data = await networkRequest(url)
            if (data.status === 200 && data.data && data.data.length > 0) {
                setPrivacyPolicyBody(data.data[0].body)
                setPrivacyPolicyTitle(data.data[0].title)
            }
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }

    const handleChange = (field, value) => {
        if (field === "mobile" && isNaN(value)) {
            return false
        }
        const updatedFormData = { ...formData };
        updatedFormData[field]["class"] = value ? "" : "invalid";
        updatedFormData[field]["value"] = value;
        setFormData(updatedFormData);
    };

    const validate = () => {
        const updatedFormData = { ...formData };
        for (const key in updatedFormData) {
            if (updatedFormData[key].value)
                updatedFormData[key].value = typeof (updatedFormData[key].value) == "boolean" ? updatedFormData[key].value : updatedFormData[key].value.trim();
            updatedFormData[key].error = "";
        }

        if (_.isEmpty(updatedFormData.name.value))
            updatedFormData.name.error = context.t("_name_required");

        if (!_.isEmpty(updatedFormData.name.value) && !nameValidate.test(updatedFormData.name.value))
            updatedFormData.name.error = context.t("_invalid_name");

        if (!_.isEmpty(updatedFormData.name.value) && nameValidate.test(updatedFormData.name.value) && !nameWordLengthCheck(updatedFormData.name.value))
            updatedFormData.name.error = context.t("_name_contain_atleast_two_words");

        if (_.isEmpty(updatedFormData.email.value))
            updatedFormData.email.error = context.t("_email_required");

        if (!_.isEmpty(updatedFormData.email.value) && !emailValidate.test(updatedFormData.email.value))
            updatedFormData.email.error = context.t("_invalid_email");

        if (_.isEmpty(updatedFormData.mobile.value))
            updatedFormData.mobile.error = context.t("_mobile_number_required");

        if (!_.isEmpty(updatedFormData.mobile.value) && !phoneValidate.test(updatedFormData.mobile.value.replace(/ /g, "")))
            updatedFormData.mobile.error = context.t("_invalid_mobile_number");

        if (_.isEmpty(updatedFormData.message.value))
            updatedFormData.message.error = context.t("_message_required");

        if (!updatedFormData.iagree.value)
            updatedFormData.iagree.error = context.t("_agree_required");


        for (const key in updatedFormData) {
            if (updatedFormData[key].error !== "") {
                setFormData(updatedFormData)
                // this.setState({ formData, submissionStatus: false, submissionStatus_1: false })
                setTimeout(() => {
                    document.querySelector(`a[href="#${key}"]`).focus()
                        }, 1000);
                scrollToTop();
                return false;
            }
        }
        setFormData(updatedFormData);
        return true;
    }
    const onSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            dispatch(LoadingAction.showLoader())
            const url = `${configure.origin}${urlEndPoints.webFormSubmit}`;
            const obj = {
                name: formData.name.value,
                email: formData.email.value,
                mobile_number: `+974${formData.mobile.value}`,
                message: formData.message.value,
                agree: formData.iagree.value,
                webform_id: "contact_us"
            }
            try {
                const data = await networkRequest(url, "POST", obj, null, true)
                if (data.data.error) {
                    // displayError(data.data.error)
                    scrollToTop()
                    dispatch(LoadingAction.hideLoader())

                }
                else {
                    if (!_.isEmpty(data.data.sid)) {
                        // this.props.showPopup({
                        //     type: "success",
                        //     title: this.context.t("_success"),
                        //     message: this.context.t("_contact_request_success"),
                        //     isOpen: true,
                        //     buttonText: this.context.t("_ok")
                        // })
                        navigate("/contact-success");
                    } else {
                        dispatch(PopupAction.showPopup({
                            type: "error",
                            title: context.t("_error"),
                            message: context.t("_contact_request_fail"),
                            isOpen: true,
                            buttonText: context.t("_ok")
                        }))
                    }
                    initState();
                    scrollToTop()
                    dispatch(LoadingAction.hideLoader())
                    // await this.networkRequest(sendNotificationUrl)
                }
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
        }
    }
    const scrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" })
    }
    const getMapEmbeddedUrl = url => {
        const coords = /\@([0-9\.\,\-a-zA-Z]*)/.exec(url);
        if (coords != null) {
            const coordsArray = coords[1].split(",");
            return "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d" + coordsArray[1] + "!3d" + coordsArray[0] + "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098";
        }
    }
    const handleCloseModal = () => {
        const elems = document.querySelector("#modal1");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        const instance = M.Modal.getInstance(elems);
        instance.close();
    }
    const handleOpenModal = () => {
        const elems = document.querySelector("#modal1");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        const instance = M.Modal.getInstance(elems);
        instance.open();
    }

    let hasError = false;
    Object.keys(formData).map(item => {
        if (formData[item].error !== "") {
            hasError = true
        }
    })

    return (
        <div id="main-container">
            <SlideToTop />
            <PageTitle title={context.t("_contact_us")} isDashboardItem={true} />
            <div id="skipContent" className="container contact">
                <h2 tabIndex="0" id="contactUsHeading" aria-label={context.t("_contact_us_page")}>{context.t("_make_an_enquiry")}</h2>
                <div className="row noFlex">
                    <div className="col s12 m12 l12 xl7">
                        <p><strong>{context.t("_thankyou_for_interest")}</strong></p>
                        <p>{context.t("_subscribe_heading_2")}</p>
                        <div className="row noFlex">
                            {
                                hasError ? <div className="error-container fail" tabIndex="0">
                                    <h5>{context.t("_error_title")}</h5>
                                    <ul>
                                        {Object.keys(formData).map((item, index) => (
                                            formData[item].error !== "" ? (
                                                <li key={index}>
                                                    <a
                                                        href={`#${item}`}
                                                        tabIndex="0"
                                                        className="error"
                                                        htmlFor={item}
                                                    >
                                                        {formData[item].error}
                                                    </a>
                                                </li>
                                            ) : null
                                        ))}
                                    </ul>
                                </div> : null
                            }
                            <div className="col s12 m10 l9">
                                {
                                    _.isEmpty(formData) ?
                                        <div />
                                        :
                                        <form onSubmit={onSubmit} >
                                            <div className="row noFlex">
                                                <div className="col s12 m6 l6">
                                                    <div className="input-field item">
                                                        <input
                                                            id="name"
                                                            name="name"
                                                            value={formData.name.value}
                                                            autoComplete="no"
                                                            onChange={(evt) => handleChange("name", evt.target.value)}
                                                            onFocus={(evt) => handleChange("name", evt.target.value)}
                                                            placeholder={context.t("_name_placeholder_1")}
                                                            type="text"
                                                        />
                                                        <label htmlFor="name" className="active">{context.t("_name")}<span className="asterisk">*</span></label>
                                                    </div>
                                                </div>

                                                <div className="col s12 m6 l6">
                                                    <div className="input-field item">
                                                        <input
                                                            id="email"
                                                            name="email"
                                                            value={formData.email.value}
                                                            autoComplete="no"
                                                            onChange={(evt) => handleChange("email", evt.target.value)}
                                                            onFocus={(evt) => handleChange("email", evt.target.value)} placeholder={context.t("_email_placeholder")}
                                                            type="text"
                                                        />
                                                        <label htmlFor="email" className="active">{context.t("_email")}<span className="asterisk">*</span></label>
                                                    </div>
                                                </div>

                                                <div className="col s12 m6 l6">
                                                    <div className="input-field item">
                                                        <input
                                                            id="mobile"
                                                            name="mobile"
                                                            value={formData.mobile.value}
                                                            autoComplete="no"
                                                            onChange={(evt) => handleChange("mobile", evt.target.value)}
                                                            onFocus={(evt) => handleChange("mobile", evt.target.value)} placeholder={context.t("_mobile_number_placeholder")}
                                                            maxLength={8}
                                                            type="text"
                                                        />
                                                        <label htmlFor="mobile" className="active">{context.t("_mobile_number")}<span className="asterisk">*</span></label>
                                                    </div>
                                                </div>

                                                <div className="col s12 m12">
                                                    <div className="input-field item">
                                                        <textarea
                                                            id="message"
                                                            name="message"
                                                            value={formData.message.value}
                                                            onChange={(evt) => handleChange("message", evt.target.value)}
                                                            onFocus={(evt) => handleChange("message", evt.target.value)} placeholder={context.t("_message_placeholder")}
                                                            rows="8"
                                                        // maxLength={8}
                                                        // type="text"
                                                        />
                                                        <label htmlFor="message" className="active">{context.t("_message")}<span className="asterisk">*</span></label>
                                                    </div>
                                                </div>

                                                {/* <div className="col s12 m12">
                                                        <div className="form-check">
                                                            <div className="text-item"><h4>{this.context.t("_type_of_inquiry")}</h4></div>
                                                            <div className="input-radio">
                                                                <div>
                                                                    <label>
                                                                        <input
                                                                            onClick={evt =>
                                                                                this.handleChange("type", "Option One")
                                                                            }
                                                                            checked={formData.type.value == "Option One"}
                                                                            name="best_subscribe"
                                                                            type="radio"
                                                                            className="radio-btn"
                                                                        /><span>Option One</span>
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <label>
                                                                        <input
                                                                            onClick={evt =>
                                                                                this.handleChange("type", "Option Two")
                                                                            }
                                                                            checked={formData.type.value == "Option Two"}
                                                                            name="best_subscribe"
                                                                            type="radio"
                                                                            className="radio-btn"
                                                                        /><span>Option Two</span>
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <label>
                                                                        <input
                                                                            onClick={evt =>
                                                                                this.handleChange("type", "Option Three")
                                                                            }
                                                                            checked={formData.type.value == "Option Three"}
                                                                            name="best_subscribe"
                                                                            type="radio"
                                                                            className="radio-btn"
                                                                        /><span>Option Three</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                <div className="col s12 m12">
                                                    <div className="input-check subscribe-items checkboxNew"><br />
                                                        <input
                                                            id="iagree"
                                                            name="iagree"
                                                            type="checkbox"
                                                            checked={formData.iagree.value}
                                                            onChange={evt => handleChange("iagree", evt.target.checked)}
                                                            className="checkbox-holder" required=""
                                                        />
                                                        <label for="iagree">
                                                            <span className={lang}>{context.t("_i_agree")}  <button type="button" style={{ border: 0, background: "none" }} tabindex="0" aria-label={context.t("_read_privacy_policy")} className="modal-trigger underline-text" onClick={() => handleOpenModal()}>{context.t("_privacy_policy")}</button>.</span>
                                                        </label>
                                                    </div>
                                                    <div className="btn-wrap">
                                                        <button aria-label="submit" type="submit" className="btn red waves-effect waves-light">{context.t("_submit")}
                                                            <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col s12 m12 l12 xl5">
                        <img alt="" src={contactImage} />
                    </div>
                </div>
            </div>
            <div id="modal1" className="modal" role="dialog">
                <div className="modal-content modal-no-close">
                    <span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => handleCloseModal()}><i className="material-icons">close</i></button></span>
                    <div><h3 dangerouslySetInnerHTML={{ __html: privacyPolicyTitle }}></h3></div>
                    <div dangerouslySetInnerHTML={{ __html: privacyPolicyBody }}>
                    </div>
                </div>
                <div className="modal-footer">
                    <a href="#!" className="modal-close waves-effect waves-green btn-flat">{context.t("_ok")}</a>
                </div>
            </div>
            <div className="bgGrey">
                <div className="container">

                    <p><strong>{context.t("_general_inquiries")}</strong><br />
                        {context.t("_feel_free_to_reach_out")}</p>
                    {
                        _.isEmpty(contactDetails) ?
                            <div /> :
                            <div className="mapHolder">
                                <div className="addressBox">
                                    <p className="location">
                                        <a aria-label={`call to ${contactDetails.field_contact_number}`} href={`tel:${contactDetails.field_contact_number}`} className="fontEnglish"><i className="fa fa-phone"></i> {contactDetails.field_contact_number}</a><br />
                                        <a aria-label={`email to ${contactDetails.field_google_map_email}`} href={`mailto:${contactDetails.field_google_map_email}`}><i className="fa fa-envelope-o"></i> {contactDetails.field_google_map_email}</a>
                                    </p>

                                    <p><strong>{contactDetails.title}</strong></p>
                                    <div dangerouslySetInnerHTML={{ __html: contactDetails.field_address_line }} />
                                </div>
                                <iframe title="map" src={getMapEmbeddedUrl(contactDetails.field_map_location)} width="100%" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" ></iframe>
                            </div>
                    }
                </div>
            </div>
            <div className="top-line ng-scope">
                <img src={topLineImage} alt="" />
            </div>
        </div>
    );
}
ContactUs.contextTypes = {
    t: PropTypes.func
}
export default ContactUs;
