import React, { Component } from "react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"


const Summer = () => {
    const navigate = useNavigate()
    useEffect(()=>{
        navigate("/news/3137")
    },[])
  return (
    <div>

    </div>
  )
}

export default Summer
