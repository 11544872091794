import React from "react"
import Main from "../../../constants/utilities.js"
import SlideToTop from "../../includes/slide_to_top.js"
import PageTitle from "../../includes/page_title.js"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { dynamicStyles, customModalStyles } from "./../../../constants/dynamicStyles"
import { calenderI18n, CustomDateInput, calenderLocal } from "./../../../constants/common"
import { Link, useNavigate } from "react-router-dom"
import Pagination from "react-js-pagination";
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import M from "materialize-css"
import { LoadingAction, PopupAction } from "./../../../actions"
import _ from "lodash"
import topLineImage from "../../../assets/images/top-line.png"
import Modal from "react-modal";
import dateformat from "dateformat"
import { getType } from "./../../dashboard/pages/utilFunctions"
import { useEffect, useState } from "react"
import { useRef } from "react"

const ActivityHistory = (props, context) => {
	const [cancelActivityConfirm, setCancelActivityConfirm] = useState(false);
	const [cancelActivityId, setCancelActivityId] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [currentActivityList, setCurrentActivityList] = useState([]);
	const [pager, setPager] = useState({ count: 0, pages: 0, items_per_page: 0, current_page: 1 });
	const [search, setSearch] = useState({ type: "All", title: "", start_date: "", end_date: "", page: 0 });
	const [studentList, setStudentList] = useState([]);
	const [checkedList, setCheckedList] = useState([]);
	const [selectedActivityId, setSelectedActivityId] = useState(null);
	const [listActivity, setListActivity] = useState([]);
	const myFormRef = useRef()
	const intervalId = 0;
	const scrollStepInPx = 50;
	const delayInMs = 16.66;

	const { config, networkRequest } = Main()
	const configure = config()
	const { lang } = useSelector(state => state.i18nState)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleTabIndex = () => {
		setTimeout(() => {
			let elem = document.querySelectorAll('select');
			elem.forEach(element => {
				element.setAttribute("tabindex", "0");
			});
		}, 1000);
	}
	function initSearchComponents() {
		var options = { autoClose: true, format: "dd/mm/yyyy" };
		var start_date_options = {
			autoClose: true,
			format: "dd/mm/yyyy",
			showClearBtn: true,
			onSelect: ((date) => {
				handleChange("start_date", dateformat(date, "dd/mm/yyyy"))
			}),
		};
		var end_date_options = {
			autoClose: true,
			format: "dd/mm/yyyy",
			showClearBtn: true,
			onSelect: ((date) => {
				handleChange("end_date", dateformat(date, "dd/mm/yyyy"))
			}),
		};
		setTimeout(() => {
			let start_date = document.querySelectorAll("#start_date");
			let end_date = document.querySelectorAll("#end_date");
			start_date_options.i18n = calenderI18n(context);
			end_date_options.i18n = calenderI18n(context);
			// M.Datepicker.init(start_date, start_date_options);
			// M.Datepicker.init(end_date, end_date_options);
			let elems = document.querySelectorAll("select");
			M.FormSelect.init(elems, options);
		}, 1000);
	}
	const getChildren = async () => {
		const url = `${configure.api_url}${urlEndPoints.myChildren}`

		try {
			const data = await networkRequest(url)
			dispatch(LoadingAction.hideLoader());
			if (!data.data.message) {
				return data.data.results && data.data.results.length > 0 ? data.data.results : []
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
		// }
	}
	const getActivity = async () => {
		let url = ""
		if (localStorage.getItem("role") == "trainer" || localStorage.getItem("role") == "asistant") {
			if (lang == "ar")
				url = `${configure.origin}${lang}/api/${urlEndPoints.trainerActivityHistory}?`
			else
				url = `${configure.api_url}${urlEndPoints.trainerActivityHistory}?`
		}
		if (localStorage.getItem("role") == "manager" || localStorage.getItem("role") == "edu_specialist") {
			if (lang == "ar")
				url = `${configure.origin}${lang}/api/${urlEndPoints.managerActivityHistory}?`
			else
				url = `${configure.api_url}${urlEndPoints.managerActivityHistory}?`
		}

		if (!_.isEmpty(search.title) && search.title != "All")
			url = `${url}&field_event_type_value=${search.title}`
		if (search.start_date)
			url = `${url}&field_start_end_dates_value=${dateformat(search.start_date, "dd-mm-yyyy")}`
		if (search.end_date)
			url = `${url}&field_start_end_dates_end_value=${dateformat(search.end_date, "dd-mm-yyyy")}`
		if (search.page)
			url = `${url}&page=${search.page}`

		try {
			const data = await networkRequest(url)
			if (data.status == 200) {
				scrollToTop()
				dispatch(LoadingAction.hideLoader())
				const list = data.data.results;
				list.forEach(async item => {
					item.field_start_and_end_time_2 = item.field_start_and_end_time_2.split("*")
					item.field_start_and_end_time_2 = Math.min.apply(null, item.field_start_and_end_time_2);
					item.field_start_and_end_time_3 = item.field_start_and_end_time_3.split("*")
					item.field_start_and_end_time_4 = item.field_start_and_end_time_4.split("*")
				});
				setCurrentActivityList(list)
				setPager(data.data.pager)
				setIsLoaded(true)
				setCheckedList({})
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
		// }
	}
	const calculateAge = (dateString) => {
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}
	const handleCheck = (event, id, index) => {
		if (event.target.checked)
			checkedList[index] = id
		else
			delete checkedList[index]
		setCheckedList({ ...checkedList })
	}
	function handlePageChange(pageNumber) {
		dispatch(LoadingAction.showLoader())
		search.page = pageNumber - 1;
		setSearch({ ...search })
		getActivity();
	}
	const handleChange = (field, value) => {
		search[field] = value;
		setSearch({ ...search })
	}
	const handelCancelActivity = async id => {
		dispatch(LoadingAction.showLoader())
		// const authResponse = await this.auth();
		// if (authResponse) {
		const url = `${configure.api_url}${urlEndPoints.cancelActivity}`
		const obj = {
			"activity_id": id
		}
		// const headers = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		try {
			const data = await networkRequest(url, "POST", obj);
			if (data.status == 200) {
				if (!_.isEmpty(data.data.error)) {
					dispatch(PopupAction.showPopup({
						type: "error",
						title: context.t("_error"),
						message: data.data.error.name[lang],
						isOpen: true,
						buttonText: context.t("_ok")
					}))
				} else {
					dispatch(PopupAction.showPopup({
						type: "success",
						title: context.t("_success"),
						message: context.t("_successfully_cancelled"),
						isOpen: true,
						buttonText: context.t("_ok")
					}))
				}

				getActivity()
			}
		} catch (error) {
			dispatch(PopupAction.showPopup({
				type: "error",
				title: context.t("_failed"),
				message: context.t("_failed_to_cancel"),
				isOpen: true,
				buttonText: context.t("_ok")
			}))
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
		// }
	}
	const filterFormSubmit = event => {
		event.preventDefault();
		dispatch(LoadingAction.showLoader())
		getActivity();
	}
	const clearSearch = async () => {
		dispatch(LoadingAction.showLoader())
		myFormRef.current.reset()
		// let elems = document.querySelector("#start_date");
		// let instance = M.Datepicker.getInstance(elems);
		// instance.setInputValue();
		// elems = document.querySelector("#end_date");
		// instance = M.Datepicker.getInstance(elems);
		// instance.setInputValue();
		setSearch({ type: "All", title: "", start_date: "", end_date: "", page: 0 })
		getActivity()
	}
	const handleGetStudentList = async id => {
		dispatch(LoadingAction.showLoader())
		// const authResponse = await auth();
		// if (authResponse) {
		const url = `${configure.api_url}${urlEndPoints.enrolledUsers(id)}`

		// const headers = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		try {
			const data = await networkRequest(url)
			dispatch(LoadingAction.hideLoader());
			if (!data.data.message) {
				const studentList = _.orderBy(data.data.results, ["field_student_name"], ["asc"])
				setStudentList(studentList)
				setSelectedActivityId(id)

				let elems = document.querySelector("#enrollments");
				elems.setAttribute("aria-hidden", "false")
				elems.setAttribute("tabindex", "-1")
				let instance = M.Modal.getInstance(elems);
				instance.open();
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
		// } else {
		// 	props.hideLoader()
		// }
	}
	const handleCloseModal = () => {
		let elems = document.querySelector("#enrollments");
		elems.removeAttribute("aria-hidden")
		elems.removeAttribute("tabindex")
		let instance = M.Modal.getInstance(elems);
		instance.close();
	}
	const handleDetailRedirect = id => {
		navigate(`/activity/${id}`);
	}
	const handleCancelMultipleActivity = async () => {
		dispatch(LoadingAction.showLoader());
		if (!_.isEmpty(checkedList)) {
			// const authResponse = await auth();
			// if (authResponse) {
			const url = `${configure.api_url}${urlEndPoints.cancelActivity}`
			const obj = {
				"activity_id": selectedActivityId,
				user_ids: checkedList
			}
			// const headers = {
			// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
			// }
			try {
				const data = await networkRequest(url, "POST", obj);
				if (data.status == 200) {
					if (!_.isEmpty(data.data.error)) {
						dispatch(PopupAction.showPopup({
							type: "error",
							title: context.t("_error"),
							message: data.data.error.name[lang],
							isOpen: true,
							buttonText: context.t("_ok")
						}))
					} else {
						dispatch(PopupAction.showPopup({
							type: "success",
							title: context.t("_success"),
							message: context.t("_successfully_cancelled"),
							isOpen: true,
							buttonText: context.t("_ok")
						}))
					}
					handleCloseModal();
					getActivity()
				}
			} catch (error) {
				dispatch(PopupAction.showPopup({
					type: "error",
					title: context.t("_failed"),
					message: context.t("_failed_to_cancel"),
					isOpen: true,
					buttonText: context.t("_ok")
				}))
				dispatch(LoadingAction.hideLoader())
				console.warn("error: ", error)
			}
			// }
		} else {
			handleCloseModal();
			getActivity()
		}

	}
	function scrollStep() {
		var scrollStep = props.scrollStepInPx ? props.scrollStepInPx : scrollStepInPx;
		if (window.pageYOffset === 0) {
			clearInterval(intervalId);
		}
		window.scroll(0, window.pageYOffset - scrollStep);
	}
	function scrollToTop() {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
		// var delayInMs = props.delayInMs ? props.delayInMs : state.delayInMs;
		// let intervalId = setInterval(scrollStep.bind(this), delayInMs);
		// setState({ intervalId: intervalId });
	}
	const handleCloseConfirmModal = (state) => {
		setCancelActivityConfirm(false)
		cancelActivityId(null)
	}
	const handleOpenCancelActivityConfirm = id => {
		cancelActivityConfirm(true)
		cancelActivityId(id)
	}
	const handleContinueCancelActivity = () => {
		if (!_.isEmpty(cancelActivityId))
			handelCancelActivity(cancelActivityId)
		handleCloseConfirmModal("cancelActivityConfirm")
	}
	const showStatus = status => {
		switch (status) {
			case "Unpublished":
				return context.t("_pending");
			case "Waiting List":
				return context.t("_waiting_list");

			default:
				return status;
		}
	}
	const handleAttendanceBtn = row => {
		// row.field_start_and_end_time_2 >= Math.round(new Date().getTime() / 1000)) ?

		let minDate = new Date();
		const weekBefore = minDate.getDate() - 7
		minDate.setDate(weekBefore);
		minDate = Math.round(minDate.getTime() / 1000)
		return (localStorage.getItem("role") == "manager" || localStorage.getItem("role") == "edu_specialist") || (row.field_start_and_end_time_5 > minDate) ? <Link to={`/attendance/${row.nid}`} className="btn noWidth  blue-btn waves-effect waves-light">{context.t("_mark")}</Link> : null
		// return <Link to={`/attendance/${row.nid}`} className="btn noWidth  blue-btn waves-effect waves-light">Mark attendance</Link>

	}
	const getDateInFormat = date => {
		// if (!_.isEmpty(date)) {
		if (date.includes("Jan"))
			date = date.replace("Jan", context.t("_jan"))

		if (date.includes("Feb"))
			date = date.replace("Feb", context.t("_feb"))

		if (date.includes("Mar"))
			date = date.replace("Mar", context.t("_mar"))

		if (date.includes("Apr"))
			date = date.replace("Apr", context.t("_apr"))

		if (date.includes("May"))
			date = date.replace("May", context.t("_may"))

		if (date.includes("Jun"))
			date = date.replace("Jun", context.t("_jun"))

		if (date.includes("Jul"))
			date = date.replace("Jul", context.t("_jul"))

		if (date.includes("Aug"))
			date = date.replace("Aug", context.t("_aug"))

		if (date.includes("Sep"))
			date = date.replace("Sep", context.t("_sep"))

		if (date.includes("Oct"))
			date = date.replace("Oct", context.t("_oct"))

		if (date.includes("Nov"))
			date = date.replace("Nov", context.t("_nov"))

		if (date.includes("Dec"))
			date = date.replace("Dec", context.t("_dec"))
		// }
		return date
	}

	useEffect(() => {
		(async () => {
			dispatch(LoadingAction.showLoader())
			document.title = `${context.t("_studio56")} | ${context.t("_attendance")}`
			await initSearchComponents();
			M.AutoInit();
			await getActivity();
			window.addEventListener('load', handleTabIndex());
			setTimeout(() => {
				let elems = document.querySelectorAll(".modal");
				M.Modal.init(elems, {
					autoClose: true,
					dismissible: true
				});
				setTimeout(() => {
					document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
				}, 500);
			}, 200);
		})()
	}, [])
	useEffect(() => {
		const filteredList = search.type === "All"
			? currentActivityList
			: currentActivityList.filter(data => data.field_event_type.toLowerCase() === search.type);

		setListActivity(filteredList);
	}, [search, currentActivityList]);
	return (
		<div id="main-content" className="activiti-list">
			<SlideToTop />
			<PageTitle title={context.t("_attendance")} />
			<div className="container">
				<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
					<ul>
						<li className="breadcrumb-item">
							<Link to="/admin-profile">{context.t("_profile")}</Link> {" "}

						</li>
						<li className="breadcrumb-item">
							<Link tabIndex="0" to aria-current={context.t("_page")}>
								<span>{context.t("_attendance")} </span>
							</Link>

						</li>
					</ul>
				</nav>
			</div>
			<div id="main-container">
				<div id="skipContent" className="container">
					<form onSubmit={filterFormSubmit} ref={myFormRef}>
						<div className="row topFormHolder">
							<div className="col s12 m6 l3">
								<div className={`input-field mobileSelect item ${lang}`}>
									<label htmlFor="activityType" className="active" style={dynamicStyles.position(lang)}>{context.t("_activity_type")}</label>
									<select className="browser-default" id="activityType" value={search.title} onChange={evt => handleChange("title", evt.target.value)}>
										<option value="" disabled >{context.t("_select_activity_type")}</option>
										<option value="All" >{context.t("_all")}</option>
										<option value="competition">{context.t("_competitions")}</option>
										<option value="session">{context.t("_sessions")}</option>
										<option value="event">{context.t("_events")}</option>
										<option value="challenge">{context.t("_challenges")}</option>
										<option value="project">{context.t("_projects")}</option>
									</select>
									<span className="helper-text"></span>
								</div>
							</div>

							<div className="col s12 m6 l3 date-popup">
								<div className="input-field item">
									<DatePicker
										name="Date"
										placeholderText={context.t("_date")}
										// ref={(input) => { this[name] = input; }}
										id="start_date"
										autoComplete="off"
										dateFormat="yyyy-MM-dd"
										onChange={date =>
											handleChange("start_date", date)
										}
										value={search.start_date}
										selected={search.start_date}
										locale={calenderLocal(context)}
										customInput={<CustomDateInput ariaLabel={context.t("_select_start_date_button")} onFieldChange={date => handleChange("start_date", new Date(date))} />}
										onCalendarOpen={e => {
											let elems = document.querySelector(".react-datepicker");
											elems.setAttribute("tabindex", "0")
											elems && elems.focus()
											elems = document.querySelector(".react-datepicker__month-select");
											elems.setAttribute("aria-label", context.t("_select_month"))
											elems = document.querySelector(".react-datepicker__year-select");
											elems.setAttribute("aria-label", context.t("_select_year"))

										}}
										onCalendarClose={e => {
											var elems = document.querySelector("#start_date");
											elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(search.start_date, "dd-mm-yyyy")}`)
											elems.setAttribute("value", dateformat(search.start_date, "yyyy-mm-dd"))
											setTimeout(() => {
												elems && elems.focus()
											}, 200);
										}}
										peekNextMonth
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
									/>
									<label htmlFor="start_date" style={dynamicStyles.position(lang)}>{context.t("_start_date")}</label>
								</div>
							</div>

							<div className="col s12 m6 l3 date-popup">
								<div className="input-field item">
									<DatePicker
										name="Date"
										placeholderText={context.t("_date")}
										// ref={(input) => { this[name] = input; }}
										id="end_date"
										autoComplete="off"
										dateFormat="yyyy-MM-dd"
										onChange={date =>
											handleChange("end_date", date)
										}
										value={search.end_date}
										selected={search.end_date}
										locale={calenderLocal(context)}
										customInput={<CustomDateInput ariaLabel={context.t("_select_end_date_button")} onFieldChange={date => handleChange("end_date", new Date(date))} />}
										onCalendarOpen={e => {
											let elems = document.querySelector(".react-datepicker");
											elems.setAttribute("tabindex", "0")
											elems && elems.focus()
											elems = document.querySelector(".react-datepicker__month-select");
											elems.setAttribute("aria-label", context.t("_select_month"))
											elems = document.querySelector(".react-datepicker__year-select");
											elems.setAttribute("aria-label", context.t("_select_year"))

										}}
										onCalendarClose={e => {
											var elems = document.querySelector("#end_date");
											elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(search.end_date, "dd-mm-yyyy")}`)
											elems.setAttribute("value", dateformat(search.end_date, "yyyy-mm-dd"))
											setTimeout(() => {
												elems && elems.focus()
											}, 200);
										}}
										peekNextMonth
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
									/>
									<label htmlFor="end_date" style={dynamicStyles.position(lang)}>{context.t("_end_date")}</label>
								</div>
							</div>

							<div className="col s12 m6 l3 valign-wrapper center-align search-btn-holder">
								<button className="btn noWidth profileBtn yellow waves-effect waves-light">
									{context.t("_search")} <i className={`tiny material-icons ${lang}`} >{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
								</button>
								<button type="button" onClick={() => clearSearch()} className="btn noWidth  blue-btn waves-effect waves-light">
									{context.t("_clear")}
								</button>
								<br /><br />
							</div>
						</div>
					</form>

					<div className="row">
						<div className="col s7 result-status">
							{isLoaded ? context.t("_search_result") + " ( " + (listActivity.length) + " of " + pager.count + " )" : ""}

						</div>
						<div className="col s5 result-type">
							<div className="input-field mobileSelect col s12 filter-select">
								<label htmlFor="result-type">{context.t("_select_activity")}</label>
								<select className="browser-default" id="result-type" value={search.type} onChange={evt => handleChange("type", evt.target.value)}>
									<option value="All" >{context.t("_all")}</option>
									<option value="competition">{context.t("_competitions")}</option>
									<option value="session">{context.t("_sessions")}</option>
									<option value="event">{context.t("_events")}</option>
									<option value="challenge">{context.t("_challenges")}</option>
									<option value="project">{context.t("_projects")}</option>
								</select>
							</div>
						</div>
					</div>
					<div className="activities">
						{listActivity.map((row, index) => (
							<div className="row" key={index}>
								<div className="col s7">
									<Link aria-label={`${row.title} ${context.t("_start_date")} ${row.field_start_and_end_time_3[0]} ${context.t("_end_date")} ${row.field_start_and_end_time_4[row.field_start_and_end_time_4.length - 1]}`} to={`/activity/${row.nid}`}>
										<h2 className="title">{row.title} <span className={row.field_event_type.toLowerCase()}>{getType(row.field_event_type, context)}</span></h2>
									</Link>

									<div className="details">
										{row.field_start_and_end_time_3 &&
											<span><i className="calendar-icons"></i> {context.t("_start_date")} <span className="date">{getDateInFormat(row.field_start_and_end_time_3[0])}</span></span>
										}
										{row.field_start_and_end_time_4 &&
											<span><i className="calendar-icons"></i> {context.t("_end_date")} <span className="date">{getDateInFormat(row.field_start_and_end_time_4[row.field_start_and_end_time_4.length - 1])}</span></span>
										}
									</div>
								</div>
								<div className="col s5 btn-wrap flex-row justify-end align-center">
									{/* {
											localStorage.getItem("role") == "volenteer" ?
												null :
												localStorage.getItem("role") == "student" ?
													<button onClick={() => handleOpenCancelActivityConfirm(row.nid)} className="btn blue-border">Cancel</button>
													:
													<button onClick={() => handleGetStudentList(row.nid)} className="btn blue-border">Enrollments</button>
										} */}
									{handleAttendanceBtn(row)}
								</div>
							</div>
						))}
						{!currentActivityList.length && isLoaded &&
							<div className="row"><p aria-label={context.t("_no_result_found")} className="col s12 no-result">{context.t("_no_result_found")}</p></div>
						}
					</div>

					<nav className="pagination-wrapper" aria-label="pagination">
						{(currentActivityList.length && isLoaded) ? (
							<Pagination
								prevPageText={`< ${context.t("_previous")}`}
								nextPageText={`${context.t("_next")} >`}
								hideFirstLastPages={true}
								hideDisabled={true}
								linkClassPrev="linkClassPrev"
								linkClassNext="linkClassNext"
								activePage={pager.current_page + 1}
								itemsCountPerPage={pager.items_per_page}
								totalItemsCount={pager.count}
								pageRangeDisplayed={5}
								onChange={handlePageChange}
							/>) : ""
						}
					</nav>
				</div>
				<div className="sparkles">
					<span className="orange-circle"></span>
					<span className="multi-square two"><b><i></i></b></span>
					<span className="grey-square-rotate red-sq one"></span>
					<span className="grey-square-rotate red-sq two"></span>
				</div>
			</div>
			<div className="top-line ng-scope">
				<img src={topLineImage} alt="" />
			</div>
			<Modal
				isOpen={cancelActivityConfirm}
				style={customModalStyles}
				contentLabel="unlink parent confirm modal"
				ariaHideApp={false}
			>
				<div className="popupAlert warning">
					<br />
					<h2>Confirm</h2>
					<p>Are you sure want to cancel activity</p>
					<div className="row">
						<button className="col btn blue" onClick={() => handleCloseConfirmModal("cancelActivityConfirm")}>Cancel</button>
						<button className="col btn profileBtn yellow" onClick={() => handleContinueCancelActivity()}>Continue</button>
					</div>
				</div>
			</Modal>
		</div>
	)
}

ActivityHistory.contextTypes = {
	t: PropTypes.func
}

export default ActivityHistory
