import React from "react";
import { Link } from "react-router-dom";
import Main from "../../constants/utilities.js";
import PageTitle from "../includes/page_title.js";
import SlideToTop from "../includes/slide_to_top.js";
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import _ from "lodash"
import { LoadingAction, LoginAction } from "./../../actions"
import M from "materialize-css";
import ReactCrop from "react-image-crop";
import topLineImage from "../../assets/images/top-line.png"
import blankImage from "../../assets/images/blank.png"
import activityImage from "../../assets/images/ico_activities.png"
import connectionImage from "../../assets/images/ico_connections.png"
import settingsImage from "../../assets/images/ico_settings.png"
import transcript from "../../assets/images/transcript.png"
import checkList from "../../assets/images/checkList.png"
import reward from "../../assets/images/reward.png"
import { useEffect } from "react";
import { useState } from "react";
import popupAction from "../../actions/popupAction.js";
import { useRef } from "react";

// class Profile extends Main {
//     constructor(props) {
//         super(props);
//         state = {
//             config: config(),
//             profileData: {
//                 picture: "",
//                 name: ""
//             },
//             selectedImage: "",
//             croppedImageUrl: "",
//             submissionStatus: false,
//             submissionStatus_1: false,
//             successMessage: null,
//             file: "",
//             crop: {
//                 unit: "px",
//                 width: 200,
//                 height: 201,
//                 aspect: 2 / 2.1,
//             },
//             profileUpdateMessage: null
//         }
//         props.showLoader()
//     }
//     componentDidMount() {
//         document.title = `${context.t("_studio56")} |`
//         M.AutoInit();
//         getProfileData()
//     }
//     componentDidUpdate() {
//         document.title = `${context.t("_studio56")} | ${context.t("_profile")}`
//     }
//     getProfileData = async () => {
//         // const authResponse = await auth();
//         // if (authResponse) {
//         const { config } = state
//         const url = `${config.api_url}${urlEndPoints.userDetails}`
//         // const headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         try {
//             const data = await networkRequest(url)
//             localStorage.setItem("profileImage", `${config.origin}${data.data.picture}`)
//             props.userLoggedIn(`${config.origin}${data.data.picture}`)
//             props.hideLoader()
//             setState({ profileData: data.data })

//             setTimeout(() => {
//                 document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
//             }, 500);
//         } catch (error) {
//             props.hideLoader()
//             console.warn("error: ", error)
//         }
//         // }
//     }
//     onChangeFile(event) {
//         event.stopPropagation();
//         event.preventDefault();
//         const that = this;
//         var file = event.target.files[0];
//         if (file) {
//             if (file.type.includes("image")) {
//                 const reader = new FileReader();
//                 reader.readAsDataURL(file);
//                 reader.onload = function (e) {
//                     var img = new Image;
//                     img.onload = function () {
//                         // if (img.width < 250) {
//                         //     return that.props.showPopup({
//                         //         type: "error",
//                         //         title: "Invalid",
//                         //         message: "Image size should be greater than 250px!",
//                         //         isOpen: true,
//                         //         buttonText: "Ok"
//                         //     })
//                         // } else if (img.width >= img.height) {
//                         //     return that.props.showPopup({
//                         //         type: "error",
//                         //         title: "Invalid",
//                         //         message: "Image height should be greater than width!",
//                         //         isOpen: true,
//                         //         buttonText: "Ok"
//                         //     })
//                         // } else {
//                         //     that.saveUserImage(file, reader.result)
//                         // }

//                         that.setState({ selectedImage: reader.result, file, submissionStatus: false, submissionStatus_1: false })
//                         let elems = document.querySelector("#photoCrop");
//                         elems.setAttribute("aria-hidden", "false")
//                         elems.setAttribute("tabindex", "-1")
//                         let instance = M.Modal.getInstance(elems);
//                         instance.open();
//                         elems = document.querySelector(".ReactCrop");
//                         elems.setAttribute("aria-label", that.context.t("_link_crop_image"))
//                     };
//                     img.src = reader.result;
//                 };
//                 // reader.onloadend = () => {
//                 //     saveUserImage(file, reader.result)
//                 // };
//             } else {
//                 props.showPopup({
//                     type: "error",
//                     title: context.t("_invalid"),
//                     message: context.t("_invalid_file_type"),
//                     isOpen: true,
//                     buttonText: context.t("_ok")
//                 })
//             }
//         }

//     }

//     handleCloseModal = () => {
//         setState({
//             selectedImage: "",
//             croppedImageUrl: "",
//             file: "",
//         })
//         upload.value = "";
//         let elems = document.querySelector("#photoCrop");
//         elems.removeAttribute("aria-hidden")
//         elems.removeAttribute("tabindex")
//         let instance = M.Modal.getInstance(elems);
//         instance.close();
//         refs.changeProfileImageBtn && refs.changeProfileImageBtn.focus()
//     }

//     onImageLoaded = image => {
//         imageRef = image;
//     };
//     onCropComplete = crop => {
//         makeClientCrop(crop);
//     };

//     onCropChange = (crop, percentCrop) => {
//         // You could also use percentCrop:
//         // setState({ crop: percentCrop });
//         setState({ crop });
//     };

//     async makeClientCrop(crop) {
//         if (imageRef && crop.width && crop.height) {
//             const croppedImageUrl = await getCroppedImg(
//                 imageRef,
//                 crop,
//                 "newFile.jpeg"
//             );
//             setState({ croppedImageUrl });
//         }
//     }
//     getCroppedImg(image, crop, fileName) {
//         const canvas = document.createElement("canvas");
//         const scaleX = image.naturalWidth / image.width;
//         const scaleY = image.naturalHeight / image.height;
//         canvas.width = crop.width;
//         canvas.height = crop.height;
//         const ctx = canvas.getContext("2d");

//         ctx.drawImage(
//             image,
//             crop.x * scaleX,
//             crop.y * scaleY,
//             crop.width * scaleX,
//             crop.height * scaleY,
//             0,
//             0,
//             crop.width,
//             crop.height
//         );

//         return new Promise((resolve, reject) => {
//             resolve(canvas.toDataURL());
//         });
//     }
//     saveUserImage = async () => {
//         // let authResponse = await auth();
//         // if (authResponse) {
//         const { config, croppedImageUrl, file } = state;
//         if (!_.isEmpty(croppedImageUrl)) {
//             handleCloseModal();
//             props.showLoader()
//             let url = `${config.api_url}${urlEndPoints.updateUserPic}`
//             // let headers = {
//             //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//             // }
//             let obj = {
//                 name: file.name,
//                 image: croppedImageUrl
//             }
//             try {
//                 const data = await networkRequest(url, "POST", obj);
//                 if (data.status == 200) {
//                     // setState({ submissionStatus: true, submissionStatus_1: true, successMessage: "Profile image updated successfully" })
//                     getProfileData()
//                     setState({ profileUpdateMessage: context.t("_profile_image_updated_successfully") })
//                     refs.profileUpdateMessageRef && refs.profileUpdateMessageRef.focus()
//                     setTimeout(() => {
//                         setState({ profileUpdateMessage: null })
//                     }, 2000);
//                 }
//             } catch (error) {
//                 props.hideLoader()
//                 console.warn("error: ", error)
//             }
//         }
//         // }
//     }
//     removeProfilePic = async () => {
//         // let authResponse = await auth();
//         // if (authResponse) {
//         props.showLoader()
//         const { config } = state
//         let url = `${config.api_url}${urlEndPoints.removeUserPic}`
//         // let headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         let obj = {}
//         try {
//             const data = await networkRequest(url, "POST", obj);
//             if (data.status === 200) {
//                 // setState({ submissionStatus: true, submissionStatus_1: true, successMessage: "Profile image removed successfully" })
//                 getProfileData()
//                 setState({ profileUpdateMessage: context.t("_profile_image_removed_successfully") })
//                 refs.profileUpdateMessageRef && refs.profileUpdateMessageRef.focus()
//                 setTimeout(() => {
//                     setState({ profileUpdateMessage: null })
//                 }, 2000);
//             }
//         } catch (error) {
//             props.hideLoader()
//             console.warn("error: ", error)
//         }
//         // }
//     }
//     getConnectionLabel = () => {
//         const role = localStorage.getItem("role");
//         switch (role) {
//             case "parent":
//                 return context.t("_participants");
//             case "school":
//                 return context.t("_students");

//             default:
//                 return context.t("_manage_connections");
//         }
//     }
//     render() {
//         const { profileData, config, selectedImage, crop, profileUpdateMessage } = state;
//         return (
//             <>
//                 <div id="main-container">
//                     <SlideToTop />
//                     <PageTitle title={context.t("_profile")} />
//                     <div>
//                         <div id="skipContent" className="container profile-container">
//                             {
//                                 /**
//                                  * 
//                                  * STUDENT PROFILE
//                                  * 
//                                  */
//                                 localStorage.getItem("role") == "student" ?
//                                     <div className="row">
//                                         <div className="col s12 text-center">
//                                             <figure className="profilePic">
//                                                 <span>
//                                                     <img src={!_.isEmpty(profileData.picture) ? `${config.origin}${profileData.picture}` : "../../assets/images/profile_pic.jpg"} alt={`${profileData.name}`} />
//                                                 </span>
//                                             </figure>
//                                             <figure className="profilePic profileNew">
//                                                 {
//                                                     _.isEmpty(profileData.picture) ?
//                                                         <img src={blankImage} alt="" />
//                                                         :
//                                                         <>
//                                                             <img src={blankImage} alt="" />
//                                                             <span>
//                                                                 <img src={`${config.origin}${profileData.picture}`} alt="" />
//                                                             </span>
//                                                         </>
//                                                 }
//                                                 <button aria-label={context.t("_change_profile_image")} type="button" ref="changeProfileImageBtn" onClick={() => { upload.click() }} className="btn changePic">
//                                                     <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
//                                                 </button>
//                                                 <input id="myInput"
//                                                     type="file"
//                                                     ref={(ref) => upload = ref}
//                                                     style={{ display: "none" }}
//                                                     onChange={onChangeFile.bind(this)}
//                                                 />
//                                                 <label style={{ display: "none" }} htmlFor="myInput" aria-label="select profile image">select profile image</label>
//                                                 <button aria-label={context.t("_remove_profile_image")} onClick={() => removeProfilePic()} type="button" className="btn removePic">
//                                                     <i className="fa fa-trash" aria-hidden="true"></i>
//                                                 </button>

//                                             </figure>
//                                             <h4 className="profileheading profileNew">{profileData.name}</h4>
//                                             {
//                                                 !_.isEmpty(profileUpdateMessage) && <p tabIndex="0" ref="profileUpdateMessageRef" style={{ textAlign: "center" }}>{profileUpdateMessage}</p>
//                                             }
//                                             {/* <div className="starHolder">
//                                             <span className="fa fa-star checked"></span>
//                                             <span className="fa fa-star checked"></span>
//                                             <span className="fa fa-star checked"></span>
//                                             <span className="fa fa-star"></span>
//                                             <span className="fa fa-star"></span>
//                                         </div> */}
//                                         </div>
//                                         <div className="col s12">
//                                             <div className="profileStatus profileNew">
//                                                 <ul>
//                                                     <li tabIndex="0" aria-label={`${context.t("_challenge_count")} ${_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}`}>
//                                                         <strong>
//                                                             {_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}
//                                                         </strong>
//                                                         <span>{context.t("_challenges")}</span></li>
//                                                     <li tabIndex="0" aria-label={`${context.t("_competition_count")} ${_.has(profileData, "enrollment_count.competition.count") ? profileData.enrollment_count.competition.count : 0}`}>
//                                                         <strong>
//                                                             {_.has(profileData, "enrollment_count.competition.count") ? profileData.enrollment_count.competition.count : 0}
//                                                         </strong>
//                                                         <span>{context.t("_competitions")}</span></li>
//                                                     <li tabIndex="0" aria-label={`${context.t("_session_count")} ${_.has(profileData, "enrollment_count.session.count") ? profileData.enrollment_count.session.count : 0}`}>
//                                                         <strong>
//                                                             {_.has(profileData, "enrollment_count.session.count") ? profileData.enrollment_count.session.count : 0}
//                                                         </strong>
//                                                         <span>{context.t("_sessions")}</span></li>
//                                                     <li tabIndex="0" aria-label={`${context.t("_event_count")} ${_.has(profileData, "enrollment_count.event.count") ? profileData.enrollment_count.event.count : 0}`}>
//                                                         <strong>
//                                                             {_.has(profileData, "enrollment_count.event.count") ? profileData.enrollment_count.event.count : 0}
//                                                         </strong>
//                                                         <span>{context.t("_events")}</span></li>
//                                                     <li tabIndex="0" aria-label={`${context.t("_project_count")} ${_.has(profileData, "enrollment_count.project.count") ? profileData.enrollment_count.project.count : 0}`}>
//                                                         <strong>
//                                                             {_.has(profileData, "enrollment_count.project.count") ? profileData.enrollment_count.project.count : 0}
//                                                         </strong>
//                                                         <span>{context.t("_projects")}</span></li>
//                                                 </ul>
//                                             </div>

//                                             <div className="profileTabHolder profileNew">
//                                                 <div className="cell">
//                                                     <Link to="/current-activities">
//                                                         <div className="profileTab item4">
//                                                             <img src={activityImage} alt="" />
//                                                             <p>{context.t("_current_activities")}</p>
//                                                         </div>
//                                                     </Link>
//                                                 </div><div className="cell">
//                                                     <Link to={`/${localStorage.getItem("role")}-connections`}>
//                                                         <div className="profileTab item4">
//                                                             <img src={connectionImage} alt="" />
//                                                             <p>{context.t("_manage_connections")}</p>
//                                                         </div>
//                                                     </Link>
//                                                 </div>

//                                                 <div className="cell">
//                                                     <Link to={`/${localStorage.getItem("role")}-settings`}>
//                                                         <div className="profileTab item4">
//                                                             <img src={settingsImage} alt="" />
//                                                             <p>{context.t("_profile_settings")}</p>
//                                                         </div>
//                                                     </Link>
//                                                 </div>
//                                                 <div className="cell">
//                                                     <Link to={`/${localStorage.getItem("role")}-attendance`}>
//                                                         <div className="profileTab item4">
//                                                             <img src={checkList} alt="" />
//                                                             <p>{context.t("_attendance")}</p>
//                                                         </div>
//                                                     </Link>
//                                                 </div>
//                                                 <div className="cell">
//                                                     <Link to={`/student-transcript/${profileData.user_id}`}>
//                                                         <div className="profileTab item4">
//                                                             <img src={transcript} alt="" />
//                                                             <p>{context.t("_transcript")}</p>
//                                                         </div>
//                                                     </Link>
//                                                 </div>
//                                                 <div className="cell">
//                                                     <Link to={`/reward/${profileData.user_id}`}>
//                                                         <div className="profileTab item4">
//                                                             <img src={reward} alt="" />
//                                                             <p>{context.t("_reward_system")}</p>
//                                                         </div>
//                                                     </Link>
//                                                 </div>
//                                             </div>
//                                         </div>

//                                     </div>
//                                     :
//                                     /**
//                                      * 
//                                      * 
//                                      * PROFILE OTHER THAN STUDENT
//                                      * 
//                                      */
//                                     <div className="row">
//                                         <div className="col s3 text-center">
//                                             {/* <figure className="profilePic">
//                                     <span>
//                                         <img src={!_.isEmpty(profileData.picture) ? `${config.origin}${profileData.picture}` : "../../assets/images/profile_pic.jpg"} alt={`${profileData.name}`} />
//                                     </span>
//                                 </figure> */}
//                                             <figure className="profilePic">
//                                                 {
//                                                     _.isEmpty(profileData.picture) ?
//                                                         <img src={blankImage} alt="" />
//                                                         :
//                                                         <>
//                                                             <img src={blankImage} alt="" />
//                                                             <span>
//                                                                 <img src={`${config.origin}${profileData.picture}`} alt="" />
//                                                             </span>
//                                                         </>
//                                                 }
//                                                 <button aria-label={context.t("_change_profile_image")}
//                                                     type="button" ref="changeProfileImageBtn" onClick={() => { upload.click() }} className="btn changePic">
//                                                     <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
//                                                 </button>
//                                                 <input id="myInput"
//                                                     type="file"
//                                                     ref={(ref) => upload = ref}
//                                                     style={{ display: "none" }}
//                                                     onChange={onChangeFile.bind(this)}
//                                                 />
//                                                 <label style={{ display: "none" }} htmlFor="myInput" aria-label="select profile image">select profile image</label>
//                                                 <button aria-label={context.t("_remove_profile_image")} onClick={() => removeProfilePic()} type="button" className="btn removePic">
//                                                     <i className="fa fa-trash" aria-hidden="true"></i>
//                                                 </button>

//                                             </figure>
//                                             <h4 className="profileheading">{`${profileData.name}`}</h4>
//                                             {
//                                                 !_.isEmpty(profileUpdateMessage) && <p tabIndex="0" ref="profileUpdateMessageRef" style={{ textAlign: "center" }}>{profileUpdateMessage}</p>
//                                             }
//                                             {/* <div className="starHolder">
//                                     <span className="fa fa-star checked"></span>
//                                     <span className="fa fa-star checked"></span>
//                                     <span className="fa fa-star checked"></span>
//                                     <span className="fa fa-star"></span>
//                                     <span className="fa fa-star"></span>
//                                 </div> */}
//                                         </div>
//                                         <div className="col s9">
//                                             {
//                                                 (localStorage.getItem("role") != "volenteer" && localStorage.getItem("role") != "parent") && localStorage.getItem("role") != "school" && <div className="profileStatus">
//                                                     <ul>
//                                                         <li tabIndex="0" aria-label={`${context.t("_challenge_count")} ${_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}`}>
//                                                             <strong>
//                                                                 {_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}
//                                                             </strong>
//                                                             <span>{context.t("_challenges")}</span></li>
//                                                         <li tabIndex="0" aria-label={`${context.t("_competition_count")} ${_.has(profileData, "enrollment_count.competition.count") ? profileData.enrollment_count.competition.count : 0}`}>
//                                                             <strong>
//                                                                 {_.has(profileData, "enrollment_count.competition.count") ? profileData.enrollment_count.competition.count : 0}
//                                                             </strong>
//                                                             <span>{context.t("_competitions")}</span></li>
//                                                         <li tabIndex="0" aria-label={`${context.t("_session_count")} ${_.has(profileData, "enrollment_count.session.count") ? profileData.enrollment_count.session.count : 0}`}>
//                                                             <strong>
//                                                                 {_.has(profileData, "enrollment_count.session.count") ? profileData.enrollment_count.session.count : 0}
//                                                             </strong>
//                                                             <span>{context.t("_sessions")}</span></li>
//                                                         <li tabIndex="0" aria-label={`${context.t("_event_count")} ${_.has(profileData, "enrollment_count.event.count") ? profileData.enrollment_count.event.count : 0}`}>
//                                                             <strong>
//                                                                 {_.has(profileData, "enrollment_count.event.count") ? profileData.enrollment_count.event.count : 0}
//                                                             </strong>
//                                                             <span>{context.t("_events")}</span></li>
//                                                         <li tabIndex="0" aria-label={`${context.t("_project_count")} ${_.has(profileData, "enrollment_count.project.count") ? profileData.enrollment_count.project.count : 0}`}>
//                                                             <strong>
//                                                                 {_.has(profileData, "enrollment_count.project.count") ? profileData.enrollment_count.project.count : 0}
//                                                             </strong>
//                                                             <span>{context.t("_projects")}</span></li>
//                                                     </ul>
//                                                 </div>
//                                             }

//                                             <div className="profileTabHolder">
//                                                 <div className="cell">
//                                                     <Link to="/current-activities">
//                                                         <div className="profileTab">
//                                                             <img src={activityImage} alt="" />
//                                                             <p>{context.t("_current_activities")}</p>
//                                                         </div>
//                                                     </Link>
//                                                 </div>
//                                                 {
//                                                     localStorage.getItem("role") == "volenteer" && <div className="cell">
//                                                         <Link to="volenteer-history">
//                                                             <div className="profileTab">
//                                                                 <img src={connectionImage} alt="" />
//                                                                 <p>{context.t("_activity_history")}</p>
//                                                             </div>
//                                                         </Link>
//                                                     </div>
//                                                 }
//                                                 {
//                                                     localStorage.getItem("role") != "volenteer" && <div className="cell">
//                                                         <Link to={`/${localStorage.getItem("role")}-connections`}>
//                                                             <div className="profileTab">
//                                                                 <img src={connectionImage} alt="" />
//                                                                 <p>{getConnectionLabel()}</p>
//                                                             </div>
//                                                         </Link>
//                                                     </div>
//                                                 }
//                                                 <div className="cell">
//                                                     <Link to={`/${localStorage.getItem("role")}-settings`}>
//                                                         <div className="profileTab">
//                                                             <img src={settingsImage} alt="" />
//                                                             <p>{context.t("_profile_settings")}</p>
//                                                         </div>
//                                                     </Link>
//                                                 </div>
//                                                 {/* {
//                                         localStorage.getItem("role") == "school" && <Link to="waiting-list">
//                                             <div className="profileTab">
//                                                 <img src={waitingImage} />
//                                                 <p>Waiting List</p>
//                                             </div>
//                                         </Link>
//                                     } */}
//                                             </div>
//                                         </div>
//                                     </div>
//                             }
//                             <div id="photoCrop" className="modal sm" role="dialog">
//                                 <div className="modal-content scroll-x-hidden">
//                                     <span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => handleCloseModal()}><i className="material-icons">close</i></button></span>
//                                     <p>{context.t("_crop_your_image")}</p>
//                                     <div className="image-crop-container">
//                                         <ReactCrop
//                                             // crossorigin="anonymous"
//                                             src={selectedImage}
//                                             crop={crop}
//                                             ruleOfThirds
//                                             minWidth={200}
//                                             minHeight={200}
//                                             keepSelection={true}
//                                             imageStyle={{ maxHeight: 250 }}
//                                             onImageLoaded={onImageLoaded}
//                                             onComplete={onCropComplete}
//                                             onChange={onCropChange}
//                                         />
//                                     </div>

//                                     <div className="clearfix" />
//                                     <button onClick={() => saveUserImage()} className="btn profileBtn yellow">{context.t("_upload")}</button>
//                                     <button onClick={() => handleCloseModal()} className="btn profileBtn blue-border">{context.t("_cancel")}</button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="top-line ng-scope">
//                     <img src={topLineImage} alt="" />
//                 </div>
//             </>
//         )
//     }

// }

// const mapStateToProps = ({ i18nState }) => {
//     return {
//         lang: i18nState.lang
//     }
// }
// const mapDispatchToProps = {
//     showLoader: LoadingAction.showLoader,
//     hideLoader: LoadingAction.hideLoader,
//     userLoggedIn: LoginAction.userLoggedIn,
// }
// Profile.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(Profile);


const Profile = (props, context) => {
    const [profileData, setProfileData] = useState({
        picture: "",
        name: ""
    })
    const [selectedImage, setSelectedImage] = useState("")
    const [croppedImageUrl, setCroppedImageUrl] = useState("")
    const [submissionStatus, setSubmissionStatus] = useState(false)
    const [submissionStatus_1, setsubmissionStatus_1] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [file, setFile] = useState("")
    const [crop, setCrop] = useState({
        unit: "px",
        width: 200,
        height: 201,
        aspect: 2 / 2.1,
    })
    const [profileUpdateMessage, setProfileUpdateMessage] = useState(null)
    const [imageRef, setImageRef] = useState(null)
    const changeProfileImageBtnRef = useRef(null)
    const profileUpdateMessageRef = useRef(null)
    const uploadRef = useRef(null)
    const { config, networkRequest } = Main()
    const configure = config()
    const dispatch = useDispatch()
    const { lang } = useSelector(state => state.i18nState)

    const getProfileData = async () => {
        // const authResponse = await auth();
        // if (authResponse) {
        const url = `${configure.api_url}${urlEndPoints.userDetails}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await networkRequest(url)
            localStorage.setItem("profileImage", `${configure.origin}${data.data.picture}`)
            dispatch(LoginAction.userLoggedIn(`${configure.origin}${data.data.picture}`))
            dispatch(LoadingAction.hideLoader())
            setProfileData(data?.data)
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // }
    }
    function onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        var uploadedFile = event.target.files[0];
        if (uploadedFile) {
            if (uploadedFile.type.includes("image")) {
                const reader = new FileReader();
                reader.readAsDataURL(uploadedFile);
                reader.onload = function (e) {
                    var img = new Image;
                    img.onload = function () {
                        // if (img.width < 250) {
                        //     return that.props.showPopup({
                        //         type: "error",
                        //         title: "Invalid",
                        //         message: "Image size should be greater than 250px!",
                        //         isOpen: true,
                        //         buttonText: "Ok"
                        //     })
                        // } else if (img.width >= img.height) {
                        //     return that.props.showPopup({
                        //         type: "error",
                        //         title: "Invalid",
                        //         message: "Image height should be greater than width!",
                        //         isOpen: true,
                        //         buttonText: "Ok"
                        //     })
                        // } else {
                        //     that.saveUserImage(file, reader.result)
                        // }
                        setSelectedImage(reader.result)
                        setFile(uploadedFile)
                        setSubmissionStatus(false)
                        setsubmissionStatus_1(false)
                        let elems = document.querySelector("#photoCrop");
                        elems.setAttribute("aria-hidden", "false")
                        elems.setAttribute("tabindex", "-1")
                        let instance = M.Modal.getInstance(elems);
                        instance.open();
                        elems = document.querySelector(".ReactCrop");
                        elems.setAttribute("aria-label", context.t("_link_crop_image"))
                    };
                    img.src = reader.result;
                };
                // reader.onloadend = () => {
                //     saveUserImage(file, reader.result)
                // };
            } else {
                dispatch(popupAction.showPopup({
                    type: "error",
                    title: context.t("_invalid"),
                    message: context.t("_invalid_file_type"),
                    isOpen: true,
                    buttonText: context.t("_ok")
                }))
            }
        }

    }
    const handleCloseModal = () => {
        setSelectedImage("")
        setCroppedImageUrl("")
        setFile("")
        uploadRef.current.value = "";
        let elems = document.querySelector("#photoCrop");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        changeProfileImageBtnRef && changeProfileImageBtnRef.current.focus()
    }

    const onImageLoaded = image => {
        setImageRef(image)
    };
    const onCropComplete = crop => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // setState({ crop: percentCrop });
        setCrop(crop)
    };

    async function makeClientCrop(crop) {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                imageRef,
                crop,
                "newFile.jpeg"
            );
            setCroppedImageUrl(croppedImageUrl)
        }
    }
    function getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            resolve(canvas.toDataURL());
        });
    }
    const saveUserImage = async () => {
        // let authResponse = await auth();
        // if (authResponse) {
        if (!_.isEmpty(croppedImageUrl)) {
            handleCloseModal();
            dispatch(LoadingAction.showLoader())
            let url = `${configure.api_url}${urlEndPoints.updateUserPic}`
            // let headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            let obj = {
                name: file.name,
                image: croppedImageUrl
            }
            try {
                const data = await networkRequest(url, "POST", obj);
                if (data.status == 200) {
                    // setState({ submissionStatus: true, submissionStatus_1: true, successMessage: "Profile image updated successfully" })
                    getProfileData()
                    setProfileUpdateMessage(context.t("_profile_image_updated_successfully"))
                    profileUpdateMessageRef && profileUpdateMessageRef.current.focus()
                    setTimeout(() => {
                        setProfileUpdateMessage(null)
                    }, 2000);
                }
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
        }
        // }
    }
    const removeProfilePic = async () => {
        // let authResponse = await auth();
        // if (authResponse) {
        dispatch(LoadingAction.showLoader())
        let url = `${configure.api_url}${urlEndPoints.removeUserPic}`
        // let headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        let obj = {}
        try {
            const data = await networkRequest(url, "POST", obj);
            if (data.status === 200) {
                // setState({ submissionStatus: true, submissionStatus_1: true, successMessage: "Profile image removed successfully" })
                getProfileData()
                setProfileUpdateMessage(context.t("_profile_image_removed_successfully"))
                profileUpdateMessageRef && profileUpdateMessageRef.current.focus()
                setTimeout(() => {
                    setProfileUpdateMessage(null)
                }, 2000);
            }
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // }
    }
    const getConnectionLabel = () => {
        const role = localStorage.getItem("role");
        switch (role) {
            case "parent":
                return context.t("_participants");
            case "school":
                return context.t("_students");

            default:
                return context.t("_manage_connections");
        }
    }

    useEffect(() => {
        dispatch(LoadingAction.showLoader())
        M.AutoInit();
        getProfileData()
    }, [])

    let userRole = (localStorage.getItem("role") === "student" || localStorage.getItem("role") === "university_student") ? "student" : localStorage.getItem("role")
    let exactRole = localStorage.getItem("role")

    return (
        <>
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={context.t("_profile")} />
                <div>
                    <div id="skipContent" className="container profile-container">
                        {
                            /**
                             * 
                             * STUDENT PROFILE
                             * 
                             */
                            userRole == "student" ?
                                <div className="row">
                                    <div className="col s12 text-center">
                                        <figure className="profilePic">
                                            <span>
                                                <img src={!_.isEmpty(profileData.picture) ? `${configure.origin}${profileData.picture}` : "../../assets/images/profile_pic.jpg"} alt={`${profileData.name}`} />
                                            </span>
                                        </figure>
                                        <figure className="profilePic profileNew">
                                            {
                                                _.isEmpty(profileData.picture) ?
                                                    <img src={blankImage} alt="" />
                                                    :
                                                    <>
                                                        <img src={blankImage} alt="" />
                                                        <span>
                                                            <img src={`${configure.origin}${profileData.picture}`} alt="" />
                                                        </span>
                                                    </>
                                            }
                                            <button aria-label={context.t("_change_profile_image")} type="button" ref={changeProfileImageBtnRef} onClick={() => { uploadRef.current.click() }} className="btn changePic">
                                                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                            </button>
                                            <input id="myInput"
                                                type="file"
                                                ref={uploadRef}
                                                style={{ display: "none" }}
                                                onChange={(e) => onChangeFile(e)}
                                            />
                                            <label style={{ display: "none" }} htmlFor="myInput" aria-label="select profile image">select profile image</label>
                                            <button aria-label={context.t("_remove_profile_image")} onClick={() => removeProfilePic()} type="button" className="btn removePic">
                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                            </button>

                                        </figure>
                                        <h4 className="profileheading profileNew">{profileData.name}</h4>
                                        {
                                            !_.isEmpty(profileUpdateMessage) && <p tabIndex="0" ref={profileUpdateMessageRef} style={{ textAlign: "center" }}>{profileUpdateMessage}</p>
                                        }
                                        {/* <div className="starHolder">
                                            <span className="fa fa-star checked"></span>
                                            <span className="fa fa-star checked"></span>
                                            <span className="fa fa-star checked"></span>
                                            <span className="fa fa-star"></span>
                                            <span className="fa fa-star"></span>
                                        </div> */}
                                    </div>
                                    <div className="col s12">
                                        <div className="profileStatus profileNew">
                                            <ul>
                                                <li tabIndex="0" aria-label={`${context.t("_challenge_count")} ${_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}`}>
                                                    <strong>
                                                        {_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}
                                                    </strong>
                                                    <span>{context.t("_challenges")}</span></li>
                                                <li tabIndex="0" aria-label={`${context.t("_competition_count")} ${_.has(profileData, "enrollment_count.competition.count") ? profileData.enrollment_count.competition.count : 0}`}>
                                                    <strong>
                                                        {_.has(profileData, "enrollment_count.competition.count") ? profileData.enrollment_count.competition.count : 0}
                                                    </strong>
                                                    <span>{context.t("_competitions")}</span></li>
                                                <li tabIndex="0" aria-label={`${context.t("_session_count")} ${_.has(profileData, "enrollment_count.session.count") ? profileData.enrollment_count.session.count : 0}`}>
                                                    <strong>
                                                        {_.has(profileData, "enrollment_count.session.count") ? profileData.enrollment_count.session.count : 0}
                                                    </strong>
                                                    <span>{context.t("_sessions")}</span></li>
                                                <li tabIndex="0" aria-label={`${context.t("_event_count")} ${_.has(profileData, "enrollment_count.event.count") ? profileData.enrollment_count.event.count : 0}`}>
                                                    <strong>
                                                        {_.has(profileData, "enrollment_count.event.count") ? profileData.enrollment_count.event.count : 0}
                                                    </strong>
                                                    <span>{context.t("_events")}</span></li>
                                                <li tabIndex="0" aria-label={`${context.t("_project_count")} ${_.has(profileData, "enrollment_count.project.count") ? profileData.enrollment_count.project.count : 0}`}>
                                                    <strong>
                                                        {_.has(profileData, "enrollment_count.project.count") ? profileData.enrollment_count.project.count : 0}
                                                    </strong>
                                                    <span>{context.t("_projects")}</span></li>
                                            </ul>
                                        </div>

                                        <div className="profileTabHolder profileNew">
                                            <div className="cell">
                                                <Link to="/current-activities">
                                                    <div className="profileTab item4">
                                                        <img src={activityImage} alt="" />
                                                        <p>{context.t("_current_activities")}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                            {localStorage.getItem("role") == "student" && <div className="cell">
                                                <Link to={`/${exactRole}-connections`}>
                                                    <div className="profileTab item4">
                                                        <img src={connectionImage} alt="" />
                                                        <p>{context.t("_manage_connections")}</p>
                                                    </div>
                                                </Link>
                                            </div>}

                                            <div className="cell">
                                                
                                                <Link to={ exactRole=='university_student' ?  '/university-student-settings' : `/${userRole}-settings`}>
                                                    <div className="profileTab item4">
                                                        <img src={settingsImage} alt="" />
                                                        <p>{context.t("_profile_settings")}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="cell">
                                                <Link to={`/${userRole}-attendance`}>
                                                    <div className="profileTab item4">
                                                        <img src={checkList} alt="" />
                                                        <p>{context.t("_attendance")}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="cell">
                                                <Link to={`/student-transcript/${profileData.user_id}`}>
                                                    <div className="profileTab item4">
                                                        <img src={transcript} alt="" />
                                                        <p>{context.t("_transcript")}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="cell">
                                                <Link to={`/reward/${profileData.user_id}`}>
                                                    <div className="profileTab item4">
                                                        <img src={reward} alt="" />
                                                        <p>{context.t("_reward_system")}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                :
                                /**
                                 * 
                                 * 
                                 * PROFILE OTHER THAN STUDENT
                                 * 
                                 */
                                <div className="row">
                                    <div className="col s3 text-center">
                                        {/* <figure className="profilePic">
                                    <span>
                                        <img src={!_.isEmpty(profileData.picture) ? `${configure.origin}${profileData.picture}` : "../../assets/images/profile_pic.jpg"} alt={`${profileData.name}`} />
                                    </span>
                                </figure> */}
                                        <figure className="profilePic">
                                            {
                                                _.isEmpty(profileData.picture) ?
                                                    <img src={blankImage} alt="" />
                                                    :
                                                    <>
                                                        <img src={blankImage} alt="" />
                                                        <span>
                                                            <img src={`${configure.origin}${profileData.picture}`} alt="" />
                                                        </span>
                                                    </>
                                            }
                                            <button aria-label={context.t("_change_profile_image")}
                                                type="button" ref={changeProfileImageBtnRef} onClick={() => { uploadRef.current.click() }} className="btn changePic">
                                                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                            </button>
                                            <input id="myInput"
                                                type="file"
                                                ref={uploadRef}
                                                style={{ display: "none" }}
                                                onChange={(e) => onChangeFile(e)}
                                            />
                                            <label style={{ display: "none" }} htmlFor="myInput" aria-label="select profile image">select profile image</label>
                                            <button aria-label={context.t("_remove_profile_image")} onClick={() => removeProfilePic()} type="button" className="btn removePic">
                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                            </button>

                                        </figure>
                                        <h4 className="profileheading">{`${profileData.name}`}</h4>
                                        {
                                            !_.isEmpty(profileUpdateMessage) && <p tabIndex="0" ref={profileUpdateMessageRef} style={{ textAlign: "center" }}>{profileUpdateMessage}</p>
                                        }
                                        {/* <div className="starHolder">
                                    <span className="fa fa-star checked"></span>
                                    <span className="fa fa-star checked"></span>
                                    <span className="fa fa-star checked"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                </div> */}
                                    </div>
                                    <div className="col s9">
                                        {
                                            (localStorage.getItem("role") != "volenteer" && localStorage.getItem("role") != "parent") && localStorage.getItem("role") != "school" && <div className="profileStatus">
                                                <ul>
                                                    <li tabIndex="0" aria-label={`${context.t("_challenge_count")} ${_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}`}>
                                                        <strong>
                                                            {_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}
                                                        </strong>
                                                        <span>{context.t("_challenges")}</span></li>
                                                    <li tabIndex="0" aria-label={`${context.t("_competition_count")} ${_.has(profileData, "enrollment_count.competition.count") ? profileData.enrollment_count.competition.count : 0}`}>
                                                        <strong>
                                                            {_.has(profileData, "enrollment_count.competition.count") ? profileData.enrollment_count.competition.count : 0}
                                                        </strong>
                                                        <span>{context.t("_competitions")}</span></li>
                                                    <li tabIndex="0" aria-label={`${context.t("_session_count")} ${_.has(profileData, "enrollment_count.session.count") ? profileData.enrollment_count.session.count : 0}`}>
                                                        <strong>
                                                            {_.has(profileData, "enrollment_count.session.count") ? profileData.enrollment_count.session.count : 0}
                                                        </strong>
                                                        <span>{context.t("_sessions")}</span></li>
                                                    <li tabIndex="0" aria-label={`${context.t("_event_count")} ${_.has(profileData, "enrollment_count.event.count") ? profileData.enrollment_count.event.count : 0}`}>
                                                        <strong>
                                                            {_.has(profileData, "enrollment_count.event.count") ? profileData.enrollment_count.event.count : 0}
                                                        </strong>
                                                        <span>{context.t("_events")}</span></li>
                                                    <li tabIndex="0" aria-label={`${context.t("_project_count")} ${_.has(profileData, "enrollment_count.project.count") ? profileData.enrollment_count.project.count : 0}`}>
                                                        <strong>
                                                            {_.has(profileData, "enrollment_count.project.count") ? profileData.enrollment_count.project.count : 0}
                                                        </strong>
                                                        <span>{context.t("_projects")}</span></li>
                                                </ul>
                                            </div>
                                        }

                                        <div className="profileTabHolder">
                                            <div className="cell">
                                                <Link to="/current-activities">
                                                    <div className="profileTab">
                                                        <img src={activityImage} alt="" />
                                                        <p>{context.t("_current_activities")}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                            {
                                                localStorage.getItem("role") == "volenteer" && <div className="cell">
                                                    <Link to="/volenteer-history">
                                                        <div className="profileTab">
                                                            <img src={connectionImage} alt="" />
                                                            <p>{context.t("_activity_history")}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            }
                                            {
                                                localStorage.getItem("role") != "volenteer" && <div className="cell">
                                                    <Link to={`/${localStorage.getItem("role")}-connections`}>
                                                        <div className="profileTab">
                                                            <img src={connectionImage} alt="" />
                                                            <p>{getConnectionLabel()}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            }
                                            <div className="cell">
                                                <Link to={`/${localStorage.getItem("role")}-settings`}>
                                                    <div className="profileTab">
                                                        <img src={settingsImage} alt="" />
                                                        <p>{context.t("_profile_settings")}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                            {/* {
                                        localStorage.getItem("role") == "school" && <Link to="waiting-list">
                                            <div className="profileTab">
                                                <img src={waitingImage} />
                                                <p>Waiting List</p>
                                            </div>
                                        </Link>
                                    } */}
                                        </div>
                                    </div>
                                </div>
                        }
                        <div id="photoCrop" className="modal sm" role="dialog">
                            <div className="modal-content scroll-x-hidden">
                                <span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => handleCloseModal()}><i className="material-icons">close</i></button></span>
                                <p>{context.t("_crop_your_image")}</p>
                                <div className="image-crop-container">
                                    <ReactCrop
                                        // crossorigin="anonymous"
                                        src={selectedImage}
                                        crop={crop}
                                        ruleOfThirds
                                        minWidth={200}
                                        minHeight={200}
                                        keepSelection={true}
                                        imageStyle={{ maxHeight: 250 }}
                                        onImageLoaded={onImageLoaded}
                                        onComplete={onCropComplete}
                                        onChange={onCropChange}
                                    />
                                </div>

                                <div className="clearfix" />
                                <button onClick={() => saveUserImage()} className="btn profileBtn yellow">{context.t("_upload")}</button>
                                <button onClick={() => handleCloseModal()} className="btn profileBtn blue-border">{context.t("_cancel")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="top-line ng-scope">
                <img src={topLineImage} alt="" />
            </div>
        </>
    )
}

Profile.contextTypes = {
    t: PropTypes.func
}

export default Profile
