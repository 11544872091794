// import React, { Component } from 'react'
// import { Link } from "react-router-dom";
// import _ from "lodash"
// import { connect } from "react-redux"
// import PropTypes from "prop-types"
// import M from "materialize-css";
// import ReactCrop from "react-image-crop";

// import { LoadingAction, LoginAction } from "./../../../actions"
// import Main from "./../../main"
// import PageTitle from "../../includes/page_title";
// import SlideToTop from "../../includes/slide_to_top";
// import { urlEndPoints } from "./../../../httpAPI/apiConfig"

// import blankImage from "../../../assets/images/blank.png"
// import activityImage from "../../../assets/images/ico_activities.png"
// import connectionImage from "../../../assets/images/ico_connections.png"
// import settingsImage from "../../../assets/images/ico_settings.png"

// import ParentCurrentActivity from "./pages/activities"
// import ParentParticipants from "./pages/participants"
// import ParentSettings from "./pages/settings"

// class CurrentNewActivity extends Main {
//     constructor(props) {
//         super(props);
//         const { match: { params: { menuType } } } = props;
//         this.state = {
//             config: this.config(),
//             profileData: {
//                 picture: "",
//                 name: ""
//             },
//             selectedImage: "",
//             croppedImageUrl: "",
//             submissionStatus: false,
//             submissionStatus_1: false,
//             successMessage: null,
//             file: "",
//             crop: {
//                 unit: "px",
//                 width: 200,
//                 height: 201,
//                 aspect: 2 / 2.1,
//             },
//             activeTab: menuType || "current-activity",
//             profileUpdateMessage: null
//         }
//     }
//     async componentDidMount() {
//         document.title = `${this.context.t("_studio56")} |`
//         M.AutoInit();
//         await this.getProfileData()

//         const { match: { params: { menuType } } } = this.props;
//         if (!menuType)
//             this.refs.parentBreadcrumb.focus()
//         // setTimeout(() => {
//         //     if (_.isEmpty(menuType))
//         //         // this.refs.parentBreadcrumb.focus()
//         // }, 500);
//     }
//     componentDidUpdate() {
//         const { match: { params: { menuType } } } = this.props;
//         if (menuType && menuType != this.state.activeTab) {
//             this.setState({ activeTab: menuType })
//         }
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_profile")}`
//     }
//     getProfileData = async () => {
//         // const authResponse = await this.auth();
//         // if (authResponse) {
//         const { config } = this.state
//         const url = `${config.api_url}${urlEndPoints.userDetails}`
//         // const headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         try {
//             const data = await this.networkRequest(url)
//             localStorage.setItem("profileImage", `${config.origin}${data.data.picture}`)
//             this.props.userLoggedIn(`${config.origin}${data.data.picture}`)
//             this.props.hideLoader()
//             this.setState({ profileData: data.data })
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//         // }
//     }
//     onChangeFile(event) {
//         event.stopPropagation();
//         event.preventDefault();
//         const that = this;
//         var file = event.target.files[0];
//         if (file) {
//             if (file.type.includes("image")) {
//                 const reader = new FileReader();
//                 reader.readAsDataURL(file);
//                 reader.onload = function (e) {
//                     var img = new Image;
//                     img.onload = function () {
//                         // if (img.width < 250) {
//                         //     return that.props.showPopup({
//                         //         type: "error",
//                         //         title: "Invalid",
//                         //         message: "Image size should be greater than 250px!",
//                         //         isOpen: true,
//                         //         buttonText: "Ok"
//                         //     })
//                         // } else if (img.width >= img.height) {
//                         //     return that.props.showPopup({
//                         //         type: "error",
//                         //         title: "Invalid",
//                         //         message: "Image height should be greater than width!",
//                         //         isOpen: true,
//                         //         buttonText: "Ok"
//                         //     })
//                         // } else {
//                         //     that.saveUserImage(file, reader.result)
//                         // }

//                         that.setState({ selectedImage: reader.result, file, submissionStatus: false, submissionStatus_1: false })
//                         let elems = document.querySelector("#photoCrop");
//                         elems.setAttribute("aria-hidden", "false")
//                         elems.setAttribute("tabindex", "-1")
//                         let instance = M.Modal.getInstance(elems);
//                         instance.open();
//                         elems = document.querySelector(".ReactCrop");
//                         elems.setAttribute("aria-label", that.context.t("_link_crop_image"))
//                     };
//                     img.src = reader.result;
//                 };
//                 // reader.onloadend = () => {
//                 //     this.saveUserImage(file, reader.result)
//                 // };
//             } else {
//                 this.props.showPopup({
//                     type: "error",
//                     title: this.context.t("_invalid"),
//                     message: this.context.t("_invalid_file_type"),
//                     isOpen: true,
//                     buttonText: this.context.t("_ok")
//                 })
//             }
//         }

//     }
//     saveUserImage = async () => {
//         // let authResponse = await this.auth();
//         // if (authResponse) {
//         const { config, croppedImageUrl, file } = this.state;
//         if (!_.isEmpty(croppedImageUrl)) {
//             this.handleCloseModal();
//             this.props.showLoader()
//             let url = `${config.api_url}${urlEndPoints.updateUserPic}`
//             // let headers = {
//             //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//             // }
//             let obj = {
//                 name: file.name,
//                 image: croppedImageUrl
//             }
//             try {
//                 const data = await this.networkRequest(url, "POST", obj);
//                 if (data.status == 200) {
//                     // this.setState({ submissionStatus: true, submissionStatus_1: true, successMessage: "Profile image updated successfully" })
//                     this.getProfileData()
//                     this.setState({ profileUpdateMessage: this.context.t("_profile_image_updated_successfully") })
//                     this.refs.profileUpdateMessageRef.focus()
//                     setTimeout(() => {
//                         this.setState({ profileUpdateMessage: null })
//                     }, 2000);
//                 }
//             } catch (error) {
//                 this.props.hideLoader()
//                 console.warn("error: ", error)
//             }
//         }
//         // }
//     }
//     removeProfilePic = async () => {
//         // let authResponse = await this.auth();
//         // if (authResponse) {
//         this.props.showLoader()
//         const { config } = this.state
//         let url = `${config.api_url}${urlEndPoints.removeUserPic}`
//         // let headers = {
//         //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//         // }
//         let obj = {}
//         try {
//             const data = await this.networkRequest(url, "POST", obj);
//             if (data.status === 200) {
//                 // this.setState({ submissionStatus: true, submissionStatus_1: true, successMessage: "Profile image removed successfully" })
//                 this.getProfileData()
//                 this.setState({ profileUpdateMessage: this.context.t("_profile_image_removed_successfully") })
//                 this.refs.profileUpdateMessageRef.focus()
//                 setTimeout(() => {
//                     this.setState({ profileUpdateMessage: null })
//                 }, 2000);
//             }
//         } catch (error) {
//             this.props.hideLoader()
//             console.warn("error: ", error)
//         }
//         // }
//     }
//     handleCloseModal = () => {
//         this.setState({
//             selectedImage: "",
//             croppedImageUrl: "",
//             file: "",
//         })
//         this.upload.value = "";
//         let elems = document.querySelector("#photoCrop");
//         elems.removeAttribute("aria-hidden")
//         elems.removeAttribute("tabindex")
//         let instance = M.Modal.getInstance(elems);
//         instance.close();
//         this.refs.changeProfileImageBtn.focus()
//     }
//     onImageLoaded = image => {
//         this.imageRef = image;
//     };
//     onCropComplete = crop => {
//         this.makeClientCrop(crop);
//     };

//     onCropChange = (crop, percentCrop) => {
//         // You could also use percentCrop:
//         // this.setState({ crop: percentCrop });
//         this.setState({ crop });
//     };
//     async makeClientCrop(crop) {
//         if (this.imageRef && crop.width && crop.height) {
//             const croppedImageUrl = await this.getCroppedImg(
//                 this.imageRef,
//                 crop,
//                 "newFile.jpeg"
//             );
//             this.setState({ croppedImageUrl });
//         }
//     }
//     getCroppedImg(image, crop, fileName) {
//         const canvas = document.createElement("canvas");
//         const scaleX = image.naturalWidth / image.width;
//         const scaleY = image.naturalHeight / image.height;
//         canvas.width = crop.width;
//         canvas.height = crop.height;
//         const ctx = canvas.getContext("2d");

//         ctx.drawImage(
//             image,
//             crop.x * scaleX,
//             crop.y * scaleY,
//             crop.width * scaleX,
//             crop.height * scaleY,
//             0,
//             0,
//             crop.width,
//             crop.height
//         );

//         return new Promise((resolve, reject) => {
//             resolve(canvas.toDataURL());
//         });
//     }
//     getBreadcrumb = () => {
//         const { activeTab } = this.state;
//         switch (activeTab) {
//             case "current-activity":
//                 return this.context.t("_current_activities")
//             case "participants":
//                 return this.context.t("_participants")
//             case "settings":
//                 return this.context.t("_settings")


//             default:
//                 return this.context.t("_current_activities")
//         }
//     }
//     handleTabClick = activeTab => {
//         // this.setState({ activeTab });
//         // this.refs.parentBreadcrumb.focus()
//     }
//     render() {
//         const { profileData, config, selectedImage, crop, activeTab, profileUpdateMessage } = this.state;
//         return (
//             <div id="main-container">
//                 <SlideToTop />
//                 <PageTitle title={this.context.t("_profile")} />
//                 <div className="container">
//                     <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
//                         <ul>
//                             <li className="breadcrumb-item" >
//                                 <Link to="/parent-profile" ref="parentBreadcrumb">{this.context.t("_profile")}</Link> {" "}

//                             </li>
//                             <li className="breadcrumb-item">
//                                 <Link tabIndex="0" to aria-current={this.context.t("_page")}>
//                                     <span>
//                                         {this.getBreadcrumb()}
//                                     </span>
//                                 </Link>

//                             </li>
//                         </ul>
//                     </nav>
//                 </div>
//                 <div id="skipContent" className="container profile-container">
//                     <div className="row">
//                         <div className="col m12 l3 text-center">

//                             <div className="parent-profile-main">

//                                 {/* <figure className="profilePic">
//                                         <span>
//                                             <img src={!_.isEmpty(profileData.picture) ? `${config.origin}${profileData.picture}` : "../../assets/images/profile_pic.jpg"} alt={`${profileData.name}`} />
//                                         </span>
//                                     </figure> */}
//                                 <figure className="profilePic profileNew">
//                                     {
//                                         _.isEmpty(profileData.picture) ?
//                                             <img src={blankImage} alt="" />
//                                             :
//                                             <>
//                                                 <img src={blankImage} alt="" />
//                                                 <span>
//                                                     <img src={`${config.origin}${profileData.picture}`} alt="" />
//                                                 </span>
//                                             </>
//                                     }
//                                     <button aria-label={this.context.t("_change_profile_image")}
//                                         type="button" ref="changeProfileImageBtn" onClick={() => { this.upload.click() }} className="btn changePic">
//                                         <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
//                                     </button>
//                                     <input id="myInput"
//                                         type="file"
//                                         ref={(ref) => this.upload = ref}
//                                         style={{ display: "none" }}
//                                         onChange={this.onChangeFile.bind(this)}
//                                     />
//                                     <label style={{ display: "none" }} htmlFor="myInput" aria-label="select profile image">select profile image</label>
//                                     <button aria-label={this.context.t("_remove_profile_image")} onClick={() => this.removeProfilePic()} type="button" className="btn removePic">
//                                         <i className="fa fa-trash" aria-hidden="true"></i>
//                                     </button>

//                                 </figure>
//                                 <h4 className="profileheading profileNew">{profileData.name}</h4>
//                                 {
//                                     !_.isEmpty(profileUpdateMessage) && <p tabIndex="0" ref="profileUpdateMessageRef" style={{ textAlign: "center" }}>{profileUpdateMessage}</p>
//                                 }
//                                 {/* <div className="starHolder">
//                                         <span className="fa fa-star checked"></span>
//                                         <span className="fa fa-star checked"></span>
//                                         <span className="fa fa-star checked"></span>
//                                         <span className="fa fa-star"></span>
//                                         <span className="fa fa-star"></span>
//                                     </div> */}



//                                 <ul>
//                                     <li>
//                                         <Link tabIndex="0" to="/parent-profile/current-activity" className={activeTab == "current-activity" ? "active" : ""}>
//                                             <img src={activityImage} alt="" />
//                                             <p>{this.context.t("_current_activities")}</p>
//                                         </Link>
//                                     </li>
//                                     <li>
//                                         <Link tabIndex="0" to="/parent-profile/participants" className={activeTab == "participants" ? "active" : ""}>
//                                             <img src={connectionImage} alt="" />
//                                             <p>{this.context.t("_participants")}</p>
//                                         </Link>
//                                     </li>

//                                     <li>
//                                         <Link tabIndex="0" to="/parent-profile/settings" className={activeTab == "settings" ? "active" : ""}>
//                                             <img src={settingsImage} alt="" />
//                                             <p>{this.context.t("_profile_settings")}</p>
//                                         </Link>
//                                     </li>
//                                 </ul>

//                             </div>

//                         </div>

//                         <div className="col m12 l9">
//                             {
//                                 activeTab == "current-activity" && <ParentCurrentActivity {...this.props} />
//                             }
//                             {
//                                 activeTab == "participants" && <ParentParticipants />
//                             }
//                             {
//                                 activeTab == "settings" && <ParentSettings />
//                             }


//                         </div>
//                         <div id="photoCrop" className="modal sm" role="dialog">
//                             <div className="modal-content scroll-x-hidden">
//                                 <span className="badge modalClose"><button className="close" aria-label="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
//                                 <p>{this.context.t("_crop_your_image")}</p>
//                                 <div className="image-crop-container">
//                                     <ReactCrop
//                                         // crossorigin="anonymous"
//                                         src={selectedImage}
//                                         crop={crop}
//                                         ruleOfThirds
//                                         minWidth={200}
//                                         minHeight={200}
//                                         keepSelection={true}
//                                         imageStyle={{ maxHeight: 250 }}
//                                         onImageLoaded={this.onImageLoaded}
//                                         onComplete={this.onCropComplete}
//                                         onChange={this.onCropChange}
//                                     />
//                                 </div>

//                                 <div className="clearfix" />
//                                 <button onClick={() => this.saveUserImage()} className="btn profileBtn yellow">{this.context.t("_upload")}</button>
//                                 <button onClick={() => this.handleCloseModal()} className="btn profileBtn blue-border">{this.context.t("_cancel")}</button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>)
//     }
// }


// const mapStateToProps = ({ i18nState }) => {
//     return {
//         lang: i18nState.lang
//     }
// }
// const mapDispatchToProps = {
//     showLoader: LoadingAction.showLoader,
//     hideLoader: LoadingAction.hideLoader,
//     userLoggedIn: LoginAction.userLoggedIn,
// }
// CurrentNewActivity.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(CurrentNewActivity)



import React, { Component } from 'react'
import { Link, useParams } from "react-router-dom";
import _ from "lodash"
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import M from "materialize-css";
import ReactCrop from "react-image-crop";

import { LoadingAction, LoginAction } from "./../../../actions"
import Main from '../../../constants/utilities';
import PageTitle from "../../includes/page_title";
import SlideToTop from "../../includes/slide_to_top";
import { urlEndPoints } from "./../../../httpAPI/apiConfig"

import blankImage from "../../../assets/images/blank.png"
import activityImage from "../../../assets/images/ico_activities.png"
import connectionImage from "../../../assets/images/ico_connections.png"
import settingsImage from "../../../assets/images/ico_settings.png"

import ParentCurrentActivity from "./pages/activities"
import ParentParticipants from "./pages/participants"
import ParentSettings from "./pages/settings"
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

import { getProfileData } from '../../../actions/dashboard/profileAction/parentProfileAction';

const CurrentNewActivity = (props, context) => {
    // constructor(props) {
    //     super(props);
    //     const { match: { params: { menuType } } } = props;
    //     this.state = {
    //         config: this.config(),
    //         profileData: {
    //             picture: "",
    //             name: ""
    //         },
    //         selectedImage: "",
    //         croppedImageUrl: "",
    //         submissionStatus: false,
    //         submissionStatus_1: false,
    //         successMessage: null,
    //         file: "",
    //         crop: {
    //             unit: "px",
    //             width: 200,
    //             height: 201,
    //             aspect: 2 / 2.1,
    //         },
    //         activeTab: menuType || "current-activity",
    //         profileUpdateMessage: null
    //     }
    // }


    const { config, networkRequest } = Main();
    const [configuration, setConfiguration] = useState(config())
    // const [profileData, setProfileData] = useState({
    //     picture: "",
    //     name: ""
    // });
    const [selectedImage, setSelectedImage] = useState("");
    const [croppedImageUrl, setCroppedImageUrl] = useState("");
    const [submissionStatus, setSubmissionStatus] = useState(false);
    const [submissionStatus_1, setSubmissionStatus_1] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [file, setFile] = useState("");
    const [crop, setCrop] = useState({
        unit: "px",
        width: 200,
        height: 201,
        aspect: 2 / 2.1,
    });
    const [profileUpdateMessage, setProfileUpdateMessage] = useState(null);

    // const [status,setStatus]=useState("")
    const { menuType } = useParams()

    const [activeTab, setActiveTab] = useState(menuType || "current-activity");
    const [image, setImage] = useState("")



    const { lang } = useSelector((state) => state.i18nState)

    const {profileData}=useSelector((state)=>state.profileReducer)
    const dispatch = useDispatch()

    const parentBreadcrumbRef = useRef(null);
    const profileUpdateMessageRef = useRef(null)
    const uploadRef = useRef(null)
    const changeProfileImageBtnRef = useRef(null)
    // async componentDidMount() {
    //     document.title = `${this.context.t("_studio56")} |`
    //     M.AutoInit();
    //     await this.getProfileData()

    //     const { match: { params: { menuType } } } = this.props;
    //     if (!menuType)
    //         this.refs.parentBreadcrumb.focus()
    //     // setTimeout(() => {
    //     //     if (_.isEmpty(menuType))
    //     //         // this.refs.parentBreadcrumb.focus()
    //     // }, 500);
    // }
    // componentDidUpdate() {
    //     const { match: { params: { menuType } } } = this.props;
    //     if (menuType && menuType != this.state.activeTab) {
    //         this.setState({ activeTab: menuType })
    //     }
    //     document.title = `${this.context.t("_studio56")} | ${this.context.t("_profile")}`
    // }


    useEffect(() => {
        document.title = `${context.t("_studio56")} |`;
        M.AutoInit();
        dispatch(getProfileData())
        // const fetchData = async () => {
        //     dispatch(getProfileData())
        //     // await getProfileData();
        // }
        if (!menuType) {
            parentBreadcrumbRef.current.focus();
            // setTimeout(() => {
            //     if (_.isEmpty(menuType))
            //         // this.refs.parentBreadcrumb.focus()
            // }, 500);
        }
        // fetchData()
    }, []);


 


    useEffect(() => {
        if (menuType && menuType !== activeTab) {
            setActiveTab(menuType)
            //   setState(prevState => ({ ...prevState, activeTab: menuType }));
        }
        document.title = `${context.t("_studio56")} | ${context.t("_profile")}`;
    }, [menuType]);




    // const getProfileData = async () => {
    //     // const authResponse = await this.auth();
    //     // if (authResponse) {
    //     const url = `${configuration.api_url}${urlEndPoints.userDetails}`
    //     // const headers = {
    //     //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
    //     // }
    //     try {
    //         const data = await networkRequest(url)
    //         localStorage.setItem("profileImage", `${configuration.origin}${data.data.picture}`)
    //         dispatch(LoginAction.userLoggedIn(`${configuration.origin}${data.data.picture}`))
    //         // props.userLoggedIn(`${configuration.origin}${data.data.picture}`)
    //         dispatch(LoadingAction.hideLoader())
    //         setProfileData(data.data)
    //         // setState({ profileData: data.data })
    //     } catch (error) {
    //         dispatch(LoadingAction.hideLoader())
    //         console.warn("error: ", error)
    //     }
    //     // }
    // }
    const onChangeFile = (event) => {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        if (file) {
            if (file.type.includes("image")) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    var img = new Image;
                    img.onload = function () {
                        // if (img.width < 250) {
                        //     return that.props.showPopup({
                        //         type: "error",
                        //         title: "Invalid",
                        //         message: "Image size should be greater than 250px!",
                        //         isOpen: true,
                        //         buttonText: "Ok"
                        //     })
                        // } else if (img.width >= img.height) {
                        //     return that.props.showPopup({
                        //         type: "error",
                        //         title: "Invalid",
                        //         message: "Image height should be greater than width!",
                        //         isOpen: true,
                        //         buttonText: "Ok"
                        //     })
                        // } else {
                        //     that.saveUserImage(file, reader.result)
                        // }

                        // that.setState({ selectedImage: reader.result, file, submissionStatus: false, submissionStatus_1: false })
                        setSelectedImage(reader.result)
                        setFile(file)
                        setSubmissionStatus(false)
                        setSubmissionStatus_1(false)
                        let elems = document.querySelector("#photoCrop");
                        elems.setAttribute("aria-hidden", "false")
                        elems.setAttribute("tabindex", "-1")
                        let instance = M.Modal.getInstance(elems);
                        instance.open();
                        elems = document.querySelector(".ReactCrop");
                        elems.setAttribute("aria-label", context.t("_link_crop_image"))
                    };
                    img.src = reader.result;
                };
                // reader.onloadend = () => {
                //     saveUserImage(file, reader.result)
                // };
            } else {
                // dispatch(PopUpAction.showPopup({
                //     type: "error",
                //     title: context.t("_invalid"),
                //     message: context.t("_invalid_file_type"),
                //     isOpen: true,
                //     buttonText: context.t("_ok")
                // }))

            }
        }

    }
    const saveUserImage = async () => {
        // let authResponse = await this.auth();
        // if (authResponse) {
        if (!_.isEmpty(croppedImageUrl)) {
            handleCloseModal();
            dispatch(LoadingAction.showLoader())
            let url = `${configuration.api_url}${urlEndPoints.updateUserPic}`
            // let headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            let obj = {
                name: file.name,
                image: croppedImageUrl
            }
            try {
                const data = await networkRequest(url, "POST", obj);
                if (data.status == 200) {
                    // this.setState({ submissionStatus: true, submissionStatus_1: true, successMessage: "Profile image updated successfully" })
                    getProfileData()
                    setProfileUpdateMessage(context.t("_profile_image_updated_successfully"))
                    profileUpdateMessageRef.current.focus()
                    setTimeout(() => {
                        setProfileUpdateMessage(null)
                    }, 2000);
                }
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
        }
        // }
    }
    const removeProfilePic = async () => {
        // let authResponse = await this.auth();
        // if (authResponse) {
        dispatch(LoadingAction.showLoader())
        let url = `${configuration.api_url}${urlEndPoints.removeUserPic}`
        // let headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        let obj = {}
        try {
            const data = await networkRequest(url, "POST", obj);
            if (data.status === 200) {
                // setState({ submissionStatus: true, submissionStatus_1: true, successMessage: "Profile image removed successfully" })
                getProfileData()
                setProfileUpdateMessage(context.t("_profile_image_removed_successfully"))
                // this.setState({ profileUpdateMessage: this.context.t("_profile_image_removed_successfully") })
                profileUpdateMessageRef.current.focus()
                setTimeout(() => {
                    setProfileUpdateMessage(null)
                    // this.setState({ profileUpdateMessage: null })
                }, 2000);
            }
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // }
    }
    const handleCloseModal = () => {
        // this.setState({
        //     selectedImage: "",
        //     croppedImageUrl: "",
        //     file: "",
        // })
        setSelectedImage("")
        setCroppedImageUrl("")
        setFile("")
        uploadRef.current.value = "";
        let elems = document.querySelector("#photoCrop");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        changeProfileImageBtnRef.current.focus()
    }
    const onImageLoaded = image => {
        setImage(image)
    };
    const onCropComplete = crop => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        // this.setState({ crop });
        setCrop(crop)
    };
    const makeClientCrop = async (crop) => {
        if (image && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                image,
                crop,
                "newFile.jpeg"
            );
            // this.setState({ croppedImageUrl });
            setCroppedImageUrl(croppedImageUrl)
        }
    }
    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            resolve(canvas.toDataURL());
        });
    }
    const getBreadcrumb = () => {
        switch (activeTab) {
            case "current-activity":
                return context.t("_current_activities")
            case "participants":
                return context.t("_participants")
            case "settings":
                return context.t("_settings")


            default:
                return context.t("_current_activities")
        }
    }
    const handleTabClick = activeTab => {
        // this.setState({ activeTab });
        // this.refs.parentBreadcrumb.focus()
    }
    return (
        <div id="main-container">
            <SlideToTop />
            <PageTitle title={context.t("_profile")} />
            <div className="container">
                <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                    <ul>
                        <li className="breadcrumb-item" >
                            <Link to="/parent-profile" ref={parentBreadcrumbRef} >{context.t("_profile")}</Link> {" "}

                        </li>
                        <li className="breadcrumb-item">
                            <Link tabIndex="0" to aria-current={context.t("_page")} >
                                <span>
                                    {getBreadcrumb()}
                                </span>
                            </Link>

                        </li>
                    </ul>
                </nav>
            </div>
            <div id="skipContent" className="container profile-container">
                <div className="row">
                    <div className="col m12 l3 text-center">

                        <div className="parent-profile-main">

                            {/* <figure className="profilePic">
                                        <span>
                                            <img src={!_.isEmpty(profileData.picture) ? `${configuration.origin}${profileData.picture}` : "../../assets/images/profile_pic.jpg"} alt={`${profileData.name}`} />
                                        </span>
                                    </figure> */}
                            <figure className="profilePic profileNew">
                                {
                                    _.isEmpty(profileData.picture) ?
                                        <img src={blankImage} alt="" />
                                        :
                                        <>
                                            <img src={blankImage} alt="" />
                                            <span>
                                                <img src={`${configuration.origin}${profileData.picture}`} alt="" />
                                            </span>
                                        </>
                                }
                                <button aria-label={context.t("_change_profile_image")}
                                    type="button" ref={changeProfileImageBtnRef} onClick={() => { uploadRef.current.click() }} className="btn changePic">
                                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </button>
                                <input id="myInput"
                                    type="file"
                                    ref={uploadRef}
                                    style={{ display: "none" }}
                                    onChange={(e)=>onChangeFile(e)}
                                />
                                <label style={{ display: "none" }} htmlFor="myInput" aria-label="select profile image">select profile image</label>
                                <button aria-label={context.t("_remove_profile_image")} onClick={() => removeProfilePic()} type="button" className="btn removePic">
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>

                            </figure>
                            <h4 className="profileheading profileNew">{profileData.name}</h4>
                            {
                                !_.isEmpty(profileUpdateMessage) && <p tabIndex="0" ref={profileUpdateMessageRef} style={{ textAlign: "center" }}>{profileUpdateMessage}</p>
                            }
                            {/* <div className="starHolder">
                                        <span className="fa fa-star checked"></span>
                                        <span className="fa fa-star checked"></span>
                                        <span className="fa fa-star checked"></span>
                                        <span className="fa fa-star"></span>
                                        <span className="fa fa-star"></span>
                                    </div> */}



                            <ul>
                                <li>
                                    <Link tabIndex="0" to="/parent-profile/current-activity" className={activeTab == "current-activity" ? "active" : ""}>
                                        <img src={activityImage} alt="" />
                                        <p>{context.t("_current_activities")}</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link tabIndex="0" to="/parent-profile/participants" className={activeTab == "participants" ? "active" : ""}>
                                        <img src={connectionImage} alt="" />
                                        <p>{context.t("_participants")}</p>
                                    </Link>
                                </li>

                                <li>
                                    <Link tabIndex="0" to="/parent-profile/settings" className={activeTab == "settings" ? "active" : ""}>
                                        <img src={settingsImage} alt="" />
                                        <p>{context.t("_profile_settings")}</p>
                                    </Link>
                                </li>
                            </ul>

                        </div>

                    </div>

                    <div className="col m12 l9">
                        {
                            activeTab == "current-activity" && <ParentCurrentActivity {...props} />
                        }
                        {
                            activeTab == "participants" && <ParentParticipants />
                        }
                        {
                            activeTab == "settings" && <ParentSettings  />
                        }


                    </div>
                    <div id="photoCrop" className="modal sm" role="dialog">
                        <div className="modal-content scroll-x-hidden">
                            <span className="badge modalClose"><button className="close" aria-label="close" onClick={() => handleCloseModal()}><i className="material-icons">close</i></button></span>
                            <p>{context.t("_crop_your_image")}</p>
                            <div className="image-crop-container">
                                <ReactCrop
                                    // crossorigin="anonymous"
                                    src={selectedImage}
                                    crop={crop}
                                    ruleOfThirds
                                    minWidth={200}
                                    minHeight={200}
                                    keepSelection={true}
                                    imageStyle={{ maxHeight: 250 }}
                                    onImageLoaded={onImageLoaded}
                                    onComplete={onCropComplete}
                                    onChange={onCropChange}
                                />
                            </div>

                            <div className="clearfix" />
                            <button onClick={() => saveUserImage()} className="btn profileBtn yellow">{context.t("_upload")}</button>
                            <button onClick={() => handleCloseModal()} className="btn profileBtn blue-border">{context.t("_cancel")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}


const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
    userLoggedIn: LoginAction.userLoggedIn,
}
CurrentNewActivity.contextTypes = {
    t: PropTypes.func
}
export default CurrentNewActivity
