import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Main from "../../../constants/utilities.js";
import PageTitle from "../../includes/page_title.js";
import SlideToTop from "../../includes/slide_to_top.js";
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../../constants/dynamicStyles"
import M from "materialize-css";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { emailValidate, nameValidate, qidValidate, phoneValidate, nameWordLengthCheck, gradeListNew, calenderI18n, CustomDateInput, calenderLocal } from "./../../../constants/common"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import CountryList from "./../../../constants/country.json"
import _, { isEmpty } from "lodash"
import { PopupAction, LoadingAction, LoginAction } from "./../../../actions"
import dateformat from "dateformat";
import ReactCrop from "react-image-crop";
import topLineImage from "../../../assets/images/top-line.png"
import blankImage from "../../../assets/images/blank.png"
import "react-image-crop/dist/ReactCrop.css";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

const UniversityProfileSettings = (props, context) => {

    const { config, networkRequest } = Main();


    const [formData, setFormData] = useState({});
    const [profileImage, setProfileImage] = useState("");
    const [intervalId, setIntervalId] = useState(0);
    const [scrollStepInPx, setScrollStepInPx] = useState(50);
    const [delayInMs, setDelayInMs] = useState(16.66);
    const [selectedImage, setSelectedImage] = useState("");
    const [croppedImageUrl, setCroppedImageUrl] = useState("");
    const [submissionStatus, setSubmissionStatus] = useState(false);
    const [submissionStatus_1, setSubmissionStatus1] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [file, setFile] = useState("");
    const [crop, setCrop] = useState({ unit: "px", width: 200, height: 201, aspect: 2 / 2.1, });
    const [schoolList, setSchoolList] = useState([]);
    const [configuration, setConfiguration] = useState(config())
    const [imageRef, setImageRef] = useState(null)


    const { lang } = useSelector((state) => state.i18nState)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const successMessageRef = useRef(null)
    const uploadRef = useRef(null)
    const changeProfileImageBtnRef = useRef(null)




    useEffect(() => {
        dispatch(LoadingAction.showLoader())
        const fetchData = async () => {

            document.title = `${context.t("_studio56")} | ${context.t("_settings")}`
            await initState();
            M.AutoInit();
            let today = new Date()
            var options = {
                autoClose: true,
                onSelect: ((date) => {
                    handleChange("dob", dateformat(date, "dd/mm/yyyy"))
                }),
                format: "dd/mm/yyyy",
                container: null,
                maxDate: new Date("12/31/2014"),
                minDate: new Date("01/01/2001"),
                yearRange: 20,
                minYear: 2001,
                maxYear: 2014
            };
            setTimeout(() => {
                var elems = document.querySelectorAll("#dob");
                var selectelem = document.querySelectorAll("select");
                options.i18n = calenderI18n(context);
                // M.Datepicker.init(elems, options);
                M.FormSelect.init(selectelem, options);
            }, 1000);
            getSchoolList();
            getProfileSettingsData();
            window.addEventListener('load', handleTabIndex());
        }
        fetchData()
    }, []);

    const handleTabIndex = () => {
        setTimeout(() => {
            let elem = document.querySelectorAll('select');
            elem.forEach(element => {
                element.setAttribute("tabindex", "0");
            });
        }, 1000);
    }
    const initState = () => {
        setFormData({
            name: { value: "", error: "", class: "" },
            qid: { value: "", error: "", class: "" },
            schoolName: { value: "", error: "", class: "" },
            schoolNewName: { value: "", error: "", class: "" },
            gender: { value: "Male", error: "", class: "" },
            dob: { value: "", error: "", class: "" },
            nationality: { value: "", error: "", class: "" },
            grade: { value: "", error: "", class: "" },
            email: { value: "", error: "", class: "" },
            mobile: { value: "", error: "", class: "" },
            channel: { value: "Mobile Number", error: "", class: "" },

        })

    }
    const handleChange = (field, value) => {
        formData[field]["class"] = value ? "" : "invalid";
        formData[field]["value"] = value;
        setFormData({ ...formData })
        setSubmissionStatus(false)
    };
    const renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max, disabled }) => {

        return (type == "phone_number" ?
            <div className="row mobile">
                <div className="col s4">
                    <div className="input-field item">
                        <input name={`${name}_country_code`}
                            placeholder="Country Code"
                            value={context.t("_ar_country_code")}
                            style={dynamicStyles.textAlign(lang)}
                            id={`${name}_country_code`}
                            type="text"
                            minLength="3"
                            maxLength="3"
                            disabled

                        />
                        <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{context.t("_country_code")}</label>
                    </div>
                </div>
                <div className="col s8">
                    <div className="input-field item">
                        <input
                            name={name}
                            placeholder={placeholder}
                            aria-label={context.t("_phone_aria_label")}
                            id={name}
                            maxLength={max}
                            type="text"
                            style={dynamicStyles.textAlign(lang)}
                            autoComplete="no"
                            onChange={evt =>
                                handleChange(name, evt.target.value)
                            }
                            onFocus={evt =>
                                handleChange(name, evt.target.value)
                            }
                            value={formData[name].value}

                        />
                        <label htmlFor={name} style={dynamicStyles.position(lang)}>
                            {`${label} `}
                            {isMandatory ? <span className="asterisk">*</span> : null}
                        </label>
                    </div>
                </div>
            </div>
            : type == "date" ? <div className="input-field item">
                <DatePicker
                    name={name}
                    placeholderText={placeholder}
                    id={name}
                    autoComplete="no"
                    maxDate={new Date("12/31/2014")}
                    minDate={new Date("01/01/2001")}
                    dateFormat="yyyy-MM-dd"
                    onChange={date =>
                        handleChange(name, date)
                    }
                    style={dynamicStyles.textAlign(lang)}
                    value={formData[name].value}
                    selected={formData[name].value}
                    locale={calenderLocal(context)}
                    customInput={<CustomDateInput ariaLabel={context.t("_select_dob_button")} onFieldChange={date => handleChange(name, new Date(date))} />}
                    onCalendarOpen={e => {
                        let elems = document.querySelector(".react-datepicker");
                        elems.setAttribute("tabindex", "0")
                        elems.focus()
                        elems = document.querySelector(".react-datepicker__month-select");
                        elems.setAttribute("aria-label", context.t("_select_month"))
                        elems = document.querySelector(".react-datepicker__year-select");
                        elems.setAttribute("aria-label", context.t("_select_year"))

                    }}
                    onCalendarClose={e => {
                        var elems = document.querySelector(".hiddenDob");
                        elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(formData[name].value, "dd-mm-yyyy")}`)
                        elems.setAttribute("value", dateformat(formData[name].value, "yyyy-mm-dd"))
                        setTimeout(() => {
                            elems.focus()
                        }, 200);
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <span
                    style={dynamicStyles.textAlign(lang)}
                    className="helper-text"
                    data-error={formData[name].error ? formData[name].error : "Required field."}
                ></span>
            </div> :
                <div className="input-field item">
                    <input
                        name={name}
                        placeholder={placeholder}
                        id={name}
                        autoComplete="no"
                        maxLength={max}
                        readOnly={disabled}
                        type={type === "date" ? "text" : type}
                        style={dynamicStyles.textAlign(lang)}

                        onChange={evt =>
                            handleChange(name, evt.target.value)
                        }
                        onFocus={evt =>
                            handleChange(name, evt.target.value)
                        }
                        value={formData[name].value}

                    />
                    <label htmlFor={name} style={dynamicStyles.position(lang)}>
                        {`${label} `}
                        {isMandatory ? <span className="asterisk">*</span> : null}
                    </label>
                </div>)
    }

    const renderSelect = ({ name, label, placeholder, isMandatory = false, data = [], type = "select" }) => {

        return (
            <div className="input-field mobileSelect">
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <select
                    className="browser-default"
                    name={name}
                    id={name}
                    style={dynamicStyles.textAlign(lang)}

                    onChange={evt =>
                        handleChange(name, evt.target.value)
                    }

                    onFocus={evt =>
                        handleChange(name, evt.target.value)
                    }
                    value={formData[name].value}

                >
                    <option value="" disabled> {placeholder} </option>
                    {
                        data?.map((item, index) => {
                            return type == "country" ?
                                <option key={index} value={item.country_id}>{item.country[lang]}</option>
                                :
                                type == "school" ? <option key={index} value={item.id}>{item.sname}</option> :
                                    <option key={index} value={item.value}>{item.label}</option>
                        })
                    }
                    {
                        type == "school" && <option value="0">{context.t("_not_in_list")}</option>
                    }
                </select>

                <span
                    style={dynamicStyles.textAlign(lang)}
                    className="helper-text"
                    data-error={formData[name].error ? formData[name].error : "Required field."}
                ></span>
            </div>
        )
    }
    const renderRadioButton = ({ data = [], name, label, defaultChecked, isMandatory = false }) => {

        return (
            <div className="input-field item">
                <div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={name}>
                    {
                        !isEmpty(data) && data?.map((item, index) => {
                            return (
                                <div key={index} className={item.value}>
                                    <input
                                        aria-checked={formData[name].value === item.value}
                                        name={name}
                                        id={item.value}
                                        aria-labelledby={`${name} ${item.label}`}
                                        type="radio"
                                        readOnly
                                        className="radio-btn"
                                        onClick={evt =>
                                            handleChange(name, item.value)
                                        }
                                        checked={formData[name].value === item.value}
                                    />
                                    <label id={`_${item.label}`} htmlFor={item.value} className="custom-radio-style">
                                        <span className="custom-radio-style">{item.label}</span>
                                    </label>
                                </div>
                            )
                        })
                    }

                </div>
                <label htmlFor={name} id={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>

                <span
                    className="helper-text"
                    data-error="Required field."
                ></span>
            </div>
        )
    }
    const getProfileSettingsData = async () => {

        let url = `${configuration.api_url}${urlEndPoints.userSettings}`

        try {
            const data = await networkRequest(url)
            let formDetails = { ...formData }
            if (!data.data.message) {
                formDetails = {
                    name: { value: data.data.name, error: "", class: "" },
                    qid: { value: data.data.field_qid, error: "", class: "" },
                    gender: { value: data.data.field_gender, error: "", class: "" },
                    nationality: { value: data.data.field_nationality, error: "", class: "" },
                    grade: { value: data.data.field_grade, error: "", class: "" },
                    email: { value: data.data.field_student_email, error: "", class: "" },
                    mobile: { value: !_.isEmpty(data.data.field_student_mobile) ? data.data.field_student_mobile.replace("+974", "") : "", error: "", class: "" },
                    schoolName: { value: data.data.field_school, error: "", class: "" },
                    schoolNewName: { value: data.data.field_new_school_name, error: "", class: "" },

                }
                const [date, month, year] = data.data.field_student_dob.split("/");
                formDetails.dob = { value: new Date(`${month}/${date}/${year}`), error: "", class: "" };
            }
            localStorage.setItem("profileImage", `${configuration.origin}${data.data.picture}`)
            dispatch(LoginAction.userLoggedIn(`${configuration.origin}${data.data.picture}`))
            setFormData({ ...formDetails })
            setProfileImage(data.data.picture)
            dispatch(LoadingAction.hideLoader())
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
    const getSchoolList = async () => {

        const url = `${configuration.api_url}${urlEndPoints.schoolList(lang)}`
        try {
            const data = await networkRequest(url)
            setSchoolList(data.data)

            initSelect()
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
    const initSelect = () => {
        if (formData.schoolName) {
            var options = {
                autoClose: true,
                classes: formData.schoolName.class

            };
            var elems = document.querySelectorAll("select");
            M.FormSelect.init(elems, options);
        }

    }
    const scrollStep = () => {
        var scrollStepInPx = scrollStepInPx;
        if (window.pageYOffset <= 400) {
            clearInterval(intervalId);
        }
        window.scroll(0, window.pageYOffset - scrollStepInPx);
    }
    const scrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" })

    }
    const validate = () => {
        for (var key in formData) {
            if (formData[key].value)
                formData[key].value = key == "dob" ? formData[key].value : formData[key].value.trim();
            formData[key].error = "";
        }
        if (formData.name.value == "")
            formData.name.error = context.t("_student_name_required");

        if (formData.name.value != "" && !nameValidate.test(formData.name.value))
            formData.name.error = context.t("_invalid_student_name")

        if (formData.name.value != "" && nameValidate.test(formData.name.value) && !nameWordLengthCheck(formData.name.value))
            formData.name.error = context.t("_student_name_contains_atleast_two_word")

        if (formData.qid.value == "")
            formData.qid.error = context.t("_qid_required")

        if (formData.qid.value != "" && !qidValidate.test(formData.qid.value))
            formData.qid.error = context.t("_invalid_qid")

        if (formData.dob.value == "")
            formData.dob.error = context.t("_date_of_birth_required")

        if (formData.nationality.value == "")
            formData.nationality.error = context.t("_nationality_required")

        if (formData.grade.value == "")
            formData.grade.error = context.t("_grade_required")

        if (formData.email.value == "")
            formData.email.error = context.t("_email_required")

        if (formData.email.value != "" && !emailValidate.test(formData.email.value))
            formData.email.error = context.t("_invalid_email")

        if (formData.mobile.value == "")
            formData.mobile.error = context.t("_mobile_number_required")

        if (formData.mobile.value != "" && !phoneValidate.test(formData.mobile.value.replace(/ /g, "")))
            formData.mobile.error = context.t("_invalid_mobile_number")


        if (formData.schoolName.value == "")
            formData.schoolName.error = context.t("_school_name_required");

        if (formData.schoolName.value == "0" && formData.schoolNewName.value == "")
            formData.schoolNewName.error = context.t("_school_name_required");



        for (var key in formData) {
            if (formData[key].error != "") {
                setFormData({ ...formData })
                setSubmissionStatus(false)
                setSubmissionStatus1(false)
                scrollToTop();
                setTimeout(() => {
                    document.querySelector(`a[href="#${key}"]`).focus()

                }, 1000);
                return false;

            }
        }
        setFormData({ ...formData })

        return true;
    }
    const displayError = error => {

        if (error.mail)
            formData.email.error = error.mail[lang]

        if (error.qid)
            formData.qid.error = error.qid[lang]

        if (error.nationality)
            formData.nationality.error = error.nationality[lang]

        if (error.grade)
            formData.grade.error = error.grade[lang]

        if (error.Student_name)
            formData.name.error = error.Student_name[lang]

        if (error.DOB)
            formData.dob.error = error.DOB[lang]

        // this.setState({ formData })
        setFormData({ ...formData })

        scrollToTop();
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        dispatch(LoadingAction.showLoader())
        setSubmissionStatus(false)
        setSubmissionStatus1(false)
        if (validate()) {

            let obj = {
                "name": formData.email.value,
                "mail": formData.email.value,
                "field_student_email": formData.email.value,
                "field_qid": formData.qid.value,
                "field_student_mobile": `+974${formData.mobile.value.replace(/ /g, "")}`,
                "roles": {
                    "target_id": "student"
                },
                "field_nationality": formData.nationality.value,
                "field_student_name": formData.name.value,
                "field_grade": formData.grade.value,
                "field_gender": formData.gender.value,
                "field_student_dob": dateformat(formData.dob.value, "dd/mm/yyyy"),
                "field_reference": {
                    "target_id": ""
                }
            }
            if (!_.isEmpty(formData.schoolName.value)) {
                let schoolName = schoolList.filter(item => item.id == formData.schoolName.value)
                if (formData.schoolName.value == "0")
                    schoolName = formData.schoolNewName.value
                else
                    schoolName = _.isEmpty(schoolName) ? null : schoolName[0].sname


                obj.school_id = {
                    "target_id": formData.schoolName.value,
                    "target_type": "taxonomy_term"
                };
                obj.school_name = schoolName
            }

            let url = `${configuration.api_url}${urlEndPoints.registerUniversityStudent}`
            try {
                const data = await networkRequest(url, "PATCH", obj)
                if (data.data.error) {
                    displayError(data.data.error)

                    dispatch(LoadingAction.hideLoader())
                }
                else {
                    setSubmissionStatus(true)
                    setSubmissionStatus1(true)
                    setSuccessMessage(context.t("_profile_updated_successfully"))

                    getProfileSettingsData();
                    successMessageRef.current.focus()
                }
            } catch (error) {

                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }

        } else {

            dispatch(LoadingAction.hideLoader())
        }
    }
    const onChangeFile = (event) => {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        if (file) {
            if (file.type.includes("image")) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    var img = new Image;
                    img.onload = function () {

                        setSubmissionStatus(false)
                        setSubmissionStatus1(false)
                        setSelectedImage(reader.result)
                        setFile(file)
                        let elems = document.querySelector("#photoCrop");
                        elems.setAttribute("aria-hidden", "false")
                        elems.setAttribute("tabindex", "-1")
                        let instance = M.Modal.getInstance(elems);
                        instance.open();
                        elems = document.querySelector(".ReactCrop");
                        elems.setAttribute("aria-label", context.t("_link_crop_image"))
                    };
                    img.src = reader.result;
                };

            } else {
                dispatch(PopupAction.showPopup({
                    type: "error",
                    title: context.t("_invalid"),
                    message: context.t("_invalid_file_type"),
                    isOpen: true,
                    buttonText: context.t("_ok")
                }))

            }
        }

    }
    const saveUserImage = async () => {

        if (!_.isEmpty(croppedImageUrl)) {
            handleCloseModal();
            dispatch(LoadingAction.showLoader())
            let url = `${configuration.api_url}${urlEndPoints.updateUserPic}`

            let obj = {
                name: file.name,
                image: croppedImageUrl
            }
            try {
                const data = await networkRequest(url, "POST", obj);
                if (data.status == 200) {
                    setSubmissionStatus(true)
                    setSubmissionStatus1(true)
                    setSuccessMessage(context.t("_profile_image_updated_successfully"))

                    getProfileSettingsData()
                    successMessageRef.current.focus()


                }
            } catch (error) {

                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
        }
        // }
    }
    const removeProfilePic = async () => {

        dispatch(LoadingAction.showLoader())

        let url = `${configuration.api_url}${urlEndPoints.removeUserPic}`

        let obj = {}
        try {
            const data = await networkRequest(url, "POST", obj);
            if (data.status === 200) {
                setSubmissionStatus(true)
                setSubmissionStatus1(true)
                setSuccessMessage(context.t("_profile_image_removed_successfully"))

                getProfileSettingsData()
                successMessageRef.current.focus()


            }
        } catch (error) {

            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }

    }
    const handleChangePassword = () => {
        navigate("/change-password")
    }
    const handleCloseModal = () => {

        setSelectedImage("")
        setCroppedImageUrl("")
        setFile("")
        uploadRef.current.value = "";
        let elems = document.querySelector("#photoCrop");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        changeProfileImageBtnRef.current.focus()
    }
    const onImageLoaded = image => {
        setImageRef(image)

    };
    const onCropComplete = crop => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {

        setCrop(crop)

    };

    const makeClientCrop = async (crop) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                imageRef,
                crop,
                "newFile.jpeg"
            );
            setCroppedImageUrl(croppedImageUrl)

        }
    }
    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            resolve(canvas.toDataURL());
        });
    }


    let hasError = false;
    Object.keys(formData).map((item, index) => {
        if (formData[item].error != "")
            hasError = true
    })
    return (
        <div id="main-container">
            <SlideToTop />
            <PageTitle title={context.t("_settings")} />
            <div id="skipContent" className="container">
                <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                    <ul>
                        <li className="breadcrumb-item">
                            <Link to="/profile">{context.t("_profile")}</Link> {" "}

                        </li>
                        <li className="breadcrumb-item">
                            <Link tabIndex="0" to aria-current={context.t("_page")}>
                                <span>
                                    {context.t("_settings")}
                                </span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col s3 text-center">
                            <figure className="profilePic">
                                <img src={blankImage} alt="" />
                                <span>
                                    <img src={`${configuration.origin}${profileImage}`} alt="" />
                                </span>
                                <button a aria-label={context.t("_change_profile_image")}
                                    type="button" ref={changeProfileImageBtnRef} onClick={() => { uploadRef.current.click() }} className="btn changePic">
                                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </button>
                                <input id="myInput"
                                    type="file"
                                    ref={uploadRef}
                                    style={{ display: "none" }}
                                    onChange={(e) => onChangeFile(e)}
                                />
                                <label style={{ display: "none" }} htmlFor="myInput" aria-label="select profile image">select profile image</label>
                                <button aria-label={context.t("_remove_profile_image")} onClick={() => removeProfilePic()} type="button" className="btn removePic">
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </figure>
                        </div>
                        <div className="col s9">

                        </div>
                    </div>

                    <div className="row">
                        {
                            hasError ? <div className="error-container fail">
                                <h5>{context.t("_error_title")}</h5>
                                <ul>
                                    {
                                        Object.keys(formData).map((item, index) => {
                                            return formData[item].error != "" ? <li key={index} ><a tabIndex="0" href={`#${item}`} className="error" htmlFor={item}>{formData?.[item]?.error}</a></li>
                                                :
                                                null
                                        })}
                                </ul>
                            </div> : null

                        }
                        {
                            (submissionStatus || submissionStatus_1) ? <div className="error-container success" tabIndex="0" ref={successMessageRef} >
                                <h5>{successMessage}</h5>
                            </div> : null
                        }
                        {
                            _.isEmpty(formData) ?
                                <div /> :
                                <form className="full-width" onSubmit={onSubmit} >
                                    <div style={{ width: "100%", display: "inline-block" }}>
                                        <div className="col s12 m6">
                                            {renderInput({ name: "name", label: context.t("_name"), placeholder: context.t("_name_placeholder"), isMandatory: true })}
                                            {renderInput({ name: "qid", label: context.t("_qid"), placeholder: context.t("_qid_placeholder"), isMandatory: true, disabled: true, max: 11 })}
                                            <div className="result-type">
                                                {
                                                    renderSelect({
                                                        name: "grade", label: context.t("_grade"), placeholder: context.t("_select_your_grade"), isMandatory: true, data: gradeListNew[lang]
                                                    })
                                                }
                                            </div>
                                            <div className="result-type">
                                                {
                                                    renderSelect({
                                                        name: "nationality", label: context.t("_nationality"), placeholder: context.t("_select_your_nationality"), isMandatory: true, data: CountryList, type: "country"
                                                    })
                                                }
                                            </div>

                                            {
                                                renderRadioButton({ label: context.t("_gender"), isMandatory: true, name: "gender", defaultChecked: "male", data: [{ label: context.t("_male"), value: "Male" }, { label: context.t("_female"), value: "Female" }] })
                                            }

                                        </div>
                                        <div className="col s12 m6">
                                            <div className=" date-popup">
                                                {renderInput({ name: "dob", label: context.t("_dob"), placeholder: context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
                                            </div>

                                            {renderInput({ name: "mobile", label: context.t("_mobile_number"), placeholder: context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max: 8 })}

                                            {renderInput({ name: "email", label: context.t("_email"), placeholder: context.t("_email_placeholder"), type: "email", isMandatory: true, disabled: true })}

                                            <div className="result-type">
                                                {
                                                    renderSelect({
                                                        name: "schoolName", label: context.t("_school_name"), placeholder: context.t("_select_school_name"), data: schoolList, type: "school", isMandatory: true
                                                    })
                                                }
                                            </div>
                                            {
                                                formData.schoolName.value == "0" && renderInput({ name: "schoolNewName", label: context.t("_school_name"), placeholder: context.t("_select_school_name"), isMandatory: true })
                                            }
                                        </div>
                                    </div>



                                    <div className="col s12 flex-row">
                                        <button className="btn blue">{context.t("_save_changes")}</button>
                                        <button type="button" onClick={() => { handleChangePassword() }} className="btn profileBtn margin0">{context.t("_change_password")}</button>
                                    </div>
                                </form>
                        }
                    </div>

                    <div id="photoCrop" className="modal sm" role="dialog">
                        <div className="modal-content scroll-x-hidden">
                            <span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => handleCloseModal()}><i className="material-icons">close</i></button></span>
                            <p>{context.t("_crop_your_image")}</p>
                            <div className="image-crop-container">
                                <ReactCrop
                                    // crossorigin="anonymous"
                                    src={selectedImage}
                                    crop={crop}
                                    ruleOfThirds
                                    minWidth={200}
                                    minHeight={200}
                                    keepSelection={true}
                                    imageStyle={{ maxHeight: 250 }}
                                    onImageLoaded={onImageLoaded}
                                    onComplete={onCropComplete}
                                    onChange={onCropChange}
                                />
                            </div>

                            <div className="clearfix" />
                            <button onClick={() => saveUserImage()} className="btn profileBtn yellow">{context.t("_upload")}</button>
                            <button onClick={() => handleCloseModal()} className="btn profileBtn blue-border">{context.t("_cancel")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="top-line ng-scope">
                <img src={topLineImage} alt="" />
            </div>
        </div>
    )


}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showPopup: (data) => PopupAction.showPopup(data),
    hidePopup: (data) => PopupAction.hidePopup(data),
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
    userLoggedIn: LoginAction.userLoggedIn,
}
UniversityProfileSettings.contextTypes = {
    t: PropTypes.func
}
export default UniversityProfileSettings



