import { HIDE_LOADER, SHOW_LOADER } from "./../constants/constants";

const initialState = {
    loading: false
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        loading: action.payload
      };
    case HIDE_LOADER:
      return {
        loading: action.payload
      };

    default:
      return { ...state };
  }
};

export default loaderReducer;
