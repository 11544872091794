import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"

import Main from "../../constants/utilities"
import PageTitle from "../includes/page_title.js"
import SlideToTop from "../includes/slide_to_top.js"
import topLineImage from "../../assets/images/top-line.png";

const ThankYou = (props, context) => {
    const { config } = Main();
	const configure = config()
	const [isLoaded, setIsLoaded] = useState(false)
	const contactSuccessRef = useRef(null);
	useEffect(() => {
		document.title = `${context.t("_studio56")} | ${context.t("_contact_us")}`;
		setTimeout(() => {
			contactSuccessRef.current && contactSuccessRef.current.focus();
		}, 1000);
	}, []);

	const { lang } = useSelector(
		(state) => state.i18nState
	);
	/*filter_menu(){
		const { config } = state;
		axios.get(config.origin+"/api/media-detailsgallery/4", {
			params: {
			  _format: "json"
			}
			})
			.then(function (response) {
				console.log(response.data);
		    
			})
			.catch(function (error) {
			console.log(error);
			});
	}*/
	return (
		<div id="main-content">
			<SlideToTop />
			<PageTitle title={context.t("_contact_us")} />
			<div id="breadcrumb-wrap">
				<div className="container">
				</div>
			</div>
			<div className="container">
				<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
					<ul>
						<li className="breadcrumb-item">
							<Link to="/">{context.t("_home")}</Link>  {" "}
						</li>
						<li className="breadcrumb-item">
							<Link tabIndex="0" to aria-current={context.t("_page")}>
								<span>{context.t("_contact_us")}</span>
							</Link>
						</li>
					</ul>
				</nav>
			</div>
			<div id="skipContent">
				<div id="main-container">
					<div className="container">
						<div className="row col-2">
							<div className="item subscribe-form">
								<h2 style={{ marginBottom: 10 }}>{context.t("_contact_us")}</h2>
								{/* <p><strong>{context.t("_subscribe_heading_1")}</strong><br />{context.t("_subscribe_heading_2")}</p> */}
								<div className="thankyou-box" tabIndex="0" ref={contactSuccessRef}>
									<h2 className="" > {context.t("_thank_you")}</h2>
									<span className=""> {context.t("_we_appreciate")}</span>
									<br /><span className=""> {context.t("_get_back_soon")} </span>
								</div>
							</div>
							<div className="item">
								<img src={require("../../assets/images/subscribe-right.png")} alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="top-line ng-scope">
				<img src={topLineImage} alt="" />
			</div>
		</div>
	)

}

ThankYou.contextTypes = {
	t: PropTypes.func
}
export default ThankYou;
