import React, { useState, useEffect } from "react"
import Main from "../../../constants/utilities"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import Pagination from "react-js-pagination";
import _ from "lodash"
import M from "materialize-css"
import { PopupAction, LoadingAction, ActivityListAction } from "./../../../actions"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import newActive1 from "../../../assets/images/profile_pic.jpg"
import eye from "../../../assets/images/eye.svg"
import { getType, getFieldType, getZone, getGender } from "./utilFunctions"

const ActivityList = (props, context) => {
    const { config } = Main();
    const configure = config()
    const dispatch = useDispatch()
    const [results, setResults] = useState([]);
    const [search, setSearch] = useState({ title: "", type: "", zone: "", fieldType: "" });
    const { lang } = useSelector(state => state.i18nState);
    const { activityFullList } = useSelector(state => state.activityListReducer) || { activityFullList: {} }
    const activityList = useSelector(state => state.activityListReducer.activityList.results)
    const { pager } = useSelector(state => state.activityListReducer.activityList) || { pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 } }
    useEffect(() => {
        M.AutoInit();
        setTimeout(() => {
            let elems = document.querySelectorAll("select");
            M.FormSelect.init(elems);
            elems = document.querySelectorAll(".view-more-img");
            // M.Tooltip.init(elems);
        }, 1000);
        // this.props.getActivityList({ lang });
        // this.props.getActivityFullList({ lang });
        window.addEventListener('load', handleTabIndex());
    }, []);

    const handleTabIndex = () => {
        setTimeout(() => {
            let elem = document.querySelectorAll('select');
            elem.forEach(element => {
                element.setAttribute("tabindex", "0");
            });
        }, 1000);
    }
    const handleActivityPageChange = (pageNumber) => {
        const { startDate, endDate, type, zone, fieldType } = props;
        dispatch(LoadingAction.showLoader())
        const updatedSearch = { ...search, pages: pageNumber - 1 };
        dispatch(ActivityListAction.getActivityList({ lang, ...updatedSearch, startDate, endDate, type, zone, fieldType }))
        // this.props.getActivityFullList({ lang, ...search, startDate, endDate });
    }
    const handleChange = (field, value) => {
        setSearch(prevSearch => ({
            ...prevSearch,
            [field]: value,
        }));
    }
    const onSubmit = async (event) => {
        const { startDate, endDate, type, zone, fieldType } = props;
        const updatedSearch = { ...search, pages: 0 };

        event.preventDefault();
        dispatch(LoadingAction.showLoader())
        dispatch(ActivityListAction.getActivityList({ lang, ...updatedSearch, startDate, endDate, type, zone, fieldType }))
    };
    // this.props.getActivityFullList({ lang, ...search, startDate, endDate });

    const handleClearSearch = () => {
        dispatch(LoadingAction.showLoader())
        setSearch({ title: "", type: "", zone: "", fieldType: "" });
        dispatch(ActivityListAction.getActivityList({ lang }));
    }
    
    const timeDiff = (start, end) => {
        start = start.split(":");
        end = end.split(":");
        var startDate = new Date(0, 0, 0, start[0], start[1], 0);
        var endDate = new Date(0, 0, 0, end[0], end[1], 0);
        var diff = endDate.getTime() - startDate.getTime();
        var hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        var minutes = Math.floor(diff / 1000 / 60);

        return (hours < 9 ? "0" : "") + hours + ":" + (minutes < 9 ? "0" : "") + minutes;
    }

    const calculateDateDiff = (fromDate, toDate) => {
        const date1 = new Date(fromDate);
        const date2 = new Date(toDate);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }
    return (

        <div id="activity" className="col s12">
            <div className="dashboard__fliter filter-new">
                <form onSubmit={onSubmit} autoComplete="no">
                    <div className="row">
                        <div className="col">
                            <div className="form-wrapper">
                                <div className="row">
                                    <div className="input-group col">
                                        <label htmlFor="activityTitle">{context.t("_search")}</label>
                                        <div className="input-field inline search">
                                            <input
                                                id="activityTitle"
                                                name="title"
                                                type="text"
                                                className="validate"
                                                placeholder={context.t("_activity_name")}
                                                value={search.title}
                                                onChange={evt => handleChange("title", evt.target.value)}
                                                onFocus={evt => handleChange("title", evt.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {/* <label htmlFor="type">{context.t("_type")}</label>
                        <div className="input-field inline search mobileSelect">
                            <select
                                id="type"
                                name="type"
                                className="browser-default"
                                value={search.type}
                                onChange={evt => handleChange("type", evt.target.value)}
                                onFocus={evt => handleChange("type", evt.target.value)}
                            >
                                <option value="" disabled>{context.t("_select_type")}</option>
                                <option value="event">{context.t("_events")}</option>
                                <option value="session">{context.t("_sessions")}</option>
                                <option value="competition">{context.t("_competitions")}</option>
                                <option value="challenge">{context.t("_challenges")}</option>
                                <option value="project">{context.t("_projects")}</option>
                            </select>
                        </div> */}

                                    {/* <label htmlFor="zone">{context.t("_zone")}</label>
                        <div className="input-field inline search mobileSelect">
                            <select
                                id="zone"
                                name="zone"
                                className="browser-default"
                                value={search.zone}
                                onChange={evt => handleChange("zone", evt.target.value)}
                                onFocus={evt => handleChange("zone", evt.target.value)}
                            >
                                <option value="" disabled>{context.t("_select_zone")}</option>
                                <option value="1">{context.t("_creative_zone")}</option>
                                <option value="3">{context.t("_technology_zone")}</option>
                                <option value="2">{context.t("_software_zone")}</option>
                            </select>
                        </div> */}

                                    {/* <label htmlFor="fieldType">{`${context.t("_online")}/ ${context.t("_onsite")}`}</label>
                        <div className="input-field inline mobileSelect">
                            <select
                                id="fieldType"
                                name="fieldType"
                                className="browser-default"
                                value={search.fieldType}
                                onChange={evt => handleChange("fieldType", evt.target.value)}
                                onFocus={evt => handleChange("fieldType", evt.target.value)}
                            >
                                <option value="" disabled>{context.t("_select")}</option>
                                <option value="online">{context.t("_online")}</option>
                                <option value="onsite">{context.t("_onsite")}</option>
                            </select>
                        </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="btn-wrapper">
                                <button className="btn bgBlue waves-effect waves-light">{context.t("_apply")}</button>
                                <button onClick={() => handleClearSearch()} type="button" className="btn blueBorder">{context.t("_reset")}</button>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
            <div className="tableHolder">
                <table className="responsive-table highlight">
                    <thead className="blue lighten-5">
                        <tr>
                            <th><strong>{context.t("_activity_name")}</strong></th>
                            <th><strong>{context.t("_type")}</strong></th>
                            <th><strong>{`${context.t("_online")}/ ${context.t("_onsite")}`}</strong></th>
                            <th><strong>{context.t("_zone")}</strong></th>
                            <th><strong>{context.t("_session_hours")}</strong></th>
                            <th><strong>{context.t("_start_date")}</strong></th>
                            <th><strong>{context.t("_end_date")}</strong></th>
                            <th><strong>{context.t("_activity_gender")}</strong></th>
                            <th><strong>{context.t("_age_group")}</strong></th>
                            <th><strong>{context.t("_enrolled")}</strong></th>
                            <th><strong>{context.t("_attended")}</strong></th>
                            <th><strong>{context.t("_waiting")}</strong></th>
                            <th><strong>{context.t("_view")}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            activityList?.map(item => {
                                return (
                                    <tr key={item.uid}>
                                        <td>
                                            <img className="profile-img" src={item.image} alt="" />
                                            <span>{item.title}</span>
                                        </td>
                                        <td>{getType(item.event_type, context)}</td>
                                        <td>{getFieldType(item.activity_type, context)}</td>
                                        <td>{getZone(item.activity_zone, context)}</td>
                                        <td className="text-center">{item.session_hours}</td>
                                        <td>{item.startDate[0]}</td>
                                        <td>{item.endDate[item.endDate.length - 1]}</td>
                                        <td>{getGender(item.activity_gender, context)}</td>
                                        <td>{item.activity_age_group}</td>
                                        <td className="text-center">{item.enrollment_count}</td>
                                        <td className="text-center">{item.attended_count}</td>
                                        <td className="text-center">{item.waitinglist_count}</td>

                                        <td>
                                            <Link aria-label={`${item.title} view`} to={`/activity-details/${item.uid}`}>
                                                <img className="view-more-img" src={eye} alt="view more details" data-position="bottom" data-tooltip="View Profile" />
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <Pagination
                    prevPageText={`< ${context.t("_previous")}`}
                    nextPageText={`${context.t("_next")} >`}
                    hideFirstLastPages={true}
                    hideDisabled={true}
                    linkClassPrev="linkClassPrev"
                    linkClassNext="linkClassNext"
                    activePage={pager?.current_page + 1}
                    itemsCountPerPage={pager?.items_per_page}
                    totalItemsCount={pager?.count}
                    pageRangeDisplayed={5}
                    onChange={handleActivityPageChange}
                />
            </div>

            {
                /**
                 * Print
                 */
            }


        </div>
    )
}

ActivityList.contextTypes = {
    t: PropTypes.func
}
export default ActivityList;


// import React from "react"
// import Main from "../../main"
// import { connect } from "react-redux"
// import { Link } from "react-router-dom"
// import PropTypes from "prop-types"
// import Pagination from "react-js-pagination";
// import _ from "lodash"
// import M from "materialize-css"
// import { PopupAction, LoadingAction, ActivityListAction } from "./../../../actions"
// import { urlEndPoints } from "./../../../httpAPI/apiConfig"
// import newActive1 from "../../../assets/images/profile_pic.jpg"
// import eye from "../../../assets/images/eye.svg"
// import { getType, getFieldType, getZone, getGender } from "./utilFunctions"

// class ActivityList extends Main {
//     constructor(props) {
//         super(props);
//         this.state = {
//             config: this.config(),
//             activityList: [],
//             results: [],
//             pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
//             search: { title: "", type: "", zone: "", fieldType: "" }
//         }
//     }
//     componentDidMount() {
//         const { lang } = this.props;
//         M.AutoInit();
//         setTimeout(() => {
//             let elems = document.querySelectorAll("select");
//             M.FormSelect.init(elems);
//             elems = document.querySelectorAll(".view-more-img");
//             // M.Tooltip.init(elems);
//         }, 1000);
//         // this.props.getActivityList({ lang });
//         // this.props.getActivityFullList({ lang });
//         window.addEventListener('load', this.handleTabIndex());
//     }
//     handleTabIndex = () => {
//         setTimeout(() => {
//             let elem = document.querySelectorAll('select');
//             elem.forEach(element => {
//                 element.setAttribute("tabindex", "0");
//             });
//         }, 1000);
//     }
//     handleActivityPageChange = (pageNumber) => {
//         const { lang, startDate, endDate, type, zone, fieldType } = this.props;
//         this.props.showLoader()
//         var { search } = this.state;
//         search.pages = pageNumber - 1;
//         this.props.getActivityList({ lang, ...search, startDate, endDate, type, zone, fieldType });
//         // this.props.getActivityFullList({ lang, ...search, startDate, endDate });
//     }
//     handleChange = (field, value) => {
//         const { search } = this.state;
//         search[field] = value;
//         this.setState({ search });
//     }
//     onSubmit = async (event) => {
//         const { lang, startDate, endDate, type, zone, fieldType } = this.props;
//         const { search } = this.state;
//         search.pages = 0;
//         event.preventDefault();
//         this.props.showLoader();
//         this.props.getActivityList({ lang, ...search, startDate, endDate, type, zone, fieldType });
//         // this.props.getActivityFullList({ lang, ...search, startDate, endDate });
//     }
//     handleClearSearch = () => {
//         const { lang } = this.props;
//         this.props.showLoader();
//         this.setState({ search: { title: "", type: "", zone: "", fieldType: "" } }, () => {
//             this.props.getActivityList({ lang });
//             // this.props.getActivityFullList({ lang });
//         })
//     }
//     timeDiff(start, end) {
//         start = start.split(":");
//         end = end.split(":");
//         var startDate = new Date(0, 0, 0, start[0], start[1], 0);
//         var endDate = new Date(0, 0, 0, end[0], end[1], 0);
//         var diff = endDate.getTime() - startDate.getTime();
//         var hours = Math.floor(diff / 1000 / 60 / 60);
//         diff -= hours * 1000 * 60 * 60;
//         var minutes = Math.floor(diff / 1000 / 60);

//         return (hours < 9 ? "0" : "") + hours + ":" + (minutes < 9 ? "0" : "") + minutes;
//     }

//     calculateDateDiff = (fromDate, toDate) => {
//         const date1 = new Date(fromDate);
//         const date2 = new Date(toDate);
//         const diffTime = Math.abs(date2 - date1);
//         const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
//         return diffDays;
//     }
//     render() {
//         const { search, config } = this.state;
//         const { activityList, pager, activityFullList } = this.props;
//         return (

//             <div id="activity" className="col s12">
//                 <div className="dashboard__fliter filter-new">
//                     <form onSubmit={this.onSubmit} autoComplete="no">
//                     <div className="row">
//                         <div className="col">
//                         <div className="form-wrapper">
//                             <div className="row">
//                             <div className="input-group col">
//                         <label htmlFor="activityTitle">{this.context.t("_search")}</label>
//                         <div className="input-field inline search">
//                             <input
//                                 id="activityTitle"
//                                 name="title"
//                                 type="text"
//                                 className="validate"
//                                 placeholder={this.context.t("_activity_name")}
//                                 value={search.title}
//                                 onChange={evt => this.handleChange("title", evt.target.value)}
//                                 onFocus={evt => this.handleChange("title", evt.target.value)}
//                             />
//                         </div>
//                         </div>

//                         {/* <label htmlFor="type">{this.context.t("_type")}</label>
//                         <div className="input-field inline search mobileSelect">
//                             <select
//                                 id="type"
//                                 name="type"
//                                 className="browser-default"
//                                 value={search.type}
//                                 onChange={evt => this.handleChange("type", evt.target.value)}
//                                 onFocus={evt => this.handleChange("type", evt.target.value)}
//                             >
//                                 <option value="" disabled>{this.context.t("_select_type")}</option>
//                                 <option value="event">{this.context.t("_events")}</option>
//                                 <option value="session">{this.context.t("_sessions")}</option>
//                                 <option value="competition">{this.context.t("_competitions")}</option>
//                                 <option value="challenge">{this.context.t("_challenges")}</option>
//                                 <option value="project">{this.context.t("_projects")}</option>
//                             </select>
//                         </div> */}

//                         {/* <label htmlFor="zone">{this.context.t("_zone")}</label>
//                         <div className="input-field inline search mobileSelect">
//                             <select
//                                 id="zone"
//                                 name="zone"
//                                 className="browser-default"
//                                 value={search.zone}
//                                 onChange={evt => this.handleChange("zone", evt.target.value)}
//                                 onFocus={evt => this.handleChange("zone", evt.target.value)}
//                             >
//                                 <option value="" disabled>{this.context.t("_select_zone")}</option>
//                                 <option value="1">{this.context.t("_creative_zone")}</option>
//                                 <option value="3">{this.context.t("_technology_zone")}</option>
//                                 <option value="2">{this.context.t("_software_zone")}</option>
//                             </select>
//                         </div> */}

//                         {/* <label htmlFor="fieldType">{`${this.context.t("_online")}/ ${this.context.t("_onsite")}`}</label>
//                         <div className="input-field inline mobileSelect">
//                             <select
//                                 id="fieldType"
//                                 name="fieldType"
//                                 className="browser-default"
//                                 value={search.fieldType}
//                                 onChange={evt => this.handleChange("fieldType", evt.target.value)}
//                                 onFocus={evt => this.handleChange("fieldType", evt.target.value)}
//                             >
//                                 <option value="" disabled>{this.context.t("_select")}</option>
//                                 <option value="online">{this.context.t("_online")}</option>
//                                 <option value="onsite">{this.context.t("_onsite")}</option>
//                             </select>
//                         </div> */}
//                         </div>
//                         </div>
//                         </div>
//                         <div className="col">
//                         <div className="btn-wrapper">
//                         <button className="btn bgBlue waves-effect waves-light">{this.context.t("_apply")}</button>
//                         <button onClick={() => this.handleClearSearch()} type="button" className="btn blueBorder">{this.context.t("_reset")}</button>
//                         </div>
//                         </div>
//                         </div>

//                     </form>
//                 </div>
//                 <div className="tableHolder">
//                     <table className="responsive-table highlight">
//                         <thead className="blue lighten-5">
//                             <tr>
//                                 <th><strong>{this.context.t("_activity_name")}</strong></th>
//                                 <th><strong>{this.context.t("_type")}</strong></th>
//                                 <th><strong>{`${this.context.t("_online")}/ ${this.context.t("_onsite")}`}</strong></th>
//                                 <th><strong>{this.context.t("_zone")}</strong></th>
//                                 <th><strong>{this.context.t("_session_hours")}</strong></th>
//                                 <th><strong>{this.context.t("_start_date")}</strong></th>
//                                 <th><strong>{this.context.t("_end_date")}</strong></th>
//                                 <th><strong>{this.context.t("_activity_gender")}</strong></th>
//                                 <th><strong>{this.context.t("_age_group")}</strong></th>
//                                 <th><strong>{this.context.t("_enrolled")}</strong></th>
//                                 <th><strong>{this.context.t("_attended")}</strong></th>
//                                 <th><strong>{this.context.t("_waiting")}</strong></th>
//                                 <th><strong>{this.context.t("_view")}</strong></th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {
//                                 activityList.map(item => {
//                                     return (
//                                         <tr key={item.uid}>
//                                             <td>
//                                                 <img className="profile-img" src={item.image} alt="" />
//                                                 <span>{item.title}</span>
//                                             </td>
//                                             <td>{getType(item.event_type, this.context)}</td>
//                                             <td>{getFieldType(item.activity_type, this.context)}</td>
//                                             <td>{getZone(item.activity_zone, this.context)}</td>
//                                             <td className="text-center">{item.session_hours}</td>
//                                             <td>{item.startDate[0]}</td>
//                                             <td>{item.endDate[item.endDate.length - 1]}</td>
//                                             <td>{getGender(item.activity_gender, this.context)}</td>
//                                             <td>{item.activity_age_group}</td>
//                                             <td className="text-center">{item.enrollment_count}</td>
//                                             <td className="text-center">{item.attended_count}</td>
//                                             <td className="text-center">{item.waitinglist_count}</td>

//                                             <td>
//                                                 <Link aria-label={`${item.title} view`} to={`/activity-details/${item.uid}`}>
//                                                     <img className="view-more-img" src={eye} alt="view more details" data-position="bottom" data-tooltip="View Profile" />
//                                                 </Link>
//                                             </td>
//                                         </tr>
//                                     )
//                                 })
//                             }
//                         </tbody>
//                     </table>
//                     <Pagination
//                         prevPageText={`< ${this.context.t("_previous")}`}
//                         nextPageText={`${this.context.t("_next")} >`}
//                         hideFirstLastPages={true}
//                         hideDisabled={true}
//                         linkClassPrev="linkClassPrev"
//                         linkClassNext="linkClassNext"
//                         activePage={pager.current_page + 1}
//                         itemsCountPerPage={pager.items_per_page}
//                         totalItemsCount={pager.count}
//                         pageRangeDisplayed={5}
//                         onChange={this.handleActivityPageChange}
//                     />
//                 </div>

//                 {
//                     /**
//                      * Print
//                      */
//                 }


//             </div>
//         )
//     }

// }

// const mapStateToProps = ({ i18nState, activityListReducer }) => {
//     return {
//         lang: i18nState.lang,
//         activityList: activityListReducer.activityList.results,
//         pager: activityListReducer.activityList.pager,
//         activityFullList: activityListReducer.activityFullList
//     }
// }
// const mapDispatchToProps = {
//     showPopup: (data) => PopupAction.showPopup(data),
//     hidePopup: (data) => PopupAction.hidePopup(data),
//     getActivityList: (data) => ActivityListAction.getActivityList(data),
//     getActivityFullList: (data) => ActivityListAction.getActivityFullList(data),
//     showLoader: LoadingAction.showLoader,
//     hideLoader: LoadingAction.hideLoader,
// }
// ActivityList.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(ActivityList);