import React from "react";
import _ from "lodash"
import Modal from "react-modal";
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"

import { PopupAction } from "./../../actions"
import failImage from "../../assets/images/img_fail.png";
import successImage from "../../assets/images/img_success.png";
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        padding: 0,
        transform: "translate(-50%, -50%)"
    }
};

const Popup = (props, context) => {
    const defaultModalContent = {
                    type: "success",
                    title: "",
                    message: "",
                    isOpen: false,
                    buttonText: ""
                }
    const dispatch = useDispatch()
    const { lang } = useSelector(state => state.i18nState)
    const modalContent = useSelector(state => state.popupReducer.popupState)
    const { type, title, message, isOpen, buttonText } = modalContent || defaultModalContent;
    return (
        <div>
            <Modal
                isOpen={isOpen}
                // onAfterOpen={this.afterOpenModal}
                // onRequestClose={this.closeModal}
                aria-label="modal"
                style={customStyles}
                contentLabel="Modal"
                ariaHideApp={false}
                id="popupmodal"
            >
                <button data-dismiss="modal" type="button" onClick={() => dispatch(PopupAction.hidePopup({}))} aria-label="modal close" className={`badge modalClose`}><i className="material-icons">close</i></button>
                <div className={`popupAlert ${type == "success" ? "pass" : "failed"} `}>
                    <div className="pulsating-circle">
                        <span><img src={type == "success" ? successImage : failImage} alt="" /></span>
                    </div>

                    <h4 aria-label={`${context.t("_title")} ${title}`}>{title}</h4>
                    <div className="popupMessage">
                        <p aria-label={`${context.t("_message")} ${message}`}>{message}</p>
                    </div>

                    {
                        buttonText ? <button aria-label={`${buttonText} ${context.t("_button")}`} onClick={() => dispatch(PopupAction.hidePopup({}))} className="btn blue-border">{buttonText}</button> : null
                    }

                </div>
            </Modal>
        </div>
    )
}


Popup.contextTypes = {
    t: PropTypes.func
}
export default Popup;