import Main from "../../components/main"
import dateformat from "dateformat"
import _ from "lodash"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { GET_ACTIVITY_LIST, GET_ACTIVITY_FULL_LIST, HIDE_LOADER } from "./../../constants/constants"

const MainConst = new Main();
const configConst = MainConst.config();
const getActivityList = ({ lang, title = "", type = "", zone = "", fieldType = "", pages = 0, startDate = "", endDate = "" }) => {
    return async dispatch => {
        let url = ""
        if (lang == "ar")
            url = `${configConst.origin}api/${urlEndPoints.getDashboardActivityList}?`
        else
            url = `${configConst.origin}api/${urlEndPoints.getDashboardActivityList}?`
        const body = {
            page: pages,
            lang
        }

        if (!_.isEmpty(title)) {
            body.title = title
        }
        if (!_.isEmpty(type)) {
            body.type_1 = type
        }
        if (!_.isEmpty(zone)) {
            body.field_activity_zone_target_id = zone
        }
        if (!_.isEmpty(fieldType)) {
            body.field_type_of_activity_value = fieldType
        }
        if (!_.isEmpty(startDate)) {
            body.field_start_and_end_time_value = `${dateformat(startDate.value, "yyyy-mm-dd")} 00:00:00`
        }
        if (!_.isEmpty(endDate)) {
            body.field_start_and_end_time_end_value_1 = `${dateformat(endDate.value, "yyyy-mm-dd")} 00:00:00`
        }
        try {
            const data = await MainConst.networkRequest(url, "POST", body)
            if (data.data.error) {
                dispatch({
                    type: GET_ACTIVITY_LIST,
                    payload: 0,
                })
            } else {
                const result = data.data
                result.results.forEach(item => {
                    item.startDate = item.startDate.split("*")
                    item.endDate = item.endDate.split("*")
                });
                dispatch({
                    type: GET_ACTIVITY_LIST,
                    payload: result
                })
            }
            dispatch({
                type: HIDE_LOADER, payload: false
            })
        } catch (error) {
            dispatch({
                type: HIDE_LOADER, payload: false
            })
        }
    }
}
export default {
    getActivityList
}