import React, { useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import Main from "../../constants/utilities"
import PropTypes from "prop-types"
import { createRef } from "react";
import _ from "lodash";
// import { dynamicStyles } from "./../../constants/dynamicStyles"
const axios = require("axios");

const Banner = (props, context) => {

	const { config } = Main()
	const vidRef = useRef(null)

	const [isLoaded, setIsLoaded] = useState(false)
	const [configuration, setConfiguration] = useState(config())
	const [banner, setBanner] = useState([])
	const [activeClass, setActiveClass] = useState("")
	const [playing, setPlaying] = useState(true)

	const { lang } = useSelector((state) => state.i18nState)

	useEffect(() => {
		get_banner();
		window.addEventListener("scroll", () => {
			let activeClass = "stickySocial";
			if (window.scrollY === 0) {
				activeClass = "";
			}
			setActiveClass(activeClass)
		});
	}, [])

	useEffect(() => {
		if (!playing) {
			vidRef.current && vidRef.current.pause();
		} else {
			vidRef.current && vidRef.current.play();
		}
	}, [playing])

	const get_banner = () => {

		let url = "";
		if (lang == "ar")
			url = `${configuration.origin}${lang}/api/banner`
		else
			url = `${configuration.origin}api/banner`


		axios.get(url, {
			params: {
				_format: "json"
			}
		})
			.then(function (response) {
				setBanner(response.data)

				setTimeout(() => {
					const bannerButton = document.querySelector(".banner-contact");
					if (bannerButton && bannerButton.addEventListener) {
						bannerButton.addEventListener('focusin', () => {
							const bannerElement = document.querySelectorAll(".banner-contact");
							bannerElement[0].classList.add('hover');
						});
						bannerButton.addEventListener('focusout', () => {
							const bannerElement = document.querySelectorAll(".banner-contact");
							bannerElement[0].classList.remove('hover');
						});
					}
				}, 1000);
			})
			.catch(function (error) {
				console.warn(error);
			});
	}

	return (
		<div className={`app-slider ${activeClass}`}>
			<div className="video-box">
				{ banner.length > 0 && !_.isEmpty(banner[0]?.video) && <div className="video-btn">
					<button onClick={() => setPlaying(!playing)} className={playing ? "btn-toggle play" : "btn-toggle pause"} aria-label={playing ? context.t("_pause_video") : context.t("_play_video")}>{playing ? context.t("_pause") : context.t("_play")}</button>
				</div>}
				{
					banner.length > 0 && <video ref={vidRef} autoPlay playsInline muted loop style={{ backgroundImage: `url(${configuration.origin + banner[0].field_media})` }}>
						{banner.map((row, index) => {
							return (<source key={index} src={configuration.origin + row.video.replace("Video file<br />", "").trim()} />)
						}
						)}

					</video>
				}

			</div>
			<div className="banner">
				{banner.map((row, index) => (
					<div key={index}>
						<div className={`desc ${lang}`} dangerouslySetInnerHTML={{ __html: row.body }}></div>
					</div>
				))}
			</div>
			{/* <div className="banner-text-wrapper">
					<div className={`banner-text ${lang}`}>
						<h1>{this.context.t("_studio56")}</h1>
						<h3><span className="red-text">{this.context.t("_imagination")}</span>{this.context.t("_,")} <span className="blue-text">{this.context.t("_innovate")}{this.context.t("_,")} <b>{this.context.t("_and")}</b> <span className="orange-text">{this.context.t("_creativity")}</span></span></h3>
						{
							lang == "ar" ?
								<p>{this.context.t("_banner_heading_1")}<b>{this.context.t("_studio56")} </b>{this.context.t("_banner_heading_3")}</p>
								:
								<p><b>{this.context.t("_studio56")} </b>{this.context.t("_banner_heading_1")}<sup>{this.context.t("_banner_heading_2")}</sup>{this.context.t("_banner_heading_3")}</p>
						}
						<p><b>{this.context.t("_studio56")} </b>{this.context.t("_banner_heading_1")}<sup>{this.context.t("_banner_heading_2")}</sup>{this.context.t("_banner_heading_3")}</p>
					</div>
				</div> */}
		</div>
	)
}

Banner.contextTypes = {
	t: PropTypes.func
}

export default Banner
