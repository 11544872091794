import React from "react"
import Main from "../../constants/utilities.js"
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom"
import PageTitle from "../includes/page_title.js"
import SlideToTop from "../includes/slide_to_top.js"
import Slider from "react-slick";
import PropTypes from "prop-types"
import { LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { useState } from "react";
import { useEffect } from "react";
import { networkRequest } from "../../httpAPI/api.js";
import topLineImage from "../../assets/images/top-line.png";


const News = (props, context) => {
	const [isLoaded, setIsLoaded] = useState(false)
	const [media, setMedia] = useState([])
	const [slider, setSlider] = useState([])
	const [pageKey, setPageKey] = useState("")
	const [id, setId] = useState("")
	const [currentSlide, setCurrentSlide] = useState(0)
	const dispatch = useDispatch()
	const { lang } = useSelector(state => state.i18nState)
	const { config } = Main()
	const configure = config()
	const params = useParams()

	const afterChangeHandler = (currentSlide) => {
		setCurrentSlide(currentSlide)
	}
	function setTitle(title) {
		document.title = `${context.t("_studio56")} | ${title}`
	}
	const slider_settings = {
		className: "slider",
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: "0",
		afterChange: afterChangeHandler,
		prevArrow: <SlickArrowLeft />,
		nextArrow: <SlickArrowRight />,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					centerPadding: "0",
				}
			},
			{
				breakpoint: 780,
				settings: {
					centerPadding: "0",
				}
			}
		]
	};
	const slider_settings_news = {
		className: "slider news-slider",
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		arrows:false,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: "0",
		afterChange: afterChangeHandler,

		responsive: [
			{
				breakpoint: 992,
				settings: {
					centerPadding: "0",
				}
			},
			{
				breakpoint: 780,
				settings: {
					centerPadding: "0",
				}
			}
		]
	};

	const getMedia = async () => {
		let url = ""
		if (lang == "ar")
			url = `${configure.origin}${lang}/api/${urlEndPoints.mediaDetail(params.id)}`
		else
			url = `${configure.origin}api/${urlEndPoints.mediaDetail(params.id)}`
		try {
			const data = await networkRequest(url)
			const category = data?.data?.[0]?.["field_key"];
			let slider = []
			// if (category === "Gallery") {
			url = `${configure.api_url}${urlEndPoints.mediaDetailGallery(params.id)}`
			const galleryData = await networkRequest(url)
			slider = galleryData.data["media-gallery"]
			// }
			setMedia(data?.data)
			console.log('data?.data: ', data?.data);
			setPageKey(data.data[0].category)
			setSlider(slider)
			console.log('slider: ', slider);
			dispatch(LoadingAction.hideLoader())
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}


	useEffect(() => {
		dispatch(LoadingAction.showLoader())
		document.title = `${context.t("_studio56")} |`
		getMedia();

	}, [])
	return (
		<div className={pageKey.toLowerCase()}>
			<SlideToTop />
			<PageTitle title={pageKey.toUpperCase()} isDashboardItem={true} />
			<div id="skipContent">
				<div id="main-container" className="single-news">
					{media.map((row, index) => (
						<div key={index} >
							{setTitle(row.title)}
							<div className="container">
								<span className="multi-square"><b><i></i></b></span>
								<span className="grey-square-rotate"></span>
								<span className="grey-square-rotate bottom"></span>
								<span className="orange-circle"></span>
								<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
									<ul>
										<li className="breadcrumb-item">
											<Link to="/">{context.t("_home")}</Link>
										</li>
										<li className="breadcrumb-item">
											<Link to="/media-center">{context.t("_navbar_media_center")}</Link>
										</li>
										<li className="breadcrumb-item">
											<Link tabIndex="0" to aria-current={context.t("_page")}>
												<span dangerouslySetInnerHTML={{ __html: row.title }} ></span>
											</Link>
										</li>
									</ul>
								</nav>
								<h2 id="gallery-title" tabIndex="0" aria-label={`${row.title} ${row.date}`}>{row.title}</h2>
								<div className="date-wrap" dangerouslySetInnerHTML={{ __html: row.date }}></div>
								{row.field_key === "News" && 
									<div className="article-news">
										<Slider {...slider_settings_news}>
											{slider && slider.map((slider, slide_index) =>
												<div key={slide_index} className="item" style={{ padding: 0 }}>
													<img src={slider.image} alt="" />
													{/* <p><strong>Image caption </strong>{slider.caption}</p> */}
												</div>
											)}

										</Slider>
										{/* <div dangerouslySetInnerHTML={{ __html: row.media }} className="img-wrap"></div> */}
									</div>
								}
								<div dangerouslySetInnerHTML={{ __html: row.description }} className="content"></div>
							</div>
							{row.field_key === "Gallery" &&
								<div className="gallery-news">
									<div className="container">
										<div dangerouslySetInnerHTML={{ __html: row.media }} className="img-wrap"></div>
									</div>
									<h3 id="slideCount" className="gallery-slider-count" aria-label={`${row.title} ${context.t("_photo")} ${currentSlide + 1} of ${slider.length}`} tabIndex="0">
										<small>{context.t("_photo")} </small><strong>{`${currentSlide + 1} / ${slider.length}`}</strong>
									</h3>
									<Slider {...slider_settings}>
										{slider.map((slider, slide_index) =>
											<div key={slide_index} className="item">
												<div className="sliderHeight" style={{ backgroundImage: `url(${slider.image})` }}>
													<img src={slider.image} alt="" />
												</div>
												{/* <p><strong>{context.t("_image_caption")} </strong>{slider.caption}</p> */}
											</div>
										)}

									</Slider>
								</div>
							}
							{row.field_key === "Videos" &&
								<div className="video-news">
									<div className="container">
										<div className="videowrapper">
											<object
												data={row.video}>
												<param name="movie" value={row.video} />
												<param name="allowFullScreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" value="true"></param>
											</object>
										</div>
										{/* <p><strong>{context.t("_video_caption")} </strong>{row.field_video_caption}</p> */}

									</div>
								</div>
							}
							<div className="container">
								<div className={`view-more ${pageKey.toLowerCase()}`}><Link to="/media-center"><i className={`material-icons ${lang == "ar" ? "en" : "ar"}`} >{`${lang == "en" ? "arrow_backward" : "arrow_forward"}`}</i>  {context.t("_back")}</Link></div>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="top-line ng-scope"><img src={topLineImage} alt="" /></div>
		</div>
	)
}

News.contextTypes = {
	t: PropTypes.func
}

export default News

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }, context) => (
	<button
		{...props}
		className={
			"slick-prev slick-arrow" +
			(currentSlide === 0 ? " slick-disabled" : "")
		}
		type="button"
		id="slide-prev"
		aria-labelledby="slideCount slide-prev"
	>
		{context.t("_pre_gallery_image")}
	</button>
);

SlickArrowLeft.contextTypes = {
	t: PropTypes.func
}
const SlickArrowRight = ({ currentSlide, slideCount, ...props }, context) => (
	<button
		{...props}
		className={
			"slick-next slick-arrow" +
			(currentSlide === slideCount - 1 ? " slick-disabled" : "")
		}
		id="slide-next"
		type="button"
		aria-labelledby="slideCount slide-next"
	>
		{context.t("_next_gallery_image")}
	</button>
);
SlickArrowRight.contextTypes = {
	t: PropTypes.func
}
