import React, { useState, useEffect, useRef } from "react"
import _ from "lodash"
import M from "materialize-css";
import PropTypes from "prop-types"
import dateformat from "dateformat";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux"

import RegisterTypes from "./registerTypes"
import Main from "../../constants/utilities"
import "react-toastify/dist/ReactToastify.css";
import { LoadingAction } from "./../../actions"
import PageTitle from "../includes/page_title.js";
import SlideToTop from "../includes/slide_to_top.js";
import CountryList from "./../../constants/country.json"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import topLineImage from "../../assets/images/top-line.png"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import { emailValidate, nameValidate, qidValidate, phoneValidate, nameWordLengthCheck, gradeListNew, calenderI18n, CustomDateInput, calenderLocal } from "./../../constants/common"

const StudentRegistration = (props, context) => {
    const { config, networkRequest } = Main();
    const dispatch = useDispatch()
    const configure = config()
    const intervalId = 0;
    const delayInMs = 16.66
    const scrollStepInPx = 50;
    const [formData, setFormData] = useState({});
    const [schoolList, setSchoolList] = useState([])
    const [termsAndCondition, setTermsAndCondition] = useState({});
    const [submissionStatus, setSubmissionStatus] = useState(false);

    const { lang } = useSelector(
        state => state.i18nState
    );

    const renderDayContents = (day, date) => {
        const tooltipText = `Tooltip for date: ${date}`;
        return <span title={tooltipText}>{"4"}</span>;
    };
    useEffect(() => {
        document.title = `${context.t("_studio56")} | ${context.t("_registration")} | ${context.t("_student")}`
        initState();
        getSchoolList();
        getTermsAndCondition();
        M.AutoInit();
        let today = new Date()
        let options = {
            autoClose: true,
            onSelect: ((date) => {
                handleChange("dob", dateformat(date, "dd/mm/yyyy"))
            }),
            onDraw: ((e) => {
                // setTimeout(() => {
                //     let elem = document.querySelectorAll('select');
                //     console.log("elemelem->", elem);
                //     elem.forEach(element => {
                //         element.setAttribute("tabindex", "0");
                //     });
                // }, 300);
            }),
            // defaultDate: new Date("01/01/2001"),
            // setDefaultDate: new Date("12/31/2014"),
            format: "dd-mm-yyyy",
            container: null,
            maxDate: new Date("12/31/2014"),
            minDate: new Date("01/01/2001"),
            yearRange: 20,
            minYear: 2001,
            maxYear: 2014
        };

        setTimeout(() => {
            options.i18n = calenderI18n(context);
            // options.isRTL = props.lang == "ar"
            var elems = document.querySelectorAll("#dob");
            var selectelem = document.querySelectorAll("select");
            // options.i18n = calenderI18n(context);
            // let instance = M.Datepicker.init(elems, options);

            M.FormSelect.init(selectelem, options);
            let component = document.getElementById("nationality");
            if (component)
                component.tabIndex = 0;
            component = document.getElementById("grade");
            if (component)
                component.tabIndex = 0;
            component = document.getElementById("relation");
            if (component)
                component.tabIndex = 0;
        }, 1000);
    }, []);

    const initState = () => {
        setFormData({
            name: { value: "", error: "", class: "" },
            nameArabic: { value: "", error: "", class: "" },
            schoolName: { value: "", error: "", class: "" },
            schoolNewName: { value: "", error: "", class: "" },
            qid: { value: "", error: "", class: "" },
            gender: { value: "", error: "", class: "" },
            dob: { value: "", error: "", class: "" },
            nationality: { value: "", error: "", class: "" },
            grade: { value: "", error: "", class: "" },
            email: { value: "", error: "", class: "" },
            mobile: { value: "", error: "", class: "" },
            parentName: { value: "", error: "", class: "" },
            parentNameArabic: { value: "", error: "", class: "" },
            parentMobile: { value: "", error: "", class: "" },
            parentMobile2: { value: "", error: "", class: "" },
            parentEmail: { value: "", error: "", class: "" },
            parentQid: { value: "", error: "", class: "" },
            relation: { value: "", error: "", class: "" },
            channel: { value: "", error: "", class: "" },
            agree: { value: false, error: "", class: "" },

        })
    }
    const initSelect = () => {
        if (formData.schoolName) {
            var options = {
                autoClose: true,
                classes: formData.schoolName.class
            };
            var elems = document.querySelectorAll("select");
            M.FormSelect.init(elems, options);
        }

    }
    const handleChange = (field, value) => {
        if (field == "qid" || field == "parentQid" || field == "mobile" || field == "parentMobile" || field == "parentMobile2") {
            if (isNaN(value)) {
                return false
            }
        }
        const updatedFormData = { ...formData };
        updatedFormData[field]["class"] = value ? "" : "invalid";
        updatedFormData[field]["value"] = value;
        setFormData(updatedFormData)
        setSubmissionStatus(false)
    };
    const getSchoolList = async () => {
        const url = `${configure.api_url}${urlEndPoints.schoolList(lang)}`
        try {
            const data = await networkRequest(url)
            setSchoolList(data.data)
            initSelect()
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
    const getTermsAndCondition = async () => {
        let url = "";
        if (lang == "ar")
            url = `${configure.origin}${lang}/${urlEndPoints.termsAndCondition}`
        else
            url = `${configure.origin}${urlEndPoints.termsAndCondition}`
        try {
            const data = await networkRequest(url)
            if (data.status == 200 && data.data && data.data.length > 0) {
                setTermsAndCondition(data.data[0])
            }
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
    const renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max, styleClass = "" }) => {
        return (type == "phone_number" ?
            <div className="row mobile">
                <div className="col s4">
                    <div className="input-field item">
                        <input name={`${name}_country_code`}
                            className={styleClass}
                            placeholder="Country Code"
                            value={context.t("_ar_country_code")}
                            style={dynamicStyles.textAlign(lang)}
                            id={`${name}_country_code`}
                            type="text"
                            minLength="3"
                            maxLength="3"
                            disabled
                        // className={"validate " + registration_form.mobile.class}
                        // required
                        />
                        <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{context.t("_country_code")}</label>
                        <span className="helper-text" data-error="Enter a valid code."></span>
                    </div>
                </div>
                <div className="col s8">
                    <div className="input-field item">
                        <input
                            name={name}
                            className={styleClass}
                            placeholder={placeholder}
                            aria-label={context.t("_phone_aria_label")}
                            id={name}
                            maxLength={max}
                            type="text"
                            style={dynamicStyles.textAlign(lang)}
                            // readOnly={type === "date" ? "readonly" : false}
                            autoComplete="no"
                            onChange={evt =>
                                handleChange(name, evt.target.value)
                            }
                            onFocus={evt =>
                                handleChange(name, evt.target.value)
                            }
                            value={formData[name].value}
                        // className={"validate " + formData[name].class}
                        // required={isMandatory}
                        />
                        <label htmlFor={name} style={dynamicStyles.position(lang)}>
                            {`${label} `}
                            {isMandatory ? <span className="asterisk">*</span> : null}
                        </label>
                        <span
                            style={dynamicStyles.textAlign(lang)}
                            className="helper-text"
                            data-error={formData[name].error ? formData[name].error : "Required field."}
                        ></span>
                    </div>
                </div>
            </div>
            : type == "date" ? <div className="input-field item">
                <DatePicker
                    name={name}
                    placeholderText={placeholder}
                    id={name}
                    locale={calenderLocal(context)}
                    autoComplete="off"
                    className={styleClass}
                    maxDate={new Date("12/31/2014")}
                    minDate={new Date("01/01/2001")}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomDateInput maxDate={new Date("12/31/2014")} minDate={new Date("01/01/2001")} onFieldChange={date => handleChange(name, new Date(date))} ariaLabel={context.t("_select_dob_button")} />}
                    onChange={date =>
                        handleChange(name, date)
                    }
                    onCalendarOpen={e => {
                        let elems = document.querySelector(".react-datepicker");
                        elems.setAttribute("tabindex", "0")
                        elems.focus()
                        elems = document.querySelector(".react-datepicker__month-select");
                        elems.setAttribute("aria-label", context.t("_select_month"))
                        elems = document.querySelector(".react-datepicker__year-select");
                        elems.setAttribute("aria-label", context.t("_select_year"))

                    }}
                    onCalendarClose={e => {
                        var elems = document.querySelector(".hiddenDob");
                        elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(formData[name].value, "dd-mm-yyyy")}`)
                        elems.setAttribute("value", dateformat(formData[name].value, "yyyy-mm-dd"))

                        setTimeout(() => {
                            elems.focus()
                        }, 200);
                    }}
                    style={dynamicStyles.textAlign(lang)}
                    value={formData[name].value}
                    selected={formData[name].value}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <span
                    style={dynamicStyles.textAlign(lang)}
                    className="helper-text"
                    data-error={formData[name].error ? formData[name].error : "Required field."}
                ></span>
            </div> :
                <div className="input-field item">
                    <input
                        name={name}
                        placeholder={placeholder}
                        id={name}
                        className={styleClass}
                        data-date="" data-date-format="DD MMMM YYYY"
                        maxLength={max}
                        type={type === "date" ? "text" : type}
                        style={dynamicStyles.textAlign(lang)}
                        // readOnly={type === "date" ? "readonly" : false}
                        autoComplete="no"
                        onChange={evt =>
                            handleChange(name, evt.target.value)
                        }
                        onFocus={evt =>
                            handleChange(name, evt.target.value)
                        }
                        value={formData[name].value}
                    // className={"validate " + formData[name].class}
                    // required={isMandatory}
                    />
                    <label htmlFor={name} style={dynamicStyles.position(lang)}>
                        {`${label} `}
                        {isMandatory ? <span className="asterisk">*</span> : null}
                    </label>
                    <span
                        style={dynamicStyles.textAlign(lang)}
                        className="helper-text"
                        data-error={formData[name].error ? formData[name].error : "Required field."}
                    ></span>
                </div>)
    }

    const renderSelect = ({ name, label, placeholder, isMandatory = false, data = [], type = "select" }) => {
        return (
            <div className="input-field mobileSelect">
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <select
                    className="browser-default"
                    name={name}
                    id={name}
                    style={dynamicStyles.textAlign(lang)}
                    onChange={evt =>
                        handleChange(name, evt.target.value)
                    }

                    onFocus={evt =>
                        handleChange(name, evt.target.value)
                    }
                    value={formData[name].value}
                // className={"validate" + formData[name].class}
                >
                    <option value="" disabled> {placeholder} </option>
                    {
                        data.map((item, index) => {
                            return type == "country" ?
                                <option key={index} value={item.country_id}>{item.country[lang]}</option>
                                :
                                type == "school" ? <option key={index} value={item.id}>{item.sname}</option> :
                                    <option key={index} value={item.key}>{item.label}</option>
                        })
                    }
                    {
                        type == "school" && <option value="0">{context.t("_not_in_list")}</option>
                    }
                </select>

                <span
                    style={dynamicStyles.textAlign(lang)}
                    className="helper-text"
                    data-error={formData[name].error ? formData[name].error : "Required field."}
                ></span>
            </div>
        )
    }
    const renderRadioButton = ({ data = [], name, label, defaultChecked, isMandatory = false }) => {
        return (
            <div className="input-field item">
                <div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={name}>
                    {
                        data.map((item, index) => {
                            return (
                                <div key={index} className={item.value}>
                                    <input
                                        aria-checked={formData[name].value === item.value}
                                        name={name}
                                        id={item.value}
                                        aria-labelledby={`${name} ${item.label}`}
                                        type="radio"
                                        readOnly
                                        className="radio-btn"
                                        onClick={evt =>
                                            handleChange(name, item.value)
                                        }
                                        checked={formData[name].value === item.value}
                                    />
                                    <label id={`_${item.label}`} htmlFor={item.value} className="custom-radio-style">
                                        <span className="custom-radio-style">{item.label}</span>
                                    </label>
                                </div>
                            )
                        })
                    }

                </div>
                <label htmlFor={name} id={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <span
                    className="helper-text"
                    data-error="Required field."
                ></span>
            </div>
        )
    }
    const renderCheckbox = ({ label, name }) => {
        return (
            <div className="item subscribe-items">
                <p>
                    <input type="checkbox"
                        name={name}
                        id={name}
                        tabIndex="0"
                        style={dynamicStyles.textAlign(lang)}
                        onChange={(event) => handleChange(name, event.target.checked)}
                        checked={formData[name].value}
                    />
                    <label for={name}>
                        <span className={lang}>{context.t("_i_agree")}
                            <button type="button" style={{ border: 0, background: "none" }} aria-label={context.t("_read_terms_condition")} tabindex="0" className="modal-trigger underline-text" onClick={() => handleOpenModal()}>{label}</button>
                        </span>
                    </label>
                </p>
            </div>

        )
    }
    const scrollStep = () => {
        if (window.pageYOffset <= 400) {
            clearInterval(intervalId);
        }
        window.scroll(0, window.pageYOffset - scrollStepInPx);
    }
    const scrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" })
        // var delayInMs = props.delayInMs ? props.delayInMs : state.delayInMs;
        // let intervalId = setInterval(scrollStep.bind(this), delayInMs);
        // setState({ intervalId: intervalId });
    }
    const validate = () => {
        const updatedFormData = { ...formData };
        for (var key in updatedFormData) {
            updatedFormData[key].value = typeof (updatedFormData[key].value) == "boolean" || key == "dob" ? updatedFormData[key].value : updatedFormData[key].value.trim();
            updatedFormData[key].error = "";
        }
        if (updatedFormData.name.value == "")
            updatedFormData.name.error = context.t("_student_name_required");

        if (updatedFormData.name.value != "" && !nameValidate.test(updatedFormData.name.value))
            updatedFormData.name.error = context.t("_invalid_student_name");

        if (updatedFormData.name.value != "" && nameValidate.test(updatedFormData.name.value) && !nameWordLengthCheck(updatedFormData.name.value))
            updatedFormData.name.error = context.t("_student_name_contains_atleast_two_word");

        if (updatedFormData.nameArabic.value != "" && nameValidate.test(updatedFormData.nameArabic.value) && !nameWordLengthCheck(updatedFormData.nameArabic.value))
            updatedFormData.nameArabic.error = context.t("_student_name_contains_atleast_two_word");

        if (updatedFormData.qid.value == "")
            updatedFormData.qid.error = context.t("_qid_required");

        if (updatedFormData.qid.value != "" && !qidValidate.test(updatedFormData.qid.value))
            updatedFormData.qid.error = context.t("_invalid_qid");

        if (updatedFormData.gender.value == "")
            updatedFormData.gender.error = context.t("_gender_required");

        if (updatedFormData.dob.value == "")
            updatedFormData.dob.error = context.t("_date_of_birth_required");

        if (updatedFormData.dob.value == "")
            updatedFormData.dob.error = context.t("_date_of_birth_required");

        if (updatedFormData.nationality.value == "")
            updatedFormData.nationality.error = context.t("_nationality_required");

        if (updatedFormData.schoolName.value == "")
            updatedFormData.schoolName.error = context.t("_school_name_required");

        if (updatedFormData.schoolName.value == "0" && updatedFormData.schoolNewName.value == "")
            updatedFormData.schoolNewName.error = context.t("_school_name_required");

        if (updatedFormData.grade.value == "")
            updatedFormData.grade.error = context.t("_grade_required");

        if (updatedFormData.email.value == "")
            updatedFormData.email.error = context.t("_email_required");

        if (updatedFormData.email.value != "" && !emailValidate.test(updatedFormData.email.value))
            updatedFormData.email.error = context.t("_invalid_email");

        if (updatedFormData.mobile.value == "")
            updatedFormData.mobile.error = context.t("_mobile_number_required");

        if (updatedFormData.mobile.value != "" && !phoneValidate.test(updatedFormData.mobile.value.replace(/ /g, "")))
            updatedFormData.mobile.error = context.t("_invalid_mobile_number");

        if (updatedFormData.mobile.value != "")
            if (updatedFormData.mobile.value.toString().startsWith("3") || updatedFormData.mobile.value.toString().startsWith("5") || updatedFormData.mobile.value.toString().startsWith("6") || updatedFormData.mobile.value.toString().startsWith("7")) {

            } else {
                updatedFormData.mobile.error = context.t("_invalid_mobile_number");
            }

        if (updatedFormData.parentQid.value == "")
            updatedFormData.parentQid.error = context.t("_parent_qid_required");

        if (updatedFormData.parentQid.value != "" && !qidValidate.test(updatedFormData.parentQid.value))
            updatedFormData.parentQid.error = context.t("_invalid_parent_qid");

        // if (lang == "en") {
        if (updatedFormData.parentName.value == "")
            updatedFormData.parentName.error = context.t("_parent_name_required");

        if (updatedFormData.parentName.value != "" && !nameValidate.test(updatedFormData.parentName.value))
            updatedFormData.parentName.error = context.t("_invalid_parent_name");

        if (updatedFormData.parentName.value != "" && nameValidate.test(updatedFormData.parentName.value) && !nameWordLengthCheck(updatedFormData.parentName.value))
            updatedFormData.parentName.error = context.t("_parent_name_contains_atleast_two_word");
        // } else {
        //     if (updatedFormData.parentNameArabic.value == "")
        //         updatedFormData.parentNameArabic.error = context.t("_parent_name_required");

        //     if (updatedFormData.parentNameArabic.value != "" && nameValidate.test(updatedFormData.parentNameArabic.value) && !nameWordLengthCheck(updatedFormData.parentNameArabic.value))
        //         updatedFormData.parentNameArabic.error = context.t("_parent_name_contains_atleast_two_word");
        // }

        if (updatedFormData.relation.value == "")
            updatedFormData.relation.error = context.t("_relationship_required");

        if (updatedFormData.parentMobile.value == "")
            updatedFormData.parentMobile.error = context.t("_parent_mobile_number_1_required");

        if (updatedFormData.parentMobile.value != "" && !phoneValidate.test(updatedFormData.parentMobile.value.replace(/ /g, "")))
            updatedFormData.parentMobile.error = context.t("_invalid_parent_mobile");

        if (updatedFormData.parentMobile.value != "")
            if (updatedFormData.parentMobile.value.toString().startsWith("3") || updatedFormData.parentMobile.value.toString().startsWith("5") || updatedFormData.parentMobile.value.toString().startsWith("6") || updatedFormData.parentMobile.value.toString().startsWith("7")) {

            } else {
                updatedFormData.parentMobile.error = context.t("_invalid_parent_mobile");
            }

        if (updatedFormData.parentMobile2.value == "")
            updatedFormData.parentMobile2.error = context.t("_parent_mobile_number_2_required");

        if (updatedFormData.parentMobile2.value != "" && !phoneValidate.test(updatedFormData.parentMobile2.value.replace(/ /g, "")))
            updatedFormData.parentMobile2.error = context.t("_invalid_parent_mobile_2");

        if (updatedFormData.parentMobile2.value != "")
            if (updatedFormData.parentMobile2.value.toString().startsWith("3") || updatedFormData.parentMobile2.value.toString().startsWith("5") || updatedFormData.parentMobile2.value.toString().startsWith("6") || updatedFormData.parentMobile2.value.toString().startsWith("7")) {

            } else {
                updatedFormData.parentMobile2.error = context.t("_invalid_parent_mobile_2");
            }

        if (updatedFormData.parentEmail.value == "")
            updatedFormData.parentEmail.error = context.t("_parent_email_required");

        if (updatedFormData.parentEmail.value != "" && !emailValidate.test(updatedFormData.parentEmail.value))
            updatedFormData.parentEmail.error = context.t("_invalid_parent_email");

        if ((updatedFormData.parentEmail.value != "" && updatedFormData.email.value != "") && updatedFormData.parentEmail.value == updatedFormData.email.value)
            updatedFormData.parentEmail.error = context.t("_parent_student_email_cannot_be_same");

        if ((updatedFormData.parentQid.value != "" && updatedFormData.parentQid.value != "") && updatedFormData.parentQid.value == updatedFormData.qid.value)
            updatedFormData.parentQid.error = context.t("_parent_student_qid_cannot_be_same");

        if (!(updatedFormData.agree.value))
            updatedFormData.agree.error = context.t("_terms_and_condition_agree");

        // if (updatedFormData.channel.value == "")
        //     updatedFormData.channel.error = context.t("_channel_of_communication_required");
        for (var key in updatedFormData) {
            if (updatedFormData[key].error != "") {
                setFormData(updatedFormData)
                setSubmissionStatus(false)
                scrollToTop();
                setTimeout(() => {
                    document.querySelector(`a[href="#${key}"]`).focus()
                }, 1000);
                return false;
            }
        }
        setFormData(updatedFormData)
        return true;
    }
    const displayError = error => {
        const updatedFormData = { ...formData };
        if (error.mail)

            updatedFormData.email.error = error.mail[lang]

        if (error.qid)
            updatedFormData.qid.error = error.qid[lang]

        if (error.nationality)
            updatedFormData.nationality.error = error.nationality[lang]

        if (error.grade)
            updatedFormData.grade.error = error.grade[lang]

        if (error.Student_name)
            updatedFormData.name.error = error.Student_name[lang]

        if (error.DOB)
            updatedFormData.dob.error = error.DOB[lang]

        for (var key in updatedFormData) {
            if (updatedFormData[key].error != "") {
                setFormData(updatedFormData)
                scrollToTop();
                setTimeout(() => {
                    document.querySelector(`a[href="#${key}"]`).focus()
                }, 1000);
                return false;

            }
        }
        setFormData(updatedFormData)
        scrollToTop();
    }
    const onSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            dispatch(LoadingAction.showLoader())

            let obj = {
                "name": formData.email.value,
                "mail": formData.email.value,
                "field_student_email": formData.email.value,
                "field_qid": formData.qid.value,
                "field_student_mobile": `+974${formData.mobile.value.replace(/ /g, "")}`,
                "roles": {
                    "target_id": "student"
                },
                "field_nationality": formData.nationality.value,
                "field_student_name": formData.name.value,
                "field_student_name_arabic": formData.nameArabic.value,
                "field_grade": formData.grade.value,
                "field_gender": formData.gender.value,
                "field_student_dob": dateformat(formData.dob.value, "dd/mm/yyyy"),
                "field_parent_mobile_number": `+974${formData.parentMobile.value.replace(/ /g, "")}`,
                "field_parent_mobile_number_2": `+974${formData.parentMobile2.value.replace(/ /g, "")}`,
                "field_parent_email": formData.parentEmail.value,
                "field_parent_qid": formData.parentQid.value,
                "field_parent_name": formData.parentName.value,
                // "field_parent_name_arabic": formData.parentNameArabic.value,
                "field_relation_to_the_student": formData.relation.value,
                // "field_channels_of_communication": formData.channel.value,
                "field_reference": {
                    "target_id": ""
                }
            }

            if (!_.isEmpty(formData.schoolName.value)) {
                let schoolName = schoolList.filter(item => item.id == formData.schoolName.value)
                if (formData.schoolName.value == "0")
                    schoolName = formData.schoolNewName.value
                else
                    schoolName = _.isEmpty(schoolName) ? null : schoolName[0].sname


                obj.school_id = {
                    "target_id": formData.schoolName.value,
                    "target_type": "taxonomy_term"
                };
                obj.school_name = schoolName
            }
            const url = `${configure.api_url}${urlEndPoints.registerStudent}`
            // let sendNotificationUrl = `${configure.api_url}${urlEndPoints.sendNotification}`
            // sendNotificationUrl = `${sendNotificationUrl}&email=${formData.parentEmail.value}`
            try {
                const data = await networkRequest(url, "POST", obj, null, true)
                if (data.data.error) {
                    displayError(data.data.error)

                }
                else {
                    setSubmissionStatus(true)
                    initState();
                    M.AutoInit();
                    // scrollToTop();
                    successMessageRef.current.focus();                    
                    // await this.networkRequest(sendNotificationUrl)
                }
                dispatch(LoadingAction.hideLoader())
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
        }
    }
    const handleCloseModal = () => {
        let elems = document.querySelector("#termsAndConditionModal");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
    }

    const handleOpenModal = () => {
        let elems = document.querySelector("#termsAndConditionModal");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        let instance = M.Modal.getInstance(elems);
        instance.open();
    }
    let hasError = false;
    Object.keys(formData).map((item, index) => {
        if (formData[item].error != "")
            hasError = true
    })

    const studentHeadingRef = useRef(null);
    const successMessageRef= useRef(null);

    return (
        <div id="main-container">
            <SlideToTop />
            <PageTitle title={context.t("_registration")} />
            <div className="container">
                <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                    <ul>
                        <li className="breadcrumb-item">
                            <Link to="/registration">{context.t("_registration")}</Link> {" "}

                        </li>
                        <li className="breadcrumb-item">
                            <Link tabIndex="0" to aria-current={context.t("_page")}>
                                <span>
                                    {context.t("_student")}
                                </span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <ColouredItems />
            <div id="skipContent" className="container registration-form student-registration-container">
                <RegisterTypes {...props} />
                <h2 tabIndex="0" ref={studentHeadingRef} aria-label={context.t("_for_student_form")} className="primary-heading" style={dynamicStyles.textAlign(lang)}>{context.t("_for_student")}</h2>
                <div className="row">
                    <div className="col s6 form-container">
                        <p style={dynamicStyles.textAlign(lang)}>
                            {context.t("_required_field")}{" "}
                            <span className="asterisk">*</span>
                        </p>
                        {
                            (hasError && !submissionStatus) ? <div className="error-container fail">
                                <h5>{context.t("_error_title")}</h5>
                                <ul aria-label="error" >
                                    {
                                        Object.keys(formData).map((item, index) => {
                                            return formData[item].error != "" ? <li key={index} ><a  tabIndex="0" href={`#${item}`} className="error" htmlFor={item}>{formData[item].error}</a></li>
                                                :
                                                null
                                        })}
                                </ul>
                            </div> : null

                        }
                        {
                            submissionStatus ? <div className="error-container success" tabIndex="0" ref={successMessageRef} >
                                <h5> {context.t("_success_title_student_1")}</h5>
                                <p>{context.t("_success_title_student_2")}</p>
                                <p>{context.t("_success_title_student_3")}</p>
                            </div> : null
                        }

                        {
                            _.isEmpty(formData) ?
                                <div /> :
                                <form onSubmit={onSubmit} autoComplete="no">
                                    {renderInput({ name: "name", label: context.t("_student_name_english"), placeholder: context.t("_student_name_english_placeholder"), isMandatory: lang == "en" })}
                                    {renderInput({ name: "nameArabic", label: context.t("_student_name_arabic"), placeholder: context.t("_student_name_arabic_placeholder"), isMandatory: lang == "ar" })}


                                    <div className=" date-popup">
                                        {renderInput({ name: "dob", label: context.t("_dob"), placeholder: context.t("_dob_placeholder"), isMandatory: true, type: "date", styleClass: "fontEnglish" })}
                                    </div>
                                    <div className="result-type1">
                                        {
                                            renderSelect({
                                                name: "nationality", label: context.t("_nationality"), placeholder: context.t("_select_your_nationality"), isMandatory: true, data: _.orderBy(CountryList, [`country.${lang}`], ["asc"]), type: "country"
                                            })
                                        }
                                    </div>
                                    <div className="result-type1">
                                        {
                                            renderSelect({
                                                name: "schoolName", label: context.t("_school_name"), placeholder: context.t("_select_school_name"), data: schoolList, type: "school", isMandatory: true
                                            })
                                        }
                                    </div>
                                    {
                                        formData.schoolName.value == "0" && renderInput({ name: "schoolNewName", label: context.t("_school_name"), placeholder: context.t("_select_school_name"), isMandatory: true })
                                    }
                                    {renderInput({ name: "qid", label: context.t("_qid"), placeholder: context.t("_qid_placeholder"), isMandatory: true, max: 11, })}
                                    {
                                        renderRadioButton({ label: context.t("_gender"), isMandatory: true, name: "gender", defaultChecked: "male", data: [{ label: context.t("_male"), value: "Male" }, { label: context.t("_female"), value: "Female" }] })
                                    }
                                    <div className="result-type1">
                                        {
                                            renderSelect({
                                                name: "grade", label: context.t("_grade"), placeholder: context.t("_select_your_grade"), isMandatory: true, data: gradeListNew[lang]
                                            })
                                        }
                                    </div>
                                    {renderInput({ name: "email", label: context.t("_email"), placeholder: context.t("_email_placeholder"), type: "email", isMandatory: true, styleClass: "fontEnglish" })}
                                    {renderInput({ name: "mobile", label: context.t("_mobile_number"), placeholder: context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max: 8 })}

                                    <p><br />{context.t("_guardian_information")} </p>
                                    {renderInput({ name: "parentQid", label: context.t("_patent_qid"), placeholder: context.t("_patent_qid_placeholder"), isMandatory: true, max: 11 })}


                                    {renderInput({ name: "parentName", label: context.t("_parent_name"), placeholder: context.t("_parent_name_placeholder"), isMandatory: true })}
                                    <div className="result-type1">
                                        {
                                            renderSelect({
                                                name: "relation", label: context.t("_relation"), placeholder: context.t("_select_relation"), isMandatory: true, data: [
                                                    { label: context.t("_father"), value: "Father" },
                                                    { label: context.t("_mother"), value: "Mother" },
                                                    { label: context.t("_sister"), value: "Sister" },
                                                    { label: context.t("_brother"), value: "Brother" },
                                                    { label: context.t("_grand_mother"), value: "Grandmother" },
                                                    { label: context.t("_grand_father"), value: "Grandfather" },
                                                    { label: context.t("_parent"), value: "Guardian" }
                                                ]
                                            })
                                        }
                                    </div>
                                    {renderInput({ name: "parentMobile", label: context.t("_parent_mobile"), placeholder: context.t("_parent_mobile_placeholder"), type: "phone_number", isMandatory: true, max: 8 })}
                                    {renderInput({ name: "parentMobile2", label: context.t("_parent_mobile_2"), placeholder: context.t("_parent_mobile_placeholder_2"), type: "phone_number", isMandatory: true, max: 8 })}
                                    {renderInput({ name: "parentEmail", label: context.t("_parent_email"), placeholder: context.t("_parent_email_placeholder"), isMandatory: true, type: "email", styleClass: "fontEnglish" })}
                                    {/* {
                                            renderRadioButton({ label: context.t("_channel_of_communication"), name: "channel", defaultChecked: "sms", data: [{ label: context.t("_email"), value: "Email Address" }, { label: context.t("_sms"), value: "Mobile Number" }, { label: context.t("_phone_call"), value: "Phone Call" }] })
                                        } */}
                                    {renderCheckbox({ label: context.t("_terms_and_condition"), name: "agree" })}
                                    <div className="btn-wrap">
                                        <button className="btn blue">{context.t("_registration")}</button>
                                    </div>
                                </form>
                        }

                    </div>
                    <div className="col s6" />
                </div>

            </div>
            <div id="termsAndConditionModal" className="modal" role="dialog">
                <div className="modal-content">
                    <span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => handleCloseModal()}><i className="material-icons">close</i></button></span>
                    <h4>{termsAndCondition.title}</h4>
                    <div className="description" dangerouslySetInnerHTML={{ __html: termsAndCondition.body }}></div>

                    <button className={`modal-close waves-effect waves-green btn blue ${lang == "ar" ? "pull-left" : "pull-right"}`}>{context.t("_agree")}</button>
                </div>
            </div>
            <div className="top-line ng-scope">
                <img src={topLineImage} alt="" />
            </div>
        </div>
    )
}

StudentRegistration.contextTypes = {
    t: PropTypes.func
}
export default StudentRegistration;

const ColouredItems = () => {
    return (
        <div className="student-color-container">
            <span className="grey-square-rotate red-sq one"></span>
            <span className="grey-square-rotate red-sq two"></span>
            <span className="grey-square-rotate red-sq three"></span>
            <span className="grey-square-rotate red-sq four"></span>
            <span className="orange-circle one"></span>
            <span className="orange-circle two"></span>
            <span className="orange-circle three"></span>
            <span className="orange-circle four"></span>
            <span className="multi-square one"><b><i></i></b></span>
            <span className="multi-square two"><b><i></i></b></span>
            <span className="multi-square three"><b><i></i></b></span>
            <span className="multi-square four"><b><i></i></b></span>
        </div>
    )
}