import React, { useState, useEffect } from "react"
import Main from "../../constants/utilities"
import { Link, useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import { withRouter } from "../../constants/common"
import _ from "lodash"
import { PopupAction, LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
// import { dynamicStyles } from "../../constants/dynamicStyles"
// import newActive1 from "../../assets/images/newActive.jpg"
import { useDispatch, useSelector } from "react-redux"



const ActivityHome = (props, context) => {
	const { config, networkRequest } = Main();
	const [activityList, setActivityList] = useState([])
	const [userActivityList, setUserActivityList] = useState([])
	const [studentList, setStudentList] = useState([])
	const [isLoaded, setIsLoaded] = useState(false)
	const [configuration, setConfiguration] = useState(config())

	const dispatch = useDispatch()
	const navigate = useNavigate();

	const { lang } = useSelector((state) => state.i18nState)

	useEffect(() => {
		(async () => {
			if (localStorage.getItem("role") == "parent")
				await getChildren();
			if (localStorage.getItem("role") == "student")
				await checkStudentEnrolled();
			if (localStorage.getItem("role") == "volenteer")
				await checkVolunteerEnrolled();
		})();

		getActivity()
	}, [])

	const getChildren = async () => {
		let url = `${configuration.api_url}${urlEndPoints.myChildren}`
		try {
			const data = await networkRequest(url)
			if (!data.data.message) {
				setStudentList(data.data.results)
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader());
			console.warn("error: ", error)
		}
		// }
	}
	const checkStudentEnrolled = async () => {
		let url = `${configuration.api_url}${urlEndPoints.getUserActivityById}`
		try {
			const data = await networkRequest(url)
			setUserActivityList(data.data)
		} catch (error) {
			dispatch(LoadingAction.hideLoader());
			console.warn("error: ", error)
		}
	}
	const checkVolunteerEnrolled = async () => {
		let url = `${configuration.api_url}${urlEndPoints.get_volunteer_activities}`
		try {
			const data = await networkRequest(url)
			setUserActivityList(data.data)
		} catch (error) {
			dispatch(LoadingAction.hideLoader());
			console.warn("error: ", error)
		}
	}
	const getActivity = async () => {
		let url = "";
		if (lang == "ar")
			url = `${configuration.origin}${lang}/api/${urlEndPoints.homeActivities}`
		else
			url = `${configuration.origin}api/${urlEndPoints.homeActivities}`
		try {
			const data = await networkRequest(url)
			if (data.status == 200) {
				const activityList = data.data.results;
				activityList.forEach(item => {
					item.field_start_and_end_time_2 = item.field_start_and_end_time_2.split("*")
					item.field_start_and_end_time_2 = item.field_start_and_end_time_2.map(date => new Date(date))
					item.field_start_and_end_time_2 = new Date(Math.min.apply(null, item.field_start_and_end_time_2));
					item.field_start_and_end_time_3 = item.field_start_and_end_time_3.split("*")
					item.field_start_and_end_time_4 = item.field_start_and_end_time_4.split("*")
					item.field_start_and_end_time = item.field_start_and_end_time.split("*")
					item.field_start_and_end_time_1 = item.field_start_and_end_time_1.split("*")
				});
				// this.setState({
				// 	// activityList,
				// 	pager: data.data.pager,
				// 	isLoaded: true
				// })
				setIsLoaded(true)
				setEnrolCount(activityList)
	}
		} catch (error) {
			dispatch(LoadingAction.hideLoader());
			console.warn("error: ", error)
		}
	}
	const setEnrolCount = async (activities) => {
		for (const activity of activities) {
			let url = `${configuration.api_url}${urlEndPoints.getEnrolmentCount(activity.nid)}`
			try {
				const data = await networkRequest(url)
				activity.enrolmentCount = !_.isEmpty(data.data) ? data.data.length : 0
			} catch (error) {
				dispatch(LoadingAction.hideLoader());
				console.warn("error: ", error)
			}
		}
		setActivityList(activities)
		dispatch(LoadingAction.hideLoader());
	}
	const getGender = gender => {
		gender = gender?.toLowerCase()
		switch (gender) {
			case "male":
				return context.t("_boys")
			case "female":
				return context.t("_girls")
			case "mixed":
				return context.t("_mixed")

		}
	}
	const getDateInFormat = date => {
		if (date.includes("Jan"))
			date = date.replace("Jan", context.t("_jan"))

		if (date.includes("Feb"))
			date = date.replace("Feb", context.t("_feb"))

		if (date.includes("Mar"))
			date = date.replace("Mar", context.t("_mar"))

		if (date.includes("Apr"))
			date = date.replace("Apr", context.t("_apr"))

		if (date.includes("May"))
			date = date.replace("May", context.t("_may"))

		if (date.includes("Jun"))
			date = date.replace("Jun", context.t("_jun"))

		if (date.includes("Jul"))
			date = date.replace("Jul", context.t("_jul"))

		if (date.includes("Aug"))
			date = date.replace("Aug", context.t("_aug"))

		if (date.includes("Sep"))
			date = date.replace("Sep", context.t("_sep"))

		if (date.includes("Oct"))
			date = date.replace("Oct", context.t("_oct"))

		if (date.includes("Nov"))
			date = date.replace("Nov", context.t("_nov"))

		if (date.includes("Dec"))
			date = date.replace("Dec", context.t("_dec"))

		return date
	}
	const getTimeInFormat = time => {
		if (time.includes("am"))
			time = time.replace("am", context.t("_am"))

		if (time.includes("pm"))
			time = time.replace("pm", context.t("_pm"))

		return time;
	}
	const handleEnrolledClick = () => {
		dispatch(PopupAction.showPopup({
			type: "error",
			title: context.t("_warning"),
			message: context.t("_already_enrolled"),
			isOpen: true,
			buttonText: context.t("_ok")
		}))
	}
	const handleEnroll = async (id) => {
		// if (_.isEmpty(localStorage.getItem("role"))) {
		// 	return this.props.history.push("/registration")
		// }
		const role = await localStorage.getItem("role");
		switch (role) {
			case "student":
				dispatch(LoadingAction.showLoader());
				handleEnrollStudent(id)
				break;
			case "volenteer":
				dispatch(LoadingAction.showLoader());
				handleEnrollVolunteer(id)
				break;
			case "school":
			case "parent":
				dispatch(LoadingAction.showLoader());
				handleEnrollParent(id)
				break;

			default:
				dispatch(PopupAction.showPopup({
					type: "error",
					title: "",
					// message: context.t("_login_required"),
					message: <>
						<Link onClick={() => { dispatch(PopupAction.hidePopup({})) }} to="/login"><b>{context.t("_login_link")}</b></Link> {context.t("_is_required_for_enrolment")}
					</>,
					isOpen: true,
					buttonText: context.t("_ok")
				}))
				break;
		}
	}
	const handleRegisterVolunteerRegister = () => {
		dispatch(PopupAction.showPopup({
			type: "error",
			title: "",
			// message: context.t("_login_required"),
			message: <>
				<Link aria-label={context.t("_login_link")} onClick={() => { dispatch(PopupAction.hidePopup({})) }} to="/login"><b>{context.t("_login_link")}</b></Link> {context.t("_is_required_for_volunteer")}
			</>,
			isOpen: true,
			buttonText: context.t("_ok")
		}))
		// this.props.showPopup({
		// 	type: "error",
		// 	title: context.t("_warning"),
		// 	message: context.t("_volunteer_not_registered"),
		// 	isOpen: true,
		// 	buttonText: context.t("_ok")
		// })
		// this.props.history.push("/registration/volunteer")
	}
	const handleVolunteeredClick = () => {
		dispatch(PopupAction.showPopup({
			type: "error",
			title: context.t("_warning"),
			message: context.t("_volunteer_not_needed"),
			isOpen: true,
			buttonText: context.t("_ok")
		}))
	}
	const handleEnrollStudent = async (id) => {
		const url = `${configuration.api_url}${urlEndPoints.activityEnrolment}`
		const obj = {
			"activity_id": id
		}
		try {
			const data = await networkRequest(url, "POST", obj)
			dispatch(LoadingAction.hideLoader());
			if (data.data.error) {
				dispatch(PopupAction.showPopup({
					type: "error",
					title: context.t("_error"),
					message: `${data.data.error.name[lang]}`,
					isOpen: true,
					buttonText: context.t("_ok")
				}))
			} else {
				await checkStudentEnrolled();
				getActivity()
				dispatch(PopupAction.showPopup({
					type: "success",
					title: context.t("_success"),
					message: context.t("_enroll_success"),
					isOpen: true,
					buttonText: context.t("_ok")
				}))
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader());
			console.warn("error: ", error)
		}

	}
	const handleEnrollVolunteer = async (id) => {
		const url = `${configuration.api_url}${urlEndPoints.volunteerEnrolment}`
		const obj = {
			"activity_id": id
		}
		try {
			const data = await networkRequest(url, "POST", obj)
			dispatch(LoadingAction.hideLoader());
			if (data.data.error) {
				dispatch(PopupAction.showPopup({
					type: "error",
					title: context.t("_error"),
					message: `${data.data.error.name[lang]}`,
					isOpen: true,
					buttonText: context.t("_ok")
				}))
			} else {
				await checkVolunteerEnrolled();
				getActivity()
				dispatch(PopupAction.showPopup({
					type: "success",
					title: context.t("_success"),
					message: context.t("_request_send"),
					isOpen: true,
					buttonText: context.t("_ok")
				}))
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader());
			console.warn("error: ", error)
		}

	}
	const handleEnrollParent = (id) => {
		if (localStorage.getItem("role") == "parent" && studentList.length == 1) {
			this.handleInitialEnroll(id);
		} else {
			navigate(`/subscribe-activities/${id}`);
		}

	}
	const handleEnrolButton = item => {
		if (Number(item.field_number_of_attendees) > Number(item.enrolmentCount))
			return <button aria-label={`${item.title} Enroll`} onClick={() => { handleEnroll(item.nid) }} className="btn blueColor ">
				{context.t("_enroll")}
			</button>
		else if (Number(item.enrolmentCount) < (Number(item.field_number_of_attendees) + Number(item.field_waiting_list_count)))
			return <button aria-label={`${item.title} Waiting`} onClick={() => { handleEnroll(item.nid) }} className="btn blueColor ">
				{context.t("_waiting_list")}
			</button>
		else
			return <button aria-label={`${item.title} Closed`} onClick={() => { handleEnrollClosed() }} className="btn blueColor btn-disabled">
				{context.t("_closed")}
			</button>
	}
	const handleEnrollClosed = () => {
		dispatch(PopupAction.showPopup({
			type: "error",
			title: context.t("_warning"),
			message: context.t("_enrolment_closed"),
			isOpen: true,
			buttonText: context.t("_ok")
		}))
	}




	return (
		isLoaded ?
			<div className="activityHome">
				<div className="container">
					<div className={`zone-text en ${activityList.length > 0 ? "" : "activity-block"}`}>
						<h3 id="activity">{context.t("_activities")}</h3>
						<div>
							{context.t("_home_activity_caption")}
							{activityList.length > 0 && <Link className="btnNewYellow btn" aria-labelledby="activity" aria-label={context.t("view_more_activity_label")} to="/activities">{context.t("_view_more")} <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i></Link>}
						</div>
					</div>
					<div className="row noFlex">
						<ul aria-label="Activities">
							{
								activityList.length > 0 ? activityList.map(item => {
									return (
										<li className="col s12 m4 l4" key={item.nid}>
											<div className="newactivities">
												<div className={`textHolderNA ${item.field_activity_zone}`}>
													<div className={`topTextHolderNA`}>
														<Link to={`/activity/${item.nid}`} aria-label={`${item.title} ${getGender(item.field_gender)} ${item.field_age_group} ${context.t("_year_old")} ${context.t("_start_date")} ${getDateInFormat(item.field_start_and_end_time_3[0])} ${getTimeInFormat(item.field_start_and_end_time[0])} ${context.t("_end_date")} ${getDateInFormat(item.field_start_and_end_time_4[item.field_start_and_end_time_4.length - 1])} ${getTimeInFormat(item.field_start_and_end_time_1[item.field_start_and_end_time_1.length - 1])}`}>
															<h4><i className="topHeadNA"></i>{item.title}</h4>
														</Link>
														<div className="topTextInsideNA">
															<span className="blackText"><i className="topBoyIconNA"></i> {getGender(item.field_gender)}</span>
															<span>{`${item.field_age_group} ${context.t("_year_old")}`}</span>
															{/* <span className="schoolSessionNA">School session</span> */}
														</div>
													</div>
													<div className="bottomTextHolderNA">
														{/* <div className="bottomTextLeftNA" dangerouslySetInnerHTML={{ __html: item.body }} /> */}
														<div className="bottomTextRightNA">
															<span className="calendarNA">
																<i className="calendar-icons"></i> {context.t("_start_date")}  <span className="date">{getDateInFormat(item.field_start_and_end_time_3[0])} <br /><small>{getTimeInFormat(item.field_start_and_end_time[0])}</small></span>
															</span>
															<span className="calendarNA">
																<i className="calendar-icons"></i> {context.t("_end_date")}
																<span className="date">{getDateInFormat(item.field_start_and_end_time_4[item.field_start_and_end_time_4.length - 1])}<br /><small>{getTimeInFormat(item.field_start_and_end_time_1[item.field_start_and_end_time_1.length - 1])}</small></span>
															</span>
														</div>
													</div>
												</div>
												<div className="btnHolderNA">
													{
														(
															item.field_need_to_show_enroll_button == "1" &&
															!_.isEmpty(item.field_start_and_end_time_3) &&
															!_.isEmpty(item.field_start_and_end_time_4) &&
															(new Date(item.field_start_and_end_time_2) >= new Date())) ?
															(localStorage.getItem("role") != "volenteer" ||
																_.isEmpty(localStorage.getItem("role"))) &&
															(!_.isEmpty(_.find(userActivityList, ["field_activity_id", item.nid])) ?
																(_.find(userActivityList, ["field_activity_id", item.nid]).field_published_status == "Unpublished" ||
																	_.find(userActivityList, ["field_activity_id", item.nid]).field_published_status == "Published") ?
																	<button onClick={() => { handleEnrolledClick() }} className="btn blueColor btn-disabled" >
																		{_.find(userActivityList, ["field_activity_id", item.nid]).field_published_status == "Published" ? context.t("_enrolled") : context.t("_pending")}
																	</button> :
																	handleEnrolButton(item)
																// <button onClick={() => { handleEnroll(item.nid) }} className="btn blueColor ">{context.t("_enroll_now")}</button> 
																:
																handleEnrolButton(item)
																// <button onClick={() => { handleEnroll(item.nid) }} className="btn blueColor">{context.t("_enroll_now")}</button>
															)
															:
															null
													}
													{
														(_.isEmpty(localStorage.getItem("role")) && item.field_need_volenteer != "0") ?
															<button className="btn" aria-label={`${context.t("volunteer_now_for")} ${item.title}`} onClick={() => { handleRegisterVolunteerRegister() }}>{context.t("_volunteer_now")}</button> : null
													}
													{
														localStorage.getItem("role") == "volenteer" ?
															item.field_need_volenteer == "0" ?
																<button className="btn btn-disabled" onClick={() => { handleVolunteeredClick() }} >{context.t("_volunteer_now")}</button>
																:
																<button className="btn" onClick={() => { handleEnroll(item.nid) }} >{context.t("_volunteer_now")}</button>
															: null
													}
													{/* <button className="btn">Volunteer</button>
												<button className="btn blueColor">Enroll Now</button> */}
												</div>
											</div>
										</li>
									)
								}) :
									<div class="activities-inner">
										<p>{context.t("_home_no_activity")}</p>
										<a class="btnNewYellow btn" aria-labelledby="activity" aria-label="Button for view more activity" href="/activities">
											{context.t("_go_to_activities")}<i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i></a>
									</div>
							}
							{/* 
						<div className="col s12 m4 l4">
							<div className="newactivities Software-Zone">
								<div className="textHolderNA">
									<div className="topTextHolderNA redIcon">
										<h4><i className="topHeadNA"></i>Robotics competition</h4>
										<div className="topTextInsideNA">
											<span className="blackText"><i className="topBoyIconNA"></i> Boys</span>
											<span>12 - 18 Years Old</span>
											<span className="schoolSessionNA">School session</span>
										</div>
									</div>
									<div className="bottomTextHolderNA">
										<div className="bottomTextLeftNA">Cmpetitions are a fun way for both adults and kids to learn </div>
										<div className="bottomTextRightNA">
											<span className="calendarNA">
												<i className="calendar-icons"></i> Start date <span className="date">13 Apr,2020</span>
											</span>
											<span className="calendarNA">
												<i className="calendar-icons"></i> End date <span className="date">13 Apr,2020</span>
											</span>
										</div>
									</div>
								</div>

								<div className="btnHolderNA">
									<button className="btn">Volunteer</button>
									<button className="btn blueColor">Enroll Now</button>
								</div>
							</div>
						</div>

						<div className="col s12 m4 l4">
							<div className="newactivities Technology-Zone">

								<div className="textHolderNA">
									<div className="topTextHolderNA blueIcon">
										<h4><i className="topHeadNA"></i>Robotics competition</h4>
										<div className="topTextInsideNA">
											<span className="blackText"><i className="topBoyIconNA"></i> Boys</span>
											<span>12 - 18 Years Old</span>
											<span className="schoolSessionNA">School session</span>
										</div>
									</div>
									<div className="bottomTextHolderNA">
										<div className="bottomTextLeftNA">Cmpetitions are a fun way for both adults and kids to learn </div>
										<div className="bottomTextRightNA">
											<span className="calendarNA">
												<i className="calendar-icons"></i> Start date <span className="date">13 Apr,2020</span>
											</span>
											<span className="calendarNA">
												<i className="calendar-icons"></i> End date <span className="date">13 Apr,2020</span>
											</span>
										</div>
									</div>
								</div>

								<div className="btnHolderNA">
									<button className="btn">Volunteer</button>
									<button className="btn blueColor">Enroll Now</button>
								</div>

							</div>
						</div> */}
						</ul>
					</div>



				</div>

			</div>
			:
			<div />
	)
}
ActivityHome.contextTypes = {
	t: PropTypes.func
}

export default withRouter(ActivityHome)
