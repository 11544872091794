import React, { useEffect, useState } from "react"
import _ from "lodash"
import M from "materialize-css"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux"

import eye from "../../../assets/images/eye.svg"
import CountryList from "./../../../constants/country.json"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import { LoadingAction, StudentListAction } from "./../../../actions"
import { getGender, handleGradeName, calculateAge, handleCountryName } from "./utilFunctions"

const StudentList = (props, context) => {
    const dispatch = useDispatch()
    const { lang } = useSelector(state => state.i18nState);
    const [search, setSearch] = useState({ title: "", age: "", nationality: "", gender: "", studentLevel: "", studentPoint: "", });
    const { pager } = useSelector(state => state.studentListReducer.studentNewList)
    const { studentList } = useSelector(state => state.studentListReducer.studentNewList.results)
    const { studentPointLevel, studentPointRange } = useSelector(state => state.studentListReducer)

    useEffect(() => {
        M.AutoInit();
        setTimeout(() => {
            let elems = document.querySelectorAll("select");
            M.FormSelect.init(elems);
            elems = document.querySelectorAll(".view-more-img");
            // M.Tooltip.init(elems);
        }, 1000);
        // this.props.getStudentsList({});
        // this.props.getStudentsFullList({});
        window.addEventListener('load', handleTabIndex());
        dispatch(StudentListAction.getStudentLevel(lang))
        dispatch(StudentListAction.getStudentPointRange())
    }, []);

    const handleTabIndex = () => {
        setTimeout(() => {
            let elem = document.querySelectorAll('select');
            elem.forEach(element => {
                element.setAttribute("tabindex", "0");
            });
        }, 1000);
    }

    const handleStudentPageChange = (pageNumber) => {
        dispatch(LoadingAction.showLoader())
        var { startDate, endDate, type, zone, fieldType } = props;
        const updatedSearch = { ...search, pages: pageNumber - 1 };
        dispatch(StudentListAction.getStudentsList({ ...updatedSearch, startDate, endDate, type, zone, fieldType }))
    }

    const handleChange = (field, value) => {
        if (field == "age")
            if (isNaN(value) || value.length > 2)
                return false
        setSearch({ ...search, [field]: value });
    }

    const onSubmit = async (event) => {
        var { startDate, endDate } = props;
        const updatedSearch = { ...search, pages: 0 };
        event.preventDefault();
        dispatch(LoadingAction.showLoader())
        dispatch(StudentListAction.getStudentsList({ ...updatedSearch, startDate, endDate }))
        // this.props.getStudentsFullList({ ...search, startDate, endDate });
    }

    const handleClearSearch = () => {
        dispatch(LoadingAction.showLoader())
        setSearch({ title: "", age: "", nationality: "", gender: "" })
        dispatch(StudentListAction.getStudentsList({}))
    }
    return (
        <div id="student" className="col s12">
            <div className="dashboard__fliter filter-new">
                <form onSubmit={onSubmit} autoComplete="no">
                    <div className="row">
                        <div className="col">
                            <div className="form-wrapper">
                                <div className="row">
                                    <div className="input-group col">
                                        <label htmlFor="studentTitle">{context.t("_search")}</label>
                                        <div className="input-field inline search">
                                            <input
                                                id="studentTitle"
                                                name="title"
                                                type="text"
                                                className="validate"
                                                placeholder={context.t("_search")}
                                                value={search.title}
                                                onChange={evt => handleChange("title", evt.target.value)}
                                                onFocus={evt => handleChange("title", evt.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-group col">
                                        <label htmlFor="age">{context.t("_age")}</label>
                                        <div className="input-field inline age">
                                            <input
                                                id="age"
                                                name="age"
                                                type="text"
                                                className="validate"
                                                value={search.age}
                                                placeholder={context.t("_age")}
                                                onChange={evt => handleChange("age", evt.target.value)}
                                                onFocus={evt => handleChange("age", evt.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-group col">
                                        <label htmlFor="nationality">{context.t("_nationality")}</label>
                                        <div className="input-field inline nationality mobileSelect">
                                            <select
                                                id="nationality"
                                                name="nationality"
                                                className="browser-default"
                                                value={search.nationality}
                                                onChange={evt => handleChange("nationality", evt.target.value)}
                                                onFocus={evt => handleChange("nationality", evt.target.value)}
                                            >
                                                <option value="" disabled>{context.t("_select_nationality")}</option>
                                                {
                                                    _.orderBy(CountryList, [`country.${lang}`], ["asc"]).map(item => <option key={item.country_id} value={item.country_id}>{item.country[lang]}</option>)
                                                }
                                                {/* <option value="">Australia</option>
                                <option value="">Bolivia</option>
                                <option value="">Germany</option>
                                <option value="">Qatar</option> */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="input-group col">
                                        <label htmlFor="gender">{context.t("_gender")}</label>
                                        <div className="input-field inline mobileSelect">
                                            <select
                                                id="gender"
                                                name="gender"
                                                className="browser-default"
                                                value={search.gender}
                                                onChange={evt => handleChange("gender", evt.target.value)}
                                                onFocus={evt => handleChange("gender", evt.target.value)}
                                            >
                                                <option value="" disabled>{context.t("_select_gender")}</option>
                                                <option value="Male">{context.t("_male")}</option>
                                                <option value="Female">{context.t("_female")}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="input-group col">
                                        <label htmlFor="fieldType">{context.t("_student_level")}</label>
                                        <div className="input-field inline mobileSelect">
                                            <select
                                                id="fieldType"
                                                name="fieldType"
                                                className="browser-default"
                                                onChange={evt => {
                                                    handleChange("studentLevel", evt.target.value)

                                                }}
                                                value={search.studentLevel}
                                            >
                                                <option value="" disabled>{context.t("_select")}</option>
                                                {
                                                    studentPointLevel?.map(item => <option key={item.id} value={item.id}>{item.title}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="input-group col">
                                        <label htmlFor="fieldType">{context.t("_student_points")}</label>
                                        <div className="input-field inline mobileSelect">
                                            <select
                                                id="fieldType"
                                                name="fieldType"
                                                className="browser-default"
                                                onChange={evt => {
                                                    handleChange("studentPoint", evt.target.value)
                                                }}
                                                value={search.studentPoint}
                                            >
                                                <option value="" disabled>{context.t("_select")}</option>
                                                {
                                                    studentPointRange?.map(item => <option key={`${item.max}*${item.min}`} value={`${item.max}*${item.min}`}>{`${item.title} ${context.t("_points")}`}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="btn-wrapper">
                                <button className="btn bgBlue waves-effect waves-light">{context.t("_apply")}</button>
                                <button onClick={() => handleClearSearch()} type="button" className="btn blueBorder">{context.t("_reset")}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="tableHolder">
                <table className="responsive-table highlight">
                    <thead className="blue lighten-5">
                        <tr>
                            <th><strong>{context.t("_student_name_1")}</strong></th>
                            <th><strong>{context.t("_qid")}</strong></th>
                            <th><strong>{context.t("_phone_number")}</strong></th>
                            <th><strong>{context.t("_gender")}</strong></th>
                            <th><strong>{context.t("_grade")}</strong></th>
                            <th><strong>{context.t("_age")}</strong></th>
                            <th><strong>{context.t("_email")}</strong></th>
                            <th><strong>{context.t("_nationality")}</strong></th>
                            <th><strong>{context.t("_enrolled")} </strong></th>
                            <th><strong>{context.t("_attended")}</strong></th>
                            <th><strong>{context.t("_waiting")}</strong></th>
                            <th><strong>{context.t("_student_points")}</strong></th>
                            <th><strong>{context.t("_student_level")}</strong></th>
                            <th><strong>{context.t("_view")}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            studentList?.map(item => {
                                return (
                                    <tr key={item.uid}>
                                        <td>
                                            <img className="profile-img" src={item.picture} alt="" />
                                            <span>{item.name}</span>
                                        </td>
                                        <td>{item.qid}</td>
                                        <td>{item.phone}</td>
                                        <td>{getGender(item.gender, context)}</td>
                                        <td>{handleGradeName(item.grade, lang)}</td>
                                        <td>{calculateAge(item.dob)}</td>
                                        <td>{item.email}</td>
                                        <td>{handleCountryName(item.nationality, lang)}</td>
                                        <td className="text-center">{item.enrollment_count}</td>
                                        <td className="text-center">{item.attended_count}</td>
                                        <td className="text-center">{item.waitinglist_count}</td>
                                        <td className="text-center">{item.field_activity_point}</td>
                                        <td>{item.level}</td>
                                        <td>
                                            <Link aria-label={`${item.name} view`} to={`/student-details/${item.uid}`}>
                                                <img className="view-more-img" src={eye} alt="view more details" data-position="bottom" data-tooltip="View Profile" />
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <Pagination
                    prevPageText={`< ${context.t("_previous")}`}
                    nextPageText={`${context.t("_next")} >`}
                    hideFirstLastPages={true}
                    hideDisabled={true}
                    linkClassPrev="linkClassPrev"
                    linkClassNext="linkClassNext"
                    activePage={pager?.current_page + 1}
                    itemsCountPerPage={pager.items_per_page}
                    totalItemsCount={pager.count}
                    pageRangeDisplayed={5}
                    onChange={handleStudentPageChange}
                />
            </div>
            {
                /**
                Print 
                */
            }
        </div>
    )
}

StudentList.contextTypes = {
    t: PropTypes.func
}
export default StudentList;

// import React from "react"
// import Main from "../../main"
// import { connect } from "react-redux"
// import { Link } from "react-router-dom"
// import PropTypes from "prop-types"
// import M from "materialize-css"
// import _ from "lodash"
// import Pagination from "react-js-pagination";
// import { PopupAction, LoadingAction, StudentListAction } from "./../../../actions"
// import CountryList from "./../../../constants/country.json"
// import eye from "../../../assets/images/eye.svg"
// import { getGender, handleGradeName, calculateAge, handleCountryName } from "./utilFunctions"
// import { urlEndPoints } from "./../../../httpAPI/apiConfig"

// class StudentList extends Main {
//     constructor(props) {
//         super(props);
//         this.state = {
//             config: this.config(),
//             studentList: [],
//             pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
//             search: { title: "", age: "", nationality: "", gender: "", studentLevel: "", studentPoint: "", },
//         }
//     }
//     componentDidMount() {
//         M.AutoInit();
//         setTimeout(() => {
//             let elems = document.querySelectorAll("select");
//             M.FormSelect.init(elems);
//             elems = document.querySelectorAll(".view-more-img");
//             // M.Tooltip.init(elems);
//         }, 1000);
//         // this.props.getStudentsList({});
//         // this.props.getStudentsFullList({});

//         window.addEventListener('load', this.handleTabIndex());

//         this.props.getStudentLevel(this.props.lang)
//         this.props.getStudentPointRange()
//     }
//     handleTabIndex = () => {
//         setTimeout(() => {
//             let elem = document.querySelectorAll('select');
//             elem.forEach(element => {
//                 element.setAttribute("tabindex", "0");
//             });
//         }, 1000);
//     }
//     handleStudentPageChange = (pageNumber) => {
//         this.props.showLoader()
//         var { search } = this.state;
//         var { startDate, endDate, type, zone, fieldType } = this.props;
//         search.pages = pageNumber - 1;
//         this.props.getStudentsList({ ...search, startDate, endDate, type, zone, fieldType });
//         // this.props.getStudentsFullList({ ...search, startDate, endDate });
//     }
//     handleChange = (field, value) => {
//         if (field == "age")
//             if (isNaN(value) || value.length > 2)
//                 return false
//         const { search } = this.state;
//         search[field] = value;
//         this.setState({ search });
//     }
//     onSubmit = async (event) => {
//         const { search } = this.state;
//         var { startDate, endDate } = this.props;
//         search.pages = 0;
//         event.preventDefault();
//         this.props.showLoader();
//         this.props.getStudentsList({ ...search, startDate, endDate });
//         // this.props.getStudentsFullList({ ...search, startDate, endDate });
//     }
//     handleClearSearch = () => {
//         this.props.showLoader();
//         this.setState({ search: { title: "", age: "", nationality: "", gender: "" } }, () => {
//             this.props.getStudentsList({});
//             // this.props.getStudentsFullList({});
//         })
//     }

//     render() {
//         const { search } = this.state;
//         const { lang, studentList, pager, studentPointLevel, studentPointRange } = this.props;
//         console.log("dsasdasd", search);
//         return (

//             <div id="student" className="col s12">
//                 <div className="dashboard__fliter filter-new">
//                     <form onSubmit={this.onSubmit} autoComplete="no">
//                         <div className="row">
//                             <div className="col">
//                                 <div className="form-wrapper">
//                                     <div className="row">
//                                         <div className="input-group col">
//                                             <label htmlFor="studentTitle">{this.context.t("_search")}</label>
//                                             <div className="input-field inline search">
//                                                 <input
//                                                     id="studentTitle"
//                                                     name="title"
//                                                     type="text"
//                                                     className="validate"
//                                                     placeholder={this.context.t("_search")}
//                                                     value={search.title}
//                                                     onChange={evt => this.handleChange("title", evt.target.value)}
//                                                     onFocus={evt => this.handleChange("title", evt.target.value)}
//                                                 />
//                                             </div>
//                                         </div>
//                                         <div className="input-group col">
//                                             <label htmlFor="age">{this.context.t("_age")}</label>
//                                             <div className="input-field inline age">
//                                                 <input
//                                                     id="age"
//                                                     name="age"
//                                                     type="text"
//                                                     className="validate"
//                                                     value={search.age}
//                                                     placeholder={this.context.t("_age")}
//                                                     onChange={evt => this.handleChange("age", evt.target.value)}
//                                                     onFocus={evt => this.handleChange("age", evt.target.value)}
//                                                 />
//                                             </div>
//                                         </div>
//                                         <div className="input-group col">
//                                             <label htmlFor="nationality">{this.context.t("_nationality")}</label>
//                                             <div className="input-field inline nationality mobileSelect">
//                                                 <select
//                                                     id="nationality"
//                                                     name="nationality"
//                                                     className="browser-default"
//                                                     value={search.nationality}
//                                                     onChange={evt => this.handleChange("nationality", evt.target.value)}
//                                                     onFocus={evt => this.handleChange("nationality", evt.target.value)}
//                                                 >
//                                                     <option value="" disabled>{this.context.t("_select_nationality")}</option>
//                                                     {
//                                                         _.orderBy(CountryList, [`country.${lang}`], ["asc"]).map(item => <option key={item.country_id} value={item.country_id}>{item.country[lang]}</option>)
//                                                     }

//                                                     {/* <option value="">Australia</option>
//                                 <option value="">Bolivia</option>
//                                 <option value="">Germany</option>
//                                 <option value="">Qatar</option> */}
//                                                 </select>
//                                             </div>
//                                         </div>
//                                         <div className="input-group col">
//                                             <label htmlFor="gender">{this.context.t("_gender")}</label>
//                                             <div className="input-field inline mobileSelect">
//                                                 <select
//                                                     id="gender"
//                                                     name="gender"
//                                                     className="browser-default"
//                                                     value={search.gender}
//                                                     onChange={evt => this.handleChange("gender", evt.target.value)}
//                                                     onFocus={evt => this.handleChange("gender", evt.target.value)}
//                                                 >
//                                                     <option value="" disabled>{this.context.t("_select_gender")}</option>
//                                                     <option value="Male">{this.context.t("_male")}</option>
//                                                     <option value="Female">{this.context.t("_female")}</option>
//                                                 </select>
//                                             </div>
//                                         </div>
//                                         <div className="input-group col">
//                                             <label htmlFor="fieldType">{this.context.t("_student_level")}</label>
//                                             <div className="input-field inline mobileSelect">
//                                                 <select
//                                                     id="fieldType"
//                                                     name="fieldType"
//                                                     className="browser-default"
//                                                     onChange={evt => {
//                                                         this.handleChange("studentLevel", evt.target.value)

//                                                     }}
//                                                     value={search.studentLevel}
//                                                 >
//                                                     <option value="" disabled>{this.context.t("_select")}</option>
//                                                     {
//                                                         studentPointLevel.map(item => <option key={item.id} value={item.id}>{item.title}</option>)
//                                                     }
//                                                 </select>
//                                             </div>
//                                         </div>
//                                         <div className="input-group col">
//                                             <label htmlFor="fieldType">{this.context.t("_student_points")}</label>
//                                             <div className="input-field inline mobileSelect">
//                                                 <select
//                                                     id="fieldType"
//                                                     name="fieldType"
//                                                     className="browser-default"
//                                                     onChange={evt => {
//                                                         this.handleChange("studentPoint", evt.target.value)

//                                                     }}
//                                                     value={search.studentPoint}
//                                                 >
//                                                     <option value="" disabled>{this.context.t("_select")}</option>
//                                                     {
//                                                         studentPointRange.map(item => <option key={`${item.max}*${item.min}`} value={`${item.max}*${item.min}`}>{`${item.title} ${this.context.t("_points")}`}</option>)
//                                                     }

//                                                 </select>
//                                             </div>
//                                         </div>

//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col">
//                                 <div className="btn-wrapper">

//                                     <button className="btn bgBlue waves-effect waves-light">{this.context.t("_apply")}</button>
//                                     <button onClick={() => this.handleClearSearch()} type="button" className="btn blueBorder">{this.context.t("_reset")}</button>
//                                 </div>
//                             </div>
//                         </div>

//                     </form>
//                 </div>

//                 <div className="tableHolder">
//                     <table className="responsive-table highlight">
//                         <thead className="blue lighten-5">
//                             <tr>
//                                 <th><strong>{this.context.t("_student_name_1")}</strong></th>
//                                 <th><strong>{this.context.t("_qid")}</strong></th>
//                                 <th><strong>{this.context.t("_phone_number")}</strong></th>
//                                 <th><strong>{this.context.t("_gender")}</strong></th>
//                                 <th><strong>{this.context.t("_grade")}</strong></th>
//                                 <th><strong>{this.context.t("_age")}</strong></th>
//                                 <th><strong>{this.context.t("_email")}</strong></th>
//                                 <th><strong>{this.context.t("_nationality")}</strong></th>
//                                 <th><strong>{this.context.t("_enrolled")} </strong></th>
//                                 <th><strong>{this.context.t("_attended")}</strong></th>
//                                 <th><strong>{this.context.t("_waiting")}</strong></th>
//                                 <th><strong>{this.context.t("_student_points")}</strong></th>
//                                 <th><strong>{this.context.t("_student_level")}</strong></th>
//                                 <th><strong>{this.context.t("_view")}</strong></th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {
//                                 studentList.map(item => {
//                                     return (
//                                         <tr key={item.uid}>
//                                             <td>
//                                                 <img className="profile-img" src={item.picture} alt="" />
//                                                 <span>{item.name}</span>
//                                             </td>
//                                             <td>{item.qid}</td>
//                                             <td>{item.phone}</td>
//                                             <td>{getGender(item.gender, this.context)}</td>
//                                             <td>{handleGradeName(item.grade, this.props.lang)}</td>
//                                             <td>{calculateAge(item.dob)}</td>
//                                             <td>{item.email}</td>
//                                             <td>{handleCountryName(item.nationality, this.props.lang)}</td>
//                                             <td className="text-center">{item.enrollment_count}</td>
//                                             <td className="text-center">{item.attended_count}</td>
//                                             <td className="text-center">{item.waitinglist_count}</td>
//                                             <td className="text-center">{item.field_activity_point}</td>
//                                             <td>{item.level}</td>
//                                             <td>
//                                                 <Link aria-label={`${item.name} view`} to={`/student-details/${item.uid}`}>
//                                                     <img className="view-more-img" src={eye} alt="view more details" data-position="bottom" data-tooltip="View Profile" />
//                                                 </Link>
//                                             </td>
//                                         </tr>
//                                     )
//                                 })
//                             }
//                         </tbody>
//                     </table>
//                     <Pagination
//                         prevPageText={`< ${this.context.t("_previous")}`}
//                         nextPageText={`${this.context.t("_next")} >`}
//                         hideFirstLastPages={true}
//                         hideDisabled={true}
//                         linkClassPrev="linkClassPrev"
//                         linkClassNext="linkClassNext"
//                         activePage={pager.current_page + 1}
//                         itemsCountPerPage={pager.items_per_page}
//                         totalItemsCount={pager.count}
//                         pageRangeDisplayed={5}
//                         onChange={this.handleStudentPageChange}
//                     />
//                 </div>
//                 {
//                     /**
//                     Print 
//                     */
//                 }


//             </div>
//         )
//     }

// }

// const mapStateToProps = ({ i18nState, studentListReducer }) => {
//     return {
//         lang: i18nState.lang,
//         studentList: studentListReducer.studentList.results,
//         pager: studentListReducer.studentList.pager,
//         studentFullList: studentListReducer.studentFullList,
//         studentPointLevel: studentListReducer.studentPointLevel,
//         studentPointRange: studentListReducer.studentPointRange
//     }
// }
// const mapDispatchToProps = {
//     showPopup: (data) => PopupAction.showPopup(data),
//     hidePopup: (data) => PopupAction.hidePopup(data),
//     getStudentsList: StudentListAction.getStudentsList,
//     getStudentLevel: StudentListAction.getStudentLevel,
//     getStudentPointRange: StudentListAction.getStudentPointRange,
//     // getStudentsFullList: StudentListAction.getStudentsFullList,
//     showLoader: LoadingAction.showLoader,
//     hideLoader: LoadingAction.hideLoader,
// }
// StudentList.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(StudentList);