import React, { useEffect, useRef, useState } from "react"
import Main from "../../constants/utilities.js"
import SlideToTop from "../includes/slide_to_top.js"
import PageTitle from "../includes/page_title.js"
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import _ from "lodash"
import { PopupAction, LoadingAction, LoginAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { emailValidate } from "../../constants/common.js"
// import { networkRequest } from "../../httpAPI/api.js"

const Login = (props, context) => {
	const [searchParams] = useSearchParams()
	const search = searchParams.get("type")
	const { config, authRequest, isLoggedInUsers, networkRequest } = Main()
	const configure = config()
	const [loginForm, setLoginForm] = useState({
		username: { value: "", error: "", class: "" },
		password: { value: "", error: "", class: "" },
	})
	const [error, setError] = useState("")
	const [showPassword, setShowPassword] = useState(false)
	const [formError, setFormError] = useState({})
	const [hasError, setHasError] = useState(false)
	const loginFormRef = useRef(null)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { lang } = useSelector(state => state.i18nState)
	const initForm = () => {
		setLoginForm({
			username: { value: "", error: "", class: "" },
			password: { value: "", error: "", class: "" },
		})
	}

	const handleChange = (field, value) => {
		loginForm[field]["class"] = value ? "" : "invalid";
		loginForm[field]["value"] = value;
		setLoginForm({ ...loginForm })
	}
	const validate = () => {
		let error = {}
		if (_.isEmpty(loginForm.username.value))
			error["username"] = context.t("_email_required");

		if (_.isEmpty(loginForm.password.value))
			error["password"] = context.t("_password_is_required");

		if (!_.isEmpty(loginForm.username.value) && !emailValidate.test(loginForm.username.value))
			error["username"] = context.t("_invalid_email")
		if (!_.isEmpty(error)) {
			setFormError(error)
			setHasError(true)
			setTimeout(() => {
				document.querySelector(`a[href="#${Object.keys(error)[0]}"]`).focus()
			}, 200);
			return false
		}
		setHasError(false)
		return true
		// for (var key in loginForm) {
		// 	if (loginForm[key].error !== "") {
		// 		setState({ loginForm })
		// 		setTimeout(() => {
		// 			// refs.errorListRef.focus()
		// 			this[key + "error"].focus()
		// 		}, 1000);
		// 		return false;
		// 	}
		// }
		// return true;
	}
	const onSubmit = async (event) => {
		event.stopPropagation();
		event.preventDefault();
		dispatch(LoadingAction.showLoader())
		if (validate()) {
			const obj = {
				"username": loginForm.username.value.trim().replace(/[\u200B-\u200D\uFEFF]/g, ""),
				"password": loginForm.password.value,
				"client_secret": configure.client_secret,
				"grant_type": configure.grant_type,
				"client_id": configure.client_id
			}
			const authUrl = `${configure.origin}${urlEndPoints.auth}`
			authRequest(authUrl, "POST", obj).then(authResponse => {
				if (!_.has(authResponse, "error")) {
					localStorage.setItem("accessToken", authResponse.data.access_token);
					localStorage.setItem("refreshToken", authResponse.data.refresh_token);
					localStorage.setItem("tokenType", authResponse.data.token_type);
					loginApi(authResponse)
					initForm()
					loginFormRef.current.reset()
				} else {
					dispatch(LoadingAction.hideLoader())
					const errorResponse = authResponse.data.message;
					setError(errorResponse)
					// refs.loginError && refs.loginError.focus()
				}
			}).catch(errorResponse => {
				console.warn(JSON.parse(JSON.stringify(errorResponse)))
				dispatch(LoadingAction.hideLoader())
				setError(context.t("_invalid_credentials"))
				// refs.loginError && refs.loginError.focus()
			})

		} else {
			dispatch(LoadingAction.hideLoader())
		}
	}
	const loginApi = async (authResponse) => {
		const loginUrl = `${configure.api_url}${urlEndPoints.login}`
		// const loginHeader = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }

		try {
			const loginResponse = await networkRequest(loginUrl, "POST")
			await getProfileData();
			dispatch(LoadingAction.hideLoader())
			if (loginResponse.data.success && loginResponse.data.status === 200) {
				localStorage.setItem("role", loginResponse.data.role);
				dispatch(LoginAction.userLoggedIn())
				if (
					loginResponse.data.role == "trainer" ||
					loginResponse.data.role == "asistant" ||
					loginResponse.data.role == "edu_specialist"
				) {
					navigate("/admin-profile");
				} else if (loginResponse.data.role == "manager" || loginResponse.data.role == "administrator") {
					// props.history.push("/admin-profile");
					// window.location.href = `/${lang}/admin-profile`;
					navigate(`/admin-profile`)
					// props.history.push("/dashboard/student");
					// } else if (loginResponse.data.role == "parent") {
					// 	props.history.push("/parent-profile");
				} else {
					// props.history.push("/activities");
					navigate(`/activities`)
					// window.location.href = `/${lang}/activities`;
				}
				setTimeout(() => {
					const bannerButton = document.querySelector(".navigation-profile");
					if (bannerButton && bannerButton.addEventListener) {
						bannerButton.addEventListener('click', () => {
							const bannerElement = document.querySelectorAll(".navigation-profile");
							bannerElement[0].classList.toggle('hover');
						});
						bannerButton.addEventListener('keypress', () => {
							const bannerElement = document.querySelectorAll(".navigation-profile");
							bannerElement[0].classList.toggle('hover');
						});
						bannerButton.addEventListener('focusIn', () => {
							const bannerElement = document.querySelectorAll(".navigation-profile");
							bannerElement[0].classList.add('hover');
						});
						bannerButton.addEventListener('focusOut', () => {
							const bannerElement = document.querySelectorAll(".navigation-profile");
							bannerElement[0].classList.remove('hover');
						});

					}
				}, 1000);
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("loginResponse  error: ", error)
		}

	}
	const handleShowPassword = stateValue => {
		setShowPassword(!showPassword)
		// setState({ [stateValue]: !state[stateValue] })
	}
	const getProfileData = async () => {
		// const authResponse = await auth();
		// if (authResponse) {
		const url = `${configure.api_url}${urlEndPoints.userDetails}`
		// const headers = {
		//     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`

		// }
		try {
			const data = await networkRequest(url)
			localStorage.setItem("profileImage", `${configure.origin}${data.data.picture}`)
			dispatch(LoginAction.userLoggedIn(`${configure.origin}${data.data.picture}`))
			dispatch(LoadingAction.hideLoader())
			// props.hideLoader()
			// setState({ profileData: data.data })
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
		}
		// }
	}

	useEffect(() => {
		document.title = `${context.t("_studio56")} | ${context.t("_login")}`
		isLoggedInUsers();
		if (_.isEmpty(localStorage.getItem("refreshToken")) || _.isEmpty(localStorage.getItem("accessToken"))) {
			dispatch(LoginAction.userLogout())
		}
		initForm();
		setTimeout(() => {
			document.getElementById("pageHeading") && document.getElementById("pageHeading").focus()
		}, 500);
	}, [])

	return (
		<div id="main-content">
			<SlideToTop />
			<PageTitle title={context.t("_login")} />
			<div id="skipContent">
				<div id="main-container">
					<ColouredItems />
					<div className="container">
						<br /><br />
						<div className="center"></div>
						<div className="row col-8">
							{
								_.isEmpty(loginForm) ?
									<div />
									: <form name="login" onSubmit={onSubmit} className="login-form loginForm" autoComplete="on" ref={loginFormRef}>
										{
											/* {
									Object.keys(loginForm).map((item, index) => {
										return loginForm[item].error !== "" ?
											<p key={index} ref={(refItem) => { this[item + "error"] = refItem; }}>
												<span className="asterisk">*</span>
												<label className="error" htmlFor={item}>{loginForm[item].error}</label>
											</p>
											: null
									})
								} */}
										<h2>{context.t("_login")}</h2>


										{
											search == "relogin" && <div className="error-container success">
												<h5>{context.t("_password_set_successfully")}</h5>
											</div>
										}
										{error &&
											<div className="error-container fail">
												<ul>
													<li tabIndex="0" aria-label={error}><label className="error" >{error}</label></li>
												</ul>
											</div>
										}
										{
											hasError ? <div className="error-container fail">
												<h5>{context.t("_error_title")}</h5>
												<ul>
													{
														Object.keys(formError).map((item, index) => {
															return formError[item] !== "" ? <li key={index}><a tabIndex="0" className="error" href={`#${item}`} htmlFor={item}>{formError[item]}</a></li>
																:
																null
														})}
												</ul>
											</div> : null

										}
										<div className="input-field item">
											<input name="username"
												placeholder={context.t("_email_placeholder")}
												onChange={evt => handleChange("username", evt.target.value)}
												value={loginForm.username.value}
												autoComplete="no"
												className="fontEnglish"
												id="username"
												name="username"
												type="text"
											/>
											<label htmlFor="username" style={dynamicStyles.position(lang)}>{context.t("_email")}</label>
											<span className="helper-text" data-error="Required field."></span>
										</div>
										<div className="input-field item">
											<input name="password"
												autoComplete="no"
												placeholder={context.t("_password_placeholder")}
												onChange={evt => handleChange("password", evt.target.value)}
												value={loginForm.password.value}
												id="password"
												name="password"
												className="fontEnglish"
												type={showPassword ? "text" : "password"}
											/>
											<button onClick={() => { handleShowPassword("showPassword") }} type="button" aria-label={showPassword ? context.t("_hide_password") : context.t("_show_password")} className="showEyes">
												<i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
												<span>show password</span>
											</button>
											<label htmlFor="password" style={dynamicStyles.position(lang)}>{context.t("_password")}</label>
											<span className="helper-text" data-error="Required field."></span>
										</div>
										<div className={`${lang == "ar" ? "right" : "left"}-align`}>
											<Link className="underline-text" to="/forgot-password"><b>{context.t("_forget_password")}</b></Link>
										</div>

										<div className={`${lang == "ar" ? "right" : "left"}-align btn-wrap`}>
											<button aria-label="login" className="btn blue login">{context.t("_login")} <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i></button>
										</div>
										<div className={`${lang == "ar" ? "right" : "left"}-align`}>
											{context.t("_dont_have")} <Link className="underline-text" to="/registration"><b>{context.t("_sign_up")}</b></Link>
										</div>
										{/* <div className="btn-wrap">
											<button className="btn red login" >
												{context.t("_login")} <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i>
											</button>
										</div>
										<div className="login-bottom">
											<Link to="/registration">{context.t("_register")}</Link>
											<Link to="/forgot-password">{context.t("_forgot_password")}</Link>
										</div> */}

									</form>
							}

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
Login.contextTypes = {
	t: PropTypes.func
}

export default Login

const ColouredItems = () => {
	return (
		<div className="student-color-container">
			<span className="grey-square-rotate red-sq one"></span>
			{/* <span className="grey-square-rotate red-sq two"></span> */}
			{/* <span className="grey-square-rotate red-sq three"></span> */}
			<span className="grey-square-rotate red-sq four"></span>
			<span className="orange-circle one"></span>
			{/* <span className="orange-circle two"></span> */}
			<span className="orange-circle three"></span>
			{/* <span className="orange-circle four"></span> */}
			<span className="multi-square one"><b><i></i></b></span>
			{/* <span className="multi-square two"><b><i></i></b></span> */}
			<span className="multi-square three"><b><i></i></b></span>
			{/* <span className="multi-square four"><b><i></i></b></span> */}
		</div>
	)
}