import { SHOW_POPUP, HIDE_POPUP } from "./../constants/constants";

const initialState = {
    modalContent: {
        type: "success",
        title: "",
        message: "",
        isOpen: false,
        buttonText: ""
    }
};

const popupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_POPUP:
      return {
        popupState: action.payload
      };
    case HIDE_POPUP:
      return {
        popupState: action.payload
      };

    default:
      return { ...state };
  }
};

export default popupReducer;
