import React from "react"
import Main from "../../../constants/utilities.js"
import SlideToTop from "../../includes/slide_to_top.js"
import PageTitle from "../../includes/page_title.js"
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import { dynamicStyles, customModalStyles } from "./../../../constants/dynamicStyles"
import { Link, useNavigate } from "react-router-dom"
import Pagination from "react-js-pagination";
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import M from "materialize-css"
import { LoadingAction, PopupAction } from "./../../../actions"
import _ from "lodash"
import topLineImage from "../../../assets/images/top-line.png"
import Modal from "react-modal";
import dateformat from "dateformat"
import { CustomDateInput, calenderLocal } from "./../../../constants/common"
import { useEffect, useState, useRef } from "react"

// class ActivityHistory extends Main {
// 	constructor(props) {
// 		super(props);
// 		checkLogin();
// 		state = {
// 			isLoaded: false,
// 			config: config(),
// 			currentActivityList: [],
// 			pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
// 			search: { type: "All", title: "", start_date: "", end_date: "", page: 0 },
// 			studentList: [],
// 			checkedList: [],
// 			selectedActivityId: null,
// 			intervalId: 0,
// 			scrollStepInPx: 50,
// 			delayInMs: 16.66,
// 			cancelActivityConfirm: false,
// 			cancelActivityId: null
// 		}
// 		handlePageChange = handlePageChange.bind(this);
// 		props.showLoader()
// 	}
// 	// async componentDidMount() {
// 	// 	document.title = `${context.t("_studio56")} | ${context.t("_current_activities")}`
// 	// 	await initSearchComponents();
// 	// 	M.AutoInit();
// 	// 	await getActivity();
// 	// 	window.addEventListener('load', handleTabIndex());
// 	// 	setTimeout(() => {
// 	// 		document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
// 	// 	}, 500);
// 	// }
// 	// componentDidUpdate() {
// 	// 	document.title = `${context.t("_studio56")} | ${context.t("_current_activities")}`
// 	// }
// 	// handleTabIndex = () => {
// 	// 	setTimeout(() => {
// 	// 		let elem = document.querySelectorAll('select');
// 	// 		elem.forEach(element => {
// 	// 			element.setAttribute("tabindex", "0");
// 	// 		});
// 	// 	}, 1000);
// 	// }
// 	// initSearchComponents() {
// 	// 	var options = { autoClose: true, format: "dd/mm/yyyy" };
// 	// 	var start_date_options = {
// 	// 		autoClose: true,
// 	// 		format: "dd/mm/yyyy",
// 	// 		showClearBtn: true,
// 	// 		onSelect: ((date) => {
// 	// 			handleChange("start_date", dateformat(date, "dd/mm/yyyy"))
// 	// 		}),
// 	// 		format: "dd/mm/yyyy",
// 	// 	};
// 	// 	var end_date_options = {
// 	// 		autoClose: true,
// 	// 		format: "dd/mm/yyyy",
// 	// 		showClearBtn: true,
// 	// 		onSelect: ((date) => {
// 	// 			handleChange("end_date", dateformat(date, "dd/mm/yyyy"))
// 	// 		}),
// 	// 		format: "dd/mm/yyyy",
// 	// 	};
// 	// 	setTimeout(() => {
// 	// 		let start_date = document.querySelectorAll("#start_date");
// 	// 		let end_date = document.querySelectorAll("#end_date");
// 	// 		// M.Datepicker.init(start_date, start_date_options);
// 	// 		// M.Datepicker.init(end_date, end_date_options);
// 	// 		let elems = document.querySelectorAll("select");
// 	// 		M.FormSelect.init(elems, options);
// 	// 	}, 1000);
// 	// }
// 	// getChildren = async () => {
// 	// 	const { config } = state
// 	// 	const url = `${config.api_url}${urlEndPoints.myChildren}`

// 	// 	try {
// 	// 		const data = await networkRequest(url)
// 	// 		props.hideLoader();
// 	// 		if (!data.data.message) {
// 	// 			return data.data.results && data.data.results.length > 0 ? data.data.results : []
// 	// 		}
// 	// 	} catch (error) {
// 	// 		props.hideLoader()
// 	// 		console.warn("error: ", error)
// 	// 	}
// 	// 	// }
// 	// }
// 	// getActivity = async () => {
// 	// 	const { config, search } = state;
// 	// 	// const authResponse = await auth();
// 	// 	// if (authResponse) {
// 	// 	let methord = "POST"
// 	// 	let param = {};
// 	// 	const body = {}
// 	// 	let url = `${config.api_url}${urlEndPoints.volunteerActivityHistory}`

// 	// 	if (!_.isEmpty(search.title) && search.title != "All")
// 	// 		body.field_event_type_value = search.title
// 	// 	if (search.start_date)
// 	// 		body.field_start_end_dates_value = dateformat(search.start_date, "dd-mm-yyyy")
// 	// 	if (search.end_date)
// 	// 		body.field_start_end_dates_end_value = dateformat(search.end_date, "dd-mm-yyyy")
// 	// 	if (search.page)
// 	// 		body.page = search.page


// 	// 	// let date = null
// 	// 	// if (!_.isEmpty(search.start_date))
// 	// 	// 	date = search.start_date;
// 	// 	// if (!_.isEmpty(search.end_date))
// 	// 	// 	if (!_.isEmpty(search.start_date))
// 	// 	// 		date = `${search.start_date}-${search.end_date}`;
// 	// 	// 	else
// 	// 	// 		date = search.end_date;
// 	// 	// if (!_.isEmpty(date))
// 	// 	// 	url = `${url}&field_start_end_dates_value=${date.replace(/[/]/g, "-")}`
// 	// 	// const headers = {
// 	// 	// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
// 	// 	// }
// 	// 	try {
// 	// 		const data = await networkRequest(url, methord, body)
// 	// 		if (data.status == 200) {
// 	// 			scrollToTop()
// 	// 			props.hideLoader()
// 	// 			const currentActivityList = data.data.results;
// 	// 			currentActivityList.forEach(async item => {
// 	// 				item.field_start_and_end_time = item.field_start_and_end_time.split("*")
// 	// 				item.field_start_and_end_time_1 = item.field_start_and_end_time_1.split("*")
// 	// 				item.field_start_and_end_time_2 = item.field_start_and_end_time_2.split("*")
// 	// 			})
// 	// 			setState({
// 	// 				currentActivityList,
// 	// 				pager: data.data.pager,
// 	// 				isLoaded: true,
// 	// 				checkedList: {}
// 	// 			})
// 	// 		}
// 	// 	} catch (error) {
// 	// 		props.hideLoader()
// 	// 		console.warn("error: ", error)
// 	// 	}
// 	// 	// }
// 	// }
// 	// calculateAge = (dateString) => {
// 	// 	var today = new Date();
// 	// 	var birthDate = new Date(dateString);
// 	// 	var age = today.getFullYear() - birthDate.getFullYear();
// 	// 	var m = today.getMonth() - birthDate.getMonth();
// 	// 	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
// 	// 		age--;
// 	// 	}
// 	// 	return age;
// 	// }
// 	// handleCheck = (event, id, index) => {
// 	// 	const { checkedList } = state
// 	// 	if (event.target.checked)
// 	// 		checkedList[index] = id
// 	// 	else
// 	// 		delete checkedList[index]
// 	// 	setState({ checkedList })

// 	// }
// 	// handlePageChange(pageNumber) {
// 	// 	props.showLoader()
// 	// 	var { search } = state;
// 	// 	search.page = pageNumber - 1;
// 	// 	setState({ search: search });
// 	// 	getActivity();
// 	// }
// 	// handleChange = (field, value) => {
// 	// 	var { search } = state;
// 	// 	search[field] = value;
// 	// 	setState({ search: search });
// 	// }
// 	// handelCancelActivity = async id => {
// 	// 	props.showLoader();
// 	// 	const { config } = state;
// 	// 	const { lang } = props;
// 	// 	// const authResponse = await auth();
// 	// 	// if (authResponse) {
// 	// 	const url = `${config.api_url}${urlEndPoints.cancelActivity}`
// 	// 	const obj = {
// 	// 		"activity_id": id
// 	// 	}
// 	// 	// const headers = {
// 	// 	// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
// 	// 	// }
// 	// 	try {
// 	// 		const data = await networkRequest(url, "POST", obj);
// 	// 		if (data.status == 200) {
// 	// 			if (!_.isEmpty(data.data.error)) {
// 	// 				props.showPopup({
// 	// 					type: "error",
// 	// 					title: context.t("_error"),
// 	// 					message: data.data.error.name[lang],
// 	// 					isOpen: true,
// 	// 					buttonText: context.t("_ok")
// 	// 				})
// 	// 			} else {
// 	// 				props.showPopup({
// 	// 					type: "success",
// 	// 					title: context.t("_success"),
// 	// 					message: context.t("_successfully_cancelled"),
// 	// 					isOpen: true,
// 	// 					buttonText: context.t("_ok")
// 	// 				})
// 	// 			}

// 	// 			getActivity()
// 	// 		}
// 	// 	} catch (error) {
// 	// 		props.showPopup({
// 	// 			type: "error",
// 	// 			title: context.t("_failed"),
// 	// 			message: context.t("_failed_to_cancel"),
// 	// 			isOpen: true,
// 	// 			buttonText: context.t("_ok")
// 	// 		})
// 	// 		props.hideLoader()
// 	// 		console.warn("error: ", error)
// 	// 	}
// 	// 	// }
// 	// }
// 	// filterFormSubmit = event => {
// 	// 	event.preventDefault();
// 	// 	props.showLoader()
// 	// 	getActivity();
// 	// }
// 	// clearSearch = async () => {
// 	// 	props.showLoader()
// 	// 	myFormRef.reset()
// 	// 	// let elems = document.querySelector("#start_date");
// 	// 	// let instance = M.Datepicker.getInstance(elems);
// 	// 	// instance.setInputValue();
// 	// 	// elems = document.querySelector("#end_date");
// 	// 	// instance = M.Datepicker.getInstance(elems);
// 	// 	// instance.setInputValue();
// 	// 	setState({ search: { type: "All", title: "", start_date: "", end_date: "", page: 0 } }, () => { getActivity(); })
// 	// }
// 	// handleGetStudentList = async id => {
// 	// 	props.showLoader()
// 	// 	// const authResponse = await auth();
// 	// 	const { formData } = state;
// 	// 	// if (authResponse) {
// 	// 	const { config } = state
// 	// 	const url = `${config.api_url}${urlEndPoints.enrolledUsers(id)}`

// 	// 	// const headers = {
// 	// 	// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
// 	// 	// }
// 	// 	try {
// 	// 		const data = await networkRequest(url)
// 	// 		props.hideLoader();
// 	// 		if (!data.data.message) {
// 	// 			const studentList = _.orderBy(data.data.results, ["field_student_name"], ["asc"])
// 	// 			setState({ studentList, selectedActivityId: id })

// 	// 			let elems = document.querySelector("#enrollments");
// 	// 			elems.setAttribute("aria-hidden", "false")
// 	// 			elems.setAttribute("tabindex", "-1")
// 	// 			let instance = M.Modal.getInstance(elems);
// 	// 			instance.open();
// 	// 		}
// 	// 	} catch (error) {
// 	// 		props.hideLoader()
// 	// 		console.warn("error: ", error)
// 	// 	}
// 	// 	// } else {
// 	// 	// 	props.hideLoader()
// 	// 	// }
// 	// }
// 	// handleCloseModal = () => {
// 	// 	let elems = document.querySelector("#enrollments");
// 	// 	elems.removeAttribute("aria-hidden")
// 	// 	elems.removeAttribute("tabindex")
// 	// 	let instance = M.Modal.getInstance(elems);
// 	// 	instance.close();
// 	// }
// 	// handleDetailRedirect = id => {
// 	// 	props.history.push(`/activity/${id}`);
// 	// }
// 	// handleCancelMultipleActivity = async () => {
// 	// 	props.showLoader();
// 	// 	const { config, checkedList, selectedActivityId } = state;
// 	// 	const { lang } = props;
// 	// 	if (!_.isEmpty(checkedList)) {
// 	// 		// const authResponse = await auth();
// 	// 		// if (authResponse) {
// 	// 		const url = `${config.api_url}${urlEndPoints.cancelActivity}`
// 	// 		const obj = {
// 	// 			"activity_id": selectedActivityId,
// 	// 			user_ids: checkedList
// 	// 		}
// 	// 		// const headers = {
// 	// 		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
// 	// 		// }
// 	// 		try {
// 	// 			const data = await networkRequest(url, "POST", obj);
// 	// 			if (data.status == 200) {
// 	// 				if (!_.isEmpty(data.data.error)) {
// 	// 					props.showPopup({
// 	// 						type: "error",
// 	// 						title: context.t("_error"),
// 	// 						message: data.data.error.name[lang],
// 	// 						isOpen: true,
// 	// 						buttonText: context.t("_ok")
// 	// 					})
// 	// 				} else {
// 	// 					props.showPopup({
// 	// 						type: "success",
// 	// 						title: context.t("_success"),
// 	// 						message: context.t("_successfully_cancelled"),
// 	// 						isOpen: true,
// 	// 						buttonText: context.t("_ok")
// 	// 					})
// 	// 				}
// 	// 				handleCloseModal();
// 	// 				getActivity()
// 	// 			}
// 	// 		} catch (error) {
// 	// 			props.showPopup({
// 	// 				type: "error",
// 	// 				title: context.t("_failed"),
// 	// 				message: context.t("_failed_to_cancel"),
// 	// 				isOpen: true,
// 	// 				buttonText: context.t("_ok")
// 	// 			})
// 	// 			props.hideLoader()
// 	// 			console.warn("error: ", error)
// 	// 		}
// 	// 		// }
// 	// 	} else {
// 	// 		handleCloseModal();
// 	// 		getActivity()
// 	// 	}

// 	// }
// 	// scrollStep() {
// 	// 	var scrollStepInPx = props.scrollStepInPx ? props.scrollStepInPx : state.scrollStepInPx;
// 	// 	if (window.pageYOffset === 0) {
// 	// 		clearInterval(state.intervalId);
// 	// 	}
// 	// 	window.scroll(0, window.pageYOffset - scrollStepInPx);
// 	// }
// 	// scrollToTop() {
// 	// 	window.scroll({ top: 0, left: 0, behavior: "smooth" })
// 	// 	// var delayInMs = props.delayInMs ? props.delayInMs : state.delayInMs;
// 	// 	// let intervalId = setInterval(scrollStep.bind(this), delayInMs);
// 	// 	// setState({ intervalId: intervalId });
// 	// }
// 	// handleCloseConfirmModal = (state) => {
// 	// 	setState({ [state]: false, cancelActivityId: null })
// 	// }
// 	// handleOpenCancelActivityConfirm = id => {
// 	// 	setState({ cancelActivityConfirm: true, cancelActivityId: id })
// 	// }
// 	// handleContinueCancelActivity = () => {
// 	// 	const { cancelActivityId } = state;
// 	// 	if (!_.isEmpty(cancelActivityId))
// 	// 		handelCancelActivity(cancelActivityId)
// 	// 	handleCloseConfirmModal("cancelActivityConfirm")
// 	// }
// 	// showStatus = status => {
// 	// 	switch (status) {
// 	// 		case "Unpublished":
// 	// 			return context.t("_pending");
// 	// 		case "Waiting List":
// 	// 			return context.t("_waiting_list");

// 	// 		default:
// 	// 			return status;
// 	// 	}
// 	// }
// 	render() {
// 		const { currentActivityList, pager, isLoaded, search, studentList, checkedList, cancelActivityConfirm } = state;
// 		const listActivity = search.type == "All" ? currentActivityList : currentActivityList.filter(data => data.field_event_type.toLowerCase() == search.type)
// 		const { lang } = props;
// 		return (
// 			<div id="main-content" className="activiti-list">
// 				<SlideToTop />
// 				<PageTitle title={context.t("_current_activities")} />
// 				<div className="container">
// 					<nav className="breadcrumb aside-breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
// 						<ul>
// 							<li className="breadcrumb-item">
// 								<Link to="/profile">{context.t("_profile")}</Link> {" "}

// 							</li>
// 							<li className="breadcrumb-item">
// 								<span>{context.t("_current_activities")} </span>

// 							</li>
// 						</ul>
// 					</nav>
// 					<Link className="activityLink" to="/activities">{context.t("_explore_activity_1")}</Link>
// 					<div className="clearfix"></div>
// 				</div>
// 				<div id="main-container">
// 					<div id="skipContent" className="container">
// 						<form onSubmit={filterFormSubmit} ref={(el) => myFormRef = el}>
// 							<div className="row topFormHolder">
// 								<div className="col s12 m6 l3">
// 									<div className={`input-field mobileSelect item ${lang}`}>
// 										<label className="active" style={dynamicStyles.position(lang)}>{context.t("_activity_type")}</label>
// 										<select className="browser-default" value={search.title} onChange={evt => handleChange("title", evt.target.value)}>
// 											<option value="" disabled >{context.t("_select_activity_type")}</option>
// 											<option value="All" >{context.t("_all")}</option>
// 											<option value="competition">{context.t("_competitions")}</option>
// 											<option value="session">{context.t("_sessions")}</option>
// 											<option value="event">{context.t("_events")}</option>
// 											<option value="challenge">{context.t("_challenges")}</option>
// 											<option value="project">{context.t("_projects")}</option>
// 										</select>
// 										<span className="helper-text"></span>
// 									</div>
// 								</div>

// 								<div className="col s12 m6 l3 date-popup">
// 									<div className="input-field item">
// 										<DatePicker
// 											name="start_date"
// 											placeholderText={context.t("_date")}
// 											id="start_date"
// 											autoComplete="off"
// 											dateFormat="yyyy-MM-dd"
// 											locale={calenderLocal(context)}
// 											customInput={<CustomDateInput ariaLabel={context.t("_select_date_button")} onFieldChange={date => handleChange("start_date", new Date(date))} />}
// 											onChange={date =>
// 												handleChange("start_date", date)
// 											}
// 											onCalendarOpen={e => {
// 												let elems = document.querySelector(".react-datepicker");
// 												elems.setAttribute("tabindex", "0")
// 												elems.focus()
// 												elems = document.querySelector(".react-datepicker__month-select");
// 												elems.setAttribute("aria-label", context.t("_select_month"))
// 												elems = document.querySelector(".react-datepicker__year-select");
// 												elems.setAttribute("aria-label", context.t("_select_year"))

// 											}}
// 											onCalendarClose={e => {
// 												var elems = document.querySelector("#start_date");
// 												elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(search.start_date, "dd-mm-yyyy")}`)
// 												elems.setAttribute("value", dateformat(search.start_date, "yyyy-mm-dd"))
// 												setTimeout(() => {
// 													elems.focus()
// 												}, 200);
// 											}}
// 											style={dynamicStyles.textAlign(lang)}
// 											value={search.start_date}
// 											selected={search.start_date}
// 											peekNextMonth
// 											showMonthDropdown
// 											showYearDropdown
// 											dropdownMode="select"
// 										/>
// 										<label htmlFor="start_date" style={dynamicStyles.position(lang)}>{context.t("_start_date")}</label>
// 									</div>
// 								</div>

// 								<div className="col s12 m6 l3 date-popup">
// 									<div className="input-field item">
// 										<DatePicker
// 											name="end_date"
// 											placeholderText={context.t("_date")}
// 											id="end_date"
// 											autoComplete="off"
// 											dateFormat="yyyy-MM-dd"
// 											locale={calenderLocal(context)}
// 											customInput={<CustomDateInput ariaLabel={context.t("_select_date_button")} onFieldChange={date => handleChange("end_date", new Date(date))} />}
// 											onChange={date =>
// 												handleChange("end_date", date)
// 											}
// 											onCalendarOpen={e => {
// 												let elems = document.querySelector(".react-datepicker");
// 												elems.setAttribute("tabindex", "0")
// 												elems.focus()
// 												elems = document.querySelector(".react-datepicker__month-select");
// 												elems.setAttribute("aria-label", context.t("_select_month"))
// 												elems = document.querySelector(".react-datepicker__year-select");
// 												elems.setAttribute("aria-label", context.t("_select_year"))

// 											}}
// 											onCalendarClose={e => {
// 												var elems = document.querySelector("#end_date");
// 												elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(search.end_date, "dd-mm-yyyy")}`)
// 												elems.setAttribute("value", dateformat(search.end_date, "yyyy-mm-dd"))
// 												setTimeout(() => {
// 													elems.focus()
// 												}, 200);
// 											}}
// 											style={dynamicStyles.textAlign(lang)}
// 											value={search.end_date}
// 											selected={search.end_date}
// 											peekNextMonth
// 											showMonthDropdown
// 											showYearDropdown
// 											dropdownMode="select"
// 										/>
// 										<label htmlFor="end_date" style={dynamicStyles.position(lang)}>{context.t("_end_date")}</label>
// 									</div>
// 								</div>

// 								<div className="col s12 m6 l3 valign-wrapper center-align search-btn-holder">
// 									<button className="btn noWidth profileBtn yellow waves-effect waves-light">
// 										{context.t("_search")} <i className={`tiny material-icons ${lang}`} >{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
// 									</button>
// 									<button type="button" onClick={() => clearSearch()} className="btn noWidth  blue-btn waves-effect waves-light">
// 										{context.t("_clear")}
// 									</button>
// 									<br /><br />
// 								</div>
// 							</div>
// 						</form>

// 						<div className="row">
// 							<div className="col s7 result-status">
// 								{isLoaded ? context.t("_search_result") + " ( " + (listActivity.length) + " of " + pager.count + " )" : ""}

// 							</div>
// 							<div className="col s5 result-type">
// 								<div className="input-field mobileSelect col s12 filter-select">
// 									<label htmlFor="result-type">{context.t("_select_activity")}</label>
// 									<select className="browser-default" id="result-type" value={search.type} onChange={evt => handleChange("type", evt.target.value)}>
// 										<option value="All" >{context.t("_all")}</option>
// 										<option value="competition">{context.t("_competitions")}</option>
// 										<option value="session">{context.t("_sessions")}</option>
// 										<option value="event">{context.t("_events")}</option>
// 										<option value="challenge">{context.t("_challenges")}</option>
// 										<option value="project">{context.t("_projects")}</option>
// 									</select>
// 								</div>
// 							</div>
// 						</div>
// 						<div className="activities">
// 							{listActivity.map((row, index) => (
// 								<div className="row" key={index}>
// 									<div className="col s7">
// 										<Link to={`/activity/${row.nid}`}>
// 											<h3 className="title">{row.title} <span className={row.field_event_type.toLowerCase()}>{row.field_event_type}</span></h3>
// 										</Link>

// 										<div className="details">
// 											{row.field_start_and_end_time &&
// 												<span><i className="calendar-icons"></i> {context.t("_start_date")} <span className="date">{row.field_start_and_end_time[0]}</span></span>
// 											}
// 											{row.field_start_and_end_time_1 &&
// 												<span><i className="calendar-icons"></i> {context.t("_end_date")} <span className="date">{row.field_start_and_end_time_1[row.field_start_and_end_time_1.length - 1]}</span></span>
// 											}
// 										</div>
// 									</div>
// 									<div className="col s5 btn-wrap flex-row justify-end align-center">
// 										{
// 											localStorage.getItem("role") == "volenteer" ?
// 												null :
// 												localStorage.getItem("role") == "student" ?
// 													<button onClick={() => handleOpenCancelActivityConfirm(row.nid)} className="btn blue-border">Cancel</button>
// 													:
// 													<button onClick={() => handleGetStudentList(row.nid)} className="btn blue-border">Enrollments</button>
// 										}

// 										{/* <button onClick={() => { handleDetailRedirect(row.nid) }} className="btn blue-border">Details</button> */}

// 									</div>
// 								</div>
// 							))}
// 							{!currentActivityList.length && isLoaded &&
// 								<div className="row"><p aria-label={context.t("_no_result_found")} className="col s12 no-result">{context.t("_no_result_found")}</p></div>
// 							}
// 						</div>
// 						<div id="enrollments" className="modal user-add-modal" role="dialog">
// 							<div className="modal-content">
// 								{/* <form onSubmit={handleAddNewStudent} className="col full-width"> */}
// 								<span className="badge modalClose"><a onClick={() => handleCloseModal()}><i className="material-icons">close</i></a></span>
// 								<h2 className="line">{context.t("_enrollments")}</h2>
// 								<div className="tableHolder">

// 									<table className="responsive-table highlight">
// 										<thead className="blue lighten-5">
// 											<tr>
// 												<th><strong>{context.t("_name")}</strong></th>
// 												<th><strong>{context.t("_qid")}</strong></th>
// 												<th><strong>{context.t("_phone_number")}</strong></th>
// 												<th><strong>{context.t("_grade")}</strong></th>
// 												<th><strong>{context.t("_gender")}</strong></th>
// 												<th><strong>{context.t("_age")}</strong></th>
// 												<th width="50"><strong><span style={{ display: "none" }}>status</span></strong></th>
// 											</tr>
// 										</thead>

// 										<tbody>
// 											{
// 												studentList.map((item, index) => {
// 													return (
// 														<tr key={index}>
// 															<td>{item.field_student_name}</td>
// 															<td>{item.field_qid}</td>
// 															<td>{item.field_student_mobile}</td>
// 															<td>{item.field_grade}</td>
// 															<td>{item.field_gender}</td>
// 															<td>{!_.isEmpty(item.field_student_dob) ? calculateAge(item.field_student_dob) : null}</td>
// 															<td>

// 																{
// 																	item.field_published_status == "Published" ? <p className="checkboxHolder checkboxNew">
// 																		<input onChange={(e) => handleCheck(e, item.uid, index)} checked={checkedList[index] ? true : false} type="checkbox" />
// 																		<label>
// 																			<span></span>
// 																		</label></p> : showStatus(item.field_published_status)
// 																}


// 															</td>
// 														</tr>
// 													)
// 												})
// 											}
// 										</tbody>
// 									</table>
// 								</div>
// 								<button onClick={() => { handleCancelMultipleActivity() }} className="btn blue-border">Cancel</button>
// 								{/* </form> */}
// 								{/* {
// 									_.isEmpty(studentFormData) ?
// 										<div /> :
// 										<form onSubmit={handleAddNewStudent} className="col full-width">
// 											<span className="badge modalClose"><a onClick={() => handleCloseModal()}><i className="material-icons">close</i></a></span>
// 											<h2 className="line">{context.t("_add_student")}</h2>

// 											<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p>
// 											{hasError ? <div className="error-container fail">
// 												<h5>{context.t("_error_title")}</h5>
// 												<ul>
// 													{
// 														Object.keys(studentFormData).map((item, index) => {
// 															return studentFormData[item].error != "" ? <li key={index} ref={(refItem) => { this[item + "error"] = refItem; }}><label className="error" htmlFor={item}>{studentFormData[item].error}</label></li>
// 																:
// 																null
// 														})}
// 												</ul>
// 											</div> : null
// 											}
// 											<p>{context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

// 											<div className="row">
// 												<div className="col s12 m6">
// 													{renderInput({ name: "name", label: context.t("_student_name"), placeholder: context.t("_student_name_placeholder"), isMandatory: true })}
// 													{renderInput({ name: "email", label: context.t("_email"), placeholder: context.t("_email_placeholder"), type: "email" })}

// 												</div>
// 												<div className="col s12 m6">
// 													<div className="date-popup">
// 														{renderInput({ name: "dob", label: context.t("_dob"), placeholder: context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
// 													</div>

// 													{renderInput({ name: "mobile", label: context.t("_mobile_number"), placeholder: context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number" })}
// 												</div>
// 												<div className="col s12">
// 													<button className="btn profileBtn blue waves-effect waves-light">{context.t("_save_changes")}</button>
// 												</div>
// 												<div className="clearfix" />
// 											</div>
// 										</form>
// 								} */}
// 							</div>
// 						</div>
// 						<nav className="pagination-wrapper" aria-label="pagination">
// 							{(currentActivityList.length && isLoaded) ? (
// 								<Pagination
// 									prevPageText={`< ${context.t("_previous")}`}
// 									nextPageText={`${context.t("_next")} >`}
// 									hideFirstLastPages={true}
// 									hideDisabled={true}
// 									linkClassPrev="linkClassPrev"
// 									linkClassNext="linkClassNext"
// 									activePage={pager.current_page + 1}
// 									itemsCountPerPage={pager.items_per_page}
// 									totalItemsCount={pager.count}
// 									pageRangeDisplayed={5}
// 									onChange={handlePageChange}
// 								/>) : ""
// 							}
// 						</nav>
// 					</div>
// 					<div className="sparkles">
// 						<span className="orange-circle"></span>
// 						<span className="multi-square two"><b><i></i></b></span>
// 						<span className="grey-square-rotate red-sq one"></span>
// 						<span className="grey-square-rotate red-sq two"></span>
// 					</div>
// 				</div>
// 				<div className="top-line ng-scope">
// 					<img src={topLineImage} alt="" />
// 				</div>
// 				<Modal
// 					isOpen={cancelActivityConfirm}
// 					style={customModalStyles}
// 					contentLabel="unlink parent confirm modal"
// 					ariaHideApp={false}
// 				>
// 					<div className="popupAlert warning">
// 						<br />
// 						<h3>{context.t("_confirm")}</h3>
// 						<p>{context.t("_cancel_activity_confirm")}</p>
// 						<div className="row">
// 							<button className="col btn blue" onClick={() => handleCloseConfirmModal("cancelActivityConfirm")}>{context.t("_cancel")}</button>
// 							<button className="col btn profileBtn yellow" onClick={() => handleContinueCancelActivity()}>{context.t("_continue")}</button>
// 						</div>
// 					</div>
// 				</Modal>
// 			</div>
// 		)
// 	}
// }

// const mapStateToProps = ({ i18nState }) => {
// 	return {
// 		lang: i18nState.lang
// 	}
// }
// const mapDispatchToProps = {
// 	showLoader: LoadingAction.showLoader,
// 	hideLoader: LoadingAction.hideLoader,
// 	showPopup: (data) => PopupAction.showPopup(data),
// 	hidePopup: (data) => PopupAction.hidePopup(data),
// }
// ActivityHistory.contextTypes = {
// 	t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(ActivityHistory);

const ActivityHistory = (props, context) => {
	const [cancelActivityConfirm, setCancelActivityConfirm] = useState(false);
	const [cancelActivityId, setCancelActivityId] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [currentActivityList, setCurrentActivityList] = useState([]);
	const [pager, setPager] = useState({ count: 0, pages: 0, items_per_page: 0, current_page: 1 });
	const [search, setSearch] = useState({ type: "All", title: "", start_date: "", end_date: "", page: 0 });
	const [studentList, setStudentList] = useState([]);
	const [checkedList, setCheckedList] = useState([]);
	const [selectedActivityId, setSelectedActivityId] = useState(null);
	const [listActivity, setListActivity] = useState([]);
	const myFormRef = useRef()
	const intervalId = 0;
	const scrollStepInPx = 50;
	const delayInMs = 16.66;

	const { config, networkRequest } = Main()
	const configure = config()
	const { lang } = useSelector(state => state.i18nState)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleTabIndex = () => {
		setTimeout(() => {
			let elem = document.querySelectorAll('select');
			elem.forEach(element => {
				element.setAttribute("tabindex", "0");
			});
		}, 1000);
	}
	function initSearchComponents() {
		var options = { autoClose: true, format: "dd/mm/yyyy" };
		var start_date_options = {
			autoClose: true,
			format: "dd/mm/yyyy",
			showClearBtn: true,
			onSelect: ((date) => {
				handleChange("start_date", dateformat(date, "dd/mm/yyyy"))
			}),
			format: "dd/mm/yyyy",
		};
		var end_date_options = {
			autoClose: true,
			format: "dd/mm/yyyy",
			showClearBtn: true,
			onSelect: ((date) => {
				handleChange("end_date", dateformat(date, "dd/mm/yyyy"))
			}),
			format: "dd/mm/yyyy",
		};
		setTimeout(() => {
			let start_date = document.querySelectorAll("#start_date");
			let end_date = document.querySelectorAll("#end_date");
			// M.Datepicker.init(start_date, start_date_options);
			// M.Datepicker.init(end_date, end_date_options);
			let elems = document.querySelectorAll("select");
			M.FormSelect.init(elems, options);
		}, 1000);
	}
	const getChildren = async () => {
		const url = `${configure.api_url}${urlEndPoints.myChildren}`

		try {
			const data = await networkRequest(url)
			dispatch(LoadingAction.hideLoader());
			if (!data.data.message) {
				return data.data.results && data.data.results.length > 0 ? data.data.results : []
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
		// }
	}
	const getActivity = async () => {
		// const authResponse = await auth();
		// if (authResponse) {
		let methord = "POST"
		let param = {};
		const body = {}
		let url = `${configure.api_url}${urlEndPoints.volunteerActivityHistory}`

		if (!_.isEmpty(search.title) && search.title != "All")
			body.field_event_type_value = search.title
		if (search.start_date)
			body.field_start_end_dates_value = dateformat(search.start_date, "dd-mm-yyyy")
		if (search.end_date)
			body.field_start_end_dates_end_value = dateformat(search.end_date, "dd-mm-yyyy")
		if (search.page)
			body.page = search.page


		// let date = null
		// if (!_.isEmpty(search.start_date))
		// 	date = search.start_date;
		// if (!_.isEmpty(search.end_date))
		// 	if (!_.isEmpty(search.start_date))
		// 		date = `${search.start_date}-${search.end_date}`;
		// 	else
		// 		date = search.end_date;
		// if (!_.isEmpty(date))
		// 	url = `${url}&field_start_end_dates_value=${date.replace(/[/]/g, "-")}`
		// const headers = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		try {
			const data = await networkRequest(url, methord, body)
			if (data.status == 200) {
				scrollToTop()
				dispatch(LoadingAction.hideLoader())
				const list = data.data.results;
				list.forEach(async item => {
					item.field_start_and_end_time = item.field_start_and_end_time.split("*")
					item.field_start_and_end_time_1 = item.field_start_and_end_time_1.split("*")
					item.field_start_and_end_time_2 = item.field_start_and_end_time_2.split("*")
				})
				setCurrentActivityList(list)
				setPager(data.data.pager)
				setIsLoaded(true)
				setCheckedList({})
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
		// }
	}
	const calculateAge = (dateString) => {
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}
	const handleCheck = (event, id, index) => {
		if (event.target.checked)
			checkedList[index] = id
		else
			delete checkedList[index]
		setCheckedList([...checkedList])
	}
	function handlePageChange(pageNumber) {
		dispatch(LoadingAction.showLoader())
		search.page = pageNumber - 1;
		setSearch({ ...search })
		getActivity();
	}
	const handleChange = (field, value) => {
		search[field] = value;
		setSearch({ ...search })
	}
	const handelCancelActivity = async id => {
		dispatch(LoadingAction.showLoader());
		// const authResponse = await auth();
		// if (authResponse) {
		const url = `${configure.api_url}${urlEndPoints.cancelActivity}`
		const obj = {
			"activity_id": id
		}
		// const headers = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		try {
			const data = await networkRequest(url, "POST", obj);
			if (data.status == 200) {
				if (!_.isEmpty(data.data.error)) {
					dispatch(PopupAction.showPopup({
						type: "error",
						title: context.t("_error"),
						message: data.data.error.name[lang],
						isOpen: true,
						buttonText: context.t("_ok")
					}))
				} else {
					dispatch(PopupAction.showPopup({
						type: "success",
						title: context.t("_success"),
						message: context.t("_successfully_cancelled"),
						isOpen: true,
						buttonText: context.t("_ok")
					}))
				}

				getActivity()
			}
		} catch (error) {
			dispatch(PopupAction.showPopup({
				type: "error",
				title: context.t("_failed"),
				message: context.t("_failed_to_cancel"),
				isOpen: true,
				buttonText: context.t("_ok")
			}))
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
		// }
	}
	const filterFormSubmit = event => {
		event.preventDefault();
		dispatch(LoadingAction.showLoader())
		getActivity();
	}
	const clearSearch = async () => {
		dispatch(LoadingAction.showLoader())
		myFormRef.current.reset()
		// let elems = document.querySelector("#start_date");
		// let instance = M.Datepicker.getInstance(elems);
		// instance.setInputValue();
		// elems = document.querySelector("#end_date");
		// instance = M.Datepicker.getInstance(elems);
		// instance.setInputValue();
		setSearch({ type: "All", title: "", start_date: "", end_date: "", page: 0 })
		getActivity()
	}
	const handleGetStudentList = async id => {
		dispatch(LoadingAction.showLoader())
		// const authResponse = await auth();
		// if (authResponse) {
		const url = `${configure.api_url}${urlEndPoints.enrolledUsers(id)}`

		// const headers = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		try {
			const data = await networkRequest(url)
			dispatch(LoadingAction.hideLoader());
			if (!data.data.message) {
				const list = _.orderBy(data.data.results, ["field_student_name"], ["asc"])
				setStudentList(list)
				setSelectedActivityId(id)

				let elems = document.querySelector("#enrollments");
				elems.setAttribute("aria-hidden", "false")
				elems.setAttribute("tabindex", "-1")
				let instance = M.Modal.getInstance(elems);
				instance.open();
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
		// } else {
		// 	props.hideLoader()
		// }
	}
	const handleCloseModal = () => {
		let elems = document.querySelector("#enrollments");
		elems.removeAttribute("aria-hidden")
		elems.removeAttribute("tabindex")
		let instance = M.Modal.getInstance(elems);
		instance.close();
	}
	const handleDetailRedirect = id => {
		navigate(`/activity/${id}`);
	}
	const handleCancelMultipleActivity = async () => {
		dispatch(LoadingAction.showLoader());
		if (!_.isEmpty(checkedList)) {
			// const authResponse = await auth();
			// if (authResponse) {
			const url = `${configure.api_url}${urlEndPoints.cancelActivity}`
			const obj = {
				"activity_id": selectedActivityId,
				user_ids: checkedList
			}
			// const headers = {
			// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
			// }
			try {
				const data = await networkRequest(url, "POST", obj);
				if (data.status == 200) {
					if (!_.isEmpty(data.data.error)) {
						dispatch(PopupAction.showPopup({
							type: "error",
							title: context.t("_error"),
							message: data.data.error.name[lang],
							isOpen: true,
							buttonText: context.t("_ok")
						}))
					} else {
						dispatch(PopupAction.showPopup({
							type: "success",
							title: context.t("_success"),
							message: context.t("_successfully_cancelled"),
							isOpen: true,
							buttonText: context.t("_ok")
						}))
					}
					handleCloseModal();
					getActivity()
				}
			} catch (error) {
				dispatch(PopupAction.showPopup({
					type: "error",
					title: context.t("_failed"),
					message: context.t("_failed_to_cancel"),
					isOpen: true,
					buttonText: context.t("_ok")
				}))
				dispatch(LoadingAction.hideLoader())
				console.warn("error: ", error)
			}
			// }
		} else {
			handleCloseModal();
			getActivity()
		}

	}
	function scrollStep() {
		var scrollStep = props.scrollStepInPx ? props.scrollStepInPx : scrollStepInPx;
		if (window.pageYOffset === 0) {
			clearInterval(intervalId);
		}
		window.scroll(0, window.pageYOffset - scrollStep);
	}
	function scrollToTop() {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
		// var delayInMs = props.delayInMs ? props.delayInMs : state.delayInMs;
		// let intervalId = setInterval(scrollStep.bind(this), delayInMs);
		// setState({ intervalId: intervalId });
	}
	const handleCloseConfirmModal = (state) => {
		setCancelActivityConfirm(false)
		cancelActivityId(null)
	}
	const handleOpenCancelActivityConfirm = id => {
		cancelActivityConfirm(true)
		cancelActivityId(id)
	}
	const handleContinueCancelActivity = () => {
		if (!_.isEmpty(cancelActivityId))
			handelCancelActivity(cancelActivityId)
		handleCloseConfirmModal("cancelActivityConfirm")
	}
	const showStatus = status => {
		switch (status) {
			case "Unpublished":
				return context.t("_pending");
			case "Waiting List":
				return context.t("_waiting_list");

			default:
				return status;
		}
	}

	useEffect(() => {
		(async () => {
			dispatch(LoadingAction.showLoader())
			document.title = `${context.t("_studio56")} | ${context.t("_current_activities")}`
			await initSearchComponents();
			M.AutoInit();
			await getActivity();
			window.addEventListener('load', handleTabIndex());
			setTimeout(() => {
				document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
			}, 500);
		})()
	}, [])

	useEffect(() => {
		const filteredList = search.type === "All"
			? currentActivityList
			: currentActivityList.filter(data => data.field_event_type.toLowerCase() === search.type);

		setListActivity(filteredList);
	}, [search, currentActivityList]);
	return (
		<div id="main-content" className="activiti-list">
			<SlideToTop />
			<PageTitle title={context.t("_current_activities")} />
			<div className="container">
				<nav className="breadcrumb aside-breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
					<ul>
						<li className="breadcrumb-item">
							<Link to="/profile">{context.t("_profile")}</Link> {" "}

						</li>
						<li className="breadcrumb-item">
							<span>{context.t("_current_activities")} </span>

						</li>
					</ul>
				</nav>
				<Link className="activityLink" to="/activities">{context.t("_explore_activity_1")}</Link>
				<div className="clearfix"></div>
			</div>
			<div id="main-container">
				<div id="skipContent" className="container">
					<form onSubmit={filterFormSubmit} ref={myFormRef}>
						<div className="row topFormHolder">
							<div className="col s12 m6 l3">
								<div className={`input-field mobileSelect item ${lang}`}>
									<label className="active" style={dynamicStyles.position(lang)}>{context.t("_activity_type")}</label>
									<select className="browser-default" value={search.title} onChange={evt => handleChange("title", evt.target.value)}>
										<option value="" disabled >{context.t("_select_activity_type")}</option>
										<option value="All" >{context.t("_all")}</option>
										<option value="competition">{context.t("_competitions")}</option>
										<option value="session">{context.t("_sessions")}</option>
										<option value="event">{context.t("_events")}</option>
										<option value="challenge">{context.t("_challenges")}</option>
										<option value="project">{context.t("_projects")}</option>
									</select>
									<span className="helper-text"></span>
								</div>
							</div>

							<div className="col s12 m6 l3 date-popup">
								<div className="input-field item">
									<DatePicker
										name="start_date"
										placeholderText={context.t("_date")}
										id="start_date"
										autoComplete="off"
										dateFormat="yyyy-MM-dd"
										locale={calenderLocal(context)}
										customInput={<CustomDateInput ariaLabel={context.t("_select_date_button")} onFieldChange={date => handleChange("start_date", new Date(date))} />}
										onChange={date =>
											handleChange("start_date", date)
										}
										onCalendarOpen={e => {
											let elems = document.querySelector(".react-datepicker");
											elems.setAttribute("tabindex", "0")
											elems.focus()
											elems = document.querySelector(".react-datepicker__month-select");
											elems.setAttribute("aria-label", context.t("_select_month"))
											elems = document.querySelector(".react-datepicker__year-select");
											elems.setAttribute("aria-label", context.t("_select_year"))

										}}
										onCalendarClose={e => {
											var elems = document.querySelector("#start_date");
											elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(search.start_date, "dd-mm-yyyy")}`)
											elems.setAttribute("value", dateformat(search.start_date, "yyyy-mm-dd"))
											setTimeout(() => {
												elems.focus()
											}, 200);
										}}
										style={dynamicStyles.textAlign(lang)}
										value={search.start_date}
										selected={search.start_date}
										peekNextMonth
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
									/>
									<label htmlFor="start_date" style={dynamicStyles.position(lang)}>{context.t("_start_date")}</label>
								</div>
							</div>

							<div className="col s12 m6 l3 date-popup">
								<div className="input-field item">
									<DatePicker
										name="end_date"
										placeholderText={context.t("_date")}
										id="end_date"
										autoComplete="off"
										dateFormat="yyyy-MM-dd"
										locale={calenderLocal(context)}
										customInput={<CustomDateInput ariaLabel={context.t("_select_date_button")} onFieldChange={date => handleChange("end_date", new Date(date))} />}
										onChange={date =>
											handleChange("end_date", date)
										}
										onCalendarOpen={e => {
											let elems = document.querySelector(".react-datepicker");
											elems.setAttribute("tabindex", "0")
											elems.focus()
											elems = document.querySelector(".react-datepicker__month-select");
											elems.setAttribute("aria-label", context.t("_select_month"))
											elems = document.querySelector(".react-datepicker__year-select");
											elems.setAttribute("aria-label", context.t("_select_year"))

										}}
										onCalendarClose={e => {
											var elems = document.querySelector("#end_date");
											elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(search.end_date, "dd-mm-yyyy")}`)
											elems.setAttribute("value", dateformat(search.end_date, "yyyy-mm-dd"))
											setTimeout(() => {
												elems.focus()
											}, 200);
										}}
										style={dynamicStyles.textAlign(lang)}
										value={search.end_date}
										selected={search.end_date}
										peekNextMonth
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
									/>
									<label htmlFor="end_date" style={dynamicStyles.position(lang)}>{context.t("_end_date")}</label>
								</div>
							</div>

							<div className="col s12 m6 l3 valign-wrapper center-align search-btn-holder">
								<button className="btn noWidth profileBtn yellow waves-effect waves-light">
									{context.t("_search")} <i className={`tiny material-icons ${lang}`} >{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
								</button>
								<button type="button" onClick={() => clearSearch()} className="btn noWidth  blue-btn waves-effect waves-light">
									{context.t("_clear")}
								</button>
								<br /><br />
							</div>
						</div>
					</form>

					<div className="row">
						<div className="col s7 result-status">
							{isLoaded ? context.t("_search_result") + " ( " + (listActivity.length) + " of " + pager.count + " )" : ""}

						</div>
						<div className="col s5 result-type">
							<div className="input-field mobileSelect col s12 filter-select">
								<label htmlFor="result-type">{context.t("_select_activity")}</label>
								<select className="browser-default" id="result-type" value={search.type} onChange={evt => handleChange("type", evt.target.value)}>
									<option value="All" >{context.t("_all")}</option>
									<option value="competition">{context.t("_competitions")}</option>
									<option value="session">{context.t("_sessions")}</option>
									<option value="event">{context.t("_events")}</option>
									<option value="challenge">{context.t("_challenges")}</option>
									<option value="project">{context.t("_projects")}</option>
								</select>
							</div>
						</div>
					</div>
					<div className="activities">
						{listActivity.map((row, index) => (
							<div className="row" key={index}>
								<div className="col s7">
									<Link to={`/activity/${row.nid}`}>
										<h3 className="title">{row.title} <span className={row.field_event_type.toLowerCase()}>{row.field_event_type}</span></h3>
									</Link>

									<div className="details">
										{row.field_start_and_end_time &&
											<span><i className="calendar-icons"></i> {context.t("_start_date")} <span className="date">{row.field_start_and_end_time[0]}</span></span>
										}
										{row.field_start_and_end_time_1 &&
											<span><i className="calendar-icons"></i> {context.t("_end_date")} <span className="date">{row.field_start_and_end_time_1[row.field_start_and_end_time_1.length - 1]}</span></span>
										}
									</div>
								</div>
								<div className="col s5 btn-wrap flex-row justify-end align-center">
									{
										localStorage.getItem("role") == "volenteer" ?
											null :
											localStorage.getItem("role") == "student" ?
												<button onClick={() => handleOpenCancelActivityConfirm(row.nid)} className="btn blue-border">Cancel</button>
												:
												<button onClick={() => handleGetStudentList(row.nid)} className="btn blue-border">Enrollments</button>
									}

									{/* <button onClick={() => { handleDetailRedirect(row.nid) }} className="btn blue-border">Details</button> */}

								</div>
							</div>
						))}
						{!currentActivityList.length && isLoaded &&
							<div className="row"><p aria-label={context.t("_no_result_found")} className="col s12 no-result">{context.t("_no_result_found")}</p></div>
						}
					</div>
					<div id="enrollments" className="modal user-add-modal" role="dialog">
						<div className="modal-content">
							{/* <form onSubmit={handleAddNewStudent} className="col full-width"> */}
							<span className="badge modalClose"><a onClick={() => handleCloseModal()}><i className="material-icons">close</i></a></span>
							<h2 className="line">{context.t("_enrollments")}</h2>
							<div className="tableHolder">

								<table className="responsive-table highlight">
									<thead className="blue lighten-5">
										<tr>
											<th><strong>{context.t("_name")}</strong></th>
											<th><strong>{context.t("_qid")}</strong></th>
											<th><strong>{context.t("_phone_number")}</strong></th>
											<th><strong>{context.t("_grade")}</strong></th>
											<th><strong>{context.t("_gender")}</strong></th>
											<th><strong>{context.t("_age")}</strong></th>
											<th width="50"><strong><span style={{ display: "none" }}>status</span></strong></th>
										</tr>
									</thead>

									<tbody>
										{
											studentList.map((item, index) => {
												return (
													<tr key={index}>
														<td>{item.field_student_name}</td>
														<td>{item.field_qid}</td>
														<td>{item.field_student_mobile}</td>
														<td>{item.field_grade}</td>
														<td>{item.field_gender}</td>
														<td>{!_.isEmpty(item.field_student_dob) ? calculateAge(item.field_student_dob) : null}</td>
														<td>

															{
																item.field_published_status == "Published" ? <p className="checkboxHolder checkboxNew">
																	<input onChange={(e) => handleCheck(e, item.uid, index)} checked={checkedList[index] ? true : false} type="checkbox" />
																	<label>
																		<span></span>
																	</label></p> : showStatus(item.field_published_status)
															}


														</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</div>
							<button onClick={() => { handleCancelMultipleActivity() }} className="btn blue-border">Cancel</button>
							{/* </form> */}
							{/* {
									_.isEmpty(studentFormData) ?
										<div /> :
										<form onSubmit={handleAddNewStudent} className="col full-width">
											<span className="badge modalClose"><a onClick={() => handleCloseModal()}><i className="material-icons">close</i></a></span>
											<h2 className="line">{context.t("_add_student")}</h2>

											<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p>
											{hasError ? <div className="error-container fail">
												<h5>{context.t("_error_title")}</h5>
												<ul>
													{
														Object.keys(studentFormData).map((item, index) => {
															return studentFormData[item].error != "" ? <li key={index} ref={(refItem) => { this[item + "error"] = refItem; }}><label className="error" htmlFor={item}>{studentFormData[item].error}</label></li>
																:
																null
														})}
												</ul>
											</div> : null
											}
											<p>{context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

											<div className="row">
												<div className="col s12 m6">
													{renderInput({ name: "name", label: context.t("_student_name"), placeholder: context.t("_student_name_placeholder"), isMandatory: true })}
													{renderInput({ name: "email", label: context.t("_email"), placeholder: context.t("_email_placeholder"), type: "email" })}

												</div>
												<div className="col s12 m6">
													<div className="date-popup">
														{renderInput({ name: "dob", label: context.t("_dob"), placeholder: context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
													</div>

													{renderInput({ name: "mobile", label: context.t("_mobile_number"), placeholder: context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number" })}
												</div>
												<div className="col s12">
													<button className="btn profileBtn blue waves-effect waves-light">{context.t("_save_changes")}</button>
												</div>
												<div className="clearfix" />
											</div>
										</form>
								} */}
						</div>
					</div>
					<nav className="pagination-wrapper" aria-label="pagination">
						{(currentActivityList.length && isLoaded) ? (
							<Pagination
								prevPageText={`< ${context.t("_previous")}`}
								nextPageText={`${context.t("_next")} >`}
								hideFirstLastPages={true}
								hideDisabled={true}
								linkClassPrev="linkClassPrev"
								linkClassNext="linkClassNext"
								activePage={pager.current_page + 1}
								itemsCountPerPage={pager.items_per_page}
								totalItemsCount={pager.count}
								pageRangeDisplayed={5}
								onChange={handlePageChange}
							/>) : ""
						}
					</nav>
				</div>
				<div className="sparkles">
					<span className="orange-circle"></span>
					<span className="multi-square two"><b><i></i></b></span>
					<span className="grey-square-rotate red-sq one"></span>
					<span className="grey-square-rotate red-sq two"></span>
				</div>
			</div>
			<div className="top-line ng-scope">
				<img src={topLineImage} alt="" />
			</div>
			<Modal
				isOpen={cancelActivityConfirm}
				style={customModalStyles}
				contentLabel="unlink parent confirm modal"
				ariaHideApp={false}
			>
				<div className="popupAlert warning">
					<br />
					<h3>{context.t("_confirm")}</h3>
					<p>{context.t("_cancel_activity_confirm")}</p>
					<div className="row">
						<button className="col btn blue" onClick={() => handleCloseConfirmModal("cancelActivityConfirm")}>{context.t("_cancel")}</button>
						<button className="col btn profileBtn yellow" onClick={() => handleContinueCancelActivity()}>{context.t("_continue")}</button>
					</div>
				</div>
			</Modal>
		</div>
	)
}

ActivityHistory.contextTypes = {
	t: PropTypes.func
}

export default ActivityHistory
