import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"

const Students  = (props, context) => {
        const { enrollment_count, waitinglist_count, attendance_count, full = {}, type, zone, fieldType } = props;
        return (
            <div className="dashboardBox">
                <h2>{context.t("_students")}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>{context.t("_students_enrolled")}</td>
                            <td className="numberWidth">{enrollment_count}</td>
                        </tr>
                        <tr>
                            <td>{context.t("_students_attended")}</td>
                            <td>{attendance_count}</td>
                        </tr>
                        <tr>
                            <td>{context.t("_students_waiting_list")}</td>
                            <td>{waitinglist_count}</td>
                        </tr>
                        {
                            _.isEmpty(type) && _.isEmpty(zone) && _.isEmpty(fieldType) && <tr>
                                <td>{context.t("_new_registered_students")}</td>
                                <td>{full.total_students}</td>
                            </tr>

                        }

                    </tbody>
                </table>

            </div>
        )
    }

Students.defaultProps = {
    enrollment_count: 0,
    waitinglist_count: 0,
    attendance_count: 0,
    full: {
        total_students: 0
    }
}

Students.contextTypes = {
    t: PropTypes.func
}
export default Students;

// import React from "react"
// import Main from "../../main"
// import { connect } from "react-redux"
// import PropTypes from "prop-types"
// import _ from "lodash"
// // import { PopupAction, LoadingAction, StudentCountAction } from "./../../../actions"

// class Students extends Main {
//     constructor(props) {
//         super(props);
//     }
//     componentDidMount() {
//         // this.props.getStudentsEnrolled({});
//         // this.props.getStudentsWaitingList({})
//         // this.props.getStudentsAttended({})
//     }
//     render() {
//         const { enrollment_count, waitinglist_count, attendance_count, full = {}, type, zone, fieldType } = this.props;

//         return (
//             <div className="dashboardBox">
//                 <h2>{this.context.t("_students")}</h2>
//                 <table>
//                     <tbody>
//                         <tr>
//                             <td>{this.context.t("_students_enrolled")}</td>
//                             <td className="numberWidth">{enrollment_count}</td>
//                         </tr>
//                         <tr>
//                             <td>{this.context.t("_students_attended")}</td>
//                             <td>{attendance_count}</td>
//                         </tr>
//                         <tr>
//                             <td>{this.context.t("_students_waiting_list")}</td>
//                             <td>{waitinglist_count}</td>
//                         </tr>
//                         {
//                             _.isEmpty(type) && _.isEmpty(zone) && _.isEmpty(fieldType) && <tr>
//                                 <td>{this.context.t("_new_registered_students")}</td>
//                                 <td>{full.total_students}</td>
//                             </tr>

//                         }

//                     </tbody>
//                 </table>

//             </div>
//         )
//     }

// }
// Students.defaultProps = {
//     enrollment_count: 0,
//     waitinglist_count: 0,
//     attendance_count: 0,
//     full: {
//         total_students: 0
//     }
// }
// const mapStateToProps = ({ i18nState, studentCountReducer }) => {
//     return {
//         lang: i18nState.lang,
//         // studentsEnrolledCount: studentCountReducer.studentsEnrolledCount,
//         // studentsWaitingListCount: studentCountReducer.studentsWaitingListCount,
//         // studentsAttendedCount: studentCountReducer.studentsAttendedCount,
//     }
// }
// const mapDispatchToProps = {
//     // showPopup: (data) => PopupAction.showPopup(data),
//     // hidePopup: (data) => PopupAction.hidePopup(data),
//     // getStudentsEnrolled: StudentCountAction.getStudentsEnrolled,
//     // getStudentsWaitingList: StudentCountAction.getStudentsWaitingList,
//     // getStudentsAttended: StudentCountAction.getStudentsAttended,
//     // showLoader: LoadingAction.showLoader,
//     // hideLoader: LoadingAction.hideLoader,
// }
// Students.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(Students);