import { GET_PARENT_PROFILE_DETAILS } from "./../../constants/constants";

const initialState = {
    loading: false,
    profileData:{
      picture: "",
      name: ""
  }
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARENT_PROFILE_DETAILS:
      return {
        profileData: action.payload
      };


    default:
      return { ...state };
  }
};

export default profileReducer;