import React, { useState, useEffect } from "react";
import { Route, Navigate } from "react-router-dom";
import Main from "../../constants/utilities";
import Unauthorized from "../unauthorized";
import _ from "lodash";

const PrivateRoute = ({ children, roles = [] }) => {
	const { accessPermission } = Main()
	const role = localStorage.getItem("role")
	const [state, setState] = useState({
		loaded: false,
		permission: false,
		redirect: false,
	});

	useEffect(() => {
		const fetchData = async () => {
			const res = await accessPermission();
			setState(res);
		};

		fetchData();
	}, []);

	const { permission, redirect, loaded } = state;

	if (loaded) {
		if (!permission && !redirect) {
			return null;
		} else if (!permission && redirect) {
			return <Navigate to={redirect} />;
		} else if (permission) {
			if (_.isEmpty(roles)) {
				return children;
			} else {
				if (roles.includes(role)) return children;
				else return <Unauthorized />;
			}
		}
	}
	return null;
};
export default PrivateRoute;