import React, { useState, useEffect } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import Main from "../../constants/utilities"
import Pagination from "react-js-pagination";
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { LoadingAction } from "./../../actions"
import activity from "../../assets/images/activity-details.jpg"
import eventImage from "../../assets/images/session-test-image.jpg"

const DashboardActivityDetails = (props, context) => {
	const { config, networkRequest } = Main();
	const configure = config()
	const dispatch = useDispatch()
	const { lang } = useSelector((state) => state.i18nState);
	const [users, setUsers] = useState([]);
	const [completeUsers, setCompleteUsers] = useState([]);
	const [activityDetails, setActivityDetails] = useState({});
	const [pager, setPager] = useState({ count: 0, pages: 0, items_per_page: 0, current_page: 1 });

	const { id } = useParams()

	const getActivityDetails = async () => {
		let url = ""
		if (lang == "ar")
			url = `${configure.origin}${lang}/api/${urlEndPoints.activityDetails(id)}`
		else
			url = `${configure.origin}api/${urlEndPoints.activityDetails(id)}`
		try {
			const data = await networkRequest(url);
			if (data.data.error) {

			} else {
				const activityDetails = data.data[0];
				activityDetails.field_start_and_end_time_3 = activityDetails.field_start_and_end_time_3.split("*")
				activityDetails.field_start_and_end_time_4 = activityDetails.field_start_and_end_time_4.split("*")
				setActivityDetails(activityDetails)
			}
			dispatch(LoadingAction.hideLoader())
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}
	const getActivityUsers = async () => {
		const { match: { params: { id } } } = props;
		const url = `${configure.api_url}${urlEndPoints.getDashboardActivityStudents(id)}`
		try {
			const data = await networkRequest(url);
			if (data.data.error) {

			} else {
				setUsers(data.data.results)
				setPager(data.data.pager)
			}
			// props.hideLoader()
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}
	const getActivityCompleteUsers = async () => {
		const { match: { params: { id } } } = props;
		const url = `${configure.api_url}${urlEndPoints.getDashboardActivityCompleteStudent(id)}`
		try {
			const data = await networkRequest(url);
			if (data.data.error) {

			} else {
				setCompleteUsers(data.data)
			}
			// props.hideLoader()
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}
	const handlePrint = () => {
		return window.print();
	}
	useEffect(() => {
		(async () => {
			document.title = `${context.t("_studio56")} | ${context.t("_activity_details")}`
			dispatch(LoadingAction.showLoader())
			await getActivityDetails()
			await getActivityUsers()
			await getActivityCompleteUsers()
		})()
	}, []);
	return (
		<div id="main-content" >
			<div className="page-title en">
				<div className="container">
					<h1>{context.t("_activity_details")}</h1>
				</div>
			</div>
			<div className="container">
				<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
					<ul>
						<li className="breadcrumb-item">
							<Link to="/dashboard/activity">{context.t("_dashboard")}</Link> {" "}
						</li>
						<li className="breadcrumb-item">
							<Link tabIndex="0" to aria-current={context.t("_page")}>
								<span>{context.t("_activity_details")}</span>
							</Link>
						</li>
					</ul>
				</nav>
			</div>
			{
				_.isEmpty(activityDetails) ?
					<div /> :
					<div className="container activity-details">
						<div className="row" id="skipContent">
							<div className="col s12 export-button">
								<button onClick={() => handlePrint()} className="btn bgYellow waves-effect waves-light">{context.t("_export_activity_details")}</button>
							</div>

							<div className="col s12 content">
								<img className="activity-details-image" src={activity} alt="type image"></img>
								<div className="eventImage">
									<img style={{ marginTop: 0, }} src={eventImage} alt="type image"></img>
								</div>
								<h2 className="line">{activityDetails.eventname}</h2>
								<div dangerouslySetInnerHTML={{ __html: activityDetails.details }} />
							</div>

							<div className="col s12 profileInfo profileInfo--width">
								<ul>
									<li><span>{context.t("_age_group")}: </span>{activityDetails.age_group}</li>
									<li><span>{context.t("_zone")}:</span>{activityDetails.field_activity_zone}</li>
									<li><span>{context.t("_attended")}:</span>{activityDetails.attendees}</li>
									<li><span>{context.t("_no_of_volunteers")}:</span></li>
									<li><span>{context.t("_gender")}:</span>{activityDetails.gender}</li>
									<li><span>{context.t("_type")}:</span>{activityDetails.nothing}</li>
									<li><span>{context.t("_enrollments")}:</span></li>
									<li><span>{context.t("_trainer")}:</span>{activityDetails.field_trainer_name}</li>
									<li><span>{context.t("_activity_date")}:</span>{`${activityDetails.field_start_and_end_time_3[0]} - ${activityDetails.field_start_and_end_time_4[activityDetails.field_start_and_end_time_4.length - 1]}`}</li>
									<li><span>{`${context.t("_online")}/ ${context.t("_onsite")}`}:</span>Onsite</li>
									<li><span>{context.t("_absents")}: </span>8</li>
									<li><span>{context.t("_assistant")}: </span>Fatima</li>
									<li><span>{context.t("_zone_address")}: </span>Venue unavailable</li>
								</ul>
							</div>

						</div>
					</div>
			}
			<div className="container details-block">
				<div className="row">
					{users.length > 0 && <div className="col s12 tableHolder rmv-padding">
						<h3 tabIndex="0" aria-label={`${activityDetails.eventname} ${context.t("_student_details")}`} className="left">{context.t("_student_details")}</h3>
						<table className="responsive-table highlight">
							<thead className="blue lighten-5">
								<tr>
									<th><strong>{context.t("_students")}</strong></th>
									<th><strong>{context.t("_status")}</strong></th>
									<th><strong>{context.t("_attendance")}</strong></th>
								</tr>
							</thead>
							<tbody>
								{
									users.map((item, index) => {
										return (
											<tr key={index}>
												<td>{item.field_student}</td>
												<td>{item.field_published_status}</td>
												<td>{item.field_activity_attended}</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
						<nav className="pagination-wrapper" aria-label="pagination">
							<Pagination
								prevPageText={`< ${context.t("_previous")}`}
								nextPageText={`${context.t("_next")} >`}
								hideFirstLastPages={true}
								hideDisabled={true}
								linkClassPrev="linkClassPrev"
								linkClassNext="linkClassNext"
								activePage={pager.current_page + 1}
								itemsCountPerPage={pager.items_per_page}
								totalItemsCount={pager.count}
								pageRangeDisplayed={5}
							// onChange={handleStudentPageChange}
							/>
						</nav>
					</div>}
				</div>
			</div>
			{
				/**
				 * Print
				 */
			}
			<div className="container details-block">
				<div className="row">

					<div className="col s12 tableHolder list-print">
						<h3 className="left">{context.t("_student_details")}</h3>
						<table>
							<thead>
								<tr>
									<th><strong>{context.t("_students")}</strong></th>
									<th><strong>{context.t("_status")}</strong></th>
									<th><strong>{context.t("_attendance")}</strong></th>
								</tr>
							</thead>
							<tbody>
								{
									completeUsers.map(item => {
										return (
											<tr key={item.id}>
												<td>{item.field_student}</td>
												<td>{item.field_published_status}</td>
												<td>{item.field_activity_attended}</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</div>
	)
}

DashboardActivityDetails.contextTypes = {
	t: PropTypes.func
}
export default DashboardActivityDetails;