import React, { useState, useEffect } from "react";
import _ from "lodash";
import M from "materialize-css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";

import Main from "../../constants/utilities";
import PageTitle from "../includes/page_title";
import { LoadingAction } from "./../../actions";
import SlideToTop from "../includes/slide_to_top";
import { urlEndPoints } from "./../../httpAPI/apiConfig";

const Faq = (props, context) => {
    const { config, networkRequest } = Main();
    const configure = config()
    const dispatch = useDispatch()
    const [faqList, setFaqList] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [pager, setPager] = useState({ count: 0, pages: 0, items_per_page: 0, current_page: 1 })

    const { lang } = useSelector(
        (state) => state.i18nState
    );

    useEffect(() => {
        dispatch(LoadingAction.showLoader())
        M.AutoInit();
        getFaqList();
    }, [])

    const getFaqList = async () => {
        const url = `${configure.api_url}${urlEndPoints.getFaqs}`
        try {
            const data = await networkRequest(url)
            if (data.data.error) {

            } else {
                setFaqList(data.data.results)
                setIsLoaded(true)
            }
            dispatch(LoadingAction.hideLoader())
        } catch (error) {
            setIsLoaded(true)
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
    return (
        <div id="main-container">
            <SlideToTop />
            <PageTitle title={"Frequently Asked Questions (FAQ)"} />
            <div className="container">
                <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                    <ul>
                        <li className="breadcrumb-item">
                            <Link to="/">{`Home`}</Link> {" "}
                        </li>
                        <li className="breadcrumb-item">
                            <span>
                                FAQ
                            </span>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="container">

                <h2>We’re here to help you</h2>

                <div className="accodianHolder">
                    {isLoaded && faqList.length == 0 ? <div className="row"><p aria-label={context.t("_no_result_found")} tabIndex="0" className="col s12 no-result">{context.t("_no_result_found")}</p></div> :
                        <ul className="collapsible">
                            {
                                faqList.map(item => {
                                    return (<li key={item.nid}>
                                        <div className="collapsible-header">
                                            <p><strong>{item.field_faq_question}</strong></p>
                                        </div>
                                        <div className="collapsible-body" dangerouslySetInnerHTML={{ __html: item.field_faq_answer }}>
                                        </div>
                                    </li>)
                                })
                            }
                        </ul>}
                </div>

            </div>
            <nav className="pagination-wrapper" aria-label="pagination">
                <Pagination
                    prevPageText="< Previous"
                    nextPageText="Next >"
                    hideFirstLastPages={true}
                    hideDisabled={true}
                    linkClassPrev="linkClassPrev"
                    linkClassNext="linkClassNext"
                    activePage={pager.current_page + 1}
                    itemsCountPerPage={pager.items_per_page}
                    totalItemsCount={pager.count}
                    pageRangeDisplayed={5}
                // onChange={handlePageChange}
                />
            </nav>
        </div>
    );
}

Faq.contextTypes = {
    t: PropTypes.func
}
export default Faq;
