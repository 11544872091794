import React from "react";
import Main from "../../constants/utilities"
import PageTitle from "../includes/page_title";
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import SlideToTop from "../includes/slide_to_top";
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { PopupAction, LoadingAction } from "./../../actions"
import { useState } from "react";
import { useEffect } from "react";

// class Notification extends Main {
//     constructor(props) {
//         super(props);
//         this.state = {
//             config: this.config(),
//             notificationList: []
//         }
//         // LoadingAction.showLoader()
//     }
//     componentDidMount() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_notification")}`
//         this.getNotification()

//     }
//     componentDidUpdate() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_notification")}`
//     }
//     getNotification = async () => {
//         const { config } = this.state;
//         let url = "";
//         if (localStorage.getItem("role") == "student")
//             url = `${config.api_url}${urlEndPoints.notificationForStudent}`
//         if (localStorage.getItem("role") == "parent" || localStorage.getItem("role") == "school" || localStorage.getItem("role") == "volenteer")
//             url = `${config.api_url}${urlEndPoints.notificationForParent}`

//         try {
//             LoadingAction.showLoader()
//             const data = await this.networkRequest(url)
//             if (data.status == 200) {
//                 this.setState({
//                     notificationList: data.data.results,
//                     pager: data.data.pager
//                 })
//                 dispatch(LoadingAction.hideLoader())
//                 setTimeout(() => {
//                     document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
//                 }, 500);
//             }
//         } catch (error) {
//             this.dispatch(LoadingAction.hideLoader())
//             console.warn("error: ", error)
//         }
//     }
//     render() {
//         const { notificationList } = this.state;
//         const { lang } = this.props;
//         return (
//             <div id="main-container">
//                 <SlideToTop />
//                 <PageTitle id="notification-title" title={this.context.t("_notification")} />
//                 {/* <div id="breadcrumb-wrap">
//                     <div className="container">
//                         <Link to="/">{`Home`}</Link> \{" "}
//                         <span>
//                             What is Fab Lab
//                         </span>
//                     </div>
//                 </div> */}

//                 <div id="skipContent" className="container">
//                     <div className="notificationHolder">
//                         <ul>
//                             {
//                                 notificationList.map(item => {
//                                     return (
//                                         <li className="notificationBox green-border" tabIndex="0" key={item.nid}>
//                                             <div className="notificHeader">
//                                                 <h3>{item.title}</h3>
//                                                 <span className="notificDate"><strong>{item.created}</strong></span>
//                                             </div>
//                                             <div dangerouslySetInnerHTML={{ __html: lang == "en" ? item.field_action_message : item.field_action_arabic_message }} />
//                                         </li>
//                                     )

//                                 })
//                             }
//                             {
//                                 notificationList.length == 0 && <div tabIndex="0" aria-label={this.context.t("_no_notification")} style={{ textAlign: "center" }}>

//                                     <p >{this.context.t("_no_notification")}</p>

//                                 </div>
//                             }
//                         </ul>
//                     </div>
//                 </div>
//                 <br />
//             </div>
//         );
//     }
// }

// const mapStateToProps = ({ i18nState }) => {
//     return {
//         lang: i18nState.lang
//     }
// }
// const mapDispatchToProps = {
//     showPopup: (data) => PopupAction.showPopup(data),
//     hidePopup: (data) => PopupAction.hidePopup(data),
//     showLoader: LoadingAction.showLoader,
//     hideLoader: LoadingAction.hideLoader,
// }
// Notification.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(Notification);


const Notification = (props, context) => {
    const [notificationList, setNotificationList] = useState([])
    const [pager, setPager] = useState({})
    const { config, networkRequest } = Main()
    const configure = config()
    const dispatch = useDispatch()
    const { lang } = useSelector(state => state.i18nState)

    const getNotification = async () => {
        let url = "";
        if (localStorage.getItem("role") == "student")
            url = `${configure.api_url}${urlEndPoints.notificationForStudent}`
        if (localStorage.getItem("role") == "parent" || localStorage.getItem("role") == "school" || localStorage.getItem("role") == "volenteer")
            url = `${configure.api_url}${urlEndPoints.notificationForParent}`

        try {
            dispatch(LoadingAction.showLoader())
            const data = await networkRequest(url)
            if (data.status == 200) {
                setNotificationList(data.data.results)
                setPager(data.data.pager)
                dispatch(LoadingAction.hideLoader())
                setTimeout(() => {
                    document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
                }, 500);
            }
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
    useEffect(() => {
        getNotification()
    }, [])
    return (
        <div id="main-container">
            <SlideToTop />
            <PageTitle id="notification-title" title={context.t("_notification")} />
            {/* <div id="breadcrumb-wrap">
                    <div className="container">
                        <Link to="/">{`Home`}</Link> \{" "}
                        <span>
                            What is Fab Lab
                        </span>
                    </div>
                </div> */}

            <div id="skipContent" className="container">
                <div className="notificationHolder">
                    <ul>
                        {
                            notificationList?.map(item => {
                                return (
                                    <li className="notificationBox green-border" tabIndex="0" key={item.nid}>
                                        <div className="notificHeader">
                                            <h3>{item.title}</h3>
                                            <span className="notificDate"><strong>{item.created}</strong></span>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: lang == "en" ? item.field_action_message : item.field_action_arabic_message }} />
                                    </li>
                                )

                            })
                        }
                        {
                            notificationList?.length == 0 && <div tabIndex="0" aria-label={context.t("_no_notification")} style={{ textAlign: "center" }}>

                                <p >{context.t("_no_notification")}</p>

                            </div>
                        }
                    </ul>
                </div>
            </div>
            <br />
        </div>
    )
}

Notification.contextTypes = {
    t: PropTypes.func
}

export default Notification
