import React, { Component, useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import { withRouter } from "../../constants/common"
import { useLocation, useNavigate } from "react-router-dom"

const RegisterTypes = (props, context) => {
    const [selectedRole, setSelectedRole] = useState("")
    const { lang } = useSelector(state => state.i18nState)
    const navigate = useNavigate()
    const location = useLocation()
    const changeRole = selectedRole => {
        navigate(`/registration/${selectedRole}`);
    };
    const onNextPress = () => {
        navigate(`/registration/${selectedRole}`);
    };
    useEffect(() => {
        setSelectedRole(location?.pathname?.split("/").pop())
        setTimeout(() => {
            document.getElementById("pageHeading") && document.getElementById("pageHeading").focus()
        }, 500);
    }, [])
    return (
        <div className="registration-container">
            <h2 id="registration-title" className="primary-heading" style={dynamicStyles.textAlign(lang)}>{context.t("_are_you")}</h2>
            <div className="btn-wrap type-selection" id="skipContent">
                <button aria-label={`${context.t("_are_you_1")} ${context.t("_parent")}`} role="button" className={selectedRole === "parent" ? "btn active" : "btn"} onClick={() => changeRole("parent")} > {context.t("_parent")} </button>
                <button aria-label={`${context.t("_are_you_1")} ${context.t("_student")}`}
                    className={
                        selectedRole === "student" ? "btn active" : "btn"
                    }
                    onClick={() => { changeRole("student") }}
                >
                    {context.t("_student")}
                </button>
                <button aria-label={`${context.t("_are_you_1")} ${context.t("_university_student")}`}
                    className={
                        selectedRole === "university-student" ? "btn active" : "btn"
                    }
                    onClick={() => changeRole("university-student")}
                >
                    {context.t("_university_student")}
                </button>
                <button aria-label={`${context.t("_are_you_1")} ${context.t("_school")}`}
                    className={
                        selectedRole === "school" ? "btn active" : "btn"
                    }
                    onClick={() => changeRole("school")}
                >
                    {context.t("_school")}
                </button>
                <button aria-label={`${context.t("_are_you_1")} ${context.t("_volunteer")}`}
                    className={
                        selectedRole === "volunteer" ? "btn active" : "btn"
                    }
                    onClick={() => changeRole("volunteer")}
                >
                    {context.t("_volunteer")}
                </button>
            </div>
            {/* <div className="btn-wrap">
              <button className="btn" onClick={() => this.onNextPress()}>
                Next <i className="material-icons">arrow_forward</i>
              </button>
            </div> */}
        </div>
    )
}

RegisterTypes.contextTypes = {
    t: PropTypes.func
}

export default RegisterTypes