import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux"

import Main from "../../constants/utilities"
import PageTitle from "../includes/page_title";
import { LoadingAction } from "./../../actions";
import SlideToTop from "../includes/slide_to_top";
import { urlEndPoints } from "./../../httpAPI/apiConfig"

const Announcements = (props, context) => {
    const { config, networkRequest } = Main();
    const configure = config()
    const dispatch = useDispatch()
    const [isLoaded, setIsLoaded] = useState(false)
    const [announcementList, setAnnouncementList] = useState([])
    const [pager, setPager] = useState({ count: 0, pages: 0, items_per_page: 0, current_page: 1 })

    useEffect(() => {
        getAnnouncements();
    }, [])

    const { lang } = useSelector(
		(state) => state.i18nState
	);

    const getAnnouncements = async () => {
        const url = `${configure.api_url}${urlEndPoints.getAnnouncement}`
        try {
            const data = await networkRequest(url)
            if (data.data.error) {

            } else {
                setIsLoaded(true)
                setPager(data.data.pager)
                setAnnouncementList(data.data.results)
            }
            dispatch(LoadingAction.hideLoader())
        } catch (error) {
            setIsLoaded(true)
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
    }
    return (
        <div id="main-container">
            <SlideToTop />
            <PageTitle title={`Announcements`} />
            <div className="container">
                <h2>Title</h2>
                {isLoaded && announcementList.length == 0 ? <div className="row"><p aria-label={context.t("_no_result_found")} tabIndex="0" className="col s12 no-result">{context.t("_no_result_found")}</p></div> :
                    announcementList.map(item => {
                        return (
                            <div className="row noFlex announcementBox" key={item.nid}>
                                <div className="col s12 m12 l12 push-xl6 xl6 "><img src={`${configure.origin}${item.field_announcement_image}`} alt="" /></div>
                                <div className="col s12 m12 l12 pull-xl6 xl6 ">
                                    <h3>{item.title}</h3>
                                    <span className="dateHolder"><i className="fa fa-calendar-o"></i><strong>Start Date</strong> {item.field_announcement_start_date} </span>
                                    <span className="dateHolder"><i className="fa fa-calendar-o"></i><strong>End Date</strong> {item.field_announcement_end_date} </span>
                                    <div className="textHolder" dangerouslySetInnerHTML={{ __html: item.body }}>
                                    </div>
                                    <button className="btn profileBtn none waves-effect waves-yellow"><strong>Click here</strong></button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <nav className="pagination-wrapper" aria-label="announcement">
                <Pagination
                    prevPageText="< Previous"
                    nextPageText="Next >"
                    hideFirstLastPages={true}
                    linkClassPrev="linkClassPrev"
                    linkClassNext="linkClassNext"
                    activePage={pager.current_page + 1}
                    itemsCountPerPage={pager.items_per_page}
                    totalItemsCount={pager.count}
                    pageRangeDisplayed={5}
                // onChange={this.handlePageChange}
                />
            </nav>
        </div>
    );
}

Announcements.contextTypes = {
    t: PropTypes.func
}
export default Announcements;
