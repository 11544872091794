import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

export const SlideToTop = (props, context) => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const { lang } = useSelector(
    (state) => state.i18nState
);

  const scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" })
  }

  if (props.button) {
    return (<button onClick={() => { scrollToTop(); }} className={`scroll-top ${lang === "en" ? "scroll-top-en" : "scroll-top-ar"}`}>{context.t("_scroll_to_top")}</button>)
  }
  else {
    return null
  }
}

SlideToTop.contextTypes = {
  t: PropTypes.func
}
export default SlideToTop;