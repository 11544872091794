import React, { useState, useEffect } from "react"
import _ from "lodash"
import M from "materialize-css";
import Popup from "reactjs-popup";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import Main from "../../constants/utilities"
import { LoadingAction } from "./../../actions"
import PageTitle from "../includes/page_title.js"
import SlideToTop from "../includes/slide_to_top.js"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import subscribeRightImage from "../../assets/images/subscribe-right.png"
import { nameWordLengthCheck, nameValidate, emailValidate, phoneValidate } from "./../../constants/common"
import topLineImage from "../../assets/images/top-line.png";

const axios = require("axios");
let contact_form = {
	name: { value: "", error: "", class: "" },
	agree: { value: false, error: "", class: "" },
	other_text: { value: "", error: "", class: "" },
	email_address: { value: "", error: "", class: "" },
	mobile_number: { value: "", error: "", class: "" },
	best_describe: { value: "schoolrepresent", error: "", class: "" },

};
const Subscribe = (props, context) => {
	const { config, networkRequest } = Main();
	const configure = config()
	const navigate = useNavigate();
    const dispatch = useDispatch();
	const [isLoaded, setIsLoaded] = useState(false)
	const [contactForm, setContactForm] = useState(contact_form)
	const [privacyPolicyBody, setPrivacyPolicyBody] = useState("")
	const [submissionStatus, setSubmissionStatus] = useState(false)
	const [privacyPolicyTitle, setPrivacyPolicyTitle] = useState("")

	const { lang } = useSelector(
		(state) => state.i18nState
	);

	useEffect(() => {
		M.AutoInit();
		getPrivacyPolicy()
		setTimeout(() => {
			document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
		}, 500);
	}, [])

	const getPrivacyPolicy = async () => {
		let url = ""
		if (lang == "ar")
			url = `${configure.origin}${lang}/${urlEndPoints.getPrivacyPolicy}`
		else
			url = `${configure.origin}${urlEndPoints.getPrivacyPolicy}`

		try {
			const data = await networkRequest(url)
			if (data.status == 200 && data.data && data.data.length > 0) {
				setPrivacyPolicyBody(data.data[0].body)
				setPrivacyPolicyTitle(data.data[0].title)
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}

	}
	const scrollToTop = () => {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
		// var delayInMs = props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
		// let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
		// this.setState({ intervalId: intervalId });
	}
	const handleChange = (field, value) => {
		// <Redirect to="/" push={true} />
		// let history = useHistory();	
		if (field == "mobile_number")
			if (isNaN(value))
				return false
		const updatedContactForm = { ...contactForm };

		switch (field) {
			case "email_address":
				var emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
				updatedContactForm[field]["class"] = emailValid ? "" : "invalid";
				updatedContactForm[field]["value"] = value;
				break;
			case "best_describe":
				updatedContactForm[field]["value"] = value;
				updatedContactForm["other_text"]["value"] = "";
				break;
			default:
				updatedContactForm[field]["class"] = value ? "" : "invalid";
				updatedContactForm[field]["value"] = value;
				break;
		}
		// var submit = true;
		// for (var key in contact_form) {
		// 	var data = contact_form[key];
		// 	if ((key === "iagree" && !data) ||
		// 		(data.hasOwnProperty("value") && key !== "other_text" && (data["class"] || !data["value"])) ||
		// 		(key === "other_text" && contact_form.best_describe.value === "_other_" && (data["class"] || !data["value"]))

		// 	) {
		// 		submit = false;
		// 	}
		// }
		// contact_form.submit = submit;
		setContactForm(updatedContactForm);
		setSubmissionStatus(false);
	}
	const validate = () => {
        const updatedContactForm = { ...contactForm };
		for (var key in contact_form) {
			updatedContactForm[key].value = typeof (updatedContactForm[key].value) == "boolean" ? updatedContactForm[key].value : updatedContactForm[key].value.trim();
			updatedContactForm[key].error = "";
		}

		if (updatedContactForm.name.value == "")
			updatedContactForm.name.error = context.t("_name_required");

		if (updatedContactForm.name.value != "" && !nameValidate.test(updatedContactForm.name.value))
			updatedContactForm.name.error = context.t("_invalid_name");

		if (updatedContactForm.name.value != "" && nameValidate.test(updatedContactForm.name.value) && !nameWordLengthCheck(updatedContactForm.name.value))
			updatedContactForm.name.error = context.t("_name_contain_atleast_two_words");

		if (updatedContactForm.email_address.value == "")
			updatedContactForm.email_address.error = context.t("_email_required");

		if (updatedContactForm.email_address.value != "" && !emailValidate.test(updatedContactForm.email_address.value))
			updatedContactForm.email_address.error = context.t("_invalid_email");

		if (updatedContactForm.mobile_number.value == "")
			updatedContactForm.mobile_number.error = context.t("_mobile_number_required");

		if (updatedContactForm.mobile_number.value != "" && !phoneValidate.test(updatedContactForm.mobile_number.value.replace(/ /g, "")))
			updatedContactForm.mobile_number.error = context.t("_invalid_mobile_number");

		if (updatedContactForm.mobile_number.value != "")
			if (updatedContactForm.mobile_number.value.toString().startsWith("3") || updatedContactForm.mobile_number.value.toString().startsWith("5") || updatedContactForm.mobile_number.value.toString().startsWith("6") || updatedContactForm.mobile_number.value.toString().startsWith("7")) {

			} else {
				updatedContactForm.mobile_number.error = context.t("_invalid_mobile_number");
			}

		if (updatedContactForm.best_describe.value == "_other_" && updatedContactForm.other_text.value == "")
			updatedContactForm.best_describe.error = context.t("_other_description_required");

		if (!(updatedContactForm.agree.value))
			updatedContactForm.agree.error = context.t("_terms_and_condition_agree");

		for (var key in updatedContactForm) {
			if (updatedContactForm[key].error != "") {
				setContactForm(updatedContactForm)
				setSubmissionStatus(false)
				scrollToTop();
				setTimeout(() => {
					document.getElementById(key + "error") && document.getElementById(key + "error").focus();
				}, 1000);
				return false;
			}
		}
		setContactForm(updatedContactForm)
		return true;

	}
	const onSubmit = (event) => {
		// const data = new FormData(event.target);
		event.preventDefault();
		if (validate()) {
			dispatch(LoadingAction.showLoader())
			var self = this;
			var submit_data = {};
			submit_data["terms_of_service"] = "1";
			submit_data["best_describe_other"] = "";
			submit_data["webform_id"] = "subscribe";
			for (var key in contactForm) {
				var data = contactForm[key];
				if (key === "other_text") {
					submit_data["best_describe_other"] = data.value;
				}
				else if (data.hasOwnProperty("value")) {
					submit_data[key] = data.value;
				}
			}

			axios.get(configure.origin + "session/token/", {
				params: {
					_format: "json"
				}
			})
				.then(function (response) {
					var headers = {};
					if (configure.environment === "production") {
						headers = {
							"Content-Type": "application/json",
							"Access-Control-Allow-Origin": "*",
							"Accept": "application/json",
							"X-CSRF-Token": response.data
						};
					}
					axios.post(configure.origin + "webform_rest/submit?_format=json", submit_data, {
						headers: headers
					})
						.then(function (response) {
							dispatch(LoadingAction.hideLoader())
							navigate("/thankyou");
						})
						.catch(function (error) {
							dispatch(LoadingAction.hideLoader())
							console.warn(error);
						});

				})
				.catch(function (error) {
					dispatch(LoadingAction.hideLoader())
					console.warn(error);
				});
		}
	}

	const handleCloseModal = () => {
		let elems = document.querySelector("#modal1");
		elems.removeAttribute("aria-hidden")
		elems.removeAttribute("tabindex")
		let instance = M.Modal.getInstance(elems);
		instance.close();
	}
	const handleOpenModal = () => {
		let elems = document.querySelector("#modal1");
		elems.setAttribute("aria-hidden", "false")
		elems.setAttribute("tabindex", "-1")
		let instance = M.Modal.getInstance(elems);
		instance.open();
	}

	let hasError = false;
	Object.keys(contactForm).map((item, index) => {
		if (contactForm[item].error != "")
			hasError = true
	})
	return (
		<div id="main-content">
			<SlideToTop />
			<PageTitle title={context.t("_subscribe")} isDashboardItem={true} />
			<div className="container">
				<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
					<ul>
						<li className="breadcrumb-item">
							<Link to="/">{context.t("_home")}</Link>  {" "}

						</li>
						<li className="breadcrumb-item">
							<Link tabIndex="0" to aria-current={context.t("_page")}>
								<span>{context.t("_subscribe")}</span>
							</Link>
						</li>
					</ul>
				</nav>
			</div>
			<div id="main-container">
				<div id="skipContent" className="container">
					<div className="row col-2">
						<div className="item subscribe-form">
							<h2 style={{ marginBottom: 10 }}>{context.t("_subscribe")}</h2>
							<p>{context.t("_subscribe_heading_2")}</p>
							{
								(hasError && !submissionStatus) ? <div className="error-container fail">
									<h5>{context.t("_error_title")}</h5>
									<ul aria-label="error" >
										{
											Object.keys(contactForm).map((item, index) => {
												return contactForm[item].error != "" ? <li key={index} ><a  tabIndex="0" href={`#${item}`} className="error" htmlFor={item}>{contactForm[item].error}</a></li>
													:
													null
											})}
									</ul>
								</div> : null

							}
							<form name="subscribe" onSubmit={onSubmit} className="subscribe-form">
								<div className="row col-2">
									<div className="input-field item">
										<input name="name"
											placeholder={context.t("_name")}
											onChange={evt => handleChange("name", evt.target.value)}
											onFocus={evt => handleChange("name", evt.target.value)}
											value={contactForm.name.value}
											id="name"
											type="text"
											for="name"
										// className={"validate " + contactForm.name.class}
										// required
										/>
										<label for="name" style={dynamicStyles.position(lang)} htmlFor="name">{context.t("_name")}</label>
										<span className="helper-text" data-error="Required field."></span>
									</div>
									<div className="input-field item">
										<input name="email_address"
											placeholder={context.t("_email")}
											onChange={evt => handleChange("email_address", evt.target.value)}
											onFocus={evt => handleChange("email_address", evt.target.value)}
											id="email_address"
											for="email_address"
											type="email"
											value={contactForm.email_address.value}
										// className={"validate " + contactForm.email_address.class}
										// required
										/>
										<label for="email_address" style={dynamicStyles.position(lang)} htmlFor="email">{context.t("_email")}</label>
										<span className="helper-text" data-error="Please enter a valid email address"></span>
									</div>
								</div>
								<div className="row col-2">
									<div className="input-field item">
										<input name="mobile_number"
											placeholder={context.t("_mobile_number")}
											onChange={evt => handleChange("mobile_number", evt.target.value)}
											onFocus={evt => handleChange("mobile_number", evt.target.value)}
											id="mobile_number"
											for="mobile_number"
											type="text"
											value={contactForm.mobile_number.value}
											maxLength={8}
										// className={"validate " + contactForm.mobile_number.class}
										// required
										/>
										<label for="mobile_number" style={dynamicStyles.position(lang)} htmlFor="mobile_number">{context.t("_mobile_number")}</label>
										<span className="helper-text" data-error="Required field"></span>
									</div>
								</div>
								<div className="form-check">
									<div className="text-item">
										<h4 id="describe-title">{context.t("_describe_your_self")}</h4>
									</div>
									<div className="input-field  subscribe-items">
										<div style={{ margin: 0 }}>
											<div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={context.t("_describe_your_self")}>
												<div>
													<input
														aria-checked={contactForm.best_describe.value === "schoolrepresent"}
														name="schoolrepresent"
														id="schoolrepresent"
														aria-labelledby="describe-title sub-rep"
														type="radio"
														readOnly
														className="radio-btn"
														onClick={evt =>
															handleChange("best_describe", "schoolrepresent")
														}
														checked={contactForm.best_describe.value === "schoolrepresent"}
													/>
													<label id='sub-rep' htmlFor="schoolrepresent" className="custom-radio-style">
														<span className="custom-radio-style">{context.t("_school_representative")}</span>
													</label>
												</div>
											</div>
											<div style={{ margin: 0 }}>
												<div>
													<input
														aria-checked={contactForm.best_describe.value === "Parents"}
														name="Parents"
														id="Parents"
														aria-labelledby="describe-title sub-parent"
														type="radio"
														readOnly
														className="radio-btn"
														onClick={evt =>
															handleChange("best_describe", "Parents")
														}
														checked={contactForm.best_describe.value === "Parents"}
													/>
													<label id='sub-parent' htmlFor="Parents" className="custom-radio-style">
														<span className="custom-radio-style">{context.t("_parents_1")}</span>
													</label>
												</div>
											</div>
											<div style={{ margin: 0 }}>
												<div>
													<input
														aria-checked={contactForm.best_describe.value === "Student"}
														name="Student"
														id="Student"
														aria-labelledby="describe-title sub-student"
														type="radio"
														readOnly
														className="radio-btn"
														onClick={evt =>
															handleChange("best_describe", "Student")
														}
														checked={contactForm.best_describe.value === "Student"}
													/>
													<label id='sub-student' htmlFor="Student" className="custom-radio-style">
														<span className="custom-radio-style">{context.t("_student718")}</span>
													</label>
												</div>
											</div>
											<div style={{ margin: 0 }}>
												<div>
													<input
														aria-checked={contactForm.best_describe.value === "_other_"}
														name="_other_"
														id="_other_"
														aria-labelledby="describe-title sub-notlist"
														type="radio"
														readOnly
														className="radio-btn"
														onClick={evt =>
															handleChange("best_describe", "_other_")
														}
														checked={contactForm.best_describe.value === "_other_"}
													/>
													<label id='sub-notlist' htmlFor="_other_" className="custom-radio-style">
														<span className="custom-radio-style">{context.t("_not_in_list")}</span>
													</label>
												</div>
											</div>
										</div>

									</div>
									{contactForm.best_describe.value === "_other_" &&
										<div id="other-field" className="input-field item">
											<input name="other_text"
												aria-labelledby="describe-title dec-notlist"
												onChange={evt => handleChange("other_text", evt.target.value)}
												onFocus={evt => handleChange("other_text", evt.target.value)}
												id="other_text"
												placeholder={context.t("_not_in_list")}
												type="text"
											// className={"validate " + contactForm.other_text.class}
											// required
											/>
											<span className="helper-text" data-error="Required field"></span>
										</div>
									}
									<input type="hidden" defaultValue={contactForm.webform_id} name="webform_id" />
									<div className="input-check subscribe-items checkboxNew">
										<div className="item">
											<p>
												<input type="checkbox"
													name="agree"
													id="agree"
													tabIndex="0"
													aria-label={`${context.t("_i_agree_1")} ${context.t("_privacy_policy")}`}
													style={dynamicStyles.textAlign(lang)}
													// ref={(input) => { this[name] = input; }}
													onChange={event => handleChange("agree", event.target.checked)}
													checked={contactForm.agree.value}
												/>
												<label for='agree' id="desc-agree">
													<span className={lang}>{context.t("_i_agree")} <u><a className="modal-trigger" onClick={() => handleOpenModal()}>{context.t("_privacy_policy")}</a></u></span>
												</label>
											</p>
										</div>
									</div>
								</div>

								<div className="btn-wrap">
									<button className="btn blue-btn  subscribe" style={{ margin: "0 0 5px 0", textTransform: "uppercase" }}>
										{context.t("_subscribe")} <i className={`material-icons ${lang}`}>{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
									</button>
								</div>
								<div id="modal1" className="modal" role="dialog">
									<div className="modal-content">
										<span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => handleCloseModal()}><i className="material-icons">close</i></button></span>
										<div><h3 dangerouslySetInnerHTML={{ __html: privacyPolicyTitle }}></h3></div>
										<div dangerouslySetInnerHTML={{ __html: privacyPolicyBody }}>
										</div>
									</div>
									<div className="modal-footer">
										<a href="#!" className="modal-close waves-effect waves-green btn-flat">{context.t("_ok")}</a>
									</div>
								</div>
							</form>
						</div>
						<div className="item">
							<img src={subscribeRightImage} alt="" />
						</div>
					</div>
				</div>
			</div>
			<div className="top-line ng-scope">
				<img src={topLineImage} alt="" />
			</div>
		</div>
	)
}

Subscribe.contextTypes = {
	t: PropTypes.func
}
export default Subscribe;
