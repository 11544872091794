import React, { useEffect } from "react"
import M from "materialize-css"
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import _ from "lodash"
import { Link, useParams } from "react-router-dom"

import Main from "../../constants/utilities"
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";
import { LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { useState } from "react"

const MachineDetail = (props, context) => {
    const [machineDetails, setMachineDetails] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)
    const [currentTab, setCurrentTab] = useState("feature")
    const { config, networkRequest } = Main()
    const configure = config()
    const dispatch = useDispatch()
    const { lang } = useSelector(state => state.i18nState)
    const { id } = useParams()

    const getMachineDetails = async () => {
        const url = `${configure.origin}${urlEndPoints.getMachineDetails(id)}`
        try {
            dispatch(LoadingAction.showLoader())
            const data = await networkRequest(url)
            if (data.data.error) {

            } else {
                setMachineDetails(data.data[0])
                setTimeout(() => {
                    document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
                }, 500);
            }

            M.AutoInit()
            dispatch(LoadingAction.hideLoader())
        } catch (error) {
            setIsLoaded(true)
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }

    }
    useEffect(() => {
        document.title = `${context.t("_studio56")} | ${context.t("_machines")}`
        getMachineDetails()
    }, [])
    return (
        <div id="main-container">
            <SlideToTop />
            <PageTitle title={context.t("_machines")} isDashboardItem={true} />
            <div className="container">
                <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                    <ul>
                        <li className="breadcrumb-item">
                            <Link to="/machines">{context.t("_machines")}</Link>{" "}
                        </li>
                        <li className="breadcrumb-item">
                            <Link tabIndex="0" to aria-current={context.t("_page")}>
                                <span dangerouslySetInnerHTML={{ __html: machineDetails.title }}></span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
            {
                !_.isEmpty(machineDetails) && <div id="skipContent" className="container">
                    <h2 className="line" dangerouslySetInnerHTML={{ __html: machineDetails.title }}></h2>

                    <div className="row machine-detail-holder">
                        <div className="col m12 l3">
                            <figure className="machine-detail-figure">
                                <img src={`${configure.origin}${machineDetails.field_machines_image}`} alt="" />
                            </figure>
                        </div>

                        <div className="col m12 l9 machine-detail-content">
                            <h4>{machineDetails.field_sub_title} <span>{machineDetails.field_machine_type}</span></h4>
                            <h5 className="blue-text">{machineDetails.field_machine_brand}</h5>
                            <div dangerouslySetInnerHTML={{ __html: machineDetails.body }}></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col s12">
                            <ul className="tabs machine-tab-menu">
                                <li onClick={() => setCurrentTab("feature")} className="tab"><a className={currentTab === "feature" ? "active" : ""} href="#feature">{context.t("_feature")}</a></li>
                                <li onClick={() => setCurrentTab("materials")} className="tab"><a className={currentTab === "materials" ? "active" : ""} href="#materials">{context.t("_materials")}</a></li>
                                <li onClick={() => setCurrentTab("safety_procedure")} className="tab"><a className={currentTab === "safety_procedure" && "active"} href="#safety_procedure">{context.t("_safety_procedure")}</a></li>
                            </ul>
                        </div>

                        {currentTab === "feature" && <div id="feature" className="col s12 machine-tab-content" dangerouslySetInnerHTML={{ __html: machineDetails.field_machine_features }} />}
                        {currentTab === "materials" && <div id="materials" className="col s12 machine-tab-content" dangerouslySetInnerHTML={{ __html: machineDetails.field_machine_materials }} />}
                        {currentTab === "safety_procedure" && <div id="safety_procedure" className="col s12 machine-tab-content" dangerouslySetInnerHTML={{ __html: machineDetails.field_machine_safety_procedure }} />}
                    </div>
                    {/* <div className="row">
                        <div className="col s12">
                            <ul className="tabs machine-tab-menu">
                                <li className="tab"><a className="active" href="#feature">{context.t("_feature")}</a></li>
                                <li className="tab"><a href="#materials">{context.t("_materials")}</a></li>
                                <li className="tab"><a href="#safety_procedure">{context.t("_safety_procedure")}</a></li>
                            </ul>
                        </div>

                        <div id="feature" className="col s12 machine-tab-content" dangerouslySetInnerHTML={{ __html: machineDetails.field_machine_features }} />
                        <div id="materials" className="col s12 machine-tab-content" dangerouslySetInnerHTML={{ __html: machineDetails.field_machine_materials }} />
                        <div id="safety_procedure" className="col s12 machine-tab-content" dangerouslySetInnerHTML={{ __html: machineDetails.field_machine_safety_procedure }} />
                    </div> */}
                </div>
            }

        </div>
    )
}

MachineDetail.contextTypes = {
    t: PropTypes.func
}

export default MachineDetail
