
import React, { useState } from "react"
import Main from "../../constants/utilities"
import SlideToTop from "../includes/slide_to_top.js"
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import PageTitle from "../includes/page_title.js"
import CompetitionDetails from "./competition_details"
import EventDetails from "./event_details"
import SessionDetails from "./session_details"
import { Link, useParams } from "react-router-dom"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { PopupAction, LoadingAction } from "./../../actions"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import _ from "lodash"
import topLineImage from "../../assets/images/top-line.png"
import axios from "axios"
import { useEffect } from "react"


const ActivityDetails= (props, context) =>{

	const { config, networkRequest } = Main();

	const [isLoaded, setIsLoaded] = useState(false)
	const [configuration, setConfiguration] = useState(config())
	const [result, setResult] = useState([])
	const [userActivityList, setUserActivityList] = useState([])
	const [page_title,setPageTitle]=useState("")

	const { lang } = useSelector((state) => state.i18nState)
	const dispatch = useDispatch()
	
    const {id}=useParams()


useEffect(() => {

	(async () => {
		dispatch(LoadingAction.showLoader())
		document.title = `${context.t("_studio56")} |`

		if (localStorage.getItem("role") == "student")
			await checkStudentEnrolled();
		let url = ""
		if (lang == "ar")
			url = `${configuration.origin}${lang}/api/${urlEndPoints.activityDetails(id)}`
		else
			url = `${configuration.origin}api/${urlEndPoints.activityDetails(id)}`

		axios.get(url, {
			params: {
				_format: "json"
			}
		}).then(function (response) {
			var type = response.data[0]["field_event_type"];
			let page_title;
			if (type == "event") {
				page_title = context.t("_event_details")
			}
			if (type == "competition") {
				page_title = context.t("_competitions")
			}
			if (type == "session") {
				page_title = context.t("_session_details")
			}
			if (type == "project") {
				page_title = context.t("_project_details")
			}
			if (type == "challenge") {
				page_title = context.t("_challenge_details")
			}
			document.title = `${context.t("_studio56")} | ${response.data[0]?.eventname}`
			const result = response.data[0];
			result.field_start_and_end_time_2 = result.field_start_and_end_time_2.split("*")
			result.field_start_and_end_time_2 = result.field_start_and_end_time_2.map(date => new Date(date))
			result.field_start_and_end_time_2 = new Date(Math.min.apply(null, result.field_start_and_end_time_2));
			result.field_start_and_end_time_3 = result.field_start_and_end_time_3.split("*")
			result.field_start_and_end_time_4 = result.field_start_and_end_time_4.split("*")
			result.field_start_and_end_time = result.field_start_and_end_time.split("*")
			result.field_start_and_end_time_1 = result.field_start_and_end_time_1.split("*")

		
			setIsLoaded(true)
			setPageTitle(page_title)
			setEnrolCount(result)

		}).catch(error => {
			console.log(error);
			dispatch(LoadingAction.hideLoader())
		})
	})();
}, [id])



	const setEnrolCount = async (activity) => {
		const url = `${configuration.api_url}${urlEndPoints.getEnrolmentCount(id)}`;
		try {
		  const data = await networkRequest(url);
		  activity.enrolmentCount = !_.isEmpty(data.data) ? data.data.length : 0;
		  const activityData = await networkRequest(`${configuration.api_url}${urlEndPoints.getActivityAccessories(id, lang)}`);
		  setResult({ ...activity, ...activityData.data });
		  dispatch(LoadingAction.hideLoader())
		  document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
		  console.warn("error: ", error);
		}
		dispatch(LoadingAction.hideLoader())
	  };


const checkStudentEnrolled= async()=>{
			let url = `${configuration.api_url}${urlEndPoints.getUserActivityById}`
		try {
			const data = await networkRequest(url)
			setUserActivityList(data.data)
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
}

	  return (
		<div id="main-content" className="activity-details">
			<SlideToTop />
			<PageTitle title={page_title} isDashboardItem={true} />
			<div className="container">
				<div className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
					<ul>
						<li className="breadcrumb-item">
							<Link to="/">{context.t("_home")}</Link> {" "}
						</li>
						<li className="breadcrumb-item">
							<Link to="/activities">{context.t("_activities")}</Link> {" "}
						</li>
						<li className="breadcrumb-item">
							<span>{page_title}</span>
						</li>
					</ul>
				</div>
			</div>
			<div id="skipContent">
				<div id="main-container">
					<div className="container1">
						{
							isLoaded && <EventDetails result={result} id={id} isEnrolled={_.find(userActivityList, { field_activity_id: id }) ? true : false} />
						}

					</div>
				</div>
			</div>
			<div className="top-line ng-scope">
				<img src={topLineImage} alt="" />
			</div>
			<div className="sparkles">
				<span className="orange-circle"></span>
				<span className="multi-square one"><b><i></i></b></span>
				<span className="multi-square two"><b><i></i></b></span>
				<span className="grey-square-rotate red-sq one"></span>
				<span className="grey-square-rotate red-sq two"></span>
			</div>
		</div>
	)
}

ActivityDetails.contextTypes = {
	t: PropTypes.func
}

export default ActivityDetails
