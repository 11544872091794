import React, { useEffect, useRef } from "react"
import Main from "../../../constants/utilities.js"
import SlideToTop from "../../includes/slide_to_top.js"
import PageTitle from "../../includes/page_title.js"
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import { dynamicStyles, customModalStyles } from "./../../../constants/dynamicStyles"
import { Link, useNavigate } from "react-router-dom"
import Pagination from "react-js-pagination";
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import M from "materialize-css"
import { LoadingAction, PopupAction } from "./../../../actions"
import _ from "lodash"
import topLineImage from "../../../assets/images/top-line.png"
import { qidValidate, emailValidate, gradeListNew, calenderI18n, CustomDateInput, calenderLocal } from "./../../../constants/common"
import Modal from "react-modal";
import dateformat from "dateformat"
import { getType } from "./../../dashboard/pages/utilFunctions"
import { useState } from "react"

// class EnrolmentListing extends Main {
//     constructor(props) {
//         super(props);
//         checkLogin();
//         state = {
//             isLoaded: false,
//             config: config(),
//             currentActivityList: [],
//             pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
//             search: { type: "All", title: "", start_date: "", end_date: "", page: 0 },
//             studentList: [],
//             checkedList: [],
//             selectedActivityId: null,
//             intervalId: 0,
//             scrollStepInPx: 50,
//             delayInMs: 16.66,
//             cancelActivityConfirm: false,
//             cancelActivityId: null,
//             formData: {}
//         }
//         this.handlePageChange = this.handlePageChange.bind(this);
//         this.props.showLoader()
//     }
//     async componentDidMount() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_enrolment_listing")}`
//         this.initState();
//         await this.initSearchComponents();
//         M.AutoInit();
//         await this.getActivity();

//         window.addEventListener('load', this.handleTabIndex());
//         setTimeout(() => {
//             document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
//         }, 500);
//     }
//     componentDidUpdate() {
//         document.title = `${this.context.t("_studio56")} | ${this.context.t("_enrolment_listing")}`
//     }
//     // handleTabIndex = () => {
//     //     setTimeout(() => {
//     //         let elem = document.querySelectorAll('select');
//     //         elem.forEach(element => {
//     //             element.setAttribute("tabindex", "0");
//     //         });
//     //     }, 1000);
//     // }
//     // initState = () => {
//     //     this.setState({
//     //         formData: {
//     //             studentId: { value: "", error: "", class: "" },
//     //             email: { value: "", error: "", class: "" },
//     //         }
//     //     })
//     // }
//     // initSearchComponents() {
//     //     var options = { autoClose: true, format: "dd/mm/yyyy" };
//     //     var start_date_options = {
//     //         autoClose: true,
//     //         format: "dd/mm/yyyy",
//     //         showClearBtn: true,
//     //         onSelect: ((date) => {
//     //             this.handleChange("start_date", dateformat(date, "dd/mm/yyyy"))
//     //         }),
//     //         format: "dd/mm/yyyy",
//     //     };
//     //     var end_date_options = {
//     //         autoClose: true,
//     //         format: "dd/mm/yyyy",
//     //         showClearBtn: true,
//     //         onSelect: ((date) => {
//     //             this.handleChange("end_date", dateformat(date, "dd/mm/yyyy"))
//     //         }),
//     //         format: "dd/mm/yyyy",
//     //     };
//     //     setTimeout(() => {
//     //         let start_date = document.querySelectorAll("#start_date");
//     //         let end_date = document.querySelectorAll("#end_date");
//     //         start_date_options.i18n = calenderI18n(this.context);
//     //         end_date_options.i18n = calenderI18n(this.context);
//     //         // M.Datepicker.init(start_date, start_date_options);
//     //         // M.Datepicker.init(end_date, end_date_options);
//     //         let elems = document.querySelectorAll("select");
//     //         M.FormSelect.init(elems, options);
//     //     }, 1000);
//     // }
//     // getChildren = async () => {
//     //     const { config } = this.state
//     //     const url = `${config.api_url}${urlEndPoints.myChildren}`

//     //     try {
//     //         const data = await this.networkRequest(url)
//     //         this.props.hideLoader();
//     //         if (!data.data.message) {
//     //             return data.data.results && data.data.results.length > 0 ? data.data.results : []
//     //         }
//     //     } catch (error) {
//     //         this.props.hideLoader()
//     //         console.warn("error: ", error)
//     //     }
//     //     // }
//     // }
//     // getActivity = async () => {
//     //     const { config, search } = this.state;
//     //     const { lang } = this.props;
//     //     let url = ""
//     //     if (lang == "ar")
//     //         url = `${config.origin}${lang}/api/${urlEndPoints.getActivitiesList}?`
//     //     else
//     //         url = `${config.api_url}${urlEndPoints.getActivitiesList}?`

//     //     if (!_.isEmpty(search.type))
//     //         url = `${url}&type=${search.type}`
//     //     if (!_.isEmpty(search.title) && search.title != "All")
//     //         url = `${url}&field_event_type_value=${search.title}`
//     //     if (search.start_date)
//     //         url = `${url}&field_start_end_dates_value=${dateformat(search.start_date, "dd-mm-yyyy")}`
//     //     if (search.end_date)
//     //         url = `${url}&field_start_end_dates_end_value=${dateformat(search.end_date, "dd-mm-yyyy")}`
//     //     if (search.page)
//     //         url = `${url}&page=${search.page}`

//     //     try {
//     //         const data = await this.networkRequest(url)
//     //         if (data.status == 200) {
//     //             this.scrollToTop()
//     //             this.props.hideLoader()
//     //             const currentActivityList = data.data.results;
//     //             currentActivityList.forEach(async item => {
//     //                 item.field_start_and_end_time_3 = item.field_start_and_end_time_3.split("*")
//     //                 item.field_start_and_end_time_4 = item.field_start_and_end_time_4.split("*")
//     //             });
//     //             this.setState({
//     //                 currentActivityList,
//     //                 pager: data.data.pager,
//     //                 isLoaded: true,
//     //                 checkedList: {}
//     //             })
//     //         }
//     //     } catch (error) {
//     //         this.props.hideLoader()
//     //         console.warn("error: ", error)
//     //     }
//     //     // }
//     // }
//     // calculateAge = (dateString) => {
//     //     var today = new Date();
//     //     var birthDate = new Date(dateString);
//     //     var age = today.getFullYear() - birthDate.getFullYear();
//     //     var m = today.getMonth() - birthDate.getMonth();
//     //     if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
//     //         age--;
//     //     }
//     //     return age;
//     // }
//     // handleCheck = (event, id, index) => {
//     //     const { checkedList } = this.state
//     //     if (event.target.checked)
//     //         checkedList[index] = id
//     //     else
//     //         delete checkedList[index]
//     //     this.setState({ checkedList })

//     // }
//     // handlePageChange(pageNumber) {
//     //     this.props.showLoader()
//     //     var { search } = this.state;
//     //     search.page = pageNumber - 1;
//     //     this.setState({ search: search });
//     //     this.getActivity();
//     // }
//     // handleChange = (field, value) => {
//     //     var { search } = this.state;
//     //     search[field] = value;
//     //     this.setState({ search: search });
//     // }
//     // handelCancelActivity = async id => {
//     //     this.props.showLoader();
//     //     const { config } = this.state;
//     //     const { lang } = this.props;
//     //     // const authResponse = await this.auth();
//     //     // if (authResponse) {
//     //     let url = ""
//     //     if (localStorage.getItem("role") == "volenteer")
//     //         url = `${config.api_url}${urlEndPoints.volunteerRemoveActivity}`
//     //     else
//     //         url = `${config.api_url}${urlEndPoints.cancelActivity}`
//     //     const obj = {
//     //         "activity_id": id
//     //     }
//     //     // const headers = {
//     //     // 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//     //     // }
//     //     try {
//     //         const data = await this.networkRequest(url, "POST", obj);
//     //         if (data.status == 200) {
//     //             if (!_.isEmpty(data.data.error)) {
//     //                 this.props.showPopup({
//     //                     type: "error",
//     //                     title: this.context.t("_error"),
//     //                     message: data.data.error.name[lang],
//     //                     isOpen: true,
//     //                     buttonText: this.context.t("_ok")
//     //                 })
//     //             } else {
//     //                 this.props.showPopup({
//     //                     type: "success",
//     //                     title: this.context.t("_success"),
//     //                     message: this.context.t("_successfully_cancelled"),
//     //                     isOpen: true,
//     //                     buttonText: this.context.t("_ok")
//     //                 })
//     //             }

//     //             this.getActivity()
//     //         }
//     //     } catch (error) {
//     //         this.props.showPopup({
//     //             type: "error",
//     //             title: this.context.t("_failed"),
//     //             message: this.context.t("_failed_to_cancel"),
//     //             isOpen: true,
//     //             buttonText: this.context.t("_ok")
//     //         })
//     //         this.props.hideLoader()
//     //         console.warn("error: ", error)
//     //     }
//     //     // }
//     // }
//     // filterFormSubmit = event => {
//     //     event.preventDefault();
//     //     this.props.showLoader()
//     //     this.getActivity();
//     // }
//     // clearSearch = async () => {
//     //     this.props.showLoader()
//     //     this.myFormRef.reset()
//     //     // let elems = document.querySelector("#start_date");
//     //     // let instance = M.Datepicker.getInstance(elems);
//     //     // instance.setInputValue();
//     //     // elems = document.querySelector("#end_date");
//     //     // instance = M.Datepicker.getInstance(elems);
//     //     // instance.setInputValue();
//     //     this.setState({ search: { type: "All", title: "", start_date: "", end_date: "", page: 0 } }, () => { this.getActivity(); })
//     // }
//     // handleGetStudentList = async id => {
//     //     this.props.showLoader()
//     //     // const authResponse = await this.auth();
//     //     const { formData } = this.state;
//     //     // if (authResponse) {
//     //     const { config } = this.state
//     //     const url = `${config.api_url}${urlEndPoints.getEnrolledStudents(id)}`

//     //     // const headers = {
//     //     // 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//     //     // }
//     //     try {
//     //         const data = await this.networkRequest(url)
//     //         this.props.hideLoader();
//     //         if (!data.data.message) {
//     //             const studentList = _.orderBy(data.data.results, ["field_student_name"], ["asc"])
//     //             this.setState({ studentList, selectedActivityId: id })

//     //             let elems = document.querySelector("#enrollments");
//     //             elems.setAttribute("aria-hidden", "false")
//     //             elems.setAttribute("tabindex", "-1")
//     //             let options = {
//     //                 inDuration: 1000,
//     //                 opacity: 1, dismissible: true
//     //             }
//     //             let instance = M.Modal.getInstance(elems, options);
//     //             instance.open();
//     //         }
//     //     } catch (error) {
//     //         this.props.hideLoader()
//     //         console.warn("error: ", error)
//     //     }
//     //     // } else {
//     //     // 	this.props.hideLoader()
//     //     // }
//     // }
//     // handleCloseModal = () => {
//     //     this.initState();
//     //     let elems = document.querySelector("#checkQid");
//     //     elems.removeAttribute("aria-hidden")
//     //     elems.removeAttribute("tabindex")

//     //     let instance = M.Modal.getInstance(elems);
//     //     instance.close();
//     //     elems = document.querySelector("#addStudent");
//     //     elems.removeAttribute("aria-hidden")
//     //     elems.removeAttribute("tabindex")

//     //     instance = M.Modal.getInstance(elems);
//     //     instance.close();
//     //     elems = document.querySelector("#enrollments");
//     //     elems.removeAttribute("aria-hidden")
//     //     elems.removeAttribute("tabindex")

//     //     instance = M.Modal.getInstance(elems);
//     //     instance.close();
//     // }
//     // handleDetailRedirect = id => {
//     //     this.props.history.push(`/activity/${id}`);
//     // }
//     // handleCancelMultipleActivity = async () => {
//     //     this.props.showLoader();
//     //     const { config, checkedList, selectedActivityId } = this.state;
//     //     const { lang } = this.props;
//     //     if (!_.isEmpty(checkedList)) {
//     //         // const authResponse = await this.auth();
//     //         // if (authResponse) {
//     //         const url = `${config.api_url}${urlEndPoints.cancelActivity}`
//     //         const obj = {
//     //             "activity_id": selectedActivityId,
//     //             user_ids: checkedList
//     //         }
//     //         // const headers = {
//     //         // 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
//     //         // }
//     //         try {
//     //             const data = await this.networkRequest(url, "POST", obj);
//     //             if (data.status == 200) {
//     //                 if (!_.isEmpty(data.data.error)) {
//     //                     this.props.showPopup({
//     //                         type: "error",
//     //                         title: this.context.t("_error"),
//     //                         message: data.data.error.name[lang],
//     //                         isOpen: true,
//     //                         buttonText: this.context.t("_ok")
//     //                     })
//     //                 } else {
//     //                     this.props.showPopup({
//     //                         type: "success",
//     //                         title: this.context.t("_success"),
//     //                         message: this.context.t("_successfully_cancelled"),
//     //                         isOpen: true,
//     //                         buttonText: this.context.t("_ok")
//     //                     })
//     //                 }
//     //                 this.handleCloseModal();
//     //                 this.getActivity()
//     //             }
//     //         } catch (error) {
//     //             this.props.showPopup({
//     //                 type: "error",
//     //                 title: this.context.t("_failed"),
//     //                 message: this.context.t("_failed_to_cancel"),
//     //                 isOpen: true,
//     //                 buttonText: this.context.t("_ok")
//     //             })
//     //             this.props.hideLoader()
//     //             console.warn("error: ", error)
//     //         }
//     //         // }
//     //     } else {
//     //         this.handleCloseModal();
//     //         this.getActivity()
//     //     }

//     // }
//     // scrollStep() {
//     //     var scrollStepInPx = this.props.scrollStepInPx ? this.props.scrollStepInPx : this.state.scrollStepInPx;
//     //     if (window.pageYOffset === 0) {
//     //         clearInterval(this.state.intervalId);
//     //     }
//     //     window.scroll(0, window.pageYOffset - scrollStepInPx);
//     // }
//     // scrollToTop() {
//     //     window.scroll({ top: 0, left: 0, behavior: "smooth" })
//     //     // var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
//     //     // let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
//     //     // this.setState({ intervalId: intervalId });
//     // }
//     // handleCloseConfirmModal = (state) => {
//     //     this.setState({ [state]: false, cancelActivityId: null })
//     // }
//     // handleOpenCancelActivityConfirm = id => {
//     //     this.setState({ cancelActivityConfirm: true, cancelActivityId: id })
//     // }
//     // handleContinueCancelActivity = () => {
//     //     const { cancelActivityId } = this.state;
//     //     if (!_.isEmpty(cancelActivityId))
//     //         this.handelCancelActivity(cancelActivityId)
//     //     this.handleCloseConfirmModal("cancelActivityConfirm")
//     // }
//     // showStatus = status => {
//     //     switch (status) {
//     //         case "Unpublished":
//     //             return this.context.t("_pending");
//     //         case "Waiting List":
//     //             return this.context.t("_waiting_list");

//     //         default:
//     //             return status;
//     //     }
//     // }
//     // handleCancelEnrolment = async (studentId) => {
//     //     this.props.showLoader();
//     //     const { config, selectedActivityId } = this.state;
//     //     const { lang } = this.props;
//     //     if (!_.isEmpty(studentId)) {
//     //         const url = `${config.api_url}${urlEndPoints.cancelActivity}`
//     //         const obj = {
//     //             "activity_id": selectedActivityId,
//     //             "user_ids": {
//     //                 0: studentId
//     //             }
//     //         }
//     //         try {
//     //             const data = await this.networkRequest(url, "POST", obj);
//     //             if (data.status == 200) {
//     //                 if (!_.isEmpty(data.data.error)) {
//     //                     this.props.showPopup({
//     //                         type: "error",
//     //                         title: this.context.t("_error"),
//     //                         message: data.data.error.name[lang],
//     //                         isOpen: true,
//     //                         buttonText: this.context.t("_ok")
//     //                     })
//     //                 } else {
//     //                     this.props.showPopup({
//     //                         type: "success",
//     //                         title: this.context.t("_success"),
//     //                         message: this.context.t("_successfully_cancelled"),
//     //                         isOpen: true,
//     //                         buttonText: this.context.t("_ok")
//     //                     })
//     //                 }
//     //                 // this.handleCloseModal();
//     //                 this.handleGetStudentList(selectedActivityId)
//     //             }
//     //         } catch (error) {
//     //             this.props.showPopup({
//     //                 type: "error",
//     //                 title: this.context.t("_failed"),
//     //                 message: this.context.t("_failed_to_cancel"),
//     //                 isOpen: true,
//     //                 buttonText: this.context.t("_ok")
//     //             })
//     //             this.props.hideLoader()
//     //             console.warn("error: ", error)
//     //         }
//     //     }

//     // }
//     // handleOpenQidCheck = () => {
//     //     const { formData } = this.state;
//     //     for (var key in formData) {
//     //         formData[key].error = "";
//     //     }
//     //     this.setState({ formData })
//     //     let elems = document.querySelector("#checkQid");
//     //     elems.setAttribute("aria-hidden", "false")
//     //     elems.setAttribute("tabindex", "-1")
//     //     let instance = M.Modal.getInstance(elems);
//     //     instance.open();
//     // }

//     // validate = (type) => {
//     //     const { formData } = this.state;
//     //     for (var key in formData) {
//     //         formData[key].value = formData[key].value.trim();
//     //         formData[key].error = "";
//     //     }
//     //     if (type == "qid") {
//     //         if (formData.studentId.value == "")
//     //             formData.studentId.error = this.context.t("_qid_required");

//     //         if (formData.studentId.value != "" && !qidValidate.test(formData.studentId.value))
//     //             formData.studentId.error = this.context.t("_invalid_qid");

//     //     } else {
//     //         // if (formData.parentName.value == "")
//     //         //     formData.parentName.error = this.context.t("_parent_name_required");

//     //         // if (formData.parentName.value != "" && !nameValidate.test(formData.parentName.value))
//     //         //     formData.parentName.error = this.context.t("_invalid_parent_name");

//     //         // if (formData.parentName.value != "" && nameValidate.test(formData.parentName.value) && !nameWordLengthCheck(formData.parentName.value))
//     //         //     formData.parentName.error = this.context.t("_parent_name_contains_atleast_two_word");

//     //         // if (formData.dob.value == "")
//     //         //     formData.dob.error = this.context.t("_date_of_birth_required");

//     //         if (_.isEmpty(formData.email.value))
//     //             formData.email.error = this.context.t("_email_required");

//     //         if (formData.email.value != "" && !emailValidate.test(formData.email.value))
//     //             formData.email.error = this.context.t("_invalid_email");

//     //         // if (formData.mobileNumber.value == "")
//     //         //     formData.mobileNumber.error = this.context.t("_mobile_number_required");

//     //         // if (formData.mobileNumber.value != "" && !phoneValidate.test(formData.mobileNumber.value))
//     //         //     formData.mobileNumber.error = this.context.t("_invalid_mobile_number");
//     //     }
//     //     for (var key in formData) {
//     //         if (formData[key].error != "") {
//     //             this.setState({ formData, submissionStatus: false })
//     //             // this.scrollToTop();
//     //             // setTimeout(() => {
//     //             //     this[key + "error"].focus()
//     //             // }, 1000);
//     //             return false;

//     //         }
//     //     }
//     //     return true;
//     // }
//     // displayError = error => {
//     //     const { formData } = this.state;
//     //     const { lang } = this.props;
//     //     if (error.qid)
//     //         formData.studentId.error = error.qid[lang]

//     //     if (error.name)
//     //         formData.name.error = error.name[lang]

//     //     if (error.mail)
//     //         formData.email.error = error.mail[lang]

//     //     if (error.DOB)
//     //         formData.dob.error = error.DOB[lang]

//     //     this.setState({ formData })


//     // }
//     // handleCheckQid = async (event) => {
//     //     event.preventDefault();
//     //     const { formData, config } = this.state;

//     //     if (this.validate("qid")) {
//     //         this.props.showLoader()
//     //         const url = `${config.api_url}${urlEndPoints.checkStudentQid(formData.studentId.value)}`
//     //         try {
//     //             const data = await this.networkRequest(url)
//     //             if (data.status == 200) {
//     //                 if (data.data.error) {
//     //                     this.props.hideLoader()
//     //                     this.displayError(data.data.error)
//     //                 } else {
//     //                     if (data.data && data.data.length == 0) {
//     //                         this.props.hideLoader()
//     //                         this.handleOpenAddStudentModal();
//     //                     } else {
//     //                         this.enrollActivity(data.data[0].uid)
//     //                         // const parentList = await this.getMyParents();
//     //                         // this.setState({ parentList })
//     //                         // this.props.hideLoader()
//     //                         this.handleCloseModal()
//     //                     }
//     //                 }
//     //             }
//     //         } catch (error) {
//     //             this.props.hideLoader()
//     //             console.warn("error: ", error)
//     //         }
//     //     }
//     // }
//     // enrollActivity = async (id) => {
//     //     const { lang } = this.props;
//     //     const { selectedActivityId } = this.state;
//     //     if (selectedActivityId) {
//     //         this.props.showLoader();
//     //         const { config } = this.state
//     //         const url = `${config.api_url}${urlEndPoints.activityEnrolment}`
//     //         const obj = {
//     //             "activity_id": selectedActivityId,
//     //             "user_ids": { "0": id }
//     //         }
//     //         try {
//     //             const data = await this.networkRequest(url, "POST", obj)
//     //             if (data.data.error) {
//     //                 this.props.showPopup({
//     //                     type: "error",
//     //                     title: this.context.t("_error"),
//     //                     message: `${data.data.error.name[lang]}`,
//     //                     isOpen: true,
//     //                     buttonText: this.context.t("_ok")
//     //                 })
//     //             } else {
//     //                 const message = this.context.t("_enroll_success");
//     //                 this.props.showPopup({
//     //                     type: "success",
//     //                     title: this.context.t("_success"),
//     //                     message,
//     //                     isOpen: true,
//     //                     buttonText: this.context.t("_ok")
//     //                 })
//     //             }
//     //             this.handleGetStudentList(selectedActivityId)
//     //         } catch (error) {
//     //             this.props.hideLoader()
//     //             console.warn("error: ", error)
//     //         }
//     //     }
//     // }

//     // handleInviteStudent = async (event) => {
//     //     event.preventDefault();
//     //     const { formData, config, selectedActivityId } = this.state;
//     //     const { lang } = this.props
//     //     if (this.validate()) {
//     //         this.props.showLoader()
//     //         let obj = {
//     //             email: formData.email.value
//     //         }
//     //         const url = `${config.api_url}${urlEndPoints.sendInvitation}`
//     //         try {
//     //             const data = await this.networkRequest(url, "POST", obj)
//     //             if (data.data.error) {
//     //                 this.displayError(data.data.error)
//     //                 this.props.hideLoader()
//     //             }
//     //             else {
//     //                 this.props.showPopup({
//     //                     type: "success",
//     //                     title: this.context.t("_success"),
//     //                     message: data.data.message[lang],
//     //                     isOpen: true,
//     //                     buttonText: this.context.t("_ok")
//     //                 })
//     //                 this.handleCloseModal();
//     //                 this.handleGetStudentList(selectedActivityId)
//     //                 // this.getStudentList()
//     //             }
//     //         } catch (error) {
//     //             this.props.hideLoader()
//     //             console.warn("error: ", error)
//     //         }
//     //     }
//     // }
//     // handleOpenAddStudentModal = () => {
//     //     const { formData } = this.state;
//     //     for (var key in formData) {
//     //         formData[key].error = "";
//     //     }
//     //     this.setState({ formData })
//     //     let elems = document.querySelector("#checkQid");
//     //     elems.removeAttribute("aria-hidden")
//     //     elems.removeAttribute("tabindex")
//     //     let instance = M.Modal.getInstance(elems);
//     //     instance.close();
//     //     elems = document.querySelector("#addStudent");
//     //     elems.setAttribute("aria-hidden", "false")
//     //     elems.setAttribute("tabindex", "-1")
//     //     instance = M.Modal.getInstance(elems);
//     //     instance.open();
//     // }
//     // handleFormChange = (field, value, state) => {
//     //     if (field == "parentQid")
//     //         if (isNaN(value))
//     //             return false
//     //     const tempState = this.state[state];
//     //     tempState[field]["class"] = value ? "" : "invalid";
//     //     tempState[field]["value"] = value;
//     //     this.setState({ [state]: tempState });
//     // };
//     // getDateInFormat = date => {
//     //     // if (!_.isEmpty(date)) {
//     //     if (date.includes("Jan"))
//     //         date = date.replace("Jan", this.context.t("_jan"))

//     //     if (date.includes("Feb"))
//     //         date = date.replace("Feb", this.context.t("_feb"))

//     //     if (date.includes("Mar"))
//     //         date = date.replace("Mar", this.context.t("_mar"))

//     //     if (date.includes("Apr"))
//     //         date = date.replace("Apr", this.context.t("_apr"))

//     //     if (date.includes("May"))
//     //         date = date.replace("May", this.context.t("_may"))

//     //     if (date.includes("Jun"))
//     //         date = date.replace("Jun", this.context.t("_jun"))

//     //     if (date.includes("Jul"))
//     //         date = date.replace("Jul", this.context.t("_jul"))

//     //     if (date.includes("Aug"))
//     //         date = date.replace("Aug", this.context.t("_aug"))

//     //     if (date.includes("Sep"))
//     //         date = date.replace("Sep", this.context.t("_sep"))

//     //     if (date.includes("Oct"))
//     //         date = date.replace("Oct", this.context.t("_oct"))

//     //     if (date.includes("Nov"))
//     //         date = date.replace("Nov", this.context.t("_nov"))

//     //     if (date.includes("Dec"))
//     //         date = date.replace("Dec", this.context.t("_dec"))
//     //     // }
//     //     return date
//     // }
//     render() {
//         const { currentActivityList, pager, isLoaded, search, studentList, formData, cancelActivityConfirm } = this.state;
//         const listActivity = search.type == "All" ? currentActivityList : currentActivityList.filter(data => data.field_event_type.toLowerCase() == search.type)
//         const { lang } = this.props;
//         let hasError = false;
//         Object.keys(formData).map(item => {
//             if (formData[item].error != "")
//                 hasError = true
//         })
//         return (
//             <div id="main-content" className="activiti-list">
//                 <SlideToTop />
//                 <PageTitle title={this.context.t("_enrolment_listing")} />
//                 <div className="container">
//                     <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
//                         <ul>
//                             <li className="breadcrumb-item">
//                                 <Link to="/admin-profile">{this.context.t("_profile")}</Link> {" "}

//                             </li>
//                             <li className="breadcrumb-item">
//                                 <Link tabIndex="0" to aria-current={this.context.t("_page")}>
//                                     <span>{this.context.t("_enrolment_listing")} </span>
//                                 </Link>

//                             </li>
//                         </ul>
//                     </nav>
//                 </div>
//                 <div id="main-container">
//                     <div id="skipContent" className="container">
//                         <form onSubmit={this.filterFormSubmit} ref={(el) => this.myFormRef = el}>
//                             <div className="row topFormHolder">
//                                 <div className="col s12 m6 l3">
//                                     <div className={`input-field mobileSelect item ${lang}`}>
//                                         <label htmlFor="activityType" className="active" style={dynamicStyles.position(lang)}>{this.context.t("_activity_type")}</label>
//                                         <select className="browser-default" id="activityType" value={search.title} onChange={evt => this.handleChange("title", evt.target.value)}>
//                                             <option value="" disabled >{this.context.t("_select_activity_type")}</option>
//                                             <option value="All" >{this.context.t("_all")}</option>
//                                             <option value="competition">{this.context.t("_competitions")}</option>
//                                             <option value="session">{this.context.t("_sessions")}</option>
//                                             <option value="event">{this.context.t("_events")}</option>
//                                             <option value="challenge">{this.context.t("_challenges")}</option>
//                                             <option value="project">{this.context.t("_projects")}</option>
//                                         </select>
//                                         <span className="helper-text"></span>
//                                     </div>
//                                 </div>

//                                 <div className="col s12 m6 l3 date-popup">
//                                     <div className={`input-field item  ${lang}`}>
//                                         <DatePicker
//                                             name="start_date"
//                                             placeholderText={this.context.t("_date")}
//                                             id="start_date"
//                                             autoComplete="off"
//                                             dateFormat="yyyy-MM-dd"
//                                             locale={calenderLocal(this.context)}
//                                             customInput={<CustomDateInput ariaLabel={this.context.t("_select_date_button")} onFieldChange={date => this.handleChange("start_date", new Date(date))} />}
//                                             onChange={date =>
//                                                 this.handleChange("start_date", date)
//                                             }
//                                             onCalendarOpen={e => {
//                                                 let elems = document.querySelector(".react-datepicker");
//                                                 elems.setAttribute("tabindex", "0")
//                                                 elems && elems.focus()
//                                                 elems = document.querySelector(".react-datepicker__month-select");
//                                                 elems.setAttribute("aria-label", this.context.t("_select_month"))
//                                                 elems = document.querySelector(".react-datepicker__year-select");
//                                                 elems.setAttribute("aria-label", this.context.t("_select_year"))

//                                             }}
//                                             onCalendarClose={e => {
//                                                 var elems = document.querySelector("#start_date");
//                                                 elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(search.start_date, "dd-mm-yyyy")}`)
//                                                 elems.setAttribute("value", dateformat(search.start_date, "yyyy-mm-dd"))
//                                                 setTimeout(() => {
//                                                     elems && elems.focus()
//                                                 }, 200);
//                                             }}
//                                             style={dynamicStyles.textAlign(lang)}
//                                             value={search.start_date}
//                                             selected={search.start_date}
//                                             peekNextMonth
//                                             showMonthDropdown
//                                             showYearDropdown
//                                             dropdownMode="select"
//                                         />
//                                         <label htmlFor="start_date" style={dynamicStyles.position(lang)}>{this.context.t("_start_date")}</label>
//                                     </div>
//                                 </div>

//                                 <div className="col s12 m6 l3 date-popup">
//                                     <div className="input-field item">
//                                         <DatePicker
//                                             name="end_date"
//                                             placeholderText={this.context.t("_date")}
//                                             id="end_date"
//                                             autoComplete="off"
//                                             dateFormat="yyyy-MM-dd"
//                                             locale={calenderLocal(this.context)}
//                                             customInput={<CustomDateInput ariaLabel={this.context.t("_select_date_button")} onFieldChange={date => this.handleChange("end_date", new Date(date))} />}
//                                             onChange={date =>
//                                                 this.handleChange("end_date", date)
//                                             }
//                                             onCalendarOpen={e => {
//                                                 let elems = document.querySelector(".react-datepicker");
//                                                 elems.setAttribute("tabindex", "0")
//                                                 elems && elems.focus()
//                                                 elems = document.querySelector(".react-datepicker__month-select");
//                                                 elems.setAttribute("aria-label", this.context.t("_select_month"))
//                                                 elems = document.querySelector(".react-datepicker__year-select");
//                                                 elems.setAttribute("aria-label", this.context.t("_select_year"))

//                                             }}
//                                             onCalendarClose={e => {
//                                                 var elems = document.querySelector("#end_date");
//                                                 elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(search.end_date, "dd-mm-yyyy")}`)
//                                                 elems.setAttribute("value", dateformat(search.end_date, "yyyy-mm-dd"))
//                                                 setTimeout(() => {
//                                                     elems && elems.focus()
//                                                 }, 200);
//                                             }}
//                                             style={dynamicStyles.textAlign(lang)}
//                                             value={search.end_date}
//                                             selected={search.end_date}
//                                             peekNextMonth
//                                             showMonthDropdown
//                                             showYearDropdown
//                                             dropdownMode="select"
//                                         />
//                                         <label htmlFor="end_date" style={dynamicStyles.position(lang)}>{this.context.t("_end_date")}</label>
//                                     </div>
//                                 </div>

//                                 <div className="col s12 m6 l3 valign-wrapper center-align search-btn-holder">
//                                     <button className="btn noWidth profileBtn yellow waves-effect waves-light">
//                                         {this.context.t("_search")} <i className={`tiny material-icons ${lang}`} >{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
//                                     </button>
//                                     <button type="button" onClick={() => this.clearSearch()} className="btn noWidth  blue-btn waves-effect waves-light">
//                                         {this.context.t("_clear")}
//                                     </button>
//                                     <br /><br />
//                                 </div>
//                             </div>
//                         </form>

//                         <div className="row">
//                             <div className="col s7 result-status">
//                                 {isLoaded ? this.context.t("_search_result") + " ( " + (listActivity.length) + " of " + pager.count + " )" : ""}

//                             </div>
//                             <div className="col s5 result-type">
//                                 <div className={`input-field mobileSelect col s12 filter-select  ${lang}`}>
//                                     <label htmlFor="result-type">{this.context.t("_select_activity")}</label>
//                                     <select className="browser-default" id="result-type" value={search.type} onChange={evt => this.handleChange("type", evt.target.value)}>
//                                         <option value="All" >{this.context.t("_all")}</option>
//                                         <option value="competition">{this.context.t("_competitions")}</option>
//                                         <option value="session">{this.context.t("_sessions")}</option>
//                                         <option value="event">{this.context.t("_events")}</option>
//                                         <option value="challenge">{this.context.t("_challenges")}</option>
//                                         <option value="project">{this.context.t("_projects")}</option>
//                                     </select>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="activities">
//                             {listActivity.map((row, index) => (
//                                 <div className="row" key={index}>
//                                     <div className="col s7">
//                                         <Link to={`/activity/${row.nid}`}>
//                                             <h2 className="title">{row.title_1 || row.title} <span className={row.field_event_type.toLowerCase()}>{getType(row.field_event_type, this.context)}</span></h2>
//                                         </Link>

//                                         <div className="details">
//                                             {row.field_start_and_end_time_3 &&
//                                                 <span><i className="calendar-icons"></i> {this.context.t("_start_date")} <span className="date">{this.getDateInFormat(row.field_start_and_end_time_3[0])}</span></span>
//                                             }
//                                             {row.field_start_and_end_time_4 &&
//                                                 <span><i className="calendar-icons"></i> {this.context.t("_end_date")} <span className="date">{this.getDateInFormat(row.field_start_and_end_time_4[row.field_start_and_end_time_4.length - 1])}</span></span>
//                                             }
//                                         </div>
//                                     </div>
//                                     <div className="col s5 btn-wrap flex-row justify-end align-center">
//                                         {/* {
//                                             localStorage.getItem("role") == "volenteer" ?
//                                                 <button onClick={() => this.handleOpenCancelActivityConfirm(row.nid)} className="btn blue-border">{this.context.t("_cancel")}</button> :
//                                                 localStorage.getItem("role") == "student" ?
//                                                     <button onClick={() => this.handleOpenCancelActivityConfirm(row.nid)} className="btn blue-border">{this.context.t("_cancel")}</button>
//                                                     :
//                                                     <button onClick={() => this.handleGetStudentList(row.nid)} className="btn blue-border">{this.context.t("_enrollments")}</button>
//                                         } */}

//                                         <Link to={`/admin-enrolment/${row.nid}`} className="btn blue-border">{this.context.t("_enrollments")}</Link>

//                                     </div>
//                                 </div>
//                             ))}
//                             {!currentActivityList.length && isLoaded &&
//                                 <div className="row"><p aria-label={this.context.t("_no_result_found")} className="col s12 no-result">{this.context.t("_no_result_found")}</p></div>
//                             }
//                         </div>
//                         <div id="enrollments" className="modal user-add-modal" role="dialog">
//                             <div className="modal-content">
//                                 {/* <form onSubmit={this.handleAddNewStudent} className="col full-width"> */}
//                                 <button className="badge modalClose" aria-label="close" onClick={() => this.handleCloseModal()}>
//                                     <i className="material-icons">close</i>
//                                 </button>
//                                 <h2 className="line">{this.context.t("_enrollments")}</h2>
//                                 {
//                                     _.isEmpty(studentList) ?
//                                         <div className="page-not-found center">
//                                             <br />
//                                             <h4>{this.context.t("_no_student_to_list")}</h4>
//                                         </div>
//                                         :
//                                         <div className="tableHolder">

//                                             <table className="responsive-table highlight">
//                                                 <thead className="blue lighten-5">
//                                                     <tr>
//                                                         <th><strong>{this.context.t("_name")}</strong></th>
//                                                         <th><strong>{this.context.t("_qid")}</strong></th>
//                                                         <th><strong>{this.context.t("_phone_number")}</strong></th>
//                                                         <th><strong>{this.context.t("_grade")}</strong></th>
//                                                         <th><strong>{this.context.t("_gender")}</strong></th>
//                                                         <th><strong>{this.context.t("_age")}</strong></th>
//                                                         <th width="50"></th>
//                                                         {/* <th width="50"><strong></strong></th> */}
//                                                     </tr>
//                                                 </thead>

//                                                 <tbody>
//                                                     {
//                                                         studentList.map((item, index) => {
//                                                             return (
//                                                                 <tr key={index}>
//                                                                     <td>{item.field_student_name}</td>
//                                                                     <td>{item.field_qid}</td>
//                                                                     <td>{item.field_student_mobile}</td>
//                                                                     <td>{item.field_grade}</td>
//                                                                     <td>{item.field_gender}</td>
//                                                                     <td>{!_.isEmpty(item.field_student_dob) ? this.calculateAge(item.field_student_dob) : null}</td>
//                                                                     <td>
//                                                                         <i onClick={() => this.handleCancelEnrolment(item.field_student)} className="material-icons attendanceClose tooltipped" data-position="right" data-tooltip="Remove enrolment">delete_forever</i>
//                                                                     </td>
//                                                                     {/* <td>
//                                                                 {
//                                                                     item.field_published_status == "Published" ? <p className="checkboxHolder"><label><input onChange={(e) => this.handleCheck(e, item.uid, index)} checked={checkedList[index] ? true : false} type="checkbox" /><span></span></label></p> : this.showStatus(item.field_published_status)
//                                                                 }
//                                                             </td> */}
//                                                                 </tr>
//                                                             )
//                                                         })
//                                                     }
//                                                 </tbody>
//                                             </table>
//                                             <br />
//                                             <button type="button" onClick={() => { this.handleOpenQidCheck() }} className="btn noWidth  blue-btn waves-effect waves-light pull-right">{this.context.t("_add_student")}</button>
//                                         </div>
//                                 }
//                                 <div id="checkQid" className="modal sm" role="dialog">
//                                     <div className="modal-content">
//                                         {
//                                             _.isEmpty(formData) ?
//                                                 <div /> :
//                                                 <div>
//                                                     <span className="badge modalClose"><button className="close" aria-label="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
//                                                     <h2 className="line">{this.context.t("_add_student")}</h2>
//                                                     {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit.</p> */}
//                                                     {hasError ? <div className="error-container fail">
//                                                         <h5>{this.context.t("_error_title")}</h5>
//                                                         <ul>
//                                                             {formData.studentId.error != "" ? <li ><label className="error" htmlFor="studentId">{formData.studentId.error}</label></li> : null}
//                                                         </ul>
//                                                     </div> : null
//                                                     }
//                                                     <form onSubmit={this.handleCheckQid} className="row full-width">
//                                                         <div className="col s12">
//                                                             <div className="input-field item">
//                                                                 <input
//                                                                     placeholder={this.context.t("_parent_id_placeholder")}
//                                                                     type="text"
//                                                                     name="studentId"
//                                                                     id="studentId"
//                                                                     maxLength={11}
//                                                                     onChange={(evt) => this.handleFormChange("studentId", evt.target.value, "formData")}
//                                                                     onFocus={(evt) => this.handleFormChange("studentId", evt.target.value, "formData")}
//                                                                     value={formData.studentId.value}
//                                                                 />
//                                                                 <label htmlFor="studentId" style={dynamicStyles.position(lang)}>{this.context.t("_student_qid")} <span className="asterisk">*</span></label>
//                                                                 <span
//                                                                     style={dynamicStyles.textAlign(lang)}
//                                                                     className="helper-text"
//                                                                     data-error="Required field."
//                                                                 ></span>
//                                                             </div>
//                                                         </div>

//                                                         <div className="col s12">
//                                                             <button type="button" onClick={this.handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{this.context.t("_cancel")}</button>
//                                                             <button className="btn profileBtn blue noWidth waves-effect waves-light">{this.context.t("_add")}</button>
//                                                         </div>
//                                                     </form>
//                                                 </div>
//                                         }
//                                     </div>
//                                 </div>
//                                 <div id="addStudent" className="modal sm user-add-modal" role="dialog">
//                                     <div className="modal-content">
//                                         {
//                                             _.isEmpty(formData) ?
//                                                 <div /> :
//                                                 <form onSubmit={this.handleInviteStudent} className="col full-width">
//                                                     <span className="badge modalClose"><button className="close" aria-label="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
//                                                     <h2 className="line">{this.context.t("_add_student")}</h2>

//                                                     {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p> */}
//                                                     {hasError ? <div className="error-container fail">
//                                                         <h5>{this.context.t("_error_title")}</h5>
//                                                         <ul>
//                                                             {
//                                                                 Object.keys(formData).map((item, index) => {
//                                                                     return formData[item].error != "" ? <li key={index} ref={(refItem) => { this[item + "error"] = refItem; }}><label className="error" htmlFor={item}>{formData[item].error}</label></li>
//                                                                         :
//                                                                         null
//                                                                 })}
//                                                         </ul>
//                                                     </div> : null
//                                                     }
//                                                     <p>{this.context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

//                                                     <div className="row">
//                                                         <div className="col s12">
//                                                             <div className="input-field item">
//                                                                 <input
//                                                                     placeholder={this.context.t("_parent_email_address_placeholder")}
//                                                                     type="text"
//                                                                     name="email"
//                                                                     id="email"
//                                                                     onChange={(evt) => this.handleFormChange("email", evt.target.value, "formData")}
//                                                                     onFocus={(evt) => this.handleFormChange("email", evt.target.value, "formData")}
//                                                                     value={formData.email.value}
//                                                                 />
//                                                                 <label htmlFor="email" style={dynamicStyles.position(lang)}>{this.context.t("_parent_email_address")} <span className="asterisk">*</span></label>
//                                                                 <span
//                                                                     style={dynamicStyles.textAlign(lang)}
//                                                                     className="helper-text"
//                                                                     data-error="Required field."
//                                                                 ></span>
//                                                             </div>
//                                                         </div>
//                                                         <div className="col s12">
//                                                             <button className="btn profileBtn blue waves-effect waves-light">{this.context.t("_send_invitation")}</button>
//                                                         </div>
//                                                         <div className="clearfix" />
//                                                     </div>
//                                                 </form>
//                                         }
//                                     </div>
//                                 </div>
//                                 {/* <button onClick={() => { this.handleCancelMultipleActivity() }} className="btn blue-border">Cancel</button> */}
//                                 {/* </form> */}
//                                 {/* {
// 									_.isEmpty(studentFormData) ?
// 										<div /> :
// 										<form onSubmit={this.handleAddNewStudent} className="col full-width">
// 											<span className="badge modalClose"><button className="close" aria-label="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
// 											<h2 className="line">{this.context.t("_add_student")}</h2>

// 											<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p>
// 											{hasError ? <div className="error-container fail">
// 												<h5>{this.context.t("_error_title")}</h5>
// 												<ul>
// 													{
// 														Object.keys(studentFormData).map((item, index) => {
// 															return studentFormData[item].error != "" ? <li key={index} ref={(refItem) => { this[item + "error"] = refItem; }}><label className="error" htmlFor={item}>{studentFormData[item].error}</label></li>
// 																:
// 																null
// 														})}
// 												</ul>
// 											</div> : null
// 											}
// 											<p>{this.context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

// 											<div className="row">
// 												<div className="col s12 m6">
// 													{this.renderInput({ name: "name", label: this.context.t("_student_name"), placeholder: this.context.t("_student_name_placeholder"), isMandatory: true })}
// 													{this.renderInput({ name: "email", label: this.context.t("_email"), placeholder: this.context.t("_email_placeholder"), type: "email" })}

// 												</div>
// 												<div className="col s12 m6">
// 													<div className="date-popup">
// 														{this.renderInput({ name: "dob", label: this.context.t("_dob"), placeholder: this.context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
// 													</div>

// 													{this.renderInput({ name: "mobile", label: this.context.t("_mobile_number"), placeholder: this.context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number" })}
// 												</div>
// 												<div className="col s12">
// 													<button className="btn profileBtn blue waves-effect waves-light">{this.context.t("_save_changes")}</button>
// 												</div>
// 												<div className="clearfix" />
// 											</div>
// 										</form>
// 								} */}
//                             </div>
//                         </div>
//                         <nav className="pagination-wrapper" aria-label="pagination">
//                             {(currentActivityList.length && isLoaded) ? (
//                                 <Pagination
//                                     prevPageText={`< ${this.context.t("_previous")}`}
//                                     nextPageText={`${this.context.t("_next")} >`}
//                                     hideFirstLastPages={true}
//                                     hideDisabled={true}
//                                     linkClassPrev="linkClassPrev"
//                                     linkClassNext="linkClassNext"
//                                     activePage={pager.current_page + 1}
//                                     itemsCountPerPage={pager.items_per_page}
//                                     totalItemsCount={pager.count}
//                                     pageRangeDisplayed={5}
//                                     onChange={this.handlePageChange}
//                                 />) : ""
//                             }
//                         </nav>
//                     </div>
//                     <div className="sparkles">
//                         <span className="orange-circle"></span>
//                         <span className="multi-square two"><b><i></i></b></span>
//                         <span className="grey-square-rotate red-sq one"></span>
//                         <span className="grey-square-rotate red-sq two"></span>
//                     </div>
//                 </div>
//                 <div className="top-line ng-scope">
//                     <img src={topLineImage} alt="" />
//                 </div>
//                 <Modal
//                     isOpen={cancelActivityConfirm}
//                     style={customModalStyles}
//                     contentLabel="unlink parent confirm modal"
//                     ariaHideApp={false}
//                 >
//                     <div className="popupAlert warning">
//                         <br />
//                         <h3>{this.context.t("_confirm")}</h3>
//                         <p>{this.context.t("_cancel_activity_confirm")}</p>
//                         <div className="row">
//                             <button className="col btn blue" onClick={() => this.handleCloseConfirmModal("cancelActivityConfirm")}>{this.context.t("_cancel")}</button>
//                             <button className="col btn profileBtn yellow" onClick={() => this.handleContinueCancelActivity()}>{this.context.t("_continue")}</button>
//                         </div>
//                     </div>
//                 </Modal>
//             </div>
//         )
//     }
// }

// const mapStateToProps = ({ i18nState }) => {
//     return {
//         lang: i18nState.lang
//     }
// }
// const mapDispatchToProps = {
//     showLoader: LoadingAction.showLoader,
//     hideLoader: LoadingAction.hideLoader,
//     showPopup: (data) => PopupAction.showPopup(data),
//     hidePopup: (data) => PopupAction.hidePopup(data),
// }
// EnrolmentListing.contextTypes = {
//     t: PropTypes.func
// }
// export default connect(mapStateToProps, mapDispatchToProps)(EnrolmentListing);


const EnrolmentListing = (props, context) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentActivityList, setCurrentActivityList] = useState([]);
    const [pager, setPager] = useState({ count: 0, pages: 0, items_per_page: 0, current_page: 1 });
    const [search, setSearch] = useState({ type: "All", title: "", start_date: "", end_date: "", page: 0 });
    const [studentList, setStudentList] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [selectedActivityId, setSelectedActivityId] = useState(null);
    const [cancelActivityConfirm, setCancelActivityConfirm] = useState(false);
    const [cancelActivityId, setCancelActivityId] = useState(null);
    const [formData, setFormData] = useState({});
    const [hasError, setHasError] = useState(false)
    const [submissionStatus, setSubmissionStatus] = useState(false);
    const [listActivity, setListActivity] = useState([]);
    const intervalId = 0;
    const scrollStepInPx = 50;
    const delayInMs = 16.66;
    const { config, networkRequest } = Main()
    const configure = config()
    const { lang } = useSelector(state => state.i18nState)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const myFormRef = useRef(null)

    const handleTabIndex = () => {
        setTimeout(() => {
            let elem = document.querySelectorAll('select');
            elem.forEach(element => {
                element.setAttribute("tabindex", "0");
            });
        }, 1000);
    }
    const initState = () => {
        setFormData({
            studentId: { value: "", error: "", class: "" },
            email: { value: "", error: "", class: "" },
        })
    }
    function initSearchComponents() {
        var options = { autoClose: true, format: "dd/mm/yyyy" };
        var start_date_options = {
            autoClose: true,
            format: "dd/mm/yyyy",
            showClearBtn: true,
            onSelect: ((date) => {
                handleChange("start_date", dateformat(date, "dd/mm/yyyy"))
            }),
            format: "dd/mm/yyyy",
        };
        var end_date_options = {
            autoClose: true,
            format: "dd/mm/yyyy",
            showClearBtn: true,
            onSelect: ((date) => {
                handleChange("end_date", dateformat(date, "dd/mm/yyyy"))
            }),
            format: "dd/mm/yyyy",
        };
        setTimeout(() => {
            let start_date = document.querySelectorAll("#start_date");
            let end_date = document.querySelectorAll("#end_date");
            start_date_options.i18n = calenderI18n(context);
            end_date_options.i18n = calenderI18n(context);
            // M.Datepicker.init(start_date, start_date_options);
            // M.Datepicker.init(end_date, end_date_options);
            let elems = document.querySelectorAll("select");
            M.FormSelect.init(elems, options);
        }, 1000);
    }
    const getChildren = async () => {
        const url = `${configure.api_url}${urlEndPoints.myChildren}`

        try {
            const data = await networkRequest(url)
            dispatch(LoadingAction.hideLoader());
            if (!data.data.message) {
                return data.data.results && data.data.results.length > 0 ? data.data.results : []
            }
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // }
    }
    const getActivity = async () => {
        let url = ""
        let method = "POST"
        if (lang == "ar")
        url = `${configure.origin}${lang}/api/${urlEndPoints.getActivitiesList}`
        else
            url = `${configure.api_url}${urlEndPoints.getActivitiesList}`

        const body = {
            lang
        }
        if (!_.isEmpty(search.type))
            body.type = search.type
        if (!_.isEmpty(search.title) && search.title != "All")
            body.field_event_type_value = search.title
        if (search.start_date)
            body.field_start_end_dates_value = dateformat(search.start_date, "dd-mm-yyyy")
        if (search.end_date)
            body.field_start_end_dates_end_value = dateformat(search.end_date, "dd-mm-yyyy")
        if (search.page)
            body.page = search.page

        try {
            const data = await networkRequest(url, "POST", body)
            if (data.status == 200) {
                scrollToTop()
                dispatch(LoadingAction.hideLoader())
                const list = data.data.results;
                list.forEach(async item => {
                    item.field_start_and_end_time_3 = item.field_start_and_end_time_3.split("*")
                    item.field_start_and_end_time_4 = item.field_start_and_end_time_4.split("*")
                });
                setCurrentActivityList(list)
                setPager(data.data.pager)
                setIsLoaded(true)
                setCheckedList({})
            }
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // }
    }
    const calculateAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    const handleCheck = (event, id, index) => {
        if (event.target.checked)
            checkedList[index] = id
        else
            delete checkedList[index]
        setCheckedList({ ...checkedList })
    }
    function handlePageChange(pageNumber) {
        dispatch(LoadingAction.showLoader())
        search.page = pageNumber - 1;
        setSearch({ ...search })
        getActivity();
    }
    const handleChange = (field, value) => {
        search[field] = value;
        setSearch({ ...search })
    }
    const handelCancelActivity = async id => {
        dispatch(LoadingAction.showLoader());
        // const authResponse = await this.auth();
        // if (authResponse) {
        let url = ""
        if (localStorage.getItem("role") == "volenteer")
            url = `${configure.api_url}${urlEndPoints.volunteerRemoveActivity}`
        else
            url = `${configure.api_url}${urlEndPoints.cancelActivity}`
        const obj = {
            "activity_id": id
        }
        // const headers = {
        // 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await networkRequest(url, "POST", obj);
            if (data.status == 200) {
                if (!_.isEmpty(data.data.error)) {
                    dispatch(PopupAction.showPopup({
                        type: "error",
                        title: context.t("_error"),
                        message: data.data.error.name[lang],
                        isOpen: true,
                        buttonText: context.t("_ok")
                    }))
                } else {
                    dispatch(PopupAction.showPopup({
                        type: "success",
                        title: context.t("_success"),
                        message: context.t("_successfully_cancelled"),
                        isOpen: true,
                        buttonText: context.t("_ok")
                    }))
                }

                getActivity()
            }
        } catch (error) {
            dispatch(PopupAction.showPopup({
                type: "error",
                title: context.t("_failed"),
                message: context.t("_failed_to_cancel"),
                isOpen: true,
                buttonText: context.t("_ok")
            }))
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // }
    }
    const filterFormSubmit = event => {
        event.preventDefault();
        dispatch(LoadingAction.showLoader())
        getActivity();
    }
    const clearSearch = async () => {
        dispatch(LoadingAction.showLoader())
        myFormRef.current.reset()
        // let elems = document.querySelector("#start_date");
        // let instance = M.Datepicker.getInstance(elems);
        // instance.setInputValue();
        // elems = document.querySelector("#end_date");
        // instance = M.Datepicker.getInstance(elems);
        // instance.setInputValue();
        setSearch({ type: "All", title: "", start_date: "", end_date: "", page: 0 })
        getActivity()
    }
    const handleGetStudentList = async id => {
        dispatch(LoadingAction.showLoader())
        // const authResponse = await this.auth();
        // if (authResponse) {
        const url = `${configure.api_url}${urlEndPoints.getEnrolledStudents(id)}`

        // const headers = {
        // 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await networkRequest(url)
            dispatch(LoadingAction.hideLoader())
            if (!data.data.message) {
                const list = _.orderBy(data.data.results, ["field_student_name"], ["asc"])
                setStudentList(list)
                setSelectedActivityId(id)

                let elems = document.querySelector("#enrollments");
                elems.setAttribute("aria-hidden", "false")
                elems.setAttribute("tabindex", "-1")
                let options = {
                    inDuration: 1000,
                    opacity: 1, dismissible: true
                }
                let instance = M.Modal.getInstance(elems, options);
                instance.open();
            }
        } catch (error) {
            dispatch(LoadingAction.hideLoader())
            console.warn("error: ", error)
        }
        // } else {
        // 	props.hideLoader()
        // }
    }
    const handleCloseModal = () => {
        initState();
        let elems = document.querySelector("#checkQid");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")

        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addStudent");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")

        instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#enrollments");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")

        instance = M.Modal.getInstance(elems);
        instance.close();
    }
    const handleDetailRedirect = id => {
        navigate(`/activity/${id}`);
    }
    const handleCancelMultipleActivity = async () => {
        dispatch(LoadingAction.showLoader())
        if (!_.isEmpty(checkedList)) {
            // const authResponse = await this.auth();
            // if (authResponse) {
            const url = `${configure.api_url}${urlEndPoints.cancelActivity}`
            const obj = {
                "activity_id": selectedActivityId,
                user_ids: checkedList
            }
            // const headers = {
            // 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            try {
                const data = await networkRequest(url, "POST", obj);
                if (data.status == 200) {
                    if (!_.isEmpty(data.data.error)) {
                        dispatch(PopupAction.showPopup({
                            type: "error",
                            title: context.t("_error"),
                            message: data.data.error.name[lang],
                            isOpen: true,
                            buttonText: context.t("_ok")
                        }))
                    } else {
                        dispatch(PopupAction.showPopup({
                            type: "success",
                            title: context.t("_success"),
                            message: context.t("_successfully_cancelled"),
                            isOpen: true,
                            buttonText: context.t("_ok")
                        }))
                    }
                    handleCloseModal();
                    getActivity()
                }
            } catch (error) {
                dispatch(PopupAction.showPopup({
                    type: "error",
                    title: context.t("_failed"),
                    message: context.t("_failed_to_cancel"),
                    isOpen: true,
                    buttonText: context.t("_ok")
                }))
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
            // }
        } else {
            handleCloseModal();
            getActivity()
        }

    }
    function scrollStep() {
        var scrollStep = props.scrollStepInPx ? scrollStepInPx : scrollStepInPx;
        if (window.pageYOffset === 0) {
            clearInterval(intervalId);
        }
        window.scroll(0, window.pageYOffset - scrollStep);
    }
    function scrollToTop() {
        window.scroll({ top: 0, left: 0, behavior: "smooth" })
        // var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
        // let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
        // this.setState({ intervalId: intervalId });
    }
    const handleCloseConfirmModal = (state) => {
        setCancelActivityConfirm(false)
        setCancelActivityId(null)
    }
    const handleOpenCancelActivityConfirm = id => {
        setCancelActivityConfirm(true)
        setCancelActivityId(id)
    }
    const handleContinueCancelActivity = () => {
        if (!_.isEmpty(cancelActivityId))
            handelCancelActivity(cancelActivityId)
        handleCloseConfirmModal("cancelActivityConfirm")
    }
    const showStatus = status => {
        switch (status) {
            case "Unpublished":
                return context.t("_pending");
            case "Waiting List":
                return context.t("_waiting_list");

            default:
                return status;
        }
    }
    const handleCancelEnrolment = async (studentId) => {
        dispatch(LoadingAction.showLoader())
        if (!_.isEmpty(studentId)) {
            const url = `${configure.api_url}${urlEndPoints.cancelActivity}`
            const obj = {
                "activity_id": selectedActivityId,
                "user_ids": {
                    0: studentId
                }
            }
            try {
                const data = await networkRequest(url, "POST", obj);
                if (data.status == 200) {
                    if (!_.isEmpty(data.data.error)) {
                        dispatch(PopupAction.showPopup({
                            type: "error",
                            title: context.t("_error"),
                            message: data.data.error.name[lang],
                            isOpen: true,
                            buttonText: context.t("_ok")
                        }))
                    } else {
                        dispatch(PopupAction.showPopup({
                            type: "success",
                            title: context.t("_success"),
                            message: context.t("_successfully_cancelled"),
                            isOpen: true,
                            buttonText: context.t("_ok")
                        }))
                    }
                    // handleCloseModal();
                    handleGetStudentList(selectedActivityId)
                }
            } catch (error) {
                dispatch(PopupAction.showPopup({
                    type: "error",
                    title: context.t("_failed"),
                    message: context.t("_failed_to_cancel"),
                    isOpen: true,
                    buttonText: context.t("_ok")
                }))
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
        }

    }
    const handleOpenQidCheck = () => {
        for (var key in formData) {
            formData[key].error = "";
        }
        setFormData({ ...formData })
        let elems = document.querySelector("#checkQid");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        let instance = M.Modal.getInstance(elems);
        instance.open();
    }

    const validate = (type) => {
        for (var key in formData) {
            formData[key].value = formData[key].value.trim();
            formData[key].error = "";
        }
        if (type == "qid") {
            if (formData.studentId.value == "")
                formData.studentId.error = context.t("_qid_required");

            if (formData.studentId.value != "" && !qidValidate.test(formData.studentId.value))
                formData.studentId.error = context.t("_invalid_qid");

        } else {
            // if (formData.parentName.value == "")
            //     formData.parentName.error = context.t("_parent_name_required");

            // if (formData.parentName.value != "" && !nameValidate.test(formData.parentName.value))
            //     formData.parentName.error = context.t("_invalid_parent_name");

            // if (formData.parentName.value != "" && nameValidate.test(formData.parentName.value) && !nameWordLengthCheck(formData.parentName.value))
            //     formData.parentName.error = context.t("_parent_name_contains_atleast_two_word");

            // if (formData.dob.value == "")
            //     formData.dob.error = context.t("_date_of_birth_required");

            if (_.isEmpty(formData.email.value))
                formData.email.error = context.t("_email_required");

            if (formData.email.value != "" && !emailValidate.test(formData.email.value))
                formData.email.error = context.t("_invalid_email");

            // if (formData.mobileNumber.value == "")
            //     formData.mobileNumber.error = context.t("_mobile_number_required");

            // if (formData.mobileNumber.value != "" && !phoneValidate.test(formData.mobileNumber.value))
            //     formData.mobileNumber.error = context.t("_invalid_mobile_number");
        }
        for (var key in formData) {
            if (formData[key].error != "") {
                setFormData(formData)
                setSubmissionStatus(false)
                // scrollToTop();
                // setTimeout(() => {
                //     this[key + "error"].focus()
                // }, 1000);
                return false;

            }
        }
        return true;
    }
    const displayError = error => {
        if (error.qid)
            formData.studentId.error = error.qid[lang]

        if (error.name)
            formData.name.error = error.name[lang]

        if (error.mail)
            formData.email.error = error.mail[lang]

        if (error.DOB)
            formData.dob.error = error.DOB[lang]

        setFormData({ ...formData })

    }
    const handleCheckQid = async (event) => {
        event.preventDefault();

        if (validate("qid")) {
            dispatch(LoadingAction.showLoader())
            const url = `${configure.api_url}${urlEndPoints.checkStudentQid(formData.studentId.value)}`
            try {
                const data = await networkRequest(url)
                if (data.status == 200) {
                    if (data.data.error) {
                        dispatch(LoadingAction.hideLoader())
                        displayError(data.data.error)
                    } else {
                        if (data.data && data.data.length == 0) {
                            dispatch(LoadingAction.hideLoader())
                            handleOpenAddStudentModal();
                        } else {
                            enrollActivity(data.data[0].uid)
                            // const parentList = await getMyParents();
                            // setState({ parentList })
                            //             dispatch(LoadingAction.hideLoader())
                            handleCloseModal()
                        }
                    }
                }
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
        }
    }
    const enrollActivity = async (id) => {
        if (selectedActivityId) {
            dispatch(LoadingAction.showLoader())
            const url = `${configure.api_url}${urlEndPoints.activityEnrolment}`
            const obj = {
                "activity_id": selectedActivityId,
                "user_ids": { "0": id }
            }
            try {
                const data = await networkRequest(url, "POST", obj)
                if (data.data.error) {
                    dispatch(PopupAction.showPopup({
                        type: "error",
                        title: context.t("_error"),
                        message: `${data.data.error.name[lang]}`,
                        isOpen: true,
                        buttonText: context.t("_ok")
                    }))
                } else {
                    const message = context.t("_enroll_success");
                    dispatch(PopupAction.showPopup({
                        type: "success",
                        title: context.t("_success"),
                        message,
                        isOpen: true,
                        buttonText: context.t("_ok")
                    }))
                }
                handleGetStudentList(selectedActivityId)
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
        }
    }

    const handleInviteStudent = async (event) => {
        event.preventDefault();
        if (validate()) {
            dispatch(LoadingAction.showLoader())
            let obj = {
                email: formData.email.value
            }
            const url = `${configure.api_url}${urlEndPoints.sendInvitation}`
            try {
                const data = await networkRequest(url, "POST", obj)
                if (data.data.error) {
                    displayError(data.data.error)
                    dispatch(LoadingAction.hideLoader())
                }
                else {
                    dispatch(PopupAction.showPopup({
                        type: "success",
                        title: context.t("_success"),
                        message: data.data.message[lang],
                        isOpen: true,
                        buttonText: context.t("_ok")
                    }))
                    handleCloseModal();
                    handleGetStudentList(selectedActivityId)
                    // getStudentList()
                }
            } catch (error) {
                dispatch(LoadingAction.hideLoader())
                console.warn("error: ", error)
            }
        }
    }
    const handleOpenAddStudentModal = () => {
        for (var key in formData) {
            formData[key].error = "";
        }
        setFormData({ ...formData })
        let elems = document.querySelector("#checkQid");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addStudent");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        instance = M.Modal.getInstance(elems);
        instance.open();
    }
    const handleFormChange = (field, value, state) => {
        if (field == "parentQid")
            if (isNaN(value))
                return false
        formData[field]["class"] = value ? "" : "invalid";
        formData[field]["value"] = value;
        setFormData({ ...formData })
    };
    const getDateInFormat = date => {
        // if (!_.isEmpty(date)) {
        if (date.includes("Jan"))
            date = date.replace("Jan", context.t("_jan"))

        if (date.includes("Feb"))
            date = date.replace("Feb", context.t("_feb"))

        if (date.includes("Mar"))
            date = date.replace("Mar", context.t("_mar"))

        if (date.includes("Apr"))
            date = date.replace("Apr", context.t("_apr"))

        if (date.includes("May"))
            date = date.replace("May", context.t("_may"))

        if (date.includes("Jun"))
            date = date.replace("Jun", context.t("_jun"))

        if (date.includes("Jul"))
            date = date.replace("Jul", context.t("_jul"))

        if (date.includes("Aug"))
            date = date.replace("Aug", context.t("_aug"))

        if (date.includes("Sep"))
            date = date.replace("Sep", context.t("_sep"))

        if (date.includes("Oct"))
            date = date.replace("Oct", context.t("_oct"))

        if (date.includes("Nov"))
            date = date.replace("Nov", context.t("_nov"))

        if (date.includes("Dec"))
            date = date.replace("Dec", context.t("_dec"))
        // }
        return date
    }
    useEffect(() => {
        (async () => {
            dispatch(LoadingAction.showLoader())
            document.title = `${context.t("_studio56")} | ${context.t("_enrolment_listing")}`
            initState();
            await initSearchComponents();
            M.AutoInit();
            await getActivity();

            window.addEventListener('load', handleTabIndex());
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        })()
    }, [])
    useEffect(() => {
        const filteredList = search.type === "All"
            ? currentActivityList
            : currentActivityList.filter(data => data.field_event_type.toLowerCase() === search.type);

        setListActivity(filteredList);
    }, [search, currentActivityList]);
    return (
        <div id="main-content" className="activiti-list">
            <SlideToTop />
            <PageTitle title={context.t("_enrolment_listing")} />
            <div className="container">
                <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                    <ul>
                        <li className="breadcrumb-item">
                            <Link to="/admin-profile">{context.t("_profile")}</Link> {" "}

                        </li>
                        <li className="breadcrumb-item">
                            <Link tabIndex="0" to aria-current={context.t("_page")}>
                                <span>{context.t("_enrolment_listing")} </span>
                            </Link>

                        </li>
                    </ul>
                </nav>
            </div>
            <div id="main-container">
                <div id="skipContent" className="container">
                    <form onSubmit={filterFormSubmit} ref={myFormRef}>
                        <div className="row topFormHolder">
                            <div className="col s12 m6 l3">
                                <div className={`input-field mobileSelect item ${lang}`}>
                                    <label htmlFor="activityType" className="active" style={dynamicStyles.position(lang)}>{context.t("_activity_type")}</label>
                                    <select className="browser-default" id="activityType" value={search.title} onChange={evt => handleChange("title", evt.target.value)}>
                                        <option value="" disabled >{context.t("_select_activity_type")}</option>
                                        <option value="All" >{context.t("_all")}</option>
                                        <option value="competition">{context.t("_competitions")}</option>
                                        <option value="session">{context.t("_sessions")}</option>
                                        <option value="event">{context.t("_events")}</option>
                                        <option value="challenge">{context.t("_challenges")}</option>
                                        <option value="project">{context.t("_projects")}</option>
                                    </select>
                                    <span className="helper-text"></span>
                                </div>
                            </div>

                            <div className="col s12 m6 l3 date-popup">
                                <div className={`input-field item  ${lang}`}>
                                    <DatePicker
                                        name="start_date"
                                        placeholderText={context.t("_date")}
                                        id="start_date"
                                        autoComplete="off"
                                        dateFormat="yyyy-MM-dd"
                                        locale={calenderLocal(context)}
                                        customInput={<CustomDateInput ariaLabel={context.t("_select_date_button")} onFieldChange={date => handleChange("start_date", new Date(date))} />}
                                        onChange={date =>
                                            handleChange("start_date", date)
                                        }
                                        onCalendarOpen={e => {
                                            let elems = document.querySelector(".react-datepicker");
                                            elems.setAttribute("tabindex", "0")
                                            elems && elems.focus()
                                            elems = document.querySelector(".react-datepicker__month-select");
                                            elems.setAttribute("aria-label", context.t("_select_month"))
                                            elems = document.querySelector(".react-datepicker__year-select");
                                            elems.setAttribute("aria-label", context.t("_select_year"))

                                        }}
                                        onCalendarClose={e => {
                                            var elems = document.querySelector("#start_date");
                                            elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(search.start_date, "dd-mm-yyyy")}`)
                                            elems.setAttribute("value", dateformat(search.start_date, "yyyy-mm-dd"))
                                            setTimeout(() => {
                                                elems && elems.focus()
                                            }, 200);
                                        }}
                                        style={dynamicStyles.textAlign(lang)}
                                        value={search.start_date}
                                        selected={search.start_date}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                    <label htmlFor="start_date" style={dynamicStyles.position(lang)}>{context.t("_start_date")}</label>
                                </div>
                            </div>

                            <div className="col s12 m6 l3 date-popup">
                                <div className="input-field item">
                                    <DatePicker
                                        name="end_date"
                                        placeholderText={context.t("_date")}
                                        id="end_date"
                                        autoComplete="off"
                                        dateFormat="yyyy-MM-dd"
                                        locale={calenderLocal(context)}
                                        customInput={<CustomDateInput ariaLabel={context.t("_select_date_button")} onFieldChange={date => handleChange("end_date", new Date(date))} />}
                                        onChange={date =>
                                            handleChange("end_date", date)
                                        }
                                        onCalendarOpen={e => {
                                            let elems = document.querySelector(".react-datepicker");
                                            elems.setAttribute("tabindex", "0")
                                            elems && elems.focus()
                                            elems = document.querySelector(".react-datepicker__month-select");
                                            elems.setAttribute("aria-label", context.t("_select_month"))
                                            elems = document.querySelector(".react-datepicker__year-select");
                                            elems.setAttribute("aria-label", context.t("_select_year"))

                                        }}
                                        onCalendarClose={e => {
                                            var elems = document.querySelector("#end_date");
                                            elems.setAttribute("aria-label", `${context.t("_selected_date")} ${dateformat(search.end_date, "dd-mm-yyyy")}`)
                                            elems.setAttribute("value", dateformat(search.end_date, "yyyy-mm-dd"))
                                            setTimeout(() => {
                                                elems && elems.focus()
                                            }, 200);
                                        }}
                                        style={dynamicStyles.textAlign(lang)}
                                        value={search.end_date}
                                        selected={search.end_date}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                    <label htmlFor="end_date" style={dynamicStyles.position(lang)}>{context.t("_end_date")}</label>
                                </div>
                            </div>

                            <div className="col s12 m6 l3 valign-wrapper center-align search-btn-holder">
                                <button className="btn noWidth profileBtn yellow waves-effect waves-light">
                                    {context.t("_search")} <i className={`tiny material-icons ${lang}`} >{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
                                </button>
                                <button type="button" onClick={() => clearSearch()} className="btn noWidth  blue-btn waves-effect waves-light">
                                    {context.t("_clear")}
                                </button>
                                <br /><br />
                            </div>
                        </div>
                    </form>

                    <div className="row">
                        <div className="col s7 result-status">
                            {isLoaded ? context.t("_search_result") + " ( " + (listActivity.length) + " of " + pager.count + " )" : ""}

                        </div>
                        <div className="col s5 result-type">
                            <div className={`input-field mobileSelect col s12 filter-select  ${lang}`}>
                                <label htmlFor="result-type">{context.t("_select_activity")}</label>
                                <select className="browser-default" id="result-type" value={search.type} onChange={evt => handleChange("type", evt.target.value)}>
                                    <option value="All" >{context.t("_all")}</option>
                                    <option value="competition">{context.t("_competitions")}</option>
                                    <option value="session">{context.t("_sessions")}</option>
                                    <option value="event">{context.t("_events")}</option>
                                    <option value="challenge">{context.t("_challenges")}</option>
                                    <option value="project">{context.t("_projects")}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="activities">
                        {listActivity.map((row, index) => (
                            <div className="row" key={index}>
                                <div className="col s7">
                                    <Link to={`/activity/${row.nid}`}>
                                        <h2 className="title">{row.title_1 || row.title} <span className={row.field_event_type.toLowerCase()}>{getType(row.field_event_type, context)}</span></h2>
                                    </Link>

                                    <div className="details">
                                        {row.field_start_and_end_time_3 &&
                                            <span><i className="calendar-icons"></i> {context.t("_start_date")} <span className="date">{getDateInFormat(row.field_start_and_end_time_3[0])}</span></span>
                                        }
                                        {row.field_start_and_end_time_4 &&
                                            <span><i className="calendar-icons"></i> {context.t("_end_date")} <span className="date">{getDateInFormat(row.field_start_and_end_time_4[row.field_start_and_end_time_4.length - 1])}</span></span>
                                        }
                                    </div>
                                </div>
                                <div className="col s5 btn-wrap flex-row justify-end align-center">
                                    {/* {
                                            localStorage.getItem("role") == "volenteer" ?
                                                <button onClick={() => handleOpenCancelActivityConfirm(row.nid)} className="btn blue-border">{context.t("_cancel")}</button> :
                                                localStorage.getItem("role") == "student" ?
                                                    <button onClick={() => handleOpenCancelActivityConfirm(row.nid)} className="btn blue-border">{context.t("_cancel")}</button>
                                                    :
                                                    <button onClick={() => handleGetStudentList(row.nid)} className="btn blue-border">{context.t("_enrollments")}</button>
                                        } */}

                                    <Link to={`/admin-enrolment/${row.nid}`} className="btn blue-border">{context.t("_enrollments")}</Link>

                                </div>
                            </div>
                        ))}
                        {!currentActivityList.length && isLoaded &&
                            <div className="row"><p aria-label={context.t("_no_result_found")} className="col s12 no-result">{context.t("_no_result_found")}</p></div>
                        }
                    </div>
                    <div id="enrollments" className="modal user-add-modal" role="dialog">
                        <div className="modal-content">
                            {/* <form onSubmit={handleAddNewStudent} className="col full-width"> */}
                            <button className="badge modalClose" aria-label="close" onClick={() => handleCloseModal()}>
                                <i className="material-icons">close</i>
                            </button>
                            <h2 className="line">{context.t("_enrollments")}</h2>
                            {
                                _.isEmpty(studentList) ?
                                    <div className="page-not-found center">
                                        <br />
                                        <h4>{context.t("_no_student_to_list")}</h4>
                                    </div>
                                    :
                                    <div className="tableHolder">

                                        <table className="responsive-table highlight">
                                            <thead className="blue lighten-5">
                                                <tr>
                                                    <th><strong>{context.t("_name")}</strong></th>
                                                    <th><strong>{context.t("_qid")}</strong></th>
                                                    <th><strong>{context.t("_phone_number")}</strong></th>
                                                    <th><strong>{context.t("_grade")}</strong></th>
                                                    <th><strong>{context.t("_gender")}</strong></th>
                                                    <th><strong>{context.t("_age")}</strong></th>
                                                    <th width="50"></th>
                                                    {/* <th width="50"><strong></strong></th> */}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    studentList.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.field_student_name}</td>
                                                                <td>{item.field_qid}</td>
                                                                <td>{item.field_student_mobile}</td>
                                                                <td>{item.field_grade}</td>
                                                                <td>{item.field_gender}</td>
                                                                <td>{!_.isEmpty(item.field_student_dob) ? calculateAge(item.field_student_dob) : null}</td>
                                                                <td>
                                                                    <i onClick={() => handleCancelEnrolment(item.field_student)} className="material-icons attendanceClose tooltipped" data-position="right" data-tooltip="Remove enrolment">delete_forever</i>
                                                                </td>
                                                                {/* <td>
                                                                {
                                                                    item.field_published_status == "Published" ? <p className="checkboxHolder"><label><input onChange={(e) => handleCheck(e, item.uid, index)} checked={checkedList[index] ? true : false} type="checkbox" /><span></span></label></p> : showStatus(item.field_published_status)
                                                                }
                                                            </td> */}
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <br />
                                        <button type="button" onClick={() => { handleOpenQidCheck() }} className="btn noWidth  blue-btn waves-effect waves-light pull-right">{context.t("_add_student")}</button>
                                    </div>
                            }
                            <div id="checkQid" className="modal sm" role="dialog">
                                <div className="modal-content">
                                    {
                                        _.isEmpty(formData) ?
                                            <div /> :
                                            <div>
                                                <span className="badge modalClose"><button className="close" aria-label="close" onClick={() => handleCloseModal()}><i className="material-icons">close</i></button></span>
                                                <h2 className="line">{context.t("_add_student")}</h2>
                                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit.</p> */}
                                                {hasError ? <div className="error-container fail">
                                                    <h5>{context.t("_error_title")}</h5>
                                                    <ul>
                                                        {formData.studentId.error != "" ? <li ><label className="error" htmlFor="studentId">{formData.studentId.error}</label></li> : null}
                                                    </ul>
                                                </div> : null
                                                }
                                                <form onSubmit={handleCheckQid} className="row full-width">
                                                    <div className="col s12">
                                                        <div className="input-field item">
                                                            <input
                                                                placeholder={context.t("_parent_id_placeholder")}
                                                                type="text"
                                                                name="studentId"
                                                                id="studentId"
                                                                maxLength={11}
                                                                onChange={(evt) => handleFormChange("studentId", evt.target.value, "formData")}
                                                                onFocus={(evt) => handleFormChange("studentId", evt.target.value, "formData")}
                                                                value={formData.studentId.value}
                                                            />
                                                            <label htmlFor="studentId" style={dynamicStyles.position(lang)}>{context.t("_student_qid")} <span className="asterisk">*</span></label>
                                                            <span
                                                                style={dynamicStyles.textAlign(lang)}
                                                                className="helper-text"
                                                                data-error="Required field."
                                                            ></span>
                                                        </div>
                                                    </div>

                                                    <div className="col s12">
                                                        <button type="button" onClick={handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{context.t("_cancel")}</button>
                                                        <button className="btn profileBtn blue noWidth waves-effect waves-light">{context.t("_add")}</button>
                                                    </div>
                                                </form>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div id="addStudent" className="modal sm user-add-modal" role="dialog">
                                <div className="modal-content">
                                    {
                                        _.isEmpty(formData) ?
                                            <div /> :
                                            <form onSubmit={handleInviteStudent} className="col full-width">
                                                <span className="badge modalClose"><button className="close" aria-label="close" onClick={() => handleCloseModal()}><i className="material-icons">close</i></button></span>
                                                <h2 className="line">{context.t("_add_student")}</h2>

                                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p> */}
                                                {hasError ? <div className="error-container fail">
                                                    <h5>{context.t("_error_title")}</h5>
                                                    <ul>
                                                        {
                                                            Object.keys(formData).map((item, index) => {
                                                                return formData[item].error != "" ? <li key={index} ref={(refItem) => { this[item + "error"] = refItem; }}><label className="error" htmlFor={item}>{formData[item].error}</label></li>
                                                                    :
                                                                    null
                                                            })}
                                                    </ul>
                                                </div> : null
                                                }
                                                <p>{context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

                                                <div className="row">
                                                    <div className="col s12">
                                                        <div className="input-field item">
                                                            <input
                                                                placeholder={context.t("_parent_email_address_placeholder")}
                                                                type="text"
                                                                name="email"
                                                                id="email"
                                                                onChange={(evt) => handleFormChange("email", evt.target.value, "formData")}
                                                                onFocus={(evt) => handleFormChange("email", evt.target.value, "formData")}
                                                                value={formData.email.value}
                                                            />
                                                            <label htmlFor="email" style={dynamicStyles.position(lang)}>{context.t("_parent_email_address")} <span className="asterisk">*</span></label>
                                                            <span
                                                                style={dynamicStyles.textAlign(lang)}
                                                                className="helper-text"
                                                                data-error="Required field."
                                                            ></span>
                                                        </div>
                                                    </div>
                                                    <div className="col s12">
                                                        <button className="btn profileBtn blue waves-effect waves-light">{context.t("_send_invitation")}</button>
                                                    </div>
                                                    <div className="clearfix" />
                                                </div>
                                            </form>
                                    }
                                </div>
                            </div>
                            {/* <button onClick={() => { handleCancelMultipleActivity() }} className="btn blue-border">Cancel</button> */}
                            {/* </form> */}
                            {/* {
									_.isEmpty(studentFormData) ?
										<div /> :
										<form onSubmit={handleAddNewStudent} className="col full-width">
											<span className="badge modalClose"><button className="close" aria-label="close" onClick={() => handleCloseModal()}><i className="material-icons">close</i></button></span>
											<h2 className="line">{context.t("_add_student")}</h2>

											<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p>
											{hasError ? <div className="error-container fail">
												<h5>{context.t("_error_title")}</h5>
												<ul>
													{
														Object.keys(studentFormData).map((item, index) => {
															return studentFormData[item].error != "" ? <li key={index} ref={(refItem) => { this[item + "error"] = refItem; }}><label className="error" htmlFor={item}>{studentFormData[item].error}</label></li>
																:
																null
														})}
												</ul>
											</div> : null
											}
											<p>{context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

											<div className="row">
												<div className="col s12 m6">
													{renderInput({ name: "name", label: context.t("_student_name"), placeholder: context.t("_student_name_placeholder"), isMandatory: true })}
													{renderInput({ name: "email", label: context.t("_email"), placeholder: context.t("_email_placeholder"), type: "email" })}

												</div>
												<div className="col s12 m6">
													<div className="date-popup">
														{renderInput({ name: "dob", label: context.t("_dob"), placeholder: context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
													</div>

													{renderInput({ name: "mobile", label: context.t("_mobile_number"), placeholder: context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number" })}
												</div>
												<div className="col s12">
													<button className="btn profileBtn blue waves-effect waves-light">{context.t("_save_changes")}</button>
												</div>
												<div className="clearfix" />
											</div>
										</form>
								} */}
                        </div>
                    </div>
                    <nav className="pagination-wrapper" aria-label="pagination">
                        {(currentActivityList.length && isLoaded) ? (
                            <Pagination
                                prevPageText={`< ${context.t("_previous")}`}
                                nextPageText={`${context.t("_next")} >`}
                                hideFirstLastPages={true}
                                hideDisabled={true}
                                linkClassPrev="linkClassPrev"
                                linkClassNext="linkClassNext"
                                activePage={pager.current_page + 1}
                                itemsCountPerPage={pager.items_per_page}
                                totalItemsCount={pager.count}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange}
                            />) : ""
                        }
                    </nav>
                </div>
                <div className="sparkles">
                    <span className="orange-circle"></span>
                    <span className="multi-square two"><b><i></i></b></span>
                    <span className="grey-square-rotate red-sq one"></span>
                    <span className="grey-square-rotate red-sq two"></span>
                </div>
            </div>
            <div className="top-line ng-scope">
                <img src={topLineImage} alt="" />
            </div>
            <Modal
                isOpen={cancelActivityConfirm}
                style={customModalStyles}
                contentLabel="unlink parent confirm modal"
                ariaHideApp={false}
            >
                <div className="popupAlert warning">
                    <br />
                    <h3>{context.t("_confirm")}</h3>
                    <p>{context.t("_cancel_activity_confirm")}</p>
                    <div className="row">
                        <button className="col btn blue" onClick={() => handleCloseConfirmModal("cancelActivityConfirm")}>{context.t("_cancel")}</button>
                        <button className="col btn profileBtn yellow" onClick={() => handleContinueCancelActivity()}>{context.t("_continue")}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

EnrolmentListing.contextTypes = {
    t: PropTypes.func
}

export default EnrolmentListing
