import { GET_CREATIVE_ZONE, GET_SOFTWARE_ZONE, GET_TECHNOLOGY_ZONE } from "./../../constants/constants";

const initialState = {
    creativeZoneCount: 0,
    softwareZoneCount: 0,
    technologyZoneCount: 0,
};

const zoneCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CREATIVE_ZONE:
            return {
                ...state,
                creativeZoneCount: action.payload
            };
        case GET_SOFTWARE_ZONE:
            return {
                ...state,
                softwareZoneCount: action.payload
            };
        case GET_TECHNOLOGY_ZONE:
            return {
                ...state,
                technologyZoneCount: action.payload
            };
        default:
            return { ...state };
    }
};

export default zoneCountReducer;
