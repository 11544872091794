import React, { useState, useEffect } from "react"
import Main from "../../constants/utilities.js"
import SlideToTop from "../includes/slide_to_top.js"
import PageTitle from "../includes/page_title.js"
import { connect, useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { LoadingAction, LoginAction } from "./../../actions"
import { passwordValidation, withRouter } from "./../../constants/common"
import { authRequest } from "../../httpAPI/api.js"



const ResetPassword = (props, context) => {


	const { config, networkRequest } = Main();

	const [isLoaded, setIsLoaded] = useState(false);
	const [configuration, setConfiguration] = useState(config())
	const [formData, setFormData] = useState({});
	const [error, setError] = useState("");
	const [intervalId, setIntervalId] = useState(0);
	const [scrollStepInPx, setScrollStepInPx] = useState(50);
	const [delayInMs, setDelayInMs] = useState(16.66);
	const [submissionStatus, setSubmissionStatus] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isLinkValid, setIsLinkValid] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const { lang } = useSelector((state) => state.i18nState)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { token } = useParams()
	// this.props.showLoader()
	// this.handleChange = this.handleChange.bind(this);
	// this.onSubmit = this.onSubmit.bind(this);


	useEffect(() => {
		dispatch(LoadingAction.showLoader())
		document.title = `${context.t("_studio56")} | ${context.t("_reset_password")}`
		// const { match: { params } } = this.props
		// const { lang } = this.props;
		// if (lang != params.lang) {
		// 	localStorage.setItem("lang", lang == "en" ? "ar" : "en");
		// 	window.location.reload();
		// }
		// isLoggedInUsers();
		initState();
		checkTokenValidity()
	}, []);

	useEffect(() => {
		document.title = `${context.t("_studio56")} | ${context.t("_reset_password")}`

	}, []);



	const checkTokenValidity = async () => {


		const obj = {
			"url_token": token
		}
		const url = `${configuration.api_url}${urlEndPoints.linkValidity}`
		try {
			const data = await networkRequest(url, "POST", obj, null, true)
			dispatch(LoadingAction.hideLoader())
			if (data.status == 200 && data.data.valid) {
				setIsLoading(false)
				setIsLinkValid(true)

			} else {
				setIsLoading(false)
				setIsLinkValid(false)

			}
			// if (data.data.error) {
			// 		dispatch(LoadingAction.hideLoader())
			// 	this.displayError(data.data.error)
			// }
			// else {
			// 	setSubmissionStatus(true)
			// 	initState();
			// 	scrollToTop();
			// 	dispatch(LoadingAction.hideLoader())
			// }
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
	}
	const initState = () => {
		setFormData({
			password: { value: "", error: "", class: "" },
			cpassword: { value: "", error: "", class: "" },
		})
	}
	const handleChange = (field, value) => {
		formData[field]["class"] = value ? "" : "invalid";
		formData[field]["value"] = value;
		// contact_form.submit = submit;
		setFormData({ ...formData })
		setSubmissionStatus(false)
	}
	const scrollStep = () => {
		var scrollStepInPx = scrollStepInPx;
		if (window.pageYOffset <= 400) {
			clearInterval(intervalId);
		}
		window.scroll(0, window.pageYOffset - scrollStepInPx);
	}
	const scrollToTop = () => {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
		// var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
		// let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
		// this.setState({ intervalId: intervalId });
	}
	const validate = () => {


		for (var key1 in formData) {
			formData[key1].value = formData[key1].value.trim();
			formData[key1].error = "";
		}

		if (formData.password.value === "")
			formData.password.error = context.t("_password_is_required");




		if (formData.password.value != "" && !passwordValidation.test(formData.password.value)){
			formData.password.error = context.t("_password_condition");
		}
			

		if (formData.cpassword.value === "")
			formData.cpassword.error = context.t("_confirm_password_required");

		if ((formData.password.value !== "" && formData.cpassword.value !== "") && (formData.password.value !== formData.cpassword.value))
			formData.password.error = context.t("_password_mismatch");


		for (var key in formData) {
			if (formData[key].error !== "") {
				setFormData({ ...formData })
				setSubmissionStatus(false)
				setTimeout(() => {
					document.querySelector(`a[href="#${key}"]`).focus()
				}, 1000);
				return false;

			}
		}
		return true;
	}


	const onSubmit = async (event) => {
		event.preventDefault();

		if (validate()) {
			dispatch(LoadingAction.showLoader())
			const obj = {
				"url_token": token,
				"pass": formData.password.value
			}
			const url = `${configuration.api_url}${urlEndPoints.resetPassword}`
			try {
				const data = await networkRequest(url, "POST", obj, null, true)
				if (data.data.error) {
					dispatch(LoadingAction.hideLoader())
					// displayError(data.data.error)
				}
				else {
					setSubmissionStatus(true)
					handleAuthAPi(data.data.email, formData.password.value)
					// this.scrollToTop();
					// this.props.hideLoader();
					// this.props.history.push("/login?type=relogin");
				}
			} catch (error) {
				dispatch(LoadingAction.hideLoader())
				console.warn("error: ", error)
			}
		}
	}
	const handleGoBack = () => {
		navigate("/forgot-password");
	}




	const handleAuthAPi = async (username, password) => {

		dispatch(LoadingAction.showLoader())

		if (validate()) {
			const obj = {
				"username": username.trim().replace(/[\u200B-\u200D\uFEFF]/g, ""),
				"password": password,
				"client_secret": configuration.client_secret,
				"grant_type": configuration.grant_type,
				"client_id": configuration.client_id
			}
			const authUrl = `${configuration.origin}${urlEndPoints.auth}`
			authRequest(authUrl, "POST", obj).then(authResponse => {
				if (!_.has(authResponse, "error")) {
					localStorage.setItem("accessToken", authResponse.data.access_token);
					localStorage.setItem("refreshToken", authResponse.data.refresh_token);
					localStorage.setItem("tokenType", authResponse.data.token_type);
					loginApi(authResponse)
					initState()
					// this.loginForm.reset();
				} else {
					dispatch(LoadingAction.hideLoader())
					const errorResponse = authResponse.data.message;
					setError(errorResponse)
					// this.setState({
					// 	"success": "",
					// 	"error": errorResponse
					// });
				}
			}).catch(errorResponse => {
				console.warn(JSON.parse(JSON.stringify(errorResponse)))
				dispatch(LoadingAction.hideLoader())
				setError(context.t("_invalid_credentials"))

				// this.setState({
				// 	"success": "",
				// 	"error": this.context.t("_invalid_credentials")
				// });
			})

		} else {
			dispatch(LoadingAction.hideLoader())
		}
	}
	const loginApi = async (authResponse) => {

		const loginUrl = `${configuration.api_url}${urlEndPoints.login}`
		// const loginHeader = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }

		try {
			const loginResponse = await networkRequest(loginUrl, "POST")
			getProfileData();
			dispatch(LoadingAction.hideLoader())
			if (loginResponse.data.success && loginResponse.data.status === 200) {
				localStorage.setItem("role", loginResponse.data.role);
				dispatch(LoginAction.userLoggedIn())
				// this.props.userLoggedIn();
				if (
					loginResponse.data.role == "trainer" ||
					loginResponse.data.role == "manager" ||
					loginResponse.data.role == "asistant" ||
					loginResponse.data.role == "edu_specialist" ||
					localStorage.getItem("role") == "administrator"
				) {
					// this.props.history.push("/admin-profile");
					window.location.href = `/${lang}/admin-profile`;
				} else {
					// this.props.history.push("/activities");
					window.location.href = `/${lang}/activities`;
				}
			}
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("loginResponse  error: ", JSON.parse(JSON.stringify(error)))
		}

	}
	const getProfileData = async () => {
		// const authResponse = await this.auth();
		// if (authResponse) {
		const url = `${configuration.api_url}${urlEndPoints.userDetails}`
		// const headers = {
		//     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		try {
			const data = await networkRequest(url)
			localStorage.setItem("profileImage", `${configuration.origin}${data.data.picture}`)
			dispatch(LoginAction.userLoggedIn(`${configuration.origin}${data.data.picture}`))
			// userLoggedIn(`${configuration.origin}${data.data.picture}`)
			// this.props.hideLoader()
			// this.setState({ profileData: data.data })
		} catch (error) {
			dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}
		// }
	}
	let hasError = false;
	Object.keys(formData).forEach((item, index) => {
		if (formData[item].error !== "")
			hasError = true
	})
	return (
		<div id="main-content">
			<SlideToTop />
			<PageTitle title={context.t("_reset_password")} />
			<div className="container">
				<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
					<ul>
						<li className="breadcrumb-item">
							<Link to="/">{context.t("_home")} </Link> {" "}
						</li>
						<li className="breadcrumb-item">
							<Link tabIndex="0" to aria-current={context.t("_page")}>
								<span>{context.t("_reset_password")}</span>
							</Link>
						</li>
					</ul>
				</nav>
			</div>
			<div id="skipContent">
				<div id="main-container">
					{
						!isLoading ? isLinkValid ?
							<div className="container "><div className="row col-8">
								{
									_.isEmpty(formData) ?
										<div /> :
										<form name="login" onSubmit={onSubmit} className="login-form">
											{error &&
												<div className="error-container fail">
													<ul>
														<li><label className="error" >{error}</label></li>
													</ul>
												</div>
											}
											{
												hasError ? <div className="error-container fail">
													<h5>{context.t("_error_title")}</h5>
													<ul>
														{
															Object.keys(formData).map((item, index) => {
																return formData[item].error !== "" ? <li key={index}><a href={`#${item}`} className="error" htmlFor={item}>{formData[item].error}</a></li>
																	:
																	null
															})}
													</ul>
												</div> : null

											}
											{
												submissionStatus ? <div className="error-container success">
													<h5>{context.t("_reset_password_success")}</h5>
												</div> : null
											}
											<div className="input-field item">
												<input name="password"
													aria-describedby="passinfo"
													className="fontEnglish"
													placeholder={context.t("_password")}
													onChange={evt => handleChange("password", evt.target.value)}
													onFocus={evt => handleChange("password", evt.target.value)}
													value={formData.password.value}
													id="password"
													type={showPassword ? "text" : "password"}
												/>
												<button onClick={() => setShowPassword(!showPassword)} type="button" aria-label={showPassword ? context.t("_hide_password") : context.t("_show_password")} className="showEyes">
													<i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
												</button>
												<label htmlFor="password" style={dynamicStyles.position(lang)}>{context.t("_password")}</label>
												<small id="passinfo" className="passinfo">{context.t("_password_condition")}</small>
												<span className="helper-text" data-error="Required field."></span>
											</div>
											<div className="input-field item">
												<input name="cpassword"
													className="fontEnglish"
													placeholder={context.t("_confirm_password")}
													onChange={evt => handleChange("cpassword", evt.target.value)}
													onFocus={evt => handleChange("cpassword", evt.target.value)}
													value={formData.cpassword.value}
													id="cpassword"
													type={showConfirmPassword ? "text" : "password"}
												/>
												<button onClick={() => setShowConfirmPassword(!showConfirmPassword)} type="button" aria-label={showPassword ? context.t("_hide_password") : context.t("_show_password")} className="showEyes">
													<i className={showConfirmPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
												</button>
												<label htmlFor="cpassword" style={dynamicStyles.position(lang)}>{context.t("_confirm_password")}</label>
												<span className="helper-text" data-error="Required field."></span>
											</div>

											<div className="btn-wrap reset-button-container">
												<button className="btn red login" >
													{context.t("_reset_password")} <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i>
												</button>
											</div>
										</form>
								}
							</div>

							</div>
							:
							<div className="page-not-found center">
								<h4>{context.t("_invalid_token")}</h4>
								<button onClick={() => handleGoBack()} className="btn profileBtn">{context.t("_go_forgot_password")}</button>
							</div>
							: null
					}
				</div>
			</div>
		</div>
	)
}


ResetPassword.contextTypes = {
	t: PropTypes.func
}
export default ResetPassword
