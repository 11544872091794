import React, { useState, useEffect } from "react"
import Slider from "react-slick";
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import Main from "../../constants/utilities"
import { LoadingAction } from "../../actions"
import PageTitle from "../includes/page_title.js"
import SlideToTop from "../includes/slide_to_top.js"
import { urlEndPoints } from "../../httpAPI/apiConfig"
import topLineImage from "../../assets/images/top-line.png";
const axios = require("axios");
const PrivacyPolicies = (props, context) => {
	const { config, networkRequest } = Main();
    const configure = config()
    const dispatch = useDispatch()

	const [isLoaded, setIsLoaded] = useState(false);
	const [media, setMedia] = useState([]);
	const [slider, setSlider] = useState([]);
	const [pageKey, setPageKey] = useState("");
	const [id, setId] = useState("");
	const [aboutUsData, setAboutUsData] = useState([]);
	const [currentSlide, setCurrentSlide] = useState(0);
	const [privacyPolicyBody, setPrivacyPolicyBody] = useState("");
	const [privacyPolicyTitle, setPrivacyPolicyTitle] = useState("");
    const { lang } = useSelector(
        (state) => state.i18nState
    );

	useEffect(() => {
        dispatch(LoadingAction.showLoader())
		document.title = `${context.t("_studio56")} | ${context.t("_privacy_policy")}`
		getPrivacyPolicy()
	}, [])
	
	const getPrivacyPolicy = async () => {
		let url = ""
		if (lang == "ar")
			url = `${configure.origin}${lang}/${urlEndPoints.getPrivacyPolicy}`
		else
			url = `${configure.origin}${urlEndPoints.getPrivacyPolicy}`

		try {
			const data = await networkRequest(url)
			if (data.status == 200 && data.data && data.data.length > 0) {
				setPrivacyPolicyBody(data.data[0].body)
				setPrivacyPolicyTitle(data.data[0].title)
				dispatch(LoadingAction.hideLoader())
			}
		} catch (error) {
            dispatch(LoadingAction.hideLoader())
			console.warn("error: ", error)
		}

	}
	const afterChangeHandler = (newCurrentSlide) => {
		setCurrentSlide(newCurrentSlide);
	  };

		const slider_settings = {
			className: "slider",
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerMode: true,
			centerPadding: "60px",
			afterChange: afterChangeHandler
		};
		return (
			<>
				<div id="main-container">
					<SlideToTop />
					<PageTitle title={context.t("_privacy_policy")} isDashboardItem={true} />
					<div id="skipContent" className="container">
						<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
							<ul>
								<li className="breadcrumb-item">
									<Link to="/">{context.t("_home")}</Link>  {" "}
								</li>
								<li className="breadcrumb-item">
									<Link tabIndex="0" to aria-current={context.t("_page")}>
										<span>{context.t("_privacy_policy")}</span>
									</Link>
								</li>
							</ul>
						</nav>
					</div>

					<div className="container">
						<h2>{privacyPolicyTitle}</h2>
						<div dangerouslySetInnerHTML={{ __html: privacyPolicyBody }}>
						</div>

					</div>
				</div>
				<div className="top-line ng-scope"><img src={topLineImage} alt="" /></div>
			</>
		)
	}

PrivacyPolicies.contextTypes = {
	t: PropTypes.func
}
export default PrivacyPolicies;
