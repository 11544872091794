import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Banner from "./banner"
import About from "./about"
import Zone from "./zone"
import News from "./news"
import Activity from "./activity"
import Makers from "./makers"
import PropTypes from "prop-types"
import Main from "../../constants/utilities"
import mail_icon from "../../assets/images/mail.svg";
import topLineImage from "../../assets/images/top-line.png";
import image1 from "../../assets/images/AGE-GROUPS-VISUALS-01.png";
import image2 from "../../assets/images/AGE-GROUPS-VISUALS-02.png";
import image3 from "../../assets/images/AGE-GROUPS-VISUALS-03.png";
import image4 from "../../assets/images/AGE-GROUPS-VISUALS-04.png";

const Home = (props, context) => {
	const { config } = Main();
	const [configure, setConfig] = useState(config())

	const top_images = [image1,image2,image3,image4];

	const { lang } = useSelector(
		(state) => state.i18nState
	);

	useEffect(() => {
		document.title = `${context.t("_studio56")} | ${context.t("_home")}`
	}, [])

	return (
		<div id="main-content">
			<Banner />
			{/* <div className="top-line">
				<img src={topLineImage} alt="" />
			</div> */}
			<Makers />
			<About />
			<div className="top-images ng-scope">
					<div className="container">
						{top_images.map((row, index) => (
						<div className="item "  key={index} >
							<img src={row} alt="" />
						</div>
					))}
					</div>
				</div>
			<Zone />
			<Activity />
			<News />
			<div id="register-wrap" className={`ng-scope ${lang}`}>
				<div className={`container home-subscribe ${lang}`}>
					<span className="multi-square-orange"><b><i></i></b></span>
					<div><img src={mail_icon} className="mail-icon" alt="" /></div>
					<p>{context.t("_latest_insight")}</p>
					<p><Link to="/subscribe" className="red-btn btn btn-signup" aria-label="subscribe button for newsletter">{context.t("_subscribe")} <i className={`material-icons ${lang}`}>{`${lang == "en" ? "" : ""}`}</i></Link></p>
				</div>
			</div>
		</div>
	)
}

Home.contextTypes = {
	t: PropTypes.func
}

export default Home
